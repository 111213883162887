import React from 'react'
import { ICreateStep } from '../interfaces'
import Button from '@/components/Button/Button'
import Icon from '@/components/Icon/Icon'
import style from '../styles/add_case.module.scss'

interface IStepButtonsProps {
  index: number
  el: ICreateStep
  handleStepSwap: (fromIndex: number, toIndex: number) => void
  handleRemoveStep: (index: number, id?: number) => Promise<void>
  fields: ICreateStep[]
}

const StepButtons = ({
  index,
  el,
  handleStepSwap,
  handleRemoveStep,
  fields
}: IStepButtonsProps): JSX.Element => {
  return (
    <div className={style.step__buttons}>
      <Button
        theme='light'
        circle
        onClick={() => handleStepSwap(index, index - 1)}
        disabled={index === 0}
      >
        <Icon src='upArrow' />
      </Button>
      <Button
        theme='light'
        circle
        onClick={() => handleStepSwap(index, index + 1)}
        disabled={index === fields.length - 1}
      >
        <Icon src='downArrow' />
      </Button>

      <Button
        theme='light'
        circle
        className={style.step__delete}
        onClick={async () => await handleRemoveStep(index, el.id)}
      >
        <Icon src='clear' className={style.step__icon} />
      </Button>
    </div>
  )
}

export default StepButtons
