import React from 'react'
import UserLayout from '../layout/components/UserLayout'
import CompanyDetail from '../CompanyDetail'
import Container from '@/components/Container'
import CompanyProjects from '../CompanyProjects'

const CompanyProjectsPage = (): React.ReactElement => {
  return (
    <UserLayout>
      <Container>
        <CompanyDetail />

        <CompanyProjects />
      </Container>
    </UserLayout>
  )
}

export default CompanyProjectsPage
