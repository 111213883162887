import Title from '@/components/Title'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import style from '../styles/gitlab_report.module.scss'
import { IGitlabReportItem } from '@/interfaces/Project'
import ReportingHistoryCard from './ReportingHistoryCard'
import { LoaderGitlab } from '../loaders'

interface IProps {
  gitlabReports: IGitlabReportItem[]
  isReportsLoading: boolean
  isFormGenerate: boolean
}

const ReportingHistory = ({
  gitlabReports,
  isReportsLoading,
  isFormGenerate
}: IProps): JSX.Element => {
  return (
    <>
      <div className={style.reports__titlewrap}>
        <Title type='h2' theme='h1' className={style.reports__title}>
          <FormattedMessage
            id='titles.gitlab.reporting_history'
            defaultMessage='Reporting history'
          />
        </Title>
      </div>
      <hr className={style.line__horizontal} />
      {isReportsLoading || isFormGenerate
        ? (
          <LoaderGitlab countShimmers={6} />
          )
        : (
          <>
            {gitlabReports.map((report) => (
              <div key={report.id}>
                <ReportingHistoryCard
                  time={report.created_at}
                  name={report.tag_title}
                  content={report.content}
                />
              </div>
            ))}
          </>
          )}
    </>
  )
}

export default ReportingHistory
