import MenuItem from '@/components/MenuItem/MenuItem'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Select from '../Select'
import { TCaseType } from '@/interfaces/Types'
import style from '@/components/Select/PrioritySelect/styles/priorityselect.module.scss'

interface IProps {
  className?: string
  handleChange?: (e: any) => void
  value?: TCaseType | ''
  required?: boolean
  clearable?: boolean
  labelLeft?: boolean
}

const PrioritySelect = ({
  handleChange,
  value,
  clearable,
  required = false,
  className = '',
  labelLeft = false,
  ...props
}: IProps): React.ReactElement => {
  const intl = useIntl()

  return (
    <Select
      {...props}
      placeholder={intl.formatMessage({
        id: 'input.placeholder',
        defaultMessage: 'Select'
      })}
      label={intl.formatMessage({
        id: 'input.type',
        defaultMessage: 'Type'
      })}
      value={value}
      onChange={handleChange}
      clearable={clearable}
      required={required}
      labelLeft={labelLeft}
      className={className}
    >
      <MenuItem value='' className={style.project_item}>
        <FormattedMessage id='priority.all' defaultMessage='All' />
      </MenuItem>

      <MenuItem value='CASE'>
        <FormattedMessage id='input.case' defaultMessage='Test Case' />
      </MenuItem>

      <MenuItem value='CHECKLIST'>
        <FormattedMessage id='input.checklist' defaultMessage='Checklist' />
      </MenuItem>

      <MenuItem value='TASK'>
        <FormattedMessage id='input.task' defaultMessage='Task' />
      </MenuItem>
    </Select>
  )
}

export default PrioritySelect
