import Button from '@/components/Button/Button'
import { Form } from '@/components/Form'
import Icon from '@/components/Icon/Icon'
import InputDayPicker from '@/components/InputDayPicker/InputDayPicker'
import MemberSelect from '@/components/Select/MemberSelect'
import Title from '@/components/Title'
import Wrapper from '@/components/Wrapper'
import React from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { isValid, parse, subDays } from 'date-fns'
import style from '../styles/run_detail.module.scss'
import Textarea from '@/components/Textarea'
import { IFormInputs } from './Description'
import { useStores } from '@/hooks'
import { reactHookFormErrorFormater } from '@/utils'

interface IAssignToModalProps {
  handleCloseModal: () => void
  useFormInstance: UseFormReturn<IFormInputs>
  handleUpdate: () => void
  idProject: number
  runId: number
}

const AssignToModal = ({
  handleCloseModal,
  useFormInstance,
  handleUpdate,
  idProject,
  runId
}: IAssignToModalProps): JSX.Element => {
  const intl = useIntl()
  const store = useStores()

  const {
    register,
    setError,
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { isSubmitting, errors }
  } = useFormInstance

  setValue('id', runId)

  const onSubmit = async (params): Promise<void> => {
    try {
      await store.api.postRunAssigned(params)
      handleCloseModal()
      if (handleUpdate !== undefined) {
        handleUpdate()
      }
    } catch (error) {
      const errors = reactHookFormErrorFormater(params, error)
      errors.forEach((item) => {
        setError(item.key as keyof IFormInputs, {
          message: item.value
        })
      })
    }
  }

  const userProject = watch('user_project')
  const deadline = watch('deadline')

  const disabledAssignSend =
    userProject === undefined || deadline === '' || isSubmitting

  return (
    <Wrapper className={style.modal}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className={style.modal__head}>
          <Button onClick={handleCloseModal} theme='light' circle>
            <Icon src='back' />
          </Button>

          <Title type='h2' className={style.modal__item}>
            <FormattedMessage
              id='button.assign_to'
              defaultMessage='Assign to'
            />
          </Title>
        </div>

        <div className={style.modal__block}>
          <div className={style.description__select}>
            <Controller
              control={control}
              name='user_project'
              rules={{
                required: intl.formatMessage({
                  id: 'error.assigned.empty_field',
                  defaultMessage: "Field can't be empty"
                })
              }}
              render={({ field: { onChange, value, ...field } }) => (
                <MemberSelect
                  {...field}
                  value={value}
                  required
                  projectId={idProject}
                  error={errors?.user_project?.message ?? ''}
                  handleChange={onChange}
                  fieldValue='id'
                  camBeAssigned
                />
              )}
            />
          </div>

          <div className={style.description__select}>
            <Controller
              control={control}
              name='deadline'
              rules={{
                required: intl.formatMessage({
                  id: 'error.assigned.empty_field',
                  defaultMessage: "Field can't be empty"
                }),
                validate: {
                  validateFormat: (value = '') => {
                    const valid = isValid(
                      parse(value, 'dd/MM/yyyy', new Date())
                    )
                    if (valid) {
                      return true
                    } else {
                      return intl.formatMessage({
                        id: 'error.assigned.date_invalid',
                        defaultMessage: 'Incorrect format date (yyyy/MM/dd)'
                      })
                    }
                  }
                }
              }}
              render={({ field: { onChange, value, ...field } }) => (
                <InputDayPicker
                  {...field}
                  label={intl.formatMessage({
                    id: 'input.due_on',
                    defaultMessage: 'Due on'
                  })}
                  placeholder={intl.formatMessage({
                    id: 'input.dd/mm/yyyy',
                    defaultMessage: 'dd/mm/yyyy'
                  })}
                  required
                  error={errors?.deadline?.message ?? ''}
                  value={value}
                  onChangeHandlerInput={onChange}
                  onChangeHandlerPicker={onChange}
                  disabledDays={[
                    {
                      from: new Date(1970, 1, 1),
                      to: subDays(Date.now(), 1)
                    }
                  ]}
                  className={style.milestones__filter}
                />
              )}
            />
          </div>

          <div className={style.description__select}>
            <Controller
              control={control}
              name='description'
              render={({ field: { onChange, value } }) => (
                <Textarea
                  label={intl.formatMessage({
                    id: 'project.form.description',
                    defaultMessage: 'Description'
                  })}
                  {...register('description', { required: false })}
                  onChange={onChange}
                  value={value}
                  error={errors?.description?.message ?? ''}
                />
              )}
            />
          </div>
        </div>

        <div className={style.description__save}>
          <Button disabled={disabledAssignSend} type='submit'>
            <Icon src='checked' slot='icon-left' />

            <FormattedMessage id='common.save' defaultMessage='Save' />
          </Button>
        </div>
      </Form>
    </Wrapper>
  )
}

export default AssignToModal
