import React from 'react'
import useSWR from 'swr'
import CommentItem from '@/components/CommentItem'
import Title from '@/components/Title'
import { useStores } from '@/hooks'
import { ICaseInRunComment } from '@/interfaces/Case'
import { FormattedMessage } from 'react-intl'
import style from '../styles/case_detail.module.scss'
import Icon from '@/components/Icon/Icon'
import Button from '@/components/Button/Button'
import { ICommentListModalContentProps } from '../interfaces'
import { LoaderCaseInRunDetail } from '../loaders'
import useCaseType from '../hooks'

const CommentListModalContent = ({
  taskId,
  runId,
  onCancel,
  currentStepNumber,
  caseType
}: ICommentListModalContentProps): JSX.Element => {
  const { api } = useStores()
  const { isTask } = useCaseType(caseType)

  const { data, isLoading } = useSWR(
    {
      id: taskId,
      runId,
      isTask: isTask,
      _key: 'getCommentsStepRun'
    },
    api.getCommentsStepRun
  )

  const comments: ICaseInRunComment[] | undefined = data?.results
  if (comments === undefined) return <></>

  return (
    <div>
      <div className={style.comments_modal__head}>
        <div className={style.comments_modal__title}>
          <Title type='h2'>
            <Icon
              src='comment'
              slot='icon-left'
              className={style.comments_modal__icon}
            />
            <FormattedMessage id='titles.comments' defaultMessage='Comments' />
          </Title>
          <div className={style.comments_modal__stroke} />
        </div>

        <div className={style.comments_modal__btns}>
          <Button theme='light' onClick={onCancel} circle>
            <Icon src='clear' />
          </Button>
        </div>
      </div>

      {isLoading
        ? (
          <LoaderCaseInRunDetail countShimmers={6} />
          )
        : (
          <div className={style.comments_modal}>
            {comments.map((el) => {
              return (
                <CommentItem
                  key={el.id}
                  currentStepNumber={currentStepNumber}
                  status={el.status}
                  comment={el.comment}
                  createdTime={el.created_at}
                />
              )
            })}
          </div>
          )}
    </div>
  )
}

export default CommentListModalContent
