import React from 'react'
import Icon from '@/components/Icon/Icon'
import BaseModal from '@/components/Modal/Base'
import useModal from '@/hooks/useModal'
import classNames from 'classnames'
import NotificationsPanel from './components/NotificationsPanel'
import style from './styles/notifications.module.scss'
import useNotifyCounter from '@/hooks/useNotifyCounter'
import { useResponsiveMedia, useWebSocket } from '@/hooks'

interface IProps {
  className?: string
}

const Notifications = ({ className = '' }: IProps): React.ReactElement => {
  const { isOpen, handleOpenModal, handleCloseModal } = useModal()
  const { counter, counterUpdate } = useNotifyCounter()
  const { isMobile } = useResponsiveMedia()

  useWebSocket(counterUpdate, 'Notifications')

  return (
    <>
      <button
        type='button'
        onClick={handleOpenModal}
        className={classNames({
          [style.button]: true,
          [className]: Boolean(className)
        })}
      >
        <Icon
          src='notification'
          className={classNames({
            [style.button_icon]: true,
            [style['button_icon--animated']]: counter > 0
          })}
        />
        {counter > 0
          ? (
            <span className={style.button_counter}>{counter < 100 ? counter : '99+'}</span>
            )
          : null}
      </button>

      <BaseModal
        open={isOpen}
        onGx-after-hide={handleCloseModal}
        onGx-overlay-dismiss={handleCloseModal}
        hideDefaultClose
        size={isMobile ? 'large' : 'small'}
        side='right'
        className={style.modal}
      >
        {isOpen
          ? (
            <NotificationsPanel handleCloseModal={handleCloseModal} />
            )
          : null}
      </BaseModal>
    </>
  )
}

export default Notifications
