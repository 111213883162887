import React, { useState } from 'react'
import { MODAL_STAGE } from '@/const'
import NewEmail from './NewEmail'
import SuccessEmail from './SuccessEmail'

interface IProps {
  onClose: () => void
}

const ChangeEmail = ({ onClose }: IProps): React.ReactElement => {
  const [stage, setStage] = useState<number>(MODAL_STAGE.first)
  const [email, setEmail] = useState<string>('')

  const StateMachineNext = {
    1: () => MODAL_STAGE.second
  }

  const handleNext = (): void => {
    setStage(StateMachineNext[String(stage)]())
  }

  return (
    <>
      {stage === MODAL_STAGE.first
        ? (
          <NewEmail
            onClose={onClose}
            success={handleNext}
            handleChangeEmail={setEmail}
            email={email}
          />
          )
        : null}

      {stage === MODAL_STAGE.second ? <SuccessEmail onClose={onClose} email={email} /> : null}
    </>
  )
}

export default ChangeEmail
