import React from 'react'
import { errorFolder } from '@/images'
import AuthRegLayout from '../layout/components/AuthRegLayout'
import { useIntl } from 'react-intl'
import ErrorUnauthorized from '../Errors/ErrorUnauthorized'

const ErrorPage500 = (): React.ReactElement => {
  const intl = useIntl()

  return (
    <AuthRegLayout image={errorFolder}>
      <ErrorUnauthorized
        error={intl.formatMessage({
          id: 'error.error',
          defaultMessage: 'Error...'
        })}
        title={intl.formatMessage({
          id: 'error.text_500',
          defaultMessage: 'Your link works no longer'
        })}
        text={intl.formatMessage({
          id: 'error.contact_administrator',
          defaultMessage: 'Contact your administrator.'
        })}
      />
    </AuthRegLayout>
  )
}

export default ErrorPage500
