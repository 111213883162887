import classNames from 'classnames'
import React, { useEffect } from 'react'
import Button from '../Button/Button'
import Icon from '../Icon/Icon'
import { UserCompanyRole } from '../User'
import style from './styles/member.module.scss'
import { MODALS_CONTENTS } from '@/const'
import { useStores } from '@/hooks'
import { observer } from 'mobx-react'
import { TCompanyRole } from '@/interfaces/Types'
import { getInitials } from '../UserName'
import { AvatarUser } from '../Avatar'
import { useIntl } from 'react-intl'
import Tooltip from '../Tooltip'

interface IMemberProps {
  id: number
  email: string
  role: TCompanyRole
  roleId: number
  className?: string
  idCompany: number
  mutate: () => Promise<void>
}

const CompanyInvite = observer(
  ({
    id,
    email,
    role,
    roleId,
    idCompany,
    className = '',
    mutate
  }: IMemberProps): React.ReactElement => {
    const { modalStore } = useStores()
    const initialsEmail = getInitials('', '', email)
    const intl = useIntl()

    const handleMutate = async (): Promise<void> => {
      await mutate()
    }
    const handleClickRemove = (): void => {
      modalStore.open(MODALS_CONTENTS.removeMemberInvite, {
        idInvite: id,
        idCompany: idCompany,
        email: email,
        initialsEmail
      })
    }

    const handleClickResend = (): void => {
      modalStore.open(MODALS_CONTENTS.resendMemberInvite, {
        idInvite: id,
        idCompany: idCompany,
        role: roleId,
        email: email,
        initialsEmail
      })
    }

    useEffect(() => {
      handleMutate().catch((err) => console.log('err', err))
    }, [modalStore.currentModal])

    return (
      <div
        className={classNames({
          [style.member]: true,
          [className]: Boolean(className)
        })}
      >
        <div className={style.member__info}>
          <div>
            <Icon src='clock' size='small' className={style.member__clock} />
          </div>
          <AvatarUser size='large' initials={initialsEmail} />

          <div className={style.member__name}>{email}</div>

          <UserCompanyRole role={role} className={style.member__role} />
        </div>
        <Tooltip
          content={intl.formatMessage({
            id: 'profile.resend.link',
            defaultMessage: 'Resend link'
          })}
        >
          <Button onClick={handleClickResend} circle>
            <Icon src='resend' size='large' />
          </Button>
        </Tooltip>

        <Tooltip
          content={intl.formatMessage({
            id: 'company.invites.remove',
            defaultMessage: 'Remove invite'
          })}
        >
          <Button onClick={handleClickRemove} theme='light' circle>
            <Icon src='clear' size='large' className={style.member__clear} />
          </Button>
        </Tooltip>
      </div>
    )
  }
)

export default CompanyInvite
