import Title from '@/components/Title'
import React from 'react'
import style from './styles/error.module.scss'

interface ErrorProps {
  error: string
  title: string
  text: string
}

const ErrorUnauthorized = ({
  error,
  title,
  text
}: ErrorProps): React.ReactElement => {
  return (
    <div className={style.errorunauth}>
      <p className={style.errorunauth__error}>{error}</p>
      <Title type='h1' className={style.errorunauth__title}>{title}</Title>
      <p className={style.errorunauth__text}>{text}</p>
    </div>
  )
}

export default ErrorUnauthorized
