import { observable, action, makeObservable } from 'mobx'
import { History, Location } from 'history'
import { RootStore } from './Root'

/**
 * @see https://github.com/alisd23/mobx-react-router
 */
export class RouterStore {
  rootStore: RootStore
  location: Location
  history: History

  constructor (rootStore: RootStore) {
    this.rootStore = rootStore
    makeObservable(this, {
      location: observable,
      _updateLocation: action,
      push: action.bound,
      replace: action.bound,
      go: action.bound,
      goBack: action.bound,
      goForward: action.bound
    })
  }

  _updateLocation (newState): void {
    this.location = newState
  }

  /*
   * History methods
   */
  push = (location, state): void => {
    this.history.push(location, state)
  }

  replace = (location, state): void => {
    this.history.replace(location, state)
  }

  go = (iState): void => {
    this.history.go(iState)
  }

  goBack = (): void => {
    this.history.back()
  }

  goForward = (): void => {
    this.history.forward()
  }
}
