import { makeAutoObservable } from 'mobx'
import { RootStore } from './Root'

export class ModalStore {
  currentModal: string | null = null
  rootStore: RootStore
  dataModal?: any

  constructor (rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  open = (name: string, data: any): void => {
    this.currentModal = name
    this.dataModal = data
  }

  close = (): void => {
    this.currentModal = null
  }

  isOpen = (): boolean => this.currentModal !== null
}
