import { LinkButton } from '@/components/Button/LinkButton'
import Icon from '@/components/Icon/Icon'
import { usePage } from '@/hooks'
import React from 'react'
import style from './styles/projectbuttons.module.scss'
import { useCurrentPermissions } from '@/hooks/useCurrentPermissions'
import { PERMISSION_LEVEL, USER_PERMISSIONS } from '@/const'

interface IListItem {
  name: string
  icon: string
  href: string
}

type PermissionKey = 'ProjectPageMilestonesCreate' | 'ProjectPageRunsCreate' | 'ProjectPageCasesCreate'

const icons = {
  ProjectPageMilestonesCreate: 'flag',
  ProjectPageRunsCreate: 'file',
  ProjectPageCasesCreate: 'test_case'
}

const ProjectButtons = ({ isBig }): React.ReactElement => {
  const page = usePage()
  const sidebar = page?.init_state?.sidebar
  const canCreateMilestone = useCurrentPermissions(USER_PERMISSIONS.milestones, [PERMISSION_LEVEL.full])
  const canUserAddCase = useCurrentPermissions(USER_PERMISSIONS.case, [PERMISSION_LEVEL.full])
  const canUserCreateRun = useCurrentPermissions(USER_PERMISSIONS.case, [PERMISSION_LEVEL.full])

  let currentKeys: PermissionKey[] = []
  if (canCreateMilestone) {
    currentKeys = [...currentKeys, 'ProjectPageMilestonesCreate']
  }
  if (canUserCreateRun) {
    currentKeys = [...currentKeys, 'ProjectPageRunsCreate']
  }
  if (canUserAddCase) {
    currentKeys = [...currentKeys, 'ProjectPageCasesCreate']
  }

  const data: IListItem[] = currentKeys.map((key) => ({
    name: sidebar[key].title,
    icon: icons[key],
    href: sidebar[key].absolute_url
  }))
  return (
    <div className={style.projectbuttons}>
      {isBig === true
        ? data.map((el) => {
          return (
            <LinkButton href={el.href} key={el.name}>
              <Icon slot='icon-left' src={el.icon} />
              {el.name}
            </LinkButton>
          )
        })
        : null}
    </div>
  )
}

export default ProjectButtons
