import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import style from '../styles/reports.module.scss'
import useSidebar from '@/hooks/useSidebar'
import Icon from '@/components/Icon/Icon'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import TooltipWithJsxContent from '@/components/Tooltip/TooltipWithJsxContent'

const GitlabReportButton = ({
  connStatus
}: {
  connStatus: boolean | undefined
}): JSX.Element => {
  const { ProjectPageGitlabReports, ProjectPageIntegrations } = useSidebar()
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const [tooltipTimeout, setTooltipTimeout] =
    useState<NodeJS.Timeout | null>(null)

  const gitlabReportPage: string = ProjectPageGitlabReports.absolute_url
  const integrationPage: string = ProjectPageIntegrations.absolute_url

  useEffect(() => {
    return () => {
      if (tooltipTimeout !== null) {
        clearTimeout(tooltipTimeout)
      }
    }
  }, [tooltipTimeout])

  const handleTooltipClick = (): void => {
    setTooltipOpen(true)
    if (tooltipTimeout != null) {
      clearTimeout(tooltipTimeout)
    }
    const newTimeout = setTimeout(() => {
      setTooltipOpen(false)
    }, 3500)
    setTooltipTimeout(newTimeout)
  }

  return (
    <div className={style.reports_gitlab__report}>
      {connStatus !== undefined && connStatus
        ? (
          <Link to={gitlabReportPage} className={style.reports_gitlab__link}>
            <div className={style.reports_gitlab__nav_group}>
              <Icon src='gitlab' />
              <span className={style.reports_gitlab__nav}>
                <FormattedMessage
                  id='titles.gitlab.reports'
                  defaultMessage='Gitlab Reports'
                />
              </span>
            </div>
          </Link>
          )
        : (
          <>
            <TooltipWithJsxContent
              jsxContent={
                <div className={style.reports_gitlab__tooltip}>
                  <FormattedMessage
                    id='common.check_integration'
                    defaultMessage='Check your GitLab integration'
                  />

                  <Link
                    to={integrationPage}
                    className={style.reports_gitlab__navlink}
                  >
                    <FormattedMessage
                      id='common.to_integration'
                      defaultMessage='To integration'
                    />
                  </Link>
                </div>
            }
              open={tooltipOpen}
              placement='bottom'
            >
              <div
                className={classNames({
                  [style.reports_gitlab__nav_group]: true,
                  [style['reports_gitlab--disabled']]: connStatus === false
                })}
                onClick={handleTooltipClick}
              >
                <Icon src='gitlab' />
                <span
                  className={classNames({
                    [style.reports_gitlab__nav]: true,
                    [style['reports_gitlab__nav--disabled']]:
                    connStatus === false
                  })}
                >
                  <FormattedMessage
                    id='titles.gitlab.reports'
                    defaultMessage='Gitlab Reports'
                  />
                </span>
              </div>
            </TooltipWithJsxContent>
          </>
          )}
    </div>
  )
}

export default GitlabReportButton
