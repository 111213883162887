import React from 'react'
import style from '../styles/companies.module.scss'
import Title from '@/components/Title'
import { companiesEmpty } from '@/images'
import { FormattedMessage } from 'react-intl'
import { LinkButton } from '@/components/Button/LinkButton'
import Icon from '@/components/Icon/Icon'
import useGlobalConfig from '@/hooks/useGlobalConfig'

const EmptyCompanies = (): React.ReactElement => {
  const obj = useGlobalConfig()
  return (
    <div className={style.empty}>
      <img className={style.empty__img} src={companiesEmpty} alt='empty' />

      <Title type='h1' className={style.empty__title}>
        <FormattedMessage
          id='companies.empty.title'
          defaultMessage='Add Test Cases'
        />
      </Title>

      <p className={style.empty__text}>
        <FormattedMessage
          id='companies.empty.text'
          defaultMessage='Add Test Cases'
        />
      </p>

      <LinkButton href={obj.create_company_url} className={style.company__addcompany}>
        <Icon src='plus' slot='icon-left' />

        <FormattedMessage
          id='companies.add_company'
          defaultMessage='Add company'
        />
      </LinkButton>
    </div>
  )
}

export default EmptyCompanies
