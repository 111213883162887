import { IStatistics } from '@/interfaces/Runs'
import React from 'react'
import { BadgeStatus } from '../Status'
import style from './styles/groupstatuses.module.scss'

interface IProps {
  statistic: IStatistics
}

const GroupTextStatuses = ({
  statistic
}: IProps): React.ReactElement => {
  return (
    <div className={style.groupstatuses}>
      <BadgeStatus status='PASSED' count={statistic.passed} />
      <BadgeStatus status='BLOCKED' count={statistic.blocked} />
      <BadgeStatus status='RETEST' count={statistic.retest} />
      <BadgeStatus status='FAILED' count={statistic.failed} />
      <BadgeStatus status='UNTESTED' count={statistic.untested} />
    </div>
  )
}

export default GroupTextStatuses
