import Icon from '@/components/Icon/Icon'
import React from 'react'
import style from '../styles/caseitem.module.scss'
import { TextTag } from '@/components/Tag'
import { ITag } from '@/interfaces/Case'
import Priority from '@/components/Priority'
import { TCaseStatusInRun, TCaseType, TPriority } from '@/interfaces/Types'
import Link from '@/components/Link'
import {
  CASE_ICON,
  CASE_IN_RUN_STATUS,
  CASE_TYPES,
  CREATE_PERMISSION_LEVEL,
  MODALS_CONTENTS,
  USER_PERMISSIONS
} from '@/const'
import DropdownStatus from '@/components/Dropdown/DropdownStatus'
import CommentButton from '@/components/Button/CommentButton'
import { BadgeStatus } from '@/components/Status'
import { useStores } from '@/hooks'
import Button from '@/components/Button/Button'
import { observer } from 'mobx-react'
import { useCurrentPermissions } from '@/hooks/useCurrentPermissions'

interface IProps {
  id: number
  runId: number
  commentsCount: number
  title: string
  priority: TPriority
  code: string
  tags?: ITag[]
  status: TCaseStatusInRun
  link: string
  needsUpdate: boolean
  type: TCaseType
  onMutate: () => void
}

const CaseItemInRun = observer(
  ({
    id,
    runId,
    title,
    priority,
    code,
    tags,
    link,
    status,
    type,
    commentsCount,
    needsUpdate,
    onMutate
  }: IProps): React.ReactElement => {
    const { api, modalStore, runDetailStore } = useStores()
    const handleClickComment = (): void => {
      modalStore.open(MODALS_CONTENTS.commentsTask, {
        runId: runId,
        taskId: id
      })
    }

    const handleClickSyncCases = (): void => {
      modalStore.open(MODALS_CONTENTS.syncCases, {
        runId: runId,
        caseId: id
      })
    }
    const updateTaskStatus = (value): void => {
      void api
        .changeTaskStatus(runId, id, {
          status: value.toUpperCase() as TCaseStatusInRun
        })
        .then(() => {
          onMutate()
        })
    }

    const isTask = type === CASE_TYPES.task

    const run = runDetailStore.getRun()

    const isRunIsProcess =
      run?.statistics.status === CASE_IN_RUN_STATUS.inprocess

    const canUserEditRun = useCurrentPermissions(
      USER_PERMISSIONS.run,
      CREATE_PERMISSION_LEVEL
    )

    const canUserChangeStatus = isTask && isRunIsProcess && canUserEditRun

    return (
      <div className={style.caseitem__item}>
        <div className={style.caseitem__desc}>
          <span className={style.caseitem__code}>{code}</span>
          <Link to={link} className={style.caseitem__link}>
            <Icon
              src={type !== undefined ? CASE_ICON[type] : 'test_case'}
              className={style.caseitem__type}
            />
            <span className={style.caseitem__title}>{title}</span>
          </Link>

          {tags !== undefined && tags.length > 0
            ? (
              <div className={style.caseitem__tags}>
                {tags.map((el) => {
                  return (
                    <TextTag size='medium' key={el.id}>
                      {el.title}
                    </TextTag>
                  )
                })}
              </div>
              )
            : null}
        </div>

        <div className={style.caseitem__right}>
          {needsUpdate
            ? (
              <Button
                size='sm'
                className={style.caseitem__cir}
                circle
                onClick={handleClickSyncCases}
              >
                <Icon src='attention' className={style.caseitem__attention} />
              </Button>
              )
            : null}

          {isTask && commentsCount > 0
            ? (
              <CommentButton onClick={handleClickComment} />
              )
            : null}

          <Priority priority={priority} variant='icon' />

          {canUserChangeStatus
            ? (
              <DropdownStatus status={status} handleChange={updateTaskStatus} />
              )
            : (
              <BadgeStatus status={status} />
              )}
        </div>
      </div>
    )
  }
)

export default CaseItemInRun
