import classNames from 'classnames'
import React from 'react'
import { HexColorInput, HexColorPicker } from 'react-colorful'
import { FormattedMessage } from 'react-intl'
import style from './styles/picker.module.scss'

interface IProps {
  className?: string
  color: string
  onChange: (newColor: string) => void
}

const ColorPicker = ({
  className = '',
  color,
  onChange
}: IProps): React.ReactElement => {
  return (
    <div
      className={classNames({
        [style.picker]: true,
        [className]: Boolean(className)
      })}
    >
      <HexColorPicker color={color} onChange={onChange} />

      <label className={style.picker__label}>
        <span className={style.picker__label_text}>
          <FormattedMessage id='color_picker.color' defaultMessage='Color' />
        </span>

        <HexColorInput
          className={style.picker__input}
          prefixed
          color={color}
          onChange={onChange}
        />
      </label>
    </div>
  )
}

export default ColorPicker
