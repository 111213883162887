import React from 'react'
import classNames from 'classnames'
import style from './styles/status.module.scss'

interface IProps {
  color: string
  size?: 'xs' | 'small' | 'medium' | 'large'
  className?: string
}

const ColorStatus = ({
  color,
  size = 'medium',
  className = ''
}: IProps): React.ReactElement => {
  return (
    <div
      className={classNames({
        [style.color]: true,
        [style[`color--${size}`]]: true,
        [className]: Boolean(className)
      })}
      style={{ backgroundColor: color }}
    />
  )
}

export default ColorStatus
