import Wrapper from '@/components/Wrapper'
import React from 'react'
import style from '../styles/add_company.module.scss'
import Title from '@/components/Title'
import { FormattedMessage, useIntl } from 'react-intl'
import PhotoInput from '@/components/Input/PhotoInput'
import Input from '@/components/Input/Input'
import Textarea from '@/components/Textarea'
import Select from '@/components/Select/Select'
import MenuItem from '@/components/MenuItem/MenuItem'
import Icon from '@/components/Icon/Icon'
import { COMPANY_TYPES } from '@/const'

const CompanyForm = ({
  register,
  image,
  errors,
  control
}): React.ReactElement => {
  const intl = useIntl()

  return (
    <Wrapper className={style.block}>
      <Title type='h2'>
        <FormattedMessage id='titles.company' defaultMessage='Company' />
      </Title>

      <div className={style.form__row}>
        <div className={style.form__left}>
          <PhotoInput
            {...register('image')}
            image={image}
            variant='company'
            className={style.form__image}
            error={errors?.image?.message ?? ''}
          />

          <div className={style.form__info}>
            <Select
              {...register('company_type', { required: true })}
              className={style.form__block}
              placeholder={intl.formatMessage({
                id: 'input.placeholder',
                defaultMessage: 'Choose'
              })}
              label={intl.formatMessage({
                id: 'company.form.type',
                defaultMessage: 'Type'
              })}
              required
            >
              <MenuItem value={COMPANY_TYPES.person}>
                <FormattedMessage
                  id='company.form.person'
                  defaultMessage='Person'
                />
              </MenuItem>
              <MenuItem value={COMPANY_TYPES.entity}>
                <FormattedMessage
                  id='company.form.entity'
                  defaultMessage='Legal Entity'
                />
              </MenuItem>
            </Select>

            <div className={style.form__info_flex}>
              <Input
                label={intl.formatMessage({
                  id: 'company.form.name',
                  defaultMessage: 'Name'
                })}
                required
                className={style.form__info_name}
                {...register('title', { required: true })}
                error={errors?.title?.message ?? ''}
              />

              <Input
                label='ID'
                required
                className={style.form__info_id}
                {...register('code', { required: true })}
                error={errors?.code?.message ?? ''}
              />
            </div>

            <Input
              label={intl.formatMessage({
                id: 'company.form.full_name',
                defaultMessage: 'Full company name'
              })}
              required
              className={style.form__block}
              {...register('full_title', { required: true })}
              error={errors?.full_title?.message ?? ''}
            />

            <Textarea
              label={intl.formatMessage({
                id: 'company.form.description',
                defaultMessage: 'Description'
              })}
              {...register('description')}
              error={errors?.description?.message ?? ''}
            />
          </div>
        </div>

        <div className={style.form__right}>
          <div className={style.right__item}>
            <Icon src='mail' className={style.right__icon} />
            <Input
              label={intl.formatMessage({
                id: 'company.form.email',
                defaultMessage: 'Email'
              })}
              type='email'
              required
              className={style.right__input}
              {...register('email', { required: true })}
              error={errors?.email?.message ?? ''}
            />
          </div>

          {/* Пока решили не делать выбор страны  */}
          {/* <div className={style.right__item}>
            <Icon src='location' className={style.right__icon} />
            <Select
              {...register('country', { required: true })}
              placeholder={intl.formatMessage({
                id: 'input.placeholder',
                defaultMessage: 'Choose'
              })}
              label={intl.formatMessage({
                id: 'company.form.country',
                defaultMessage: 'Country'
              })}
              required
              value='Россия'
              className={style.right__input}
            >
              <MenuItem value='Россия'>Россия</MenuItem>
              <MenuItem value='Не Россия'>Не Россия</MenuItem>
            </Select>
          </div> */}

          <div className={style.right__item}>
            <Icon src='website' className={style.right__icon} />
            <Input
              label={intl.formatMessage({
                id: 'company.form.site',
                defaultMessage: 'Company website'
              })}
              className={style.right__input}
              {...register('site_url')}
              error={errors?.site_url?.message ?? ''}
            />
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default CompanyForm
