import Container from '@/components/Container'
import useCurrentUser from '@/hooks/useCurrentUser'
import React from 'react'
import FaqList from './components/FaqList'
import SendQuestion from './components/SendQuestion'
import style from './style/report_detail.module.scss'

const Faq = (): React.ReactElement => {
  const { currentUser } = useCurrentUser()
  const email = currentUser?.email
  return (
    <Container>
      <div className={style.content}>
        {email !== undefined ? <SendQuestion email={email} /> : null}

        <FaqList />
      </div>
    </Container>
  )
}

export default Faq
