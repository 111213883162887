import TextButton from '@/components/Button/TextButton'
import React from 'react'
import { Matcher } from 'react-day-picker'
import { FormattedMessage } from 'react-intl'
import style from '../styles/daypicker.module.scss'

interface IProps {
  handleReset: () => void
  disabledReset?: boolean
  selected?: Matcher | Matcher[] | undefined
}

const Bottom = ({
  disabledReset,
  selected,
  handleReset
}: IProps): React.ReactElement => {
  return (
    <div className={style.bottom}>
      <TextButton
        disabled={disabledReset === true || selected === undefined}
        onClick={handleReset}
      >
        <FormattedMessage
          id='date_picker.reset'
          defaultMessage='Reset filter'
        />
      </TextButton>
    </div>
  )
}

export default Bottom
