import Button from '@/components/Button/Button'
import ErrorsBlock from '@/components/ErrorsBlock'
import Icon from '@/components/Icon/Icon'
import Title from '@/components/Title'
import { useStores } from '@/hooks'
import React, { useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import style from './styles/cases.module.scss'

interface IProps {
  id: number | null | undefined
  idProject: number
  onClose: () => void
  mutateSuites: () => void
  exampleCase?: string
  format: 'eqator' | 'qase' | 'testit'
}

const ImportCases = ({
  onClose,
  id,
  idProject,
  mutateSuites,
  exampleCase,
  format
}: IProps): React.ReactElement => {
  const intl = useIntl()
  const inputFile = useRef<HTMLInputElement>(null)
  const [errors, setErrors] = useState<string[] | null>(null)
  const [success, setSuccess] = useState<string>()
  const { api } = useStores()
  const handleUploadClick = (): void => {
    inputFile.current?.click()
  }

  const handleInput = (e): void => {
    const currentFiles: FileList = e.target.files
    const suiteId = id === null ? undefined : id
    if (currentFiles !== null) {
      api
        .importCase(idProject, suiteId, currentFiles)
        .then(() => {
          // onClose()
          setSuccess(
            intl.formatMessage({
              id: 'cases.import.success',
              defaultMessage: 'Importing cases may take time. You will be notified when the request is processed'
            })
          )
          setErrors(null)
        })
        .then(mutateSuites)
        .catch((err) => {
          setErrors(err.response.data.non_field_errors)
        })
    }
  }

  return (
    <>
      <div className={style.modal__content}>
        <div className={style.modal__import}>
          {format === 'eqator'
            ? (
              <Title>
                <Icon className={style.title__icon} src='eqatorIcon' />
                <FormattedMessage
                  id='cases.import.eqator.file'
                  defaultMessage='Import Eqator file'
                />
              </Title>
              )
            : format === 'qase'
              ? (
                <Title>
                  <Icon className={style.title__icon} src='qaseIcon' />
                  <FormattedMessage
                    id='cases.import.qase.file'
                    defaultMessage='Import Qase file'
                  />
                </Title>
                )
              : (
                  format === 'testit' && (
                    <Title>
                      <Icon className={style.title__icon} src='testitIcon' />
                      <FormattedMessage
                        id='cases.import.testit.file'
                        defaultMessage='Import Test IT file'
                      />
                    </Title>
                  )
                )}

          {format === 'eqator' && (
            <>
              <div className={style.modal__menu}>
                <div className={style.modal__items}>
                  {exampleCase !== undefined
                    ? (
                      <a download href={exampleCase}>
                        <FormattedMessage
                          id='cases.import.example.case'
                          defaultMessage='Example file for import'
                        />
                      </a>
                      )
                    : null}
                </div>
              </div>
            </>
          )}

          <div className={style.modal__subtitle}>
            <FormattedMessage
              id='cases.import.supported'
              defaultMessage='Supported file types are: csv, xls, xlsx. The file must not exceed 20MB.'
            />
          </div>

          {success !== undefined
            ? (
              <span className={style.modal__success}>{success}</span>
              )
            : null}
        </div>
      </div>

      <div className={style.modal__footer}>
        <Button theme='light' onClick={onClose}>
          <Icon src='clear' slot='icon-left' />
          <FormattedMessage id='common.cancel' defaultMessage='Cancel' />
        </Button>

        <Button onClick={handleUploadClick}>
          <Icon src='download' slot='icon-left' />
          <FormattedMessage
            id='cases.import.file'
            defaultMessage='Import file'
          />
        </Button>
      </div>

      {errors !== null
        ? (
          <ErrorsBlock errorsArray={errors} className={style.modal__errors} />
          )
        : null}

      <input
        type='file'
        hidden
        ref={inputFile}
        onInput={handleInput}
        accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
      />
    </>
  )
}

export default ImportCases
