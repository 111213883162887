import Title from '@/components/Title'
import Wrapper from '@/components/Wrapper'
import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import StatisticItem from './components/StatisticItem'
import useSWR from 'swr'
import { usePage, useStores } from '@/hooks'
import EmptyList from '@/components/EmptyList/EmptyList'
import { format, isValid, parse, subDays } from 'date-fns'
import InputDayPicker from '@/components/InputDayPicker/InputDayPicker'
import style from './styles/reports.module.scss'
import { LoaderRunsStatistic } from './loaders'

const currentDate = format(new Date(), 'dd/MM/yyyy')
const twoWeekAgoDate = format(subDays(Date.now(), 14), 'dd/MM/yyyy')

const Statistics = (): React.ReactElement => {
  const [startDate, setStartDate] = useState<string>(twoWeekAgoDate)
  const [endDate, setEndDate] = useState<string>(currentDate)

  const page = usePage()
  const store = useStores()
  const intl = useIntl()

  const hash = page.init_state.share_hash

  const formatDate = (date: string): string | undefined => {
    const parseDate = parse(date, 'dd/MM/yyyy', new Date())
    if (isValid(parseDate)) {
      return format(parseDate, 'yyyy-MM-dd')
    } else {
      return undefined
    }
  }

  const CHARTS_INFO = {
    error_count: {
      title: intl.formatMessage({
        id: 'statistic.error_count',
        defaultMessage: 'Errors count'
      })
    },
    flake_count: {
      title: intl.formatMessage({
        id: 'statistic.flake_count',
        defaultMessage: 'Errors flake8'
      })
    },
    radon_count: {
      title: intl.formatMessage({
        id: 'statistic.radon_count',
        defaultMessage: 'Cyclomatic complexity'
      })
    },
    security_linter_count: {
      title: intl.formatMessage({
        id: 'statistic.security_linter_count',
        defaultMessage: 'Security'
      })
    },
    migrations_count: {
      title: intl.formatMessage({
        id: 'statistic.migrations_count',
        defaultMessage: 'Project migrations'
      })
    },
    unit_tests_count: {
      title: intl.formatMessage({
        id: 'statistic.unit_tests_count',
        defaultMessage: 'Unit tests'
      })
    },
    garpix_page_tests_count: {
      title: intl.formatMessage({
        id: 'statistic.garpix_page_tests_count',
        defaultMessage: 'Unit tests garpix_page'
      })
    },
    // coverage_result: {
    //   title: intl.formatMessage({
    //     id: "statistic.coverage_result",
    //     defaultMessage: "Test coverage result",
    //   }),
    // },
    coverage_value: {
      title: intl.formatMessage({
        id: 'statistic.coverage_value',
        defaultMessage: 'Test coverage value'
      })
    },
    lighthouse_count: {
      title: intl.formatMessage({
        id: 'statistic.lighthouse_count',
        defaultMessage: 'Lighthouse'
      })
    }
    // duration: {
    //   title: 'duration'
    // },
  }

  const { data, isLoading } = useSWR(
    {
      hash,
      from: formatDate(startDate),
      to: formatDate(endDate)
    },
    store.api.getShareCliReports
  )

  const hasCli = data?.has_cli

  const statistic: { labels: string[], charts: any[] } = {
    labels: [],
    charts: []
  }

  if (hasCli === true) {
    const labels: string[] = []
    const chartsData = {}
    data?.result.forEach((el) => {
      labels.push(store.localeDate.formatDate(el.created_at__date))
      Object.keys(CHARTS_INFO).forEach((chartKey) => {
        if (chartsData[chartKey] === undefined) {
          chartsData[chartKey] = {
            title: CHARTS_INFO[chartKey].title,
            data: []
          }
        }
        chartsData[chartKey].data.push(el[`max_${chartKey}`])
      })
    })
    statistic.labels = labels
    statistic.charts = []
    for (const key in chartsData) {
      statistic.charts.push(chartsData[key])
    }
  }

  const hasData = !isLoading && statistic.charts.length > 0

  return (
    <Wrapper>
      <Title type='h2' theme='h1'>
        <FormattedMessage id='titles.statistic' defaultMessage='Statistic' />
      </Title>

      {hasCli === true
        ? (
          <div className={style.stat__filters}>
            <InputDayPicker
              label={intl.formatMessage({
                id: 'milestones.form.start_date',
                defaultMessage: 'Start date'
              })}
              className={style.stat__filter}
              placeholder={intl.formatMessage({
                id: 'input.dd/mm/yyyy',
                defaultMessage: 'dd/mm/yyyy'
              })}
              value={startDate}
              onChangeHandlerInput={setStartDate}
              onChangeHandlerPicker={setStartDate}
            />

            <InputDayPicker
              label={intl.formatMessage({
                id: 'milestones.form.end_date',
                defaultMessage: 'End date'
              })}
              className={style.stat__filter}
              placeholder={intl.formatMessage({
                id: 'input.dd/mm/yyyy',
                defaultMessage: 'dd/mm/yyyy'
              })}
              value={endDate}
              onChangeHandlerInput={setEndDate}
              onChangeHandlerPicker={setEndDate}
              disabledDays={[
                {
                  from: new Date(1970, 1, 1),
                  to: subDays(parse(startDate, 'dd/MM/yyyy', new Date()), 1)
                }
              ]}
            />
          </div>
          )
        : null}

      {hasData
        ? (
          <div className={style.stat__row}>
            {statistic.charts.map((el) => {
              return (
                <StatisticItem
                  key={el.title}
                  title={el.title}
                  chartData={el.data}
                  labels={statistic.labels}
                />
              )
            })}
          </div>
          )
        : null}

      {hasCli === false
        ? (
          <EmptyList
            icon='error'
            text={intl.formatMessage({
              id: 'statistic.not_connected',
              defaultMessage:
              'Your project is not connected to the statistics collection system.'
            })}
          />
          )
        : null}

      {!hasData && hasCli === true
        ? (
          <EmptyList
            icon='error'
            text={intl.formatMessage({
              id: 'statistic.no_data',
              defaultMessage: 'There is no data for the selected period'
            })}
          />
          )
        : null}

      {isLoading ? <LoaderRunsStatistic countShimmers={Object.keys(CHARTS_INFO).length} /> : null}
    </Wrapper>
  )
}

export default Statistics
