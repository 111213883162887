import { MODAL_STAGE } from '@/const'
import React, { useState } from 'react'
import ResendCode from './components/ResendCode'
import SendEmail from './components/SendEmail'

const Registration = (): React.ReactElement => {
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [stage, setStage] = useState<number>(MODAL_STAGE.first)
  const StateMachineNext = {
    1: () => MODAL_STAGE.second
  }

  const StateMachinePrev = {
    2: () => MODAL_STAGE.first
  }
  const handleNext = (): void => {
    setStage(StateMachineNext[String(stage)]())
  }

  const handlePrev = (): void => {
    setStage(StateMachinePrev[String(stage)]())
  }

  return (
    <>
      {stage === MODAL_STAGE.first
        ? (
          <SendEmail nextStage={handleNext} email={email} setEmail={setEmail} phone={phone} setPhone={setPhone} />
          )
        : null}
      {stage === MODAL_STAGE.second
        ? (
          <ResendCode prevStage={handlePrev} email={email} />
          )
        : null}
    </>
  )
}

export default Registration
