import React, {
  Suspense,
  forwardRef,
  useImperativeHandle,
  useState
} from 'react'
import { usePage, useStores } from '@/hooks'
import Wrapper from '@/components/Wrapper'
import Title from '@/components/Title'
import Icon from '@/components/Icon/Icon'
import style from './styles/suites.module.scss'
import Button from '@/components/Button/Button'
import useSWR from 'swr'
import { FormattedMessage, useIntl } from 'react-intl'
import BaseModal from '@/components/Modal/Base'
import { CreateOrEditSuite } from '../CreateOrEditSuite'
import SuitesDndList from '../SuitesDndList/SuitesDndList'
import DeleteSuite from '../DeleteSuite'
import { ISuite, ISuiteCaseParams } from '@/interfaces/Suites'
import useDebounce from '@/hooks/useDebounce'
import { suiteService } from '../../service'
import { observer } from 'mobx-react'
import { useCurrentPermissions } from '@/hooks/useCurrentPermissions'
import SearchInput from '@/components/Input/SearchInput/SearchInput'
import {
  CREATE_PERMISSION_LEVEL,
  EDIT_PERMISSION_LEVEL,
  USER_PERMISSIONS
} from '@/const'
import { useLocation } from 'react-router-dom'
import { LoaderSuites } from '../Cases/loaders'
import useModal from '@/hooks/useModal'

interface IProps {
  params: ISuiteCaseParams
  changeParams: (name: string, value: any) => void
  nodesThree?: number[]
  setNodesThree: (
    value: React.SetStateAction<string | number | Array<string | number | null>>
  ) => void
  setQueryParams: (name: string, value: any) => void
}

const LayDndProvider = React.lazy(
  async () =>
    await import('@minoru/react-dnd-treeview').then(
      ({ getBackendOptions, MultiBackend, DndProvider }) => {
        const DndComponent: React.FC<any> = (props) => {
          const backendOptions = getBackendOptions(props)
          const backend = MultiBackend

          return (
            <DndProvider backend={backend} options={backendOptions}>
              {props.children}
            </DndProvider>
          )
        }

        return {
          default: DndComponent
        }
      }
    )
)

const Suites = observer(
  forwardRef(
    (
      {
        params,
        changeParams,
        nodesThree,
        setNodesThree,
        setQueryParams
      }: IProps,
      ref: React.MutableRefObject<{
        handleReloadSuites: () => void
      } | null>
    ): React.ReactElement => {
      const page = usePage()
      const { api } = useStores()
      const { pathname } = useLocation()

      const canCurrentUserEditSuite = useCurrentPermissions(
        USER_PERMISSIONS.case,
        EDIT_PERMISSION_LEVEL
      )
      const canUserAddSuite = useCurrentPermissions(
        USER_PERMISSIONS.case,
        CREATE_PERMISSION_LEVEL
      )

      if (params?.createSuite === true) {
        const urlParams = new URLSearchParams(window.location.search)
        urlParams.delete('createSuite')
        const newURL = `${pathname}?${urlParams.toString()}`
        history.replaceState({}, '', newURL)
      }

      const [isDeleteLoading, setIsDeleteLoading] = useState(false)

      const {
        isOpen: isOpenAddOrEdit,
        handleCloseModal: closeModalAddOrEdit,
        handleOpenModal: openModalAddOrEdit
      } = useModal()

      const {
        isOpen: isOpenDelete,
        handleCloseModal: closeModalDelete,
        handleOpenModal: openModalDelete
      } = useModal()
      const [search, setSearch] = useState(params.qSuite ?? '')
      const [initSuiteId, setInitSuiteId] = useState<number>()
      const [currentDeleteSuite, setCurrentDeleteSuite] = useState<ISuite>()

      const debouncedSearch = useDebounce(search)

      const intl = useIntl()

      const projectId = page.init_state.object.id

      const { data, isLoading, mutate } = useSWR(
        { project_id: projectId, q: debouncedSearch, _key: 'getSuites' },
        api.getSuites,
        {
          revalidateOnReconnect: false
        }
      )

      const { data: casesWithoutSuites, mutate: mutateWithoutSuites } = useSWR(
        {
          page: 1,
          page_size: 1,
          project: projectId,
          without_suite: true,
          _key: 'getCases'
        },
        api.getCases
      )

      useImperativeHandle(ref, () => ({ handleReloadSuites }))

      const handleReloadSuites = async (): Promise<void> => {
        await Promise.all([mutateWithoutSuites(), mutate()])
      }

      const handleOpenModalDelete = (id: number): void => {
        const suite = data?.find((el) => el.id === id)
        setCurrentDeleteSuite(suite)
        openModalDelete()
      }

      const handleCloseModalAddOrEdit = (): void => {
        closeModalAddOrEdit()
        setInitSuiteId(undefined)
      }

      const handleCloseModalDelete = (): void => {
        closeModalDelete()
        setCurrentDeleteSuite(undefined)
        setInitSuiteId(undefined)
      }

      const handleSearch = (value: string): void => {
        setSearch(value)
        if (value !== '') {
          changeParams('qSuite', value)
        } else {
          changeParams('qSuite', undefined)
        }
      }

      const handlSetNodes = (value: number[]): void => {
        setNodesThree(value)
        if (value.length !== 0) {
          changeParams('nodesThree', value)
        } else {
          changeParams('nodesThree', undefined)
        }
      }

      const handleDelete = (id: number): void => {
        setIsDeleteLoading(true)

        api
          .deleteSuite(id)
          .then(() => {
            if (id === params?.activeNode) {
              setQueryParams('activeNode', undefined)
              suiteService.setCurrentSuite({ id: undefined, nodeTitle: '' })
            }
          })
          .catch((err) => console.log('err', err))
          .finally(() => {
            void handleReloadSuites()
            setIsDeleteLoading(false)
          })
        handleCloseModalDelete()
      }

      const handleEdit = (id: number): void => {
        setInitSuiteId(id)
        openModalAddOrEdit()
      }

      const onChange = (id, target, sort): void => {
        api
          .updateSuite(id, {
            parent: target === 0 ? null : target,
            sort: sort
          })
          .then(handleReloadSuites)
          .catch((e) => {
            console.log(e)
          })
      }

      return (
        <Wrapper className={style.suites}>
          <div className={style.suites__top}>
            <Title type='h1' className={style.suites__title}>
              <FormattedMessage
                id='suites.title'
                defaultMessage='Test Suites'
              />
            </Title>

            {canUserAddSuite && (
              <Button
                onClick={openModalAddOrEdit}
                className={style.suites__addsuite}
              >
                <Icon src='plus' slot='icon-left' />

                <FormattedMessage
                  id='suites.add_suite'
                  defaultMessage='Add test suite'
                />
              </Button>
            )}
          </div>
          <div className={style.suites__form}>
            <SearchInput
              handleChange={handleSearch}
              value={search}
              placeholder={intl.formatMessage({
                id: 'suites.search',
                defaultMessage: 'Search suites'
              })}
            />
          </div>

          <Suspense fallback={<LoaderSuites countShimmers={5} />}>
            <LayDndProvider>
              {isLoading
                ? (
                  <LoaderSuites countShimmers={5} />
                  )
                : (
                  <SuitesDndList
                    data={data}
                    onChange={onChange}
                    onDelete={handleOpenModalDelete}
                    onEdit={handleEdit}
                    nodeArray={
                    nodesThree !== undefined && nodesThree?.length > 0
                      ? nodesThree
                      : true
                  }
                    setNodeArray={handlSetNodes}
                    queryParams={params}
                    setQueryParams={setQueryParams}
                    canEditSuite={canCurrentUserEditSuite}
                    caseCountWithoutCases={casesWithoutSuites?.count}
                    changeParamsWithoutCases={changeParams}
                  />
                  )}
            </LayDndProvider>
          </Suspense>

          {isOpenDelete && (currentDeleteSuite != null)
            ? (
              <BaseModal
                open={isOpenDelete}
                onGx-after-hide={handleCloseModalDelete}
                onGx-overlay-dismiss={handleCloseModalDelete}
                hideDefaultClose
                className={style.modal}
              >
                <DeleteSuite
                  suite={currentDeleteSuite}
                  onCancel={handleCloseModalDelete}
                  onDelete={handleDelete}
                  disableSubmit={isDeleteLoading}
                />
              </BaseModal>
              )
            : null}

          {isOpenAddOrEdit
            ? (
              <BaseModal
                open={isOpenAddOrEdit}
                onGx-after-hide={handleCloseModalAddOrEdit}
                onGx-overlay-dismiss={handleCloseModalAddOrEdit}
                hideDefaultClose
                className={style.modal}
              >
                <CreateOrEditSuite
                  project={projectId}
                  handleClose={handleCloseModalAddOrEdit}
                  initSuiteId={initSuiteId}
                  handleReloadSuites={handleReloadSuites}
                />
              </BaseModal>
              )
            : null}
        </Wrapper>
      )
    }
  )
)

export default Suites
