import { ICurrentUser } from '@/interfaces/User'
import { UserStates } from '@/interfaces/Types'
import { useStores } from './useStores'

interface IUseCurrentUser {
  currentUser?: ICurrentUser
  status: UserStates
}

const useCurrentUser = (): IUseCurrentUser => {
  const { userStore } = useStores()
  const currentUser = userStore.currentUser
  const status = userStore.status

  return {
    status,
    currentUser
  }
}

export default useCurrentUser
