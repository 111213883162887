import { INTERVAL_METHOD, REPORT_TYPE } from '../interface'

export const reportTypeOptions = [
  {
    value: REPORT_TYPE.withDescription,
    labelId: 'reports.gitlab.headline',
    defaultMessage: 'Headline with a description'
  },
  {
    value: REPORT_TYPE.onlyHeadlines,
    labelId: 'reports.gitlab.only_headline',
    defaultMessage: 'Only headlines'
  }
]

export const intervalMethodOptions = [
  {
    value: INTERVAL_METHOD.tags,
    labelId: 'reports.gitlab.tags',
    defaultMessage: 'Tags'
  },
  {
    value: INTERVAL_METHOD.time,
    labelId: 'reports.gitlab.time',
    defaultMessage: 'Time'
  }
]
