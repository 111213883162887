import React from 'react'
import { Pagination as PaginationComponent } from '@garpix/pagination/react'
import Button from '../Button/Button'
import style from './styles/pagination.module.scss'
import Icon from '../Icon/Icon'
import { IPagination } from '@garpix/pagination/core'
import classNames from 'classnames'

interface IProps {
  currentPage: number
  total: number
  pageSize: number
  handleChange: (page: number) => void
  className?: string
}

const Pagination = ({
  currentPage,
  total,
  pageSize,
  handleChange,
  className = ''
}: IProps): React.ReactElement => {
  const classes = classNames({
    [style.pagination]: true,
    [className]: Boolean(className)
  })

  return (
    <PaginationComponent
      currentPage={currentPage}
      total={total}
      pageSize={pageSize}
    >
      {({
        pages,
        currentPage,
        hasNextPage,
        hasPreviousPage,
        previousPage,
        nextPage,
        totalPages
      }: IPagination) => {
        if (total > pageSize) {
          return (
            <div className={classes}>
              <Button
                disabled={!hasPreviousPage}
                theme='light'
                circle
                onClick={() => handleChange(currentPage - 1)}
                size='sm'
              >
                <Icon src='back' />
              </Button>
              {pages.map((item, i) => {
                if (typeof item === 'string') {
                  return <span key={`dots_${i}`} className={style.pagination__points}>...</span>
                }
                return (
                  <Button
                    theme={item === currentPage ? 'violet' : 'light'}
                    className={style.pagination__button}
                    onClick={() => handleChange(item)}
                    key={item}
                  >
                    {item}
                  </Button>
                )
              })}
              <Button
                disabled={!hasNextPage}
                circle
                theme='light'
                onClick={() => handleChange(currentPage + 1)}
                size='sm'
              >
                <Icon src='back' className={style.pagination__nexticon} />
              </Button>
            </div>
          )
        }
      }}
    </PaginationComponent>
  )
}

export default Pagination
