import GroupStatuses from '@/components/GroupStatuses/GroupStatuses'
import StepItem from '@/components/StepItem'
import Title from '@/components/Title'
import Wrapper from '@/components/Wrapper'
import React, { Dispatch } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import BaseModal from '@/components/Modal/Base'
import useModal from '@/hooks/useModal'
import JiraBugListOfStep from './JiraBugListOfStep'
import StepStatus from './StepStatus'
import CommentListModalContent from './CommentListModalContent'
import { useObjectPage, usePage, useStores } from '@/hooks'
import StepButton from './StepButton'
import useSWR from 'swr'
import JiraBugCreationForm from './JiraBugCreationForm'
import { IStep } from '@/interfaces/Case'
import { ICaseInRunPage } from '@/interfaces/ObjectPage'
import { IStatistics } from '@/interfaces/Runs'
import { TBadgeStatus, TCaseType } from '@/interfaces/Types'
import CreateCommentForm from './CreateCommentForm'
import useCaseType from '../hooks'
import { BadgeStatus } from '@/components/Status'
import {
  USER_PERMISSIONS,
  CASE_IN_RUN_STATUS,
  CREATE_PERMISSION_LEVEL
} from '@/const'
import { useCurrentPermissions } from '@/hooks/useCurrentPermissions'
import style from '../styles/case_detail.module.scss'

export interface IStepsProps {
  steps: IStep[]
  statistic: IStatistics
  type: TCaseType
  setCurrentItem: Dispatch<React.SetStateAction<IStep>>
  currentItem: IStep
  mutate: () => Promise<void>
  taskStatus: TBadgeStatus
}

const Steps = ({
  steps,
  statistic,
  type,
  setCurrentItem,
  currentItem,
  mutate,
  taskStatus
}: IStepsProps): JSX.Element => {
  const page = usePage()
  const store = useStores()
  const intl = useIntl()
  const { id: runId, statistics } = useObjectPage<ICaseInRunPage>()

  const {
    isOpen: isCommentModalOpen,
    handleOpenModal: handleOpenCommentModal,
    handleCloseModal: handleCloseCommentModal
  } = useModal()

  const projectId: number = page.init_state.project_object.id
  const { case_run: caseInRun } = page.init_state
  const caseType = caseInRun.case_type
  const { isCase, isChecklist } = useCaseType(caseType)

  const modalTitle = isCase
    ? intl.formatMessage({
      id: 'case.steps.status',
      defaultMessage: 'Step status'
    })
    : intl.formatMessage({
      id: 'task.status',
      defaultMessage: 'Task status'
    })

  const {
    isOpen: isBugListModalOpen,
    handleOpenModal: handleOpenBugListModal,
    handleCloseModal
  } = useModal()

  const {
    isOpen: isJiraBugFormOpen,
    handleOpenModal: handleJiraBugFormOpen,
    handleCloseModal: handleJiraBugFormClose
  } = useModal()

  const {
    isOpen: isOpenCommentListModal,
    handleOpenModal: handleOpenCommentListModal,
    handleCloseModal: handleCloseCommentListModal
  } = useModal()

  const { data: jiraHealthCheck } = useSWR(
    [projectId],
    store.api.getJiraHealthCheck
  )

  const canUserEditRun =
    useCurrentPermissions(USER_PERMISSIONS.run, CREATE_PERMISSION_LEVEL) &&
    statistics.status === CASE_IN_RUN_STATUS.inprocess

  return (
    <Wrapper className={style.steps}>
      <div className={style.steps__block}>
        <Title type='h2' className={style.steps__title}>
          <FormattedMessage id='titles.steps' defaultMessage='Steps' />
        </Title>
        <GroupStatuses statistic={statistic} />
      </div>

      <div className={style.steps__list}>
        {steps.map((el) => {
          return (
            <div className={style.steps__item} key={el.id}>
              <StepItem
                stepNumber={el.number}
                description={el.description}
                expectedResult={el.expected_result}
                type={type}
              />

              <div className={style.steps__item_group}>
                {canUserEditRun
                  ? (
                    <StepStatus
                      stepElement={el}
                      updateStepData={mutate}
                    />
                    )
                  : (
                    <BadgeStatus status={el.status} />
                    )}

                {el.status !== 'UNTESTED'
                  ? (
                    <StepButton
                      stepElement={el}
                      jiraHealthCheckStatus={jiraHealthCheck?.result}
                      setCurrentItem={setCurrentItem}
                      handleJiraBugFormOpen={handleJiraBugFormOpen}
                      handleOpenCommentListModal={handleOpenCommentListModal}
                      handleOpenAddComment={handleOpenCommentModal}
                      handleOpenBugListModal={handleOpenBugListModal}
                    />
                    )
                  : null}
              </div>
            </div>
          )
        })}

        <BaseModal
          open={isCommentModalOpen}
          onGx-after-hide={handleCloseCommentModal}
          hideDefaultClose
          className={style.modal}
        >
          {isCommentModalOpen
            ? (
              <CreateCommentForm
                taskStatus={taskStatus}
                status={isCase || isChecklist ? currentItem.status : taskStatus}
                handleCloseModal={handleCloseCommentModal}
                modalTitle={modalTitle}
                idStepOrTask={currentItem.id}
                updateStepData={mutate}
                runId={runId}
                caseInRun={caseInRun}
              />
              )
            : null}
        </BaseModal>

        <BaseModal
          open={isJiraBugFormOpen}
          onGx-after-hide={handleJiraBugFormClose}
          hideDefaultClose
          className={style.modal}
        >
          {isJiraBugFormOpen
            ? (
              <JiraBugCreationForm
                handleCloseModal={handleJiraBugFormClose}
                caseStep={currentItem.id}
                updateStepData={mutate}
              />
              )
            : null}
        </BaseModal>

        <BaseModal
          open={isBugListModalOpen}
          onGx-after-hide={handleCloseModal}
          onGx-overlay-dismiss={handleCloseModal}
          hideDefaultClose
          size='medium'
        >
          {isBugListModalOpen
            ? (
              <JiraBugListOfStep
                idStepOrTask={currentItem.id}
                onCancel={handleCloseModal}
                projectId={projectId}
              />
              )
            : null}
        </BaseModal>

        <BaseModal
          open={isOpenCommentListModal}
          onGx-after-hide={handleCloseCommentListModal}
          onGx-overlay-dismiss={handleCloseCommentListModal}
          hideDefaultClose
          size='medium'
        >
          {isOpenCommentListModal
            ? (
              <CommentListModalContent
                caseType={caseType}
                currentStepNumber={currentItem.number}
                runId={runId}
                taskId={currentItem.id}
                onCancel={handleCloseCommentListModal}
              />
              )
            : null}
        </BaseModal>
      </div>
    </Wrapper>
  )
}

export default Steps
