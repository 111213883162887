import React from 'react'
import CommonLayout from '../layout/components/CommonLayout'
import { forbidden } from '@/images'
import { useIntl } from 'react-intl'
import ErrorAuth from '../Errors/ErrorAuth'

const ErrorPage403 = (): React.ReactElement => {
  const intl = useIntl()

  return (
    <CommonLayout>
      <ErrorAuth
        numberError={403}
        textError={intl.formatMessage({
          id: 'error.forbidden',
          defaultMessage: 'Forbidden...'
        })}
        descriptionTitle={intl.formatMessage({
          id: 'error.page_not_available',
          defaultMessage: 'The page is not available'
        })}
        descriprionText={intl.formatMessage({
          id: 'error.text_403',
          defaultMessage: 'You do not have access to this page. Contact the administrator.'
        })}
        image={forbidden}
      />
    </CommonLayout>
  )
}

export default ErrorPage403
