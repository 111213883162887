import Title from '@/components/Title'
import Wrapper from '@/components/Wrapper'
import { IAttachment } from '@/interfaces/Attachment'
import React, { useEffect, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import { UploadZoneApi, UploadZoneForm } from '..'
import style from './styles/files.module.scss'

interface IProps {
  initFiles: IAttachment[]
  onChange: (e: File[]) => void
  onDelete?: () => void
  isScrollableList?: boolean
  enableReinitialize?: boolean
  isUpload?: boolean
  className?: string
  canUserDeleteFile?: boolean
  type: 'api' | 'form'
  readonly?: boolean
}

const UploadContainer = ({
  initFiles = [],
  onChange,
  onDelete,
  isScrollableList = false,
  enableReinitialize,
  isUpload,
  canUserDeleteFile,
  type,
  className = '',
  readonly
}: IProps): React.ReactElement => {
  const [wrapperHeight, setWrapperHeight] = useState(0)
  const [boxHeight, setBoxHeight] = useState(0)

  const wrapperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    // Костыли с подстройкой высоты блока с файлами под соседний
    if (wrapperRef.current === null) return
    const wrapperHeight = wrapperRef.current.clientHeight
    setWrapperHeight(wrapperHeight)
  }, [])

  useEffect(() => {
    // Костыли с подстройкой высоты блока с файлами под соседний
    if (wrapperRef.current === null) return

    const wrapper = wrapperRef.current
    const wrapperStyles = getComputedStyle(wrapper)

    const computedWrapperHeight = Math.max(wrapper.clientHeight, wrapperHeight)

    const contentHeight =
      computedWrapperHeight -
      parseInt(wrapperStyles.paddingBlockStart) -
      parseInt(wrapperStyles.paddingBlockEnd)

    setBoxHeight(contentHeight)
  })

  return (
    <Wrapper
      className={classNames({
        [style.files]: true,
        [className]: Boolean(className)
      })}
      ref={wrapperRef}
    >
      <div className={style.box} style={{ maxHeight: `${boxHeight}px` }}>
        <Title type='h2'>
          <FormattedMessage id='titles.files' defaultMessage='Files' />
        </Title>

        {type === 'form'
          ? (
            <UploadZoneForm
              initFiles={initFiles}
              onChange={onChange}
              onDelete={onDelete}
              className={style.upload}
              isScrollableList={isScrollableList}
              enableReinitialize={enableReinitialize}
              isUpload={isUpload}
              canUserDeleteFile={canUserDeleteFile}
              readonly={readonly}
            />
            )
          : null}

        {type === 'api'
          ? (
            <UploadZoneApi
              initFiles={initFiles}
              onChange={onChange}
              onDelete={onDelete}
              className={style.upload}
              isScrollableList={isScrollableList}
              enableReinitialize={enableReinitialize}
              isUpload={isUpload}
              canUserDeleteFile={canUserDeleteFile}
              readonly={readonly}
            />
            )
          : null}
      </div>
    </Wrapper>
  )
}

export default UploadContainer
