import { IProjectMember } from '@/interfaces/Member'
import { ICurrentUser } from '@/interfaces/User'

export const determineDisplayName = (
  assignedTo: IProjectMember | null,
  currentUser: ICurrentUser,
  currentUserName: string
): string => {
  const firstName = assignedTo?.first_name ?? ''
  const lastName = assignedTo?.last_name ?? ''

  return firstName === currentUser.first_name &&
    lastName === currentUser.last_name
    ? currentUserName
    : [firstName, lastName].filter(Boolean).join(' ')
}
