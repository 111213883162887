import { ControlledAccordion } from '@/components/Accordion'
import { useObjectPage, useStores } from '@/hooks'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import useSWR from 'swr'
import GitlabIntegrationHead from './components/GitlabIntegrationHead'
import GitlabIntegrationForm from './components/GitlabIntegrationForm'
import SwitchButton from '@/components/Button/SwitchButton'
import { useIntl } from 'react-intl'
import { yupResolver } from '@hookform/resolvers/yup'
import { gitlabValidationSchema } from './validations'
import { useIntegrationStatus } from '../Integrations/context'
import { getStatusFromMappings } from './utils'
import styles from '../Integrations/styles/card.module.scss'

const GitlabIntegration = (): JSX.Element => {
  const intl = useIntl()

  const { gitlabIntegrationStatus, setGitlabIntegrationStatus } =
    useIntegrationStatus()

  const formMethods = useForm({
    resolver: yupResolver(gitlabValidationSchema)
  })
  const { id } = useObjectPage()
  const { api } = useStores()
  const [toggle, setToggle] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const errorMessage = intl.formatMessage({
    id: 'integrations.jira.failed',
    defaultMessage: 'Synchronization failed'
  })

  const {
    data: healthCheck,
    isLoading: isLoadingHealthCheck,
    mutate: mutateHealthStatus
  } = useSWR(
    {
      id: id?.toString(),
      _key: 'getGitlabHealthCheckStatus'
    },
    api.getGitlabHealthCheckStatus
  )

  const {
    data: project,
    isLoading: isLoadingGetProject,
    mutate: projectMutation
  } = useSWR(
    {
      id: id,
      _key: 'getProject'
    },
    api.getProject
  )

  const setGitlabIntegration = async (
    enable: boolean,
    fromToggle: boolean = false
  ): Promise<void> => {
    if (fromToggle) {
      await api.postGitlabSettings(id, { gitlab_enable: enable })
    } else {
      const requiredData = {
        ...formMethods.getValues(),
        gitlab_enable: enable
      }
      await api.postGitlabSettings(id, requiredData)
    }
    await mutateHealthStatus()
  }

  const handleToggle = async (): Promise<void> => {
    await setGitlabIntegration(!toggle, true)
    setToggle(!toggle)
    await mutateHealthStatus()
    await projectMutation()
  }

  const handleOpenAccordion = async (): Promise<void> => {
    setIsOpen(true)
  }

  useEffect(() => {
    if (healthCheck?.result !== undefined && project !== undefined) {
      const { result } = healthCheck
      const status = getStatusFromMappings(project, result)
      setGitlabIntegrationStatus(status)
      if (status === true) setToggle(status)
      if (status === null) setToggle(false)
    }
  }, [healthCheck, project, setGitlabIntegrationStatus])

  useEffect(() => {
    void api.getProject({ id: id }).then((project) => {
      formMethods.reset({
        gitlab_url: project.gitlab_url,
        gitlab_id: project.gitlab_id,
        gitlab_token: project.gitlab_token,
        gitlab_group: project.gitlab_group
      })
      setToggle(project.gitlab_enable)
    })
  }, [])

  return (
    <ControlledAccordion
      className={styles.card}
      setIsOpen={setIsOpen}
      isOpen={isOpen}
      headRight={
        <SwitchButton
          handleChange={formMethods.handleSubmit(
            handleToggle,
            handleOpenAccordion
          )}
          disabled={isLoadingHealthCheck || isLoadingGetProject}
          textColor='red'
          toggled={toggle}
          label={gitlabIntegrationStatus === false ? errorMessage : null}
        />
      }
      head={<GitlabIntegrationHead />}
    >
      <FormProvider {...formMethods}>
        <GitlabIntegrationForm
          projectMutation={projectMutation}
          setGitlabIntegration={setGitlabIntegration}
        />
      </FormProvider>
    </ControlledAccordion>
  )
}

export default GitlabIntegration
