import React from 'react'
import style from './styles/loading.module.scss'
import { Shimmer, ShimmerWrap } from '@/components/Loading'

interface IProps {
  countShimmers: number
  className?: string
}

const LoaderCaseDetail = ({
  countShimmers,
  className = ''
}: IProps): React.ReactElement => {
  const shimmers = Array.from({ length: countShimmers }, (_, index) => (
    <Shimmer key={index} size='large' />
  ))

  return (
    <ShimmerWrap className={className}>
      <div className={style.wrap}>
        <Shimmer size='xl' />
      </div>

      <div className={style.wrap}>
        <Shimmer />
      </div>

      <div className={style.wrap}>
        <div className={style.loading}>{shimmers}</div>
      </div>
    </ShimmerWrap>
  )
}

export default LoaderCaseDetail
