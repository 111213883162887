import React from 'react'
import { useNavigate } from 'react-router-dom'
import Button from './Button'
import { IButtonProps } from './interfaces'

interface IProps extends IButtonProps {
  href: string
}

const LinkButton = ({
  children,
  href,
  onClick,
  ...props
}: IProps): React.ReactElement => {
  const navigate = useNavigate()

  const handleClick = (e): void => {
    e.preventDefault()
    onClick?.(e)
    navigate(href)
  }

  return (
    <Button onClick={handleClick} href={href} {...props}>
      {children}
    </Button>
  )
}

export { LinkButton }
