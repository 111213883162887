import React from 'react'
import Icon from '@/components/Icon/Icon'
import classNames from 'classnames'
import { TextTag } from '@/components/Tag'
import { observer } from 'mobx-react'
import style from '../styles/update_cases.module.scss'
import { FormattedMessage } from 'react-intl'
import { suiteService } from '../SuitesCasesChanges/service'

interface IProps {
  caseCount?: number
}

const WithoutSuite = observer(({ caseCount = 0 }: IProps) => {
  const suiteId = suiteService.getCurrentSuite().id

  const handleClick = (): void => {
    if (suiteId === undefined) {
      // suiteId === null - не выбран сьюьт
      suiteService.setCurrentSuite({ id: null, nodeTitle: 'node.text' })
    } else {
      // suiteId === undefined - выбрано без сьюта
      suiteService.setCurrentSuite({ id: undefined, nodeTitle: 'node.text' })
    }
  }
  return (
    <div className={style.dnd__root}>
      <button
        type='button'
        onClick={handleClick}
        className={classNames({
          [style.dnd__titlebtn]: true,
          [style['dnd__titlebtn--opened']]: suiteId === undefined
        })}
      >
        <Icon src='folders' />

        <FormattedMessage
          id='suites.root_suite'
          defaultMessage='Without suite'
        />
      </button>

      <div className={style.dnd__right}>
        <TextTag>
          <Icon src='test_case' />
          {caseCount}
        </TextTag>
      </div>
    </div>
  )
})

export default WithoutSuite
