import style from '../style/profile.module.scss'
import React from 'react'
import Icon from '@/components/Icon/Icon'
import Button from '@/components/Button/Button'
import { FormattedMessage } from 'react-intl'
import CompanyMemberShort from '@/components/Member/CompanyMemberShort'

interface IProps {
  onClose: () => void
  email: string
}

const SuccessEmail = ({ onClose, email }: IProps): React.ReactElement => {
  return (
    <div className={style.success__block}>
      <div className={style.success__top}>
        <div className={style.success__successtext}>
          <FormattedMessage
            id='profile.change_email.send'
            defaultMessage='We just sent you a link over to'
          />
        </div>

        <CompanyMemberShort name={email} avatar={null} initials='' />
      </div>

      <Button onClick={onClose} className={style.success__end}>
        <Icon src='checked' slot='icon-left' />

        <FormattedMessage id='common.ok' defaultMessage='Ok' />
      </Button>
    </div>
  )
}

export default SuccessEmail
