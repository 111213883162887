export const priorities = [
  {
    value: 'LOWEST',
    icon: 'priority_very_low',
    id: 'priority.very_low',
    defaultMessage: 'Very low'
  },
  {
    value: 'LOW',
    icon: 'priority_low',
    id: 'priority.low',
    defaultMessage: 'Low'
  },
  {
    value: 'MEDIUM',
    icon: 'priority_medium',
    id: 'priority.medium',
    defaultMessage: 'Medium'
  },
  {
    value: 'HIGH',
    icon: 'priority_high',
    id: 'priority.high',
    defaultMessage: 'High'
  },
  {
    value: 'HIGHEST',
    icon: 'priority_critical',
    id: 'priority.critical',
    defaultMessage: 'Critical'
  }
]

export const replaceRussianSymbols = (input: string): string => {
  const replacements = {
    н: 'w',
    д: 'd',
    ч: 'h',
    м: 'm'
  }
  return input.replace(/[ндчм]/g, (match) => replacements[match])
}
