import React from 'react'
import { format } from 'date-fns'
import { IMilestonesList } from '@/interfaces/Milestones'
import Icon from '@/components/Icon/Icon'
import Button from '@/components/Button/Button'
import ListContent from '@/components/ListContent'
import { useIntl } from 'react-intl'
import Title from '@/components/Title'
import { MilestoneItem } from '@/components/MilestoneItem'
import style from '../styles/dashboard.module.scss'

interface IProps {
  date: Date | null
  handleGoBack: () => void
  onPrevDay: () => void
  onNextDay: () => void
  milestones: IMilestonesList[]
  locale: Locale
  isLoading: boolean
  error: Error
}

const CalendarDetail = ({
  date,
  handleGoBack,
  onPrevDay,
  onNextDay,
  milestones,
  locale,
  error,
  isLoading
}: IProps): React.ReactElement => {
  const intl = useIntl()
  const formattedDate = date != null ? format(date, 'cccc, dd.MM.yyyy', { locale: locale }) : ''

  return (
    <div className={style.calendardetail}>
      <div className={style.calendardetail__head}>
        <div className={style.calendardetail__head__side}>
          <Button onClick={handleGoBack} theme='white'> <Icon src='back' /> </Button>

          <Title
            type='h2'
            className={style.calendardetail__title}
          >
            {formattedDate}
          </Title>
        </div>

        <div className={style.calendardetail__head__side}>
          <Button
            theme='white'
            onClick={onPrevDay}
          >
            <Icon src='arrow_left' />
          </Button>
          <Button
            theme='white'
            onClick={onNextDay}
          >
            <Icon src='arrow_right' />
          </Button>
        </div>
      </div>

      <ListContent
        isLoading={isLoading}
        error={error}
        hasData={milestones.length > 0}
        emptyListIcon='flag'
        emptyListText={intl.formatMessage({
          id: 'milestones.calendar.no_milestones',
          defaultMessage: 'No milestone planned for this day'
        })}
      >
        <div className={style.calendardetail__list}>
          {milestones.map((milestone) => (
            <MilestoneItem
              projectImage={milestone.image}
              key={milestone.id}
              absoluteUrl={milestone.absolute_url}
              id={milestone.id}
              title={milestone.title}
              color={milestone.color}
              progress={milestone.progress}
              code={milestone.code}
            />
          ))}
        </div>
      </ListContent>
    </div>
  )
}

export default CalendarDetail
