import React, { useState } from 'react'
import useSWR from 'swr'
import classNames from 'classnames'
import Button from '@/components/Button/Button'
import Icon from '@/components/Icon/Icon'
import { useStores } from '@/hooks'
import CompanyButton from './CompanyButton'
import qs from 'qs'
import { useSearchParams } from 'react-router-dom'
import style from '../styles/dashboard.module.scss'
import { observer } from 'mobx-react'
import { useIntl } from 'react-intl'
import { sidebarService } from '../service'
import { LoaderSidebarDashboard } from '../loaders'

const Sidebar = observer((): React.ReactElement => {
  const [, setSearchParams] = useSearchParams()
  const [isBig, setIsBig] = useState(sidebarService.open)
  const { api, companyStore, routerStore } = useStores()
  const intl = useIntl()

  // фиксит вертикальные прыжки контента в сайдбаре во время открытия
  const toggleOpenSidebar = (): void => {
    try {
      if (!sidebarService.open) {
        setTimeout(() => {
          setIsBig(!isBig)
        }, 300)
      } else setIsBig(!isBig)
    } finally {
      sidebarService.toggle()
    }
  }

  const { data, isLoading } = useSWR(
    {
      page: 1,
      page_size: 10000,
      status: 'active',
      in_company: true,
      _key: 'getCompaniesSidebar'
    },
    api.getCompanies
  )

  const qsParams = qs.parse(routerStore.history.location.search, {
    ignoreQueryPrefix: true
  })

  const addCompanyToStore = (companyId: number): void => {
    if (companyId > 0) {
      companyStore.setCompany(companyId)
    } else companyStore.setCompany(undefined)
  }

  const getDefaultActiveCompany = (): number => {
    const qsCompanyId = Number(qsParams?.company)

    if (isNaN(qsCompanyId)) {
      addCompanyToStore(-1)
      return -1
    } else {
      addCompanyToStore(qsCompanyId)
      return qsCompanyId
    }
  }

  const [activeCompanyTab, setActiveCompanyTab] = useState<number>(() =>
    getDefaultActiveCompany()
  )

  const hasData = !isLoading && data !== undefined

  const handleClickRecents = (): void => {
    const params = { ...qsParams, company: undefined }
    const queryParams = qs.stringify(params)

    setSearchParams(queryParams)
    setActiveCompanyTab(-1)
    addCompanyToStore(-1)
  }

  const handleClickCompany = (companyId: number): void => {
    const params = { ...qsParams, company: companyId }
    const queryParams = qs.stringify(params)

    setSearchParams(queryParams)
    setActiveCompanyTab(companyId)
    addCompanyToStore(companyId)
  }

  return (
    <div
      className={classNames({
        [style.sidebar]: true,
        [style['sidebar--opened']]: sidebarService.open
      })}
    >
      <div className={style.sidebar__scroll}>
        {isLoading ? <LoaderSidebarDashboard countShimmers={7} /> : null}

        {hasData
          ? (
            <>
              <CompanyButton
                isActive={activeCompanyTab === -1}
                title={intl.formatMessage({
                  id: 'dashboard.recents',
                  defaultMessage: 'Recents'
                })}
                icon='clock'
                onClick={handleClickRecents}
                isSidebarOpened={isBig}
              />

              {data.results.map((el, i) => {
                return (
                  <CompanyButton
                    key={el.id}
                    isActive={activeCompanyTab === el.id}
                    title={el.title}
                    logo={el.image}
                    onClick={() => handleClickCompany(el.id)}
                    isSidebarOpened={isBig}
                  />
                )
              })}
            </>
            )
          : null}
      </div>

      <Button
        theme='light'
        className={style.sidebar__button}
        onClick={toggleOpenSidebar}
      >
        <Icon
          src='back'
          className={classNames({
            [style.sidebar__icon]: true,
            [style['sidebar__icon--opened']]: sidebarService.open
          })}
        />
      </Button>
    </div>
  )
})

export default Sidebar
