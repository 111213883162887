import Container from '@/components/Container'
import { CASE_TYPES } from '@/const'
import { useObjectPage, usePage, useStores } from '@/hooks'
import { ICaseInRunPage } from '@/interfaces/ObjectPage'
import React from 'react'
import useSWR from 'swr'
import Description from './components/Description'
import Files from './components/Files'
import Steps from './components/Steps'
import { LoaderCaseDetail, LoaderFiles } from './loaders'

const CaseDetail = (): React.ReactElement => {
  const obj = useObjectPage<ICaseInRunPage>()
  const page = usePage()
  const { api } = useStores()

  const { id, project } = obj

  const { data, mutate } = useSWR(
    {
      id: id,
      _key: 'getCase'
    },
    api.getCase
  )

  const onChange = async (file): Promise<void> => {
    await api.updateCase(id, { files: file, project: { id: project } })
    await mutate()
  }

  const handleDelete = async (): Promise<void> => {
    await mutate()
  }

  const initFiles = data?.files !== undefined ? data?.files : []

  const editUrl = page.init_state.subpages.CasePageUpdate.absolute_url

  return (
    <Container>
      {data !== undefined
        ? (
          <>
            <Description caseData={data} editUrl={editUrl} />
            {initFiles !== undefined
              ? (
                <Files
                  initFiles={initFiles}
                  onChange={onChange}
                  onDelete={handleDelete}
                />
                )
              : (
                <LoaderFiles />
                )}
            {data.case_type === CASE_TYPES.case ||
          data.case_type === CASE_TYPES.checklist
              ? (
                <Steps steps={data.steps} editUrl={editUrl} type={data.case_type} />
                )
              : null}
          </>
          )
        : (
          <LoaderCaseDetail countShimmers={6} />
          )}
    </Container>
  )
}

export default CaseDetail
