import Container from '@/components/Container'
import React from 'react'
import History from './components/History'
import MainInfo from './components/MainInfo'
import Milestones from './components/Milestones'
import Runs from './components/Runs'
import style from './styles/project_detail.module.scss'

const ProjectDetail = (): React.ReactElement => {
  return (
    <Container>
      <MainInfo />

      <div className={style.detail__flex}>
        <Milestones />
        <Runs />
      </div>

      <History />
    </Container>
  )
}

export default ProjectDetail
