import Icon from '@/components/Icon/Icon'
import React from 'react'
import style from './styles/suite.module.scss'
import Checkbox from '@/components/Checkbox/Checkbox'

interface IProps {
  title: string
  id: number
  casesCount: number
  parent: number | null
  sort: number
  onChange: (value) => void
  checked: boolean
}

const SuiteItem = ({
  checked,
  title,
  id,
  casesCount,
  parent,
  sort,
  onChange
}: IProps): React.ReactElement => {
  return (
    <Checkbox
      checked={checked}
      size='small'
      className={style.checkbox}
      onGx-change={onChange}
    >
      <div className={style.checkbox__item}>
        <Icon src='folders' />

        <div className={style.checkbox__title}>{title}</div>

        <div className={style.checkbox__count}>
          <Icon src='test_case' slot='icon-left' />

          {casesCount}
        </div>
      </div>
    </Checkbox>
  )
}

export default SuiteItem
