import Button from '@/components/Button/Button'
import { PieChart } from '@/components/Charts'
import GroupFullStatuses from '@/components/GroupStatuses/GroupFullStatuses'
import Icon from '@/components/Icon/Icon'
import Title from '@/components/Title'
import Wrapper from '@/components/Wrapper'
import { PIE_CHART_BACKGROUND_COLORS, PIE_CHART_BORDER_COLORS } from '@/const'
import { useObjectPage } from '@/hooks'
import { IReportPage } from '@/interfaces/ObjectPage'
import { downloadBase64File } from '@/utils'
import React, { useRef } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import style from '../style/report_detail.module.scss'

const Result = (): React.ReactElement => {
  const intl = useIntl()
  const { statistics } = useObjectPage<IReportPage>()

  const chartRef = useRef()

  const saveChart = (): void => {
    const chart = chartRef.current
    // @ts-expect-error
    const img = chart.toBase64Image()
    downloadBase64File(img, 'chart.png')
  }

  const chartData = {
    labels: [
      `${intl.formatMessage({
        id: 'status.test.blocked',
        defaultMessage: 'Blocked'
      })}`,
      `${intl.formatMessage({
        id: 'status.test.failed',
        defaultMessage: 'Failed'
      })}`,
      `${intl.formatMessage({
        id: 'status.test.passed',
        defaultMessage: 'Passed'
      })}`,
      `${intl.formatMessage({
        id: 'status.test.retest',
        defaultMessage: 'Retest'
      })}`,
      `${intl.formatMessage({
        id: 'status.test.untested',
        defaultMessage: 'Untested'
      })}`
    ],
    datasets: [
      {
        data: [
          statistics.blocked,
          statistics.failed,
          statistics.passed,
          statistics.retest,
          statistics.untested
        ],
        backgroundColor: PIE_CHART_BACKGROUND_COLORS,
        borderColor: PIE_CHART_BORDER_COLORS,
        borderWidth: 1
      }
    ]
  }

  const completedCount = statistics.count - statistics.untested
  const completedPercent =
    statistics.count !== 0
      ? ((100 / statistics.count) * completedCount).toFixed(0)
      : 0
  const passedPercent =
    statistics.count !== 0
      ? ((100 / statistics.count) * statistics.passed).toFixed(0)
      : 0

  return (
    <Wrapper className={style.block}>
      <Title type='h2'>
        <FormattedMessage
          id='titles.current_result'
          defaultMessage='Current result'
        />
      </Title>

      <div className={style.result__row}>
        <PieChart
          data={chartData}
          className={style.result__chart}
          ref={chartRef}
        />

        <div className={style.result__center}>
          <Button
            theme='light'
            wide
            className={style.result__download}
            onClick={saveChart}
          >
            <Icon src='download' slot='icon-left' />
            <FormattedMessage
              id='reports.download_chart'
              defaultMessage='Download chart'
            />
          </Button>

          <div className={style.result__statuses}>
            <Title type='h3' theme='h4'>
              <FormattedMessage
                id='titles.test_cases'
                defaultMessage='Test checks'
              />
            </Title>

            <div className={style.result__statuslist}>
              <GroupFullStatuses statistic={statistics} />
            </div>
          </div>
        </div>

        <div className={style.result__info}>
          <div className={style.result__percents}>
            <div className={style.percent__item}>
              <span>
                <FormattedMessage
                  id='reports.completed'
                  defaultMessage='Completed'
                />
              </span>
              <span className={style.percent__completed}>
                {completedPercent}%
              </span>
            </div>

            <div className={style.percent__item}>
              <span>
                <FormattedMessage id='reports.passed' defaultMessage='Passed' />
              </span>
              <span className={style.percent__passed}>{passedPercent}%</span>
            </div>
          </div>

          <Title type='h3' theme='h4' className={style.result__cases_title}>
            <FormattedMessage
              id='titles.test_cases'
              defaultMessage='Test checks'
            />
          </Title>

          <div className={style.result__cases}>
            <span className={style.result__cases_completed}>
              {completedCount}
            </span>
            <span>/</span>
            <span>{statistics.count}</span>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default Result
