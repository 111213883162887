import classNames from 'classnames'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import Icon from '../Icon/Icon'
import style from './styles/empty.module.scss'

const EmptySearch = ({ className = '' }): React.ReactElement => {
  return (
    <div
      className={classNames({
        [style.empty]: true,
        [className]: Boolean(className)
      })}
    >
      <Icon src='search' className={style.empty__icon} />
      <p className={style.empty__text}>
        <FormattedMessage
          id='empty_results.search'
          defaultMessage='Nothing found for your request'
        />
      </p>
    </div>
  )
}

export default EmptySearch
