import React from 'react'
import style from './styles/notification_item.module.scss'
import { INotifiesInviteSendedData } from '@/interfaces/Notifies'
import { ITimeSince } from '@/interfaces/Utils'
import NotifyTime from './components/NotifyTime'
import { FormattedMessage } from 'react-intl'
import Tooltip from '../Tooltip'
import { getInitials } from '../UserName'
import { AvatarCompany, AvatarUser } from '../Avatar'
import Link from '../Link'
import useGlobalConfig from '@/hooks/useGlobalConfig'

interface IProps {
  data: INotifiesInviteSendedData
  time: ITimeSince
}

const NotifyCompanyInvite = ({ time, data }: IProps): React.ReactElement => {
  const { company, author } = data
  const initialsUser = getInitials(
    author.first_name,
    author.last_name,
    author.email
  )

  const config = useGlobalConfig()

  const companyUrl =
    data.company?.id !== undefined
      ? `${config.company_list_url}?invite=${data.company.id}`
      : config.company_list_url

  return (
    <>
      <Tooltip content={`${author.first_name} ${author.last_name}`}>
        <AvatarUser
          src={author.avatar}
          className={style.item__avatar}
          initials={initialsUser}
        />
      </Tooltip>

      <div className={style.item__content}>
        <p className={style.info}>
          <FormattedMessage
            id='notifications.item.company_invite'
            defaultMessage='You were <span>invited<span> to the company'
            values={{
              span: (chunks) => (
                <span className={style.invtext__invite}>{chunks}</span>
              )
            }}
          />
        </p>

        <div className={style.run}>
          <AvatarCompany src={company.avatar} />

          <Link to={companyUrl}>{company.title}</Link>
        </div>

        <NotifyTime time={time} />
      </div>
    </>
  )
}

export default NotifyCompanyInvite
