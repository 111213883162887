import Button from '@/components/Button/Button'
import { PieChart } from '@/components/Charts'
import GroupFullStatuses from '@/components/GroupStatuses/GroupFullStatuses'
import Icon from '@/components/Icon/Icon'
import { IValue } from '@/components/Select/interfaces/AsyncSelect'
import RunsSelect from '@/components/Select/RunsSelect'
import Wrapper from '@/components/Wrapper'
import { PIE_CHART_BACKGROUND_COLORS, PIE_CHART_BORDER_COLORS } from '@/const'
import { IStatistics } from '@/interfaces/Runs'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import style from '../styles/milestone_detail.module.scss'

interface IProps {
  id: number
  projectId: number
  setSelectedRun: (value) => void
  statistics: IStatistics
  selectedRun?: IValue
}

const PieChartBlock = ({
  id,
  statistics,
  setSelectedRun,
  selectedRun,
  projectId
}: IProps): React.ReactElement => {
  const intl = useIntl()

  return (
    <Wrapper className={style.chart}>
      <div className={style.chart__content}>
        <div className={style.chart__pie}>
          <PieChart
            data={{
              labels: [
                `${intl.formatMessage({
                  id: 'status.test.blocked',
                  defaultMessage: 'Blocked'
                })}`,
                `${intl.formatMessage({
                  id: 'status.test.failed',
                  defaultMessage: 'Failed'
                })}`,
                `${intl.formatMessage({
                  id: 'status.test.passed',
                  defaultMessage: 'Passed'
                })}`,
                `${intl.formatMessage({
                  id: 'status.test.retest',
                  defaultMessage: 'Retest'
                })}`,
                `${intl.formatMessage({
                  id: 'status.test.untested',
                  defaultMessage: 'Untested'
                })}`
              ],
              datasets: [
                {
                  data: [
                    statistics.blocked,
                    statistics.failed,
                    statistics.passed,
                    statistics.retest,
                    statistics.untested
                  ],
                  backgroundColor: PIE_CHART_BACKGROUND_COLORS,
                  borderColor: PIE_CHART_BORDER_COLORS,
                  borderWidth: 1
                }
              ]
            }}
          />
        </div>

        <div className={style.chart__desc}>
          <RunsSelect
            handleChange={(e) => setSelectedRun(e)}
            milestoneId={id}
            value={selectedRun}
            isClearable
            projectId={projectId}
          />

          <Button theme='light' size='lg' className={style.chart__button}>
            <Icon src='download' slot='icon-left' />

            <FormattedMessage
              id='project.dowmload_chart'
              defaultMessage='Download chart'
            />
          </Button>

          <GroupFullStatuses statistic={statistics} />
        </div>
      </div>
    </Wrapper>
  )
}

export default PieChartBlock
