import React from 'react'
import { Shimmer, ShimmerWrap } from '@/components/Loading'

interface IProps {
  countShimmers: number
  className?: string
}

const LoaderGitlab = ({
  countShimmers,
  className = ''
}: IProps): React.ReactElement => {
  const shimmers = Array.from({ length: countShimmers }, (_, index) => (
    <Shimmer key={index} size='xl' />
  ))

  return <ShimmerWrap className={className}>{shimmers}</ShimmerWrap>
}

export default LoaderGitlab
