import React from 'react'
import BaseModal from './Base'
import { useStores } from '@/hooks'
import { observer } from 'mobx-react'
import ViewCommentsCase from './ModalsContent/ViewCommentsCase'
import ViewCommentsTask from './ModalsContent/ViewCommentsTask'
import ViewRemoveMemberCompany from './ModalsContent/ViewRemoveMemberCompany'
import ViewRemoveMemberInvite from './ModalsContent/ViewRemoveMemberInvite'
import ViewResendMemberInvite from './ModalsContent/ViewResendMemberInvite'
import ViewAddUserCompany from './ModalsContent/ViewAddUserCompany'
import ViewSyncCase from './ModalsContent/ViewSyncCase'
import ViewRemoveMemberProject from './ModalsContent/ViewRemoveMemberProject'
import { TSizeModal } from './interface'

const modals = {
  empty: () => <div>Empty modal</div>,

  // comments
  commentsCase: ViewCommentsCase,
  commentsTask: ViewCommentsTask,

  // companies
  removeMemberCompany: ViewRemoveMemberCompany,
  removeMemberInvite: ViewRemoveMemberInvite,
  resendMemberInvite: ViewResendMemberInvite,
  addUserCompany: ViewAddUserCompany,

  // cases
  syncCases: ViewSyncCase,

  // projects
  removeMemberProject: ViewRemoveMemberProject
}

export const Modals: React.FC = observer(() => {
  const { modalStore } = useStores()
  const CurrentModal = modals[modalStore.currentModal ?? 'empty']
  const afterHideModal = (): void => modalStore.close()
  const isOpen = modalStore.isOpen()

  const sizeModals: { [key in TSizeModal]: string[] } = {
    xl: [],
    large: [],
    medium: ['addUserCompany', 'resendMemberInvite', 'removeMemberInvite'],
    small: []
  }
  const getModalSize = (): TSizeModal | undefined => {
    for (const size in sizeModals) {
      if (
        modalStore.currentModal !== null &&
        sizeModals[size].includes(modalStore.currentModal) === true
      ) return size as TSizeModal
    }
    return undefined
  }
  const size = getModalSize()

  return (
    <BaseModal
      size={size}
      open={isOpen ?? undefined}
      onGx-after-hide={afterHideModal}
    >
      <CurrentModal handleClose={modalStore.close} />
    </BaseModal>
  )
})
