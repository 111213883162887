import React from 'react'
import { Shimmer, ShimmerWrap } from '@/components/Loading'

interface IProps {
  className?: string
}

const LoaderProjectDiagramm = ({
  className = ''
}: IProps): React.ReactElement => {
  return (
    <ShimmerWrap className={className}>

      <Shimmer size='xl' />

      <Shimmer size='xl' />
    </ShimmerWrap>
  )
}

export default LoaderProjectDiagramm
