import React from 'react'
import style from './styles/projectmenu.module.scss'
import { usePage } from '@/hooks'
import { ISidebarLink } from '@/apps/layout/interfaces'
import ProjectMenuLinks from './ProjectMenuLinks'
import ProjectMenuAccordion from './ProjectMenuAccordion'
import { useIntl } from 'react-intl'
import { CAN_VIEW_PERMISSION_LEVEL, USER_PERMISSIONS } from '@/const'
import { useCurrentPermissions } from '@/hooks/useCurrentPermissions'
import ProjectMenuLink from './ProjectMenuLink'

interface IProps {
  isBig: boolean
}

const milestoneLinkKey = 'ProjectPageMilestones'
const runLinkKey = 'ProjectPageRuns'
const casesAndSuitesLinkKey = 'ProjectPageCasesAndSuites'
const historyLinkKey = 'ProjectPageHistory'
const membersLinkKey = 'ProjectPageMembers'

const adminLinksKeys = ['ProjectPageIntegrations', 'ProjectPageRoles']

const otherLinksKeys = ['ProjectPageReports']

const icons = {
  // mainLinksKeys
  ProjectPageMilestones: 'flag',
  // Test plans вставлять сюда
  ProjectPageRuns: 'run',
  ProjectPageCasesAndSuites: 'test_case',

  // adminLinksKeys
  ProjectPageIntegrations: 'integrations',
  ProjectPageRoles: 'test_lead',

  // otherLinksKeys
  ProjectPageMembers: 'tester',
  ProjectPageReports: 'reports',
  ProjectPageHistory: 'history'
}

const ProjectMenu = ({ isBig }: IProps): React.ReactElement => {
  const page = usePage()
  const intl = useIntl()

  const isAdmin = useCurrentPermissions(
    USER_PERMISSIONS.administration,
    CAN_VIEW_PERMISSION_LEVEL
  )

  const isCanUseMilestone = useCurrentPermissions(
    USER_PERMISSIONS.milestones,
    CAN_VIEW_PERMISSION_LEVEL
  )

  const isCanUseRun = useCurrentPermissions(
    USER_PERMISSIONS.runs,
    CAN_VIEW_PERMISSION_LEVEL
  )

  const isCanUseCaseAndSuites = useCurrentPermissions(
    USER_PERMISSIONS.cases,
    CAN_VIEW_PERMISSION_LEVEL
  )

  const isCanUseHistory = useCurrentPermissions(
    USER_PERMISSIONS.history,
    CAN_VIEW_PERMISSION_LEVEL
  )

  const isCanUseMembers = useCurrentPermissions(
    USER_PERMISSIONS.members,
    CAN_VIEW_PERMISSION_LEVEL
  )

  const sidebar = page?.init_state?.sidebar

  const getLinks = (keysArray: string[]): ISidebarLink[] => {
    return keysArray.map((key) => getLink(key))
  }

  const getLink = (key: string): ISidebarLink => {
    return {
      name: sidebar[key].title,
      icon: icons[key],
      href: sidebar[key].absolute_url
    }
  }

  const milestoneLink = getLink(milestoneLinkKey)
  const runLink = getLink(runLinkKey)
  const casesLink = getLink(casesAndSuitesLinkKey)
  const historyLink = getLink(historyLinkKey)
  const membersLink = getLink(membersLinkKey)

  const adminLinks = getLinks(adminLinksKeys)
  const otherLinks = getLinks(otherLinksKeys)

  return (
    <div className={style.projectmenu}>
      {isCanUseMilestone
        ? (
          <ProjectMenuLink
            isBig={isBig}
            name={milestoneLink.name}
            href={milestoneLink.href}
            icon={milestoneLink.icon}
          />
          )
        : null}

      {isCanUseRun
        ? (
          <ProjectMenuLink
            isBig={isBig}
            name={runLink.name}
            href={runLink.href}
            icon={runLink.icon}
          />
          )
        : null}

      {isCanUseCaseAndSuites
        ? (
          <ProjectMenuLink
            isBig={isBig}
            name={casesLink.name}
            href={casesLink.href}
            icon={casesLink.icon}
          />
          )
        : null}

      {isAdmin
        ? (
          <ProjectMenuAccordion
            icon='settings'
            title={intl.formatMessage({
              id: 'sidebar.administration',
              defaultMessage: 'Administration'
            })}
            isBig={isBig}
            defaultOpened={adminLinksKeys.includes(page.page_model)}
            hasActiveLink={adminLinksKeys.includes(page.page_model)}
            storageKey='admin'
          >
            <ProjectMenuLinks linksArray={adminLinks} isBig={isBig} />
          </ProjectMenuAccordion>
          )
        : null}

      <ProjectMenuAccordion
        icon='etc'
        title={intl.formatMessage({
          id: 'sidebar.other',
          defaultMessage: 'Other'
        })}
        isBig={isBig}
        defaultOpened={otherLinksKeys.includes(page.page_model)}
        hasActiveLink={otherLinksKeys.includes(page.page_model)}
        storageKey='other'
      >
        <ProjectMenuLinks linksArray={otherLinks} isBig={isBig} />

        {isCanUseMembers
          ? (
            <ProjectMenuLink
              isBig={isBig}
              name={membersLink.name}
              href={membersLink.href}
              icon={membersLink.icon}
            />
            )
          : null}

        {isCanUseHistory
          ? (
            <ProjectMenuLink
              isBig={isBig}
              name={historyLink.name}
              href={historyLink.href}
              icon={historyLink.icon}
            />
            )
          : null}
      </ProjectMenuAccordion>
    </div>
  )
}

export default ProjectMenu
