import { LineChart } from '@/components/Charts'
import Title from '@/components/Title'
import { CHART_COLORS } from '@/const'
import React, { useRef } from 'react'
import style from '../styles/reports.module.scss'
import { EmptyChart } from '@/components/EmptyResults'

interface IProps {
  title: string
  chartData: number[]
  labels: string[]
}

const StatisticItem = ({
  title,
  chartData,
  labels
}: IProps): React.ReactElement => {
  const chartRef = useRef()

  const datasets = [
    {
      data: chartData,
      borderColor: CHART_COLORS.default,
      backgroundColor: CHART_COLORS.default
    }
  ]

  return (
    <div className={style.stat__item}>
      <Title type='h3'>{title}</Title>

      {datasets.length === 0
        ? (
          <EmptyChart />
          )
        : (
          <LineChart data={{ labels, datasets }} ref={chartRef} />
          )}
    </div>
  )
}

export default StatisticItem
