import React from 'react'
import { FormattedMessage } from 'react-intl'
import style from './styles/step.module.scss'
import HtmlContent from '../HtmlContent/HtmlContent'
import { TCaseType } from '@/interfaces/Types'
import { CASE_TYPES } from '@/const'
import classNames from 'classnames'

interface IStepItemProps {
  stepNumber: number
  description: string
  expectedResult: string
  type: TCaseType
  isEditable?: boolean
}

const StepItem = ({
  stepNumber,
  description,
  expectedResult,
  type
}: IStepItemProps): React.ReactElement => {
  const typeValue = type.toLowerCase()
  return (
    <div
      className={classNames({
        [style.step]: true,
        [style[`step__type--${typeValue}`]]: true
      })}
    >
      <div className={style.step__number}>{stepNumber}</div>

      <div className={style.step__block}>
        <div className={style.step__title}>
          <FormattedMessage id='case.steps.step' defaultMessage='Step' />
        </div>

        <HtmlContent className={style.step__text} content={description} />
      </div>

      {type === CASE_TYPES.case
        ? (
          <div className={style.step__block}>
            <div className={style.step__title}>
              <FormattedMessage
                id='case.steps.result'
                defaultMessage='Expected Result'
              />
            </div>

            <HtmlContent className={style.step__text} content={expectedResult} />
          </div>
          )
        : null}
    </div>
  )
}

export default StepItem
