import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ITextButtonProps } from './interfaces'
import TextButton from './TextButton'

interface IProps extends ITextButtonProps {
  href: string
}

const TextLinkButton = ({
  children,
  href,
  ...props
}: IProps): React.ReactElement => {
  const navigate = useNavigate()

  const handleClick = (e): void => {
    e.preventDefault()
    navigate(href)
  }
  return (
    <TextButton onClick={handleClick} href={href} {...props}>
      {children}
    </TextButton>
  )
}

export { TextLinkButton }
