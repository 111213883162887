import React from 'react'
import Icon from '../Icon/Icon'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import { useStores } from '@/hooks'
import { isToday, isTomorrow, isPast, endOfDay, isValid } from 'date-fns'
import style from './styles/deadline.module.scss'

interface IProps {
  date: string
  className?: string
  isCompleted?: boolean
}

const Deadline = ({ date, isCompleted = false, className = '' }: IProps): React.ReactElement => {
  const store = useStores()
  const deadlineDate = endOfDay(new Date(date))
  if (!isValid(deadlineDate)) {
    return <></>
  }

  const formatedDate = store.localeDate.formatDate(date)

  const dateClasses = classNames({
    [style.date]: true,
    [style['date--yellow']]: isToday(deadlineDate) || isTomorrow(deadlineDate),
    [style['date--red']]: isPast(deadlineDate) && !isCompleted,
    [style['date--grey']]: isPast(deadlineDate) && isCompleted
  })

  const componentClasses = classNames({
    [style.deadline]: true,
    [className]: Boolean(className)
  })

  return (
    <div className={componentClasses}>
      <div className={style.deadline__title}>
        <Icon src='clock' size='xs' slot='icon-left' />

        <FormattedMessage id='common.deadline' defaultMessage='Date to' />
      </div>

      <div className={dateClasses}>{formatedDate}</div>
    </div>
  )
}

export default Deadline
