import React, {
  Suspense,
  forwardRef,
  useImperativeHandle,
  useState
} from 'react'
import { usePage, useStores } from '@/hooks'
import style from '../../styles/update_cases.module.scss'
import useSWR from 'swr'
import { useIntl } from 'react-intl'
import ListContent from '@/components/ListContent'
import Loading from '@/components/Loading/Loading'
import SearchInput from '@/components/Input/SearchInput/SearchInput'
import useDebounce from '@/hooks/useDebounce'
import SuitesDndList from '../../components/SuitesDndList'
import { useNavigate } from 'react-router-dom'
import useSidebar from '@/hooks/useSidebar'
import WithoutSuite from './WithoutSuite'

const Suites = forwardRef(
  (
    props: unknown,
    ref: React.MutableRefObject<{
      handleMutate: () => void
    } | null>
  ): React.ReactElement => {
    const page = usePage()
    const sidebar = useSidebar()
    const { api } = useStores()
    const navigate = useNavigate()

    const [search, setSearch] = useState('')

    const debouncedSearch = useDebounce(search)

    const intl = useIntl()

    const runId = page.init_state.object.id

    const { data, error, isLoading, isValidating, mutate } = useSWR(
      {
        runId: runId,
        q: debouncedSearch,
        _key: 'getSuitesInRunUpdate'
      },
      api.getSuitesInRun,
      {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false
      }
    )

    const { data: casesWithoutSuites, mutate: mutateWithoutSuite } = useSWR(
      {
        page: 1,
        page_size: 1,
        id: runId,
        without_suite: true,
        _key: 'getSuitesInRunUpdate'
      },
      api.getCasesInRun
    )
    const handleMutate = async (): Promise<void> => {
      await mutate()
      await mutateWithoutSuite()
    }

    const handleNavigateCreateSuite = (): void => {
      const createSuite: string = `${sidebar.ProjectPageCasesAndSuites.absolute_url}?createSuite=true`
      navigate(createSuite)
    }

    useImperativeHandle(ref, () => ({ handleMutate }))

    return (
      <div className={style.suites}>
        <SearchInput
          handleChange={setSearch}
          value={search}
          placeholder={intl.formatMessage({
            id: 'suites.search',
            defaultMessage: 'Search suites'
          })}
        />

        <Suspense fallback={<Loading />}>
          <div className={style.suites__tree}>
            <ListContent
              isLoading={isLoading || isValidating}
              error={error}
              hasData={
                (data !== undefined && data?.length > 0) ||
                (casesWithoutSuites !== undefined &&
                  casesWithoutSuites.count > 0)
              }
              emptyListIcon='folders'
              emptyListText={intl.formatMessage({
                id: 'suites.no_suites',
                defaultMessage: 'No suites has been found'
              })}
              emptyListButton={intl.formatMessage({
                id: 'suites.add_suite',
                defaultMessage: 'Add test suite'
              })}
              onClick={handleNavigateCreateSuite}
              classNameError={style.dnd__error}
            >
              {data !== undefined
                ? (
                  <div className={style.dnd__list}>
                    {casesWithoutSuites?.count !== undefined &&
                  casesWithoutSuites?.count > 0
                      ? (
                        <WithoutSuite caseCount={casesWithoutSuites?.count} />
                        )
                      : null}
                    <SuitesDndList data={data} />
                  </div>
                  )
                : null}
            </ListContent>
          </div>
        </Suspense>
      </div>
    )
  }
)

export default Suites
