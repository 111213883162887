import classNames from 'classnames'
import React from 'react'
import { useIntl } from 'react-intl'
import Icon from '../Icon/Icon'
import style from './styles/button.module.scss'

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: () => void
  size?: 'xs' | 'small' | 'medium' | 'large' | 'xl'
}

const DeleteButton = ({
  className = '',
  size = 'medium',
  onClick,
  type = 'button',
  ...props
}: IProps): React.ReactElement => {
  const intl = useIntl()

  const ariaLabel = intl.formatMessage({
    id: 'common.delete',
    defaultMessage: 'Delete'
  })

  return (
    <button
      className={classNames({
        [style.delete]: true,
        [style[`delete--${size}`]]: true,
        [className]: Boolean(className)
      })}
      onClick={onClick}
      type={type}
      aria-label={ariaLabel}
      {...props}
    >
      <Icon src='clear' size={size} />
    </button>
  )
}

export default DeleteButton
