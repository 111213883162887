import React from 'react'
import Pagination from '@/components/Pagination'
import PaginatedQueryResult from '@/api/interfaces/PaginatedQueryResult'
import { IMilestonesList } from '@/interfaces/Milestones'
import { MilestoneItem } from '@/components/MilestoneItem'
import style from './styles/milestonelist.module.scss'

interface IProps {
  data: PaginatedQueryResult<IMilestonesList>
  currentPage: number
  pageSize: number
  setPage: (string) => void
}

const MilestoneList = ({
  data,
  currentPage,
  pageSize,
  setPage
}: IProps): React.ReactElement => {
  return (
    <>
      <div className={style.milestonelist__list}>
        {data.results.map((el) => {
          return (
            <MilestoneItem
              absoluteUrl={el.absolute_url}
              key={el.id}
              title={el.title}
              casesCount={el.cases_count}
              runsCount={el.runs_count}
              id={el.id}
              endDate={el.end_date}
              color={el.color}
              progress={el.progress}
              code={el.code}
            />
          )
        })}
      </div>

      <div className={style.milestonelist__pagination}>
        <Pagination
          currentPage={currentPage}
          total={data.count}
          pageSize={pageSize}
          handleChange={setPage}
        />
      </div>
    </>
  )
}

export default MilestoneList
