import { StylesConfig } from 'react-select'

const customSelectStyles: StylesConfig<any, boolean> = {
  control: (styles, { isFocused }) => ({
    ...styles,
    minHeight: 'var(--gx-input-height-medium)',
    borderRadius: 'var(--gx-input-height-medium)',
    backgroundColor: 'var(--gx-input-background-color)',
    border: isFocused
      ? '1px solid var(--color-violet)'
      : '1px solid var(--color-inputs-light)',
    cursor: 'pointer',
    fontSize: 'var(--gx-input-font-size-medium)',
    boxShadow: 'none',
    transition: 'var(--transition-fast)',

    overflow: 'hidden',
    ':hover': {
      ...styles[':hover'],
      borderColor: 'var(--color-violet)'
    },
    width: '100%'
  }),
  placeholder: (styles) => ({
    ...styles,
    color: 'var(--color-inputs-light)'
  }),
  valueContainer: (styles, { isMulti }) => ({
    ...styles,
    flexWrap: isMulti ? 'wrap' : 'nowrap'
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: 'none'
  }),
  container: (styles) => ({
    ...styles,
    transition: 'var(--transition-fast)'
  }),
  menu: (styles) => ({
    ...styles,
    boxShadow: 'var(--shadow-menu)',
    backgroundColor: 'var(--color-white)',
    transition: 'var(--transition-fast)',
    borderRadius: 'var(--border-radius-default)',
    border: '1px solid var(--color-inputs-light)',
    padding: '5px 0',
    overflow: 'hidden'
  }),
  menuPortal: (styles) => ({
    ...styles,
    transition: 'var(--transition-fast)',
    zIndex: 'calc(var(--index-modal) + 1)'
  }),
  menuList: (styles) => ({
    ...styles,
    maxHeight: '200px',
    overflow: 'auto',
    padding: '0',
    transition: 'var(--transition-fast)',
    scrollbarWidth: 'thin',
    scrollbarColor:
      'var(--gx-scrollbar-thumb-color) var(--gx-scrollbar-track-color)',
    '::-webkit-scrollbar': {
      width: '8px',
      height: '8px',
      backgroundColor: 'var(--gx-scrollbar-track-color)'
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: 'var(--gx-scrollbar-thumb-color)',
      borderRadius: '4px'
    }
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected, isMulti }) => {
    return {
      ...styles,
      display: 'flex',
      padding: 'var(--gx-spacing-x-small) var(--gx-spacing-x-large) ',
      transition: 'var(--transition-fast)',
      color: 'var(--color-black)',
      backgroundColor: 'var(--color-bg)',
      cursor: 'pointer',
      wordBreak: 'break-word',
      '::before': {
        ...styles['::before'],
        content: "''",
        display: isMulti ? 'block' : 'none',
        width: '20px',
        height: '20px',
        borderRadius: '6px',
        border: '1px solid var(--color-inputs-light)',
        backgroundColor: isSelected ? 'var(--color-violet)' : 'transparent',
        backgroundImage: isSelected ? 'url("/src/images/icons/Ok.svg")' : '',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        marginRight: '10px',
        flexShrink: 0
      },
      ':hover': {
        ...styles[':hover'],
        color: 'var(--color-black)',
        backgroundColor: 'var(--color-light)'
      }
    }
  },
  loadingIndicator: (styles) => ({
    ...styles,
    color: 'var(--color-violet)'
  }),
  singleValue: (styles) => ({
    ...styles,
    color: 'var(--color-black)'
  }),
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: 'var(--color-gray-light)',
    borderRadius: '20px'
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: 'var(--color-status-deleted)',

    ':hover': {
      ...styles[':hover'],
      backgroundColor: 'transparent'
    }
  })
}

export default customSelectStyles
