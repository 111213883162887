import React from 'react'
import style from '../styles/gitlab.module.scss'
import Input from '@/components/Input/Input'
import { useFormContext } from 'react-hook-form'
import { IGitlabFormData } from '../interfaces'
import { Form } from '@/components/Form'
import Button from '@/components/Button/Button'
import Icon from '@/components/Icon/Icon'
import { FormattedMessage } from 'react-intl'
import { KeyedMutator } from 'swr'
import { IProject } from '@/interfaces/Project'
import { useObjectPage, useStores } from '@/hooks'
import { useIntegrationStatus } from '@/apps/Integrations/context'

interface IGitlabForm {
  setGitlabIntegration: (enable: boolean) => Promise<void>
  projectMutation: KeyedMutator<IProject>
}

const GitlabIntegrationForm = ({
  setGitlabIntegration,
  projectMutation
}: IGitlabForm): JSX.Element => {
  const { id } = useObjectPage()
  const { setGitlabIntegrationStatus } = useIntegrationStatus()

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors }
  } = useFormContext<IGitlabFormData>()
  const { api } = useStores()

  const onSubmit = async (): Promise<void> => {
    await setGitlabIntegration(true)
    await projectMutation()
  }

  const handleClearClick = async (): Promise<void> => {
    const dataToClear = {
      gitlab_enable: false,
      gitlab_url: '',
      gitlab_id: null,
      gitlab_group: '',
      gitlab_token: ''
    }
    reset(dataToClear)
    await api.postGitlabSettings(id, dataToClear)
    await projectMutation()
    setGitlabIntegrationStatus(null)
  }

  return (
    <>
      <hr className={style.line__horizontal} />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className={style.form}>
          <div className={style.form__left}>
            <Input
              label='GitLab URL'
              required
              className={style.form__block}
              {...register('gitlab_url', { required: true })}
              error={errors.gitlab_url?.message}
            />

            <Input
              label='GitLab ID'
              required
              className={style.form__block}
              {...register('gitlab_id', { required: true })}
              error={errors.gitlab_id?.message}
            />

            <Input
              label='GitLab Group'
              required
              className={style.form__block}
              {...register('gitlab_group', { required: true })}
              error={errors.gitlab_group?.message}
            />

            <Input
              label='GitLab Token'
              required
              className={style.form__block}
              {...register('gitlab_token', { required: true })}
              error={errors.gitlab_token?.message}
            />
          </div>

          <div className={style.form__right}>
            <div className={style.form__btns}>
              <div className={style.btns}>
                <Button theme='light' onClick={handleClearClick}>
                  <Icon src='broom' slot='icon-left' />
                  <FormattedMessage id='common.clear' defaultMessage='Clear' />
                </Button>

                <Button type='submit'>
                  <Icon src='checked' slot='icon-left' />
                  <FormattedMessage id='common.save' defaultMessage='Save' />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </>
  )
}

export default GitlabIntegrationForm
