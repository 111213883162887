import { LinkButton } from '@/components/Button/LinkButton'
import Icon from '@/components/Icon/Icon'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import style from '../styles/registration.module.scss'

interface IProps {
  hrefRedirect: string
}

const Success = ({ hrefRedirect }: IProps): React.ReactElement => {
  return (
    <div className={style.form__success}>
      <div className={style.form__icon}>
        <Icon src='checked' className={style.form__bigicon} />
      </div>

      <div className={style.form__successtext}>
        <FormattedMessage
          id='profile.change_email.success'
          defaultMessage='Your new email has been successfully confirmed!'
        />
      </div>

      <LinkButton href={hrefRedirect} size='lg'>
        <Icon src='arrow_right' slot='icon-left' />

        <FormattedMessage
          id='profile.change_email.account'
          defaultMessage='To account'
        />
      </LinkButton>
    </div>
  )
}

export default Success
