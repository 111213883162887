import { Api } from '@/api'
import { RootStore } from './Root'
import { makeAutoObservable } from 'mobx'
import { IRole } from '@/interfaces/Company'

export class RolesStore {
  rootStore: RootStore
  api: Api
  roles: IRole[]

  constructor (rootStore: RootStore) {
    this.rootStore = rootStore
    this.api = this.rootStore.api
    this.roles = []

    makeAutoObservable(this)
  }

  initRoles = async (): Promise<void> => {
    if (!this.api.isAuthToken()) {
      return
    }
    const roles = await this.api.getCompanyRoles({ page_size: 1000, page: 1 })
    this.roles = roles.results
  }

  getRoleByID = (id: number): IRole | undefined => {
    return this.roles.find((el) => el.id === id)
  }
}
