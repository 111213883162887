import classNames from 'classnames'
import React from 'react'
import { useIntl } from 'react-intl'
import style from '../styles/status.module.scss'
import { TBadgeStatus } from '../../../interfaces/Types'

interface IProps {
  status: TBadgeStatus
  count?: number
  countOnly?: boolean
  className?: string
}

const BadgeStatus = ({
  status,
  count,
  countOnly = false,
  className = ''
}: IProps): React.ReactElement => {
  const intl = useIntl()
  const stat = status !== undefined ? status : ''
  const statusLowerCase = stat.toLowerCase()

  const defaultMessage = stat.charAt(0) + statusLowerCase.slice(1)

  const statusText = intl.formatMessage({
    id: `status.test.${statusLowerCase}`,
    defaultMessage
  })

  return (
    <div
      className={classNames({
        [style.test]: true,
        [style.status]: true,
        [style[`test--${statusLowerCase}`]]: true,
        [style['test--count']]: countOnly,
        [className]: Boolean(className)
      })}
    >
      {count !== undefined ? <span>{count}</span> : null}
      {!countOnly
        ? (
          <span className={style.test__text}>{statusText}</span>
          )
        : null}
    </div>
  )
}

export default BadgeStatus
