import React, { Dispatch, ReactElement, useEffect, useState } from 'react'
import Button from '@/components/Button/Button'
import Icon from '@/components/Icon/Icon'
import Title from '@/components/Title'
import { TextTag } from '@/components/Tag'
import { FormattedMessage, useIntl } from 'react-intl'
import style from './index.module.scss'
import useSWR from 'swr'
import useDebounce from '@/hooks/useDebounce'
import { useStores } from '@/hooks'
import SearchInput from '@/components/Input/SearchInput/SearchInput'
import CompanyUser from '@/apps/AddOrEditProject/components/CompanyUser'
import Pagination from '@/components/Pagination'
import { IProjectMember, IProjectRoleShort } from '@/interfaces/Member'
import { observer } from 'mobx-react'
import BaseModal from '@/components/Modal/Base'
import { LoaderAddMembers } from '../../apps/Members/loaders'
import { IUpdateMembers } from '@/interfaces/ApiParams'
import { ICompanyUser } from '@/interfaces/Company'
import { EmptySearch } from '../EmptyResults'

interface IProps {
  title: ReactElement
  projectCompany: number
  projectId: number
  projectMembers: IProjectMember[]
  roles: IProjectRoleShort[]
  onClose: () => void
  handleUpdateProject: () => void
  baseModalProps: {
    open: boolean
    hideDefaultClose?: boolean
    'onGx-after-hide'?: () => void
  }
  setMember: Dispatch<React.SetStateAction<IUpdateMembers[]>>
  newMembers: IUpdateMembers[]
  setUsersNotInProject: Dispatch<React.SetStateAction<ICompanyUser[]>>
}

const PAGE_SIZE = 10

const AddMembers = ({
  handleUpdateProject,
  onClose,
  roles,
  title,
  projectCompany,
  projectId,
  projectMembers,
  baseModalProps,
  setMember,
  newMembers,
  setUsersNotInProject
}: IProps): React.ReactElement => {
  const intl = useIntl()
  const [search, setSearch] = useState('')
  const [companyUsers, setCompanyUsers] = useState<ICompanyUser[]>([])
  const debouncedSearch = useDebounce(search)
  const { api } = useStores()
  const [page, setPage] = useState(1)

  const { data, isLoading } = useSWR(
    {
      page: page,
      page_size: PAGE_SIZE,
      company_id: projectCompany,
      project_id: projectId,
      q: debouncedSearch,
      _key: 'getCompanyUsersNotInProject'
    },
    api.getCompanyUsersNotInProject
  )

  const handleSearch = (value: string): void => {
    setSearch(value)
    setPage(1)
  }

  const handleRemoveAllSelected = (): void => {
    setMember([])
  }

  const handleCloseModal = (): void => {
    setMember([])
    baseModalProps['onGx-after-hide']?.()
  }

  useEffect(() => {
    if (data !== undefined) {
      setCompanyUsers(data.results)
    }
  }, [data])

  useEffect(() => {
    if (companyUsers !== undefined) {
      setUsersNotInProject(
        companyUsers.filter((el) => {
          const memberIndex = newMembers.findIndex(
            (index) => index.id === el.user.id
          )
          return (
            newMembers[memberIndex] !== undefined &&
              newMembers[memberIndex].abac_role === undefined
          )
        })
      )
    }
  }, [newMembers, companyUsers])

  return (
    <BaseModal {...baseModalProps} onGx-after-hide={handleCloseModal}>
      <div className={style.modal}>
        <div className={style.modal__head}>
          <div className={style.modal__info}>
            <Button onClick={onClose} theme='light' circle>
              <Icon src='back' />
            </Button>

            <Title type='h2' className={style.modal__title}>
              {title}
            </Title>

            {newMembers.length > 0
              ? (
                <TextTag
                  className={style.modal__count__item}
                  size='large'
                  isDeletable
                  onDelete={handleRemoveAllSelected}
                >
                  {newMembers.length}
                  <Icon src='user' />
                </TextTag>
                )
              : null}
          </div>

          <Button onClick={handleUpdateProject}>
            <Icon src='checked' slot='icon-left' />
            <FormattedMessage id='common.add' defaultMessage='Add' />
          </Button>
        </div>

        <div className={style.modal__filters}>
          <SearchInput
            value={search}
            handleChange={handleSearch}
            placeholder={intl.formatMessage({
              id: 'project.form.search_user',
              defaultMessage: 'Search user'
            })}
            className={style.modal__search}
          />
        </div>

        <div className={style.modal__wrap}>
          {isLoading ? <LoaderAddMembers countShimmers={2} /> : null}

          {!isLoading && data?.count !== undefined && data?.count > 0
            ? (
              <>
                {companyUsers?.map((companyUser) => (
                  <CompanyUser
                    key={companyUser.id}
                    companyUser={companyUser}
                    projectMembers={projectMembers}
                    projectRoles={roles}
                    newMembers={newMembers}
                    setMember={setMember}
                  />
                ))}
              </>
              )
            : null}

          {!isLoading && data?.count !== undefined && data?.count === 0
            ? <EmptySearch />
            : null}
        </div>

        <Pagination
          currentPage={page}
          total={data?.count ?? 0}
          pageSize={PAGE_SIZE}
          handleChange={setPage}
        />
      </div>
    </BaseModal>
  )
}

export default observer(AddMembers)
