import React from 'react'
import style from './styles/notification_item.module.scss'
import { INotifiesInviteTimeoutData } from '@/interfaces/Notifies'
import { ITimeSince } from '@/interfaces/Utils'
import NotifyTime from './components/NotifyTime'
import { FormattedMessage } from 'react-intl'
import NotifyCircle from './components/NotifyCircle'
import Button from '../Button/Button'
import Icon from '../Icon/Icon'
import { AvatarUser } from '../Avatar'
import Link from '../Link'
import { useStores } from '@/hooks'
import { getInitials } from '../UserName'

interface IProps {
  data: INotifiesInviteTimeoutData
  time: ITimeSince
}

const NotifyInviteTimeout = ({ time, data }: IProps): React.ReactElement => {
  const { company, invite, invite_user: inviteUser } = data

  const { api } = useStores()

  const initialsUser =
    inviteUser?.first_name !== undefined && inviteUser?.last_name !== undefined
      ? getInitials(inviteUser.first_name, inviteUser.last_name)
      : getInitials('', '', invite.email)

  const handleResendInvite = async (): Promise<void> => {
    try {
      await api.sendInviteCompany(
        {
          email: invite.email,
          role: invite.role
        },
        company.id
      )
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <>
      <NotifyCircle />

      <div className={style.item__content}>
        <p className={style.info}>
          <FormattedMessage
            id='notifications.item.invite_timeout'
            defaultMessage='Invitation of company <company></company> <text>timed out</text>'
            values={{
              text: (chunks) => (
                <span className={style.invtext__decline}>{chunks}</span>
              ),
              company: () => (
                <Link
                  to={company.absolute_url ?? '/'}
                  className={style.invtext__company}
                >
                  {company.title}
                </Link>
              )
            }}
          />
        </p>

        <div className={style.run}>
          <AvatarUser
            size='small'
            initials={initialsUser}
            src={inviteUser?.avatar}
          />
          <span className={style.run__name}>{invite.email}</span>

          <Button
            circle
            size='sm'
            className={style.run__btn}
            onClick={handleResendInvite}
          >
            <Icon src='resend' />
          </Button>
        </div>

        <NotifyTime time={time} />
      </div>
    </>
  )
}

export default NotifyInviteTimeout
