import React from 'react'
import style from '../styles/notifications.module.scss'
import Icon from '@/components/Icon/Icon'
import { FormattedMessage } from 'react-intl'

const EmptyNotifications = (): React.ReactElement => {
  return (
    <div className={style.empty}>
      <Icon src='notification' size='xxxl' className={style.empty__icon} />

      <p className={style.empty__title}>
        <FormattedMessage
          id='notifications.empty.title'
          defaultMessage='No notifications to show yet'
        />
      </p>
      <p className={style.empty__text}>
        <FormattedMessage
          id='notifications.empty.text'
          defaultMessage='You will see useful information here'
        />
      </p>
    </div>
  )
}

export default EmptyNotifications
