import classNames from 'classnames'
import React from 'react'
import style from './styles/lang-switch.module.scss'
import Icon from '../Icon/Icon'

interface IProps {
  currentValue: boolean
  setCurrentValue: (value: boolean) => void
  leftIcon: string
  rightIcon: string
  theme?: 'white' | 'light'
  className?: string
}

const SwitchIcon = ({
  currentValue,
  setCurrentValue,
  leftIcon,
  rightIcon,
  theme = 'light',
  className = ''
}: IProps): React.ReactElement => {
  return (
    <div
      className={classNames({
        [style.switch]: true,
        [style[`switch--${theme}`]]: Boolean(theme),
        [style['switch--circle']]: true,
        [className]: Boolean(className)
      })}
    >
      <input
        className={style.switch__checkbox}
        id='switch'
        type='checkbox'
        checked={currentValue}
        onChange={({ target }) => {
          setCurrentValue(target.checked)
        }}
      />
      <label
        htmlFor='switch'
        className={classNames({
          [style.switch__label]: true,
          [style['switch__label--circle']]: Boolean(theme)
        })}
      >
        <span
          className={classNames({
            [style.switch__thumb]: true,
            [style['switch__thumb--circle']]: true
          })}
        />

        <Icon
          src={leftIcon}
          className={classNames({
            [style.icon]: true,
            [style.icon__left]: true,
            [style.icon__checked]: !currentValue
          })}
        />

        <Icon
          src={rightIcon}
          className={classNames({
            [style.icon]: true,
            [style.icon__right]: true,
            [style.icon__checked]: currentValue
          })}
        />
      </label>
    </div>
  )
}

export default SwitchIcon
