import Button from '@/components/Button/Button'
import { LineChart } from '@/components/Charts'
import useSWR from 'swr'
import Icon from '@/components/Icon/Icon'
import MilestoneSelect from '@/components/Select/MilestoneSelect/MilestoneSelect'
import PeriodSelect from '@/components/Select/PeriodSelect'
import RunsSelect from '@/components/Select/RunsSelect'
import Wrapper from '@/components/Wrapper'
import { useObjectPage, usePage, useStores } from '@/hooks'
import { downloadBase64File, getLastDayStatistic } from '@/utils'
import React, { useEffect, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import style from '../styles/project_detail.module.scss'
import { TCaseStatusInRun } from '@/interfaces/Types'
import { CHART_COLORS } from '@/const'
import { IPeriodSelectValue } from '@/components/Select/interfaces/SelectProps'
import { initialDates } from '@/components/Select/utils'
import GroupFullStatuses from '@/components/GroupStatuses/GroupFullStatuses'
import { EmptyChart } from '@/components/EmptyResults'
import Title from '@/components/Title'
import { useNavigate } from 'react-router-dom'
import { LoaderProjectDiagramm } from '../loaders'

interface IDataset {
  label: TCaseStatusInRun
  data: number[]
  borderColor: string
  backgroundColor: string
}

const MainInfo = (): React.ReactElement => {
  const [milestoneId, setMilestoneId] = useState<number>()
  const [runId, setRunId] = useState<number>()
  const [dates, setDates] = useState<IPeriodSelectValue>(initialDates())

  const runsSelectRef = useRef<any>()
  const chartRef = useRef()
  const navigate = useNavigate()
  const store = useStores()
  const page = useObjectPage()
  const obj = usePage()

  const notFoundUrl = obj.init_state.global.not_found_url
  const currentUser = obj.init_state.project_object.current_user
  const projectId = obj.init_state.project_object.id

  useEffect(() => {
    if (currentUser === null) {
      navigate(notFoundUrl)
    }
  }, [currentUser])

  const saveChart = (): void => {
    const chart = chartRef.current
    // @ts-expect-error
    const img = chart.toBase64Image()
    downloadBase64File(img, 'chart.png')
  }

  const handleChangeMilestone = (e): void => {
    if (e !== null) {
      setMilestoneId(e.value)
      runsSelectRef?.current?.clearValue()
    } else {
      setMilestoneId(undefined)
      runsSelectRef?.current?.clearValue()
    }
  }

  const handleChangeRun = (e): void => {
    if (e !== null) {
      setRunId(e.value)
    } else {
      setRunId(undefined)
    }
  }

  const handlechangeDates = (value: IPeriodSelectValue): void => {
    setDates(value)
  }

  const { data, isLoading } = useSWR(
    {
      id: page.id,
      run: runId,
      milestone: milestoneId,
      project: projectId,
      date_from: dates.start,
      date_to: dates.end,
      _key: 'getProjectReport'
    },
    store.api.getProjectReport
  )

  const labels = data?.activity.labels ?? []

  const datasets: IDataset[] =
    data?.activity?.datasets != null
      ? data.activity.datasets.map((el): IDataset => {
        return {
          label: el.label,
          data: el.data,
          borderColor: CHART_COLORS.status[el.label.toLowerCase()].opaque,
          backgroundColor:
              CHART_COLORS.status[el.label.toLowerCase()].opacity
        }
      })
      : []

  const lastDayDate = labels.length > 0 ? labels[labels.length - 1] : ''

  const statistic = getLastDayStatistic(data?.activity?.datasets ?? [])

  return (
    <Wrapper className={style.info}>
      <div className={style.info__left}>
        {isLoading ? <LoaderProjectDiagramm /> : null}
        {datasets.length === 0 && !isLoading ? <EmptyChart /> : null}
        {datasets.length !== 0 && !isLoading
          ? (
            <LineChart data={{ labels, datasets }} ref={chartRef} />
            )
          : null}
      </div>

      <div className={style.info__right}>
        <div className={style.info__filters}>
          <MilestoneSelect
            handleChange={handleChangeMilestone}
            projectId={page.id}
            isClearable
          />

          <RunsSelect
            handleChange={handleChangeRun}
            milestoneId={milestoneId}
            isClearable
            ref={runsSelectRef}
            // key - хак для принудительной перезагрузки опций
            key={milestoneId ?? 'runs_select'}
            projectId={projectId}
          />
          <PeriodSelect
            className={style.members__filter}
            handleChange={handlechangeDates}
          />

          <Button
            theme='light'
            className={style.info__download}
            onClick={saveChart}
          >
            <Icon src='download' slot='icon-left' />
            <FormattedMessage
              id='project.dowmload_chart'
              defaultMessage='Download chart'
            />
          </Button>
        </div>

        <div className={style.info__statuses}>
          {data?.statistics !== undefined
            ? (
              <>
                <Title type='h3' className={style.info__title}>
                  <FormattedMessage
                    id='project.statistic.status'
                    defaultMessage='Status on {date}'
                    values={{ date: lastDayDate }}
                  />
                </Title>
                <GroupFullStatuses statistic={statistic} />
              </>
              )
            : null}
        </div>
      </div>
    </Wrapper>
  )
}

export default MainInfo
