import Container from '@/components/Container'
import Wrapper from '@/components/Wrapper'
import React, { useEffect, useRef, useState } from 'react'
import style from './styles/project_roles.module.scss'
import useSWR from 'swr'
import Title from '@/components/Title'
import { FormattedMessage, useIntl } from 'react-intl'
import Button from '@/components/Button/Button'
import Icon from '@/components/Icon/Icon'
import { useObjectPage, useStores } from '@/hooks'
import PermissionsDropdown from './components/PermissionsDropdown'
import { IRolesTableStructure, ITableHead } from './interfaces'
import RoleHead from './components/RoleHead'
import useModal from '@/hooks/useModal'
import BaseModal from '@/components/Modal/Base'
import DeleteRole from './components/DeleteRole'
import ResetRole from './components/ResetRole'
import LoaderProjectRoles from './loaders/LoaderProjectRoles'
import { TPermissionKey } from '@/interfaces/Types'
import AddRole from './components/AddRole'
import { getTrimedString } from '@/utils'

interface IEditRole {
  roleId: number
  title: string
}

const ProjectRoles = (): React.ReactElement => {
  const [isLoading, setIsLoading] = useState(true)
  const [tableHead, setTableHead] = useState<ITableHead[]>([])
  const [rolesStructure, setRolesStructure] = useState<IRolesTableStructure[]>(
    []
  )
  const [editRole, setEditRole] = useState<IEditRole | undefined>()
  const [errors, setErrors] =
    useState<{ [key: string]: string[] } | undefined>()

  const tableWrapRef = useRef<HTMLDivElement>(null)
  const { id: projectId } = useObjectPage()
  const { api } = useStores()
  const intl = useIntl()

  const { isOpen, handleOpenModal, handleCloseModal } = useModal()
  const {
    isOpen: isResetOpen,
    handleOpenModal: handleResetOpen,
    handleCloseModal: handleResetClose
  } = useModal()
  const {
    isOpen: isAddRoleOpen,
    handleOpenModal: handleAddRoleOpen,
    handleCloseModal: handleAddRoleClose
  } = useModal()

  const handleResetRoles = async (): Promise<void> => {
    await api.resetProjectRole(projectId)
    await updateTable()
    handleResetClose()
  }

  const handleAddRole = async (roleName: string): Promise<void> => {
    setErrors(undefined)
    try {
      await api.createProjectRole(projectId, {
        title: roleName,
        permissions_list: []
      })

      await updateTable()
      handleAddRoleClose()
      tableScrollToRight()
    } catch (error) {
      setErrors(error.response.data)
    }
  }

  const handleRenameRole = async (roleName: string): Promise<void> => {
    if (editRole !== undefined) {
      try {
        const trimRoleName = getTrimedString(roleName)
        await api.changeProjectRolesPermission(projectId, editRole.roleId, {
          title: trimRoleName,
          permissions_list: []
        })
        await updateTable()
        handleAddRoleClose()
        setEditRole(undefined)
      } catch (error) {
        setErrors(error.response.data)
      }
    }
  }

  const handleDeleteRole = async (newRole: number): Promise<void> => {
    if (editRole !== undefined) {
      await api.deleteProjectRole(projectId, editRole.roleId, {
        new_role: newRole
      })
      await updateTable()
      handleCloseModal()
    }
  }

  const openAddRoleModal = (): void => {
    handleAddRoleOpen()
  }

  const openRenameRoleModal = (roleId: number, title: string): void => {
    setEditRole({ roleId, title })
    handleAddRoleOpen()
  }

  const openDeleteModal = (roleId: number, title: string): void => {
    setEditRole({ roleId, title })
    handleOpenModal()
  }

  const closeDeleteModal = (): void => {
    setEditRole(undefined)
    setEditRole(undefined)
    handleCloseModal()
  }

  const closeAddRoleModal = (): void => {
    setErrors(undefined)
    setEditRole(undefined)
    handleAddRoleClose()
  }

  const tableScrollToRight = (): void => {
    // setTimeout чтобы таблица точно после рендера проскроллилась до правого края
    setTimeout(() => {
      if (tableWrapRef.current !== null) {
        tableWrapRef.current.scrollLeft = tableWrapRef.current?.scrollWidth
      }
    }, 0)
  }

  const {
    data: permissions,
    isLoading: permissonsLoading,
    mutate: permissionsMutate
  } = useSWR(
    'getProjectRolesPermissions',
    async () => await api.getProjectRolesPermissions(projectId)
  )

  const {
    data: roles,
    isLoading: rolesLoading,
    mutate: rolesMutate
  } = useSWR('getProjectRoles', async () => {
    const roles = await api.getProjectRoles(projectId, {
      page_size: 1000,
      page: 1
    })
    return roles.results
  })

  const updateTable = async (): Promise<void> => {
    await Promise.all([permissionsMutate(), rolesMutate()])
  }

  const permissionTitle = intl.formatMessage({
    id: 'permission.table.title',
    defaultMessage: 'Permission'
  })

  useEffect(() => {
    if (permissions !== undefined && roles !== undefined) {
      const head = [{ title: permissionTitle, isProtected: true, roleId: 0 }]
      roles.forEach((el) => {
        head.push({
          title: el.title,
          isProtected: el.is_protected,
          roleId: el.id
        })
      })
      setTableHead(head)

      const structure: IRolesTableStructure[] = []

      for (const key in permissions) {
        const values = roles.map((el) => {
          return {
            level: el.permissions[key],
            roleId: el.id,
            isProtected: el.is_protected
          }
        })

        const permission: IRolesTableStructure = {
          title: permissions[key].title,
          allowed_permissions: permissions[key].allowed_permissions,
          values: values,
          permissionName: key as TPermissionKey
        }

        structure.push(permission)
      }

      setRolesStructure(structure)
      setIsLoading(false)
    }
  }, [permissonsLoading, rolesLoading, permissions, roles])

  return (
    <Container>
      <Wrapper>
        <div className={style.head}>
          <Title className={style.title}>
            <FormattedMessage
              id='titles.project_roles'
              defaultMessage='Roles'
            />
          </Title>

          <Button
            theme='light'
            onClick={handleResetOpen}
            disabled={rolesStructure.length === 0}
          >
            <Icon src='broom' slot='icon-left' />
            <FormattedMessage
              id='roles.reset_roles'
              defaultMessage='Reset roles'
            />
          </Button>

          <Button
            onClick={openAddRoleModal}
            disabled={rolesStructure.length === 0}
          >
            <Icon src='plus' slot='icon-left' />
            <FormattedMessage id='roles.add_role' defaultMessage='Add role' />
          </Button>
        </div>

        {isLoading
          ? (
            <LoaderProjectRoles />
            )
          : (
            <div className={style.tablewrap} ref={tableWrapRef}>
              <table className={style.table}>
                <thead>
                  <tr className={style.table__tr}>
                    {tableHead.map((el, i) => {
                      return (
                        <RoleHead
                          title={el.title}
                          key={i}
                          isProtected={el.isProtected}
                          roleId={el.roleId}
                          updateTable={updateTable}
                          handleDeleteRole={openDeleteModal}
                          handleRenameRole={openRenameRoleModal}
                        />
                      )
                    })}
                  </tr>
                </thead>
                <tbody className={style.table__body}>
                  {rolesStructure.map((perm) => {
                    return (
                      <tr key={perm.title} className={style.table__tr}>
                        <td className={style.table__td}>
                          <div className={style.table__td_block}>
                            {perm.title}
                          </div>
                        </td>

                        {perm.values.map((value, i) => {
                          return (
                            <td className={style.table__td} key={i}>
                              <div className={style.table__td_block}>
                                <PermissionsDropdown
                                  readOnly={value.isProtected}
                                  roleId={value.roleId}
                                  permissionName={perm.permissionName}
                                  value={value.level}
                                  allowedPermissions={perm.allowed_permissions}
                                  updateTable={updateTable}
                                />
                              </div>
                            </td>
                          )
                        })}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
            )}
      </Wrapper>

      <BaseModal
        open={isResetOpen}
        onGx-after-hide={handleResetClose}
        onGx-overlay-dismiss={handleResetClose}
        hideDefaultClose
        size='medium'
      >
        {isResetOpen
          ? (
            <ResetRole onCancel={handleResetClose} onDelete={handleResetRoles} />
            )
          : null}
      </BaseModal>

      <BaseModal
        open={isOpen}
        onGx-after-hide={closeDeleteModal}
        onGx-overlay-dismiss={closeDeleteModal}
        hideDefaultClose
        size='medium'
      >
        {editRole !== undefined
          ? (
            <DeleteRole
              onCancel={closeDeleteModal}
              onDelete={handleDeleteRole}
              roleId={editRole.roleId}
              title={editRole.title}
              roles={roles ?? []}
            />
            )
          : null}
      </BaseModal>

      <BaseModal
        open={isAddRoleOpen}
        onGx-after-hide={closeAddRoleModal}
        onGx-overlay-dismiss={closeAddRoleModal}
        hideDefaultClose
        size='medium'
      >
        {isAddRoleOpen
          ? (
            <AddRole
              onCancel={closeAddRoleModal}
              onAddRole={handleAddRole}
              onRenameRole={handleRenameRole}
              errors={errors?.title}
              defaultRoleName={editRole?.title}
            />
            )
          : null}
      </BaseModal>
    </Container>
  )
}

export default ProjectRoles
