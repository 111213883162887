import { ICase } from '@/interfaces/Case'
import { ISiutesTreeNode, ISuite, ISuiteCheckbox } from '@/interfaces/Suites'
import { Dispatch } from 'react'

export function buildTree (
  array: ISuite[],
  parent: number | null = null
): Array<ISiutesTreeNode<ISuiteCheckbox>> {
  const tree: Array<ISiutesTreeNode<ISuiteCheckbox>> = []

  for (const item of array) {
    if (item.parent === parent) {
      const children = buildTree(array, item.id)
      const treeNode: ISiutesTreeNode<ISuiteCheckbox> = {
        data: {
          ...item,
          isChecked: false,
          isIndeterminate: false
        },
        id: item.id,
        parent: item.parent
      }
      if (children.length > 0) {
        treeNode.children = children
      }
      tree.push(treeNode)
    }
  }

  return tree
}

export function findNodeById<T> (
  tree: Array<ISiutesTreeNode<T>>,
  id: number
): ISiutesTreeNode<T> | null {
  for (const node of tree) {
    if (node.id === id) {
      return node
    }
    if (node.children != null) {
      const foundNode = findNodeById(node.children, id)
      if (foundNode !== null) {
        return foundNode
      }
    }
  }
  return null
}

const updateCheckedRecursively = (
  node: ISiutesTreeNode<ISuiteCheckbox>,
  id: number,
  isChecked: boolean,
  isAncestor = false
): ISiutesTreeNode<ISuiteCheckbox> => {
  const updatedNode: ISiutesTreeNode<ISuiteCheckbox> = {
    ...node,
    data: {
      ...node.data,
      isChecked:
        isAncestor || node.data.id === id ? isChecked : node.data.isChecked
    }
  }

  if (node.children != null) {
    updatedNode.children = node.children.map((childNode) =>
      updateCheckedRecursively(
        childNode,
        id,
        isChecked,
        isAncestor || node.data.id === id
      )
    )
  }

  return updatedNode
}

export const updateCheckedById = (
  id: number,
  isChecked: boolean,
  setTreeData: Dispatch<
  React.SetStateAction<Array<ISiutesTreeNode<ISuiteCheckbox>>>
  >
): void => {
  setTreeData((prevTreeData) => {
    return prevTreeData.map((node) => {
      if (node.data.id === id) {
        return {
          ...node,
          data: {
            ...node.data,
            isChecked: isChecked
          }
        }
      } else if (node.children != null) {
        return {
          ...node,
          children: updateCheckedReq(node.children, id, isChecked)
        }
      }
      return node
    })
  })
}

const updateCheckedReq = (
  nodes: Array<ISiutesTreeNode<ISuiteCheckbox>>,
  id: number,
  isChecked: boolean
): Array<ISiutesTreeNode<ISuiteCheckbox>> => {
  return nodes.map((node) => {
    if (node.data.id === id) {
      return {
        ...node,
        data: {
          ...node.data,
          isChecked: isChecked
        }
      }
    } else if (node.children != null) {
      return {
        ...node,
        children: updateCheckedReq(node.children, id, isChecked)
      }
    }
    return node
  })
}

export const updateCheckedByIdRecursively = (
  id: number,
  isChecked: boolean,
  setTreeData: Dispatch<
  React.SetStateAction<Array<ISiutesTreeNode<ISuiteCheckbox>>>
  >
): void => {
  setTreeData((prevTreeData) => {
    const updatedTreeData = prevTreeData.map((node) =>
      updateCheckedRecursively(node, id, isChecked)
    )
    return updatedTreeData
  })
}

export const filterSuitesByCases = (
  suiteArray: ISuite[],
  objectArray: ICase[]
): ISuite[] => {
  const suiteIds = new Set<number>()
  const resultArray: ISuite[] = []

  const countsCases = countSuitesInObjectArray(objectArray)

  for (const object of objectArray) {
    if (object.suite !== null) {
      suiteIds.add(object.suite)
    }
  }

  const findParents = (id: number): void => {
    for (const suite of suiteArray) {
      if (suite.id === id) {
        if (
          !resultArray.some((existingSuite) => existingSuite.id === suite.id)
        ) {
          resultArray.push({ ...suite, cases_count: countsCases[suite.id] })
        }
        if (suite.parent !== null) {
          findParents(suite.parent)
        }
        break
      }
    }
  }

  for (const id of suiteIds) {
    findParents(id)
  }

  return resultArray
}

const countSuitesInObjectArray = (objectArray: ICase[]): Record<number, number> => {
  const suiteCounts: Record<number, number> = {}

  for (const object of objectArray) {
    const suiteId = object.suite !== null ? object.suite : -1
    if (suiteCounts[suiteId] === undefined) {
      suiteCounts[suiteId] = 1
    } else {
      suiteCounts[suiteId]++
    }
  }

  return suiteCounts
}
