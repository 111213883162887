import React, { useState } from 'react'
import { useObjectPage, usePage, useStores } from '@/hooks'
import { FormattedMessage, useIntl } from 'react-intl'
import useCurrentUser from '@/hooks/useCurrentUser'
import useSWR from 'swr'
import Wrapper from '@/components/Wrapper'
import Title from '@/components/Title'
import { Accordion } from '@/components/Accordion'
import { TextTag } from '@/components/Tag'
import Icon from '@/components/Icon/Icon'
import Link from '@/components/Link'
import TextButton from '@/components/Button/TextButton'
import Button from '@/components/Button/Button'
import BaseModal from '@/components/Modal/Base'
import useModal from '@/hooks/useModal'
import ChangeOwner from './components/ChangeOwner'
import Tooltip from '@/components/Tooltip'
import { LinkButton } from '@/components/Button/LinkButton'
import CompanyNavigation from './components/CompanyNavigation'
import { getInitials } from '@/components/UserName'
import { AvatarCompany, AvatarUser } from '@/components/Avatar'
import { useForm } from 'react-hook-form'
import { Form } from '@/components/Form'
import { reactHookFormErrorFormater } from '@/utils'
import { useNavigate } from 'react-router-dom'
import LeaveCompany from './components/LeaveCompany'
import style from './styles/company.module.scss'

export interface IFormInputs {
  non_field_errors?: string
  idCompany?: number
}

const CompanyDetail = (): React.ReactElement => {
  const obj = usePage()
  const { api } = useStores()
  const navigate = useNavigate()
  const {
    id: idCompany,
    title: titleCompany,
    image: avatarCompany
  } = useObjectPage()
  const [isNotification, setNotification] = useState<boolean>(
    obj.init_state.get_notifies
  )
  const intl = useIntl()
  const { isOpen, handleOpenModal, handleCloseModal } = useModal()
  const {
    isOpen: isLeaveModalOpen,
    handleOpenModal: handleOpenLeaveModal,
    handleCloseModal: handleCloseLeaveModal
  } = useModal()
  const { currentUser } = useCurrentUser()
  const { data, mutate } = useSWR(
    { id: idCompany, _key: 'getCompany' },
    api.getCompany
  )
  const initialsUser = getInitials(
    data?.owner.first_name ?? '',
    data?.owner.last_name ?? '',
    data?.owner.email
  )

  const handleMutate = async (): Promise<void> => {
    await mutate()
  }

  const isCurrentUserAdmin: boolean = currentUser?.id === data?.owner.id
  const email = data?.email ?? null
  const subpageProjects = obj.init_state.subpages.CompanyPageProjects
  const subpageMembers = obj.init_state.subpages.CompanyPageMembers
  const subpageInvites = obj.init_state.subpages.CompanyPageInvites
  const companyList = obj.init_state.global.company_list_url

  const toggleNotification = async (): Promise<void> => {
    setNotification(!isNotification)
    await api.turnNotifications(idCompany, { value: !isNotification })
  }

  const {
    handleSubmit,
    setError,
    formState: { errors, isSubmitting }
  } = useForm<IFormInputs>({
    defaultValues: { idCompany: idCompany }
  })

  const onSubmit = async (params): Promise<void> => {
    try {
      const res = await api.leaveCompany(params.idCompany)
      handleCloseLeaveModal()
      navigate(companyList)
      return res
    } catch (error) {
      const errors = reactHookFormErrorFormater(params, error)
      errors.forEach((item) => {
        setError(item.key as keyof IFormInputs, {
          type: '400',
          message: item.value
        })
      })
    }
  }

  const nonFieldErrors = errors?.non_field_errors?.message ?? ''

  return (
    <Wrapper className={style.company__wrapper}>
      <div className={style.company}>
        <AvatarCompany size='xxxl' src={data?.image} />

        <div className={style.company__middle}>
          <div className={style.company__title}>
            <Title type='h1' className={style.company__title}>
              {data?.title}
            </Title>

            <div className={style.company__code}>
              {`id: ${data?.code ?? ''}`}
            </div>
          </div>

          {data?.description !== ''
            ? (
              <Accordion
                head={intl.formatMessage({
                  id: 'company.form.description',
                  defaultMessage: 'Description'
                })}
              >
                {data?.description}
              </Accordion>
              )
            : null}
          <div className={style.company__tags}>
            <TextTag>
              <Icon src='folder' />
              {data?.projects_count}
            </TextTag>

            <TextTag>
              <Icon src='user' />
              {data?.users_count}
            </TextTag>

            {data !== undefined && Boolean(data?.site_url) && (
              <Link
                className={style.company__site}
                to={data?.site_url}
                target='_blank'
              >
                <Icon src='website' />
              </Link>
            )}
          </div>
        </div>

        <div className={style.company__end}>
          <div className={style.company__items}>
            {data?.owner !== undefined
              ? (
                <div className={style.company__item}>
                  <div className={style.company__code}>
                    <Icon src='admin' className={style.company__img} />

                    <FormattedMessage id='common.owner' defaultMessage='Owner' />
                  </div>

                  <div className={style.company__owner}>
                    <AvatarUser
                      size='medium'
                      src={data?.owner.avatar}
                      initials={initialsUser}
                    />

                    <TextButton
                      className={style.company__ownerlink}
                      href={data?.owner.profile_url}
                    >
                      {`${data?.owner.first_name} ${data?.owner.last_name}`}
                    </TextButton>

                    {isCurrentUserAdmin && data.users_count > 1
                      ? (
                        <Tooltip
                          content={intl.formatMessage({
                            id: 'company.change_owner.title',
                            defaultMessage: 'Change the company owner'
                          })}
                        >
                          <Button theme='light' circle onClick={handleOpenModal}>
                            <Icon src='reverseArrow' />
                          </Button>
                        </Tooltip>
                        )
                      : null}
                  </div>
                </div>
                )
              : null}

            {email !== null
              ? (
                <div className={style.company__item}>
                  <div className={style.company__code}>
                    <Icon src='mail' className={style.company__img} />

                    <FormattedMessage
                      id='company.form.email'
                      defaultMessage='Email'
                    />
                  </div>

                  <div className={style.company__owner}>
                    <a className={style.company__mail} href={`mailto:${email}`}>
                      {email}
                    </a>
                  </div>
                </div>
                )
              : null}
          </div>

          <div className={style.company__notify}>
            <button
              className={style.company__icon}
              type='button'
              onClick={toggleNotification}
            >
              {isNotification
                ? (
                  <Icon src='notificationFill' />
                  )
                : (
                  <Icon src='notification' />
                  )}
            </button>

            {isCurrentUserAdmin
              ? (
                <LinkButton
                  href={obj.init_state.subpages.CompanyPageUpdate.absolute_url}
                  theme='light'
                  circle
                >
                  <Icon src='edit' />
                </LinkButton>
                )
              : null}
          </div>
        </div>
      </div>

      <CompanyNavigation
        subpageProjects={subpageProjects}
        subpageMembers={subpageMembers}
        isCurrentUserAdmin={isCurrentUserAdmin}
        subpageInvites={subpageInvites}
        handleOpenLeaveModal={handleOpenLeaveModal}
      />

      <BaseModal
        open={isLeaveModalOpen}
        onGx-after-hide={handleCloseLeaveModal}
        onGx-overlay-dismiss={handleCloseLeaveModal}
        hideDefaultClose
        className={style.change_owner}
        size='medium'
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <LeaveCompany
            onClose={handleCloseLeaveModal}
            isSubmitting={isSubmitting}
            companyName={titleCompany}
            avatar={avatarCompany}
            error={nonFieldErrors}
          />
        </Form>
      </BaseModal>

      {data !== undefined
        ? (
          <ChangeOwner
            companyName={data?.title}
            avatar={data?.image}
            idCompany={idCompany}
            onClose={handleCloseModal}
            handleMutate={handleMutate}
            isOpen={isOpen}
          />
          )
        : null}
    </Wrapper>
  )
}

export default CompanyDetail
