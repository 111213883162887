import React from 'react'
import ProjectLayout from '../layout/components/ProjectLayout'
import UserLayout from '../layout/components/UserLayout'
import Milestones from '../Milestones/Milestones'

const ProjectMilestones = (): React.ReactElement => {
  return (
    <UserLayout>
      <ProjectLayout>
        <Milestones />
      </ProjectLayout>
    </UserLayout>
  )
}

export default ProjectMilestones
