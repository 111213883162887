import BugItem from '@/components/BugItem'
import Button from '@/components/Button/Button'
import Icon from '@/components/Icon/Icon'
import Title from '@/components/Title'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Accordion } from '@/components/Accordion'
import useSWR from 'swr'
import { useStores } from '@/hooks'
import style from '../styles/case_detail.module.scss'

export interface IJiraBugListOfStepProps {
  onCancel: () => void
  idStepOrTask: number
  projectId: number | string
  isTask?: boolean
}

const JiraBugListOfStep = ({
  onCancel,
  idStepOrTask,
  projectId,
  isTask = false
}: IJiraBugListOfStepProps): JSX.Element => {
  const { api } = useStores()

  const { data: jiraIssues } = useSWR(
    {
      id: projectId,
      case_run_step: !isTask ? idStepOrTask : undefined,
      task: isTask ? idStepOrTask : undefined,
      _key: 'getJiraIssues'
    },
    api.getJiraIssues
  )

  return (
    <>
      <div className={style.case__header}>
        <Title type='h2' className={style.case__header__name}>
          <Icon src='bug' />
          <FormattedMessage id='titles.bugs' defaultMessage='Bugs' />
        </Title>

        <div>
          <Button theme='light' onClick={onCancel} circle>
            <Icon src='clear' />
          </Button>
        </div>
      </div>

      <Accordion
        defaultOpened
        className={style.accordion}
        head={
          <div>
            <span className={style.accordion__header_text}>
              <FormattedMessage id='case.steps.step' defaultMessage='Step' />
            </span>
          </div>
        }
      >
        <div className={style.accordion__items}>
          {jiraIssues !== undefined
            ? jiraIssues.map((el) => (
              <BugItem key={el.id} name={el.key} url={el.absolute_url} />
            ))
            : null}
        </div>
      </Accordion>
    </>
  )
}

export default JiraBugListOfStep
