import classNames from 'classnames'
import React from 'react'
import style from './styles/user.module.scss'

interface IProps {
  role: string
  className?: string
}

const UserRole = ({ role, className = '' }: IProps): React.ReactElement => {
  return (
    <div
      className={classNames({
        [style.role]: true,
        [className]: Boolean(className)
      })}
    >
      <span>{role}</span>
    </div>
  )
}

export default UserRole
