import React, { useState } from 'react'
import { useObjectPage, useStores } from '@/hooks'
import ProjectsList from './ProjectsList/ProjectsList'
import Container from '@/components/Container'
import Wrapper from '@/components/Wrapper'
import Title from '@/components/Title'
import Icon from '@/components/Icon/Icon'
import style from './styles/project.module.scss'
import { FormattedMessage, useIntl } from 'react-intl'
import ListContent from '@/components/ListContent'
import SearchInput from '@/components/Input/SearchInput/SearchInput'
import useDebounce from '@/hooks/useDebounce'
import useModal from '@/hooks/useModal'
import SelectCompanyModal from '@/apps/Projects/SelectCompanyModal/SelectCompanyModal'
import Button from '@/components/Button/Button'
import { SwitchIcon } from '@/components/Switch'
import { SWITCH_VALUE, projectsService } from '@/services/ProjectSwitcher'
import CompanySelect from '@/components/Select/CompanySelect'
import useSWR from 'swr'

const PAGE_SIZE = 10

const Projects = (): React.ReactElement => {
  const { api } = useStores()
  const objectPage = useObjectPage()

  const [companyId, setCompanyId] = useState<number | undefined>()
  const [search, setSearch] = useState('')
  const [displayMode, setDisplayMode] = useState(projectsService.switch)
  const debouncedSearch = useDebounce(search)
  const [page, setPage] = useState(1)
  const intl = useIntl()

  const { data, error, isLoading } = useSWR(
    {
      page: page,
      page_size: PAGE_SIZE,
      q: debouncedSearch,
      company: companyId,
      _key: 'getProjects'
    },
    api.getProjects
  )

  const handleChangeDisplayMode = (): void => {
    projectsService.toggle()
    setDisplayMode(projectsService.switch)
  }

  const { isOpen, handleOpenModal, handleCloseModal } = useModal()
  const handleSearch = (value: string): void => {
    setSearch(value)
    setPage(1)
  }

  const handleChangeCompany = (e): void => {
    setCompanyId(e?.value)
  }

  return (
    <Container>
      <Wrapper className={style.project}>
        <div className={style.project__top}>
          <Title type='h1' className={style.project__title}>
            {objectPage.title}
          </Title>

          <Button
            onClick={handleOpenModal}
            className={style.project__addproject}
          >
            <Icon src='plus' slot='icon-left' />

            <FormattedMessage
              id='project.add_project'
              defaultMessage='Add project'
            />
          </Button>
        </div>

        <div className={style.project__filters}>
          <CompanySelect
            isClearable
            handleChange={handleChangeCompany}
            labelClassName={style.company__select_label}
          />

          <SearchInput
            handleChange={handleSearch}
            value={search}
            className={style.project__search}
            placeholder={intl.formatMessage({
              id: 'project.search',
              defaultMessage: 'Search project'
            })}
          />

          <SwitchIcon
            currentValue={displayMode === SWITCH_VALUE.list}
            setCurrentValue={handleChangeDisplayMode}
            leftIcon='tile'
            rightIcon='list'
          />
        </div>

        <ListContent
          isLoading={isLoading}
          error={error}
          hasData={data !== undefined && data?.count > 0}
          emptyListIcon='folder'
          emptyListText={intl.formatMessage({
            id: 'project.no_projects',
            defaultMessage: 'No projects has been found'
          })}
          emptyListButton={intl.formatMessage({
            id: 'project.add_project',
            defaultMessage: 'Add project'
          })}
          onClick={handleOpenModal}
        >
          {data !== undefined
            ? (
              <ProjectsList
                displayMode={displayMode}
                data={data}
                currentPage={page}
                pageSize={PAGE_SIZE}
                setPage={setPage}
              />
              )
            : null}
        </ListContent>

        <SelectCompanyModal open={isOpen} close={handleCloseModal} />
      </Wrapper>
    </Container>
  )
}

export default Projects
