import BaseAsyncSelect from '@/components/Select/AsyncSelect/BaseAsyncSelect'
import { IValue } from '@/components/Select/interfaces/AsyncSelect'
import { useObjectPage, useStores } from '@/hooks'
import React, { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import useSWR from 'swr'

interface IProps {
  handleChange: (value: any) => void
  value?: IValue | undefined
  setIsProjectSelected: React.Dispatch<React.SetStateAction<boolean>>
}

const JiraProjectField = (
  { handleChange, value, setIsProjectSelected }: IProps
): JSX.Element => {
  const store = useStores()
  const intl = useIntl()
  const { id } = useObjectPage()
  const [defaultVals, setDefaultVals] = useState<IValue>({
    label: '',
    value: 0
  })

  const { data: currentProjectData } = useSWR({ id }, store.api.getProject)
  const currentJiraProjectKey = currentProjectData?.jira_key

  const { data: jiraProject } = useSWR(
    { id: id.toString() },
    store.api.getJiraProjects
  )

  const filteredProject = useMemo(() => {
    return jiraProject?.find((project) => project.key === currentJiraProjectKey)
  }, [jiraProject, currentJiraProjectKey])

  useEffect(() => {
    if (filteredProject !== undefined) {
      setDefaultVals({ label: filteredProject.name, value: filteredProject.id })
      setIsProjectSelected(true)
    }
  }, [filteredProject])

  const loadOptions = (
    inputValue: string,
    callback: (
      options: Array<{
        value: number
        label: string
        key: string
      }>
    ) => void
  ): void => {
    store.api
      .getJiraProjects({ id: id, q: inputValue })
      .then((data) => {
        const mappedData = data.map(({ name, id, key }) => ({
          value: id,
          label: name,
          key: key
        }))
        callback(mappedData)
      })
      .catch((err) => console.log(err))
  }

  return (
    <BaseAsyncSelect
      required
      value={value ?? defaultVals}
      label={intl.formatMessage({
        id: 'case.form.project',
        defaultMessage: 'Project'
      })}
      handleChange={handleChange}
      loadOptions={loadOptions}
    />
  )
}

export default JiraProjectField
