import React from 'react'
import style from './styles/loading.module.scss'
import { Shimmer, ShimmerWrap } from '@/components/Loading'

interface IProps {
  className?: string
}

const LoaderMilestoneDescription = ({
  className = ''
}: IProps): React.ReactElement => {
  return (
    <ShimmerWrap className={className}>
      <div className={style.wrap}>
        <Shimmer size='xl' />
      </div>
    </ShimmerWrap>
  )
}

export default LoaderMilestoneDescription
