import useSWR from 'swr'
import Loading from '@/components/Loading/Loading'
import Pagination from '@/components/Pagination'
import React, {
  Suspense,
  forwardRef,
  useEffect,
  useState
} from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import CaseItemCheckbox from '@/components/CaseItem/CaseItemCheckbox/CaseItemCheckbox'
import SearchInput from '@/components/Input/SearchInput/SearchInput'
import {
  FieldArrayWithId,
  UseFieldArrayAppend,
  UseFieldArrayRemove
} from 'react-hook-form'
import { ICase } from '@/interfaces/Case'
import { CASE_STATUS } from '@/const'
import useDebounce from '@/hooks/useDebounce'
import PrioritySelect from '@/components/Select/PrioritySelect/PrioritySelect'
import { TPriority } from '@/interfaces/Types'
import Tags from '@/components/Tags/Tags'
import { usePage } from '@garpix/cms'
import PageSizeSelect from '@/components/Select/PageSizeSelect'
import ListContent from '@/components/ListContent'
import { ICountSelected } from '@/interfaces/Suites'
import EmptyList from '@/components/EmptyList/EmptyList'
import { useStores } from '@/hooks'
import Icon from '@/components/Icon/Icon'
import { useNavigate } from 'react-router-dom'
import { IFormInputs } from '@/apps/AddOrEditRun'
import SuitesDndListWithChecks from './SuitesDndListWithChecks'
import { LoaderAddCase } from '../loaders'
import { pageSizeSelectService } from '@/services/PageSizeSelect'
import WithoutSuite from './WithoutSuiteWithChecks'
import style from '../styles/update_cases.module.scss'

interface IProps {
  selectedCase: Array<FieldArrayWithId<IFormInputs, 'cases', '_id'>>
  append: UseFieldArrayAppend<IFormInputs, 'cases'>
  remove: UseFieldArrayRemove
}

const AddCase = (
  { selectedCase, append, remove }: IProps,
  ref
): React.ReactElement => {
  const obj = usePage()
  const intl = useIntl()
  const navigate = useNavigate()
  const projectId = obj.page.init_state.project_object.id
  const { api } = useStores()

  const [search, setSearch] = useState('')
  const [searchSuite, setSearchSuite] = useState('')
  const [priority, setPriority] = useState<TPriority | ''>('')
  const [currentPage, setCurrentPage] = useState(1)
  const [currentSuite, setCurrentSuite] = useState<number | undefined>()
  const [selectTags, setSelectTags] = useState<number[]>([])
  const [pageSize, setPageSize] = useState<number>(
    pageSizeSelectService.getSize('addCasesModal') ?? 200
  )
  const [countSelected, setCountSelected] = useState<ICountSelected>()

  const debouncedSearch = useDebounce(search)
  const debouncedSearchSuite = useDebounce(searchSuite)

  const handleAddCase = (el: ICase): void => {
    append(el)
  }

  const handleRemoveCase = (index: number | number[]): void => {
    remove(index)
  }

  const { data: dataAllCases, isLoading: isLoadingDataAllCases } = useSWR(
    {
      project: projectId,
      status: CASE_STATUS.approved,
      full_list: true,
      _key: 'getCasesAllCreate'
    },
    api.getCases,
    {
      onSuccess: (cases) => {
        if (countSelected === undefined) {
          const updatedCountSelected = {}
          for (const item of cases.results) {
            const suite = item.suite !== null ? item.suite : -1
            if (suite !== undefined) {
              if (updatedCountSelected[suite] === undefined) {
                updatedCountSelected[suite] = { total: 0, selected: 0 }
              }
              updatedCountSelected[suite].total++
            }
          }
          setCountSelected(updatedCountSelected)
        }
      }
    }
  )

  const { data, isLoading } = useSWR(
    {
      q: debouncedSearch,
      page: currentPage,
      page_size: pageSize,
      project: projectId,
      status: CASE_STATUS.approved,
      priority: priority,
      tags: selectTags,
      suite: currentSuite !== -1 ? currentSuite : undefined,
      without_suite: currentSuite === -1,
      _key: 'getCasesCreate'
    },
    api.getCases
  )

  const handleSearch = (value: string): void => {
    setSearch(value)
    setCurrentPage(1)
  }
  const handleChangeCase = (value): void => {
    const elIndex = selectedCase.findIndex((item) => item.id === value.id)
    if (elIndex === -1) {
      handleAddCase(value)
    } else {
      handleRemoveCase(elIndex)
    }
  }

  const handleChangePriority = (event): void => {
    setPriority(event.target.value)
    setCurrentPage(1)
  }

  const handleChangeTags = (): void => {
    setCurrentPage(1)
  }

  const handleChangePageSize = (e: any): void => {
    const newPageSize = Number(e.target.value)
    setPageSize(newPageSize)
    pageSizeSelectService.setSize('addCasesModal', newPageSize)
    setCurrentPage(1)
  }

  const handleNavigateCreateSuite = (): void => {
    const createSuite: string = `${String(
      obj.page.init_state.sidebar.ProjectPageCasesAndSuites.absolute_url
    )}?createSuite=true`
    navigate(createSuite)
  }

  const {
    data: suitesData,
    error,
    isLoading: isLoadingSuites,
    isValidating
  } = useSWR(
    {
      project_id: projectId,
      q: debouncedSearchSuite
    },
    async (key) => {
      const data = await api.getSuites(key)

      if (Array.isArray(data)) {
        return data.map((el) => ({
          ...el,
          isChecked: false,
          isIndeterminate: false
        }))
      }

      return data
    }
  )

  const handleCheckSuite = (suiteId: number, isChecked: boolean): void => {
    const id = suiteId === -1 ? null : suiteId
    const casesInSuite = dataAllCases?.results.filter((el) => el.suite === id)
    if (isChecked) {
      casesInSuite?.forEach((el) => {
        if (!selectedIds.includes(el.id)) handleAddCase(el)
      })
    } else {
      const arrIndex =
        casesInSuite?.map((el) =>
          selectedCase.findIndex(
            (element) => element.id === el.id && selectedIds.includes(el.id)
          )
        ) ?? []

      handleRemoveCase(arrIndex)
    }
  }

  const { data: casesWithoutSuites } = useSWR(
    {
      page: 1,
      full_list: true,
      project: projectId,
      status: CASE_STATUS.approved,
      without_suite: true,
      _key: 'getCasesWithoutSuites'
    },
    api.getCases
  )
  const selectedIds = selectedCase.map((item) => item.id)

  useEffect(() => {
    const groupedBySuite = new Map<number, ICase[]>()

    selectedCase.forEach((item) => {
      const suite = item.suite !== null ? item.suite : -1
      if (!groupedBySuite.has(suite)) {
        groupedBySuite.set(suite, [])
      }
      groupedBySuite.get(suite)?.push(item)
    })

    for (const key in countSelected) {
      const updatedCountSelected = { ...countSelected }
      updatedCountSelected[key].selected =
        groupedBySuite.get(Number(key))?.length ?? 0
      setCountSelected(updatedCountSelected)
    }
  }, [selectedCase, dataAllCases, suitesData])

  return (
    <div className={style.content}>
      <div className={style.content__suites}>
        <SearchInput
          value={searchSuite}
          handleChange={setSearchSuite}
          placeholder={intl.formatMessage({
            id: 'suites.search',
            defaultMessage: 'Search test suite'
          })}
          className={style.content__suites_search}
        />

        <Suspense fallback={<Loading />}>
          <div className={style.suites__tree}>
            <ListContent
              isLoading={isLoadingSuites || isValidating}
              error={error}
              hasData={
                (suitesData !== undefined && suitesData?.length > 0) ||
                (casesWithoutSuites?.count !== undefined &&
                  casesWithoutSuites?.count > 0)
              }
              emptyListIcon='folders'
              emptyListText={intl.formatMessage({
                id: 'suites.no_suites',
                defaultMessage: 'No suites has been found'
              })}
              emptyListButton={intl.formatMessage({
                id: 'suites.add_suite',
                defaultMessage: 'Add test suite'
              })}
              onClick={handleNavigateCreateSuite}
            >
              {!isLoadingSuites && !isLoading && !isLoadingDataAllCases
                ? (
                  <div className={style.dnd__list}>
                    {casesWithoutSuites?.count !== undefined &&
                  casesWithoutSuites?.count > 0
                      ? (
                        <WithoutSuite
                          caseCount={casesWithoutSuites?.count}
                          currentSuite={currentSuite}
                          setCurrentSuite={setCurrentSuite}
                          checkSuite={handleCheckSuite}
                          countSelected={countSelected ?? {}}
                        />
                        )
                      : null}

                    {suitesData !== undefined
                      ? (
                        <SuitesDndListWithChecks
                          data={suitesData}
                          currentSuite={currentSuite}
                          setCurrentSuite={setCurrentSuite}
                          checkSuite={handleCheckSuite}
                          countSelected={countSelected ?? {}}
                        />
                        )
                      : null}
                  </div>
                  )
                : null}
            </ListContent>
          </div>
        </Suspense>
      </div>

      <div className={style.content__cases}>
        <div className={style.content__filters}>
          <div className={style.content__title_suite}>
            <Icon src='folders' />

            {currentSuite !== undefined
              ? (
                <div className={style.content__cases}>
                  {currentSuite !== -1
                    ? (
                      <>
                        {suitesData?.find((item) => item.id === currentSuite)
                          ?.title ?? null}
                      </>
                      )
                    : (
                      <FormattedMessage
                        id='suites.root_suite'
                        defaultMessage='Without suite'
                      />
                      )}
                </div>
                )
              : (
                <FormattedMessage id='suites.all' defaultMessage='All checks' />
                )}
          </div>

          <Tags
            onChange={handleChangeTags}
            setSelectTags={setSelectTags}
            selectTags={selectTags}
          />

          <div className={style.cases__selects}>
            <SearchInput
              value={search}
              handleChange={handleSearch}
              placeholder={intl.formatMessage({
                id: 'case.search',
                defaultMessage: 'Search test check'
              })}
              className={style.cases__select}
            />

            <PrioritySelect
              value={priority}
              handleChange={handleChangePriority}
            />
          </div>
        </div>

        {isLoading ? <LoaderAddCase countShimmers={8} /> : null}

        {!isLoading && data?.results.length === 0
          ? (
            <EmptyList
              icon='test_case'
              text={intl.formatMessage({
                id: 'cases.no_cases',
                defaultMessage: 'No test cases added yet'
              })}
            />
            )
          : null}

        {!isLoading && data !== undefined && data.results.length > 0
          ? (
            <>
              <div className={style.content__suite}>
                {data?.results.map((el) => {
                  return (
                    <CaseItemCheckbox
                      key={el.id}
                      title={el.title}
                      code={el.code}
                      checked={selectedIds.includes(el.id)}
                      onChange={() => handleChangeCase(el)}
                      caseType={el.case_type}
                    />
                  )
                })}
              </div>

              <div className={style.content__pagination}>
                <PageSizeSelect
                  isScrollTop
                  pageSizeOptions={[100, 150, 200]}
                  value={String(pageSize)}
                  handleChange={handleChangePageSize}
                />

                <Pagination
                  currentPage={currentPage}
                  total={data?.count ?? 0}
                  pageSize={pageSize}
                  handleChange={setCurrentPage}
                />
              </div>
            </>
            )
          : null}
      </div>
    </div>
  )
}

export default forwardRef(AddCase)
