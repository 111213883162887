import React from 'react'
import style from './styles/notification_item.module.scss'
import Icon from '../Icon/Icon'
import { TaskStatus } from '../Status'
import { FormattedMessage } from 'react-intl'
import { INotifiesRunAssignData } from '@/interfaces/Notifies'
import { ITimeSince } from '@/interfaces/Utils'
import Link from '../Link'
import NotifyTime from './components/NotifyTime'
import Tooltip from '../Tooltip'
import { getInitials } from '../UserName'
import { AvatarUser } from '../Avatar'

interface IProps {
  data: INotifiesRunAssignData
  time: ITimeSince
}

const NotifyRunAssign = ({ data, time }: IProps): React.ReactElement => {
  const { author, project, run } = data
  const initialsUser = getInitials(
    author.first_name,
    author.last_name,
    author.email
  )

  return (
    <>
      <Tooltip content={`${author.first_name} ${author.last_name}`}>
        <AvatarUser
          src={author.avatar}
          className={style.item__avatar}
          initials={initialsUser}
        />
      </Tooltip>

      <div className={style.item__content}>
        <p className={style.info}>
          <FormattedMessage
            id='notifications.item.you_assigned'
            values={{
              link: <Link to={project.absolute_url}>{project.title}</Link>
            }}
            defaultMessage='You have been assigned in {link} project'
          />
        </p>

        <div className={style.run}>
          <Link to={run.absolute_url} className={style.run__link}>
            <Icon src='run' className={style.run__icon} />
            <span className={style.run__name}>{run.title}</span>
          </Link>

          <TaskStatus status={run.status} />
        </div>

        <NotifyTime time={time} />
      </div>
    </>
  )
}

export default NotifyRunAssign
