import React, { Suspense, useRef } from 'react'
import Suites from './components/Suites'
import Cases from './components/Cases'
import BaseModal from '@/components/Modal/Base'
import Wrapper from '@/components/Wrapper'
import Button from '@/components/Button/Button'
import Icon from '@/components/Icon/Icon'
import Title from '@/components/Title'
import { FormattedMessage, useIntl } from 'react-intl'
import useModal from '@/hooks/useModal'
import {
  FieldArrayWithId,
  UseFieldArrayAppend,
  UseFieldArrayRemove
} from 'react-hook-form'
import { observer } from 'mobx-react'
import Loading from '@/components/Loading/Loading'
import { IFormInputs } from '@/apps/AddOrEditRun'
import classNames from 'classnames'
import EmptyList from '../../EmptyList/EmptyList'
import AddCase from '../components/AddCase'
import style from '../styles/update_cases.module.scss'

const LayDndProvider = React.lazy(
  async () =>
    await import('@minoru/react-dnd-treeview').then(
      ({ getBackendOptions, MultiBackend, DndProvider }) => {
        const DndComponent: React.FC<any> = (props) => {
          const backendOptions = getBackendOptions(props)
          const backend = MultiBackend

          return (
            <DndProvider backend={backend} options={backendOptions}>
              {props.children}
            </DndProvider>
          )
        }

        return {
          default: DndComponent
        }
      }
    )
)

interface IProps {
  selectedCase: Array<FieldArrayWithId<IFormInputs, 'cases', '_id'>>
  append: UseFieldArrayAppend<IFormInputs, 'cases'>
  remove: UseFieldArrayRemove
}

const SuitesCasesChanges = observer(
  ({ selectedCase, append, remove }: IProps): React.ReactElement => {
    const { isOpen, handleOpenModal, handleCloseModal } = useModal()
    const intl = useIntl()

    const refIsLoadingModal = useRef(null)
    return (
      <>
        <Suspense fallback={<Loading />}>
          <LayDndProvider>
            <Wrapper className={style.casessuites__wrap}>
              <div className={style.cases__top}>
                <Title type='h1' className={style.cases__title}>
                  <FormattedMessage id='cases.title' defaultMessage='Checks' />
                </Title>
                {selectedCase.length !== 0
                  ? (
                    <Button type='button' onClick={handleOpenModal}>
                      <Icon src='plus' slot='icon-left' />

                      <FormattedMessage
                        id='case.update'
                        defaultMessage='Update'
                      />
                    </Button>
                    )
                  : null}
              </div>

              <div
                className={classNames({
                  [style.casessuites]: true,
                  [style.casessuites__hidden]: selectedCase.length === 0
                })}
              >
                <Suites selectedCase={selectedCase} />

                <Cases selectedCase={selectedCase} />
              </div>

              {selectedCase.length === 0
                ? (
                  <EmptyList
                    icon='test_case'
                    text={intl.formatMessage({
                      id: 'runs.empty',
                      defaultMessage: 'The Test check doesn’t have steps'
                    })}
                    buttonText={intl.formatMessage({
                      id: 'case.add_case',
                      defaultMessage: 'Add'
                    })}
                    className={style.steps__empty}
                    onClick={handleOpenModal}
                  />
                  )
                : null}
            </Wrapper>

            <BaseModal
              open={isOpen}
              onGx-after-hide={handleCloseModal}
              hideDefaultClose
              className={style.cases__modal}
              size='xl'
            >
              <Wrapper className={style.modal}>
                <div className={style.modal__head}>
                  <div className={style.modal__title}>
                    <Button onClick={handleCloseModal} theme='light' circle>
                      <Icon src='back' />
                    </Button>

                    <Title type='h2' className={style.modal__item}>
                      <FormattedMessage
                        id='case.add_case'
                        defaultMessage='Add Test Cases'
                      />
                    </Title>
                  </div>

                  <Button onClick={handleCloseModal}>
                    <Icon src='checked' slot='icon-left' />

                    <FormattedMessage id='common.save' defaultMessage='Save' />
                  </Button>
                </div>

                <AddCase
                  selectedCase={selectedCase}
                  append={append}
                  remove={remove}
                  ref={refIsLoadingModal}
                />
              </Wrapper>
            </BaseModal>
          </LayDndProvider>
        </Suspense>
      </>
    )
  }
)

export default SuitesCasesChanges
