import Icon from '@/components/Icon/Icon'
import classNames from 'classnames'
import React, { ChangeEvent } from 'react'
import Input from '../Input'
import style from '../styles/input.module.scss'

interface IProps {
  handleChange: (value) => void
  value: string
  placeholder?: string
  className?: string
  clearable?: boolean
}

const SearchInput = ({
  handleChange,
  value,
  placeholder = 'Search',
  clearable = true,
  className = ''
}: IProps): React.ReactElement => {
  return (
    <Input
      onChange={({ target }: ChangeEvent<HTMLInputElement>) =>
        handleChange(target.value)}
      type='search'
      value={value}
      clearable={clearable}
      placeholder={placeholder}
      className={classNames({
        [className]: Boolean(className)
      })}
    >
      <Icon src='search' slot='prefix' className={style.iconsearch} />
    </Input>
  )
}

export default SearchInput
