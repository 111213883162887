import MenuItem from '@/components/MenuItem/MenuItem'
import { THistoryModel } from '@/interfaces/Types'
import classNames from 'classnames'
import React, { forwardRef } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Select from '../Select'
import style from './styles/historymodelselect.module.scss'

interface IProps {
  className?: string
  setModel: (value: THistoryModel) => void
  value: THistoryModel | ''
}

const HistoryModelSelect = (
  { className = '', setModel, value }: IProps,
  ref
): React.ReactElement => {
  const intl = useIntl()

  const handleChange = (e: React.FormEvent<HTMLInputElement>): void => {
    setModel(e.currentTarget.value as THistoryModel)
  }

  return (
    <Select
      ref={ref}
      className={classNames({
        [style.select]: true,
        [className]: Boolean(className)
      })}
      placeholder={intl.formatMessage({
        id: 'input.placeholder',
        defaultMessage: 'Choose'
      })}
      label={intl.formatMessage({
        id: 'history.model',
        defaultMessage: 'Type'
      })}
      onChange={handleChange}
      clearable
      value={value}
    >
      <MenuItem value='run'>
        <FormattedMessage id='history.model.run' defaultMessage='Run' />
      </MenuItem>

      <MenuItem value='milestone'>
        <FormattedMessage
          id='history.model.milestone'
          defaultMessage='Milestone'
        />
      </MenuItem>

      <MenuItem value='case'>
        <FormattedMessage id='history.model.case' defaultMessage='Case' />
      </MenuItem>

      <MenuItem value='case_run'>
        <FormattedMessage
          id='history.model.case_run'
          defaultMessage='Case run'
        />
      </MenuItem>

      <MenuItem value='suite'>
        <FormattedMessage id='history.model.suite' defaultMessage='Suite' />
      </MenuItem>
    </Select>
  )
}

export default forwardRef(HistoryModelSelect)
