export const MILESTONE_DEFAULT_COLORS = [
  '#FF8800',
  '#6154f5',
  '#30b35c',
  '#f2b518',
  '#17ABFF',
  '#EA74FF',
  '#0fd6e0',
  '#3dbe00',
  '#ED0969',
  '#1154FF',
  '#a9a2df',
  '#795575',
  '#8ce070',
  '#6fb2bc'
]
