import Link from '@/components/Link'
import { garpixLogo, logo } from '@/images'
import React from 'react'
import { Layout } from '.'
import style from '../styles/layout.module.scss'
import { LanguageSwitch } from '@/components/Switch'

interface LayoutProps {
  children: React.ReactNode
  image: string
}

const AuthRegLayout = ({ children, image }: LayoutProps): React.ReactElement => {
  return (
    <Layout>
      <div className={style.auth}>
        <header className={style.auth__header}>
          <Link to='/' className={style.logo__link}>
            <img
              src={logo}
              alt='Eqator logo'
              width='152px'
              height='81px'
              className={style.auth__header_logo}
            />
          </Link>

          <LanguageSwitch theme='white' />
        </header>

        <main className={style.content}>
          <img
            src={image}
            width='333px'
            height='562px'
            alt=''
            className={style.content__image}
          />
          <div className={style.content__box}>{children}</div>
        </main>
        <footer className={style.auth__footer}>
          <span>by</span>

          <a href='https://garpix.com/' target='_blank' rel='noreferrer'>
            <img
              src={garpixLogo}
              width='69px'
              height='14px'
              alt='Garpix logo'
              className={style.auth__footer_logo}
            />
          </a>
        </footer>
      </div>
    </Layout>
  )
}

export default AuthRegLayout
