
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Controller, useForm } from 'react-hook-form'
import Input from '@/components/Input/Input'
import Icon from '@/components/Icon/Icon'
import Button from '@/components/Button/Button'
import { useStores } from '@/hooks'
import { reactHookFormErrorFormater } from '@/utils'
import { Form } from '@/components/Form'
import Title from '@/components/Title'
import ErrorsBlock from '@/components/ErrorsBlock'
import useGlobalConfig from '@/hooks/useGlobalConfig'
import BackButton from '@/components/Button/BackButton'
import style from '../styles/registration.module.scss'

interface IProps {
  nextStage: () => void
  setEmail: (value) => void
  email: string
}

export interface IFormInputs {
  username?: string
  non_field_errors?: string
}

const SendEmail = ({
  nextStage,
  email,
  setEmail
}: IProps): React.ReactElement => {
  const intl = useIntl()
  const { api } = useStores()
  const globalConfig = useGlobalConfig()

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting }
  } = useForm<IFormInputs>({
    defaultValues: {
      username: email
    }
  })

  const onSubmit = async (params): Promise<void> => {
    try {
      const res = await api.sendCodeRestorePassword({
        username: params.username
      })
      nextStage()
      return res
    } catch (error) {
      const errors = reactHookFormErrorFormater(params, error)
      errors.forEach((item) => {
        setError(item.key as keyof IFormInputs, {
          type: '400',
          message: item.value
        })
      })
    }
  }

  const nonFieldErrors = errors?.non_field_errors?.message ?? ''

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className={style.top}>
        <BackButton href={globalConfig.login_url} />

        <Title className={style.top__title}>
          <FormattedMessage
            id='profile.forgot_pass.title'
            defaultMessage='Forgot password'
          />
        </Title>
      </div>

      <div className={style.form}>
        <p>
          <FormattedMessage
            id='profile.forgot_pass.email_text'
            defaultMessage='Please enter the email you will use to sign in to the system.'
          />
        </p>

        <Controller
          control={control}
          name='username'
          render={({ field: { onChange, value } }) => {
            return (
              <Input
                type='email'
                className={style.form__input}
                label={intl.formatMessage({
                  id: 'registration.email',
                  defaultMessage: 'Email'
                })}
                required
                value={value}
                onChange={(e) => {
                  setEmail(e.target.value)
                  onChange(e.target.value)
                }}
                placeholder='mail@mail.com'
                error={errors?.username?.message ?? ''}
              />
            )
          }}
        />

        <div className={style.form__bottom}>
          <Button type='submit' disabled={isSubmitting} size='lg'>
            <Icon src='arrow_right' slot='icon-left' />
            <FormattedMessage id='registration.next' defaultMessage='Next' />
          </Button>
        </div>

        {nonFieldErrors !== ''
          ? (
            <ErrorsBlock errorsArray={[nonFieldErrors]} />
            )
          : null}
      </div>
    </Form>
  )
}

export default SendEmail
