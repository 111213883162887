import { GxMenu } from '@garpix/garpix-web-components-react'
import classNames from 'classnames'
import React from 'react'
// import style from './styles/dropdown.module.scss'

const DropdownMenu = ({ children, className = '' }): React.ReactElement => {
  const classes = classNames({
    // [style.menu]: true,
    [className]: Boolean(className)
  })
  return <GxMenu className={classes}>{children}</GxMenu>
}

export default DropdownMenu
