import Container from '@/components/Container'
import { Form } from '@/components/Form'
import React from 'react'
import { useForm } from 'react-hook-form'
import style from './style/profile.module.scss'
import Information from './components/Information'
import { IUserProfile } from '@/interfaces/User'
import BlockChangeAvatar from './components/BlockChangeAvatar'
import { usePage, useStores } from '@/hooks'
import { reactHookFormErrorFormater } from '@/utils'

export interface IFormInputs extends Partial<IUserProfile> {
  non_field_errors?: string
  delete_avatar: boolean
}

const EditProfile = (): React.ReactElement => {
  const page = usePage()
  const { api } = useStores()

  const currentUser: IUserProfile = page.init_state.current_user

  const {
    register,
    handleSubmit,
    control,
    watch,
    setError,
    setValue,
    formState: { isSubmitting, errors }
  } = useForm<IFormInputs>({
    defaultValues: {
      avatar: currentUser.avatar,
      first_name: currentUser.first_name,
      last_name: currentUser.last_name,
      telegram_url: currentUser.telegram_url,
      gitlab_url: currentUser.gitlab_url,
      delete_avatar: false
    }
  })

  const onSubmit = async (params): Promise<void> => {
    try {
      await api.updateProfile(params)
      location.replace(location.pathname + '?modal=success')
    } catch (error) {
      const errors = reactHookFormErrorFormater(params, error, 'data')
      errors.forEach((item) => {
        setError(item.key as keyof IFormInputs, {
          message: item.value
        })
      })
    }
  }

  const avatar = watch('avatar')
  const email = currentUser.email
  const phone = currentUser.phone

  return (
    <Container>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className={style.content}>
          <BlockChangeAvatar
            name={`${currentUser.first_name} ${currentUser.last_name}`}
            register={register}
            errors={errors}
            setValue={setValue}
            avatar={avatar}
          />

          <Information
            register={register}
            errors={errors}
            control={control}
            username={currentUser.username}
            email={email}
            phone={phone}
            isSubmitting={isSubmitting}
          />
        </div>
      </Form>
    </Container>
  )
}

export default EditProfile
