import React from 'react'
import BadgeStatus from './Badges/BadgeStatus'
import CaseStatus from './CaseStatus'
import TaskStatus from './TaskStatus'
import { HISTORY_MODEL } from '@/const'
import { TBadgeStatus, TCaseStatus, TRunStatus } from '@/interfaces/Types'
import { TModel } from '@/interfaces/History'

interface IProps {
  model: TModel
  status: TRunStatus | TCaseStatus | TBadgeStatus | never
}

const AllHistoryStatus: React.FC<IProps> = ({
  status,
  model
}) => {
  switch (model) {
    case HISTORY_MODEL.case_run:
      return <BadgeStatus status={status as TBadgeStatus} />
    case HISTORY_MODEL.case:
      return <CaseStatus status={status as TCaseStatus} />
    case HISTORY_MODEL.run:
      return <TaskStatus status={status as TRunStatus} />
    default:
      return null
  }
}

export default AllHistoryStatus
