import Container from '@/components/Container'
import React, { useState } from 'react'
import Activity from './components/Activity'
import Head from './components/Head'
import Result from './components/Result'
import Summary from './components/Summary'
import { IPeriodSelectValue } from '@/components/Select/interfaces/SelectProps'
import { initialDates } from '@/components/Select/utils'

const ShareReportDetail = (): React.ReactElement => {
  const [dates, setDates] = useState<IPeriodSelectValue>(initialDates())

  return (
    <Container>
      <Head dates={dates} />

      <Result />

      <Activity dates={dates} setDates={setDates} />

      <Summary />
    </Container>
  )
}

export default ShareReportDetail
