import React from 'react'
import style from './styles/memberlist.module.scss'
import Pagination from '@/components/Pagination'
import PaginatedQueryResult from '@/api/interfaces/PaginatedQueryResult'
import {
  IProjectMember,
  IProjectRole,
  IProjectRoleShort
} from '@/interfaces/Member'
import Member from '@/components/Member'

interface IProps {
  data: PaginatedQueryResult<IProjectMember>
  currentPage: number
  pageSize: number
  setPage: (string) => void
  roles: IProjectRoleShort[]
  projectId: number
  onRemove: (value: number) => Promise<void>
  currentUser: {
    abac_role: IProjectRole
    role: string
    id: number
  }
}

const MemberList = ({
  roles,
  data,
  currentPage,
  pageSize,
  setPage,
  projectId,
  currentUser,
  onRemove
}: IProps): React.ReactElement => {
  return (
    <>
      <div className={style.memberlist__list}>
        {data.results.map((el) => {
          return (
            <Member
              roles={roles}
              projectId={projectId}
              key={el.id}
              id={el.id}
              email={el.email}
              firstName={el.first_name}
              lastName={el.last_name}
              avatar={el.avatar}
              abacRole={el.abac_role}
              profileUrl={el.profile_url}
              currentUser={currentUser}
              onRemove={onRemove}
              isOwner={el.is_owner}
              isRemovable
            />
          )
        })}
      </div>

      <div className={style.memberlist__pagination}>
        <Pagination
          currentPage={currentPage}
          total={data.count}
          pageSize={pageSize}
          handleChange={setPage}
        />
      </div>
    </>
  )
}

export default MemberList
