import React from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import styles from './styles/sortable.module.scss'
import Icon from '../Icon/Icon'

interface ISortableItemWrapper {
  id: number | string
  children: React.ReactNode
  disabled: boolean
}

export const SortableItemWrapper = ({
  id,
  children,
  disabled
}: ISortableItemWrapper): JSX.Element => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  }

  return (
    <div ref={setNodeRef} style={style} className={styles.sortable__wrapper}>
      <div className={styles.sortable__item}>
        {disabled
          ? null
          : (
            <Icon
              {...attributes}
              {...listeners}
              className={styles.sortable__action}
              src='action'
            />
            )}

        {children}
      </div>
    </div>
  )
}
