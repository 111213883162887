import React from 'react'
import AddOrEditRun from '../AddOrEditRun'
import ProjectLayout from '../layout/components/ProjectLayout'
import UserLayout from '../layout/components/UserLayout'

const RunCreate = (): React.ReactElement => {
  return (
    <UserLayout>
      <ProjectLayout>
        <AddOrEditRun />
      </ProjectLayout>
    </UserLayout>
  )
}

export default RunCreate
