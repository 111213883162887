import classNames from 'classnames'
import React from 'react'
import style from '../styles/status.module.scss'
import { TBadgeStatus } from '../../../interfaces/Types'

interface IProps {
  status: TBadgeStatus
  count: number
  percent: number
  className?: string
}

const BadgeSimpleStatus = ({
  status,
  count,
  percent,
  className = ''
}: IProps): React.ReactElement => {
  return (
    <div
      className={classNames({
        [style.badge]: true,
        [style['badge--simple']]: true,
        [style[`test--${status.toLowerCase()}`]]: true,
        [className]: Boolean(className)
      })}
    >
      <span>{count}</span>
      <span className={style.simple__percent}>{percent}%</span>
    </div>
  )
}

export default BadgeSimpleStatus
