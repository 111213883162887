import usePage from '@/hooks/usePage'
import { TPermissionKey, TPermissionLevel } from '@/interfaces/Types'

export const useCurrentPermissions = (
  permissionKey: TPermissionKey,
  allowedLevels: TPermissionLevel[]
): boolean => {
  const page = usePage()

  const userPermissions = page.init_state.project_object.current_user.abac_role.permissions
  const userPermissionLevel = userPermissions[permissionKey]

  return allowedLevels.includes(userPermissionLevel)
}
