import React from 'react'
import Title from '@/components/Title'
import Icon from '@/components/Icon/Icon'
import { FormattedMessage } from 'react-intl'
import Checkbox from '@/components/Checkbox/Checkbox'
import UserSelect from '@/components/Select/UserSelect'
import { Control, Controller, UseFormClearErrors } from 'react-hook-form'
import Button from '@/components/Button/Button'
import RoleCompanySelect from '@/components/Select/RoleCompanySelect'
import { IFormInputs } from './ChangeOwner'
import { IValue } from '@/components/Select/interfaces/AsyncSelect'
import style from '../styles/company.module.scss'

interface IProps {
  onClose: () => void
  idCompany: number
  success: () => void
  control: Control<IFormInputs, any>
  clearErrors: UseFormClearErrors<IFormInputs>
  errors: any
  isSubmitting: boolean
  isStayCompany: boolean
  userValue: IValue | null
  roleValue?: string
  nonFieldErrors: string
  setStayCompany: (value: boolean) => void
}

const ChooseUser = ({
  idCompany,
  onClose,
  success,
  isSubmitting,
  errors,
  control,
  isStayCompany,
  roleValue,
  userValue,
  nonFieldErrors,
  setStayCompany,
  clearErrors
}: IProps): React.ReactElement => {
  const handleChangeCheckbox = (): void => {
    setStayCompany(!isStayCompany)
  }

  const isDisabledButton =
    (isStayCompany && (roleValue === '' || userValue == null)) ||
    (!isStayCompany && userValue === null)

  const handleNextModal = (): void => {
    clearErrors('non_field_errors')
    success()
  }

  return (
    <>
      <div className={style.change_owner__content}>
        <Icon
          src='reverseArrow'
          size='xxxl'
          className={style.change_owner__icon}
        />

        <Title className={style.change_owner__title}>
          <FormattedMessage
            id='company.change_owner.title'
            defaultMessage='Change the company owner'
          />
        </Title>

        <Checkbox onGx-change={handleChangeCheckbox} checked={isStayCompany}>
          <FormattedMessage
            id='company.change_owner.checkbox'
            defaultMessage='Stay in the company'
          />
        </Checkbox>

        <Controller
          control={control}
          name='user'
          render={({ field: { onChange, value } }) => {
            return (
              <UserSelect
                label='company.change_owner.new'
                required
                handleChange={onChange}
                value={value}
                error={errors?.user?.message ?? ''}
                companyId={idCompany}
                isClearable
              />
            )
          }}
        />

        {isStayCompany
          ? (
            <Controller
              control={control}
              name='role'
              render={({ field: { onChange, value } }) => {
                return (
                  <RoleCompanySelect
                    bannedRoles='owner'
                    isFromChangeOwner
                    handleChange={onChange}
                    value={value}
                    error={errors?.role?.message ?? ''}
                    required
                  />
                )
              }}
            />
            )
          : (
            <p className={style.change_owner__warning}>
              <FormattedMessage
                id='company.change_owner.warning'
                defaultMessage='After the change of owner, you will be removed from the company.'
              />
            </p>
            )}

        {nonFieldErrors !== ''
          ? (
            <div className={style.change_owner__error}>{nonFieldErrors}</div>
            )
          : null}
      </div>

      <div className={style.change_owner__bottom}>
        <Button onClick={onClose} theme='light'>
          <Icon src='clear' slot='icon-left' />

          <FormattedMessage
            id='company.change_owner.cancel'
            defaultMessage='Cancel'
          />
        </Button>

        {isStayCompany
          ? (
            <Button disabled={isSubmitting || isDisabledButton} type='submit'>
              <Icon src='checked' slot='icon-left' />

              <FormattedMessage
                id='company.change_owner.confirm'
                defaultMessage='Change'
              />
            </Button>
            )
          : (
            <Button disabled={isDisabledButton} onClick={handleNextModal}>
              <Icon src='checked' slot='icon-left' />

              <FormattedMessage
                id='company.change_owner.confirm'
                defaultMessage='Change'
              />
            </Button>
            )}
      </div>
    </>
  )
}

export default ChooseUser
