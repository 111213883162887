import { ModalAction } from '@/components/Modal'
import React, { useEffect, useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Title from '@/components/Title'
import Input from '@/components/Input/Input'
import { getTrimedString } from '@/utils'

interface IProps {
  onCancel: () => void
  onAddRole: (roleName: string) => void
  onRenameRole: (roleName: string) => void
  defaultRoleName?: string
  errors?: string | string[]
}

const AddRole = ({
  onCancel,
  onAddRole,
  onRenameRole,
  defaultRoleName,
  errors
}: IProps): React.ReactElement => {
  const [value, setValue] = useState<string>(defaultRoleName ?? '')

  const inputRef = useRef<HTMLGxInputElement>(null)
  const intl = useIntl()

  const title =
    defaultRoleName !== undefined
      ? (
        <FormattedMessage id='roles.edit_role' defaultMessage='Edit role' />
        )
      : (
        <FormattedMessage id='roles.add_role' defaultMessage='Add role' />
        )

  const confirmText =
    defaultRoleName !== undefined
      ? intl.formatMessage({
        id: 'common.save',
        defaultMessage: 'Save'
      })
      : intl.formatMessage({
        id: 'common.add',
        defaultMessage: 'Add'
      })

  const handleChange = (e): void => {
    setValue(e.target.value)
  }

  const handleAction = (): void => {
    const trimValue = getTrimedString(value)
    if (defaultRoleName !== undefined) {
      onRenameRole(trimValue)
    } else {
      onAddRole(trimValue)
    }
  }

  useEffect(() => {
    // setTimeout нужен так как панель модалки перехватывает фокус после анимации при открытии
    setTimeout(() => {
      inputRef?.current?.setFocus()
    }, 300)
  }, [])

  return (
    <ModalAction
      onCancel={onCancel}
      onAction={handleAction}
      disableActionButton={value === ''}
      confirmText={confirmText}
    >
      <Title type='h2' theme='h1'>
        {title}
      </Title>

      <Input
        required
        onChange={handleChange}
        value={value}
        label={intl.formatMessage({
          id: 'roles.role_name',
          defaultMessage: 'Name'
        })}
        clearable
        error={errors}
        ref={inputRef}
      />
    </ModalAction>
  )
}

export default AddRole
