import ProjectSelect from '@/components/Select/ProjectSelect'
import Title from '@/components/Title'
import Wrapper from '@/components/Wrapper'
import useSWR from 'swr'
import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import style from '../styles/dashboard.module.scss'
import { useStores } from '@/hooks'
import RunItem from '@/components/RunItem'
import { EmptySearch } from '@/components/EmptyResults'
import SearchInput from '@/components/Input/SearchInput/SearchInput'
import useDebounce from '@/hooks/useDebounce'
import { observer } from 'mobx-react'
import EmptyList from '@/components/EmptyList/EmptyList'
import { LoaderRunsDashboard } from '../loaders'

const pageSize = 7

const Runs = observer((): React.ReactElement => {
  const [projectId, setProjectId] = useState<string | number>('')
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search)
  const intl = useIntl()
  const store = useStores()
  const currentCompany = store.companyStore.getCompany()

  const { data, isLoading } = useSWR(
    {
      q: debouncedSearch,
      project: projectId,
      page: 1,
      page_size: pageSize,
      company: currentCompany,
      _key: 'getRuns'
    },
    store.api.getRuns
  )
  const runs = data?.results ?? []

  const handleChange = (e): void => {
    setProjectId(e?.value ?? '')
  }

  const noRunsInCompany =
    !isLoading && data !== undefined && runs.length === 0 && search === ''

  return (
    <Wrapper className={style.runs}>
      <div className={style.top}>
        <Title type='h2' theme='h1' className={style.title}>
          <FormattedMessage id='titles.runs' defaultMessage='Test Runs' />
        </Title>

        <ProjectSelect
          labelLeft
          isClearable
          handleChange={handleChange}
          selectClassName={style.runs__select}
          labelClassName={style.runs__select_label}
          companyId={currentCompany}
          // key - хак для принудительной перезагрузки опций
          key={currentCompany ?? 'company'}
        />
        <SearchInput
          handleChange={setSearch}
          value={search}
          placeholder={intl.formatMessage({
            id: 'runs.search',
            defaultMessage: 'Search runs'
          })}
          clearable
          className={style.search}
        />
      </div>

      {!isLoading && runs.length > 0
        ? (
          <>
            {runs.map((el) => {
              return (
                <RunItem
                  key={el.id}
                  title={el.title}
                  deadline={el.deadline}
                  absoluteUrl={el.absolute_url}
                  milestone={el.milestone}
                  id={el.id}
                  assignedTo={el.assigned_to}
                  statistic={el.statistics}
                  className={style.item}
                  isFromDashboard
                />
              )
            })}
          </>
          )
        : null}

      {isLoading ? <LoaderRunsDashboard countShimmers={pageSize} /> : null}

      {!isLoading && runs.length === 0 && search !== ''
        ? (
          <EmptySearch />
          )
        : null}

      {noRunsInCompany
        ? (
          <EmptyList
            icon='run'
            text={intl.formatMessage({
              id: 'runs.last_runs.empty',
              defaultMessage: 'You don’t have Test Runs'
            })}
            className={style.steps__empty}
          />
          )
        : null}
    </Wrapper>
  )
})

export default Runs
