import classNames from 'classnames'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import Icon from '../Icon/Icon'
import style from './styles/empty.module.scss'

const EmptyChart = ({ className = '' }): React.ReactElement => {
  return (
    <div
      className={classNames({
        [style.empty]: true,
        [className]: Boolean(className)
      })}
    >
      <Icon src='error' className={style.empty__icon} />
      <p className={style.empty__text}>
        <FormattedMessage
          id='empty_results.chart'
          defaultMessage='There is no data to display graph'
        />
      </p>
    </div>
  )
}

export default EmptyChart
