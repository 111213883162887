import React, { Dispatch } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import NameOfBranchField from './NameOfBranchField'
import Button from '@/components/Button/Button'
import Icon from '@/components/Icon/Icon'
import InputDayPicker from '@/components/InputDayPicker/InputDayPicker'
import { FormattedMessage, useIntl } from 'react-intl'
import { compareAsc, format, parse } from 'date-fns'
import Title from '@/components/Title'
import { IGitlabReportItem } from '@/interfaces/Project'
import {
  IGitlabReportFormData,
  INTERVAL_METHOD,
  REPORT_TYPE
} from '../interface'
import { Form } from '@/components/Form'
import { useObjectPage, useStores } from '@/hooks'
import ReportNameInput from './ReportNameInput'
import { KeyedMutator } from 'swr'
import RadioGroup from '@/components/RadioGroup'
import { intervalMethodOptions, reportTypeOptions } from '../utils'
import { IValue } from '@/components/Select/interfaces/AsyncSelect'
import TagField from './TagField'
import style from '../styles/gitlab_report.module.scss'

interface IGitlabReportFormProps {
  reportListMutate: KeyedMutator<IGitlabReportItem[]>
  isFormGenerate: boolean
  setIsFormGenerate: Dispatch<React.SetStateAction<boolean>>
}

const GitlabReportForm = ({
  reportListMutate,
  isFormGenerate,
  setIsFormGenerate
}: IGitlabReportFormProps): JSX.Element => {
  const intl = useIntl()
  const { api } = useStores()
  const { id } = useObjectPage()
  const { watch, setValue, handleSubmit, control } =
    useFormContext<IGitlabReportFormData>()

  const startDate = watch('startDate')
  const endDate = watch('endDate')

  const isReportWithDescription = (reportType: string): boolean =>
    reportType === REPORT_TYPE.withDescription
  const isIntervalByTime = (intervalMethod: string): boolean =>
    intervalMethod === INTERVAL_METHOD.time
  const separateDates = (startDate: string, endDate: string): string =>
    startDate + ' / ' + endDate

  const setReportNameByCondition = (
    data: Pick<
    IGitlabReportFormData,
    'intervalMethod' | 'startDate' | 'endDate' | 'reportName'
    >
  ): string => {
    if (isIntervalByTime(data.intervalMethod)) {
      return separateDates(data.startDate, data.endDate)
    } else {
      return data.reportName
    }
  }

  const onSubmit = async (data: IGitlabReportFormData): Promise<void> => {
    setIsFormGenerate(true)
    const objectToServer = {
      branch: data.branchName,
      is_date_interval: isIntervalByTime(data.intervalMethod),
      tag_from: data.startTag,
      tag_to: data.endTag,
      tag_new: setReportNameByCondition(data),
      report_format: isReportWithDescription(data.reportType),
      updated_after: data.startDate,
      updated_before: data.endDate
    }
    await api.postGitlabReport(id, objectToServer)
    await reportListMutate()
    setIsFormGenerate(false)
  }

  const handleChangeStartDate = (date: string): void => {
    const parseStateDate = parse(date, 'dd/MM/yyyy', new Date())
    const formattedDate = format(parseStateDate, 'yyyy-MM-dd')
    setValue('startDate', formattedDate)
    const parseEndDate = parse(endDate, 'dd/MM/yyyy', new Date())
    if (compareAsc(parseEndDate, parseStateDate) === -1) {
      setValue('endDate', '')
    }
  }

  const handleChangeEndDate = (date: string): void => {
    const parseStateDate = parse(date, 'dd/MM/yyyy', new Date())
    const formattedDate = format(parseStateDate, 'yyyy-MM-dd')
    setValue('endDate', formattedDate)
  }

  const handleBranchChange = (branch: IValue): void => {
    setValue('branchName', branch.label)
  }

  const handleChangeTagStart = (selectedValue: IValue | null): void => {
    if (selectedValue != null) {
      setValue('startTag', selectedValue.label)
    }
  }

  const handleChangeTagEnd = (selectedValue: IValue | null): void => {
    if (selectedValue != null) {
      setValue('endTag', selectedValue.label)
    }
  }

  return (
    <Form className={style.reports__form} onSubmit={handleSubmit(onSubmit)}>
      <div>
        <Title type='h3' theme='h2' className={style.reports__formtitle}>
          <FormattedMessage
            id='reports.gitlab.type_of_report'
            defaultMessage='Type of report'
          />
        </Title>
        <RadioGroup
          control={control}
          name='reportType'
          options={reportTypeOptions}
        />
      </div>

      <div>
        <Title type='h3' theme='h2' className={style.reports__formtitle}>
          <FormattedMessage
            id='reports.gitlab.method_of_setting'
            defaultMessage='Method of setting the interval'
          />
        </Title>
        <RadioGroup
          control={control}
          name='intervalMethod'
          options={intervalMethodOptions}
        />
      </div>

      {isIntervalByTime(watch('intervalMethod'))
        ? (
          <div>
            <Title type='h3' theme='h2' className={style.reports__formtitle}>
              <FormattedMessage
                id='reports.gitlab.mr'
                defaultMessage='MR Creation Dates'
              />
            </Title>

            <div className={style.reports__btngroup}>
              <Controller
                control={control}
                name='startDate'
                render={({ field: { value } }) => {
                  return (
                    <InputDayPicker
                      label={intl.formatMessage({
                        id: 'milestones.form.start_date',
                        defaultMessage: 'Start date'
                      })}
                      placeholder={intl.formatMessage({
                        id: 'input.yyyy/mm/dd',
                        defaultMessage: 'yyyy/mm/dd'
                      })}
                      value={value}
                      onChangeHandlerInput={handleChangeStartDate}
                      onChangeHandlerPicker={handleChangeStartDate}
                      className={style.form__date}
                    />
                  )
                }}
              />

              <Controller
                control={control}
                name='endDate'
                render={({ field: { value } }) => {
                  return (
                    <InputDayPicker
                      label={intl.formatMessage({
                        id: 'milestones.form.end_date',
                        defaultMessage: 'End date'
                      })}
                      placeholder={intl.formatMessage({
                        id: 'input.yyyy/mm/dd',
                        defaultMessage: 'yyyy/mm/dd'
                      })}
                      value={value}
                      onChangeHandlerInput={handleChangeEndDate}
                      onChangeHandlerPicker={handleChangeEndDate}
                      className={style.form__date}
                      disabledDays={[
                        {
                          from: new Date(1970, 1, 1),
                          to: parse(startDate, 'yyyy/MM/dd', new Date())
                        }
                      ]}
                    />
                  )
                }}
              />
            </div>
          </div>
          )
        : (
          <>
            <NameOfBranchField
              selectClassName={style.reports__select}
              handleChange={handleBranchChange}
              label={intl.formatMessage({
                id: 'reports.gitlab.name_of_branch',
                defaultMessage: 'Name of branch'
              })}
              placeholder={intl.formatMessage({
                id: 'input.placeholder',
                defaultMessage: 'Select...'
              })}
            />

            <TagField
              selectClassName={style.reports__select}
              handleChange={handleChangeTagStart}
              label={intl.formatMessage({
                id: 'reports.gitlab.tag_after',
                defaultMessage: 'Tag after which to generate a report'
              })}
              placeholder={intl.formatMessage({
                id: 'input.placeholder',
                defaultMessage: 'Select...'
              })}
            />

            <TagField
              selectClassName={style.reports__select}
              handleChange={handleChangeTagEnd}
              label={intl.formatMessage({
                id: 'reports.gitlab.tag_before',
                defaultMessage: 'Tag up to which to generate a report'
              })}
              placeholder={intl.formatMessage({
                id: 'input.placeholder',
                defaultMessage: 'Select...'
              })}
            />
            <ReportNameInput />
          </>
          )}

      <Button
        type='submit'
        disabled={isFormGenerate}
        className={style.reports__submit}
      >
        <Icon src='video' className={style.reports__titleback} />
        <FormattedMessage
          id='reports.gitlab.generate'
          defaultMessage='Generate'
        />
      </Button>
    </Form>
  )
}

export default GitlabReportForm
