import React, { ReactElement, useEffect, useState } from 'react'
import { NodeModel } from '@minoru/react-dnd-treeview'
import Icon from '@/components/Icon/Icon'
import classNames from 'classnames'
import { TextTag } from '@/components/Tag'
import { observer } from 'mobx-react'
import { ICountSelected, ISuiteCheckbox } from '@/interfaces/Suites'
import Checkbox from '@/components/Checkbox/Checkbox'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import style from '../styles/update_cases.module.scss'

const LayDndTree = React.lazy(
  async () =>
    await import('@/components/DndTree').then(({ DndTree }) => ({
      default: DndTree
    }))
)

interface IProps {
  data: ISuiteCheckbox[]
  currentSuite?: number
  setCurrentSuite: (value: number | undefined) => void
  checkSuite: (suiteId: number, isChecked: boolean) => void
  countSelected: ICountSelected
}

const ClickZone = observer(
  ({
    node,
    onToggle,
    isOpen,
    isDragging,
    hasChild,
    setCurrentSuite,
    checkSuite,
    currentSuite,
    countSelected
  }) => {
    const nodeId = Number(node.id)
    const [isChecked, setChecked] = useState(node.isChecked)
    const [isIndeterminate, setIndeterminate] = useState(false)
    const [isAddCases, setIsAddCases] = useState(false)
    const { search: searchQuery } = useLocation()

    const handleClick = (): void => {
      if (currentSuite === nodeId) {
        setCurrentSuite(undefined)
      } else {
        setCurrentSuite(nodeId)
      }
    }

    const handleCheckbox = ({
      target
    }: React.ChangeEvent<HTMLInputElement>): void => {
      const value = isIndeterminate ? true : target.checked
      setChecked(value)
      checkSuite(nodeId, value)
    }

    useEffect(() => {
      if (countSelected[nodeId] !== undefined) {
        if (countSelected[nodeId]?.selected > 0) {
          setIndeterminate(true)
        }
        if (countSelected[nodeId]?.selected === countSelected[nodeId]?.total) {
          setIndeterminate(false)
          setChecked(true)
        }
        if (countSelected[nodeId]?.selected === 0) {
          setIndeterminate(false)
          setChecked(false)
        }
      }
    }, [countSelected])

    useEffect(() => {
      const qs = queryString.parse(searchQuery, {
        parseNumbers: true
      })

      if (qs.nodeId === nodeId && !isAddCases) {
        checkSuite(nodeId, true)
        setChecked(true)
        setIsAddCases(true)
      }
    }, [])

    return (
      <div className={style.dnd__item} key={nodeId}>
        <Checkbox
          size='medium'
          onGx-change={handleCheckbox}
          checked={isChecked}
          isIndeterminate={isIndeterminate}
        />

        <button
          type='button'
          onClick={handleClick}
          className={classNames({
            [style.dnd__titlebtn]: true,
            [style['dnd__titlebtn--opened']]: nodeId === currentSuite
          })}
        >
          <Icon src='folders' />

          <div>{node.text}</div>
        </button>

        <div className={style.dnd__right}>
          <TextTag className={style.dnd__tag}>
            <Icon src='test_case' />
            {countSelected[node.id]?.selected ?? 0} /
            {countSelected[node.id]?.total ?? 0}
          </TextTag>

          {hasChild === true
            ? (
              <button
                type='button'
                onClick={onToggle}
                className={style.dnd__openbtn}
              >
                <Icon
                  src='arrow_down'
                  className={classNames({
                    [style.dnd__arrow]: true,
                    [style.dnd__arrow_open]: isOpen
                  })}
                />
              </button>
              )
            : null}
        </div>
      </div>
    )
  }
)

interface IRenderProps {
  depth: number
  isOpen: boolean
  isDragging: boolean
  onToggle: () => void
  hasChild: boolean
  currentSuite?: number
  setCurrentSuite: (value: number | undefined) => void
  checkSuite: (suiteId: number, isChecked: boolean) => void
  countSelected: ICountSelected
}

const render = (
  node: NodeModel<any>,
  {
    depth,
    isOpen,
    isDragging,
    hasChild,
    onToggle,
    currentSuite,
    setCurrentSuite,
    checkSuite,
    countSelected
  }: IRenderProps
): ReactElement => {
  return (
    <div className={style.dnd}>
      {node.droppable !== undefined
        ? (
          <ClickZone
            key={node.id}
            isOpen={isOpen}
            node={node}
            onToggle={onToggle}
            isDragging={isDragging}
            hasChild={hasChild}
            setCurrentSuite={setCurrentSuite}
            currentSuite={currentSuite}
            checkSuite={checkSuite}
            countSelected={countSelected}
          />
          )
        : null}
    </div>
  )
}
const SuitesDndListWithChecks = ({
  data = [],
  currentSuite,
  setCurrentSuite,
  checkSuite,
  countSelected
}: IProps): React.ReactElement => {
  interface IData {
    casesCount: number
    sort: number
  }

  interface IFormatData {
    id: number
    parent: number
    droppable: boolean
    text: string
    data: IData
    isChecked: boolean
  }
  const formatData: IFormatData[] = data.map((element) => {
    const isHasParent = data.findIndex((el) => el.id === element.parent) !== -1

    return {
      id: element.id,
      parent: element.parent === null || !isHasParent ? 0 : element.parent,
      droppable: true,
      text: element.title,
      isChecked: element.isChecked,
      data: { casesCount: element.cases_count, sort: element.sort }
    }
  })

  return (
    <LayDndTree
      render={(node, params) => {
        const newParams = {
          ...params,
          setCurrentSuite,
          checkSuite,
          currentSuite,
          countSelected
        }
        return render(node, newParams)
      }}
      data={formatData}
      nodeArray
    />
  )
}

export default SuitesDndListWithChecks
