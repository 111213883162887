import { makeAutoObservable } from 'mobx'

export type TSwitchStorage = 'list' | 'tile'

export const SWITCH_VALUE: {
  list: TSwitchStorage
  tile: TSwitchStorage
} = {
  list: 'list',
  tile: 'tile'
}

class ProjectSwitcher {
  #localStorageKey: string
  switch: TSwitchStorage

  constructor () {
    this.#localStorageKey = 'project_switch'
    if (typeof window !== 'undefined') {
      const parsedStorage = this.getParsedStorage()
      this.switch = parsedStorage as TSwitchStorage
    } else {
      this.switch = SWITCH_VALUE.tile
    }
    makeAutoObservable(this)
  }

  getParsedStorage = (): string => {
    const projectSwitchStorage = localStorage?.getItem(this.#localStorageKey)

    if (projectSwitchStorage === null) {
      localStorage.setItem(this.#localStorageKey, SWITCH_VALUE.tile)
      return SWITCH_VALUE.tile
    }

    return projectSwitchStorage
  }

  toggle = (): void => {
    if (this.switch === SWITCH_VALUE.tile) {
      this.switch = SWITCH_VALUE.list
      localStorage.setItem(this.#localStorageKey, SWITCH_VALUE.list)
    } else {
      this.switch = SWITCH_VALUE.tile
      localStorage.setItem(this.#localStorageKey, SWITCH_VALUE.tile)
    }
  }

  changeStorageValue = (value: TSwitchStorage): void => {
    this.switch = value
    localStorage.setItem(this.#localStorageKey, value)
  }
}

export const projectsService = new ProjectSwitcher()
