import React, { useState } from 'react'
import Wrapper from '@/components/Wrapper'
import BackButton from '@/components/Button/BackButton'
import Title from '@/components/Title'
import { FormattedMessage } from 'react-intl'
import Button from '@/components/Button/Button'
import Icon from '@/components/Icon/Icon'
import { usePage, useStores } from '@/hooks'
import { downloadFile } from '@/utils'
import style from '../style/report_detail.module.scss'

const Head = ({ dates }): React.ReactElement => {
  const [isGenerating, setIsGenerating] = useState(false)

  const { init_state: initState } = usePage()
  const store = useStores()

  const backLink = initState.reports_list_url
  const run = initState.run_object
  const shareHash = initState.share_hash

  const handleGetPdf = (): void => {
    setIsGenerating(true)
    const params = {
      date_from: dates.start,
      date_to: dates.end,
      run_id: run.id,
      share_hash: shareHash
    }

    store.api
      .getShareReportPdf(params)
      .then((res) => {
        const date = new Date()
        const fileName =
          'Report_' +
          String(run.title) +
          '_' +
          date.toJSON().slice(0, 10) +
          '.pdf'
        downloadFile(res.data, fileName)
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => setIsGenerating(false))
  }

  const formattedDate = store.localeDate.formatDateTime(run.updated_at)

  return (
    <Wrapper className={style.block}>
      <div className={style.head__top}>
        <BackButton href={backLink} />

        <Title type='h1' className={style.head__title}>
          <FormattedMessage
            id='shared.titles.report'
            defaultMessage='Report on the run of the project {name}'
            values={{ name: initState.project_title }}
          />
        </Title>

        <Button onClick={handleGetPdf} disabled={isGenerating}>
          <Icon src='download' slot='icon-left' />
          <FormattedMessage
            id='reports.download_report'
            defaultMessage='Download report'
          />
        </Button>
      </div>

      <div className={style.head__bottom}>
        <div className={style.info}>
          <div
            className={style.info__color}
            style={{ backgroundColor: run.color }}
          />

          <div>
            <span className={style.info__code}>{run.code}</span>
            <span className={style.info__name}>{run.title}</span>
          </div>
        </div>

        <div className={style.head__date}>
          <span className={style.head__datetime}>{formattedDate}</span>
        </div>
      </div>
    </Wrapper>
  )
}

export default Head
