import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import Icon from '@/components/Icon/Icon'
import { Dropdown, DropdownMenu } from '@/components/Dropdown'
import Link from '@/components/Link'
import ModalAddCases from './ModalAddCases'
import useModal from '@/hooks/useModal'
import { IMilestone } from '@/interfaces/Milestones'
import ModalDeleteRun from './ModalDeleteRun'
import ModalMoveMilestone from './ModalMoveMilestone'
import classNames from 'classnames'
import style from '../styles/runitem.module.scss'
import { useCurrentPermissions } from '@/hooks/useCurrentPermissions'
import {
  CAN_VIEW_PERMISSION_LEVEL,
  EDIT_PERMISSION_LEVEL,
  PERMISSION_LEVEL,
  RUN_STATUS,
  USER_PERMISSIONS
} from '@/const'
import { TRunStatus } from '@/interfaces/Types'

interface IProps {
  milestone: IMilestone | null
  idRun: number
  runUrl: string
  titleRun: string
  mutateMilestones?: () => void
  status: TRunStatus
}

const RunDropdown = ({
  milestone,
  idRun,
  titleRun,
  runUrl,
  status,
  mutateMilestones
}: IProps): JSX.Element => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [isCopiedLink, setIsCopiedLink] = useState(false)

  const isReadRun = useCurrentPermissions(
    USER_PERMISSIONS.run,
    CAN_VIEW_PERMISSION_LEVEL
  )

  const isEditRun = useCurrentPermissions(
    USER_PERMISSIONS.run,
    EDIT_PERMISSION_LEVEL
  )

  const isDeleteRun = useCurrentPermissions(USER_PERMISSIONS.run, [
    PERMISSION_LEVEL.full
  ])

  const {
    isOpen: isOpenAddCases,
    handleOpenModal: handleOpenModalAddCases,
    handleCloseModal: handleCloseModalAddCases
  } = useModal()

  const {
    isOpen: isOpenDelete,
    handleOpenModal: handleOpenModalDelete,
    handleCloseModal: handleCloseModalDelete
  } = useModal()

  const {
    isOpen: isOpenMoveMilestone,
    handleOpenModal: handleOpenModalMoveMilestone,
    handleCloseModal: handleCloseModalMoveMilestone
  } = useModal()

  let timer: NodeJS.Timeout | undefined

  const handleCopyLink = async (): Promise<void> => {
    await navigator.clipboard
      .writeText(window.location.origin + runUrl)
      .then(() => {
        setIsCopiedLink(true)
        timer = setTimeout(() => {
          setIsCopiedLink(false)
        }, 2000)
      })
  }

  useEffect(() => {
    return () => {
      if (timer !== undefined) clearTimeout(timer)
    }
  }, [])

  const editUrl = `${runUrl}/update`

  if (!isReadRun) return <></>

  return (
    <>
      <Dropdown
        open={isDropdownOpen}
        closeOnSelect
        placement='bottom-end'
        onGx-show={() => {
          setIsDropdownOpen(true)
        }}
        onGx-hide={() => setIsDropdownOpen(false)}
      >
        <button slot='trigger' type='button'>
          <Icon src='etc' className={style.menu__dropdownbtn} />
        </button>

        <DropdownMenu className={style.menu}>
          {isEditRun && status !== RUN_STATUS.completed
            ? (
              <>
                <button
                  type='button'
                  className={style.menu__btn}
                  onClick={handleOpenModalAddCases}
                >
                  <Icon src='test_case' />

                  <FormattedMessage
                    id='runs.menu.edit_case'
                    defaultMessage='Edit test case'
                  />
                </button>

                <div className={style.menu__line} />
              </>
              )
            : null}

          {isEditRun && status !== RUN_STATUS.completed
            ? (
              <Link to={editUrl} className={style.menu__btn}>
                <Icon src='edit' />

                <FormattedMessage id='common.edit' defaultMessage='Edit' />
              </Link>
              )
            : null}

          {isEditRun && status !== RUN_STATUS.completed
            ? (
              <button
                type='button'
                className={style.menu__btn}
                onClick={handleOpenModalMoveMilestone}
              >
                <Icon src='moveTo' />

                <FormattedMessage
                  id='runs.menu.move'
                  defaultMessage='Move to milestone'
                />
              </button>
              )
            : null}

          {isDeleteRun
            ? (
              <button
                type='button'
                className={classNames(style.menu__btn, style.menu__delete_icon)}
                onClick={handleOpenModalDelete}
              >
                <Icon src='delete' className={style.menu__delete_icon} />

                <FormattedMessage id='common.delete' defaultMessage='Delete' />
              </button>
              )
            : null}

          {isEditRun ? <div className={style.menu__line} /> : null}

          <button
            type='button'
            className={style.menu__btn}
            onClick={handleCopyLink}
          >
            {!isCopiedLink
              ? (
                <Icon src='attach' />
                )
              : (
                <Icon src='checked' className={style.menu__check} />
                )}

            <FormattedMessage id='runs.menu.copy' defaultMessage='Copy link' />
          </button>
        </DropdownMenu>
      </Dropdown>

      {isOpenAddCases
        ? (
          <ModalAddCases
            isOpen={isOpenAddCases}
            handleCloseModal={handleCloseModalAddCases}
            idRun={idRun}
            milestone={milestone}
          />
          )
        : null}

      {isOpenDelete
        ? (
          <ModalDeleteRun
            title={titleRun}
            isOpen={isOpenDelete}
            handleCloseModal={handleCloseModalDelete}
            mutateMilestones={mutateMilestones}
            idRun={idRun}
          />
          )
        : null}

      {isOpenMoveMilestone
        ? (
          <ModalMoveMilestone
            isOpen={isOpenMoveMilestone}
            handleCloseModal={handleCloseModalMoveMilestone}
            idMilestone={milestone?.id}
            idRun={idRun}
            mutateMilestones={mutateMilestones}
          />
          )
        : null}
    </>
  )
}

export default RunDropdown
