import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import classNames from 'classnames'
import {
  Control,
  Controller,
  UseFormRegister,
  UseFormSetValue,
  useFormContext
} from 'react-hook-form'
import { IFormData } from '../interfaces'
import Textarea from '@/components/Textarea'
import useSWR from 'swr'
import { useObjectPage, useStores } from '@/hooks'
import CustomFieldSelect from './CustomFieldSelect'
import AssigneeSelectField from './AssigneeSelectField'
import { IObjectPage } from '@/interfaces/ObjectPage'
import TypeOfTaskField from './TypeOfTaskField'
import Input from '@/components/Input/Input'
import JiraBugPrioritySelect from './JiraBugPrioritySelect'
import { estimationSchema } from '../schemas'
import { observer } from 'mobx-react'
import { Accordion } from '@/components/Accordion'
import Title from '@/components/Title'
import { UploadZoneForm } from '@/components/UploadZone'
import JiraTasksSelect from '@/components/Select/JiraTasksSelect'
import style from '../styles/case_detail.module.scss'

interface IProps {
  register: UseFormRegister<IFormData>
  control: Control<IFormData, any>
  setValue: UseFormSetValue<IFormData>
  filesCount: number
}

const AccordionHead = ({ count }: { count: number }): React.ReactElement => {
  return (
    <Title type='h3' className={style.files__title}>
      <FormattedMessage id='titles.files' defaultMessage='Files' />
      {` (${count})`}
    </Title>
  )
}

const JiraBugFormBody = observer(
  ({ register, control, setValue, filesCount }: IProps): JSX.Element => {
    const intl = useIntl()
    const [isSubtask, setIsSubtask] = useState(false)
    const { formState } = useFormContext<IFormData>()

    const { project: idProject }: IObjectPage = useObjectPage()
    const { api, integrationServerErrorsStore } = useStores()

    const [typeOfTaskId, setTypeOfTaskId] =
      useState<number | string | undefined>(undefined)

    const { data: customFields, mutate: customFieldsMutate } = useSWR(
      {
        id: idProject,
        issue_type: typeOfTaskId
      },
      api.getJiraCustomFields,
      {
        onError (err) {
          integrationServerErrorsStore.setIntegrationError(
            err.response.data.authorization_error[0].length > 0
          )
        }
      }
    )

    const errors = formState.errors

    const handleTypeOfTaskChange = async (
      taskId: string | number,
      subtask: boolean
    ): Promise<void> => {
      setIsSubtask(subtask)
      setValue('issubtask', subtask)
      setTypeOfTaskId(taskId)
      setValue('issuetype', taskId)
      await customFieldsMutate()
    }

    const handleChangeParentTask = (e): void => {
      setValue('parent_task', e?.value)
    }

    return (
      <div className={style.modal__content}>
        <div className={style.modal__wrapper}>
          <div className={style.modal__box}>
            <Input
              disabled
              required
              className={style.modal__section__item}
              type='text'
              {...register('project', { required: true })}
              label={intl.formatMessage({
                id: 'case.placeholder.project_key',
                defaultMessage: 'Project Key'
              })}
              error={errors?.project?.message ?? ''}
            />

            <Input
              type='text'
              required
              className={style.modal__section__item}
              {...register('summary', { required: true })}
              label={intl.formatMessage({
                id: 'case.placeholder.task_name',
                defaultMessage: 'Name of Task'
              })}
              error={errors?.summary?.message ?? ''}
            />

            <div className={style.modal__section}>
              <div
                className={classNames(style.input__container, style.modal__box)}
              >
                <div className={style.input__pair}>
                  <div className={style.input__pairelem}>
                    <TypeOfTaskField
                      projectId={idProject}
                      handleChange={handleTypeOfTaskChange}
                      {...register('issuetype', { required: true })}
                      error={errors?.issuetype?.message ?? ''}
                    />
                  </div>

                  <div className={style.input__pairelem}>
                    <JiraBugPrioritySelect
                      error={errors?.priority?.message ?? ''}
                    />
                  </div>
                </div>
              </div>
            </div>

            {isSubtask
              ? (
                <JiraTasksSelect
                  isClearable
                  required
                  idProject={idProject}
                  {...register('parent_task', { required: true })}
                  labelClassName={style.testplan__label}
                  handleChange={handleChangeParentTask}
                  error={errors?.parent_task?.message ?? ''}
                />
                )
              : null}

            <div className={style.input__pair}>
              <div className={style.input__pairelem}>
                <Input
                  type='text'
                  placeholder='3w 4d 12h 5m'
                  {...register('timeoriginalestimate', {
                    validate: async (value) => {
                      try {
                        await estimationSchema.validate(value)
                        return true
                      } catch (error) {
                        return intl.formatMessage({
                          id: 'case.validation.estimated',
                          defaultMessage: 'Error. Use format: (3w 4d 12h 5m)'
                        })
                      }
                    }
                  })}
                  error={errors?.timeoriginalestimate?.message ?? ''}
                  label={intl.formatMessage({
                    id: 'case.placeholder.estimated',
                    defaultMessage: 'Estimated'
                  })}
                />
              </div>

              <span className={style.input__pairelem}>
                <FormattedMessage
                  id='case.placeholder.estimated_description'
                  defaultMessage='(eg. 3w 4d 12h 5m)'
                />
              </span>
            </div>
          </div>

          <div className={style.line} />
          <div className={style.modal__box}>
            <Input
              disabled
              required
              className={style.modal__section__item}
              type='text'
              {...register('reporter', { required: true })}
              label={intl.formatMessage({
                id: 'case.placeholder.reporter',
                defaultMessage: 'Reporter'
              })}
              error={errors?.reporter?.message ?? ''}
            />

            <AssigneeSelectField
              required
              projectId={idProject}
              error={errors?.assignee?.message ?? ''}
            />

            {typeOfTaskId === undefined
              ? null
              : customFields?.values?.map((field, idx) => {
                return field.searcherKey === 'userpickergroupsearcher'
                  ? (
                    <CustomFieldSelect
                      key={idx}
                      required
                      field={field}
                      projectId={idProject}
                      error={errors?.custom_fields?.message ?? ''}
                    />
                    )
                  : null
              })}
          </div>
        </div>
        <div className={style.modal__item}>
          <Textarea
            required
            {...register('description', { required: true })}
            error={errors?.description?.message ?? ''}
            label={intl.formatMessage({
              id: 'status.description',
              defaultMessage: 'Description'
            })}
          />
        </div>

        <div className={style.modal__item}>
          <Controller
            control={control}
            name='files'
            render={({ field: { onChange } }) => (
              <Accordion
                head={<AccordionHead count={filesCount} />}
                defaultOpened={false}
              >
                <UploadZoneForm
                  className={style.files__upload}
                  initFiles={[]}
                  onChange={onChange}
                  canUserDeleteFile
                />
              </Accordion>
            )}
          />
        </div>
      </div>
    )
  }
)

export default JiraBugFormBody
