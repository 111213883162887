import { IValue } from '@/components/Select/interfaces/AsyncSelect'
import { makeAutoObservable } from 'mobx'

class Milestone {
  currentMilestone: IValue = {
    label: '',
    value: 0
  }

  constructor () {
    makeAutoObservable(this)
  }

  setCurrentMilestone (data: IValue): void {
    this.currentMilestone.value = data.value
    this.currentMilestone.label = data.label
  }

  setDefaultMilestone (): void {
    this.currentMilestone.value = 0
    this.currentMilestone.label = ''
  }

  getCurrentMilestone (): IValue {
    return this.currentMilestone
  }
}

export const milestoneService = new Milestone()
