import { useStores } from '@/hooks'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import style from './styles/modal.module.scss'
import Icon from '@/components/Icon/Icon'
import Button from '@/components/Button/Button'
import { useForm } from 'react-hook-form'
import { reactHookFormErrorFormater } from '@/utils'
import CompanyMemberShort from '@/components/Member/CompanyMemberShort'
import { Form } from '@/components/Form'

export interface IFormInputs {
  non_field_errors?: string
  idInvite: number
  idCompany: number
}

interface IProps {
  handleClose: () => void
}

const ViewRemoveMemberInvite = ({
  handleClose
}: IProps): React.ReactElement | null => {
  const { modalStore, api } = useStores()
  const idInvite = modalStore.dataModal.idInvite
  const idCompany = modalStore.dataModal.idCompany
  const avatar = modalStore.dataModal.avatar
  const email = modalStore.dataModal.email
  const initialsEmail = modalStore.dataModal.initialsEmail

  const {
    setError,
    handleSubmit,
    formState: { isSubmitting, errors }
  } = useForm<IFormInputs>({
    defaultValues: {
      idInvite,
      idCompany
    }
  })

  const onSubmit = async (params): Promise<void> => {
    try {
      await api.declineInvite(params)
      handleClose()
    } catch (error) {
      const errors = reactHookFormErrorFormater(params, error)
      errors.forEach((item) => {
        setError(item.key as keyof IFormInputs, {
          type: '400',
          message: item.value
        })
      })
    }
  }

  const nonFieldErrors = errors?.non_field_errors?.message ?? ''

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className={style.deleteuser__top}>
        <p className={style.deleteuser__text}>
          <FormattedMessage
            id='company.invites.delete.title'
            defaultMessage='Do you really want to cancel the invitation for:'
          />
        </p>

        <CompanyMemberShort
          name={email}
          avatar={avatar}
          initials={initialsEmail}
        />

        {nonFieldErrors !== ''
          ? (
            <div className={style.deleteuser__error}>{nonFieldErrors}</div>
            )
          : null}
      </div>

      <div className={style.deleteuser__bottom}>
        <Button onClick={handleClose} theme='light'>
          <Icon src='clear' slot='icon-left' />

          <FormattedMessage
            id='company.invites.delete.cancel'
            defaultMessage='No'
          />
        </Button>

        <Button disabled={isSubmitting} type='submit'>
          <Icon src='checked' slot='icon-left' />

          <FormattedMessage
            id='company.invites.delete.confirm'
            defaultMessage='Yes'
          />
        </Button>
      </div>
    </Form>
  )
}

export default ViewRemoveMemberInvite
