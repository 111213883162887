import { Components } from '@garpix/garpix-web-components'
import { GxPhoneInput } from '@garpix/garpix-web-components-react'
import classNames from 'classnames'
import React, { forwardRef } from 'react'
import Icon from '../Icon/Icon'
import style from './styles/input.module.scss'

const PhoneInput = forwardRef<Components.GxInput, any>(
  ({ onBlur, onChange, ...props }, ref): React.ReactElement => {
    const { children = '', className = '', label, error = '' } = props
    const showError = Array.isArray(error) ? error[0] : error
    return (
      <GxPhoneInput
        ref={ref}
        {...props}
        onGx-blur={onBlur}
        onGx-change={onChange}
        className={classNames({
          [style.input]: true,
          [style.phone_input]: true,
          [style.error]: Boolean(showError),
          [className]: Boolean(className)
        })}
        invalid={Boolean(showError)}
        label={label}
        pill
      >
        {children}
        {showError !== ''
          ? (
            <>
              <Icon src='error' slot='suffix' className={style.error__icon} />
              <div slot='help-text' className={style.error__text}>
                {showError}
              </div>
            </>
            )
          : null}
      </GxPhoneInput>
    )
  }
)
export default PhoneInput
