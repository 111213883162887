import Wrapper from '@/components/Wrapper'
import style from '../style/profile.module.scss'
import React from 'react'
import Title from '@/components/Title'
import { FormattedMessage } from 'react-intl'
import Icon from '@/components/Icon/Icon'
import { usePage } from '@/hooks'
import { IUserProfile } from '@/interfaces/User'
import { getInitials } from '@/components/UserName'
import { AvatarUser } from '@/components/Avatar'

const UserInfo = (): React.ReactElement => {
  const page = usePage()
  const currentUser: IUserProfile = page.init_state.user_data
  const initialsUser = getInitials(
    currentUser.first_name ?? '',
    currentUser.last_name ?? '',
    currentUser.email
  )
  if (currentUser === null) {
    return (
      <Wrapper className={style.userinfo}>
        <Title type='h1'>
          <FormattedMessage
            id='another_profile.title.no_user'
            defaultMessage='User not found'
          />
        </Title>
      </Wrapper>
    )
  }

  return (
    <Wrapper className={style.userinfo}>
      <Title type='h1' hidden>
        <FormattedMessage
          id='another_profile.title'
          defaultMessage='User profile'
        />
      </Title>

      <div className={style.userinfo__content}>
        <div className={style.userinfo__avatarblock}>
          <AvatarUser src={currentUser.avatar} size='xxxl' initials={initialsUser} />

          <span
            className={style.userinfo__name}
          >{`${currentUser.first_name} ${currentUser.last_name}`}
          </span>
        </div>

        <div className={style.userinfo__info}>
          <div className={style.userinfo__infoblock}>
            {currentUser.email !== ''
              ? (
                <div className={style.userinfo__item}>
                  <div className={style.userinfo__code}>
                    <Icon src='mail' />

                    <FormattedMessage id='profile.email' defaultMessage='Email' />
                  </div>

                  <a className={style.userinfo__link} href={`mailto:${currentUser.email}`}>{currentUser.email}</a>
                </div>
                )
              : null}

            {currentUser.phone !== ''
              ? (
                <div className={style.userinfo__item}>
                  <div className={style.userinfo__code}>
                    <Icon src='phone' />

                    <FormattedMessage id='profile.phone' defaultMessage='Phone' />
                  </div>

                  <a className={style.userinfo__link} href={`tel:${currentUser.phone}`}>{currentUser.phone}</a>
                </div>
                )
              : null}
          </div>

          <div className={style.userinfo__links}>
            {currentUser.gitlab_url !== ''
              ? (
                <a
                  href={currentUser.gitlab_url}
                  className={style.userinfo__link}
                  target='_blank'
                  rel='noreferrer'
                >
                  <Icon src='git' size='xl' />
                </a>
                )
              : null}

            {currentUser.telegram_url !== ''
              ? (
                <a
                  href={currentUser.telegram_url}
                  className={style.userinfo__link}
                  target='_blank'
                  rel='noreferrer'
                >
                  <Icon src='resend' size='xl' />
                </a>
                )
              : null}
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default UserInfo
