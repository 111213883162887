import Button from '@/components/Button/Button'
import { TextLinkButton } from '@/components/Button/TextLinkButton'
import EmptyList from '@/components/EmptyList/EmptyList'
import Icon from '@/components/Icon/Icon'
import useSWR from 'swr'
import Title from '@/components/Title'
import Wrapper from '@/components/Wrapper'
import { useObjectPage, usePage, useStores } from '@/hooks'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { MilestoneItem } from '@/components/MilestoneItem'
import style from '../styles/project_detail.module.scss'

const Milestones = (): React.ReactElement => {
  const intl = useIntl()
  const store = useStores()
  const page = useObjectPage()
  const obj = usePage()

  const { data, isLoading } = useSWR(
    {
      project: page.id,
      page: 1,
      page_size: 5,
      _key: 'getMilestones'
    },
    store.api.getMilestones
  )

  const createMilestone = obj.init_state.subpages.ProjectPageMilestonesCreate.absolute_url
  const listMilestones = obj.init_state.subpages.ProjectPageMilestones.absolute_url

  const milestones = data?.results ?? []
  return (
    <Wrapper className={style.wrapper__half}>
      <div className={style.mile__top}>
        <Title type='h2' theme='h1'>
          <FormattedMessage
            id='titles.milestones'
            defaultMessage='Milestones'
          />
        </Title>

        <Button href={createMilestone} circle>
          <Icon src='plus' />
        </Button>
      </div>

      {!isLoading && milestones.length > 0
        ? (
          <>
            <div className={style.list}>
              {milestones.map((el) => {
                return (
                  <MilestoneItem
                    key={el.id}
                    title={el.title}
                    id={el.id}
                    endDate={el.end_date}
                    color={el.color}
                    progress={el.progress}
                    code={el.code}
                    absoluteUrl={el.absolute_url}
                  />
                )
              })}
            </div>

            <TextLinkButton href={listMilestones} className={style.mile__add}>
              {intl.formatMessage({
                id: 'milestones.all_milestones',
                defaultMessage: 'All milestones'
              })}
            </TextLinkButton>
          </>
          )
        : (
          <EmptyList
            icon='flag'
            text={intl.formatMessage({
              id: 'milestones.no_milestones',
              defaultMessage: 'No milestones has been found'
            })}
            buttonText={intl.formatMessage({
              id: 'milestones.add_milestone',
              defaultMessage: 'Add milestone'
            })}
            href={createMilestone}
          />
          )}
    </Wrapper>
  )
}

export default Milestones
