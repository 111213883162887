import style from '../styles/registration.module.scss'

import React from 'react'
import { FormattedMessage } from 'react-intl'
import Icon from '@/components/Icon/Icon'
import { LinkButton } from '@/components/Button/LinkButton'
import { usePage } from '@garpix/cms'

const ErrorRegistration = (): React.ReactElement => {
  const obj = usePage()
  const hrefLogin = obj.page.init_state.global.login_url
  return (
    <div className={style.form__end}>
      <div className={style.form__icon}>
        <Icon src='clear' size='small' className={style.form__bigicon} />
      </div>

      <div className={style.form__endtext}>
        <FormattedMessage
          id='registration.error.text'
          defaultMessage='The link has expired, repeat the operation'
        />
      </div>

      <LinkButton href={hrefLogin} size='lg'>
        <Icon src='arrow_right' slot='icon-left' />

        <FormattedMessage
          id='registration.error.button'
          defaultMessage='To login'
        />
      </LinkButton>
    </div>
  )
}

export default ErrorRegistration
