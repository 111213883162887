import React, { useState } from 'react'
import style from './styles/projects.module.scss'
import Wrapper from '@/components/Wrapper'
import Title from '@/components/Title'
import { LinkButton } from '@/components/Button/LinkButton'
import Icon from '@/components/Icon/Icon'
import { FormattedMessage, useIntl } from 'react-intl'
import SearchInput from '@/components/Input/SearchInput/SearchInput'
import ListContent from '@/components/ListContent'
import { useObjectPage, useStores } from '@/hooks'
import useDebounce from '@/hooks/useDebounce'
import useSWR from 'swr'
import { scrollTop } from '@/utils'
import ProjectsList from './components/ProjectsList'
import usePage from '@/hooks/usePage'

const CompanyProjects = (): JSX.Element => {
  const store = useStores()
  const { id: idCompany } = useObjectPage()
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search)
  const [page, setPage] = useState(1)
  const pageSize = 10
  const intl = useIntl()
  const obj = usePage()
  const addProjectLink: string = obj.init_state.subpages.ProjectPageCreate.absolute_url

  const { data, error, isLoading } = useSWR(
    {
      page: page,
      page_size: pageSize,
      company: idCompany,
      q: debouncedSearch,
      _key: 'getProjects'
    },
    store.api.getProjects,
    {
      onSuccess: () => {
        scrollTop()
      }
    }
  )

  const handleSearch = (value: string): void => {
    setSearch(value)
    setPage(1)
  }
  return (
    <Wrapper className={style.projects}>
      <div className={style.projects__top}>
        <div className={style.projects__left}>
          <Title type='h1' className={style.projects__title}>
            <FormattedMessage id='project.title' defaultMessage='Projects' />
          </Title>
        </div>
        <SearchInput
          handleChange={handleSearch}
          value={search}
          className={style.projects__search}
          placeholder={intl.formatMessage({
            id: 'project.search',
            defaultMessage: 'Search project'
          })}
        />

        <LinkButton href={addProjectLink}>
          <Icon src='plus' slot='icon-left' />

          <FormattedMessage
            id='project.add_project'
            defaultMessage='Add project'
          />
        </LinkButton>
      </div>

      <ListContent
        isLoading={isLoading}
        error={error}
        hasData={data !== undefined && data?.count > 0}
        emptyListIcon='folder'
        emptyListText={intl.formatMessage({
          id: 'project.no_projects',
          defaultMessage: 'No projects has been found'
        })}
        emptyListButton={intl.formatMessage({
          id: 'project.add_project',
          defaultMessage: 'Add project'
        })}
        href={addProjectLink}
      >
        {data !== undefined
          ? (
            <ProjectsList
              data={data}
              currentPage={page}
              pageSize={pageSize}
              setPage={setPage}
            />
            )
          : null}
      </ListContent>
    </Wrapper>
  )
}

export default CompanyProjects
