import Button from '@/components/Button/Button'
import Icon from '@/components/Icon/Icon'
import Title from '@/components/Title'
import useSWR from 'swr'
import Wrapper from '@/components/Wrapper'
import { useObjectPage, usePage, useStores } from '@/hooks'
import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import style from '../styles/project_detail.module.scss'
import RunItem from '@/components/RunItem'
import EmptyList from '@/components/EmptyList/EmptyList'
import { TextLinkButton } from '@/components/Button/TextLinkButton'
import MilestoneSelect from '@/components/Select/MilestoneSelect/MilestoneSelect'
import BaseModal from '@/components/Modal/Base'
import AssignToModal from '@/apps/RunDetail/components/AssignToModal'
import useModal from '@/hooks/useModal'
import { useForm } from 'react-hook-form'
import { IValue } from '@/components/Select/interfaces/AsyncSelect'

export interface IFormInputs {
  id?: number
  description?: string
  project: number
  user_project?: IValue
  deadline?: string
}

const Runs = (): React.ReactElement => {
  const [milestone, setMilestone] = useState<string | number>('')
  const [runId, setRunId] = useState<number>(0)

  const intl = useIntl()
  const { api } = useStores()
  const page = useObjectPage()
  const obj = usePage()

  const { isOpen, handleOpenModal, handleCloseModal } = useModal()

  const { data, isLoading, mutate: mutateRuns } = useSWR(
    {
      milestone,
      page: 1,
      project: page.id,
      page_size: 5,
      _key: 'getRuns'
    },
    api.getRuns
  )
  const runs = data?.results ?? []

  const handleChange = (e): void => {
    setMilestone(e?.value ?? '')
  }
  const createRun = obj.init_state.subpages.ProjectPageRunsCreate.absolute_url
  const listMilestones = obj.init_state.subpages.ProjectPageRuns.absolute_url

  const handleOpenModalClick = (runId: number): void => {
    setRunId(runId)
    handleOpenModal()
  }

  return (
    <Wrapper className={style.wrapper__half}>
      <div className={style.runs__top}>
        <Title
          type='h2'
          theme='h1'
          className={`${style.title} ${style.runs__title}`}
        >
          <FormattedMessage id='titles.runs' defaultMessage='Test Runs' />
        </Title>
        <MilestoneSelect
          selectClassName={style.runs__select}
          handleChange={handleChange}
          labelLeft
          labelClassName={style.runs__select}
          projectId={page.id}
          isClearable
        />

        <Button href={createRun} circle className={style.runs__add}>
          <Icon src='plus' />
        </Button>
      </div>

      {!isLoading && runs.length > 0
        ? (
          <>

            <div className={style.list}>
              {runs.map((el) => {
                return (
                  <RunItem
                    key={el.id}
                    title={el.title}
                    deadline={el.deadline}
                    milestone={el.milestone}
                    absoluteUrl={el.absolute_url}
                    id={el.id}
                    assignedTo={el.assigned_to}
                    statistic={el.statistics}
                    handleOpenModal={handleOpenModalClick}
                    isFull={false}
                    handleUpdate={mutateRuns}
                  />
                )
              })}
            </div>

            <TextLinkButton href={listMilestones} className={style.mile__add}>
              {intl.formatMessage({
                id: 'runs.all_runs',
                defaultMessage: 'All runs'
              })}
            </TextLinkButton>
          </>
          )
        : null}

      {!isLoading && runs.length === 0
        ? (
          <EmptyList
            icon='file'
            text={intl.formatMessage({
              id: 'runs.no_runs',
              defaultMessage: 'No test runs has been found'
            })}
            buttonText={intl.formatMessage({
              id: 'runs.add_run',
              defaultMessage: 'Add testrun'
            })}
            href={createRun}
          />
          )
        : null}
      <BaseModal
        open={isOpen}
        onGx-after-hide={handleCloseModal}
        hideDefaultClose
        size='small'
        className={style.runs__modal}
      >
        <AssignToModal
          handleCloseModal={handleCloseModal}
          handleUpdate={mutateRuns}
          idProject={page.id}
          runId={runId}
          useFormInstance={useForm<IFormInputs>({
            defaultValues: {
              user_project: undefined,
              project: page.id,
              description: '',
              deadline: ''
            }
          })}
        />
      </BaseModal>
    </Wrapper>
  )
}

export default Runs
