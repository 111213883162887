import React, { useState } from 'react'
import style from './styles/companyitem.module.scss'
import classNames from 'classnames'
import { IMember } from '@/interfaces/Member'
import Link from '../Link'
import { FormattedMessage } from 'react-intl'
import Icon from '../Icon/Icon'
import { TextTag } from '../Tag'
import Button from '../Button/Button'
import { useStores } from '@/hooks'
import TextButton from '../Button/TextButton'
import { timeLeft } from '@/utils'
import { TCompanyStatus } from '@/interfaces/Types'
import { COMPANY_STATUS } from '@/const'
import { getInitials } from '../UserName'
import { AvatarCompany, AvatarUser } from '../Avatar'
import { useLocation } from 'react-router-dom'
import qs from 'qs'

interface IProps {
  id: number
  userId: number
  title: string
  code: string
  owner: IMember
  image: string
  projectsCount: number
  membersCount: number
  urlCompany: string
  status: TCompanyStatus
  inviteId: number | null
  deleteTimeLeft: number
  className?: string
  onChange: () => void
}

const CompanyItem = ({
  className = '',
  id,
  userId,
  title,
  code,
  owner,
  image,
  projectsCount,
  membersCount,
  urlCompany,
  status,
  inviteId,
  deleteTimeLeft,
  onChange
}: IProps): React.ReactElement => {
  const { api } = useStores()
  const { search } = useLocation()
  const qsParams = qs.parse(search, { ignoreQueryPrefix: true })
  const simpleCard = status === COMPANY_STATUS.active && inviteId === null
  const isDeleted = status === COMPANY_STATUS.deleted
  const isBanned = status === COMPANY_STATUS.banned

  const timeLeftValue = timeLeft(deleteTimeLeft)
  const isCurrentUserAdmin: boolean = userId === owner?.id
  const initialsUser = getInitials(
    owner.first_name ?? '',
    owner.last_name ?? '',
    owner.email
  )

  /** Анимация при переходе из уведомления инвайта */
  const isAnimated = qsParams?.invite === id.toString()
  /** Карточки темнее */
  const isHighlighted = !simpleCard || isDeleted

  const [disabled, setDisabled] = useState(false)
  const [isError, setError] = useState(false)

  const btnDisabled = async (
    clickButton: () => Promise<void>
  ): Promise<void> => {
    setDisabled(true)
    await clickButton()
    setDisabled(false)
  }

  const handleAcceptInvite = async (): Promise<void> =>
    await btnDisabled(async (): Promise<void> => {
      try {
        setError(false)
        await api.acceptInvite({
          idInvite: inviteId ?? 0
        })
        onChange()
      } catch (err) {
        console.log('err', err)
        setError(true)
      }
    })

  const handleDeclineInvite = async (): Promise<void> =>
    await btnDisabled(async (): Promise<void> => {
      try {
        setError(false)
        await api.declineInvite({
          idInvite: inviteId ?? 0
        })
        onChange()
      } catch (err) {
        console.log('err', err)
        setError(true)
      }
    })

  const handleRestoreCompany = async (): Promise<void> =>
    await btnDisabled(async (): Promise<void> => {
      try {
        setError(false)
        await api.restoreCompany({
          idCompany: id
        })
        onChange()
      } catch (err) {
        console.log('err', err)
        setError(true)
      }
    })

  return (
    <div
      className={classNames({
        [style.company]: true,
        [style['company--highlighted']]: isHighlighted,
        [style['company--animated']]: isAnimated,
        [className]: Boolean(className)
      })}
    >
      <div className={style.company__top}>
        <div className={style.company__head}>
          <AvatarCompany
            src={image}
            className={style.company__avatar}
            size='large'
          />

          <div className={style.company__titlewrap}>
            {isDeleted || isBanned
              ? (
                <div className={style.company__title}>{title}</div>
                )
              : (
                <Link className={style.company__title} to={urlCompany}>
                  {title}
                </Link>
                )}

            <div className={style.company__code}>id: {code}</div>
          </div>
        </div>

        {!simpleCard
          ? (
            <div className={style.company__badges}>
              {isDeleted
                ? (
                  <div className={style.company__remove}>
                    <FormattedMessage
                      id='common.removed'
                      defaultMessage='Removed'
                    />
                  </div>
                  )
                : null}
              {isBanned
                ? (
                  <div className={style.company__remove}>
                    <FormattedMessage
                      id='common.banned'
                      defaultMessage='Banned'
                    />
                  </div>
                  )
                : null}
              <div className={style.company__badge} />
            </div>
            )
          : null}
      </div>

      <div className={style.company__bottom}>
        <div className={style.company__owner}>
          {owner !== null
            ? (
              <>
                <div className={style.company__code}>
                  <Icon src='admin' size='small' />

                  <FormattedMessage id='common.owner' defaultMessage='Owner' />
                </div>

                <Link className={style.company__name} to={owner.profile_url}>
                  <AvatarUser
                    src={owner.avatar}
                    className={style.company__avatarowner}
                    initials={initialsUser}
                  />

                  {`${owner.first_name} ${owner.last_name}`}
                </Link>
              </>
              )
            : null}
        </div>
        {simpleCard
          ? (
            <div className={style.company__end}>
              <TextTag size='medium'>
                <Icon src='folder' slot='icon-left' />
                {projectsCount}
              </TextTag>

              <TextTag size='medium'>
                <Icon src='user' slot='icon-left' />
                {membersCount}
              </TextTag>
            </div>
            )
          : null}

        {isDeleted
          ? (
            <div className={style.company__end}>
              <div>
                <div className={style.company__code}>
                  <Icon src='clock' size='small' />

                  <FormattedMessage
                    id='companies.time'
                    defaultMessage='Time left'
                  />
                </div>

                <div className={style.company__name}>
                  <FormattedMessage
                    id={`time_left.${timeLeftValue.period}`}
                    defaultMessage={`${timeLeftValue.count} ${timeLeftValue.period}`}
                    values={{ count: timeLeftValue.count }}
                  />
                </div>
              </div>

              {isCurrentUserAdmin
                ? (
                  <Button disabled={disabled} onClick={handleRestoreCompany}>
                    <div className={style.company__button}>
                      <Icon src='refresh' size='small' />

                      <FormattedMessage
                        id='companies.restore'
                        defaultMessage='Time left'
                      />
                    </div>
                  </Button>
                  )
                : null}
            </div>
            )
          : null}

        {inviteId !== null
          ? (
            <div className={style.company__invite}>
              <TextButton disabled={disabled} onClick={handleDeclineInvite}>
                <FormattedMessage
                  id='companies.decline'
                  defaultMessage='Decline'
                />
              </TextButton>

              <Button disabled={disabled} onClick={handleAcceptInvite}>
                <div className={style.company__button}>
                  <Icon src='checked' size='small' />

                  <FormattedMessage
                    id='companies.invite'
                    defaultMessage='Accept invitation'
                  />
                </div>
              </Button>
            </div>
            )
          : null}
      </div>

      {isError
        ? (
          <div className={style.company__error}>
            <FormattedMessage id='error.server' defaultMessage='Server error' />
          </div>
          )
        : null}
    </div>
  )
}

export default CompanyItem
