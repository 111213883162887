import Icon from '@/components/Icon/Icon'
import React from 'react'
import style from '../styles/caseitem.module.scss'
import Checkbox from '@/components/Checkbox/Checkbox'
import { CASE_ICON } from '@/const'
import { TCaseType } from '@/interfaces/Types'

interface IProps {
  title: string
  code: string
  onChange: (value) => void
  checked: boolean
  caseType: TCaseType
}

const CaseItemCheckbox = ({
  checked,
  title,
  code,
  onChange,
  caseType
}: IProps): React.ReactElement => {
  return (
    <Checkbox checked={checked} size='small' className={style.checkbox} onGx-change={onChange}>
      <div className={style.caseitem__itemcheck}>
        <Icon src={CASE_ICON[caseType]} />
        <div className={style.caseitem__title}>{title}</div>

        <div className={style.caseitem__right}>
          <div className={style.caseitem__id}>{code} </div>
        </div>
      </div>
    </Checkbox>
  )
}

export default CaseItemCheckbox
