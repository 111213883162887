import React from 'react'
import { Layout } from '.'
import style from '../styles/layout.module.scss'
import { observer } from 'mobx-react'
import Sidebar from './Sidebar/Sidebar'
import classNames from 'classnames'
import { sidebarService } from './service'

interface LayoutProps {
  children: React.ReactNode
}

const ProjectLayout = observer(
  ({ children }: LayoutProps): React.ReactElement => {
    return (
      <Layout>
        <div className={style.project__block}>
          <Sidebar />
          <div
            className={classNames({
              [style.project__content]: true,
              [style['project__content--opened']]: sidebarService.open
            })}
          >
            {children}
          </div>
        </div>
      </Layout>
    )
  }
)

export default ProjectLayout
