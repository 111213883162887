import React from 'react'
import { useFormContext } from 'react-hook-form'
import { IFormData, IOption } from '../interfaces'
import { useIntl } from 'react-intl'
import { useStores } from '@/hooks'
import BaseAsyncSelect from '@/components/Select/AsyncSelect/BaseAsyncSelect'
import style from '../styles/case_detail.module.scss'

interface IProps {
  projectId: string | number
  required: boolean
  error?: string
}

const AssigneeSelectField = ({ projectId, required, error }: IProps): JSX.Element => {
  const intl = useIntl()
  const { api } = useStores()
  const { setValue, register } = useFormContext<IFormData>()

  const handleChange = (selectedOption: IOption): void => {
    if (selectedOption != null) {
      setValue('assignee', selectedOption)
    }
  }

  const loadOptions = (
    inputValue: string,
    callback: (options: IOption[]) => void
  ): void => {
    api
      .getJiraUsers({
        id: projectId,
        q: inputValue
      })
      .then((data) => {
        callback(
          data.map((el) => {
            return {
              value: Number(el.key),
              label: el.name,
              email: el.email
            }
          })
        )
      })
      .catch((err) => console.log(err))
  }

  return (
    <div className={style.input__container}>
      <BaseAsyncSelect
        {...register('assignee', { required: true })}
        label={intl.formatMessage({
          id: 'case.form.assignee',
          defaultMessage: 'Assignee'
        })}
        required={required}
        placeholder={intl.formatMessage({
          id: 'input.placeholder',
          defaultMessage: 'Select...'
        })}
        error={error}
        handleChange={handleChange}
        loadOptions={loadOptions}
      />
    </div>
  )
}
export default AssigneeSelectField
