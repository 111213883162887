import React, { useEffect, useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Icon from '@/components/Icon/Icon'
import Button from '@/components/Button/Button'
import BaseModal from '@/components/Modal/Base'
import Title from '@/components/Title'
import { usePage, useStores } from '@/hooks'
import { IMilestonesList } from '@/interfaces/Milestones'
import SearchInput from '@/components/Input/SearchInput/SearchInput'
import useDebounce from '@/hooks/useDebounce'
import ListContent from '@/components/ListContent'
import { MilestoneItemRadio } from '@/components/MilestoneItem'
import useSWRInfinite from 'swr/infinite'
import useDebounceSwrInfinite from '@/hooks/useDebounceSwrInfinite'
import { LoaderMilestones } from '../loaders'
import classNames from 'classnames'
import style from '../styles/runitem.module.scss'

interface IProps {
  isOpen: boolean
  handleCloseModal: () => void
  idRun: number
  idMilestone?: number
  mutateMilestones?: () => void
}

const PAGE_SIZE = 10

const ModalMoveMilestone = ({
  isOpen,
  idRun,
  idMilestone,
  handleCloseModal,
  mutateMilestones
}: IProps): JSX.Element => {
  const intl = useIntl()
  const obj = usePage()
  const idProject = obj.init_state.project_object.id
  const [search, setSearch] = useState<string>('')
  const [isLoading, setIsLoading] = useState(false)
  const debouncedSearch = useDebounce(search)
  const { api } = useStores()
  const [isLoadingMove, setIsLoadingMove] = useState(false)
  const [currentMilestone, setCurrentMilestone] =
    useState<number | undefined>(idMilestone)
  const refListRuns = useRef<HTMLDivElement | null>(null)

  const isButtonView =
    currentMilestone !== undefined && currentMilestone !== idMilestone

  const { data, error, size, setSize } = useSWRInfinite(
    (index) => {
      return {
        page: index + 1,
        count: PAGE_SIZE,
        q: debouncedSearch,
        project: idProject,
        _key: 'getMilestones'
      }
    },
    async (params) => {
      setIsLoading(true)
      const data = await api.getMilestones({
        ...params,
        page_size: params.count
      })
      setIsLoading(false)
      return data.results
    },
    {
      revalidateFirstPage: false,
      revalidateAll: false
    }
  )

  const isEmpty = data?.length === 0

  const isReachingEnd =
    isEmpty || (data != null && data[data.length - 1]?.length < PAGE_SIZE)

  const handleEnd = (): void => {
    if (isLoading) return

    const currentRef = refListRuns.current
    if (currentRef != null) {
      const currentScrollTop = currentRef.scrollTop
      const scrollThreshold = 10

      const isScrollEnd =
        currentScrollTop + currentRef.clientHeight + scrollThreshold >=
        currentRef.scrollHeight

      if (!isReachingEnd && isScrollEnd) {
        void setSize((prev) => {
          return prev + 1
        })
      }
    }
  }

  const handleEndDebounced = useDebounceSwrInfinite(handleEnd, 100)

  const list: IMilestonesList[] =
    data !== undefined && data !== null ? [].concat.apply([], data) : []

  const handleMoveMilestone = async (): Promise<void> => {
    setIsLoadingMove(true)
    if (currentMilestone !== undefined) {
      await api.updateRunMilestone(idRun, currentMilestone)
    }
    mutateMilestones?.()
    setIsLoadingMove(false)
    handleCloseModal()
  }

  useEffect(() => {
    refListRuns.current?.addEventListener('scroll', handleEndDebounced)
    return () => {
      refListRuns.current?.removeEventListener('scroll', handleEndDebounced)
    }
  }, [isReachingEnd, size, data])

  return (
    <BaseModal
      open={isOpen}
      onGx-after-hide={handleCloseModal}
      hideDefaultClose
      className={style.modal_milestone__modal}
      size='medium'
    >
      <div className={style.modal_milestone}>
        <div className={style.modal_milestone__head}>
          <Title className={style.modal_milestone__title}>
            <FormattedMessage
              id='run.milestone.title'
              defaultMessage='Choose new Milestone'
            />
          </Title>

          <Button
            theme='light'
            circle
            className={style.step__delete}
            onClick={handleCloseModal}
          >
            <Icon src='clear' className={style.step__icon} />
          </Button>
        </div>

        <SearchInput
          handleChange={setSearch}
          value={search}
          placeholder={intl.formatMessage({
            id: 'milestones.search',
            defaultMessage: 'Search milestone'
          })}
          clearable
        />

        <div
          ref={refListRuns}
          className={classNames({
            [style.modal_milestone__list]: true,
            [style['modal_milestone__list-offset']]: isButtonView
          })}
        >
          <ListContent
            isLoading={false}
            error={error}
            hasData={list.length > 0}
            emptyListIcon='flag'
            emptyListText={intl.formatMessage({
              id: 'milestones.no_milestones',
              defaultMessage: 'No milestones has been found'
            })}
          >
            {list.map((el) => (
              <MilestoneItemRadio
                key={el.id}
                radioName='milestone'
                id={el.id}
                title={el.title}
                color={el.color}
                code={el.code}
                casesCount={el.cases_count}
                runsCount={el.runs_count}
                milestoneId={el.id}
                checked={el.id === currentMilestone}
                className={style.modal_milestone__radio}
                onChange={setCurrentMilestone}
              />
            ))}
          </ListContent>

          {!isLoading && !isReachingEnd
            ? (
              <LoaderMilestones countShimmers={2} />
              )
            : null}
        </div>

        {isButtonView
          ? (
            <div className={style.modal_milestone__bottom}>
              <Button
                disabled={isLoadingMove}
                size='lg'
                className={style.modal_milestone__button}
                onClick={handleMoveMilestone}
              >
                <Icon src='moveTo' slot='icon-left' />

                <FormattedMessage id='runs.move' defaultMessage='Move' />
              </Button>
            </div>
            )
          : null}
      </div>
    </BaseModal>
  )
}

export default ModalMoveMilestone
