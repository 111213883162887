import { Accordion } from '@/components/Accordion'
import { HistoryGroup } from '@/components/HistoryGroup'
import Title from '@/components/Title'
import Wrapper from '@/components/Wrapper'
import { useObjectPage, useStores } from '@/hooks'
import React, { useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import useSWR from 'swr'
import { LoaderHistory } from '@/components/HistoryGroup/loaders'
import Pagination from '@/components/Pagination'
import { scrollToBlock } from '@/utils'
import style from '../styles/project_detail.module.scss'

const PAGE_SIZE = 10

const AccordionHead = (): React.ReactElement => {
  return (
    <Title type='h2' theme='h1' className={style.accordion__title}>
      <FormattedMessage id='titles.history' defaultMessage='History' />
    </Title>
  )
}

const History = (): React.ReactElement | null => {
  const { api } = useStores()
  const obj = useObjectPage()
  const historyRef = useRef(null)
  const [page, setPage] = useState(1)

  const handleChangePage = (page: number): void => {
    setPage(page)
    scrollToBlock(historyRef)
  }

  const { data, isLoading } = useSWR(
    {
      project: obj.id,
      page: page,
      page_size: PAGE_SIZE,
      _key: 'getHistory'
    },
    api.getHistory
  )

  if (
    data?.results?.length !== undefined &&
    data?.results?.length === 0 &&
    !isLoading
  ) {
    return <></>
  }

  return (
    <Wrapper ref={historyRef}>
      <Accordion head={<AccordionHead />} defaultOpened={false}>
        {isLoading ? <LoaderHistory countShimmers={PAGE_SIZE} /> : null}

        {data !== undefined && !isLoading
          ? (
            <HistoryGroup data={data.results} />
            )
          : null}
        {data !== undefined
          ? (
            <Pagination
              currentPage={page}
              total={data.count}
              pageSize={PAGE_SIZE}
              handleChange={handleChangePage}
            />
            )
          : null}
      </Accordion>
    </Wrapper>
  )
}

export default History
