import { makeAutoObservable, runInAction } from 'mobx'
import { ICurrentUser } from '@/interfaces/User'
import { RootStore } from './Root'
import { Api } from '@/api'
import { UserStates } from '@/interfaces/Types'

export interface IUserStore {
  currentUser?: ICurrentUser
  status: UserStates
}

export class UserStore implements IUserStore {
  status: UserStates
  rootStore: RootStore
  api: Api
  currentUser: ICurrentUser

  constructor (rootStore: RootStore) {
    this.rootStore = rootStore
    this.api = this.rootStore.api
    this.status = 'notAsked'
    makeAutoObservable(this)
  }

  setCurrentUser = (data: ICurrentUser): void => {
    this.status = 'isLogin'
    this.currentUser = data
  }

  getCurrentUser = (): void => {
    this.status = 'loading'
    if (!this.api.isAuthToken()) {
      this.status = 'notLogin'
      return
    }
    this.api
      .getCurrentUser()
      .then((data) => {
        runInAction(() => this.setCurrentUser(data))
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          runInAction(() => {
            this.status = 'notLogin'
          })
        } else {
          runInAction(() => {
            this.status = 'failed'
          })
        }
      })
  }
}
