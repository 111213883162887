import React from 'react'
import ProjectLayout from '../layout/components/ProjectLayout'
import UserLayout from '../layout/components/UserLayout'
import MilestoneDetail from '../MilestoneDetail'

const Milestone = (): React.ReactElement => {
  return (
    <UserLayout>
      <ProjectLayout>
        <MilestoneDetail />
      </ProjectLayout>
    </UserLayout>
  )
}

export default Milestone
