import Input from '@/components/Input/Input'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { IGitlabReportFormData } from '../interface'
import { useIntl } from 'react-intl'
import style from '../styles/gitlab_report.module.scss'

const ReportNameInput = (): JSX.Element => {
  const intl = useIntl()
  const { setValue, watch } = useFormContext<IGitlabReportFormData>()
  return (
    <div>
      <Input
        label={intl.formatMessage({
          id: 'reports.gitlab.report_name',
          defaultMessage: 'Report name'
        })}
        placeholder={intl.formatMessage({
          id: 'reports.gitlab.report_name',
          defaultMessage: 'Report name'
        })}
        className={style.reports__textfield}
        type='text'
        value={watch('endTag')}
        onChange={(e) => setValue('reportName', e.target.value)}
      />
    </div>
  )
}

export default ReportNameInput
