import MenuItem from '@/components/MenuItem/MenuItem'
import classNames from 'classnames'
import React, { forwardRef } from 'react'
import { useIntl } from 'react-intl'
import Select from '../Select'
import style from '../styles/select.module.scss'
import { IProjectRoleShort } from '@/interfaces/Member'

interface IProps {
  className?: string
  handleChange: (value) => void
  value?: string
  error?: string
  projectRoles: IProjectRoleShort[]
  showLabel?: boolean
  disabled?: boolean
  clearable?: boolean
}

const RoleProjectSelect = (
  {
    className = '',
    handleChange,
    value,
    error,
    projectRoles,
    showLabel = false,
    clearable = false,
    disabled
  }: IProps,
  ref
): React.ReactElement => {
  const intl = useIntl()
  return (
    <Select
      ref={ref}
      error={error}
      className={classNames({
        [style.form]: true,
        [className]: Boolean(className)
      })}
      placeholder={intl.formatMessage({
        id: 'input.placeholder',
        defaultMessage: 'Choose'
      })}
      label={showLabel
        ? intl.formatMessage({
          id: 'input.role',
          defaultMessage: 'Role'
        })
        : undefined}
      onChange={(e) => handleChange(e.target.value)}
      value={value ?? ''}
      disabled={disabled}
      clearable={clearable}
    >
      {projectRoles.map((role) => (
        <MenuItem key={role.id} value={role.id}>
          {role.title}
        </MenuItem>
      ))}
    </Select>
  )
}

export default forwardRef(RoleProjectSelect)
