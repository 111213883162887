import { usePage } from '@/hooks'
import React from 'react'
import AddOrEditProject from '../AddOrEditProject'
import UserLayout from '../layout/components/UserLayout'

const AddOrEditProjectPage = (): React.ReactElement => {
  const page = usePage()
  const obj = page.init_state.object
  const id = obj.id
  const companyTitle = obj.company.title
  const companyImage = obj.company.image

  return (
    <UserLayout>
      <AddOrEditProject
        companyTitle={companyTitle}
        id={id}
        companyImage={companyImage}
      />
    </UserLayout>
  )
}

export default AddOrEditProjectPage
