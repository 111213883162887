import Checkbox from '@/components/Checkbox/Checkbox'
import style from '@/apps/AddOrEditProject/styles/add_edit.module.scss'
import React, { Dispatch, useState } from 'react'
import classNames from 'classnames'
import Link from '@/components/Link'
import RoleProjectSelect from '@/components/Select/RoleProjectSelect'
import { TTargetValue } from '@/interfaces/Types'
import { observer } from 'mobx-react'
import { IProjectRoleShort } from '@/interfaces/Member'
import { getInitials } from '@/components/UserName'
import { AvatarUser } from '@/components/Avatar'
import { IUpdateMembers } from '@/interfaces/ApiParams'

interface IProps {
  id: number
  lastName: string
  firstName: string
  email: string
  projectRoles: IProjectRoleShort[]
  avatar: string | null
  profileUrl: string
  target: TTargetValue
  handleRoleChange: (roleId: number) => void
  role?: number
  setMember: Dispatch<React.SetStateAction<IUpdateMembers[]>>
  newMembers: IUpdateMembers[]
  isError: boolean
  isCheckbox: boolean
}

const CompanyMemberToAdd = ({
  id,
  lastName,
  firstName,
  projectRoles,
  avatar,
  profileUrl,
  target,
  handleRoleChange,
  role,
  email,
  setMember,
  newMembers,
  isError,
  isCheckbox = true
}: IProps): React.ReactElement => {
  const [selectedRoleId, setSelectedRoleId] =
    useState<number | undefined>(role)

  const initialsUser = getInitials(firstName, lastName, email)
  const checked = newMembers?.findIndex((el) => el.id === id) !== -1

  const handleRoleSelect = (roleId: number): void => {
    setSelectedRoleId(roleId)

    handleRoleChange(roleId)
  }

  const handleCheckboxClick = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    if (e.target.checked) {
      setMember([
        ...newMembers,
        {
          abac_role: undefined,
          id: Number(e.target.value)
        }
      ])
    } else {
      setSelectedRoleId(undefined)
      setMember(newMembers.filter((el) => el.id !== Number(e.target.value)))
    }
  }

  return (
    <div className={classNames(style.member, style.modal__member)}>
      {isCheckbox
        ? (
          <Checkbox
            checked={checked}
            value={id}
            className={style.member__check}
            onGx-change={handleCheckboxClick}
          />
          )
        : null}
      <div className={style.member__main}>
        <AvatarUser src={avatar} size='large' initials={initialsUser} />

        <Link to={profileUrl} target={target} className={style.member__name}>
          {`${firstName} ${lastName}`}
        </Link>
      </div>

      <RoleProjectSelect
        className={style.member__select}
        disabled={!checked && isCheckbox}
        projectRoles={projectRoles}
        value={(checked && selectedRoleId)?.toString() ?? undefined}
        handleChange={handleRoleSelect}
        error={!(checked && selectedRoleId !== undefined) && isError ? ' ' : undefined}
      />
    </div>
  )
}

export default observer(CompanyMemberToAdd)
