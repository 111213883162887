import { TNotificationImportStatus } from './interfaces/Notifies'
import {
  TCaseType,
  TCompanyRole,
  TCompanyStatus,
  TCompanyType,
  TModalStage,
  TPermissionKey,
  TPermissionLevel,
  TSyncCase,
  TVerify
} from './interfaces/Types'

export const PATHS = {
  ALL: {
    path: '*'
  }
}

export const COMPANY_ROLE_ICON = {
  owner: 'admin'
  // admin: 'admin',
  // employee: 'admin',
}

export const FILE_EXTENSIONS = {
  video: ['mp4', 'mov', 'avi', 'mkv']
}

export const PRIORITY_TYPES_FOR_BACKEND = {
  critical: 'CRITICAL',
  high: 'HIGH',
  low: 'LOW',
  medium: 'MEDIUM',
  very_low: 'VERY LOW'
}

export const RUN_STATUS = {
  assigned: 'ASSIGNED',
  created: 'CREATED',
  in_progress: 'INPROCESS',
  completed: 'COMPLETED'
}

export const CHART_COLORS = {
  default: '#6154f5',
  status: {
    untested: {
      opaque: 'rgba(131, 148, 183, 1)',
      opacity: 'rgba(131, 148, 183, 0.7)'
    },
    blocked: {
      opaque: 'rgba(182, 186, 192, 1)',
      opacity: 'rgba(182, 186, 192, 0.7)'
    },
    failed: {
      opaque: 'rgba(197, 58, 58, 1)',
      opacity: 'rgba(197, 58, 58, 0.7)'
    },
    passed: {
      opaque: 'rgba(48, 178, 92, 1)',
      opacity: 'rgba(48, 178, 92, 0.7)'
    },
    retest: {
      opaque: 'rgba(242, 181, 24, 1)',
      opacity: 'rgba(242, 181, 24, 0.7)'
    }
  }
}

export const STEP_STATUSES = {
  passed: 'passed',
  blocked: 'blocked',
  retest: 'retest',
  failed: 'failed',
  untested: 'untested'
}

export const STEP_STATUSES_BACKEND = {
  passed: 'PASSED',
  blocked: 'BLOCKED',
  retest: 'RETEST',
  failed: 'FAILED',
  untested: 'UNTESTED'
}

export const STEP_STATUSES_ARRAY = [
  STEP_STATUSES.passed,
  STEP_STATUSES.blocked,
  STEP_STATUSES.retest,
  STEP_STATUSES.failed,
  STEP_STATUSES.untested
]

export const CASE_STATUS = {
  draft: 'draft',
  approved: 'approved'
}

export const CASE_IN_RUN_STATUS = {
  created: 'CREATED',
  assigned: 'ASSIGNED',
  inprocess: 'INPROCESS',
  completed: 'COMPLETED'
}

export const PIE_CHART_BACKGROUND_COLORS = [
  CHART_COLORS.status.blocked.opacity,
  CHART_COLORS.status.failed.opacity,
  CHART_COLORS.status.passed.opacity,
  CHART_COLORS.status.retest.opacity,
  CHART_COLORS.status.untested.opacity
]

export const PIE_CHART_BORDER_COLORS = [
  CHART_COLORS.status.blocked.opaque,
  CHART_COLORS.status.failed.opaque,
  CHART_COLORS.status.passed.opaque,
  CHART_COLORS.status.retest.opaque,
  CHART_COLORS.status.untested.opaque
]

export const HISTORY_MODEL = {
  milestone: 'milestone',
  run: 'run',
  case: 'case',
  case_run: 'case_run'
} as const

export const CASE_TYPES: { [key: string]: TCaseType } = {
  case: 'CASE',
  checklist: 'CHECKLIST',
  task: 'TASK'
}

export const RADIO_PARAMS: { multiple: string, single: string } = {
  multiple: 'MULTIPLE',
  single: 'SINGLE'
}

export const MODAL_STAGE: TModalStage = {
  first: 1,
  second: 2,
  third: 3,
  fourth: 4
}

/** С бэка в уведомлениях в поле event приходит цифра, превращаем ее в человекочитаемый вид */
export const NOTIFICATION_EVENT_TYPES = {
  // ----------------- Список на бэке -----------------
  // EXAMPLE_EVENT_1 = 1
  // REGISTRATION_EVENT = 2
  // CASE_CHANGE_STATUS_EVENT = 3
  // RUN_CHANGE_STATUS_EVENT = 4
  // MILESTONE_CREATE_STATUS_EVENT = 5
  // MILESTONE_ENDDATE_1_EVENT = 6
  // MILESTONE_ENDDATE_2_EVENT = 7
  // PROFILE_FILL_EVENT = 8
  // RUN_ASSIGNED_EVENT = 9
  // ALL_CASES_APPROVED_EVENT = 10
  // INVITE_TO_COMPANY_EVENT = 11
  // DECLINE_INVITE_TO_COMPANY_EVENT = 12
  // TIMEOUT_INVITE_TO_COMPANY_EVENT = 13
  // NOTIFY_EVENT_INVITE_TO_COMPANY_REGISTER = 14
  // NOTIFY_EVENT_INVITE_TO_COMPANY_UNREGISTER = 15
  // ROLE_CHANGED_EVENT = 16
  // COMPANY_EXCLUDE_EVENT = 17
  // INVITE_TO_PROJECT_EVENT = 18
  // PROJECT_EXCLUDE_EVENT = 19
  // CASES_IMPORT_EVENT = 20

  // ----------------- Список на фронте -----------------
  runAssigh: 9,
  // system: "system",
  runChangeStatus: 4,
  caseChangeStatus: 3,
  milestoneDeadline1: 6, // за 1 день до дедлайна
  milestoneDeadline2: 7, // за 2 дня до дедлайна
  profileFill: 8,
  milestoneCreate: 5,
  allCases: 10,
  inviteSended: 11,
  inviteDeclined: 12,
  inviteTimeout: 13,
  inviteProject: 18,
  roleChanged: 16,
  excludeCompany: 17,
  excludeProject: 19,
  importCases: 20
}

export const MODALS_CONTENTS = {
  empty: 'empty',
  commentsCase: 'commentsCase',
  commentsTask: 'commentsTask',
  removeMemberCompany: 'removeMemberCompany',
  removeMemberInvite: 'removeMemberInvite',
  resendMemberInvite: 'resendMemberInvite',
  addUserCompany: 'addUserCompany',
  syncCases: 'syncCases',
  bugCase: 'bugCase',
  bugCaseCreate: 'bugCaseCreate',
  showPreview: 'showPreview',
  removeMemberProject: 'removeMemberProject'
}

export const VERIFY_TYPES: { [key: string]: TVerify } = {
  Link: 'link',
  Code: 'code'
}

export const COMPANY_TYPES: { [key: string]: TCompanyType } = {
  person: 'PERSON',
  entity: 'ENTITY'
}

export const COMPANY_ROLES: { [key in TCompanyRole]: TCompanyRole } = {
  owner: 'owner',
  admin: 'admin',
  employee: 'employee'
}

export const COMPANY_STATUS: { [key: string]: TCompanyStatus } = {
  active: 'active',
  banned: 'banned',
  deleted: 'deleted'
}

export const SYNC_CASE_TYPES: { [key: string]: TSyncCase } = {
  save: 'SAVE',
  revert: 'REVERT'
}

export const USER_PERMISSIONS: { [key in TPermissionKey]: TPermissionKey } = {
  administration: 'administration',
  ai_generation: 'ai_generation',
  assign_run: 'assign_run',
  case: 'case',
  case_approve: 'case_approve',
  cases: 'cases',
  cases_export: 'cases_export',
  gitlab_reports: 'gitlab_reports',
  history: 'history',
  jira_issues: 'jira_issues',
  members: 'members',
  milestone: 'milestone',
  milestones: 'milestones',
  project: 'project',
  run: 'run',
  runs: 'runs'
}

export const PERMISSION_LEVEL: { [key in TPermissionLevel]: TPermissionLevel } =
  {
    forbidden: 'forbidden',
    full: 'full',
    read: 'read',
    update: 'update'
  }

/** Редактирование с созданием */
export const CREATE_PERMISSION_LEVEL = [
  PERMISSION_LEVEL.full
]

/** Редактирование без создания */
export const EDIT_PERMISSION_LEVEL = [
  PERMISSION_LEVEL.full,
  PERMISSION_LEVEL.update
]

/** Константа только для завязки на то, что у пользователя есть хоть какой-то доступ к пермишну */
export const CAN_VIEW_PERMISSION_LEVEL = [
  PERMISSION_LEVEL.full,
  PERMISSION_LEVEL.update,
  PERMISSION_LEVEL.read
]

export const CASE_ICON = {
  CASE: 'test_case',
  CHECKLIST: 'test_check',
  TASK: 'test_task'
}

export const IMPORT_STATUSES: { [key: string]: TNotificationImportStatus } =
  {
    queue: 'cases_import_queue',
    start: 'cases_import_start',
    finish: 'cases_import_finish',
    error: 'cases_import_error'
  }
