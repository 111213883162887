import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Form } from '@/components/Form'
import { reactHookFormErrorFormater } from '@/utils'
import Title from '@/components/Title'
import { FormattedMessage, useIntl } from 'react-intl'
import RoleCompanySelect from '@/components/Select/RoleCompanySelect'
import style from './styles/modal.module.scss'
import Button from '@/components/Button/Button'
import Icon from '@/components/Icon/Icon'
import Input from '@/components/Input/Input'
import { useStores } from '@/hooks'
import { COMPANY_ROLES } from '@/const'
import CompanyMemberShort from '@/components/Member/CompanyMemberShort'

export interface IFormInputs {
  non_field_errors?: string
  email: string
  role: number | ''
}

interface IProps {
  handleClose: () => void
}

const ViewAddUserCompany = ({ handleClose }: IProps): React.ReactElement => {
  const [isSuccess, setSuccess] = useState(false)
  const { api, modalStore } = useStores()
  const idCompany = modalStore.dataModal.idCompany
  const intl = useIntl()
  const {
    setError,
    handleSubmit,
    register,
    watch,
    control,
    formState: { isSubmitting, errors }
  } = useForm<IFormInputs>({
    defaultValues: {
      role: '',
      email: ''
    }
  })

  const onSubmit = async (params): Promise<void> => {
    try {
      await api.sendInviteCompany(params, idCompany)
      setSuccess(true)
    } catch (error) {
      const errors = reactHookFormErrorFormater(params, error)
      errors.forEach((item) => {
        setError(item.key as keyof IFormInputs, {
          type: '400',
          message: item.value
        })
      })
    }
  }

  const email = watch('email')

  const nonFieldErrors = errors?.non_field_errors?.message ?? ''

  return (
    <>
      {!isSuccess
        ? (
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className={style.invite__content}>
              <Title className={style.invite__title}>
                <FormattedMessage
                  id='company.invite.title'
                  defaultMessage='Add user'
                />
              </Title>

              <Controller
                control={control}
                name='role'
                render={({ field: { onChange, value } }) => {
                  return (
                    <RoleCompanySelect
                      handleChange={onChange}
                      value={String(value)}
                      error={errors?.role?.message ?? ''}
                      required
                      bannedRoles={COMPANY_ROLES.owner}
                    />
                  )
                }}
              />

              <Input
                label={intl.formatMessage({
                  id: 'company.invite.user',
                  defaultMessage: 'User'
                })}
                required
                className={style.form__block}
                type='email'
                {...register('email', {
                  required: true
                })}
                error={errors?.email?.message ?? ''}
              />

              {nonFieldErrors !== ''
                ? (
                  <div className={style.invite__error}>{nonFieldErrors}</div>
                  )
                : null}
            </div>

            <div className={style.invite__bottom}>
              <Button onClick={handleClose} theme='light'>
                <Icon src='clear' slot='icon-left' />

                <FormattedMessage id='common.cancel' defaultMessage='Cancel' />
              </Button>

              <Button disabled={isSubmitting} type='submit'>
                <Icon src='checked' slot='icon-left' />

                <FormattedMessage
                  id='company.invite.submit'
                  defaultMessage='Add'
                />
              </Button>
            </div>
          </Form>
          )
        : (
          <div className={style.success__block}>
            <div className={style.success__top}>
              <div className={style.success__successtext}>
                <FormattedMessage
                  id='company.invite.success'
                  defaultMessage='The invitation was sent'
                />
              </div>

              <CompanyMemberShort name={email} avatar={null} initials='' />
            </div>

            <Button onClick={handleClose} className={style.success__end}>
              <Icon src='checked' slot='icon-left' />

              <FormattedMessage id='common.ok' defaultMessage='Ok' />
            </Button>
          </div>
          )}
    </>
  )
}

export default ViewAddUserCompany
