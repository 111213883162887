import * as yup from 'yup'
import { FormattedMessage } from 'react-intl'
import React from 'react'

export const gitlabValidationSchema = yup.object({
  gitlab_url: yup
    .string()
    .url(() => (
      <FormattedMessage
        id='validation.url_format'
        defaultMessage='Field must start with http or https. Field is required.'
      />
    ))
    .required(() => (
      <FormattedMessage
        id='validation.required'
        defaultMessage='Field is required'
      />
    ))
    .nullable(),
  gitlab_id: yup
    .string()
    .required(() => (
      <FormattedMessage
        id='validation.required'
        defaultMessage='Field is required'
      />
    ))
    .nullable(),
  gitlab_group: yup
    .string()
    .required(() => (
      <FormattedMessage
        id='validation.required'
        defaultMessage='Field is required'
      />
    ))
    .nullable(),
  gitlab_token: yup
    .string()
    .required(() => (
      <FormattedMessage
        id='validation.required'
        defaultMessage='Field is required'
      />
    ))
    .nullable()
})
