import { ModalDelete } from '@/components/Modal'
import Title from '@/components/Title'
import { ISuite } from '@/interfaces/Suites'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import style from '../styles/casessuites.module.scss'

interface IProps {
  suite: ISuite
  onCancel: () => void
  onDelete: (id: number) => void
  disableSubmit: boolean
}

const DeleteSuite = ({
  suite,
  onCancel,
  onDelete,
  disableSubmit
}: IProps): React.ReactElement => {
  const { title, cases_count: count, id } = suite
  const handleDelete = (): void => {
    onDelete(id)
  }

  return (
    <ModalDelete
      onCancel={onCancel}
      onDelete={handleDelete}
      disableSubmit={disableSubmit}
    >
      <Title type='h2' theme='h1' className={style.delete__title}>
        <FormattedMessage
          id='suites.delete.title'
          defaultMessage='Do you really want to delete the test suite '
        />
        <span className={style.delete__name}>{title}</span>?
      </Title>

      {count > 0
        ? (
          <p className={style.delete__text}>
            <FormattedMessage
              id='suites.delete.text'
              defaultMessage='It contains {count, plural, one {# case} other {# cases}}'
              values={{ count }}
            />
          </p>
          )
        : null}
    </ModalDelete>
  )
}

export default DeleteSuite
