import React from 'react'
import ProjectLayout from '../layout/components/ProjectLayout'
import UserLayout from '../layout/components/UserLayout'
import Integrations from '../Integrations'

const ProjectIntegrationsPage = (): React.ReactElement => {
  return (
    <UserLayout>
      <ProjectLayout>
        <Integrations />
      </ProjectLayout>
    </UserLayout>
  )
}

export default ProjectIntegrationsPage
