import Icon from '@/components/Icon/Icon'
import MenuItem from '@/components/MenuItem/MenuItem'
import React, { ChangeEvent } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Select from '@/components/Select/Select'
import { useFormContext } from 'react-hook-form'
import { IFormData } from '../interfaces'
import { priorities } from '../utils'

interface IProps {
  error?: string
}

const JiraBugPrioritySelect = ({ error }: IProps): JSX.Element => {
  const intl = useIntl()
  const { setValue, watch, register } = useFormContext<IFormData>()
  const selectedPriority = watch('priority')
  const selectedPriorityInfo = priorities.find(
    (priority) => priority.value === selectedPriority
  )

  return (
    <Select
      required
      {...register('priority', { required: true })}
      error={error}
      placeholder={intl.formatMessage({
        id: 'input.placeholder',
        defaultMessage: 'Choose'
      })}
      label={intl.formatMessage({
        id: 'case.form.priority',
        defaultMessage: 'Priority'
      })}
      onChange={(e: ChangeEvent<HTMLInputElement>) =>
        setValue('priority', e.target.value)}
      value={selectedPriority}
    >
      {(selectedPriorityInfo != null)
        ? (
          <Icon src={selectedPriorityInfo.icon} slot='prefix' />
          )
        : null}

      {priorities.map((priority) => (
        <MenuItem key={priority.value} value={priority.value}>
          <Icon src={priority.icon} />
          <FormattedMessage
            id={priority.id}
            defaultMessage={priority.defaultMessage}
          />
        </MenuItem>
      ))}
    </Select>
  )
}

export default JiraBugPrioritySelect
