import Button from '@/components/Button/Button'
import { Form } from '@/components/Form'
import Icon from '@/components/Icon/Icon'
import Title from '@/components/Title'
import React, { useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { FormProvider, useForm } from 'react-hook-form'
import { IFormData } from '../interfaces'
import { useObjectPage, useStores } from '@/hooks'
import { IJiraIssues } from '@/interfaces/Project'
import JiraBugFormBody from './JiraBugFormBody'
import { IObjectPage } from '@/interfaces/ObjectPage'
import { replaceRussianSymbols } from '../utils'
import useSWR from 'swr'
import ErrorsBlock from '@/components/ErrorsBlock'
import { reactHookFormErrorFormater } from '@/utils'
import useCurrentUser from '@/hooks/useCurrentUser'
import style from '../styles/case_detail.module.scss'

export interface IBugProps {
  handleCloseModal: () => void
  caseStep?: number
  taskId?: number
  updateStepData: () => Promise<void>
}

const JiraBugCreationForm = ({
  handleCloseModal,
  updateStepData,
  caseStep,
  taskId
}: IBugProps): JSX.Element => {
  const page: IObjectPage = useObjectPage()
  const { api } = useStores()
  const intl = useIntl()
  const projectId = page.project
  const { currentUser } = useCurrentUser()
  const formMethods = useForm<IFormData>()

  const { mutate: mutateJiraIssues } = useSWR(
    {
      id: projectId,
      case_run_step: caseStep,
      _key: 'getJiraIssues'
    },
    api.getJiraIssues
  )

  useEffect(() => {
    void api.getProject({ id: projectId }).then((project) => {
      formMethods.reset({
        project: project.code,
        priority: 'MEDIUM',
        reporter: currentUser?.email
      })
    })
  }, [])

  const modalJiraTitle = intl.formatMessage({
    id: 'case.steps.create_bug',
    defaultMessage: 'Create Bug'
  })

  const updateData = async (): Promise<void> => {
    await updateStepData()
    await mutateJiraIssues()
  }

  const onSubmit = async (params: IFormData): Promise<void> => {
    const objectToServer: IJiraIssues = {
      case_run_step: caseStep ?? undefined,
      task: taskId ?? undefined,
      description: params.description,
      issuetype: params.issuetype.toString(),
      summary: params.summary,
      priority: params.priority,
      assignee: params.assignee.email,
      parent: params.issubtask ? params.parent_task : undefined,
      timeoriginalestimate:
        params.timeoriginalestimate !== ''
          ? replaceRussianSymbols(params.timeoriginalestimate)
          : undefined,
      custom_fields: params.custom_fields,
      files: params.files ?? []
    }
    try {
      await api.postJiraIssues(page.project ?? '', objectToServer)
      handleCloseModal()
    } catch (error) {
      const errors = reactHookFormErrorFormater(params, error, 'data')
      errors.forEach((item) => {
        formMethods.setError(item.key as keyof IFormData, {
          message: item.value
        })
      })

      if (formMethods.formState.errors?.reporter !== undefined) {
        formMethods.setError('reporter', {
          message: intl.formatMessage({
            id: 'case.validation.reporter',
            defaultMessage: 'The reporter is not a jira user'
          })
        })
      }
    } finally {
      await updateData()
    }
  }

  const filesCount = formMethods.watch('files')?.length

  const nonFieldErrors =
    formMethods.formState.errors?.non_field_errors?.message ?? ''

  return (
    <Form onSubmit={formMethods.handleSubmit(onSubmit)}>
      <div className={style.modal__top}>
        <Title type='h2' className={style.modal__title}>
          <Icon src='bug' className={style.icon__black} />
          {modalJiraTitle}
        </Title>

        {handleCloseModal != null
          ? (
            <Button onClick={handleCloseModal} theme='light' circle>
              <Icon src='clear' />
            </Button>
            )
          : null}
      </div>
      <FormProvider {...formMethods}>
        <JiraBugFormBody
          register={formMethods.register}
          control={formMethods.control}
          setValue={formMethods.setValue}
          filesCount={filesCount ?? 0}
        />
      </FormProvider>

      <div className={style.modal__footer}>
        <ErrorsBlock
          errorsArray={[nonFieldErrors]}
          className={style.footer__errors}
        />

        <Button type='submit'>
          <Icon src='checked' slot='icon-left' />
          <FormattedMessage id='common.create' defaultMessage='Save' />
        </Button>
      </div>
    </Form>
  )
}

export default JiraBugCreationForm
