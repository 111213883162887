import React from 'react'
import style from '../style/profile.module.scss'
import { FormattedMessage, useIntl } from 'react-intl'
import Button from '@/components/Button/Button'
import Icon from '@/components/Icon/Icon'
import Input from '@/components/Input/Input'
import { useStores } from '@/hooks'
import { Controller, useForm } from 'react-hook-form'
import { reactHookFormErrorFormater } from '@/utils'
import { Form } from '@/components/Form'

interface IProps {
  handleChangeEmail: (value: string) => void
  onClose: () => void
  success: () => void
  email: string
}
export interface IFormInputs {
  email?: string
  non_field_errors?: string
}

const NewEmail = ({
  success,
  handleChangeEmail,
  email,
  onClose
}: IProps): React.ReactElement => {
  const intl = useIntl()

  const { api } = useStores()

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting }
  } = useForm<IFormInputs>({
    defaultValues: {
      email: ''
    }
  })

  const onSubmit = async (params): Promise<void> => {
    try {
      await api.sendCodeEmail({
        email: params.email
      })
      success()
    } catch (error) {
      const errors = reactHookFormErrorFormater(params, error)
      errors.forEach((item) => {
        setError(item.key as keyof IFormInputs, {
          message: item.value
        })
      })
    }
  }

  const nonFieldErrors = errors?.non_field_errors?.message ?? ''

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className={style.modal__top}>
        <FormattedMessage
          id='profile.change_email.title'
          defaultMessage='New Email adress'
        />

        <Button onClick={onClose} theme='light' circle>
          <Icon src='clear' />
        </Button>
      </div>

      <div className={style.modal__middle}>
        <FormattedMessage
          id='profile.change_email.text'
          defaultMessage='Enter your new email, which you will use to log in to the system'
        />
        <Controller
          control={control}
          name='email'
          render={({ field: { onChange, value } }) => {
            return (
              <Input
                label={intl.formatMessage({
                  id: 'profile.change_email.email',
                  defaultMessage: 'Email'
                })}
                className={style.modal__input}
                required
                value={value}
                onChange={(e) => {
                  handleChangeEmail(e.target.value)
                  onChange(e.target.value)
                }}
                error={errors?.email?.message ?? ''}
              />
            )
          }}
        />

        {nonFieldErrors !== ''
          ? (
            <div className={style.modal__error}>{nonFieldErrors}</div>
            )
          : null}
      </div>

      <div className={style.modal__bottom}>
        <Button disabled={isSubmitting} type='submit'>
          <Icon src='checked' slot='icon-left' />

          <FormattedMessage id='common.save' defaultMessage='Save' />
        </Button>
      </div>
    </Form>
  )
}

export default NewEmail
