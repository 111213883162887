import React, { useState } from 'react'
import Wrapper from '@/components/Wrapper'
import style from '../style/report_detail.module.scss'
import BackButton from '@/components/Button/BackButton'
import Title from '@/components/Title'
import { FormattedMessage } from 'react-intl'
import Button from '@/components/Button/Button'
import Icon from '@/components/Icon/Icon'
import { useLocale, useObjectPage, usePage, useStores } from '@/hooks'
import { IReportPage } from '@/interfaces/ObjectPage'
import useSidebar from '@/hooks/useSidebar'
import { downloadFile } from '@/utils'

const Head = ({ dates }): React.ReactElement => {
  const [isGenerating, setIsGenerating] = useState(false)

  const objectPage = useObjectPage<IReportPage>()
  const page = usePage()
  const { locale } = useLocale()
  const { ProjectPageReports } = useSidebar()
  const store = useStores()

  const handleGetPdf = (): void => {
    setIsGenerating(true)
    const params = {
      date_from: dates.start,
      date_to: dates.end,
      id: page.init_state.object.id
    }

    store.api
      .getReportPdf(params)
      .then((res) => {
        const date = new Date()
        const fileName =
          'Report_' +
          objectPage.title +
          '_' +
          date.toJSON().slice(0, 10) +
          '.pdf'
        downloadFile(res.data, fileName)
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => setIsGenerating(false))
  }

  const formattedDate = new Date(
    page.init_state.status_updated_at
  ).toLocaleString(locale, {
    timeStyle: 'short',
    dateStyle: 'short'
  })
  return (
    <Wrapper className={style.block}>
      <div className={style.head__top}>
        <BackButton href={ProjectPageReports.absolute_url} />

        <Title type='h1' className={style.head__title}>
          <FormattedMessage id='titles.report' defaultMessage='Report' />
        </Title>

        <Button onClick={handleGetPdf} disabled={isGenerating}>
          <Icon src='download' slot='icon-left' />
          <FormattedMessage
            id='reports.download_report'
            defaultMessage='Download report'
          />
        </Button>
      </div>

      <div className={style.head__bottom}>
        <div className={style.info}>
          <div
            className={style.info__color}
            style={{ backgroundColor: objectPage.color }}
          />

          <div>
            <span className={style.info__code}>{objectPage.code}</span>
            <span className={style.info__name}>{objectPage.title}</span>
          </div>
        </div>

        <div className={style.head__date}>
          <span className={style.head__datetime}>{formattedDate}</span>
        </div>
      </div>
    </Wrapper>
  )
}

export default Head
