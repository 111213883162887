import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import { createBrowserHistory, History } from "history";
import { wrapHistory } from "oaf-react-router";
import React from "react";
import { HelmetProvider } from "react-helmet-async";
import ReactDOM from "react-dom/client";
import { observe } from "mobx";
import {
  applyPolyfills,
  defineCustomElements,
} from "@garpix/garpix-web-components/loader";
import "@garpix/garpix-web-components/dist/garpix-web-components/garpix-web-components.css";
import App from "./App";
import { RootStore } from "./services";
import { StoreProvider } from "./contexts";
import { defaultLanguage } from "@/i18n";
import { runtimeConfig } from "./config";
import * as Sentry from "@sentry/react";
// import { Integrations } from '@sentry/tracing';
import SentryError from "./apps/SentryError";
import { RouterStore } from "./services/RouterStore";
import { ISyncedHistory } from "./interfaces/Stores";

/**
 * @see https://github.com/alisd23/mobx-react-router/blob/master/src/sync.js
 */
const syncHistoryWithStore = (
  history: History,
  store: RouterStore
): ISyncedHistory => {
  // Initialise store
  store.history = history;

  // Handle update from history object
  const handleLocationChange = (location) => {
    store._updateLocation(location);
  };

  const unsubscribeFromHistory = history.listen(handleLocationChange);
  handleLocationChange(history.location);

  const subscribe = (listener) => {
    const onStoreChange = () => {
      const rawLocation = { ...store.location };
      listener(rawLocation, history.action);
    };

    // Listen for changes to location state in store
    const unsubscribeFromStore = observe(store, "location", onStoreChange);

    listener(store.location, history.action);

    return unsubscribeFromStore;
  };

  const syncedHistory: ISyncedHistory = {
    ...history,
    subscribe: subscribe,
    unsubscribe: unsubscribeFromHistory,
  };

  return syncedHistory;
};

const container = document.getElementById("root") || document.body;

const API_URL = runtimeConfig.API_URL;

const rootStore = new RootStore(API_URL, window.language ?? defaultLanguage);
const browserHistory = createBrowserHistory();

const history = syncHistoryWithStore(browserHistory, rootStore.routerStore);
(window as any).rootStore = rootStore
const settings = {
  primaryFocusTarget: "body",
  restorePageStateOnPop: true,
  smoothScroll: false,
};

if (String(window.env.RAZZLE_SENTRY_ENABLE) === "1") {
  Sentry.init({
    dsn: "https://77e8ccdebb6549439cb3a7a4cc2eb39b@sentry.garpix.com/44",
    // integrations: [new Integrations.BrowserTracing()],
    // integrations: [new Sentry.BrowserTracing()],

    // tracesSampleRate: 1.0,

    // autoSessionTracking: false,
  });
}

wrapHistory(history, settings);

ReactDOM.hydrateRoot(
  container,
  <Sentry.ErrorBoundary fallback={<SentryError />}>
    <StoreProvider store={rootStore}>
      <HelmetProvider>
        <HistoryRouter history={history}>
          <App />
        </HistoryRouter>
      </HelmetProvider>
    </StoreProvider>
  </Sentry.ErrorBoundary>
);

applyPolyfills().then(() => {
  defineCustomElements();
});

if (module.hot) {
  module.hot.accept();
}
