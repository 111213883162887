import { VERIFY_TYPES } from '@/const'
import { TVerify } from '@/interfaces/Types'
import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'

interface IProps {
  type: TVerify
  seconds: number
  setSeconds: (value) => void
}

const Timer = ({
  seconds,
  setSeconds,
  type
}: IProps): React.ReactElement | null => {
  useEffect(() => {
    let timeoutId
    if (seconds > 0) {
      timeoutId = setTimeout(setSeconds, 1000, seconds - 1)
    }
    return () => {
      clearTimeout(timeoutId)
    }
  }, [seconds])

  if (seconds <= 0) {
    return null
  }

  return (
    <div>
      {type === VERIFY_TYPES.Link
        ? (
          <FormattedMessage
            id='profile.again_send.link'
            defaultMessage='You can get the approval link again via: {seconds, plural, one {# second} other {# seconds}}'
            values={{ seconds }}
          />
          )
        : (
          <FormattedMessage
            id='profile.again_send.code'
            defaultMessage='You can get the approval code again via: {seconds, plural, one {# second} other {# seconds}}'
            values={{ seconds }}
          />
          )}
    </div>
  )
}

export default Timer
