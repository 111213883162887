import { Accordion } from '@/components/Accordion'
import Title from '@/components/Title'
import Wrapper from '@/components/Wrapper'
import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { UploadZoneForm } from '@/components/UploadZone'
import style from '../styles/add_case.module.scss'

const AccordionHead = ({ count }: { count: number }): React.ReactElement => {
  return (
    <Title type='h2' className={style.files__title}>
      <FormattedMessage id='titles.files' defaultMessage='Files' />
      {` (${count})`}
    </Title>
  )
}

const Files = ({
  onChange,
  initFiles,
  defaultOpened,
  files
}): React.ReactElement => {
  const [filesCount, setFilesCount] = useState<number>(
    Number(initFiles.length)
  )
  const [initCount, setInitCount] = useState<number>(
    Number(initFiles.length)
  )
  const handleChangeInitCount = (): void => {
    setInitCount(initCount - 1)
  }

  useEffect(() => {
    setFilesCount(initCount + Number(files.length))
  }, [files])
  return (
    <Wrapper className={style.files}>
      <Accordion
        head={<AccordionHead count={filesCount} />}
        defaultOpened={defaultOpened}
      >
        <UploadZoneForm
          initFiles={initFiles}
          onChange={onChange}
          canUserDeleteFile
          setFilesCount={handleChangeInitCount}
        />
      </Accordion>
    </Wrapper>
  )
}

export default Files
