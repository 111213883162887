import style from '../style/profile.module.scss'
import React from 'react'
import Icon from '@/components/Icon/Icon'
import Button from '@/components/Button/Button'
import { FormattedMessage } from 'react-intl'

interface IProps {
  onClose: () => void
}

const SuccessPassword = ({ onClose }: IProps): React.ReactElement => {
  return (
    <>
      <div className={style.modal__success}>
        <div className={style.modal__icon}>
          <Icon src='checked' size='small' className={style.modal__bigicon} />
        </div>

        <Button onClick={onClose} theme='light' circle>
          <Icon src='clear' />
        </Button>
      </div>

      <div className={style.modal__successtext}>
        <FormattedMessage
          id='profile.forgot_pass.success'
          defaultMessage='Your password has been changed!'
        />
      </div>
    </>
  )
}

export default SuccessPassword
