import React, { useEffect, useState } from 'react'
import useSWR from 'swr'
import { useObjectPage, usePage, useStores } from '@/hooks'
import Container from '@/components/Container'
import Wrapper from '@/components/Wrapper'
import Title from '@/components/Title'
import { Form } from '@/components/Form'
import Icon from '@/components/Icon/Icon'
import style from './styles/members.module.scss'
import { FormattedMessage, useIntl } from 'react-intl'
import MemberList from './MemberList/MemberList'
import ListContent from '@/components/ListContent'
import useSidebar from '@/hooks/useSidebar'
import SearchInput from '@/components/Input/SearchInput/SearchInput'
import useDebounce from '@/hooks/useDebounce'
import { scrollTop } from '@/utils'
import RoleProjectSelect from '@/components/Select/RoleProjectSelect'
import useModal from '@/hooks/useModal'
import Button from '@/components/Button/Button'
import AddMembers from '@/components/AddMembers'
import { observer } from 'mobx-react'
import AllUsersAdded from '@/apps/AddOrEditProject/components/AllUsersAdded'
import { IMembersPage } from '@/interfaces/ObjectPage'
import { useCurrentPermissions } from '@/hooks/useCurrentPermissions'
import { EDIT_PERMISSION_LEVEL, USER_PERMISSIONS } from '@/const'
import { IUpdateMembers } from '@/interfaces/ApiParams'
import SpecifyMembers from '@/components/AddMembers/SpecifyMembers'
import { ICompanyUser } from '@/interfaces/Company'

const PAGE_SIZE = 10
const PAGE_SIZE_COMPANY = 1

const Members = (): React.ReactElement => {
  const { api } = useStores()
  const obj = usePage()
  const objectPage: IMembersPage = useObjectPage()
  const subpage = useSidebar()
  const [search, setSearch] = useState<string>('')
  const debouncedSearch = useDebounce(search)
  const [role, setRole] = useState<number | ''>('')
  const [companyUsersSelect, setCompanyUsers] = useState<ICompanyUser[]>([])
  const [newMembers, setNewMembers] = useState<IUpdateMembers[]>([])
  const [page, setPage] = useState(1)
  const intl = useIntl()
  const { isOpen, handleOpenModal, handleCloseModal } = useModal()
  const {
    isOpen: isOpenSpecify,
    handleOpenModal: handleOpenModalSpecify,
    handleCloseModal: handleCloseModalSpecify
  } = useModal()
  const [showAllUsersAddedModal, setShowAllUsersAddedModal] = useState(false)
  const currentUser = obj.init_state.project_object.current_user
  const canEditCurrentUser = useCurrentPermissions(
    USER_PERMISSIONS.administration,
    EDIT_PERMISSION_LEVEL
  )

  const projectCompany = objectPage.company.id
  const projectId = objectPage.id

  const { data: company } = useSWR(
    { id: projectCompany, _key: 'getCompany' },
    api.getCompany
  )

  const { data, error, isLoading, mutate } = useSWR(
    () => ({
      projectId: projectId,
      page: page,
      page_size: PAGE_SIZE,
      q: debouncedSearch,
      abac_role: role !== '' ? role : undefined,
      _key: 'getMembers'
    }),
    async ({ projectId, ...params }) =>
      await api.getProjectMembers(projectId, params),
    {
      onSuccess: () => {
        scrollTop()
      }
    }
  )

  const { data: companyUsers, mutate: companyUsersMutate } = useSWR(
    {
      page: 1,
      page_size: PAGE_SIZE_COMPANY,
      company_id: projectCompany,
      project_id: projectId,
      _key: 'getCompanyUsers'
    },
    api.getCompanyUsersNotInProject
  )

  const { data: roles } = useSWR([objectPage.id], api.getProjectRolesShort)

  const handleUpdateProject = async (): Promise<void> => {
    const isRolesSelect =
      newMembers.findIndex((el) => el.abac_role === undefined) === -1
    if (isRolesSelect) {
      try {
        await api.updateProject(objectPage.id, {
          members: newMembers
        })
        await mutate()
        await companyUsersMutate()
        setNewMembers([])
        setCompanyUsers([])
        handleCloseModal()
        handleCloseModalSpecify()
      } catch (error) {
        console.error('Error updating project and members:', error)
      }
    } else {
      handleCloseModal()
      handleOpenModalSpecify()
    }
  }

  const handleRole = (value: string): void => {
    setRole(value !== '' ? Number(value) : '')
    setPage(1)
  }

  const handleSearch = (value: string): void => {
    setSearch(value)
    setPage(1)
  }
  const handleCloseSpecify = (): void => {
    handleCloseModalSpecify()
    handleOpenModal()
    const users = newMembers.map((el) => {
      return { ...el, abac_role: undefined }
    })
    setNewMembers(users)
  }

  const handleRemove = async (_id): Promise<void> => {
    await api.removeMembers(projectId, _id)
    await mutate()
    await companyUsersMutate()
  }

  useEffect(() => {
    if (companyUsers != null) {
      const allAdded = companyUsers.count === 0
      setShowAllUsersAddedModal(allAdded)
    }
  }, [companyUsers, data?.results])

  return (
    <Container>
      <Wrapper className={style.members}>
        <div className={style.members__top}>
          <Title type='h1' className={style.members__title}>
            <FormattedMessage id='titles.members' defaultMessage='Members' />
          </Title>

          {canEditCurrentUser
            ? (
              <Button onClick={handleOpenModal} className={style.members__add}>
                <Icon src='plus' slot='icon-left' />
                <FormattedMessage
                  id='project.add_member'
                  defaultMessage='Add member'
                />
              </Button>
              )
            : null}
        </div>
        <Form onSubmit={undefined}>
          <div className={style.members__form}>
            <RoleProjectSelect
              showLabel
              clearable
              projectRoles={roles?.results ?? []}
              handleChange={handleRole}
              value={String(role)}
              className={style.members__filter}
            />

            <SearchInput
              handleChange={handleSearch}
              value={search}
              placeholder={intl.formatMessage({
                id: 'members.search',
                defaultMessage: 'Search members'
              })}
            />
          </div>
        </Form>

        <ListContent
          isLoading={isLoading}
          error={error}
          hasData={data !== undefined && data?.count > 0}
          emptyListIcon='tester'
          emptyListText={intl.formatMessage({
            id: 'members.no_members',
            defaultMessage: 'Empty list'
          })}
          emptyListButton={intl.formatMessage({
            id: 'members.add_member',
            defaultMessage: 'Add member'
          })}
          href={subpage?.ProjectPageUpdate.absolute_url}
        >
          {data !== undefined
            ? (
              <MemberList
                projectId={projectId}
                roles={roles?.results ?? []}
                data={data}
                currentPage={page}
                pageSize={PAGE_SIZE}
                setPage={setPage}
                currentUser={currentUser}
                onRemove={handleRemove}
              />
              )
            : null}
        </ListContent>
      </Wrapper>

      {isOpen && roles?.results != null && projectCompany !== undefined
        ? (
            showAllUsersAddedModal
              ? (
                <AllUsersAdded
                  companyUrl={company?.absolute_url}
                  onClose={handleCloseModal}
                  baseModalProps={{
                    open: showAllUsersAddedModal,
                    size: 'medium',
                    hideDefaultClose: true,
                    'onGx-after-hide': handleCloseModal
                  }}
                />
                )
              : (
                <AddMembers
                  baseModalProps={{
                    open: isOpen,
                    hideDefaultClose: true,
                    'onGx-after-hide': handleCloseModal
                  }}
                  onClose={handleCloseModal}
                  handleUpdateProject={handleUpdateProject}
                  roles={roles?.results ?? []}
                  projectId={projectId}
                  projectMembers={data?.results ?? []}
                  projectCompany={projectCompany}
                  setMember={setNewMembers}
                  newMembers={newMembers}
                  setUsersNotInProject={setCompanyUsers}
                  title={
                    <FormattedMessage
                      id='project.add.member'
                      defaultMessage='Add member'
                    />
            }
                />
                )
          )
        : null}

      {isOpenSpecify
        ? (
          <SpecifyMembers
            baseModalProps={{
              open: isOpenSpecify,
              hideDefaultClose: true,
              'onGx-after-hide': handleCloseModal
            }}
            roles={roles?.results ?? []}
            onClose={handleCloseSpecify}
            handleUpdateProject={handleUpdateProject}
            setMember={setNewMembers}
            companyUsersSelect={companyUsersSelect}
            newMembers={newMembers}
          />
          )
        : null}
    </Container>
  )
}

export default observer(Members)
