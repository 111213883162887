import AsyncCreatableSelect from 'react-select/async-creatable'
import React, { useRef } from 'react'
import style from './styles/baseasyncselect.module.scss'
import customSelectStyles from './styles'
import { useIntl } from 'react-intl'
import classNames from 'classnames'
import { ClearIndicator, DropdownIndicator } from './components'

interface SelectProps {
  placeholder: string
  label?: string
  isMulti?: boolean
  handleChange: (e) => void
  loadOptions: (inputValue: string, callback: (options: any) => void) => void
  selectClassName?: string
  labelClassName?: string
}

const BaseAsyncCreatableSelect = ({
  placeholder,
  label,
  isMulti = false,
  handleChange,
  loadOptions,
  selectClassName = '',
  labelClassName = ''
}: SelectProps): React.ReactElement => {
  const intl = useIntl()
  const selectInputRef = useRef<any>()

  const noOptionsMessage = intl.formatMessage({
    id: 'select.no_options',
    defaultMessage: 'No options'
  })

  const loadingMessage = intl.formatMessage({
    id: 'select.loading',
    defaultMessage: 'Loading'
  })

  const createMessage = (tagName: string): string =>
    intl.formatMessage(
      {
        id: 'select.create',
        defaultMessage: 'Create "{tag}"'
      },
      {
        tag: tagName
      }
    )

  const onChanges = (e): void => {
    if (e !== null) {
      handleChange(e)
    }
  }

  const handleCreate = (e): void => {
    handleChange({ label: e, _isNew: true })
  }
  return (
    <label
      className={classNames({
        [style.baseasyncselect__label]: true,
        [labelClassName]: Boolean(labelClassName)
      })}
    >
      <span className={style.baseasyncselect__label_text}>{label}</span>
      <AsyncCreatableSelect
        isClearable
        ref={selectInputRef}
        styles={customSelectStyles}
        placeholder={placeholder}
        components={{ DropdownIndicator, ClearIndicator }}
        onChange={onChanges}
        onCreateOption={handleCreate}
        isMulti={isMulti}
        noOptionsMessage={() => noOptionsMessage}
        loadingMessage={() => loadingMessage}
        formatCreateLabel={(value) => createMessage(value)}
        cacheOptions
        defaultOptions
        loadOptions={loadOptions}
        menuPortalTarget={document.body}
        className={selectClassName}
      />
    </label>
  )
}

export default BaseAsyncCreatableSelect
