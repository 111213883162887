import React, { createContext, useContext, useState } from 'react'

// null for unused state
interface IIntegrationStatusContextType {
  jiraIntegrationStatus: boolean | null
  gitlabIntegrationStatus: boolean | null
  setJiraIntegrationStatus: (status: boolean | null) => void
  setGitlabIntegrationStatus: (status: boolean | null) => void
}

export const IntegrationStatusContext = createContext<IIntegrationStatusContextType>({
  jiraIntegrationStatus: null,
  gitlabIntegrationStatus: null,
  setJiraIntegrationStatus: () => {},
  setGitlabIntegrationStatus: () => {}
})

export const IntegrationStatusProvider = ({ children }): JSX.Element => {
  const [jiraIntegrationStatus, setJiraIntegrationStatus] =
    useState<boolean | null>(null)
  const [gitlabIntegrationStatus, setGitlabIntegrationStatus] =
    useState<boolean | null>(null)

  return (
    <IntegrationStatusContext.Provider
      value={{
        jiraIntegrationStatus,
        gitlabIntegrationStatus,
        setJiraIntegrationStatus,
        setGitlabIntegrationStatus
      }}
    >
      {children}
    </IntegrationStatusContext.Provider>
  )
}

export const useIntegrationStatus = (): IIntegrationStatusContextType => {
  return useContext(IntegrationStatusContext)
}
