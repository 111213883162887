import React from 'react'
import ProjectLayout from '../layout/components/ProjectLayout'
import UserLayout from '../layout/components/UserLayout'
import ReportDetail from '../ReportDetail'

const ReportDetailPage = (): React.ReactElement => {
  return (
    <UserLayout>
      <ProjectLayout>
        <ReportDetail />
      </ProjectLayout>
    </UserLayout>
  )
}

export default ReportDetailPage
