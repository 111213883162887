import { Shimmer, ShimmerWrap } from '@/components/Loading'
import React from 'react'
import style from '../styles/project_roles.module.scss'

const Shimmers = (): React.ReactElement => {
  return (
    <>
      {Array.from({ length: 12 }, (_, index) => (
        <Shimmer key={index} />
      ))}
    </>
  )
}

const LoaderProjectRoles = (): React.ReactElement => {
  return (
    <ShimmerWrap className={style.shimmer_wrap}>
      <Shimmers />
    </ShimmerWrap>
  )
}

export default LoaderProjectRoles
