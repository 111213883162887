import React, { useEffect, useState } from 'react'
import style from './styles/projectmenu.module.scss'
import Icon from '@/components/Icon/Icon'
import classNames from 'classnames'
import { sidebarService } from '../../service'

interface IProps {
  children: React.ReactNode
  isBig: boolean
  icon: string
  title: string
  defaultOpened: boolean
  hasActiveLink: boolean
  storageKey: string
}

const storageKeyPrefix = 'acc_'

const ProjectMenuAccordion = ({
  children,
  isBig,
  icon,
  title,
  defaultOpened,
  hasActiveLink,
  storageKey
}: IProps): React.ReactElement => {
  const storageKeyFull = storageKeyPrefix + storageKey
  const storageValue = sidebarService.parsedStorage?.[storageKeyFull]

  const [opened, setOpened] = useState(defaultOpened || Boolean(storageValue))

  const handleToggleOpened = (): void => {
    setOpened(() => {
      sidebarService.changeStorageValue(storageKeyFull, !opened)
      return !opened
    })
  }

  useEffect(() => {
    if (storageValue === undefined) {
      sidebarService.changeStorageValue(storageKeyFull, defaultOpened)
    }
  }, [])

  return (
    <div>
      <button
        className={classNames({
          [style.accordion__head]: true,
          [style['accordion__head--opened']]: opened,
          [style['accordion__head--active']]: hasActiveLink
        })}
        type='button'
        onClick={handleToggleOpened}
      >
        <span className={style.accordion__headblock}>
          <Icon src={icon} size='small' className={style.accordion__headicon} />

          {isBig
            ? (
              <span className={style.accordion__title}>{title}</span>
              )
            : null}
        </span>

        {isBig
          ? (
            <Icon
              src='arrow_down'
              className={classNames({
                [style.accordion__icon]: true,
                [style['accordion__icon--opened']]: opened
              })}
            />
            )
          : null}
      </button>

      <div
        className={classNames({
          [style.accordion__body]: true,
          [style['accordion__body--opened']]: opened
        })}
      >
        {children}
      </div>
    </div>
  )
}

export default ProjectMenuAccordion
