import React, { useState } from 'react'
import NewPhone from './NewPhone'
import VerifyPhone from './VerifyPhone'
import SuccessPhone from './SuccessPhone'
import { MODAL_STAGE } from '@/const'

interface IProps {
  onClose: () => void
}

const ChangePhone = ({ onClose }: IProps): React.ReactElement => {
  const [stage, setStage] = useState<number>(MODAL_STAGE.first)
  const [phone, setPhone] = useState<string>('7')

  const StateMachineNext = {
    1: () => MODAL_STAGE.second,
    2: () => MODAL_STAGE.third
  }

  const handleNext = (): void => {
    setStage(StateMachineNext[String(stage)]())
  }

  return (
    <>
      {stage === MODAL_STAGE.first
        ? (
          <NewPhone
            onClose={onClose}
            phoneFormatted={phone}
            setPhoneFormatted={setPhone}
            success={handleNext}
          />
          )
        : null}
      {stage === MODAL_STAGE.second
        ? (
          <VerifyPhone
            phone={phone}
            success={handleNext}
            onClose={onClose}
          />
          )
        : null}

      {stage === MODAL_STAGE.third
        ? (
          <SuccessPhone onClose={onClose} />
          )
        : null}
    </>
  )
}

export default ChangePhone
