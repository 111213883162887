import classNames from 'classnames'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import style from './styles/errors_block.module.scss'

interface IProps {
  networkError?: boolean
  errorsArray?: string[]
  className?: string
}

const ErrorsBlock = ({
  errorsArray,
  networkError,
  className = ''
}: IProps): React.ReactElement => {
  return (
    <div
      className={classNames({
        [style.errors]: true,
        [className]: Boolean(className)
      })}
    >
      {errorsArray !== undefined && errorsArray.length > 0
        ? errorsArray.map((el) => {
          return (
            <span className={style.errors__text} key={el}>
              {el}
            </span>
          )
        })
        : null}
      {networkError === true
        ? (
          <span className={style.errors__text}>
            <FormattedMessage
              id='common.network_error'
              defaultMessage='Network error. Check your internet connection.'
            />
          </span>
          )
        : null}
    </div>
  )
}

export default ErrorsBlock
