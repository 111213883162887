import React from 'react'
import classNames from 'classnames'
import style from './styles/icon.module.scss'
import Icon from '../Icon'

interface IconProps {
  size?: 'xs' | 'small' | 'medium' | 'large' | 'xl' | 'xxl' | 'xxxl' | 'xxxxl'
  className?: string
}

const DownloadAnimated = ({
  size = 'medium',
  className = '',
  ...props
}): React.ReactElement<IconProps> => {
  const iconClasses = classNames({
    [style.icon]: true,
    [className]: Boolean(className)
  })

  return (
    <div className={iconClasses}>
      <Icon
        className={classNames(style.download, style.download__arrow)}
        src='downloadArrow'
        {...props}
      />

      <Icon
        className={classNames(style.download, style.download__bot)}
        src='downloadDown'
        {...props}
      />
    </div>
  )
}

export default DownloadAnimated
