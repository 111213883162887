import Container from '@/components/Container'
import { HistoryGroup } from '@/components/HistoryGroup'
import InputDayPicker from '@/components/InputDayPicker/InputDayPicker'
import ListContent from '@/components/ListContent'
import Pagination from '@/components/Pagination'
import HistoryModelSelect from '@/components/Select/HistoryModelSelect/HistoryModelSelect'
import Title from '@/components/Title'
import Wrapper from '@/components/Wrapper'
import { useObjectPage, useStores } from '@/hooks'
import { THistoryModel } from '@/interfaces/Types'
import { scrollTop } from '@/utils'
import { format, isValid, parse, subDays } from 'date-fns'
import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import useSWR from 'swr'
import style from './styles/history.module.scss'
import PageSizeSelect from '@/components/Select/PageSizeSelect'
import { pageSizeSelectService } from '@/services/PageSizeSelect'

const History = (): React.ReactElement => {
  const objectPage = useObjectPage()
  const store = useStores()
  const intl = useIntl()
  const [startDate, setStartDate] = useState<string>('')
  const [endDate, setEndDate] = useState<string>('')
  const [model, setModel] = useState<THistoryModel | ''>('')
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState<number>(
    pageSizeSelectService.getSize('historyPage') ?? 10
  )

  const formatDate = (date: string): string | undefined => {
    const parseDate = parse(date, 'dd/MM/yyyy', new Date())
    if (isValid(parseDate)) {
      return format(parseDate, 'yyyy-MM-dd')
    } else {
      return undefined
    }
  }

  const { data, error, isLoading } = useSWR(
    {
      project: objectPage.id,
      page: page,
      page_size: pageSize,
      date_after: formatDate(startDate),
      date_before: formatDate(endDate),
      _key: 'getHistory',
      model: model
    },
    store.api.getHistory,
    {
      onSuccess: () => {
        scrollTop()
      }
    }
  )

  const handleChangePageSize = (e: any): void => {
    const newPageSize = Number(e.target.value)
    setPageSize(newPageSize)
    pageSizeSelectService.setSize('historyPage', newPageSize)
    setPage(1)
  }

  return (
    <Container>
      <Wrapper>
        <Title type='h1' className={style.history__title}>
          <FormattedMessage id='titles.history' defaultMessage='History' />
        </Title>

        <div className={style.history__filters}>
          <InputDayPicker
            label={intl.formatMessage({
              id: 'milestones.form.start_date',
              defaultMessage: 'Start date'
            })}
            className={style.history__picker}
            placeholder={intl.formatMessage({
              id: 'input.dd/mm/yyyy',
              defaultMessage: 'dd/mm/yyyy'
            })}
            value={startDate}
            onChangeHandlerInput={setStartDate}
            onChangeHandlerPicker={setStartDate}
          />

          <InputDayPicker
            label={intl.formatMessage({
              id: 'milestones.form.end_date',
              defaultMessage: 'End date'
            })}
            className={style.history__picker}
            placeholder={intl.formatMessage({
              id: 'input.dd/mm/yyyy',
              defaultMessage: 'dd/mm/yyyy'
            })}
            value={endDate}
            onChangeHandlerInput={setEndDate}
            onChangeHandlerPicker={setEndDate}
            disabledDays={[
              {
                from: new Date(1970, 1, 1),
                to: subDays(parse(startDate, 'dd/MM/yyyy', new Date()), 1)
              }
            ]}
          />

          <HistoryModelSelect value={model} setModel={setModel} />
        </div>

        <ListContent
          isLoading={isLoading}
          error={error}
          hasData={data !== undefined && data?.count > 0}
          emptyListIcon='history'
          emptyListText={intl.formatMessage({
            id: 'history.empty',
            defaultMessage: 'Project history is empty'
          })}
        >
          {data !== undefined
            ? (
              <>
                <HistoryGroup data={data.results} />

                <div className={style.history__pagination}>
                  <PageSizeSelect
                    value={String(pageSize)}
                    handleChange={handleChangePageSize}
                  />

                  <Pagination
                    currentPage={page}
                    total={data.count}
                    pageSize={pageSize}
                    handleChange={setPage}
                  />
                </div>
              </>
              )
            : null}
        </ListContent>
      </Wrapper>
    </Container>
  )
}

export { History }
