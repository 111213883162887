import { makeAutoObservable } from 'mobx'

interface IParsedStorage {
  open?: boolean
  parsedStorage?: { [key: string]: any }
}

export class Sidebar {
  #localStorageKey: string
  open: boolean
  parsedStorage: { [key: string]: any }

  constructor (key: string) {
    this.#localStorageKey = `sidebar_${key}`

    if (typeof window !== 'undefined') {
      const parsedStorage = this.#getParsedStorage()
      this.parsedStorage = parsedStorage
      this.open = parsedStorage === null || parsedStorage?.open !== false
    } else {
      this.open = true
      this.parsedStorage = {}
    }
    makeAutoObservable(this)
  }

  #getParsedStorage = (): IParsedStorage => {
    const sidebarStorage = localStorage?.getItem(this.#localStorageKey)

    if (sidebarStorage === null) {
      return {}
    }

    return JSON.parse(sidebarStorage)
  }

  changeStorageValue = (key: string, value: any): void => {
    this.parsedStorage[key] = value

    const stringifiedStorage = JSON.stringify(this.parsedStorage)
    localStorage.setItem(this.#localStorageKey, stringifiedStorage)
  }

  toggle = (): void => {
    this.open = !this.open
    this.changeStorageValue('open', this.open)
  }
}
