import EmptyList from '@/components/EmptyList/EmptyList'
import StepItem from '@/components/StepItem'
import Title from '@/components/Title'
import Wrapper from '@/components/Wrapper'
import { IStep } from '@/interfaces/Case'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import style from '../styles/case_detail.module.scss'
import { TCaseType } from '@/interfaces/Types'

interface IProps {
  steps: IStep[]
  editUrl: string
  type: TCaseType
}

const Steps = ({ steps, editUrl, type }: IProps): React.ReactElement => {
  const intl = useIntl()
  return (
    <Wrapper className={style.steps}>
      <Title type='h2' className={style.steps__title}>
        <FormattedMessage id='titles.steps' defaultMessage='Steps' />
      </Title>

      {steps.length > 0
        ? (
          <div className={style.steps__list}>
            {steps.map((el) => {
              return (
                <StepItem
                  type={type}
                  key={el.id}
                  stepNumber={el.number}
                  description={el.description}
                  expectedResult={el.expected_result}
                />
              )
            })}
          </div>
          )
        : (
          <EmptyList
            icon='test_case'
            text={intl.formatMessage({
              id: 'case.empty.text',
              defaultMessage: 'The Test check doesn’t have steps'
            })}
            buttonText={intl.formatMessage({
              id: 'case.empty.button',
              defaultMessage: 'Add step'
            })}
            href={editUrl}
            className={style.steps__empty}
          />
          )}
    </Wrapper>
  )
}

export default Steps
