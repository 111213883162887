import Container from '@/components/Container'
import { Form } from '@/components/Form'
import { Footer, Head } from '@/components/Forms'
import BaseModal from '@/components/Modal/Base'
import { CASE_TYPES } from '@/const'
import { usePage, useStores } from '@/hooks'
import useGlobalConfig from '@/hooks/useGlobalConfig'
import useModal from '@/hooks/useModal'
import { makeAbbr, reactHookFormErrorFormater, urlSlug } from '@/utils'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useLocation, useNavigate } from 'react-router-dom'
import DeleteProject from './components/DeleteProject'
import ProjectForm from './components/ProjectForm'
import qs from 'qs'
import { IAddOrEditProjectProps, IFormInputs } from './interfaces'

const emptyProject: IFormInputs = {
  image: null,
  title: '',
  code: '',
  description: '',
  urls: [
    { value: '', system: 'jira' },
    { value: '', system: 'gitlab' },
    { value: '', system: 'confluence' }
  ],
  priority_case_type: CASE_TYPES.case
}

const AddOrEditProject = ({
  companyTitle,
  id,
  companyImage
}: IAddOrEditProjectProps): React.ReactElement => {
  const [isDeleteLoading, setIsDeleteLoading] = useState(false)
  const isUpdate = id !== undefined
  const navigate = useNavigate()
  const { api } = useStores()
  const location = useLocation()
  const page = usePage()
  const intl = useIntl()
  const { isOpen, handleOpenModal, handleCloseModal } = useModal()
  const config = useGlobalConfig()
  const qsParams = qs.parse(location.search, { ignoreQueryPrefix: true })
  const prevHref =
    qsParams.next !== undefined
      ? qsParams.next
      : page.init_state.global.dashboard_url

  const projectTitle: string | null =
    id !== null && id !== undefined && id !== 0 && !Number.isNaN(id)
      ? page.init_state.object.title
      : null

  const getDefaultValues = async (): Promise<IFormInputs> => {
    if (id !== undefined) {
      const project = await api.getProject({ id: id })

      return {
        ...project,
        members: undefined,
        priority_case_type:
          project?.priority_case_type !== undefined
            ? project.priority_case_type
            : CASE_TYPES.case
      }
    }
    return emptyProject
  }

  const companyId: number =
    id !== null && id !== undefined
      ? page.init_state.object.company.id
      : page.init_state.object.id

  const {
    register,
    watch,
    handleSubmit,
    control,
    setError,
    setValue,
    formState: { isSubmitting, errors, isLoading }
  } = useForm<IFormInputs>({
    defaultValues: id !== undefined ? getDefaultValues : emptyProject
  })

  const onSubmit = async (params): Promise<void> => {
    try {
      if (id === undefined) {
        params.company = companyId
        const res = await api.createProject(params)
        if (qsParams.next !== undefined) {
          window.location.href = qsParams.next as string
        } else {
          navigate(res.absolute_url)
        }
      } else {
        const { code } = await api.getProject({ id: id })
        const res = await api.updateProject(id, params)
        if (qsParams.next !== undefined && code === params.code) {
          window.location.href = qsParams.next as string
        } else {
          navigate(res.absolute_url)
        }
      }
    } catch (error) {
      const errors = reactHookFormErrorFormater(params, error, 'data')
      errors.forEach((item) => {
        setError(item.key as keyof IFormInputs, {
          message: item.value
        })
      })
      const errorImage = error?.response?.data?.image
      if (errorImage !== undefined) {
        setError('image', {
          message: errorImage
        })
      }
    }
  }

  const handleOpenDeleteModal = (): void => {
    handleOpenModal()
  }

  const handleDelete = async (): Promise<void> => {
    if (id === undefined) {
      return
    }
    setIsDeleteLoading(true)

    api
      .deleteProject(id)
      .then(() => {
        handleCloseModal()
        navigate(config.project_list_url)
      })
      .catch((err) => console.log('err', err))
      .finally(() => setIsDeleteLoading(false))
  }

  const titleForm = watch('title')
  const image = watch('image')

  useEffect(() => {
    if (titleForm !== undefined && !isUpdate) {
      setValue('code', urlSlug(makeAbbr(titleForm)).toUpperCase())
    }
  }, [titleForm])

  const addText = intl.formatMessage({
    id: 'project.add_project',
    defaultMessage: 'Add project'
  })

  const updateText = intl.formatMessage({
    id: 'project.update_project',
    defaultMessage: 'Add project'
  })

  const saveButtonText = intl.formatMessage({
    id: 'common.save',
    defaultMessage: 'Save'
  })

  const buttonText = isUpdate ? saveButtonText : addText
  const titleText = isUpdate ? updateText : addText

  const nonFieldErrors = errors?.non_field_errors?.message ?? ''

  return (
    <Container>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Head
          companyName={companyTitle}
          companyImage={companyImage}
          title={titleText}
          isSubmitting={isSubmitting}
          errors={nonFieldErrors}
          buttonText={buttonText}
          pastHref={prevHref as string}
          isDeletable={isUpdate}
          onDelete={handleOpenDeleteModal}
        />
        {!isLoading || id === undefined
          ? (
            <ProjectForm
              projectTitle={projectTitle}
              image={image}
              errors={errors}
              register={register}
              control={control}
            />
            )
          : null}

        {/* редактирование участников проекта - убрано пока не решится вопрос с подстановкой ролей для нового проекта */}
        {/* {id !== null && id !== undefined
          ? (
            <ProjectMembers
              mutateMembers={mutate}
              members={
              projectData?.members.map((member) => {
                return {
                  ...member,
                  _id: member.id,
                  id: member.user_id
                }
              }) ?? []
            }
              projectId={id}
              companyId={companyId}
            />
            )
          : (
            <div style={{ paddingBottom: '20px' }} />
            )} */}

        <Footer
          isSubmitting={isSubmitting}
          errors={nonFieldErrors}
          buttonText={buttonText}
        />
      </Form>

      {isUpdate
        ? (
          <BaseModal
            open={isOpen}
            onGx-after-hide={handleCloseModal}
            onGx-overlay-dismiss={handleCloseModal}
            hideDefaultClose
            size='medium'
          >
            <DeleteProject
              onCancel={handleCloseModal}
              onDelete={handleDelete}
              disableSubmit={isDeleteLoading}
            />
          </BaseModal>
          )
        : null}
    </Container>
  )
}

export default AddOrEditProject
