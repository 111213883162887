import React from 'react'
import style from './styles/button.module.scss'
import classNames from 'classnames'
import { GxButton } from '@garpix/garpix-web-components-react'
import { IButtonProps } from './interfaces'

const Button = ({
  children,
  size = 'med',
  theme = 'violet',
  className = '',
  wide = false,
  ...props
}: IButtonProps): React.ReactElement => {
  return (
    <GxButton
      className={classNames({
        [style.button]: true,
        [style.wide]: wide,
        [style[`button--${theme}`]]: theme,
        [style[`button--${size}`]]: size,
        [className]: Boolean(className)
      })}
      size={size}
      pill
      // href={href}
      {...props}
    >
      {children}
    </GxButton>
  )
}

export default Button
