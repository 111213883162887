import { TCurrentSuite } from '@/interfaces/Types'
import { makeAutoObservable } from 'mobx'

interface ISuiteData {
  nodeTitle: string
  id: TCurrentSuite
  nodeThree?: number[]
}

class Suite {
  currentSuite: ISuiteData = {
    nodeTitle: '',
    id: undefined,
    nodeThree: []
  }

  constructor () {
    makeAutoObservable(this)
  }

  setCurrentSuite (data: ISuiteData): void {
    this.currentSuite.id = data.id
    this.currentSuite.nodeTitle = data.nodeTitle
  }

  setNodeThree (data?: number[]): void {
    this.currentSuite.nodeThree = data
  }

  getCurrentSuite (): ISuiteData {
    return this.currentSuite
  }

  resetCurrentSuite (): void {
    this.currentSuite.nodeTitle = ''
    this.currentSuite.id = null
  }
}

export const suiteService = new Suite()
