import React from 'react'
import { regImage } from '@/images'
import AuthRegLayout from '../layout/components/AuthRegLayout'
import PasswordRecovery from '../PasswordRecovery'
import YandexMetrika from '@/components/YandexMetrika'

const PasswordRecoveryPage = (): React.ReactElement => {
  return (
    <>
      <YandexMetrika />
      <AuthRegLayout image={regImage}>
        <PasswordRecovery />
      </AuthRegLayout>
    </>
  )
}

export default PasswordRecoveryPage
