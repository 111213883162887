import BackButton from '@/components/Button/BackButton'
import Button from '@/components/Button/Button'
import { LinkButton } from '@/components/Button/LinkButton'
import HtmlContent from '@/components/HtmlContent/HtmlContent'
import Icon from '@/components/Icon/Icon'
import Priority from '@/components/Priority'
import { CaseStatus } from '@/components/Status'
import Title from '@/components/Title'
import Wrapper from '@/components/Wrapper'
import {
  CASE_STATUS,
  CASE_TYPES,
  CREATE_PERMISSION_LEVEL,
  EDIT_PERMISSION_LEVEL,
  USER_PERMISSIONS
} from '@/const'
import { useStores } from '@/hooks'
import { ICase } from '@/interfaces/Case'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import style from '../styles/case_detail.module.scss'
import { useNavigate } from 'react-router-dom'
import useSWR from 'swr'
import { TextTag } from '@/components/Tag'
import { useCurrentPermissions } from '@/hooks/useCurrentPermissions'

interface IProps {
  caseData: ICase
  editUrl: string
}

const Description = ({ caseData, editUrl }: IProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false)

  const { api } = useStores()

  const navigate = useNavigate()

  const { data: nextCaseData } = useSWR(
    { id: caseData.id, _key: 'getNextCase' },
    api.getNextCase
  )

  const canUserApproveCases = useCurrentPermissions(
    USER_PERMISSIONS.case_approve,
    EDIT_PERMISSION_LEVEL
  )
  const canUserEditCase = useCurrentPermissions(
    USER_PERMISSIONS.case,
    EDIT_PERMISSION_LEVEL
  )
  const canUserCloneCase = useCurrentPermissions(
    USER_PERMISSIONS.case,
    CREATE_PERMISSION_LEVEL
  )

  const linkToNextCase = nextCaseData?.url ?? null

  const handleApproveCase = async (): Promise<void> => {
    setIsLoading(true)
    await api.approveCase({
      id: caseData.id,
      data: {
        status: caseData.status,
        title: caseData.title,
        preconditions: caseData.preconditions,
        priority: caseData.priority,
        suite: caseData.suite ?? -1
      }
    })
    location.reload()
    setIsLoading(false)
  }

  const handleCloneCase = async (): Promise<void> => {
    setIsLoading(true)
    try {
      const res = await api.cloneCase(caseData.id)
      navigate(res.absolute_url)
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleBack = (): void => {
    navigate(-1)
  }

  const tags = caseData.tags

  const isApproveDisabled =
    (caseData.case_type === CASE_TYPES.case && caseData.steps.length === 0) ||
    isLoading

  return (
    <Wrapper className={style.description}>
      <div className={style.description__top}>
        <div className={style.description__titlebox}>
          <BackButton onClick={handleBack} className={style.head__back} />

          <div className={style.description__title}>
            <div className={style.description__code}>{caseData.code}</div>

            <Title type='h1' className={style.description__title}>
              {caseData.title}
            </Title>
          </div>

          {canUserEditCase
            ? (
              <>
                <LinkButton href={editUrl} theme='light' circle>
                  <Icon src='edit' />
                </LinkButton>
              </>
              )
            : null}

          {canUserCloneCase
            ? (
              <Button
                size='lg'
                theme='light'
                onClick={handleCloneCase}
                disabled={isLoading}
              >
                <Icon slot='icon-left' src='test_case' />

                <FormattedMessage id='common.clone' defaultMessage='Clone' />
              </Button>
              )
            : null}

          {linkToNextCase !== null
            ? (
              <LinkButton
                href={linkToNextCase}
                size='med'
                theme={
                caseData.status === CASE_STATUS.approved ? 'violet' : 'light'
              }
              >
                <FormattedMessage
                  id='button.next_test_case'
                  defaultMessage='Next test case'
                />

                <Icon slot='icon-left' src='arrow_right' />
              </LinkButton>
              )
            : null}

          {caseData.status === CASE_STATUS.draft && canUserApproveCases
            ? (
              <>
                <Button
                  size='lg'
                  onClick={handleApproveCase}
                  disabled={isApproveDisabled}
                >
                  <Icon slot='icon-left' src='checked' />

                  <FormattedMessage
                    id='button.approve'
                    defaultMessage='Approve'
                  />
                </Button>
              </>
              )
            : null}
        </div>
        {isApproveDisabled && canUserApproveCases
          ? (
            <p className={style.description__disabled_text}>
              <FormattedMessage
                id='case.not_approve'
                defaultMessage="You can't approve a case without steps"
              />
            </p>
            )
          : null}
      </div>

      <div className={style.description__content}>
        <div className={style.description__text}>
          <div className={style.description__grey}>
            <FormattedMessage
              id='case.form.preconditions'
              defaultMessage='Preconditions'
            />
          </div>

          <HtmlContent content={caseData.preconditions} />
        </div>

        <div className={style.description__buttons}>
          <CaseStatus status={caseData.status} size='small' />

          <div>
            <FormattedMessage
              id='case.form.priority'
              defaultMessage='Priority'
            />
            <Priority priority={caseData.priority} />
          </div>
        </div>
      </div>

      {tags?.length > 0
        ? (
          <div className={style.description__tags}>
            {tags?.map((el) => (
              <TextTag key={el.id} theme='hollow' size='small'>
                {el.title}
              </TextTag>
            ))}
          </div>
          )
        : null}
    </Wrapper>
  )
}

export default Description
