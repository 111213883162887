import React from 'react'
import UserLayout from '../layout/components/UserLayout'
import CompanyDetail from '../CompanyDetail'
import CompanyMembers from '../CompanyMembers'
import Container from '@/components/Container'

const CompanyMembersPage = (): React.ReactElement => {
  return (
    <UserLayout>
      <Container>
        <CompanyDetail />

        <CompanyMembers />
      </Container>
    </UserLayout>
  )
}

export default CompanyMembersPage
