import MenuItem from '@/components/MenuItem/MenuItem'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { StatusSelectProps } from '../interfaces/SelectProps'
import Select from '../Select'

const StatusSelect = ({
  handleChange,
  value,
  ...props
}: StatusSelectProps): React.ReactElement => {
  const intl = useIntl()

  return (
    <Select
      // className={style.milestones__filter}
      {...props}
      placeholder={intl.formatMessage({
        id: 'input.placeholder',
        defaultMessage: 'Select'
      })}
      label={intl.formatMessage({
        id: 'runs.status',
        defaultMessage: 'Status'
      })}
      value={value}
      onChange={handleChange}
      clearable
    >
      <MenuItem value='CREATED'>
        <FormattedMessage id='status.task.created' defaultMessage='Created' />
      </MenuItem>

      <MenuItem value='ASSIGNED'>
        <FormattedMessage id='status.task.assigned' defaultMessage='Assigned' />
      </MenuItem>

      <MenuItem value='INPROCESS'>
        <FormattedMessage
          id='status.task.inprocess'
          defaultMessage='In process'
        />
      </MenuItem>

      <MenuItem value='COMPLETED'>
        <FormattedMessage
          id='status.task.completed'
          defaultMessage='Completed'
        />
      </MenuItem>
    </Select>
  )
}

export default StatusSelect
