import React, { useState } from 'react'
import SelectCompanyHeader from '@/apps/Projects/SelectCompanyModal/SelectCompanyHead'
import style from '../styles/project.module.scss'
import BaseModal from '@/components/Modal/Base'
import SearchInput from '@/components/Input/SearchInput/SearchInput'
import useSWR from 'swr'
import { useStores } from '@/hooks'
import useDebounce from '@/hooks/useDebounce'
import { CompanySelectItem } from '@/apps/Projects/SelectCompanyModal/CompanySelectItem'
import { EmptySearch } from '@/components/EmptyResults'
import { ICompanyList } from '@/interfaces/Company'
import Icon from '@/components/Icon/Icon'
import { FormattedMessage } from 'react-intl'
import { LinkButton } from '@/components/Button/LinkButton'
import { observer } from 'mobx-react'
import classNames from 'classnames'

const SelectCompanyModal = ({
  open,
  close
}): React.ReactElement => {
  const { api, modalCompanyStore } = useStores()
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search)

  const { data, isLoading } = useSWR(
    {
      q: debouncedSearch,
      status: 'active',
      _key: 'getCompanies'
    },
    api.getCompanies
  )

  const companies = data?.results ?? []
  const [selectedCompany, setSelectedCompany] = useState< ICompanyList| null>(null)
  const [isCompanySelected, setIsCompanySelected] = useState(false)

  const handleCompanySelect = (company): void => {
    setSelectedCompany(company)
    setIsCompanySelected(true)
    modalCompanyStore.setCompany(company.id)
  }

  const handleModalClose = (): void => {
    setSelectedCompany(null)
    setIsCompanySelected(false)
    setSearch('')
    close()
  }

  // константа для сокращения названия компании в списке
  const maxCompanyItemTitleLength = 25

  // сокращаем название компании для корректного отображения в кнопке, если оно слишком длинное
  const maxCompanyTitleLength = 15
  const shortenedTitle =
    (selectedCompany != null) && selectedCompany.title !== ''
      ? selectedCompany.title.length > maxCompanyTitleLength
        ? selectedCompany.title.slice(0, maxCompanyTitleLength) + '...'
        : selectedCompany.title
      : ''

  return (
    <BaseModal
      hideDefaultClose
      open={open}
      side='right'
      size='small'
      handleClose={close}
      onGx-after-hide={handleModalClose}
      className={style.modal}
    >

      <SelectCompanyHeader handleCloseModal={close} />

      <SearchInput
        handleChange={setSearch}
        className={style.modal__input}
        value={search}
      />

      <form
        className={classNames(style.modal__companyList, {
          [style.modal____showaddbuttonmargin]: isCompanySelected
        })}
      >
        {companies.length > 0
          ? (
            <>
              {companies.map((company) => {
                return (
                  <CompanySelectItem
                    maxCompanyItemTitleLength={maxCompanyItemTitleLength}
                    key={company.id}
                    image={company.image}
                    title={company.title}
                    selected={selectedCompany?.id === company.id}
                    onSelect={() => handleCompanySelect(company)}
                  />
                )
              })}
            </>
            )
          : null}
      </form>

      {isCompanySelected && (selectedCompany != null)
        ? (
          <div className={style.modal__btncontainer}>
            <LinkButton
              href={selectedCompany.create_project_url}
              size='lg'
              className={style.modal__btncontainer__button}
            >
              <div className={style.modal__btncontainer__button__inner}>
                <Icon src='plus' />
                <FormattedMessage
                  id='common.add_to'
                  defaultMessage='Add to {value}'
                  values={{ value: shortenedTitle }}
                />
              </div>
            </LinkButton>
          </div>
          )
        : null}

      {!isLoading && search !== '' && companies.length === 0
        ? (
          <EmptySearch />
          )
        : null}

    </BaseModal>

  )
}
export default observer(SelectCompanyModal)
