import useCurrentUser from '@/hooks/useCurrentUser'
import React from 'react'
import UserDropdown from '../UserDropdown/UserDropdown'
import HeaderMenu from './HeaderMenu/HeaderMenu'
import style from './styles/header.module.scss'
import Notifications from '@/apps/Notifications'
import { Logo } from './Logo'
import useGlobalConfig from '@/hooks/useGlobalConfig'
import TechWorksInfo from './TechWorksInfo'

const Header = (): React.ReactElement => {
  const { currentUser } = useCurrentUser()
  const { show_maintenance_message: showMaintenanceMessage } =
    useGlobalConfig()

  return (
    <header className={style.header}>
      <Logo />

      {showMaintenanceMessage ? <TechWorksInfo /> : null}

      <HeaderMenu />
      {currentUser !== undefined
        ? (
          <div className={style.header__right}>
            <Notifications className={style.header__notification} />

            <UserDropdown user={currentUser} />
          </div>
          )
        : null}
    </header>
  )
}

export default Header
