import { GxDropdown } from '@garpix/garpix-web-components-react'
import React from 'react'
import cn from 'classnames'
import style from './styles/dropdown.module.scss'

const Dropdown = ({
  full = false,
  children,
  className = '',
  skidding = 5,
  hoist = true,
  ...props
}): React.ReactElement => {
  return (
    <GxDropdown
      hoist={hoist}
      className={cn({
        [style.dropdown]: true,
        [style.full]: full,
        [className]: Boolean(className)
      })}
      skidding={skidding}
      {...props}
    >
      {children}
    </GxDropdown>
  )
}

export default Dropdown
