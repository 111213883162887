import React from 'react'
import style from '../styles/history.module.scss'
import { format, parseISO } from 'date-fns'
import classNames from 'classnames'

const removeTags = (htmlString: string): string => {
  return htmlString.replace(/<\/?li>|<\/?ul>/g, '<ul></ul>')
}

const ReportingHistoryCard = ({
  name,
  time,
  content
}: {
  name: string
  time: string
  content: string
}): JSX.Element => {
  const formattedTime: string = format(parseISO(time), 'dd MMMM yyyy HH:mm')

  return (
    <div className={classNames([style.history__card])}>
      <div className={style.history__article}>
        <span className={style.history__card__name}>{name === null ? '' : name}</span>
        <div className={style.history__card__datetime}>{formattedTime}</div>
      </div>
      <hr className={classNames(style.history__card__line)} />
      <div
        className={style.history__card__content}
        dangerouslySetInnerHTML={{ __html: removeTags(content) }}
      />
    </div>
  )
}

export default ReportingHistoryCard
