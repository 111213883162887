import React, { forwardRef, ReactElement } from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, ChartData } from 'chart.js'
import { Pie } from 'react-chartjs-2'

ChartJS.register(ArcElement, Tooltip)

interface IProps {
  data: ChartData<'pie'>
  className?: string
}

interface IChartData {
  chartArea: {
    left: number
    top: number
    right: number
    bottom: number
  }
  ctx: any
}

const plugin = {
  id: 'emptyDoughnut',
  afterDraw (chart, args, options: {color: string, width: number, radiusDecrease: number}) {
    const { datasets } = chart.data
    const { color, width, radiusDecrease } = options
    let hasData = false

    for (let i = 0; i < datasets.length; i += 1) {
      const dataset = datasets[i]
      hasData = dataset.data.filter(i => i !== 0).length > 0
    }

    if (!hasData) {
      const { chartArea: { left, top, right, bottom }, ctx } = chart as IChartData
      const centerX = (left + right) / 2
      const centerY = (top + bottom) / 2
      const r = Math.min(right - left, bottom - top) / 2

      ctx.beginPath()
      ctx.lineWidth = width ?? 2
      ctx.strokeStyle = color ?? 'rgba(255, 128, 0, 0.5)'
      const radius = radiusDecrease ?? 0
      ctx.arc(centerX, centerY, (r - radius), 0, 2 * Math.PI)
      ctx.stroke()
    }
  }
}

const PieChartBase = ({ data, className = '' }: IProps, ref): ReactElement => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
      emptyDoughnut: {
        color: '#8394B7',
        width: 5,
        radiusDecrease: 20
      }
    }
  }
  return <Pie ref={ref} data={data} options={options} plugins={[plugin]} className={className} />
}

export const PieChart = forwardRef(PieChartBase)
