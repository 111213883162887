import Icon from '@/components/Icon/Icon'
import Wrapper from '@/components/Wrapper'
import React from 'react'
import { UseFormRegister, UseFormSetValue } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { useUser } from '@garpix/cms'
import style from '../style/profile.module.scss'
import PhotoInput from '@/components/Input/PhotoInput'

interface IProps {
  register: UseFormRegister<any>
  setValue: UseFormSetValue<any>
  errors: any
  name?: string
  avatar?: string | null
}

const BlockChangeAvatar = ({
  register,
  setValue,
  errors,
  name,
  avatar
}: IProps): React.ReactElement => {
  const { handleLogoutRedirect } = useUser()

  const handleLogout = (): void => {
    void handleLogoutRedirect()
  }

  const handleAddAvatar = (): void => {
    setValue('delete_avatar', false)
  }

  const handleDeleteAvatar = (): void => {
    setValue('delete_avatar', true)
  }

  return (
    <Wrapper className={style.avatar}>
      <div className={style.avatar__block}>
        <PhotoInput
          {...register('avatar')}
          image={avatar}
          variant='avatar'
          error={errors?.image?.message ?? ''}
          deletable
          onDelete={handleDeleteAvatar}
          handleAddAvatar={handleAddAvatar}
        />
      </div>

      <div className={style.avatar__name}>{name}</div>

      <button
        type='button'
        className={style.avatar__button}
        onClick={handleLogout}
      >
        <Icon src='logout' />

        <span>
          <FormattedMessage id='profile.logout' defaultMessage='Log out' />
        </span>
      </button>

    </Wrapper>
  )
}

export default BlockChangeAvatar
