import Icon from '@/components/Icon/Icon'
import { SidebarNavLink } from '@/components/NavLinkButton'
import classNames from 'classnames'
import React from 'react'
import style from './styles/projectmenu.module.scss'

interface IProps {
  isBig: boolean
  name: string
  href: string
  icon: string
}

const ProjectMenuLink = ({
  isBig,
  name,
  href,
  icon
}: IProps): React.ReactElement => {
  return (
    <SidebarNavLink
      key={name}
      link={href}
      className={!isBig ? style['link--small'] : undefined}
    >
      <Icon src={icon} size='small' />
      <span
        className={classNames({
          [style.link__text]: true,
          [style['link__text--hidden']]: !isBig
        })}
      >
        {name}
      </span>
    </SidebarNavLink>
  )
}

export default ProjectMenuLink
