import { GxCheckbox } from '@garpix/garpix-web-components-react'
import classNames from 'classnames'
import React from 'react'
import Icon from '../Icon/Icon'
import style from './styles/checkbox.module.scss'

interface IProps extends React.PropsWithChildren<any> {
  children?: React.ReactNode | string
  className?: string
  error?: boolean
  isIndeterminate?: boolean
  checked?: boolean
  size?: 'small' | 'medium' | 'large'
}

const Checkbox = ({
  children = '',
  className = '',
  error = false,
  checked = false,
  isIndeterminate = false,
  size = 'large',
  ...props
}: IProps): React.ReactElement => {
  const icon = {
    checked: 'checked',
    isIndeterminate: 'minus'
  }
  return (
    <GxCheckbox
      checked={checked || isIndeterminate}
      className={classNames({
        [style.checkbox]: true,
        [style.error]: Boolean(error),
        [style.is_indeterminate]: Boolean(isIndeterminate),
        [style[`checkbox--${size}`]]: true,
        [className]: Boolean(className)
      })}
      {...props}
    >
      <Icon
        slot='checked-icon'
        src={isIndeterminate ? icon.isIndeterminate : icon.checked}
      />
      {children}
    </GxCheckbox>
  )
}

export default Checkbox
