import useSWR from 'swr'
import { useStores } from './useStores'

export const useFile = ({ url }: { url: string | undefined }): { href: string | undefined } => {
  const { api } = useStores()
  const { data } = useSWR(
    { fullUrl: url, _key: 'getFile' },
    api.getFile,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateOnMount: true,
      dedupingInterval: 3600000,
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // Never retry on 404.
        if (error.status === 404) {
          return undefined
        }
      }
    }
  )

  return {
    href: data
  }
}
