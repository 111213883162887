import React from 'react'
import { Control, Controller } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import style from './styles/radio_group.module.scss'
import { Radio } from '../Radio'

interface RadioOption {
  value: string
  labelId: string
  defaultMessage: string
}

interface RadioGroupProps {
  control: Control<any, any>
  name: string
  options: RadioOption[]
}

const RadioGroup: React.FC<RadioGroupProps> = ({ control, name, options }) => {
  return (
    <div className={style.radiogroup}>
      {options.map(({ value, labelId, defaultMessage }) => (
        <Controller
          key={value}
          control={control}
          defaultValue={value}
          name={name}
          render={({ field: { onChange, value: radioValue } }) => (
            <Radio
              value={value}
              name={name}
              onGx-change={(e) => onChange(e.target.value)}
              checked={radioValue === value}
            >
              <FormattedMessage id={labelId} defaultMessage={defaultMessage} />
            </Radio>
          )}
        />
      ))}
    </div>
  )
}

export default RadioGroup
