import React from 'react'
import classNames from 'classnames'
import style from './styles/html_content.module.scss'
import DOMPurify from 'isomorphic-dompurify'
interface Content {
  content: string
  className: string
}

const HtmlContent = ({ content = '', className = '' }): React.ReactElement<Content> => {
  const clean = DOMPurify.sanitize(content, {
    ALLOWED_TAGS: [
      'a',
      'b',
      'blockquote',
      'br',
      'del',
      'div',
      'em',
      'figcaption',
      'figure',
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'hr',
      'i',
      'img',
      'li',
      'mark',
      'ol',
      'p',
      'section',
      'span',
      'strong',
      'sub',
      'sup',
      'table',
      'tbody',
      'th',
      'tr',
      'td',
      'u',
      'ul'
    ],
    ALLOWED_ATTR: ['style', 'href', 'rel', 'src', 'align', 'alt', 'width', 'height', 'class', 'colspan', 'rowspan']
  })

  const classes = classNames({
    [style.html_content]: true,
    [className]: Boolean(className)
  })

  return (
    <div className={classes} dangerouslySetInnerHTML={{ __html: clean }} />
  )
}

export default HtmlContent
