import { TTargetValue } from '@/interfaces/Types'
import classNames from 'classnames'
import React from 'react'
import { Link as LinkReact } from 'react-router-dom'
import style from './styles/link.module.scss'

interface IProps {
  className?: string
  children: React.ReactNode
  to: string
  onClick?: () => void
  target?: TTargetValue
}

const Link = ({
  to,
  children,
  target = undefined,
  onClick,
  className = ''
}: React.PropsWithChildren<IProps>): React.ReactElement => {
  if (target !== undefined) {
    return (
      <a
        href={to}
        target={target}
        onClick={onClick}
        rel='noreferrer'
        className={classNames({
          [style.link]: true,
          [className]: Boolean(className)
        })}
      >
        {children}
      </a>
    )
  }
  return (
    <LinkReact
      to={to}
      onClick={onClick}
      className={classNames({
        [style.link]: true,
        [className]: Boolean(className)
      })}
    >
      {children}
    </LinkReact>
  )
}

export default Link
