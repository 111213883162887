import { daysPeriodStart } from '@/utils'
import { formatISO, lastDayOfMonth, startOfMonth } from 'date-fns'
import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import MenuItem from '../MenuItem/MenuItem'
import { PeriodSelectProps } from './interfaces/SelectProps'
import Select from './Select'

const currentDate = new Date().toISOString().slice(0, 10)

const getPreviousMonthFirstDay = (): Date => {
  const date = new Date()
  date.setDate(1)
  date.setMonth(date.getMonth() - 1)
  return date
}

const values = {
  days30: {
    start: daysPeriodStart(30),
    end: currentDate
  },
  days7: {
    start: daysPeriodStart(7),
    end: currentDate
  },
  days14: {
    start: daysPeriodStart(14),
    end: currentDate
  },
  thisMonth: {
    start: formatISO(startOfMonth(Date.now()), { representation: 'date' }),
    end: currentDate
  },
  previousMonth: {
    start: formatISO(getPreviousMonthFirstDay(), { representation: 'date' }),
    end: formatISO(lastDayOfMonth(getPreviousMonthFirstDay()), {
      representation: 'date'
    })
  }
}

const defaultValue = 'days14'

const PeriodSelect = ({
  handleChange,
  className = '',
  ...props
}: PeriodSelectProps): React.ReactElement => {
  const intl = useIntl()
  const [value, setValue] = useState(defaultValue)

  const onChange = (e): void => {
    setValue(e.target.value)
    handleChange(values[e.target.value])
  }

  return (
    <Select
      {...props}
      placeholder={intl.formatMessage({
        id: 'input.placeholder',
        defaultMessage: 'Choose'
      })}
      label={intl.formatMessage({
        id: 'input.period',
        defaultMessage: 'For the period'
      })}
      className={className}
      value={value}
      onChange={onChange}
    >
      <MenuItem value='days30'>
        <FormattedMessage id='input.periods.30_days' defaultMessage='30 days' />
      </MenuItem>
      <MenuItem value={defaultValue}>
        <FormattedMessage id='input.periods.14_days' defaultMessage='14 days' />
      </MenuItem>
      <MenuItem value='days7'>
        <FormattedMessage id='input.periods.7_days' defaultMessage='7 days' />
      </MenuItem>
      <MenuItem value='thisMonth'>
        <FormattedMessage
          id='input.periods.this_month'
          defaultMessage='This month'
        />
      </MenuItem>
      <MenuItem value='previousMonth'>
        <FormattedMessage
          id='input.periods.previous_month'
          defaultMessage='This month'
        />
      </MenuItem>
    </Select>
  )
}

export default PeriodSelect
