import { NoSSR } from '@garpix/cms'
import React, { ReactElement } from 'react'
import ReactDOM from 'react-dom'

export const PortalBase = ({ children }): any => {
  return ReactDOM.createPortal(
    children,
    document.body
  )
}

export const Portal = ({ children }): ReactElement => {
  return (
    <NoSSR>
      <PortalBase>
        {children}
      </PortalBase>
    </NoSSR>
  )
}
