import React from 'react'
import style from './styles/loading.module.scss'
import classNames from 'classnames'

interface IProps {
  size?: 'large' | 'medium' | 'small' | 'xs' | 'xxs'
  className?: string
}

const Loading = ({
  size = 'large',
  className = ''
}: IProps): React.ReactElement => {
  return (
    <div
      className={classNames({
        [style.loading]: true,
        [className]: Boolean(className)
      })}
    >
      <svg
        width='200px'
        height='200px'
        viewBox='0 0 100 100'
        preserveAspectRatio='xMidYMid'
        className={style[`image--${size}`]}
      >
        <g transform='translate(50 50)'>
          <g transform='translate(-19 -19) scale(0.6)'>
            <g>
              <animateTransform
                attributeName='transform'
                type='rotate'
                values='0;45'
                keyTimes='0;1'
                dur='0.4347826086956522s'
                begin='0s'
                repeatCount='indefinite'
              />
              <path
                d='M32.79754809959447 22.89805316298281 L39.86861591145995 29.969120974848284 L29.969120974848284 39.86861591145995 L22.89805316298281 32.79754809959447 A40 40 0 0 1 7.000000000000006 39.382737335030434 L7.000000000000006 39.382737335030434 L7.000000000000007 49.382737335030434 L-7.000000000000001 49.382737335030434 L-7.000000000000002 39.382737335030434 A40 40 0 0 1 -22.898053162982805 32.79754809959448 L-22.898053162982805 32.79754809959448 L-29.96912097484828 39.868615911459955 L-39.86861591145995 29.969120974848284 L-32.79754809959447 22.89805316298281 A40 40 0 0 1 -39.382737335030434 7.000000000000009 L-39.382737335030434 7.000000000000009 L-49.382737335030434 7.00000000000001 L-49.382737335030434 -6.999999999999999 L-39.382737335030434 -7 A40 40 0 0 1 -32.79754809959448 -22.898053162982798 L-32.79754809959448 -22.898053162982798 L-39.868615911459955 -29.96912097484827 L-29.969120974848305 -39.86861591145994 L-22.898053162982826 -32.797548099594465 A40 40 0 0 1 -6.999999999999994 -39.382737335030434 L-6.999999999999994 -39.382737335030434 L-6.999999999999996 -49.382737335030434 L6.999999999999978 -49.38273733503044 L6.99999999999998 -39.38273733503044 A40 40 0 0 1 22.898053162982812 -32.79754809959447 L22.898053162982812 -32.79754809959447 L29.969120974848288 -39.86861591145995 L39.86861591145993 -29.969120974848302 L32.79754809959446 -22.898053162982826 A40 40 0 0 1 39.382737335030434 -6.9999999999999964 L39.382737335030434 -6.9999999999999964 L49.382737335030434 -6.999999999999999 L49.38273733503044 6.999999999999974 L39.38273733503044 6.999999999999977 A40 40 0 0 1 32.79754809959447 22.898053162982812 M0 -23A23 23 0 1 0 0 23 A23 23 0 1 0 0 -23'
                fill='#6154f5'
              />
            </g>
          </g>
          <g transform='translate(19 19) scale(0.6)'>
            <g>
              <animateTransform
                attributeName='transform'
                type='rotate'
                values='45;0'
                keyTimes='0;1'
                dur='0.4347826086956522s'
                begin='-0.2173913043478261s'
                repeatCount='indefinite'
              />
              <path
                d='M-32.79754809959448 -22.898053162982798 L-39.868615911459955 -29.96912097484827 L-29.969120974848305 -39.86861591145994 L-22.898053162982826 -32.797548099594465 A40 40 0 0 1 -6.999999999999994 -39.382737335030434 L-6.999999999999994 -39.382737335030434 L-6.999999999999996 -49.382737335030434 L6.999999999999978 -49.38273733503044 L6.99999999999998 -39.38273733503044 A40 40 0 0 1 22.898053162982812 -32.79754809959447 L22.898053162982812 -32.79754809959447 L29.969120974848288 -39.86861591145995 L39.86861591145993 -29.969120974848302 L32.79754809959446 -22.898053162982826 A40 40 0 0 1 39.382737335030434 -6.9999999999999964 L39.382737335030434 -6.9999999999999964 L49.382737335030434 -6.999999999999999 L49.38273733503044 6.999999999999974 L39.38273733503044 6.999999999999977 A40 40 0 0 1 32.79754809959447 22.898053162982812 L32.79754809959447 22.898053162982812 L39.86861591145995 29.969120974848288 L29.96912097484831 39.86861591145993 L22.89805316298283 32.79754809959446 A40 40 0 0 1 6.999999999999998 39.382737335030434 L6.999999999999998 39.382737335030434 L7.000000000000001 49.382737335030434 L-6.9999999999999725 49.38273733503044 L-6.999999999999975 39.38273733503044 A40 40 0 0 1 -22.898053162982777 32.79754809959449 L-22.898053162982777 32.79754809959449 L-29.969120974848245 39.868615911459976 L-39.868615911459905 29.969120974848344 L-32.79754809959444 22.898053162982862 A40 40 0 0 1 -39.382737335030434 7.000000000000001 L-39.382737335030434 7.000000000000001 L-49.382737335030434 7.000000000000004 L-49.38273733503044 -6.999999999999968 L-39.38273733503044 -6.999999999999972 A40 40 0 0 1 -32.79754809959445 -22.898053162982833 M0 -23A23 23 0 1 0 0 23 A23 23 0 1 0 0 -23'
                fill='#d8d8f9'
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
  )
}

export default Loading
