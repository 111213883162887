import Wrapper from '@/components/Wrapper'
import style from '../style/profile.module.scss'
import React, { useEffect, useState } from 'react'
import Title from '@/components/Title'
import { FormattedMessage, useIntl } from 'react-intl'
import { Control, UseFormRegister } from 'react-hook-form'
import Input from '@/components/Input/Input'
import Icon from '@/components/Icon/Icon'
import TextButton from '@/components/Button/TextButton'
import Button from '@/components/Button/Button'
import BaseModal from '@/components/Modal/Base'
import useModal from '@/hooks/useModal'
import ChangePassword from './ChangePassword'
import ChangePhone from './ChangePhone'
import qs from 'qs'
import SuccessUpdateUser from './SuccessUpdateUser'
import ChangeEmail from './ChangeEmail'
import classNames from 'classnames'
import { LanguageSwitch } from '@/components/Switch'

interface IProps {
  control: Control<any, any>
  register: UseFormRegister<any>
  errors: any
  username: string
  email: string
  phone?: string
  isSubmitting: boolean
}

type TContent = 'email' | 'password' | 'phone' | 'success'
interface IConstContent {
  email: TContent
  password: TContent
  phone: TContent
  success: TContent
}

const Information = ({
  register,
  errors,
  username,
  email,
  phone,
  isSubmitting
}: IProps): React.ReactElement => {
  const intl = useIntl()
  const qsParams = qs.parse(location.search, { ignoreQueryPrefix: true })
  const CONTENT: IConstContent = {
    email: 'email',
    password: 'password',
    phone: 'phone',
    success: 'success'
  }

  const [currentContent, setCurrentContent] = useState<TContent | undefined>()

  const openModalSuccess = Boolean(
    qsParams?.modal !== undefined && qsParams.modal === CONTENT.success
  )

  const {
    isOpen,
    handleOpenModal: handleOpen,
    handleCloseModal: handleClose
  } = useModal()

  const handleCloseModal = (): void => {
    if (openModalSuccess) {
      window.history.pushState({}, document.title, location.pathname)
    }
    setCurrentContent(undefined)
    handleClose()
  }

  const handleOpenChangeMail = (): void => {
    setCurrentContent(CONTENT.email)
    handleOpen()
  }

  const handleOpenChangePass = (): void => {
    setCurrentContent(CONTENT.password)
    handleOpen()
  }

  const handleOpenChangePhone = (): void => {
    setCurrentContent(CONTENT.phone)
    handleOpen()
  }

  useEffect(() => {
    if (openModalSuccess) {
      handleOpen()
      setCurrentContent(CONTENT.success)
    }
  }, [])

  return (
    <Wrapper className={style.information}>
      <div className={style.information__top}>
        <Title type='h1'>
          <FormattedMessage
            id='profile.title'
            defaultMessage='Profile information'
          />
        </Title>
        <Button type='submit' disabled={isSubmitting}>
          <FormattedMessage id='common.save' defaultMessage='Save' />
        </Button>
      </div>

      <div className={classNames(style.information__base, style.information__fullname)}>
        <Input
          className={style.information__input}
          label={intl.formatMessage({
            id: 'profile.first_name',
            defaultMessage: 'First Name'
          })}
          required
          {...register('first_name', { required: true })}
          error={errors?.first_name?.message ?? ''}
        />

        <Input
          className={style.information__input}
          label={intl.formatMessage({
            id: 'profile.last_name',
            defaultMessage: 'Last Name'
          })}
          required
          {...register('last_name', { required: true })}
          error={errors?.last_name?.message ?? ''}
        />
      </div>

      <div className={style.information__base}>
        <div className={style.information__item}>
          <Icon src='mail' className={style.information__icon} />

          <div className={style.information__noicon}>
            <div className={style.information__code}>
              <FormattedMessage id='profile.email' defaultMessage='Email' />
            </div>

            {email !== ''
              ? (
                <div className={style.information__text}>
                  {email}

                  <TextButton
                    className={style.information__edit}
                    onClick={handleOpenChangeMail}
                  >
                    <Icon src='edit' />
                  </TextButton>
                </div>
                )
              : (
                <TextButton onClick={handleOpenChangeMail}>
                  <FormattedMessage
                    id='profile.add_email'
                    defaultMessage='Add email'
                  />
                </TextButton>
                )}
          </div>
        </div>

        <div className={style.information__item}>
          <Icon src='phone' className={style.information__icon} />

          <div className={style.information__noicon}>
            <div className={style.information__code}>
              <FormattedMessage id='profile.phone' defaultMessage='Phone' />
            </div>

            {phone !== ''
              ? (
                <div className={style.information__text}>
                  {phone}

                  <TextButton
                    className={style.information__edit}
                    onClick={handleOpenChangePhone}
                  >
                    <Icon src='edit' />
                  </TextButton>
                </div>
                )
              : (
                <TextButton onClick={handleOpenChangePhone}>
                  <FormattedMessage
                    id='profile.add_phone'
                    defaultMessage='Add phone number'
                  />
                </TextButton>
                )}
          </div>
        </div>

        <div className={style.information__item}>
          <Icon src='key' className={style.information__icon} />

          <div className={style.information__noicon}>
            <div className={style.information__code}>
              <FormattedMessage
                id='profile.password'
                defaultMessage='Password'
              />
            </div>

            <div className={style.information__text}>
              <TextButton onClick={handleOpenChangePass}>
                <FormattedMessage
                  id='profile.change_password'
                  defaultMessage='Change password'
                />
              </TextButton>
            </div>
          </div>
        </div>
      </div>

      <Title type='h2' className={style.information__links}>
        <Icon src='clip' />

        <FormattedMessage id='profile.links' defaultMessage='Links' />
      </Title>

      <label className={style.information__label}>
        <span className={style.information__text}>
          <Icon src='telegram' />

          <FormattedMessage
            id='profile.telegram'
            defaultMessage='Telegram URL'
          />
        </span>

        <Input
          className={style.information__link}
          {...register('telegram_url', { required: false })}
          error={errors?.telegram_url?.message ?? ''}
        />
      </label>

      <label className={style.information__label}>
        <span className={style.information__text}>
          <Icon src='gitlab' />

          <FormattedMessage id='profile.gitlab' defaultMessage='GitLab URL' />
        </span>

        <Input
          className={style.information__link}
          {...register('gitlab_url', { required: false })}
          error={errors?.gitlab_url?.message ?? ''}
        />
      </label>

      <span className={style.information__switcher}>
        <FormattedMessage id='profile.language' defaultMessage='Language' />

        <LanguageSwitch />
      </span>

      <BaseModal
        open={isOpen}
        onGx-after-hide={handleCloseModal}
        onGx-overlay-dismiss={handleCloseModal}
        hideDefaultClose
        size='medium'
        className={style.modal}
      >
        {currentContent === CONTENT.email
          ? (
            <ChangeEmail onClose={handleCloseModal} />
            )
          : null}

        {currentContent === CONTENT.password
          ? (
            <ChangePassword
              onClose={handleCloseModal}
              username={username}
              email={email}
            />
            )
          : null}

        {currentContent === CONTENT.phone
          ? (
            <ChangePhone onClose={handleCloseModal} />
            )
          : null}

        {currentContent === CONTENT.success
          ? (
            <SuccessUpdateUser onClose={handleCloseModal} />
            )
          : null}
      </BaseModal>
    </Wrapper>
  )
}

export default Information
