import React from 'react'
import { Layout } from '.'
import Header from './Header/Header'
import style from '../styles/layout.module.scss'

interface LayoutProps {
  children: React.ReactNode
}

const CommonLayout = ({ children }: LayoutProps): React.ReactElement => {
  return (
    <Layout>
      <Header />
      <main className={style.common__main}>{children}</main>
    </Layout>
  )
}

export default CommonLayout
