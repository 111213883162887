import React, { useState } from 'react'
import useSWR from 'swr'
import { useObjectPage, usePage, useStores } from '@/hooks'
import { debounce, formatDateForBackend, scrollTop } from '@/utils'
import Container from '@/components/Container'
import Wrapper from '@/components/Wrapper'
import Title from '@/components/Title'
import { Form } from '@/components/Form'
import Icon from '@/components/Icon/Icon'
import { FormattedMessage, useIntl } from 'react-intl'
import Select from '@/components/Select/Select'
import MenuItem from '@/components/MenuItem/MenuItem'
import InputDayPicker from '@/components/InputDayPicker/InputDayPicker'
import MilestoneList from './MilestoneList/MilestoneList'
import { format, isValid, parse } from 'date-fns'
import ListContent from '@/components/ListContent'
import { LinkButton } from '@/components/Button/LinkButton'
import SearchInput from '@/components/Input/SearchInput/SearchInput'
import useDebounce from '@/hooks/useDebounce'
import { useCurrentPermissions } from '@/hooks/useCurrentPermissions'
import { PERMISSION_LEVEL, USER_PERMISSIONS } from '@/const'
import style from './styles/milestones.module.scss'

const Milestones = (): React.ReactElement => {
  const { api } = useStores()
  const obj = usePage()
  const objectPage = useObjectPage()
  const sidebar = obj.init_state?.sidebar
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search)
  const [page, setPage] = useState(1)
  const [completed, setCompleted] = useState('any')
  const [date, setDate] = useState<string | undefined>(undefined)
  const [dateBefore, setDateBefore] = useState<string | undefined>(undefined)

  const canCreateMilestone = useCurrentPermissions(USER_PERMISSIONS.milestones, [PERMISSION_LEVEL.full])
  const intl = useIntl()

  const textButtonEmptyList = canCreateMilestone
    ? intl.formatMessage({
      id: 'milestones.add_milestone',
      defaultMessage: 'Add milestone'
    })
    : undefined

  const pageSize = 10
  const parser = (date?: string): void => {
    if (date === undefined) {
      handleDate(undefined)
    } else {
      const parseDate = parse(date, 'dd/MM/yyyy', new Date())
      if (isValid(parseDate)) {
        handleDate(format(parse(date, 'dd/MM/yyyy', new Date()), 'dd/MM/yyyy'))
      }
    }
  }
  const parserBefore = (date?: string): void => {
    if (date === undefined) {
      handleDateBefore(undefined)
    } else {
      const parseDate = parse(date, 'dd/MM/yyyy', new Date())
      if (isValid(parseDate)) {
        handleDateBefore(
          format(parse(date, 'dd/MM/yyyy', new Date()), 'dd/MM/yyyy')
        )
      }
    }
  }
  const handleChangeDate = debounce(parser, 1000)
  const handleChangeDateBefore = debounce(parser, 1000)

  const { data, error, isLoading } = useSWR(
    {
      page: page,
      page_size: pageSize,
      q: debouncedSearch,
      end_date_after:
        date !== undefined ? formatDateForBackend(date) : undefined,
      end_date_before:
        dateBefore !== undefined ? formatDateForBackend(dateBefore) : undefined,
      completed: completed,
      project: objectPage.id,
      _key: 'getMilestones'
    },
    api.getMilestones,
    {
      onSuccess: () => {
        scrollTop()
      }
    }
  )

  const handleSearch = (value: string): void => {
    setSearch(value)
    setPage(1)
  }

  const handleCompleted = (value: string): void => {
    setCompleted(value)
    setPage(1)
  }

  const handleDate = (value: string | undefined): void => {
    setDate(value)
    setPage(1)
  }

  const handleDateBefore = (value: string | undefined): void => {
    setDateBefore(value)
    setPage(1)
  }
  const urlMilestoneCreate =
    sidebar?.ProjectPageMilestonesCreate?.absolute_url ?? ''

  return (
    <Container>
      <Wrapper className={style.milestones}>
        <div className={style.milestones__top}>
          <Title type='h1' className={style.milestones__title}>
            <FormattedMessage
              id='titles.milestones'
              defaultMessage='Milestones'
            />
          </Title>

          {canCreateMilestone
            ? (
              <LinkButton
                href={urlMilestoneCreate}
                className={style.milestones__addproject}
              >
                <Icon src='plus' slot='icon-left' />

                <FormattedMessage
                  id='milestones.add_milestone'
                  defaultMessage='Add milestone'
                />
              </LinkButton>
              )
            : null}
        </div>
        <Form onSubmit={undefined}>
          <div className={style.milestones__form}>
            <InputDayPicker
              label={intl.formatMessage({
                id: 'input.from',
                defaultMessage: 'From'
              })}
              placeholder={intl.formatMessage({
                id: 'input.dd/mm/yyyy',
                defaultMessage: 'dd/mm/yyyy'
              })}
              value={date}
              onChangeHandlerInput={handleChangeDate}
              onChangeHandlerPicker={parser}
            />

            <InputDayPicker
              label={intl.formatMessage({
                id: 'input.before',
                defaultMessage: 'Before'
              })}
              placeholder={intl.formatMessage({
                id: 'input.dd/mm/yyyy',
                defaultMessage: 'dd/mm/yyyy'
              })}
              value={dateBefore}
              onChangeHandlerInput={handleChangeDateBefore}
              onChangeHandlerPicker={parserBefore}
            />

            <Select
              placeholder={intl.formatMessage({
                id: 'input.placeholder',
                defaultMessage: 'Select'
              })}
              label={intl.formatMessage({
                id: 'input.completed',
                defaultMessage: 'Сompleted'
              })}
              onChange={(e) => handleCompleted(e.target.value)}
            >
              <MenuItem value='not_started'>
                <FormattedMessage
                  id='input.not_started'
                  defaultMessage='Not started'
                />
              </MenuItem>

              <MenuItem value='finish'>
                <FormattedMessage id='input.finish' defaultMessage='Finish' />
              </MenuItem>

              <MenuItem value='any'>
                <FormattedMessage id='input.any' defaultMessage='Any' />
              </MenuItem>
            </Select>

            <SearchInput
              handleChange={handleSearch}
              value={search}
              placeholder={intl.formatMessage({
                id: 'milestones.search',
                defaultMessage: 'Search milestones'
              })}
              className={style.milestones__filter}
            />
          </div>
        </Form>

        <ListContent
          isLoading={isLoading}
          error={error}
          hasData={data !== undefined && data?.count > 0}
          emptyListIcon='flag'
          emptyListText={intl.formatMessage({
            id: 'milestones.no_milestones',
            defaultMessage: 'No milestones has been found'
          })}
          emptyListButton={textButtonEmptyList}
          href={urlMilestoneCreate}
        >
          {data !== undefined
            ? (
              <MilestoneList
                data={data}
                currentPage={page}
                pageSize={pageSize}
                setPage={setPage}
              />
              )
            : null}
        </ListContent>
      </Wrapper>
    </Container>
  )
}

export default Milestones
