import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import Textarea from '@/components/Textarea'
import Button from '@/components/Button/Button'
import { useProjectObject, useStores } from '@/hooks'
import style from '../styles/add_case.module.scss'
import { AISpiner } from './AISpiner'
import { TCaseType } from '@/interfaces/Types'
import { IAISuccessProps, IAITestCase } from '../interfaces'

interface IProps {
  handleSuccess: (data: IAISuccessProps) => void
  caseType: TCaseType
}

export const AICases = ({
  handleSuccess,
  caseType
}: IProps): React.ReactElement => {
  const [isShow, setShow] = useState(false)
  const store = useStores()
  const intl = useIntl()
  const { id: projectId } = useProjectObject()
  const [text, setText] = useState('')
  const [error, setError] = useState('')
  const [isSubmitted, setSubmitted] = useState(false)

  const handleSubmit = async (e): Promise<void> => {
    e.preventDefault()
    setError('')
    setSubmitted(true)
    try {
      const res: IAITestCase = await store.api.generateOpenaiCase({
        project: projectId,
        message: text,
        case_type: caseType
      })
      res.steps.map((el) => (el._id = Date.now() + el.number))
      handleSuccess(res)
    } catch (error) {
      const { data } = error.response
      setError(data?.non_field_errors?.[0] ?? '')
    } finally {
      setSubmitted(false)
    }
  }

  const handleShow = (): void => setShow(true)

  return (
    <div className={style.ai__block}>
      {isSubmitted ? <AISpiner /> : null}
      {isShow
        ? (
          <div>
            <Textarea
              disabled={isSubmitted}
              className={style.ai__textarea}
              name='message'
              label={intl.formatMessage({
                id: 'cases.ai.enter.request',
                defaultMessage: 'Enter a request'
              })}
              value={text}
              onChange={(e) => setText(e.target.value)}
              error={error}
            />
            <Button
              disabled={isSubmitted || text.trim() === ''}
              type='button'
              onClick={handleSubmit}
            >
              {intl.formatMessage({
                id: 'common.send',
                defaultMessage: 'Send'
              })}
            </Button>
          </div>
          )
        : (
          <Button
            onClick={handleShow}
            theme='light'
            size='sm'
            full
            disabled={isSubmitted}
            type='button'
          >
            {intl.formatMessage({
              id: 'cases.ai.enable.magic',
              defaultMessage: 'Enable AI magic'
            })}
          </Button>
          )}
    </div>
  )
}
