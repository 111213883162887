import Input from '@/components/Input/Input'
import MenuItem from '@/components/MenuItem/MenuItem'
import MilestoneSelect from '@/components/Select/MilestoneSelect/MilestoneSelect'
import Select from '@/components/Select/Select'
import Textarea from '@/components/Textarea'
import Title from '@/components/Title'
import Wrapper from '@/components/Wrapper'
import { errorFolder } from '@/images'
import React from 'react'
import { Control, Controller, UseFormRegister } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import style from '../styles/add_run.module.scss'

interface IProps {
  project: {
    id: number
    title: string
  }
  control: Control<any, any>
  register: UseFormRegister<any>
  errors: any
}

const RunForm = ({
  project,
  control,
  register,
  errors
}: IProps): React.ReactElement => {
  const intl = useIntl()

  return (
    <Wrapper className={style.form}>
      <div className={style.form__content}>
        <Title type='h2'>
          <FormattedMessage id='titles.runs' defaultMessage='Test Run' />
        </Title>

        <Select
          disabled
          className={style.form__block}
          placeholder={intl.formatMessage({
            id: 'input.placeholder',
            defaultMessage: 'Choose'
          })}
          label={intl.formatMessage({
            id: 'case.form.project',
            defaultMessage: 'Project'
          })}
          required
          value={project.title}
        >
          <MenuItem value={project.title} className={style.project_item}>
            <img
              src={errorFolder}
              width={30}
              height={30}
              alt=''
              className={style.project_item__icon}
            />
            {project.title}
          </MenuItem>
        </Select>

        <div className={style.form__block}>
          <Controller
            control={control}
            name='milestone'
            render={({ field: { onChange, value } }) => (
              <MilestoneSelect
                value={value}
                projectId={project.id}
                handleChange={onChange}
              />
            )}
          />
        </div>

        <Input
          label={intl.formatMessage({
            id: 'milestones.form.name',
            defaultMessage: 'Name'
          })}
          required
          className={style.form__block}
          {...register('title', { required: true })}
          error={errors?.title?.message ?? ''}
        />

        <Textarea
          label={intl.formatMessage({
            id: 'milestones.form.description',
            defaultMessage: 'Description'
          })}
          {...register('description')}
          error={errors?.description?.message ?? ''}
        />
      </div>
    </Wrapper>
  )
}

export default RunForm
