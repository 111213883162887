import * as yup from 'yup'
import { FormattedMessage } from 'react-intl'
import React from 'react'

export const jiraValidationSchema = yup.object({
  jira_enable: yup.boolean(),
  jira_server_url: yup
    .string()
    .url(() => (
      <FormattedMessage
        id='validation.url_format'
        defaultMessage='Field must start with http or https. Field is required.'
      />
    ))
    .required(() => (
      <FormattedMessage
        id='validation.required'
        defaultMessage='Field is required'
      />
    ))
    .nullable(),
  jira_api_url: yup
    .string()
    .url(() => (
      <FormattedMessage
        id='validation.url_format'
        defaultMessage='Field must start with http or https. Field is required.'
      />
    ))
    .required(() => (
      <FormattedMessage
        id='validation.required'
        defaultMessage='Field is required'
      />
    )).nullable(),
  jira_user: yup
    .string()
    .required(() => (
      <FormattedMessage
        id='validation.required'
        defaultMessage='Field is required'
      />
    )).nullable(),
  jira_token: yup
    .string()
    .required(() => (
      <FormattedMessage
        id='validation.required'
        defaultMessage='Field is required'
      />
    )).nullable(),
  jira_project: yup.mixed(),
  jira_type_tasks: yup.array().of(yup.mixed()),
  jira_id: yup.mixed(),
  jira_key: yup.string()
})
