import style from '../style/profile.module.scss'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Icon from '@/components/Icon/Icon'
import Button from '@/components/Button/Button'
import { useStores } from '@/hooks'
import PhoneInput from '@/components/Input/PhoneInput'
import { Controller, useForm } from 'react-hook-form'
import { reactHookFormErrorFormater } from '@/utils'
import { Form } from '@/components/Form'

interface IProps {
  success: () => void
  onClose: () => void
  setPhoneFormatted: (value) => void
  phoneFormatted: string
}

export interface IFormInputs {
  non_field_errors?: string
  phone?: string
}

const NewPhone = ({
  success,
  onClose,
  setPhoneFormatted,
  phoneFormatted
}: IProps): React.ReactElement => {
  const intl = useIntl()
  const { api } = useStores()

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors, isSubmitting }
  } = useForm<IFormInputs>({
    defaultValues: {
      phone: phoneFormatted
    }
  })

  const onSubmit = async (params): Promise<void> => {
    try {
      await api.sendCodePhone({
        phone: phoneFormatted
      })
      success()
    } catch (error) {
      const errors = reactHookFormErrorFormater(params, error)
      errors.forEach((item) => {
        setError(item.key as keyof IFormInputs, {
          type: '400',
          message: item.value
        })
      })
    }
  }
  const nonFieldErrors = errors?.non_field_errors?.message ?? ''

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className={style.modal__top}>
        <FormattedMessage
          id='profile.change_phone.title'
          defaultMessage='New Phone number'
        />

        <Button onClick={onClose} theme='light' circle>
          <Icon src='clear' />
        </Button>
      </div>

      <div className={style.modal__middle}>
        <FormattedMessage
          id='profile.change_phone.text'
          defaultMessage='Enter your phone number, which you will use to log in to the system'
        />
        <Controller
          control={control}
          name='phone'
          render={({ field: { onChange, value } }) => {
            return (
              <PhoneInput
                label={intl.formatMessage({
                  id: 'profile.change_phone.phone',
                  defaultMessage: 'Phone'
                })}
                required
                country='ru'
                className={style.modal__input}
                regions='europe'
                value={value}
                onChange={(e) => {
                  setPhoneFormatted(e.detail.formattedValue)
                  onChange(e.detail.value)
                }}
                error={errors?.phone?.message ?? ''}
              />
            )
          }}
        />

        {nonFieldErrors !== ''
          ? (
            <div className={style.modal__error}>{nonFieldErrors}</div>
            )
          : null}
      </div>

      <div className={style.modal__bottom}>
        <Button disabled={isSubmitting} type='submit'>
          <Icon src='checked' slot='icon-left' />

          <FormattedMessage id='common.save' defaultMessage='Save' />
        </Button>
      </div>
    </Form>
  )
}

export default NewPhone
