import React from 'react'
import style from '../styles/notification_item.module.scss'
import { FormattedMessage } from 'react-intl'
import { ITimeSince } from '@/interfaces/Utils'

interface IProps {
  time: ITimeSince
}

const NotifyTime = ({ time }: IProps): React.ReactElement => {
  return (
    <span className={style.item__time}>
      <FormattedMessage
        id={`time_since.${time.period}`}
        defaultMessage={`${time.count} ${time.period} ago`}
        values={{ count: time.count }}
      />
    </span>
  )
}

export default NotifyTime
