import React from 'react'
import CaseDetail from '../CaseDetail'
import ProjectLayout from '../layout/components/ProjectLayout'
import UserLayout from '../layout/components/UserLayout'

const Case = (): React.ReactElement => {
  return (
    <UserLayout>
      <ProjectLayout>
        <CaseDetail />
      </ProjectLayout>
    </UserLayout>
  )
}

export default Case
