import { IHealthCheck, IProject } from '@/interfaces/Project'

export const getStatusFromMappings = (
  data: IProject,
  healthCheck: boolean
): boolean | null => {
  const isIntegrationSuccess = data.jira_enable && healthCheck
  const isIntegrationFailure = !healthCheck && data.jira_enable
  const noIntegrationYet = !data.jira_enable && data.gitlab_token == null

  const statusMappings = [
    { condition: isIntegrationSuccess, value: true },
    { condition: isIntegrationFailure, value: false },
    { condition: noIntegrationYet, value: null }
  ]

  const matchingMapping = statusMappings.find((mapping) => mapping.condition)

  return matchingMapping != null ? matchingMapping.value : null
}

export const canShowAdditionalFields = (
  healthcheck: IHealthCheck | undefined,
  project: IProject | undefined
): boolean => {
  const requiredFields = [
    'jira_api_url',
    'jira_server_url',
    'jira_token',
    'jira_user'
  ]

  if (healthcheck?.result === false || project == null) {
    return false
  }

  return requiredFields.every((field) => project[field] !== null)
}
