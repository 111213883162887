import { usePage } from '@/hooks'
import React from 'react'
import ErrorUpdate from './components/ErrorUpdate'
import Success from './components/Success'

const EmailConfirm = (): React.ReactElement => {
  const page = usePage()
  const isEmailUpdate: boolean =
    page.init_state.user !== null
      ? page.init_state.user.is_email_confirmed === true &&
        page.init_state.user.is_authenticated === true
      : false

  const url: string = isEmailUpdate
    ? page.init_state.global.profile_url
    : page.init_state.global.login_url
  return <>{isEmailUpdate ? <Success hrefRedirect={url} /> : <ErrorUpdate hrefRedirect={url} />}</>
}

export default EmailConfirm
