import { LinkButton } from '@/components/Button/LinkButton'
import Icon from '@/components/Icon/Icon'
import Title from '@/components/Title'
import useGlobalConfig from '@/hooks/useGlobalConfig'
import React from 'react'
import { useIntl } from 'react-intl'
import style from './styles/error.module.scss'

interface ErrorProps {
  numberError: number
  textError: string
  descriptionTitle: string
  descriprionText: string
  image: string
}

const ErrorAuth = ({
  numberError,
  textError,
  descriptionTitle,
  descriprionText,
  image
}: ErrorProps): React.ReactElement => {
  const intl = useIntl()
  const obj = useGlobalConfig()
  return (
    <div className={style.error}>
      <div className={style.error__picture}>
        <div className={style.error__textnumber}>
          <p className={style.error__text}>{textError}</p>
          <Title type='h1' className={style.error__number}>{numberError}</Title>
        </div>
        <img className={style.error__img} src={image} alt='Error' />
      </div>

      <div className={style.error__description}>
        <h2 className={style.error__descripriontitle}>{descriptionTitle}</h2>

        <p className={style.error__descripriontext}>{descriprionText}</p>

        <LinkButton className={style.error__descriprionbutton} href={obj.dashboard_url}>
          {intl.formatMessage({
            id: 'button.main',
            defaultMessage: 'Return to main'
          })}
          <Icon src='arrow_right' slot='icon-left' size='large' />
        </LinkButton>
      </div>
    </div>
  )
}

export default ErrorAuth
