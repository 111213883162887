import React from 'react'
import Container from '@/components/Container'

import JiraIntegration from '../IntegrationsJira/JiraIntegration'
import GitlabIntegration from '../IntegrationsGitlab/GitlabIntegrations'
import { IntegrationStatusProvider } from './context'
import styles from './styles/card.module.scss'

const Integrations = (): JSX.Element => {
  return (
    <IntegrationStatusProvider>
      <Container className={styles.card__wrapper}>
        <JiraIntegration />
        <GitlabIntegration />
      </Container>
    </IntegrationStatusProvider>
  )
}

export default Integrations
