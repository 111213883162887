import style from '../styles/registration.module.scss'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useForm } from 'react-hook-form'
import Icon from '@/components/Icon/Icon'
import Button from '@/components/Button/Button'
import { useStores } from '@/hooks'
import { reactHookFormErrorFormater } from '@/utils'
import { Form } from '@/components/Form'
import Title from '@/components/Title'
import Timer from '@/components/Timer'
import { VERIFY_TYPES } from '@/const'

interface IProps {
  prevStage: () => void
  email: string
}

export interface IFormInputs {
  email?: string
  non_field_errors?: string
}

const ResendEmail = ({ prevStage, email }: IProps): React.ReactElement => {
  const [seconds, setSeconds] = React.useState(60)
  const { api } = useStores()

  const {
    handleSubmit,
    setError,
    formState: { errors, isSubmitting }
  } = useForm<IFormInputs>({
    defaultValues: {
      email: email
    }
  })

  const onSubmit = async (params): Promise<void> => {
    setSeconds(60)
    try {
      await api.sendCodeEmail({
        email: params.email
      })
      // nextStage();
    } catch (error) {
      const errors = reactHookFormErrorFormater(params, error)
      errors.forEach((item) => {
        setError(item.key as keyof IFormInputs, {
          message: item.value
        })
      })
    }
  }
  const nonFieldErrors = errors?.non_field_errors?.message ?? ''

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Button
        theme='light'
        className={style.form__back}
        circle
        onClick={prevStage}
      >
        <Icon src='back' />
      </Button>

      <Title type='h1'>
        <FormattedMessage
          id='registration.title'
          defaultMessage='Registration'
        />
      </Title>

      <div className={style.form}>
        <p>
          <FormattedMessage
            id='registration.resend.text'
            defaultMessage='We just sent you a confirmation code over to {email}'
            values={{ email }}
          />
        </p>

        <div className={style.form__bottom}>
          {seconds === 0
            ? (
              <Button disabled={isSubmitting} type='submit' size='lg'>
                <Icon src='checked' slot='icon-left' />

                <FormattedMessage
                  id='registration.resend.resend'
                  defaultMessage='Resend'
                />
              </Button>
              )
            : (
              <Timer
                type={VERIFY_TYPES.Link}
                setSeconds={setSeconds}
                seconds={seconds}
              />
              )}
        </div>

        {nonFieldErrors !== ''
          ? (
            <div className={style.form__error}>{nonFieldErrors}</div>
            )
          : null}
      </div>
    </Form>
  )
}

export default ResendEmail
