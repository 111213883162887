import BackButton from '@/components/Button/BackButton'
import { LinkButton } from '@/components/Button/LinkButton'
import HtmlContent from '@/components/HtmlContent/HtmlContent'
import Icon from '@/components/Icon/Icon'
import LinearProgress from '@/components/LinearProgress'
import { ColorStatus } from '@/components/Status'
import Title from '@/components/Title'
import Wrapper from '@/components/Wrapper'
import { useObjectPage } from '@/hooks'
import useSidebar from '@/hooks/useSidebar'
import { IMilestone } from '@/interfaces/Milestones'
import { IMilestonePage } from '@/interfaces/ObjectPage'
import React from 'react'
import { EDIT_PERMISSION_LEVEL, USER_PERMISSIONS } from '@/const'
import { FormattedMessage } from 'react-intl'
import { useCurrentPermissions } from '@/hooks/useCurrentPermissions'
import style from '../styles/milestone_detail.module.scss'

interface IProps {
  milestone: IMilestone
  hrefUpdate: string
}

const Description = ({ milestone, hrefUpdate }: IProps): React.ReactElement => {
  const { progress } = useObjectPage<IMilestonePage>()
  const { ProjectPageMilestones } = useSidebar()

  const canUpdateMilestones = useCurrentPermissions(
    USER_PERMISSIONS.milestones,
    EDIT_PERMISSION_LEVEL
  )
  return (
    <Wrapper className={style.description}>
      <div className={style.description__titlebox}>
        <BackButton
          href={ProjectPageMilestones.absolute_url}
          className={style.head__back}
        />

        <ColorStatus size='large' color={milestone.color} />

        <div className={style.description__title}>
          <div className={style.description__code}>{milestone.code}</div>

          <Title type='h1' className={style.description__title}>
            {milestone.title}
          </Title>
        </div>

        <div className={style.description__titleright}>
          {canUpdateMilestones
            ? (
              <LinkButton href={hrefUpdate} theme='light' circle>
                <Icon src='edit' size='xl' />
              </LinkButton>
              )
            : null}
        </div>
      </div>

      <div className={style.description__content}>
        <div className={style.description__text}>
          <div className={style.description__grey}>
            <FormattedMessage
              id='milestones.form.description'
              defaultMessage='Description'
            />
          </div>

          <HtmlContent content={milestone.description} />
        </div>

        <div className={style.description__buttons}>
          <div className={style.description__block}>
            <span className={style.description__grey}>
              <FormattedMessage
                id='milestones.form.start_date'
                defaultMessage='Start date'
              />
            </span>

            <span className={style.description__date}>
              {milestone.start_date}
            </span>
          </div>

          <div className={style.line__content} />

          <div className={style.description__block}>
            <div className={style.description__grey}>
              <FormattedMessage
                id='milestones.form.end_date'
                defaultMessage='End date'
              />
            </div>

            <span className={style.description__date}>
              {milestone.end_date}
            </span>
          </div>
        </div>
      </div>

      <div className={style.description__complete}>
        <FormattedMessage
          id='common.successfully'
          defaultMessage='Successfully'
        />
      </div>

      <div className={style.description__progress}>
        <LinearProgress value={progress} />
      </div>
    </Wrapper>
  )
}

export default Description
