import React from 'react'
import Icon from '../Icon/Icon'
import style from './styles/bugbtn.module.scss'

interface IProps {
  name: string
  url: string
}

const BugItem = ({ name, url }: IProps): React.ReactElement => {
  return (
    <a
      href={url}
      className={style.bug}
      target='_blank'
      rel='noopener noreferrer'
    >
      <Icon src='bug' />
      <span className={style.bug__name}>{name}</span>
    </a>
  )
}

export default BugItem
