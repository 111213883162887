import Icon from '@/components/Icon/Icon'
import classNames from 'classnames'
import React from 'react'
import styles from '../../Integrations/styles/card.module.scss'
import { useIntegrationStatus } from '@/apps/Integrations/context'

const JiraIntegrationHead = (): JSX.Element => {
  const { jiraIntegrationStatus } = useIntegrationStatus()

  const roundColor = classNames(styles.round, {
    [styles.green]: jiraIntegrationStatus === true,
    [styles.red]: jiraIntegrationStatus === false,
    [styles.gray]: jiraIntegrationStatus === null
  })

  return (
    <div className={styles.card__header}>
      <div className={styles.card__left}>
        <div className={roundColor} />
        <Icon className={styles.card__icon} src='jira_icon' />
        <div className={styles.card__title}>Jira</div>
      </div>
    </div>
  )
}

export default JiraIntegrationHead
