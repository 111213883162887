import { IProjectObject } from '@/interfaces/ProjectObject'
import { usePage as usePageCms } from '@garpix/cms'

const useProjectObject = (): IProjectObject => {
  const value = usePageCms()
  const page = value.page
  return page.init_state.project_object
}

export default useProjectObject
