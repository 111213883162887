import Icon from '@/components/Icon/Icon'
import Input from '@/components/Input/Input'
import PhotoInput from '@/components/Input/PhotoInput'
import Textarea from '@/components/Textarea'
import Title from '@/components/Title'
import Wrapper from '@/components/Wrapper'
import { CASE_TYPES } from '@/const'
import React from 'react'
import { Controller } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import style from '../styles/add_edit.module.scss'
import { Radio } from '@/components/Radio'

const ProjectForm = ({
  projectTitle,
  register,
  image,
  errors,
  control
}): React.ReactElement => {
  const intl = useIntl()

  const validateMaxLength = (value: string): boolean | string => {
    if (value.length > 100) {
      return intl.formatMessage({
        id: 'project.form.id.err',
        defaultMessage: 'Maximum character length - 100'
      })
    }
    return true
  }

  return (
    <Wrapper className={style.project}>
      <div className={style.form}>
        <div className={style.form__left}>
          <Title type='h2'>
            {projectTitle ?? (
              <FormattedMessage id='titles.project' defaultMessage='Project' />
            )}
          </Title>
          <div className={style.form__flex}>
            <PhotoInput
              {...register('image')}
              image={image}
              variant='project'
              className={style.form__image}
              error={errors?.image?.message ?? ''}
            />

            <div className={style.form__info}>
              <div className={style.form__info_top}>
                <Input
                  label={intl.formatMessage({
                    id: 'project.form.name',
                    defaultMessage: 'Name'
                  })}
                  required
                  className={style.form__info_name}
                  {...register('title', { required: true })}
                  error={errors?.title?.message ?? ''}
                />

                <Controller
                  name='code'
                  control={control}
                  defaultValue=''
                  rules={{
                    required: true,
                    validate: validateMaxLength
                  }}
                  render={({ field }) => (
                    <Input
                      label='ID'
                      required
                      className={style.form__info_id}
                      {...field}
                      error={errors?.code?.message ?? ''}
                    />
                  )}
                />
              </div>
              <Textarea
                label={intl.formatMessage({
                  id: 'project.form.description',
                  defaultMessage: 'Description'
                })}
                {...register('description')}
                error={errors?.description?.message ?? ''}
              />

              <p className={style.form__info_text}>
                <FormattedMessage
                  id='project.form.type_text'
                  defaultMessage='What will be used in the project?'
                />
              </p>

              <div className={style.form__info_type}>
                <Controller
                  control={control}
                  name='priority_case_type'
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Radio
                        value={CASE_TYPES.case}
                        name='priority_case_type'
                        onGx-change={(e) => onChange(e.target.value)}
                        checked={value === CASE_TYPES.case}
                      >
                        <FormattedMessage
                          id='input.case'
                          defaultMessage='Case'
                        />
                      </Radio>
                    )
                  }}
                />

                <Controller
                  control={control}
                  name='priority_case_type'
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Radio
                        value={CASE_TYPES.checklist}
                        name='priority_case_type'
                        onGx-change={(e) => onChange(e.target.value)}
                        checked={value === CASE_TYPES.checklist}
                      >
                        <FormattedMessage
                          id='input.checklist'
                          defaultMessage='Checklist'
                        />
                      </Radio>
                    )
                  }}
                />

                <Controller
                  control={control}
                  name='priority_case_type'
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Radio
                        value={CASE_TYPES.task}
                        name='priority_case_type'
                        onGx-change={(e) => onChange(e.target.value)}
                        checked={value === CASE_TYPES.task}
                      >
                        <FormattedMessage
                          id='input.task'
                          defaultMessage='Task'
                        />
                      </Radio>
                    )
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={style.form__right}>
          <Title type='h3' className={style.form__right_title}>
            <Icon src='clip' />
            <FormattedMessage id='titles.links' defaultMessage='Links' />
          </Title>

          <div className={style.links__item}>
            <Icon src='task' className={style.links__icon} />
            <Input
              label={intl.formatMessage({
                id: 'project.form.tracker',
                defaultMessage: 'Task tracking system'
              })}
              className={style.links__input}
              {...register('urls.0.value')}
              error={errors?.urls?.['0']?.value.message ?? ''}
            />
            <input type='hidden' {...register('urls.0.system')} value='jira' />
          </div>

          <div className={style.links__item}>
            <Icon src='knowledge' className={style.links__icon} />
            <Input
              label={intl.formatMessage({
                id: 'project.form.base',
                defaultMessage: 'Knowledge base'
              })}
              className={style.links__input}
              {...register('urls.1.value')}
              error={errors?.urls?.['1']?.value?.message ?? ''}
            />
            <input
              type='hidden'
              {...register('urls.1.system')}
              value='gitlab'
            />
          </div>

          <div className={style.links__item}>
            <Icon src='git' className={style.links__icon} />
            <Input
              label={intl.formatMessage({
                id: 'project.form.git',
                defaultMessage: 'Version control system'
              })}
              className={style.links__input}
              {...register('urls.2.value')}
              error={errors?.urls?.['2']?.value.message ?? ''}
            />
            <input
              type='hidden'
              {...register('urls.2.system')}
              value='confluence'
            />
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default ProjectForm
