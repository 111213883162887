import React, { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { FormattedMessage, useIntl } from 'react-intl'
import Button from '../Button/Button'
import Icon from '../Icon/Icon'
import { Portal } from '../Portal'
import { IAttachment } from '@/interfaces/Attachment'
import { checkImageInURL } from '@/utils'
import { useStores } from '@/hooks'
import useModal from '@/hooks/useModal'
import BaseModal from '../Modal/Base'
import DeleteFile from './DeleteFile'
import { IFilePreview, IUploadZoneComponent } from './interfaces'
import FilesList from './components/FilesList'
import EmptyList from '../EmptyList/EmptyList'
import style from './styles/upload_zone.module.scss'

let id = 0

const attachmentToPreview = (file: IAttachment): IFilePreview => {
  const { file_url: fileUrl, file_name: fileName } = file
  return {
    isLoaded: true,
    id: file.id,
    name: fileName,
    size: file.size,
    date: new Date(file.created_at).toLocaleDateString(),
    previewUrl: checkImageInURL(fileName) ? fileUrl : undefined,
    url: fileUrl
  }
}

const UploadZone = ({
  className = '',
  onChange,
  isScrollableList = false,
  initFiles = [],
  handleDeleteFile,
  enableReinitialize = false,
  isUpload,
  canUserDeleteFile,
  files,
  setFiles,
  currentFile,
  setCurrentFile,
  isForm,
  readonly,
  setFilesCount
}: IUploadZoneComponent): React.ReactElement => {
  const { api } = useStores()
  const {
    isOpen,
    handleOpenModal: openModal,
    handleCloseModal: closeModal
  } = useModal()
  const intl = useIntl()

  // const {
  //   isOpen: isOpenViewModal,
  //   handleOpenModal: openViewModal,
  //   handleCloseModal: closeViewModal
  // } = useModal()

  const inputFile = useRef<HTMLInputElement>(null)
  const fileDownloadRef = useRef<HTMLAnchorElement>(null)

  const [counter, setCounter] = React.useState(0)
  const [opened, setOpened] = useState(false)
  const [fileItems, setFileItems] = useState<IFilePreview[]>(() => {
    return initFiles.map(attachmentToPreview)
  })

  // const [selectedItem, setSelectedItem] =
  //   useState<Partial<IFilePreview> | null>(null)

  const handleDragEnter = (event: DragEvent): void => {
    if (
      event.dataTransfer?.files != null &&
      event.dataTransfer.types.includes('Files')
    ) {
      setCounter((prev): number => prev + 1)
      setOpened(true)
    }
  }

  const handleDragLeave = (): void => {
    setCounter((prev) => prev - 1)
  }

  const addFiles = (files: File[]): void => {
    setFiles((prev) => {
      const newState = [...prev, ...files]
      if (isForm === true) onChange(newState)
      return newState
    })
    if (isForm === false) onChange(files)

    const previews = files.map((file) => {
      let previewUrl: string | undefined
      if (file.type.includes('image')) {
        previewUrl = URL.createObjectURL(file)
      } else {
        previewUrl = checkImageInURL(file.name)
          ? URL.createObjectURL(file)
          : undefined
      }
      return {
        id: --id,
        name: file.name,
        size: file.size,
        date: new Date(file.lastModified).toLocaleDateString(),
        previewUrl,
        isLoaded: false
      }
    })
    setFileItems((prev) => [...previews, ...prev])
  }

  const handleDrop = (e: DragEvent): void => {
    e.preventDefault()
    const currentFiles = Array.from(e.dataTransfer?.files ?? [])
    addFiles(currentFiles)
    setCounter((prev) => prev - 1)
  }

  const dragover = (e): void => {
    e.preventDefault()
  }

  const handleUploadClick = (): void => {
    inputFile.current?.click()
  }

  const handleInput = (e): void => {
    const currentFiles: File[] = Array.from(e.target.files ?? [])
    addFiles(currentFiles)
  }

  const handleOpenModalDelete = (id: number, name: string): void => {
    setCurrentFile({ id, name })
    openModal()
  }

  // const handleOpenModalPreview = (name: string, url: string): void => {
  //   setSelectedItem({ name, url })
  //   // openViewModal()
  // }

  const handleCloseModalDelete = (): void => {
    setCurrentFile(null)
    closeModal()
  }

  // const handleCloseModalPreview = (): void => {
  //   setSelectedItem(null)
  //   // closeViewModal()
  // }

  const handleDelete = async (): Promise<void> => {
    if (currentFile?.id !== undefined) {
      let currentFiles = Array.from(fileItems)
      currentFiles = currentFiles.filter((item) => item.id !== currentFile.id)
      setFileItems(currentFiles)
      if (currentFile.id > 0) {
        await api.removeAttachments(currentFile.id)
        setFilesCount?.()
      }
    }
    await handleDeleteFile()
    await handleCloseModalDelete()
  }

  useEffect(() => {
    if (counter === 0) {
      setOpened(false)
    }
  }, [counter])

  useEffect(() => {
    if (readonly !== true) {
      document.addEventListener('dragenter', handleDragEnter, false)
      document.addEventListener('dragleave', handleDragLeave, false)
      document.addEventListener('dragover', dragover, false)
      document.addEventListener('drop', handleDrop, false)
    }

    return () => {
      document.removeEventListener('dragenter', handleDragEnter, false)
      document.removeEventListener('dragleave', handleDragLeave, false)
      document.removeEventListener('dragover', dragover, false)
      document.removeEventListener('drop', handleDrop, false)
    }
  }, [])

  const currentFiles = enableReinitialize
    ? initFiles.map(attachmentToPreview)
    : fileItems

  // const handleSaveFile = (): void => {
  //   if (fileDownloadRef.current != null) {
  //     fileDownloadRef.current.click()
  //   }
  // }

  return (
    <>
      {readonly !== true
        ? (
          <div
            className={classNames({
              [style.drop]: true,
              [style['drop--small']]: currentFiles.length > 0 || isUpload,
              [className]: Boolean(className)
            })}
          >
            <span className={style.drop__text}>
              <FormattedMessage
                id='upload_zone.message'
                defaultMessage='Drop files here to attach, or click on the button'
              />
            </span>

            <Button circle onClick={handleUploadClick}>
              <Icon src='attach' />
            </Button>

            <Portal>
              <input
                type='file'
                multiple
                hidden
                ref={inputFile}
                onInput={handleInput}
              />
              <div
                className={classNames({
                  [style.overlay]: true,
                  [style['overlay--visible']]: opened
                })}
              >
                <div className={style.zone}>
                  <Icon src='draft' className={style.zone__icon} />

                  <p className={style.zone__text}>
                    <FormattedMessage
                      id='upload_zone.zone_message'
                      defaultMessage='Drop files here to attach'
                    />
                  </p>
                </div>
              </div>
            </Portal>
          </div>
          )
        : null}

      {readonly === true && currentFiles.length === 0
        ? (
          <EmptyList
            icon='attach'
            text={intl.formatMessage({
              id: 'upload_zone.empty',
              defaultMessage: 'No files have been added'
            })}
            size='md'
          />
          )
        : null}

      <FilesList
        isScrollableList={isScrollableList}
        currentFiles={currentFiles}
        isForm={isForm}
        handleOpenModalDelete={handleOpenModalDelete}
        fileDownloadRef={fileDownloadRef}
        canUserDeleteFile={canUserDeleteFile}
        isUpload={isUpload}
        readonly={readonly}
      />

      <BaseModal
        open={isOpen}
        onGx-after-hide={handleCloseModalDelete}
        onGx-overlay-dismiss={handleCloseModalDelete}
        hideDefaultClose
        size='medium'
      >
        <DeleteFile
          onCancel={handleCloseModalDelete}
          onDelete={handleDelete}
          disableSubmit={false}
          fileName={currentFile?.name ?? ''}
        />
      </BaseModal>

      {/* {selectedItem !== null && isForm === false && (
        <BaseModal
          open={isOpenViewModal}
          onGx-after-hide={handleCloseModalPreview}
          onGx-overlay-dismiss={handleCloseModalPreview}
          hideDefaultClose
          size='medium'
        >
          <ViewFile
            handleClose={handleCloseModalPreview}
            name={selectedItem.name as string}
            url={selectedItem.url as string}
            handleSave={handleSaveFile}
          />
        </BaseModal>
      )} */}
    </>
  )
}

export default UploadZone
