import React from 'react'
import ProjectLayout from '../layout/components/ProjectLayout'
import UserLayout from '../layout/components/UserLayout'
import CasesSuites from '../SuitesCases/CasesSuites'

const ProjectCasesSuites = (): React.ReactElement => {
  return (
    <UserLayout>
      <ProjectLayout>
        <CasesSuites />
      </ProjectLayout>
    </UserLayout>
  )
}

export default ProjectCasesSuites
