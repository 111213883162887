import React from 'react'
import Faq from '../Faq'
import UserLayout from '../layout/components/UserLayout'

const FaqPage = (): React.ReactElement => {
  return (
    <UserLayout>
      <Faq />
    </UserLayout>
  )
}

export default FaqPage
