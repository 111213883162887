import { GxCheckbox } from '@garpix/garpix-web-components-react'
import classNames from 'classnames'
import React from 'react'
import Icon from '../Icon/Icon'
import style from './styles/checkbox.module.scss'

interface IProps extends React.PropsWithChildren<any> {
  children?: React.ReactNode | string
  className?: string
  error?: boolean
  checked?: boolean
  setChecked: (value: boolean) => void
  size?: 'small' | 'medium' | 'large'
}

const CheckboxFavorite = ({
  children = '',
  className = '',
  error = false,
  checked = false,
  size = 'medium',
  setChecked,
  ...props
}: IProps): React.ReactElement => {
  const handleChange = (): void => {
    setChecked(!checked)
  }

  return (
    <>
      <GxCheckbox
        checked={checked}
        className={classNames({
          [style.favorite]: true,
          [style[`checkbox--${size}`]]: true,
          [className]: Boolean(className)
        })}
        {...props}
        onGx-change={handleChange}
      >
        {checked
          ? (
            <Icon
              src='starFill'
              className={classNames({
                [style.star]: true,
                [style.star__checked]: true,
                [style[`star--${size}`]]: true
              })}
            />
            )
          : (
            <Icon
              src='star'
              className={classNames({
                [style.star]: true,
                [style.star__unchecked]: true,
                [style[`star--${size}`]]: true
              })}
            />
            )}
      </GxCheckbox>
    </>
  )
}

export default CheckboxFavorite
