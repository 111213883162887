import Button from '@/components/Button/Button'
import EmptyList from '@/components/EmptyList/EmptyList'
import Icon from '@/components/Icon/Icon'
import Textarea from '@/components/Textarea'
import Title from '@/components/Title'
import Wrapper from '@/components/Wrapper'
import { useStores } from '@/hooks'
import React from 'react'
import {
  UseFieldArrayAppend,
  UseFieldArrayMove,
  UseFieldArrayRemove,
  UseFieldArrayUpdate,
  UseFormRegister
} from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import style from '../styles/add_case.module.scss'
import { TCaseType } from '@/interfaces/Types'
import { CASE_TYPES } from '@/const'
import { ICreateStep, IFormInputs } from '../interfaces'
import StepButtons from './StepButtons'

interface IProps {
  append: UseFieldArrayAppend<IFormInputs, 'steps'>
  remove: UseFieldArrayRemove
  register: UseFormRegister<IFormInputs>
  fields?: ICreateStep[]
  type: TCaseType
  move: UseFieldArrayMove
  update: UseFieldArrayUpdate<IFormInputs, 'steps'>
}

const Steps = ({
  fields = [],
  append,
  remove,
  register,
  type,
  move,
  update
}: IProps): React.ReactElement => {
  const intl = useIntl()
  const { api } = useStores()

  const handleStepSwap = (fromIndex, toIndex): void => {
    const currentItem = fields[fromIndex]
    const nextItem = fields[toIndex]

    const numberFrom = currentItem.number
    const numberTo = nextItem.number
    update(fields.indexOf(nextItem), { ...nextItem, number: numberFrom })
    update(fields.indexOf(currentItem), { ...currentItem, number: numberTo })

    move(fromIndex, toIndex)
  }

  const handleAddStep = (): void => {
    append({
      description: '',
      expected_result: '',
      comments_count: 0,
      number: fields.length + 1,
      issues_count: 0
    })
  }

  const handleRemoveStep = async (index, id): Promise<void> => {
    remove(index)
    if (typeof id === 'number') {
      await api.removeStep(id)
    }
  }

  return (
    <Wrapper className={style.steps}>
      <Title type='h2'>
        <FormattedMessage id='titles.steps' defaultMessage='Steps' />
      </Title>
      {fields.length > 0
        ? (
          <>
            {fields.map((el: ICreateStep, index) => {
              return (
                <div
                  key={el.id === undefined ? el._id : el.id}
                  className={style.step}
                >
                  <div
                    className={style.step__number}
                    {...register(`steps.${index}.number`)}
                  >
                    {index + 1}
                  </div>

                  <Textarea
                    label={intl.formatMessage({
                      id: 'case.steps.step',
                      defaultMessage: 'Step'
                    })}
                    className={style.step__textarea}
                    {...register(`steps.${index}.description`)}
                  />

                  {type === CASE_TYPES.case
                    ? (
                      <Textarea
                        label={intl.formatMessage({
                          id: 'case.steps.result',
                          defaultMessage: 'Expected Result'
                        })}
                        className={style.step__textarea}
                        {...register(`steps.${index}.expected_result`)}
                      />
                      )
                    : null}

                  <StepButtons
                    index={index}
                    el={el}
                    handleStepSwap={handleStepSwap}
                    handleRemoveStep={handleRemoveStep}
                    fields={fields}
                  />
                </div>
              )
            })}

            <Button type='button' onClick={handleAddStep}>
              <Icon src='plus' slot='icon-left' />

              <FormattedMessage
                id='case.empty.button'
                defaultMessage='Add step'
              />
            </Button>
          </>
          )
        : (
          <EmptyList
            icon='steps'
            text={intl.formatMessage({
              id: 'case.empty.text',
              defaultMessage: 'The Test Case doesn’t have steps'
            })}
            buttonText={intl.formatMessage({
              id: 'case.empty.button',
              defaultMessage: 'Add step'
            })}
            className={style.steps__empty}
            onClick={handleAddStep}
          />
          )}
    </Wrapper>
  )
}

export default Steps
