import React from 'react'
import { FormattedMessage } from 'react-intl'
import Icon from '@/components/Icon/Icon'
import Title from '@/components/Title'
import { LinkButton } from '@/components/Button/LinkButton'
import style from '../styles/registration.module.scss'

interface IProps {
  hrefRedirect: string
}

const SuccesChanged = ({ hrefRedirect }: IProps): React.ReactElement => {
  return (
    <>
      <div className={style.form__icon}>
        <Icon src='checked' className={style.form__bigicon} />
      </div>

      <div className={style.form__endtext}>
        <Title type='h1'>
          <FormattedMessage
            id='profile.forgot_pass.success'
            defaultMessage='Your password has been changed!'
          />
        </Title>
      </div>

      <LinkButton href={hrefRedirect} size='lg'>
        <Icon src='arrow_right' slot='icon-left' />

        <FormattedMessage
          id='profile.change_email.login'
          defaultMessage='To login'
        />
      </LinkButton>
    </>
  )
}

export default SuccesChanged
