import { useStores } from '@/hooks'
import React from 'react'
import { useIntl } from 'react-intl'
import {
  TAdditional,
  IValue,
  CompanySelectProps,
  ILoadOptions
} from '../interfaces/AsyncSelect'
import BaseAsyncPaginate from '../AsyncSelect/BaseAsyncPaginate'

const PAGE_SIZE = 20

const defaultAdditional: TAdditional = {
  page: 1
}

const CompanySelect = ({
  handleChange,
  value,
  ...props
}: CompanySelectProps): React.ReactElement => {
  const { api } = useStores()
  const intl = useIntl()

  const loadPageOptions = async (
    q: string,
    _: unknown,
    { page }: TAdditional
  ): Promise<ILoadOptions> => {
    const { options, hasMore } = await loadOptions(q, page)

    return {
      options,
      hasMore,
      additional: {
        page: page + 1
      }
    }
  }

  const loadOptions = async (search: string, page: number): Promise<any> => {
    let hasMore = false
    const options: IValue[] = await api
      .getCompanies({
        page: page,
        page_size: PAGE_SIZE,
        q: search,
        status: 'active'
      })
      .then((paginatedObject) => {
        hasMore = paginatedObject.next !== null

        return paginatedObject.results.map((el) => {
          return { value: el.id, label: el.title }
        })
      })

    return {
      options,
      hasMore,
      additional: {
        page: page + 1
      }
    }
  }

  return (
    <BaseAsyncPaginate
      {...props}
      value={value}
      label={intl.formatMessage({
        id: 'input.company',
        defaultMessage: 'Company'
      })}
      placeholder={intl.formatMessage({
        id: 'input.placeholder',
        defaultMessage: 'Select...'
      })}
      handleChange={handleChange}
      loadOptions={loadPageOptions}
      additional={defaultAdditional}
    />
  )
}

export default CompanySelect
