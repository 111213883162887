import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import Select from '@/components/Select/Select'
import MenuItem from '@/components/MenuItem/MenuItem'
import useSWR from 'swr'
import useObjectPage from '@/hooks/useObjectPage'
import { useStores } from '@/hooks/useStores'
import { IProject } from '@/interfaces/Project'
import { SelectShimmer } from '@/components/Select/SelectShimmer'
import style from '../styles/jira.module.scss'

interface IOptions {
  value: string
  label: string
  icon?: string
}

interface ITaskFieldProps {
  handleChange: (value: IOptions[]) => void
  jiraProjectId: string | number
  currentProjectData: IProject | undefined
}

const TaskField = ({
  handleChange,
  jiraProjectId,
  currentProjectData
}: ITaskFieldProps): JSX.Element => {
  const intl = useIntl()
  const { id } = useObjectPage()
  const { api } = useStores()
  const [options, setOptions] = useState<IOptions[]>([])
  const [selectedOptions, setSelectedOptions] = useState<IOptions[]>([])
  const [fieldTypesNames, setFieldTypesNames] = useState<string[]>([])

  const { isLoading } = useSWR(
    { id: id, jiraId: jiraProjectId, _key: 'getJiraIssueTypes' },
    api.getJiraIssueTypes,
    {
      onSuccess: (issueTypes) => {
        // gx-multi-select throwed some errors when we got two or more same values
        // thats the reason to create unique values array
        const uniqueIssueTypes = Array.from(
          new Set(issueTypes.map((issue) => issue.name))
        )
        const mappedData = uniqueIssueTypes.map((name, idx) => {
          const issue = issueTypes.find((issue) => issue.name === name)
          const issueIds = issueTypes.map((issue) => issue.id)
          return {
            value: name,
            label: name,
            icon: issue?.iconUrl,
            id: issueIds[idx]
          }
        })
        setOptions(mappedData)
        setFieldTypesNames(
          issueTypes
            ?.filter((item) =>
              currentProjectData?.jira_type_tasks?.includes(item.id)
            )
            .map((item) => item.name)
        )
      }
    }
  )

  const handleMenuItemClick = (option: IOptions): void => {
    const isSelected = selectedOptions.find(
      (selectedOption) => selectedOption.value === option.value
    )
    if (isSelected != null) {
      const updatedOptions = selectedOptions.filter(
        (selectedOption) => selectedOption.value !== option.value
      )

      setSelectedOptions(updatedOptions)
    } else {
      setSelectedOptions([...selectedOptions, option])
    }
  }

  useEffect(() => {
    handleChange(selectedOptions)
  }, [selectedOptions, handleChange])

  useEffect(() => {
    const selectedOptionsFromNames = options.filter((option) =>
      fieldTypesNames?.includes(option.value)
    )
    setSelectedOptions(selectedOptionsFromNames)
    handleChange(selectedOptionsFromNames)
  }, [fieldTypesNames])

  const value = selectedOptions.map((option) => option.value)

  if (isLoading) {
    return (
      <SelectShimmer
        label={intl.formatMessage({
          id: 'integrations.jira.type_of_task',
          defaultMessage: 'Type of task'
        })}
        className={style.form__block}
        required
      />
    )
  }

  return (
    <div className={style.formsection}>
      <Select
        multiple
        required
        className={style.form__block}
        label={intl.formatMessage({
          id: 'integrations.jira.type_of_task',
          defaultMessage: 'Type of task'
        })}
        value={value}
      >
        {options.map((epic, index) => (
          <MenuItem
            key={index}
            value={epic.label}
            className={style.project_item}
            onClick={() => handleMenuItemClick(epic)}
          >
            <div className={style.multi}>
              <img
                src={epic.icon}
                alt={epic.label}
                className={style.multi__image}
              />
            </div>
            {epic.value}
          </MenuItem>
        ))}
      </Select>
    </div>
  )
}

export default TaskField
