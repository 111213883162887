import classNames from 'classnames'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import Icon from '../Icon/Icon'
import Button from './Button'

interface IProps {
  href?: string
  className?: string
  onClick?: () => void
  size?: 'sm' | 'med' | 'lg'
}

const BackButton = ({
  className = '',
  onClick,
  size = 'med',
  href
}: IProps): React.ReactElement => {
  const navigate = useNavigate()

  const handleBack = (): void => {
    if (onClick !== undefined) {
      onClick()
    }
    if (href !== undefined) {
      navigate(href)
    }
  }

  return (
    <Button
      theme='light'
      circle
      size={size}
      onClick={handleBack}
      className={classNames({
        [className]: Boolean(className)
      })}
    >
      <Icon src='back' />
    </Button>
  )
}

export default BackButton
