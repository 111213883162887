import Icon from '@/components/Icon/Icon'
import classNames from 'classnames'
import React from 'react'
import styles from '../../Integrations/styles/card.module.scss'
import { useIntegrationStatus } from '@/apps/Integrations/context'

const GitlabIntegrationHead = (): JSX.Element => {
  const { gitlabIntegrationStatus } =
      useIntegrationStatus()

  const roundColor = classNames(styles.round, {
    [styles.green]: gitlabIntegrationStatus === true,
    [styles.red]: gitlabIntegrationStatus === false,
    [styles.gray]: gitlabIntegrationStatus === null
  })

  return (
    <div className={styles.card__header}>
      <div className={styles.card__left}>
        <div className={roundColor} />
        <Icon className={styles.card__icon} src='gitlab_icon' />
        <div className={styles.card__title}>Gitlab</div>
      </div>
    </div>
  )
}

export default GitlabIntegrationHead
