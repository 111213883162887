import React from 'react'
import style from './styles/button.module.scss'
import classNames from 'classnames'
import Icon from '../Icon/Icon'
import Button from './Button'

interface ICommentButtonProps {
  className?: string
  onClick: () => void
  isCommentsExists?: boolean
}

const CommentButton = ({
  className = '',
  onClick,
  isCommentsExists
}: ICommentButtonProps): React.ReactElement | null => {
  return (
    <Button
      className={classNames({
        [style.comment]: isCommentsExists,
        [style.comment_empty]: isCommentsExists === false,
        [className]: Boolean(className)
      })}
      circle
      theme={isCommentsExists === true ? 'light' : 'violet'}
      onClick={onClick}
    >
      <Icon
        src={
          isCommentsExists === true
            ? 'comment'
            : 'commentPlus'
        }
        className={classNames({
          [style.comment__icon]: isCommentsExists,
          [style.comment_empty__icon]: isCommentsExists === false
        })}
      />
    </Button>
  )
}

export default CommentButton
