import React, { useRef, useState } from 'react'
import queryString from 'query-string'
import Suites from './components/Suites/Suites'
import style from './styles/casessuites.module.scss'
import Cases from './components/Cases/Cases'
import Container from '@/components/Container'
import { ISuiteCaseParams } from '@/interfaces/Suites'
import { useLocation } from 'react-router-dom'
import { suiteService } from './service'

const CasesSuites = (): React.ReactElement => {
  const { search: searchQuery } = useLocation()
  const mutateSuitesRef = useRef<{ handleReloadSuites: () => void } | null>(null)

  const getDefaultParams = (): ISuiteCaseParams => {
    const params = queryString.parse(searchQuery, {
      arrayFormat: 'comma',
      parseNumbers: true
    })
    if (typeof params?.nodesThree === 'number') {
      params.nodesThree = [params.nodesThree]
    }
    if (typeof params?.tagsCases === 'number') {
      params.tagsCases = [params.tagsCases]
    }
    return params
  }

  const [queryParams, setQueryParams] = useState<ISuiteCaseParams>(
    () => getDefaultParams()
  )
  const [nodesThree, setNodesThree] = useState(
    () => getDefaultParams()?.nodesThree ?? []
  )

  const changeQueryParams = (name: string, value: any): void => {
    setQueryParams((queryParams) => {
      const newValue = { ...queryParams, [name]: value }
      const newParamsString: string = queryString.stringify(newValue, {
        arrayFormat: 'comma'
      })
      if (name === 'nodesThree') {
        setNodesThree(value)
        suiteService.setNodeThree(value)
      }

      history.replaceState({}, '', `?${newParamsString}`)
      return newValue
    })
  }

  return (
    <Container className={style.casessuites}>
      <Suites
        params={queryParams}
        setQueryParams={changeQueryParams}
        changeParams={changeQueryParams}
        nodesThree={nodesThree as number[]}
        setNodesThree={setNodesThree}
        ref={mutateSuitesRef}
      />

      <Cases
        params={queryParams}
        changeParams={changeQueryParams}
        ref={mutateSuitesRef}
      />
    </Container>
  )
}

export default CasesSuites
