import React, { Suspense, useRef } from 'react'
import Suites from './components/Suites'
import Cases from './components/Cases'
import BaseModal from '@/components/Modal/Base'
import Wrapper from '@/components/Wrapper'
import Button from '@/components/Button/Button'
import Icon from '@/components/Icon/Icon'
import Title from '@/components/Title'
import { FormattedMessage } from 'react-intl'
import { IRunsElement } from '@/interfaces/Runs'
import { TCaseStatusInRun, TRunStatus } from '@/interfaces/Types'
import { IAttachment } from '@/interfaces/Attachment'
import { ICase } from '@/interfaces/Case'
import { IValue } from '@/components/Select/interfaces/AsyncSelect'
import useModal from '@/hooks/useModal'
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form'
import { usePage, useStores } from '@/hooks'
import { observer } from 'mobx-react'
import { milestoneService } from '@/apps/MilestoneDetail/service'
import { reactHookFormErrorFormater } from '@/utils'
import { Form } from '@/components/Form'
import Loading from '@/components/Loading/Loading'
import {
  USER_PERMISSIONS,
  EDIT_PERMISSION_LEVEL,
  CASE_IN_RUN_STATUS
} from '@/const'
import { useCurrentPermissions } from '@/hooks/useCurrentPermissions'
import AddCase from '../components/AddCase'
import style from '../styles/update_cases.module.scss'

const LayDndProvider = React.lazy(
  async () =>
    await import('@minoru/react-dnd-treeview').then(
      ({ getBackendOptions, MultiBackend, DndProvider }) => {
        const DndComponent: React.FC<any> = (props) => {
          const backendOptions = getBackendOptions(props)
          const backend = MultiBackend

          return (
            <DndProvider backend={backend} options={backendOptions}>
              {props.children}
            </DndProvider>
          )
        }

        return {
          default: DndComponent
        }
      }
    )
)

export interface IFormInputs
  extends Partial<Omit<IRunsElement, 'assigned_to' | 'milestone' | 'files'>> {
  non_field_errors?: string
  title?: string
  status?: TRunStatus
  references?: string
  description?: string
  files: IAttachment[]
  plan?: number
  cases: ICase[]
  project: number
  assigned_to?: number | null
  milestone?: IValue
}

interface IProps {
  handleUpdate?: () => void
  runStatus?: TCaseStatusInRun
}

const SuitesCasesDetail = observer(
  ({ handleUpdate, runStatus }: IProps): React.ReactElement => {
    const { isOpen, handleOpenModal, handleCloseModal } = useModal()
    const page = usePage()

    const { api } = useStores()
    const canUserEditRun =
      useCurrentPermissions(USER_PERMISSIONS.run, EDIT_PERMISSION_LEVEL) &&
      runStatus !== CASE_IN_RUN_STATUS.completed
    const id = page.init_state.object.id
    const { project_object: projectObject } = page.init_state
    const mutateCasesRef = useRef<{ handleMutate: () => void } | null>(null)
    const mutateSuitesRef = useRef<{ handleMutate: () => void } | null>(null)

    const emptyProject = {
      data: {
        id: 0,
        title: '',
        status: 'CREATED',
        references: '',
        description: '',
        assigned_to: null
      },
      project: projectObject.id,
      milestone:
        milestoneService.currentMilestone !== undefined &&
        milestoneService.currentMilestone.value !== 0
          ? {
              value: milestoneService.currentMilestone.value,
              label: milestoneService.currentMilestone.label
            }
          : undefined,
      files: [],
      cases: []
    }

    const getDefaultValues = async (): Promise<IFormInputs> => {
      if (id !== undefined) {
        const run = await api.getRun({ id })
        if (handleUpdate !== undefined) {
          handleUpdate()
        }
        const baseCases = await api.getBaseCasesInRun(id)
        const milestoneId = run.milestone?.id
        const assigned = run.assigned_to
        return {
          ...run,
          milestone:
            milestoneId !== undefined
              ? {
                  value: milestoneId,
                  label: run.milestone.title
                }
              : undefined,
          assigned_to: assigned?.id,
          files: run.files,
          cases: baseCases.results,
          project: projectObject.id
        }
      }
      return emptyProject
    }

    const {
      control,
      setError,
      handleSubmit,
      formState: { isSubmitting }
    } = useForm<IFormInputs>({
      defaultValues: id !== undefined ? getDefaultValues : emptyProject
    })

    const { fields, append, remove } = useFieldArray({
      control,
      name: 'cases',
      keyName: '_id'
    })

    const onSubmit: SubmitHandler<IFormInputs> = async (
      params
    ): Promise<void> => {
      try {
        await api.updateRun(id, params)
        handleCloseModal()
        if (mutateCasesRef.current !== null) {
          mutateCasesRef.current.handleMutate()
        }
        if (mutateSuitesRef.current !== null) {
          mutateSuitesRef.current.handleMutate()
        }
      } catch (error) {
        const errors = reactHookFormErrorFormater(params, error)
        errors.forEach((item) => {
          setError(item.key as keyof IFormInputs, {
            message: item.value
          })
        })
      }
    }

    return (
      <>
        <Suspense fallback={<Loading />}>
          <LayDndProvider>
            <Wrapper className={style.casessuites__wrap}>
              <div className={style.cases__top}>
                <Title type='h1' className={style.cases__title}>
                  <FormattedMessage id='cases.title' defaultMessage='Checks' />
                </Title>

                {canUserEditRun
                  ? (
                    <Button type='button' onClick={handleOpenModal}>
                      <Icon src='plus' slot='icon-left' />

                      <FormattedMessage
                        id='case.add_case'
                        defaultMessage='Add test case'
                      />
                    </Button>
                    )
                  : null}
              </div>

              <div className={style.casessuites}>
                <Suites ref={mutateSuitesRef} />

                <Cases ref={mutateCasesRef} updatePage={handleUpdate} />
              </div>
            </Wrapper>
            <BaseModal
              open={isOpen}
              onGx-after-hide={handleCloseModal}
              hideDefaultClose
              className={style.cases__modal}
              size='xl'
            >
              <Wrapper className={style.modal}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <div className={style.modal__head}>
                    <div className={style.modal__title}>
                      <Button onClick={handleCloseModal} theme='light' circle>
                        <Icon src='back' />
                      </Button>

                      <Title type='h2' className={style.modal__item}>
                        <FormattedMessage
                          id='case.add_case'
                          defaultMessage='Add Test Cases'
                        />
                      </Title>
                    </div>

                    <Button type='submit'>
                      <Icon src='checked' slot='icon-left' />

                      <FormattedMessage
                        id='common.save'
                        defaultMessage='Save'
                      />
                    </Button>
                  </div>

                  <AddCase
                    selectedCase={fields}
                    append={append}
                    remove={remove}
                  />
                </Form>

                {isSubmitting
                  ? (
                    <div className={style.modal__loader}>
                      <Loading />
                    </div>
                    )
                  : null}
              </Wrapper>
            </BaseModal>
          </LayDndProvider>
        </Suspense>
      </>
    )
  }
)

export default SuitesCasesDetail
