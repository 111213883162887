import React from 'react'
import style from './styles/button.module.scss'
import classNames from 'classnames'
import { GxButton } from '@garpix/garpix-web-components-react'
import { ITextButtonProps } from './interfaces'

const TextButton = ({
  children,
  size = 'med',
  className = '',
  href,
  ...props
}: ITextButtonProps): React.ReactElement => {
  return (
    <GxButton
      className={classNames({
        [style.text]: true,
        [style[`text--${size}`]]: size,
        [className]: Boolean(className)
      })}
      variant='text'
      size={size}
      href={href}
      {...props}
    >
      {children}
    </GxButton>
  )
}

export default TextButton
