import React, { useCallback, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import style from './styles/cases.module.scss'
import { useStores } from '@/hooks'
import Checkbox from '@/components/Checkbox/Checkbox'
import SearchInput from '@/components/Input/SearchInput/SearchInput'
import Button from '@/components/Button/Button'
import Icon from '@/components/Icon/Icon'
import Title from '@/components/Title'
import { ISuite, ISuiteExportItem } from '@/interfaces/Suites'
import { updateItemStates } from './CheckboxTree/Tree/updateItemStates'
import CheckboxList from './CheckboxTree/CheckboxList/CheckboxList'
import { downloadFile } from '@/utils'
import { CHECKBOX_STATE, ICheckboxState } from '@/interfaces/Checkbox'
import { LoaderExportCases } from './loaders'

interface IProps {
  listSuites: ISuite[]
  onClose: () => void
}

const ExportCases = ({ listSuites, onClose }: IProps): React.ReactElement => {
  const intl = useIntl()
  const store = useStores()
  const [search, setSearch] = useState('')
  const [selected, setSelected] = useState<ISuiteExportItem[]>([])
  const [isSelectAll, setSelectAll] = useState(false)

  const defaultItemStates: ICheckboxState[] = listSuites.map((el) => ({
    id: el.id,
    state: CHECKBOX_STATE.UNCHECKED
  }))

  const selectedAllItemStates: ICheckboxState[] = listSuites.map(
    (el) => ({
      id: el.id,
      state: CHECKBOX_STATE.CHECKED
    })
  )

  const [itemStates, setItemStates] =
    useState<ICheckboxState[]>(defaultItemStates)

  const getStateForId = useCallback(
    (id: number) => {
      const find = itemStates.find((i) => i.id === id)
      if (find !== undefined) return find.state
      else return -1
    },
    [itemStates]
  )

  const clickHandler = useCallback(
    (id: number) => {
      const index = selected.findIndex((item) => id === item.id)
      if (index === -1) {
        setSelected([...selected, { id: id }])
      } else {
        setSelected(selected.filter((item) => id !== item.id))
      }
      setItemStates(updateItemStates(itemStates, listSuites, id))
    },
    [itemStates]
  )

  const onExport = (): void => {
    store.api
      .exportCaseSuites(selected)
      .then((res) => {
        const date = new Date()
        const fileName = 'Cases_' + date.toJSON().slice(0, 10) + '.xlsx'
        downloadFile(res.data, fileName)
      })
      .then(() => onClose())
      .catch((error) => {
        console.log(error)
      })
  }

  const handleSelectAll = (): void => {
    if (isSelectAll) {
      setItemStates(defaultItemStates)
      setSelected([])
    } else {
      setItemStates(selectedAllItemStates)
      setSelected(
        listSuites.map((el) => ({
          id: el.id
        }))
      )
    }
    setSelectAll(!isSelectAll)
  }

  return (
    <>
      <div className={style.modal__head}>
        <Button onClick={onClose} theme='light' circle>
          <Icon src='back' />
        </Button>

        <Title type='h2' className={style.modal__title}>
          <FormattedMessage
            id='titles.export_excel'
            defaultMessage='Export to Excel'
          />
        </Title>

        <Button onClick={onExport}>
          <Icon src='download' slot='icon-left' />

          <FormattedMessage id='cases.export' defaultMessage='Export' />
        </Button>
      </div>

      <div className={style.modal}>
        <div className={style.modal__filters}>
          <SearchInput
            value={search}
            handleChange={setSearch}
            placeholder={intl.formatMessage({
              id: 'suites.search',
              defaultMessage: 'Search test suite'
            })}
            className={style.cases__search}
          />

          <Checkbox
            className={style.modal__checkbox}
            size='medium'
            onGx-change={handleSelectAll}
            checked={isSelectAll}
          >
            <FormattedMessage
              id='suites.checkbox'
              defaultMessage='All test cases'
            />
          </Checkbox>
        </div>

        <div className={style.modal__suites}>
          {listSuites !== undefined
            ? (
              <CheckboxList
                items={listSuites}
                onClick={clickHandler}
                getStateForId={getStateForId}
              />
              )
            : (
              <LoaderExportCases countShimmers={8} />
              )}
        </div>
      </div>
    </>
  )
}

export default ExportCases
