import React, { ReactElement } from 'react'
import { NodeModel } from '@minoru/react-dnd-treeview'
import Icon from '@/components/Icon/Icon'
import classNames from 'classnames'
import { TextTag } from '@/components/Tag'
import { observer } from 'mobx-react'
import { ISuite } from '@/interfaces/Suites'
import style from '../styles/update_cases.module.scss'
import { suiteService } from '../SuitesCasesDetail/service'

const LayDndTree = React.lazy(
  async () =>
    await import('@/components/DndTree').then(({ DndTree }) => ({
      default: DndTree
    }))
)

interface IProps {
  data: ISuite[]
}

const ClickZone = observer(
  ({ node, onToggle, isOpen, isDragging, hasChild }) => {
    const nodeId = Number(node.id)
    const suiteId = suiteService.getCurrentSuite().id

    const handleClick = (): void => {
      if (suiteId === nodeId) {
        suiteService.setCurrentSuite({ id: null, nodeTitle: node.text })
      } else {
        suiteService.setCurrentSuite({ id: nodeId, nodeTitle: node.text })
      }
    }

    return (
      <div className={style.dnd__item}>
        <button
          type='button'
          onClick={handleClick}
          className={classNames({
            [style.dnd__titlebtn]: true,
            [style['dnd__titlebtn--opened']]: nodeId === suiteId
          })}
        >
          <Icon src='folders' />

          <div>{node.text}</div>
        </button>

        <div className={style.dnd__right}>
          <TextTag>
            <Icon src='test_case' />

            {node.data.casesCount}
          </TextTag>

          {hasChild === true
            ? (
              <button
                type='button'
                onClick={onToggle}
                className={style.dnd__openbtn}
              >
                <Icon
                  src='arrow_down'
                  className={classNames({
                    [style.dnd__arrow]: true,
                    [style.dnd__arrow_open]: isOpen
                  })}
                />
              </button>
              )
            : null}
        </div>
      </div>
    )
  }
)

const render = (
  node: NodeModel<any>,
  {
    depth,
    isOpen,
    isDragging,
    hasChild,
    onToggle
  }: {
    depth: number
    isOpen: boolean
    isDragging: boolean
    onToggle: () => void
    hasChild: boolean
  }
): ReactElement => {
  return (
    <div className={style.dnd}>
      {node.droppable !== undefined
        ? (
          <ClickZone
            isOpen={isOpen}
            node={node}
            onToggle={onToggle}
            isDragging={false}
            hasChild={hasChild}
          />
          )
        : null}
    </div>
  )
}
const SuitesDndList = ({ data = [] }: IProps): React.ReactElement => {
  interface IData {
    casesCount: number
    sort: number
  }

  interface IFormatData {
    id: number
    parent: number
    droppable: boolean
    text: string
    data: IData
  }

  const formatData: IFormatData[] = data.map((element) => {
    const isHasParent = data.findIndex(el => el.id === element.parent) !== -1

    return {
      id: element.id,
      parent: element.parent === null || !isHasParent ? 0 : element.parent,
      droppable: false,
      text: element.title,
      data: { casesCount: element.cases_count, sort: element.sort }
    }
  })

  return (
    <LayDndTree
      canDrop={false}
      render={(node, params) => {
        const newParams = { ...params }
        return render(node, newParams)
      }}
      data={formatData}
      nodeArray
    />
  )
}

export default SuitesDndList
