import React from 'react'
import style from './styles/loading.module.scss'
import classNames from 'classnames'
import { Shimmer } from '@/components/Loading'

interface IProps {
  countShimmers: number
  className?: string
}

const LoaderCompany = ({ countShimmers, className = '' }: IProps): React.ReactElement => {
  const shimmers = Array.from({ length: countShimmers }, (_, index) => (
    <Shimmer key={index} size='xl' />
  ))

  return (
    <div
      className={classNames({
        [style.loading]: true,
        [className]: Boolean(className)
      })}
    >
      {shimmers}
    </div>
  )
}

export default LoaderCompany
