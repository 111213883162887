import React, { useEffect, useState } from 'react'
import style from './styles/caseslist.module.scss'
import { ICase } from '@/interfaces/Case'
import CaseItem from '@/components/CaseItem'
import Pagination from '@/components/Pagination'
import PageSizeSelect from '@/components/Select/PageSizeSelect'
import DraggableWrapper from '@/components/DraggableWrapper'
import { useCurrentPermissions } from '@/hooks/useCurrentPermissions'
import { useStores } from '@/hooks'
import { EDIT_PERMISSION_LEVEL, USER_PERMISSIONS } from '@/const'
import { TCasesSort } from '@/interfaces/Types'

interface IProps {
  currentPage: number
  pageSize: number
  setPage: (string) => void
  setPageSize: (e: any) => void
  initialCases: ICase[]
  casesTotal: number
  casesSort: TCasesSort
}

const CasesList = ({
  currentPage,
  pageSize,
  setPage,
  setPageSize,
  initialCases,
  casesTotal,
  casesSort
}: IProps): React.ReactElement => {
  const [cases, setCases] = useState<ICase[]>(initialCases)

  const canUserUseDnd = useCurrentPermissions(
    USER_PERMISSIONS.cases,
    EDIT_PERMISSION_LEVEL
  )

  const canUserCaseApprove = useCurrentPermissions(
    USER_PERMISSIONS.case_approve,
    EDIT_PERMISSION_LEVEL
  )

  const canUserCaseEdit = useCurrentPermissions(
    USER_PERMISSIONS.case,
    EDIT_PERMISSION_LEVEL
  )

  const { api } = useStores()

  const handleDragEnd = async (
    cases: ICase[],
    caseId: ICase['id']
  ): Promise<void> => {
    const newSortOrder = cases.map((c) => c.id)

    const caseToEdit = cases.find((el) => el.id === caseId)

    if (caseToEdit !== undefined) {
      if (casesSort === '-sort') {
        caseToEdit.sort =
          newSortOrder.length - newSortOrder.indexOf(caseId) - 1
      }
      setCases(cases)
      await api.patchCase({ id: caseToEdit.id, sort: caseToEdit.sort })
    }
  }
  useEffect(() => {
    setCases(initialCases)
  }, [initialCases])

  return (
    <>
      <div className={style.caseslist__list}>
        <DraggableWrapper<ICase>
          disabled={!canUserUseDnd}
          items={cases}
          handleDragEnd={handleDragEnd}
        >
          {cases.map((el) => (
            <CaseItem
              link={el.absolute_url}
              key={el.id}
              title={el.title}
              priority={el.priority}
              id={el.id}
              code={el.code}
              status={el.status}
              editUrl={el.edit_absolute_url ?? ''}
              stepsCount={el.steps_count}
              caseType={el.case_type}
              showDropdown={canUserCaseApprove || canUserCaseEdit}
              isDraggable
            />
          ))}
        </DraggableWrapper>
      </div>

      <div className={style.caseslist__pagination}>
        <PageSizeSelect
          pageSizeOptions={[100, 150, 200]}
          value={String(pageSize)}
          handleChange={setPageSize}
          isScrollTop
        />

        <Pagination
          currentPage={currentPage}
          total={casesTotal}
          pageSize={pageSize}
          handleChange={setPage}
        />
      </div>
    </>
  )
}

export default CasesList
