import React, { useState } from 'react'
import { IFilePreview, IUploadZoneProps } from './interfaces'
import UploadZone from './UploadZone'

const UploadZoneApi = ({
  className = '',
  onChange,
  isScrollableList = false,
  initFiles = [],
  enableReinitialize = false,
  onDelete,
  isUpload,
  canUserDeleteFile,
  readonly
}: IUploadZoneProps): React.ReactElement => {
  const [files, setFiles] = useState<File[]>([])

  const [currentFile, setCurrentFile] =
    useState<Partial<IFilePreview> | null>(null)

  const handleDeleteFile = async (): Promise<void> => {
    onDelete?.()
  }

  return (
    <UploadZone
      className={className}
      initFiles={initFiles}
      isScrollableList={isScrollableList}
      enableReinitialize={enableReinitialize}
      isUpload={isUpload}
      canUserDeleteFile={canUserDeleteFile}
      onChange={onChange}
      files={files}
      setFiles={setFiles}
      currentFile={currentFile}
      setCurrentFile={setCurrentFile}
      handleDeleteFile={handleDeleteFile}
      isForm={false}
      readonly={readonly}
    />
  )
}

export default UploadZoneApi
