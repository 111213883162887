import { ModalDelete } from '@/components/Modal'
import Title from '@/components/Title'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import style from './styles/upload_zone.module.scss'

interface IProps {
  onCancel: () => void
  onDelete: () => void
  disableSubmit: boolean
  fileName: string
}

const DeleteFile = ({
  onCancel,
  onDelete,
  disableSubmit,
  fileName
}: IProps): React.ReactElement => {
  return (
    <ModalDelete
      onCancel={onCancel}
      onDelete={onDelete}
      disableSubmit={disableSubmit}
    >
      <Title type='h2' theme='h1' className={style.delete__title}>
        <FormattedMessage
          id='upload_zone.delete file'
          defaultMessage='Do you really want to delete the file {fileName}?'
          values={{ fileName }}
        />
      </Title>
    </ModalDelete>
  )
}

export default DeleteFile
