import React from 'react'
import { Shimmer, ShimmerWrap } from '@/components/Loading'

const LoaderFiles = (): React.ReactElement => {
  return (
    <ShimmerWrap>
      <Shimmer />
    </ShimmerWrap>
  )
}

export default LoaderFiles
