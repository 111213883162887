import { IGlobalConfig } from '@/interfaces/ObjectPage'
import { usePage as usePageCms } from '@garpix/cms'

const useGlobalConfig = (): IGlobalConfig => {
  const value = usePageCms()
  const page = value.page
  return page.init_state.global
}

export default useGlobalConfig
