import React from 'react'
import style from '../styles/projects.module.scss'
import Pagination from '@/components/Pagination'
import PaginatedQueryResult from '@/api/interfaces/PaginatedQueryResult'
import { IProjectElement } from '@/interfaces/Projects'
import { ProjectsItem } from '@/components/ProjectsItem'

interface IProps {
  data: PaginatedQueryResult<IProjectElement>
  currentPage: number
  pageSize: number
  setPage: (string) => void
}

const ProjectsList = ({
  data,
  currentPage,
  pageSize,
  setPage
}: IProps): React.ReactElement => {
  return (
    <>
      <div className={style.projects__list}>
        {data.results.map((el) => {
          return (
            <ProjectsItem
              key={el.id}
              id={el.id}
              code={el.code}
              title={el.title}
              absoluteUrl={el.absolute_url}
              image={el.image}
              users={el.users}
              milestonesCount={el.milestones_count}
              casesCount={el.cases_count}
              runsCount={el.runs_count}
              isFavorite={el.is_favourite}
            />
          )
        })}
      </div>

      <div className={style.projects__pagination}>
        <Pagination
          currentPage={currentPage}
          total={data.count}
          pageSize={pageSize}
          handleChange={setPage}
        />
      </div>
    </>
  )
}

export default ProjectsList
