import { Accordion } from '@/components/Accordion'
import Title from '@/components/Title'
import Wrapper from '@/components/Wrapper'
import { IAttachment } from '@/interfaces/Attachment'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { UploadZoneApi } from '@/components/UploadZone'
import { useCurrentPermissions } from '@/hooks/useCurrentPermissions'
import { EDIT_PERMISSION_LEVEL, USER_PERMISSIONS } from '@/const'
import style from '../styles/case_detail.module.scss'

interface IProps {
  initFiles: IAttachment[]
  onChange: (e: File[]) => void
  onDelete?: () => void
}

const AccordionHead = ({ count }: { count: number }): React.ReactElement => {
  return (
    <Title type='h2' className={style.files__title}>
      <FormattedMessage id='titles.files' defaultMessage='Files' />
      {` (${count})`}
    </Title>
  )
}

const Files = ({
  onChange,
  initFiles,
  onDelete
}: IProps): React.ReactElement => {
  const canUserDeleteFile = useCurrentPermissions(
    USER_PERMISSIONS.case,
    EDIT_PERMISSION_LEVEL
  )

  return (
    <Wrapper className={style.files}>
      <Accordion
        head={<AccordionHead count={initFiles.length} />}
        defaultOpened={false}
      >
        <UploadZoneApi
          enableReinitialize
          initFiles={initFiles}
          onChange={onChange}
          onDelete={onDelete}
          canUserDeleteFile={canUserDeleteFile}
        />
      </Accordion>
    </Wrapper>
  )
}

export default Files
