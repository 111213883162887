import Button from '@/components/Button/Button'
import { PieChart } from '@/components/Charts'
import EmptyList from '@/components/EmptyList/EmptyList'
import GroupFullStatuses from '@/components/GroupStatuses/GroupFullStatuses'
import Icon from '@/components/Icon/Icon'
import Wrapper from '@/components/Wrapper'
import { PIE_CHART_BACKGROUND_COLORS, PIE_CHART_BORDER_COLORS } from '@/const'
import { IRunsElement } from '@/interfaces/Runs'
import React, { useRef } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import style from '../styles/run_detail.module.scss'
import { downloadBase64File } from '@/utils'

interface IProps {
  run: IRunsElement
}

const PieChartBlock = ({ run }: IProps): React.ReactElement => {
  const intl = useIntl()
  const chartRef = useRef()

  const saveChart = (): void => {
    const chart = chartRef.current
    // @ts-expect-error
    const img = chart.toBase64Image()
    downloadBase64File(img, 'chart.png')
  }

  return (
    <Wrapper className={style.chart}>
      {run !== undefined
        ? (
          <div className={style.chart__content}>
            <div className={style.chart__pie}>
              <PieChart
                data={{
                  labels: [
                  `${intl.formatMessage({
                    id: 'status.test.blocked',
                    defaultMessage: 'Blocked'
                  })}`,
                  `${intl.formatMessage({
                    id: 'status.test.failed',
                    defaultMessage: 'Failed'
                  })}`,
                  `${intl.formatMessage({
                    id: 'status.test.passed',
                    defaultMessage: 'Passed'
                  })}`,
                  `${intl.formatMessage({
                    id: 'status.test.retest',
                    defaultMessage: 'Retest'
                  })}`,
                  `${intl.formatMessage({
                    id: 'status.test.untested',
                    defaultMessage: 'Untested'
                  })}`
                  ],
                  datasets: [
                    {
                      data: [
                        run.statistics.blocked,
                        run.statistics.failed,
                        run.statistics.passed,
                        run.statistics.retest,
                        run.statistics.untested
                      ],
                      backgroundColor: PIE_CHART_BACKGROUND_COLORS,
                      borderColor: PIE_CHART_BORDER_COLORS,
                      borderWidth: 1
                    }
                  ]
                }}
                ref={chartRef}
              />
            </div>

            <div className={style.chart__desc}>
              <Button
                theme='light'
                size='lg'
                className={style.chart__button}
                onClick={saveChart}
              >
                <Icon src='download' slot='icon-left' />

                <FormattedMessage
                  id='project.dowmload_chart'
                  defaultMessage='Download chart'
                />
              </Button>

              <GroupFullStatuses statistic={run.statistics} />
            </div>
          </div>
          )
        : (
          <EmptyList
            icon='run'
            text={intl.formatMessage({
              id: 'runs.no_runs',
              defaultMessage: 'No test runs has been found'
            })}
            buttonText={intl.formatMessage({
              id: 'runs.add_run',
              defaultMessage: 'Add run'
            })}
            className={style.chart__empty}
          />
          )}
    </Wrapper>
  )
}

export default PieChartBlock
