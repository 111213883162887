import React from 'react'
import classNames from 'classnames'
import { useIntl } from 'react-intl'
import style from './styles/status.module.scss'
import { THistoryStatus } from '@/interfaces/Types'

interface IProps {
  status: THistoryStatus
  className?: string
}

const HistoryStatus = ({
  status,
  className = ''
}: IProps): React.ReactElement => {
  const intl = useIntl()

  const defaultMessage = status.charAt(0).toUpperCase() + status.slice(1)

  const statusText = intl.formatMessage({
    id: `status.history.${status}`,
    defaultMessage
  })

  return (
    <div
      className={classNames({
        [style.history]: true,
        [style[`history--${status}`]]: true,
        [className]: Boolean(className)
      })}
    >
      {statusText}
    </div>
  )
}

export default HistoryStatus
