import { COMPANY_ROLES, COMPANY_ROLE_ICON } from '@/const'
import { TCompanyRole } from '@/interfaces/Types'
import classNames from 'classnames'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import Icon from '../Icon/Icon'
import style from './styles/user.module.scss'

interface IProps {
  role: TCompanyRole
  className?: string
}

const UserCompanyRole = ({
  role,
  className = ''
}: IProps): React.ReactElement => {
  return (
    <div
      className={classNames({
        [style.role]: true,
        [style['role--company']]: true,
        [className]: Boolean(className)
      })}
    >
      {role === COMPANY_ROLES.owner
        ? (
          <Icon src={COMPANY_ROLE_ICON[role]} />
          )
        : null}
      <FormattedMessage id={`company.roles.${role}`} defaultMessage='Employee' />
    </div>
  )
}

export default UserCompanyRole
