import React, { useState } from 'react'
import NewPassword from './NewPassword'
import Success from './SuccessPassword'
import SendCode from './SendCode'
import BaseChangePassword from './BaseChangePassword'
import { MODAL_STAGE } from '@/const'

interface IProps {
  onClose: () => void
  username: string
  email: string
}

const ChangePassword = ({
  onClose,
  username,
  email
}: IProps): React.ReactElement => {
  const [stage, setStage] = useState<number>(MODAL_STAGE.first)
  const [code, setCode] = useState('')

  const StateMachineNext = {
    1: () => MODAL_STAGE.second,
    2: () => MODAL_STAGE.third,
    3: () => MODAL_STAGE.fourth
  }

  const handleNext = (): void => {
    setStage(StateMachineNext[String(stage)]())
  }

  const handleSuccess = (): void => {
    setStage(MODAL_STAGE.fourth)
  }
  return (
    <>
      {stage === MODAL_STAGE.first
        ? (
          <BaseChangePassword
            onClose={onClose}
            success={handleSuccess}
            nextModal={handleNext}
          />
          )
        : null}
      {stage === MODAL_STAGE.second
        ? (
          <SendCode
            email={email}
            username={username}
            code={code}
            setCode={setCode}
            success={handleNext}
            onClose={onClose}
          />
          )
        : null}
      {stage === MODAL_STAGE.third
        ? (
          <NewPassword
            email={email}
            code={code}
            onClose={onClose}
            success={handleNext}
          />
          )
        : null}

      {stage === MODAL_STAGE.fourth ? <Success onClose={onClose} /> : null}
    </>
  )
}

export default ChangePassword
