import React from 'react'
import style from './styles/notification_item.module.scss'
import { FormattedMessage } from 'react-intl'
import { INotifiesProfileFillData } from '@/interfaces/Notifies'
import { ITimeSince } from '@/interfaces/Utils'
import NotifyCircle from './components/NotifyCircle'
import { LinkButton } from '../Button/LinkButton'
import Icon from '../Icon/Icon'
import NotifyTime from './components/NotifyTime'

interface IProps {
  data: INotifiesProfileFillData
  time: ITimeSince
}

const NotifyProfileFill = ({ data, time }: IProps): React.ReactElement => {
  const { profile_url: profileUrl } = data

  return (
    <>
      <NotifyCircle />

      <div className={style.item__content}>
        <p className={style.text}>
          <FormattedMessage
            id='notifications.item.fill_profile'
            defaultMessage='Fill in the required data in the profile'
          />
        </p>

        <LinkButton href={profileUrl}>
          <Icon src='user' slot='icon-left' />

          <FormattedMessage id='common.profile' defaultMessage='Profile' />
        </LinkButton>

        <NotifyTime time={time} />
      </div>
    </>
  )
}

export default NotifyProfileFill
