import { ModalDelete } from '@/components/Modal'
import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import style from '../styles/project_roles.module.scss'
import Title from '@/components/Title'
import { useObjectPage } from '@/hooks'
import Input from '@/components/Input/Input'

interface IProps {
  onCancel: () => void
  onDelete: () => void
}

const ResetRole = ({ onCancel, onDelete }: IProps): React.ReactElement => {
  const { title } = useObjectPage()
  const [value, setValue] = useState('')

  const intl = useIntl()

  const handleChange = (e): void => {
    setValue(e.target.value)
  }

  return (
    <ModalDelete
      onCancel={onCancel}
      onDelete={onDelete}
      disableSubmit={title !== value}
      icon='broom'
      confirmText={intl.formatMessage({
        id: 'common.reset',
        defaultMessage: 'Reset'
      })}
    >
      <Title type='h2' theme='h1' className={style.delete__title}>
        <FormattedMessage
          id='roles.reset.title'
          defaultMessage='Do you really want to reset custom  roles of the project '
        />
        <span className={style.delete__name}>{title}</span>?
      </Title>

      <p className={style.delete__text}>
        <FormattedMessage
          id='roles.reset.confirm'
          defaultMessage='All users with custom roles will get the new role of “Developer”. Please enter a project name to confirm action.'
        />
      </p>

      <Input onChange={handleChange} type='text' value={value} />
    </ModalDelete>
  )
}

export default ResetRole
