import React from 'react'
import Login from '@/apps/Login/Login'
import AuthRegLayout from '../layout/components/AuthRegLayout'
import { loginImage } from '@/images'
import YandexMetrika from '@/components/YandexMetrika'

const ErrorPage401 = (): React.ReactElement => {
  return (
    <>
      <YandexMetrika />
      <AuthRegLayout image={loginImage}>
        <Login isReloading />
      </AuthRegLayout>
    </>
  )
}

export default ErrorPage401
