import useSWR from 'swr'
import SearchInput from '@/components/Input/SearchInput/SearchInput'
import Pagination from '@/components/Pagination'
import ReportsItem from '@/components/ReportsItem'
import SharedMilestoneSelect from '@/components/Select/MilestoneSelect/SharedMilestoneSelect'
import Title from '@/components/Title'
import Wrapper from '@/components/Wrapper'
import { usePage, useStores } from '@/hooks'
import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { EmptySearch } from '@/components/EmptyResults'
import useDebounce from '@/hooks/useDebounce'
import { scrollTop } from '@/utils'
import style from './styles/reports.module.scss'
import { LoaderRunsReport } from './loaders'

const pageSize = 10

const RunsReports = (): React.ReactElement => {
  const store = useStores()
  const intl = useIntl()
  const { init_state: initState } = usePage()

  const shareHash = initState.share_hash

  const [pageNumber, setPageNumber] = useState(1)
  const [milestone, setMilestone] = useState<number | undefined>()
  const [search, setSearch] = useState<string>('')

  const debouncedSearch = useDebounce(search)

  const { data, isLoading } = useSWR(
    {
      share_hash: shareHash,
      milestone,
      page: pageNumber,
      page_size: pageSize,
      q: debouncedSearch,
      _key: 'getShareReports'
    },
    store.api.getShareReports,
    {
      revalidateOnFocus: false,
      onSuccess: () => {
        scrollTop()
      }
    }
  )

  const handleSearch = (value: string): void => {
    setSearch(value)
    setPageNumber(1)
  }

  const handleChangeSelect = (e): void => {
    if (e !== null) {
      setMilestone(e.value)
    } else {
      setMilestone(undefined)
    }
    setPageNumber(1)
  }

  return (
    <Wrapper className={style.reports}>
      <div className={style.reports__titlewrap}>
        <Title type='h2' theme='h1' className={style.reports__title}>
          <FormattedMessage
            id='shared.titles.reports'
            defaultMessage='Reports on the project {name}'
            values={{ name: initState.project_title }}
          />
        </Title>
      </div>

      <div className={style.reports__top}>
        <SharedMilestoneSelect
          handleChange={handleChangeSelect}
          selectClassName={style.reports__select}
          labelClassName={style.reports__select_label}
          isClearable
          shareHash={shareHash}
        />

        <SearchInput
          handleChange={handleSearch}
          value={search}
          className={style.reports__search}
          placeholder={intl.formatMessage({
            id: 'runs.search',
            defaultMessage: 'Search runs'
          })}
        />
      </div>

      <div className={style.reports__results}>
        {!isLoading
          ? data?.results.map((el) => {
            return (
              <ReportsItem
                key={el.id}
                color={el.color}
                code={el.code}
                title={el.title}
                absoluteUrl={el.absolute_url}
                statistics={el.statistics}
              />
            )
          })
          : null}

        {!isLoading && data?.results.length === 0 ? <EmptySearch /> : null}

        {isLoading ? <LoaderRunsReport countShimmers={pageSize} /> : null}
      </div>

      <div className={style.reports__pagination}>
        <Pagination
          currentPage={pageNumber}
          total={data?.count ?? 0}
          pageSize={pageSize}
          handleChange={setPageNumber}
        />
      </div>
    </Wrapper>
  )
}

export default RunsReports
