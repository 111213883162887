import React, { forwardRef } from 'react'
import { GxForm } from '@garpix/garpix-web-components-react'

const FormBase = ({ onSubmit, children, className = '' }, ref): React.ReactElement => {
  const onGxSubmit = (e): void => {
    onSubmit(e)
  }
  return (
    <GxForm ref={ref} onGx-submit={onGxSubmit} className={className}>
      {children}
    </GxForm>
  )
}

const Form = forwardRef(FormBase)

export {
  Form
}
