import React, { useEffect, useState } from 'react'
import style from './styles/members.module.scss'
import Wrapper from '@/components/Wrapper'
import ListContent from '@/components/ListContent'
import { useObjectPage, usePage, useStores } from '@/hooks'
import useDebounce from '@/hooks/useDebounce'
import { FormattedMessage, useIntl } from 'react-intl'
import useSWR from 'swr'
import { scrollTop } from '@/utils'
import Title from '@/components/Title'
import SearchInput from '@/components/Input/SearchInput/SearchInput'
import Button from '@/components/Button/Button'
import Icon from '@/components/Icon/Icon'
import UserList from './components/UserList'
import RoleCompanySelect from '@/components/Select/RoleCompanySelect'
import { COMPANY_ROLES, MODALS_CONTENTS } from '@/const'
import { observer } from 'mobx-react'

const CompanyMembers = observer((): React.ReactElement => {
  const { api, modalStore } = useStores()
  const { id: idCompany } = useObjectPage()
  const obj = usePage()
  const [search, setSearch] = useState('')
  const [currentRole, setCurrentRole] = useState<number | ''>('')
  const debouncedSearch = useDebounce(search)
  const [page, setPage] = useState(1)
  const pageSize = 10
  const intl = useIntl()
  const isAdmin =
    obj.init_state.current_user_role === COMPANY_ROLES.admin ||
    obj.init_state.current_user_role === COMPANY_ROLES.owner
  const isOwner = obj.init_state.current_user_role === COMPANY_ROLES.owner
  const buttonText = isAdmin
    ? intl.formatMessage({
      id: 'company.members.button',
      defaultMessage: 'Add users'
    })
    : undefined

  const { data, error, isLoading, mutate } = useSWR(
    {
      page: page,
      page_size: pageSize,
      company_pk: idCompany,
      q: debouncedSearch,
      is_blocked: false,
      role: currentRole ?? undefined,
      _key: 'getCompanyUsers'
    },
    api.getCompanyUsers,
    {
      onSuccess: () => {
        scrollTop()
      }
    }
  )

  const handleMutate = async (): Promise<void> => {
    await mutate()
  }

  const handleSearch = (value: string): void => {
    setSearch(value)
    setPage(1)
  }

  const handleClickAddUser = (): void => {
    modalStore.open(MODALS_CONTENTS.addUserCompany, {
      idCompany: idCompany
    })
  }
  const handleChangeRole = (e): void => {
    if (e.target.value !== '') {
      setCurrentRole(Number(e.target.value))
    } else {
      setCurrentRole('')
    }
  }

  useEffect(() => {
    handleMutate().catch((err) => console.log('err', err))
  }, [modalStore.currentModal])

  return (
    <Wrapper className={style.members}>
      <div className={style.members__top}>
        <div className={style.members__left}>
          <Title type='h1' className={style.members__title}>
            <FormattedMessage
              id='company.members.title'
              defaultMessage='Users'
            />
          </Title>
        </div>

        {isAdmin && (
          <Button onClick={handleClickAddUser}>
            <Icon src='plus' slot='icon-left' />

            <FormattedMessage
              id='company.members.button'
              defaultMessage='Add user'
            />
          </Button>
        )}
      </div>

      <div className={style.members__filters}>
        <RoleCompanySelect
          value={String(currentRole)}
          handleChange={handleChangeRole}
        />

        <SearchInput
          handleChange={handleSearch}
          value={search}
          className={style.members__search}
          placeholder={intl.formatMessage({
            id: 'company.members.search',
            defaultMessage: 'Search user'
          })}
        />
      </div>

      <ListContent
        isLoading={isLoading}
        error={error}
        hasData={data !== undefined && data?.count > 0}
        emptyListIcon='folder'
        emptyListText={intl.formatMessage({
          id: 'company.members.no_search',
          defaultMessage: 'No users has been found'
        })}
        emptyListButton={buttonText}
        onClick={handleClickAddUser}
      >
        {data !== undefined
          ? (
            <UserList
              data={data}
              currentPage={page}
              pageSize={pageSize}
              setPage={setPage}
              idCompany={idCompany}
              handleMutate={handleMutate}
              isAdmin={isAdmin}
              isOwner={isOwner}
            />
            )
          : null}
      </ListContent>
    </Wrapper>
  )
})

export default CompanyMembers
