import style from '../style/profile.module.scss'
import React, { useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Input from '@/components/Input/Input'
import Icon from '@/components/Icon/Icon'
import TextButton from '@/components/Button/TextButton'
import Button from '@/components/Button/Button'
import { useStores } from '@/hooks'
import { Controller, useForm } from 'react-hook-form'
import { reactHookFormErrorFormater } from '@/utils'
import { Form } from '@/components/Form'
import Timer from '@/components/Timer'
import { VERIFY_TYPES } from '@/const'

interface IProps {
  code: string
  username: string
  setCode: (value) => void
  onClose: () => void
  success: () => void
  email: string
}

export interface IFormInputs {
  username?: string
  restore_password_confirm_code?: string
  non_field_errors?: string
}

const SendCode = ({
  email,
  code,
  setCode,
  onClose,
  success
}: IProps): React.ReactElement => {
  const intl = useIntl()
  const { api } = useStores()
  const [seconds, setSeconds] = React.useState(0)

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting }
  } = useForm<IFormInputs>({
    defaultValues: {
      username: email,
      restore_password_confirm_code: ''
    }
  })

  const handleResendCode = async (): Promise<void> => {
    setSeconds(60)
    try {
      await api.sendCodeRestorePassword({ username: email })
    } catch (error) {
      if (error.response.data === undefined) {
        setError('non_field_errors', {
          type: '400',
          message: intl.formatMessage({
            id: 'error.server',
            defaultMessage: 'Server error'
          })
        })
      }
    }
  }

  const onSubmit = async (params): Promise<void> => {
    try {
      await api.checkCodeRestorePassword({
        restore_password_confirm_code: code,
        username: email
      })
      success()
    } catch (error) {
      const errors = reactHookFormErrorFormater(params, error)
      errors.forEach((item) => {
        setError(item.key as keyof IFormInputs, {
          message: item.value
        })
      })
    }
  }

  useEffect(() => {
    void handleResendCode()
  }, [])

  const nonFieldErrors = errors?.non_field_errors?.message ?? ''

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className={style.modal__top}>
        <FormattedMessage
          id='profile.change_password'
          defaultMessage='Change password'
        />

        <Button onClick={onClose} theme='light' circle>
          <Icon src='clear' />
        </Button>
      </div>

      {email !== undefined
        ? (
          <>
            <div className={style.modal__middle}>
              <FormattedMessage
                id='profile.forgot_pass.text'
                defaultMessage='We just sent you a confirmation code over to {email}.'
                values={{ email }}
              />

              <Controller
                control={control}
                name='restore_password_confirm_code'
                render={({ field: { onChange, value } }) => {
                  return (
                    <Input
                      type='text'
                      label={intl.formatMessage({
                        id: 'profile.forgot_pass.code',
                        defaultMessage: 'Enter code'
                      })}
                      required
                      className={style.modal__input}
                      value={value}
                      onChange={(e) => {
                        setCode(e.target.value)
                        onChange(e.target.value)
                      }}
                      error={errors?.restore_password_confirm_code?.message ?? ''}
                    />
                  )
                }}
              />

              {seconds === 0
                ? (
                  <TextButton onClick={handleResendCode}>
                    <FormattedMessage
                      id='profile.resend.code'
                      defaultMessage='Resend code'
                    />
                  </TextButton>
                  )
                : (
                  <Timer
                    type={VERIFY_TYPES.Code}
                    setSeconds={setSeconds}
                    seconds={seconds}
                  />
                  )}

              {nonFieldErrors !== ''
                ? (
                  <div className={style.modal__error}>{nonFieldErrors}</div>
                  )
                : null}
            </div>

            <div className={style.modal__bottom}>
              <Button disabled={isSubmitting} type='submit'>
                <Icon src='checked' slot='icon-left' />

                <FormattedMessage
                  id='profile.forgot_pass.confirm'
                  defaultMessage='Confirm'
                />
              </Button>
            </div>
          </>
          )
        : (
          <div className={style.modal__block}>
            <FormattedMessage
              id='profile.forgot_pass.no_email'
              defaultMessage='Your email has not been confirmed'
            />
          </div>
          )}
    </Form>
  )
}

export default SendCode
