import { GxMenuItem } from '@garpix/garpix-web-components-react'
import classNames from 'classnames'
import React from 'react'
import Icon from '../Icon/Icon'
import style from './styles/item.module.scss'

const MenuItem = ({
  children,
  value,
  className = '',
  multiple = false,
  ...props
}): React.ReactElement => {
  return (
    <GxMenuItem
      value={value}
      className={classNames({
        [style.default]: true,
        [className]: Boolean(className)
      })}
      {...props}
    >
      {multiple
        ? (
          <span slot='prefix' className={style.check}>
            <Icon src='checked' size='small' className={style.check__icon} />
          </span>
          )
        : null}
      {children}
    </GxMenuItem>
  )
}

export default MenuItem
