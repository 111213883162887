import { GxSelect } from '@garpix/garpix-web-components-react'
import classNames from 'classnames'
import React, { forwardRef } from 'react'
import Icon from '../Icon/Icon'
import { SelectProps } from './interfaces/SelectProps'
import style from './styles/select.module.scss'

const SelectBase = (
  {
    onBlur,
    onChange,
    children,
    className = '',
    label,
    required = false,
    error = '',
    placeholder = '',
    labelLeft = false,
    multiple = false,
    ...props
  }: SelectProps,
  ref
): React.ReactElement => {
  const newChildrenProps = {
    multiple: multiple
  }
  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, newChildrenProps)
    } else {
      return null
    }
  })
  return (
    <GxSelect
      ref={ref}
      label={label}
      required={required}
      distance={10}
      onGx-blur={onBlur}
      onGx-change={onChange}
      className={classNames({
        [style.default]: true,
        [style.error]: Boolean(error),
        [style.label_left]: labelLeft,
        [className]: Boolean(className)
      })}
      pill
      placeholder={placeholder}
      multiple={multiple}
      hoist
      {...props}
    >
      {/* {children} */}
      {childrenWithProps}
      <Icon src='arrow_down' slot='icon' />
      <Icon src='clear' slot='clear-icon' />
      {error !== ''
        ? (
          <>
            <Icon src='error' slot='suffix' className={style.error__icon} />
            <div slot='help-text' className={style.error__text}>
              {error.toString()}
            </div>
          </>
          )
        : undefined}
    </GxSelect>
  )
}

const Select = forwardRef(SelectBase)

export default Select
