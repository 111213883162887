import React from 'react'
import Title from '@/components/Title'
import { FormattedMessage } from 'react-intl'
import Button from '@/components/Button/Button'
import Icon from '@/components/Icon/Icon'
import style from '../styles/project.module.scss'

interface IProps {
  handleCloseModal: () => void
}

const SelectCompanyHeader = ({
  handleCloseModal
}: IProps): React.ReactElement => {
  return (
    <div className={style.modal__head}>
      <Title type='h2' theme='h1' className={style.modal__head__title}>
        <FormattedMessage
          id='project.add_project_to'
          defaultMessage='Add project to'
        />
      </Title>

      <Button
        theme='light' circle
        onClick={handleCloseModal}
      >
        <Icon src='clear' />
      </Button>
    </div>
  )
}

export default SelectCompanyHeader
