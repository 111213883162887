import React from 'react'
import { FormattedMessage } from 'react-intl'
import Button from '../Button/Button'
import Dropdown from '../Dropdown/Dropdown'
import DropdownMenu from '../Dropdown/DropdownMenu'
import ColorPicker from './ColorPicker'
import style from './styles/picker.module.scss'

interface IProps {
  className?: string
  color: string
  onChange: (newColor: string) => void
}

const ColorPickerDropdown = ({
  className = '',
  color,
  onChange
}: IProps): React.ReactElement => {
  return (
    <div className={className}>
      <Dropdown className={style.droppicker__drop}>
        <Button theme='light' size='lg' slot='trigger'>
          <div
            className={style.droppicker__color}
            slot='icon-left'
            style={{ backgroundColor: color }}
          />
          <FormattedMessage
            id='color_picker.pick_color'
            defaultMessage='Pick color'
          />
        </Button>
        <DropdownMenu>
          <ColorPicker color={color} onChange={onChange} />
        </DropdownMenu>
      </Dropdown>
    </div>
  )
}

export default ColorPickerDropdown
