import { TTargetValue } from '@/interfaces/Types'
import classNames from 'classnames'
import React from 'react'
import { useIntl } from 'react-intl'
import Button from '../Button/Button'
import Icon from '../Icon/Icon'
import Link from '../Link'
import style from './styles/member.module.scss'
import Select from '@/components/Select/Select'
import MenuItem from '@/components/MenuItem/MenuItem'
import { IProjectRole, IProjectRoleShort } from '@/interfaces/Member'
import { useStores } from '@/hooks'
import { UserRole } from '@/components/User'
import { getInitials } from '../UserName'
import AvatarUser from '../Avatar/AvatarUser'
import {
  EDIT_PERMISSION_LEVEL,
  MODALS_CONTENTS,
  USER_PERMISSIONS
} from '@/const'
import { useCurrentPermissions } from '@/hooks/useCurrentPermissions'

interface IMemberProps {
  id: number
  profileUrl: string
  firstName: string
  lastName: string
  email: string
  avatar: string | null
  className?: string
  isRemovable?: boolean
  checked?: boolean
  isOwner: boolean
  target?: TTargetValue
  onChange?: (e) => void
  onRemove?: (value: number) => Promise<void>
  abacRole: IProjectRole
  roles: IProjectRoleShort[]
  projectId: number
  currentUser: {
    abac_role: IProjectRole
    id: number
  }
}

const Member = ({
  roles,
  abacRole,
  id,
  profileUrl,
  firstName,
  lastName,
  email,
  avatar,
  className = '',
  isRemovable = false,
  target = undefined,
  onRemove,
  currentUser,
  projectId,
  isOwner
}: IMemberProps): React.ReactElement => {
  const intl = useIntl()
  const initialsUser = getInitials(firstName, lastName, email)
  const { modalStore } = useStores()
  const { api } = useStores()
  const userName =
    (firstName + lastName).trim() !== ''
      ? `${firstName}  ${lastName}`
      : intl.formatMessage({
        id: 'members.empty_name',
        defaultMessage: 'User without name'
      })

  const selectedRole = roles.find((roleItem) => roleItem.id === abacRole.id)

  const canCurrentUserManageRoles = useCurrentPermissions(
    USER_PERMISSIONS.administration,
    EDIT_PERMISSION_LEVEL
  )

  const handleRoleChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    await api.changeProjectMemberRole(projectId, id, {
      abac_role: Number(e.target.value)
    })
  }

  const handleClickRemove = (): void => {
    modalStore.open(MODALS_CONTENTS.removeMemberProject, {
      memberId: id,
      memberName: userName,
      memberAvatar: avatar,
      initialsUser: initialsUser,
      onRemove: onRemove
    })
  }
  return (
    <div
      className={classNames({
        [style.member]: true,
        [className]: Boolean(className)
      })}
    >
      <div className={style.member__info}>
        <AvatarUser src={avatar} size='large' initials={initialsUser} />
        <Link to={profileUrl} target={target} className={classNames(style.member__name, style.member__link)}>
          {userName}
        </Link>

        {selectedRole != null &&
        id !== currentUser.id &&
        canCurrentUserManageRoles &&
        !isOwner
          ? (
            <Select
              onChange={handleRoleChange}
              className={style.member__select}
              value={selectedRole.id.toString()}
            >
              {roles.map((role) => {
                return (
                  <MenuItem key={role.id} value={role.id}>
                    {role.title}
                  </MenuItem>
                )
              })}
            </Select>
            )
          : (
            <UserRole role={abacRole.title} className={style.member__role} />
            )}
      </div>

      {isRemovable &&
      canCurrentUserManageRoles &&
      !isOwner &&
      id !== currentUser.id
        ? (
          <Button
            onClick={handleClickRemove}
            theme='light'
            size='lg'
            circle
            data-testid='remove-button'
          >
            <Icon src='clear' size='large' className={style.member__clear} />
          </Button>
          )
        : null}
    </div>
  )
}

export default Member
