import React from 'react'
import style from './styles/loading.module.scss'
import classNames from 'classnames'

interface IProps {
  className?: string
  slot?: string
}

const LoadingDots = ({ className, slot }: IProps): React.ReactElement => {
  return (
    <div className={classNames(style.dots, className)} slot={slot}>
      <div className={style.dots__first} />
      <div className={style.dots__second} />
      <div className={style.dots__third} />
    </div>
  )
}

export default LoadingDots
