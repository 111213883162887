import React from 'react'
import style from '../styles/project_roles.module.scss'
import classNames from 'classnames'
import Icon from '@/components/Icon/Icon'

interface IProps {
  title: string
  isProtected: boolean
  roleId: number
  updateTable: () => void
  handleDeleteRole: (roleId: number, title: string) => void
  handleRenameRole: (roleId: number, title: string) => void
}

const RoleHead = ({
  title,
  isProtected,
  roleId,
  handleDeleteRole,
  handleRenameRole
}: IProps): React.ReactElement => {
  const handleClickEdit = (): void => {
    handleRenameRole(roleId, title)
  }

  if (isProtected) {
    return (
      <th className={style.table__th}>
        <div className={style.table__th_block}>{title}</div>
      </th>
    )
  }

  return (
    <th className={style.table__th}>
      <div className={style.table__th_block}>
        <div className={style.role_head}>
          <span className={style.role_head__title}>{title}</span>

          <div className={style.role_head__buttons}>
            <button
              type='button'
              className={classNames(
                style.role_head__btn,
                style['role_head__btn--violet']
              )}
              onClick={handleClickEdit}
            >
              <Icon src='edit' />
            </button>

            <button
              type='button'
              className={style.role_head__btn}
              onClick={() => handleDeleteRole(roleId, title)}
            >
              <Icon src='delete' />
            </button>
          </div>
        </div>
      </div>
    </th>
  )
}

export default RoleHead
