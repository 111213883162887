import React from 'react'
import style from './styles/notification_item.module.scss'
import { ITimeSince } from '@/interfaces/Utils'
import NotifyTime from './components/NotifyTime'
import { FormattedMessage } from 'react-intl'
import NotifyCircle from './components/NotifyCircle'
import Link from '../Link'
import Icon from '../Icon/Icon'
import { LinkButton } from '../Button/LinkButton'
import { INotifiesImportCasesData } from '@/interfaces/Notifies'

interface IProps {
  data: INotifiesImportCasesData
  time: ITimeSince
  handleCloseModal: () => void
}

const NotifyImportCases = ({
  time,
  data,
  handleCloseModal
}: IProps): React.ReactElement => {
  const { project, errors, result, cases_num: casesNumber } = data

  return (
    <>
      <NotifyCircle />

      <div className={style.item__content}>
        {!result && errors !== null
          ? (
            <>
              <p className={style.info}>
                <FormattedMessage
                  id='notifications.item.import_cases.error'
                  defaultMessage='<error></error> importing test cases into <project></project> project. Try again.'
                  values={{
                    error: () => (
                      <span className={style.import__error}>
                        <FormattedMessage
                          id='common.error'
                          defaultMessage='Error'
                        />
                      </span>
                    ),
                    project: () => (
                      <Link
                        to={project.absolute_url ?? '/'}
                        onClick={handleCloseModal}
                        className={style.invtext__company}
                      >
                        {project.title}
                      </Link>
                    )
                  }}
                />
              </p>

              <LinkButton
                className={style.import__btn}
                size='sm'
                onClick={handleCloseModal}
                href={project.cases_url}
              >
                <FormattedMessage id='case.to' defaultMessage='To cases' />

                <Icon src='arrow_right' slot='icon-left' />
              </LinkButton>

              <div className={style.run}>
                {errors.map((el) => (
                  <span key={el} className={style.import__text}>
                    {el}
                  </span>
                ))}
              </div>
            </>
            )
          : null}

        {result
          ? (
            <>
              <p className={style.info}>
                <FormattedMessage
                  id='notifications.item.import_cases.success'
                  defaultMessage='Test case file import <success></success> in <project></project> project'
                  values={{
                    success: () => (
                      <span className={style.import__success}>
                        <FormattedMessage
                          id='notifications.item.import_cases.complete_success'
                          defaultMessage='Completed successfully'
                        />
                      </span>
                    ),
                    project: () => (
                      <Link
                        to={project.absolute_url ?? '/'}
                        onClick={handleCloseModal}
                        className={style.invtext__company}
                      >
                        {project.title}
                      </Link>
                    )
                  }}
                />
              </p>

              <div className={style.run}>
                <span className={style.run__name}>
                  <FormattedMessage
                    id='notifications.item.import_cases'
                    defaultMessage='{cases_count} test cases added'
                    values={{
                      cases_count: casesNumber
                    }}
                  />
                </span>

                <LinkButton
                  className={style.import__cases}
                  circle
                  onClick={handleCloseModal}
                  size='sm'
                  href={project.cases_url}
                >
                  <Icon src='arrow_right' />
                </LinkButton>
              </div>
            </>
            )
          : null}

        <NotifyTime time={time} />
      </div>
    </>
  )
}

export default NotifyImportCases
