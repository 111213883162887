import classNames from 'classnames'
import React from 'react'
import style from '../styles/notification_item.module.scss'

interface IProps {
  isRead: boolean
  children: React.ReactNode
  handleMarkAsRead: () => void
}

const NotifyWrapper = ({
  isRead,
  children,
  handleMarkAsRead
}: IProps): React.ReactElement => {
  const handleClick = (): void => {
    if (!isRead) {
      handleMarkAsRead()
    }
  }

  return (
    <div
      onClick={handleClick}
      className={classNames({
        [style.item]: true,
        [style['item--unread']]: !isRead
      })}
    >
      {children}
    </div>
  )
}

export default NotifyWrapper
