import { usePage } from '@/hooks'
import React from 'react'
import AddOrEditRun from '../AddOrEditRun'
import ProjectLayout from '../layout/components/ProjectLayout'
import UserLayout from '../layout/components/UserLayout'

const RunUpdate = (): React.ReactElement => {
  const page = usePage()
  const id = page.init_state.object.id

  return (
    <UserLayout>
      <ProjectLayout>
        <AddOrEditRun id={id} />
      </ProjectLayout>
    </UserLayout>
  )
}

export default RunUpdate
