import Title from '@/components/Title'
import Wrapper from '@/components/Wrapper'
import useSWR from 'swr'
import { useStores } from '@/hooks'
import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import style from '../styles/dashboard.module.scss'
import { EmptySearch } from '@/components/EmptyResults'
import SearchInput from '@/components/Input/SearchInput/SearchInput'
import useDebounce from '@/hooks/useDebounce'
import { observer } from 'mobx-react'
import EmptyList from '@/components/EmptyList/EmptyList'
import Icon from '@/components/Icon/Icon'
import SelectCompanyModal from '@/apps/Projects/SelectCompanyModal/SelectCompanyModal'
import useModal from '@/hooks/useModal'
import Button from '@/components/Button/Button'
import { ProjectsItem, ProjectsItemTable } from '@/components/ProjectsItem'
import { LoaderProjectsDashboard } from '../loaders'
import { SwitchIcon } from '@/components/Switch'
import { SWITCH_VALUE, projectsService } from '@/services/ProjectSwitcher'

const pageSize = 7

const Projects = observer((): React.ReactElement => {
  const intl = useIntl()

  const [search, setSearch] = useState<string>('')
  const debouncedSearch = useDebounce(search)
  const { isOpen, handleOpenModal, handleCloseModal } = useModal()
  const { api, companyStore } = useStores()
  const currentCompany = companyStore.getCompany()

  const { data, isLoading } = useSWR(
    {
      q: debouncedSearch,
      page: 1,
      page_size: pageSize,
      company: currentCompany,
      _key: 'getProjects'
    },
    api.getProjects
  )

  const projects = data?.results ?? []

  const noProjectsInCompany =
    !isLoading && data !== undefined && projects.length === 0 && search === ''

  return (
    <Wrapper className={style.projects}>
      <div className={style.top}>
        <Title type='h2' theme='h1' className={style.title}>
          <FormattedMessage id='titles.projects' defaultMessage='Projects' />
        </Title>

        <div className={style.projects__filters}>
          {!noProjectsInCompany
            ? (
              <SearchInput
                handleChange={setSearch}
                value={search}
                placeholder={intl.formatMessage({
                  id: 'project.search',
                  defaultMessage: 'Search project'
                })}
                clearable
                className={style.search}
              />
              )
            : null}

          <SwitchIcon
            currentValue={projectsService.switch === SWITCH_VALUE.list}
            setCurrentValue={projectsService.toggle}
            leftIcon='tile'
            rightIcon='list'
          />

          <Button
            onClick={handleOpenModal}
            className={style.project__addproject}
          >
            <Icon src='plus' slot='icon-left' />
            <FormattedMessage
              id='project.add_project'
              defaultMessage='Add project'
            />
          </Button>
        </div>
      </div>

      {isLoading ? <LoaderProjectsDashboard countShimmers={pageSize} /> : null}

      {projects.length > 0
        ? (
          <>
            {projectsService.switch === SWITCH_VALUE.list
              ? (
                <>
                  {projects.map((el) => {
                    return (
                      <ProjectsItem
                        key={el.id}
                        code={el.code}
                        title={el.title}
                        absoluteUrl={el.absolute_url}
                        image={el.image}
                        className={style.item}
                        users={el.users}
                        milestonesCount={el.milestones_count}
                        casesCount={el.cases_count}
                        runsCount={el.runs_count}
                        id={el.id}
                        isFavorite={el.is_favourite}
                      />
                    )
                  })}
                </>
                )
              : (
                <div className={style.projects__table}>
                  {projects.map((el) => {
                    return (
                      <ProjectsItemTable
                        key={el.id}
                        id={el.id}
                        code={el.code}
                        title={el.title}
                        absoluteUrl={el.absolute_url}
                        image={el.image}
                        users={el.users}
                        milestonesCount={el.milestones_count}
                        casesCount={el.cases_count}
                        runsCount={el.runs_count}
                        isFavorite={el.is_favourite}
                      />
                    )
                  })}
                </div>
                )}
          </>
          )
        : null}

      {!isLoading && search !== '' && projects.length === 0
        ? (
          <EmptySearch />
          )
        : null}

      {noProjectsInCompany
        ? (
          <EmptyList
            icon='folder'
            text={intl.formatMessage({
              id: 'projects.empty',
              defaultMessage: 'You don’t have Projects'
            })}
            className={style.steps__empty}
          />
          )
        : null}

      <SelectCompanyModal open={isOpen} close={handleCloseModal} />
    </Wrapper>
  )
})

export default Projects
