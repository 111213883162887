import Title from '@/components/Title'
import React from 'react'
import style from './styles/error.module.scss'
import { LinkButton } from '@/components/Button/LinkButton'
import { useIntl } from 'react-intl'
import useGlobalConfig from '@/hooks/useGlobalConfig'
import Icon from '@/components/Icon/Icon'

interface ErrorProps {
  numberError: number
  textError: string
  descriptionTitle: string
  descriprionText: string
  image: string
}

const Error404 = ({
  numberError,
  textError,
  descriptionTitle,
  descriprionText,
  image
}: ErrorProps): React.ReactElement => {
  const intl = useIntl()
  const obj = useGlobalConfig()
  return (
    <div className={style.error}>
      <div className={style.error__picture}>
        <div className={style.error__textnumber}>
          <p className={style.error__text}>{textError}</p>

          <Title type='h1' className={style.error__number}>
            {numberError}
          </Title>
        </div>

        <img className={style.error__img} src={image} alt='Error' />
      </div>

      <div className={style.error__description}>
        <h2 className={style.error__descripriontitle}>{descriptionTitle}</h2>

        <p className={style.error__descripriontext}>{descriprionText}</p>
      </div>

      <LinkButton
        href={obj.dashboard_url}
      >
        {intl.formatMessage({
          id: 'button.main',
          defaultMessage: 'Return to main'
        })}
        <Icon src='arrow_right' slot='icon-left' size='large' />
      </LinkButton>
    </div>
  )
}

export default Error404
