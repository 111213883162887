import BackButton from '@/components/Button/BackButton'
import { LinkButton } from '@/components/Button/LinkButton'
import DropdownStatus from '@/components/Dropdown/DropdownStatus'
import GroupTextStatuses from '@/components/GroupStatuses/GroupTextStatuses'
import HtmlContent from '@/components/HtmlContent/HtmlContent'
import Icon from '@/components/Icon/Icon'
import Priority from '@/components/Priority'
import { BadgeTextStatus } from '@/components/Status'
import { TextTag } from '@/components/Tag'
import Title from '@/components/Title'
import Wrapper from '@/components/Wrapper'
import {
  CASE_IN_RUN_STATUS,
  CASE_TYPES,
  CREATE_PERMISSION_LEVEL,
  MODALS_CONTENTS,
  STEP_STATUSES_BACKEND,
  USER_PERMISSIONS
} from '@/const'
import { useObjectPage, useStores } from '@/hooks'
import { ICaseInRun, ITag } from '@/interfaces/Case'
import { IStatistics } from '@/interfaces/Runs'
import { TBadgeStatus, TCaseType } from '@/interfaces/Types'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Button from '@/components/Button/Button'
import { usePage } from '@garpix/cms'
import useSWR from 'swr'
import useModal from '@/hooks/useModal'
import BaseModal from '@/components/Modal/Base'
import CreateCommentForm from './CreateCommentForm'
import JiraBugCreationForm from './JiraBugCreationForm'
import JiraBugListOfStep from './JiraBugListOfStep'
import CommentListModalContent from './CommentListModalContent'
import useCaseType from '../hooks'
import TaskDropdown from './TaskDropdown'
import { ICaseInRunPage } from '@/interfaces/ObjectPage'
import style from '../styles/case_detail.module.scss'
import { useCurrentPermissions } from '@/hooks/useCurrentPermissions'

interface IDescriptionProps {
  caseData: ICaseInRun
  statistic: IStatistics
  caseType: TCaseType
  tags: ITag[]
  runId: number
  handleChangeStatus: (e) => void
  hrefCaseInRun: string
  needsUpdate: boolean
  taskStatus: TBadgeStatus
  mutateStatistic: () => Promise<void>
}

const Description = ({
  caseData,
  statistic,
  caseType,
  tags,
  runId,
  handleChangeStatus,
  hrefCaseInRun,
  needsUpdate,
  taskStatus,
  mutateStatistic
}: IDescriptionProps): JSX.Element => {
  const { api } = useStores()
  const intl = useIntl()
  const { isTask } = useCaseType(caseType)
  const { modalStore } = useStores()

  const { page } = usePage()
  const { statistics } = useObjectPage<ICaseInRunPage>()

  const projectId = page.init_state.project_object.id
  const nextPageLink = page.init_state.next_case.url
  const generalCase = page.init_state.case_run.to_general
  const runLink = page.init_state.object.absolute_url

  const handleClickSyncCases = (): void => {
    modalStore.open(MODALS_CONTENTS.syncCases, {
      runId: runId,
      caseId: caseData.id
    })
  }

  const { data: jiraHealthCheck } = useSWR([projectId], api.getJiraHealthCheck)

  const {
    isOpen: isCommentModalOpen,
    handleOpenModal: handleOpenCommentModal,
    handleCloseModal: handleCloseCommentModal
  } = useModal()

  const {
    isOpen: isBugListModalOpen,
    handleOpenModal: handleOpenBugListModal,
    handleCloseModal
  } = useModal()

  const {
    isOpen: isJiraBugFormOpen,
    handleOpenModal: handleJiraBugFormOpen,
    handleCloseModal: handleJiraBugFormClose
  } = useModal()

  const {
    isOpen: isOpenCommentListModal,
    handleOpenModal: handleOpenCommentListModal,
    handleCloseModal: handleCloseCommentListModal
  } = useModal()

  const canUserEditRun = useCurrentPermissions(
    USER_PERMISSIONS.run,
    CREATE_PERMISSION_LEVEL
  )

  const canUserChangeStatus =
    isTask && statistics.status === CASE_IN_RUN_STATUS.inprocess && canUserEditRun

  return (
    <Wrapper className={style.description}>
      <div className={style.description__titlebox}>
        <BackButton className={style.head__back} href={hrefCaseInRun} />
        <div className={style.description__title}>
          <div className={style.description__code}>{caseData.code}</div>

          <Title type='h1' className={style.description__title}>
            {caseData.title}
          </Title>
        </div>
        {needsUpdate
          ? (
            <Button
              size='sm'
              className={style.caseitem__cir}
              circle
              onClick={handleClickSyncCases}
            >
              <Icon src='attention' className={style.caseitem__attention} />
            </Button>
            )
          : null}
        <LinkButton href={generalCase} size='med' theme='light'>
          <Icon slot='icon-left' src='test_case' />

          <FormattedMessage
            id='button.to_general'
            defaultMessage='To general'
          />
        </LinkButton>

        {nextPageLink !== null
          ? (
            <LinkButton href={nextPageLink} size='med' theme='violet'>
              <FormattedMessage
                id='button.next_test_case'
                defaultMessage='Next test case'
              />

              <Icon slot='icon-left' src='arrow_right' />
            </LinkButton>
            )
          : (
            <LinkButton href={runLink} size='med' theme='light'>
              <Icon slot='icon-left' src='arrow_right' />

              <FormattedMessage
                id='button.to_test_run'
                defaultMessage='To the test run'
              />
            </LinkButton>
            )}
      </div>

      <div className={style.description__content}>
        <div className={style.description__text}>
          <div className={style.description__grey}>
            <FormattedMessage
              id='case.form.preconditions'
              defaultMessage='Preconditions'
            />
          </div>

          <HtmlContent content={caseData.preconditions} />
        </div>

        <div className={style.description__buttons}>
          <div className={style.block__top}>
            <div className={style.block}>
              <span className={style.block__title}>
                <FormattedMessage id='runs.status' defaultMessage='Status' />
              </span>

              {canUserChangeStatus
                ? (
                  <DropdownStatus
                    status={taskStatus}
                    handleChange={handleChangeStatus}
                  />
                  )
                : (
                  <BadgeTextStatus status={taskStatus} />
                  )}
            </div>

            {taskStatus !== STEP_STATUSES_BACKEND.untested && isTask
              ? (
                <TaskDropdown
                  task={caseData}
                  jiraHealthCheckStatus={jiraHealthCheck?.result}
                  handleJiraBugFormOpen={handleJiraBugFormOpen}
                  handleOpenCommentListModal={handleOpenCommentListModal}
                  handleOpenAddComment={handleOpenCommentModal}
                  handleOpenBugListModal={handleOpenBugListModal}
                />
                )
              : null}
          </div>

          <div className={style.block}>
            <span className={style.block__title}>
              <FormattedMessage
                id='case.form.priority'
                defaultMessage='Priority'
              />
            </span>

            <Priority priority={caseData.priority} />
          </div>
        </div>
      </div>

      <div className={style.description__stats}>
        {caseType !== CASE_TYPES.task
          ? (
            <GroupTextStatuses statistic={statistic} />
            )
          : null}

        {tags?.length > 0
          ? (
            <div className={style.tags}>
              {tags.map((tag) => {
                return (
                  <TextTag
                    key={tag.id}
                    theme='hollow'
                    size='small'
                  >
                    {tag.title}
                  </TextTag>
                )
              })}
            </div>
            )
          : null}
      </div>

      <BaseModal
        open={isCommentModalOpen}
        onGx-after-hide={handleCloseCommentModal}
        hideDefaultClose
        className={style.modal}
      >
        {isCommentModalOpen
          ? (
            <CreateCommentForm
              taskStatus={taskStatus}
              status={caseData.status}
              handleCloseModal={handleCloseCommentModal}
              modalTitle={intl.formatMessage({
                id: 'task.status',
                defaultMessage: 'Task status'
              })}
              updateStepData={mutateStatistic}
              runId={runId}
              idStepOrTask={caseData.id}
              caseInRun={caseData}
            />
            )
          : null}
      </BaseModal>

      <BaseModal
        open={isJiraBugFormOpen}
        onGx-after-hide={handleJiraBugFormClose}
        hideDefaultClose
        className={style.modal}
      >
        {isJiraBugFormOpen
          ? (
            <JiraBugCreationForm
              handleCloseModal={handleJiraBugFormClose}
              taskId={caseData.id}
              updateStepData={mutateStatistic}
            />
            )
          : null}
      </BaseModal>

      <BaseModal
        open={isBugListModalOpen}
        onGx-after-hide={handleCloseModal}
        onGx-overlay-dismiss={handleCloseModal}
        hideDefaultClose
        size='medium'
      >
        {isBugListModalOpen
          ? (
            <JiraBugListOfStep
              isTask
              idStepOrTask={caseData.id}
              onCancel={handleCloseModal}
              projectId={projectId}
            />
            )
          : null}
      </BaseModal>

      <BaseModal
        open={isOpenCommentListModal}
        onGx-after-hide={handleCloseCommentListModal}
        onGx-overlay-dismiss={handleCloseCommentListModal}
        hideDefaultClose
        size='medium'
      >
        {isOpenCommentListModal
          ? (
            <CommentListModalContent
              caseType={caseType}
              taskId={caseData.id}
              runId={runId}
              onCancel={handleCloseCommentListModal}
            />
            )
          : null}
      </BaseModal>
    </Wrapper>
  )
}

export default Description
