import { Dropdown, DropdownMenu } from '@/components/Dropdown'
import Icon from '@/components/Icon/Icon'
import React, { useState } from 'react'
import style from '../styles/project_roles.module.scss'
import { TPermissionKey, TPermissionLevel } from '@/interfaces/Types'
import classNames from 'classnames'
import { useObjectPage, useStores } from '@/hooks'
import { LoadingDots } from '@/components/Loading'

interface IValue {
  value: TPermissionLevel
  title: string
}

interface IProps {
  roleId: number
  permissionName: TPermissionKey
  value: TPermissionLevel
  allowedPermissions: { [key in TPermissionLevel]: string }
  updateTable: () => void
  readOnly?: boolean
}

const permissionIcons: { [key in TPermissionLevel]: string } = {
  full: 'checked',
  update: 'edit',
  forbidden: 'clear',
  read: 'show_password'
}

const PermissionsDropdown = ({
  roleId,
  permissionName,
  value,
  allowedPermissions,
  updateTable,
  readOnly = false
}: IProps): React.ReactElement => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const { api } = useStores()
  const { id: projectId } = useObjectPage()

  const allowedValues: IValue[] = []

  for (const key in allowedPermissions) {
    allowedValues.push({
      value: key as TPermissionLevel,
      title: allowedPermissions[key]
    })
  }

  const changePermission = async (
    roleId: number,
    name: TPermissionKey,
    value: TPermissionLevel
  ): Promise<void> => {
    setIsLoading(true)
    try {
      await api.changeProjectRolesPermission(projectId, roleId, {
        permissions_list: [
          {
            source: name,
            permission: value
          }
        ]
      })
    } catch (e) {
      console.log(e)
    } finally {
      await updateTable()
      await setIsLoading(false)
    }
  }

  const handleChangePermission = (value): void => {
    void changePermission(roleId, permissionName, value)
    setIsDropdownOpen(false)
  }

  if (readOnly) {
    return (
      <div className={style.drop__info}>
        <span className={style.drop__value}>
          <span
            className={classNames(
              style.drop__iconwrap,
              style[`drop__iconwrap--${value}`]
            )}
          >
            <Icon src={permissionIcons[value]} size='xs' />
          </span>
          <span>{allowedPermissions[value]}</span>
        </span>
      </div>
    )
  }

  return (
    <Dropdown
      placement='bottom-end'
      onGx-show={() => setIsDropdownOpen(true)}
      onGx-hide={() => setIsDropdownOpen(false)}
      open={isDropdownOpen}
      className={style.drop}
      hoist={false}
    >
      {isLoading
        ? (
          <LoadingDots slot='trigger' className={style.drop__loader} />
          )
        : (
          <button
            slot='trigger'
            className={classNames({
              [style.drop__btn]: true,
              [style['drop__btn--opened']]: isDropdownOpen
            })}
            type='button'
            disabled={isLoading}
          >
            <span className={style.drop__value}>
              <span
                className={classNames(
                  style.drop__iconwrap,
                  style[`drop__iconwrap--${value}`]
                )}
              >
                <Icon src={permissionIcons[value]} size='xs' />
              </span>

              <span>{allowedPermissions[value]}</span>
            </span>
            <Icon
              src='arrow_down'
              className={classNames({
                [style.drop__icon]: true,
                [style['drop__icon--opened']]: isDropdownOpen
              })}
            />
          </button>
          )}

      {!isLoading
        ? (
          <DropdownMenu>
            {allowedValues.map((el) => {
              if (value !== el.value) {
                return (
                  <button
                    type='button'
                    onClick={() => handleChangePermission(el.value)}
                    key={el.value}
                    className={style.drop__item}
                  >
                    <span
                      className={classNames(
                        style.drop__iconwrap,
                        style[`drop__iconwrap--${el.value}`]
                      )}
                    >
                      <Icon src={permissionIcons[el.value]} size='xs' />
                    </span>
                    <span> {el.title}</span>
                  </button>
                )
              }
              return null
            })}
          </DropdownMenu>
          )
        : null}
    </Dropdown>
  )
}

export default PermissionsDropdown
