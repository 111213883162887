import Container from '@/components/Container'
import { useObjectPage, usePage, useStores } from '@/hooks'
import { ICaseInRunPage } from '@/interfaces/ObjectPage'
import React, { useEffect, useState } from 'react'
import useSWR from 'swr'
import Description from './components/Description'
import Steps from './components/Steps'
import { LoaderCaseInRunDetail } from './loaders'
import { IStep } from '@/interfaces/Case'
import useCaseType from './hooks'
import { TCaseStatusInRun } from '@/interfaces/Types'
import { usePageContext } from '@garpix/cms'
import Files from './components/Files'

const itemEmpty: IStep = {
  id: -1,
  description: '',
  expected_result: '',
  status: 'UNTESTED',
  number: -1
}

const CaseInRunDetail = (): JSX.Element => {
  const page = usePage()
  const { api } = useStores()

  const { id: runId, absolute_url: absoluteUrl } =
    useObjectPage<ICaseInRunPage>()
  const { handleClearAllCaches } = usePageContext()

  const { tags, id: caseId, case_type: caseType } = page.init_state.case_run

  const { data, mutate } = useSWR(
    {
      runId: runId,
      caseId: caseId,
      _key: 'getCaseInRun'
    },
    api.getCaseInRun
  )

  const { isCase, isChecklist } = useCaseType(caseType)
  const updateTaskStatus = (value): void => {
    void api
      .changeTaskStatus(runId, caseId, {
        status: value.toUpperCase() as TCaseStatusInRun
      })
      .then(async () => {
        await mutate()
      })
  }

  const [currentItem, setCurrentItem] = useState<IStep>(itemEmpty)

  const { data: statistic, mutate: mutateStatistic } = useSWR(
    {
      id: caseId,
      model: 'case',
      _key: 'getStatsInfo'
    },
    api.getStatsInfo
  )

  const { data: steps, mutate: mutateSteps } = useSWR(
    {
      id: runId,
      case_id: caseId,
      _key: 'getCaseInRunSteps'
    },
    api.getCaseInRunSteps
  )

  const handleMutate = async (): Promise<void> => {
    await Promise.all([mutate(), mutateStatistic(), mutateSteps()])
  }

  useEffect(() => {
    // usePage при переходе иногда старые данные подставляет,
    // поэтому чистим контекст при заходе на страницу.
    handleClearAllCaches()
  }, [])

  const initFiles = data?.files !== undefined ? data?.files : []

  return (
    <Container>
      {steps !== undefined && statistic !== undefined && data !== undefined
        ? (
          <>
            <Description
              hrefCaseInRun={absoluteUrl}
              statistic={statistic}
              runId={runId}
              caseData={data}
              caseType={caseType}
              tags={tags}
              handleChangeStatus={updateTaskStatus}
              needsUpdate={data.needs_update}
              taskStatus={data.status}
              mutateStatistic={handleMutate}
            />

            <Files
              initFiles={initFiles}
              mutateRun={mutate}
              runId={runId}
              caseId={caseId}
            />
            {isCase || isChecklist
              ? (
                <Steps
                  type={caseType}
                  steps={steps}
                  statistic={statistic}
                  setCurrentItem={setCurrentItem}
                  currentItem={currentItem}
                  mutate={handleMutate}
                  taskStatus={data.status}
                />
                )
              : null}
          </>
          )
        : (
          <LoaderCaseInRunDetail countShimmers={6} />
          )}
    </Container>
  )
}

export default CaseInRunDetail
