import React from 'react'
import { Layout } from '../layout/components'

const LandingPage = (): React.ReactElement => {
  return (
    <Layout>
      PasswordRecoveryPage
    </Layout>
  )
}

export default LandingPage
