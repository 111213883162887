import { useObjectPage, usePage, useStores } from '@/hooks'
import React, { useEffect, useState } from 'react'
import Description from './components/Description'
import PieChartBlock from './components/PieChartBlock'
import Runs from './components/Runs'
import useSWR from 'swr'
import { IValue } from '@/components/Select/interfaces/AsyncSelect'
import Wrapper from '@/components/Wrapper'
import Container from '@/components/Container'
import { observer } from 'mobx-react'
import { milestoneService } from './service'
import useModal from '@/hooks/useModal'
import BaseModal from '@/components/Modal/Base'
import ModalFileUploadingError from '@/components/Modal/ModalFileUploadingError'
import { MAX_SIZE } from './const'
import { useCurrentPermissions } from '@/hooks/useCurrentPermissions'
import { CAN_VIEW_PERMISSION_LEVEL, EDIT_PERMISSION_LEVEL, USER_PERMISSIONS } from '@/const'
import { LoaderMilestoneDescription, LoaderMilestoneDiagram, LoaderMilestoneFiles } from './loaders'
import UploadContainer from '@/components/UploadZone/UploadContainer/UploadContainer'
import style from './styles/milestone_detail.module.scss'

const MilestoneDetail = observer((): React.ReactElement => {
  const obj = useObjectPage()
  const { api } = useStores()
  const page = usePage()
  const { isOpen, handleOpenModal, handleCloseModal } = useModal()
  const [uploadFile, setUploadFile] = useState(false)
  const [largeFileNames, setLargeFileNames] = useState<string[]>([])
  const canUserSeeRunsBlock = useCurrentPermissions(USER_PERMISSIONS.runs, CAN_VIEW_PERMISSION_LEVEL)

  const canUserDeleteImage = useCurrentPermissions(
    USER_PERMISSIONS.milestone,
    EDIT_PERMISSION_LEVEL
  )

  const projectId = page.init_state.project_object.id
  const { id, title } = obj

  const [selectedRun, setSelectedRun] = useState<IValue | undefined>()

  const { data: milestone, mutate } = useSWR(
    {
      id: id,
      _key: 'getMilestone'
    },
    api.getMilestone
  )

  const { data: stepsInfo } = useSWR(
    {
      id: selectedRun?.value ?? obj.id,
      model: selectedRun?.value !== undefined ? 'run' : 'milestone',
      _key: 'getStepsInfo'
    },
    api.getStepsInfo
  )

  const onChange = async (files): Promise<void> => {
    const largeFiles = files.filter((file) => file.size > MAX_SIZE)
    const largeFilesNames: string[] = largeFiles.map((obj) => obj.name)
    const filesToUpload = files.filter((file) => file.size <= MAX_SIZE)

    if (largeFiles.length > 0) {
      setLargeFileNames(largeFilesNames)
      handleOpenModal()
    }

    if (filesToUpload.length > 0) {
      setUploadFile(true)
      await api.updateMilestone(id, { files: filesToUpload })
      setUploadFile(false)
      await mutate()
    }
  }

  useEffect(() => {
    milestoneService.setCurrentMilestone({ value: id, label: title })
  }, [id])

  const initFiles = milestone?.files !== undefined ? milestone?.files : []

  return (
    <Container>
      {milestone !== undefined
        ? (
          <Description
            milestone={milestone ?? {}}
            hrefUpdate={page.init_state.subpages.MilestonePageUpdate.absolute_url}
          />
          )
        : (
          <LoaderMilestoneDescription />
          )}

      <div className={style.container}>
        {stepsInfo !== undefined
          ? (
            <PieChartBlock
              id={id}
              projectId={projectId}
              statistics={stepsInfo}
              setSelectedRun={setSelectedRun}
              selectedRun={selectedRun}
            />
            )
          : (
            <Wrapper className={style.chart}>
              <LoaderMilestoneDiagram countShimmers={6} />
            </Wrapper>
            )}

        {milestone !== undefined
          ? (
            <UploadContainer
              initFiles={initFiles}
              onChange={onChange}
              isScrollableList
              type='api'
              isUpload={uploadFile}
              enableReinitialize
              canUserDeleteFile={canUserDeleteImage}
            />
            )
          : (
            <Wrapper className={style.chart}>
              <LoaderMilestoneFiles />
            </Wrapper>
            )}
      </div>

      {canUserSeeRunsBlock ? <Runs id={id} /> : null}

      <BaseModal
        size='medium'
        open={isOpen}
        onGx-after-hide={handleCloseModal}
        hideDefaultClose
      >
        <ModalFileUploadingError
          largeFileNames={largeFileNames}
          onCancel={handleCloseModal}
        />
      </BaseModal>
    </Container>
  )
})

export default MilestoneDetail
