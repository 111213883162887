import classNames from 'classnames'
import React from 'react'
import Link from '../Link'
import Tooltip from '../Tooltip'
import style from './styles/user.module.scss'
import { getInitials } from '../UserName'
import AvatarUser from '../Avatar/AvatarUser'

interface IProps {
  firstName: string
  lastName: string
  email: string
  avatar: string | null
  profileUrl: string
  className?: string
  size?: 'small' | 'medium' | 'large' | 'xl' | 'xxl'
  tooltip?: boolean
}

const UserLink = ({
  firstName,
  lastName,
  email,
  avatar,
  profileUrl,
  size,
  className = '',
  tooltip = false
}: IProps): React.ReactElement => {
  const name = firstName + ' ' + lastName
  const nameOrEmail = name !== ' ' ? name : email
  const initialsUser = getInitials(firstName, lastName, email)

  return (
    <Link
      to={profileUrl}
      className={classNames({
        [style.link]: true,
        [className]: Boolean(className)
      })}
    >
      <div
        className={classNames({
          [style.link]: true,
          [className]: Boolean(className)
        })}
      >
        {!tooltip
          ? (
            <>
              <span className={style.link__name}>{nameOrEmail}</span>

              <AvatarUser
                size={size}
                src={avatar}
                initials={initialsUser}
              />
            </>
            )
          : (
            <Tooltip content={nameOrEmail}>
              <AvatarUser
                size={size}
                src={avatar}
                initials={initialsUser}
              />
            </Tooltip>
            )}
      </div>
    </Link>
  )
}

export default UserLink
