import React from 'react'
import ShareLayout from '../layout/components/ShareLayout'
import ShareReportDetail from '../ShareReportDetail'

const ShareReportRunPage = (): React.ReactElement => {
  return (
    <ShareLayout>
      <ShareReportDetail />
    </ShareLayout>
  )
}

export default ShareReportRunPage
