import Icon from '@/components/Icon/Icon'
import { useObjectPage, usePage, useProjectObject } from '@/hooks'
import React from 'react'
import Title from '@/components/Title'
import { Accordion } from '@/components/Accordion'
import { useIntl } from 'react-intl'
import { LinkButton } from '@/components/Button/LinkButton'
import classNames from 'classnames'
import Link from '@/components/Link'
import { IObjectPage } from '@/interfaces/ObjectPage'
import { AvatarProject } from '@/components/Avatar'
import { useCurrentPermissions } from '@/hooks/useCurrentPermissions'
import { EDIT_PERMISSION_LEVEL, USER_PERMISSIONS } from '@/const'
import { TProjectLinks } from '@/interfaces/Types'
import style from './styles/projectdescription.module.scss'

const icons: { [key in TProjectLinks]: string } = {
  jira: 'task',
  confluence: 'knowledge',
  gitlab: 'git'
}

const ProjectDescription = ({ isBig }): React.ReactElement => {
  const page: IObjectPage = useObjectPage()
  const intl = useIntl()
  const statePage = usePage()?.init_state
  const projectInfo = useProjectObject()

  const canCurrentUserEditProject = useCurrentPermissions(
    USER_PERMISSIONS.project,
    EDIT_PERMISSION_LEVEL
  )

  const sidebar = statePage?.sidebar
  const urls = page?.urls ?? []
  const updateUrl = `${
    sidebar?.ProjectPageUpdate?.absolute_url as string
  }?next=${location.pathname}`

  return (
    <div
      className={classNames({
        [style.desc]: true,
        [style['desc--small']]: isBig === false
      })}
    >
      <div
        className={classNames({
          [style.desc__actions]: true,
          [style['desc__actions--small']]: isBig === false
        })}
      >
        <AvatarProject
          size={isBig === true ? 'xl' : 'large'}
          src={projectInfo.image}
        />
        {isBig === true && canCurrentUserEditProject
          ? (
            <LinkButton href={updateUrl} theme='light' circle>
              <Icon src='edit' size='xl' />
            </LinkButton>
            )
          : null}
      </div>
      {isBig === true
        ? (
          <Link to={projectInfo.absolute_url} className={style.desc__mainlink}>
            <Title className={style.desc__title} type='h2'>
              {projectInfo.title}
            </Title>
          </Link>
          )
        : null}
      <div
        className={classNames({
          [style.desc__code]: true,
          [style['desc__code--small']]: isBig === false
        })}
      >
        {projectInfo?.code !== undefined
          ? (
            <>{isBig === true ? <> {`id: ${projectInfo?.code} `}</> : null}</>
            )
          : null}
        <div
          className={classNames({
            [style.desc__links]: true,
            [style['desc__links--vertical']]: isBig === false
          })}
        >
          {urls
            .filter((url) => url.value !== '')
            .map((url) => {
              return (
                <a
                  key={url.id}
                  href={url.value}
                  target='_blank'
                  rel='noreferrer'
                >
                  <Icon src={icons[url.system]} />
                </a>
              )
            })}
        </div>
      </div>
      {projectInfo.description !== '' && isBig === true
        ? (
          <Accordion
            head={intl.formatMessage({
              id: 'sidebar.description',
              defaultMessage: 'Description'
            })}
          >
            <p className={style.desc__info}>{projectInfo.description}</p>
          </Accordion>
          )
        : null}
    </div>
  )
}

export default ProjectDescription
