import { useStores } from '@/hooks'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import style from './styles/modal.module.scss'
import Icon from '@/components/Icon/Icon'
import Button from '@/components/Button/Button'
import CompanyMemberShort from '@/components/Member/CompanyMemberShort'

export interface IFormInputs {
  non_field_errors?: string
  idUser: number
  idCompany: number
}

interface IProps {
  handleClose: () => void
}

const ViewRemoveMemberProject = ({
  handleClose
}: IProps): React.ReactElement | null => {
  const { modalStore } = useStores()
  const data = modalStore.dataModal
  const id = data.memberId
  const name = data.memberName
  const avatar = data.memberAvatar
  const initials = data.initialsUser
  const onRemove = data.onRemove

  const handleRemove = (): void => {
    onRemove(id)
    modalStore.close()
  }

  return (
    <>
      <div className={style.deleteuser__top}>
        <Icon src='delete' size='xxxl' className={style.deleteuser__grayicon} />

        <p className={style.deleteuser__text}>
          <FormattedMessage
            id='project.members.delete.title'
            defaultMessage='Do you really want to delete the user from project?'
          />
        </p>

        <CompanyMemberShort
          name={name}
          avatar={avatar}
          initials={initials}
        />
      </div>

      <div className={style.deleteuser__bottom}>
        <Button onClick={handleClose} theme='light'>
          <Icon src='clear' slot='icon-left' />

          <FormattedMessage id='common.cancel' defaultMessage='Cancel' />
        </Button>

        <Button onClick={handleRemove}>
          <Icon src='checked' slot='icon-left' />

          <FormattedMessage
            id='company.members.delete.confirm'
            defaultMessage='Delete'
          />
        </Button>
      </div>
    </>
  )
}

export default ViewRemoveMemberProject
