import BaseModal from '@/components/Modal/Base'
import React from 'react'
import Icon from '@/components/Icon/Icon'
import Title from '@/components/Title'
import { FormattedMessage } from 'react-intl'
import style from '../styles/add_edit.module.scss'
import Button from '@/components/Button/Button'
import { LinkButton } from '@/components/Button/LinkButton'

interface AllUsersAddedProps {
  companyUrl?: string
  onClose: () => void
  baseModalProps
}

const AllUsersAdded = ({
  companyUrl,
  onClose,
  baseModalProps
}: AllUsersAddedProps): React.ReactElement => {
  return (
    <BaseModal {...baseModalProps}>
      <div className={style.allselected}>
        <div className={style.allselected__info}>
          <Icon size='xxxl' src='user' className={style.allselected__user} />

          <Title>
            <FormattedMessage
              id='project.all_users_added.title'
              defaultMessage='You have already added all users of the company to your project'
            />
          </Title>

          <p className={style.allselected__subtitle}>
            <FormattedMessage
              id='project.all_users_added.subtitle'
              defaultMessage='Add new users to the company to join the project.'
            />
          </p>
        </div>

        <hr className={style.modal__line} />

        <div className={style.allselected__buttons}>
          {companyUrl !== null && companyUrl !== undefined && (
            <LinkButton theme='light' href={companyUrl}>
              <Icon src='arrow_right' slot='icon-left' />
              <FormattedMessage
                id='project.all_users_added.add'
                defaultMessage='Company users'
              />
            </LinkButton>
          )}

          <Button theme='violet' onClick={onClose}>
            <Icon src='checked' slot='icon-left' />
            <FormattedMessage
              id='project.all_users_added.accept'
              defaultMessage='Got it'
            />
          </Button>
        </div>
      </div>
    </BaseModal>
  )
}

export default AllUsersAdded
