import { LinkButton } from '@/components/Button/LinkButton'
import Icon from '@/components/Icon/Icon'
import useGlobalConfig from '@/hooks/useGlobalConfig'
import { emptyDashboard } from '@/images'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import style from '../styles/dashboard.module.scss'
import Title from '@/components/Title'

const EmptyDashboard = (): React.ReactElement => {
  const globalConfig = useGlobalConfig()
  return (
    <div className={style.empty}>
      <img
        src={emptyDashboard}
        width={1496}
        height={300}
        alt=''
      />

      <div className={style.empty__block}>
        <Title type='h2' theme='h1' className={style.empty__title}>
          <FormattedMessage
            id='dashboard.empty.title'
            defaultMessage='Welcome to eqator!'
          />
        </Title>
        <p className={style.empty__text}>
          <FormattedMessage
            id='dashboard.empty.text'
            defaultMessage='Get started by creating your company'
          />
        </p>

        <LinkButton
          href={globalConfig.create_company_url}
          className={style.project__addproject}
        >
          <Icon src='plus' slot='icon-left' />

          <FormattedMessage
            id='companies.add_company'
            defaultMessage='Add company'
          />
        </LinkButton>
      </div>
    </div>
  )
}

export default EmptyDashboard
