import Title from '@/components/Title'
import Wrapper from '@/components/Wrapper'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import BackButton from '@/components/Button/BackButton'
import useSidebar from '@/hooks/useSidebar'
import GitlabReportForm from './components/GitlabReportForm'
import useSWR from 'swr'
import { useObjectPage, useStores } from '@/hooks'
import ReportingHistory from './components/ReportingHistory'
import { FormProvider, useForm } from 'react-hook-form'
import {
  IGitlabReportFormData,
  INTERVAL_METHOD,
  REPORT_TYPE
} from './interface'
import Container from '@/components/Container'
import style from './styles/gitlab_report.module.scss'

const GitlabReport = (): JSX.Element => {
  const { ProjectPageReports } = useSidebar()
  const { api } = useStores()
  const { id } = useObjectPage()
  const [isFormGenerate, setIsFormGenerate] = useState(false)

  const formMethods = useForm<IGitlabReportFormData>({
    defaultValues: {
      intervalMethod: INTERVAL_METHOD.tags,
      reportType: REPORT_TYPE.withDescription
    }
  })

  const {
    data: gitlabReports,
    mutate: reportListMutate,
    isLoading: isReportsLoading
  } = useSWR(
    {
      id: id,
      _key: 'getGitlabReportList'
    },
    api.getGitlabReportList
  )

  return (
    <Container>
      <Wrapper className={style.reports}>
        <div>
          <div className={style.reports__titlewrap}>
            <Title type='h2' theme='h1' className={style.reports__title}>
              <BackButton
                href={ProjectPageReports.absolute_url}
                className={style.reports__titleback}
              />
              <FormattedMessage
                id='titles.gitlab.reports'
                defaultMessage='Gitlab Reports'
              />
            </Title>
          </div>
          <hr className={style.line__horizontal} />
        </div>

        <FormProvider {...formMethods}>
          <GitlabReportForm
            reportListMutate={reportListMutate}
            isFormGenerate={isFormGenerate}
            setIsFormGenerate={setIsFormGenerate}
          />
        </FormProvider>
      </Wrapper>

      {gitlabReports !== undefined
        ? (
          <Wrapper className={style.reports}>
            <ReportingHistory
              gitlabReports={gitlabReports}
              isReportsLoading={isReportsLoading}
              isFormGenerate={isFormGenerate}
            />
          </Wrapper>
          )
        : null}
    </Container>
  )
}

export default GitlabReport
