import { ModalDelete } from '@/components/Modal'
import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import style from '../styles/project_roles.module.scss'
import Title from '@/components/Title'
import { IProjectRole } from '@/interfaces/Member'
import Select from '@/components/Select/Select'
import MenuItem from '@/components/MenuItem/MenuItem'

interface IProps {
  onCancel: () => void
  onDelete: (newRole: number) => void
  roleId: number
  title: string
  roles: IProjectRole[]
}

const DeleteRole = ({
  onCancel,
  onDelete,
  roleId,
  title,
  roles
}: IProps): React.ReactElement => {
  const [value, setValue] = useState<number | undefined>()

  const intl = useIntl()

  const handleChange = (e): void => {
    setValue(Number(e.target.value))
  }

  const handleDelete = (): void => {
    if (value !== undefined) {
      onDelete(value)
    }
  }

  return (
    <ModalDelete
      onCancel={onCancel}
      onDelete={handleDelete}
      disableSubmit={value === undefined}
    >
      <Title type='h2' theme='h1' className={style.delete__title}>
        <FormattedMessage
          id='roles.delete.title'
          defaultMessage='Do you really want to delete the role named '
        />
        <span className={style.delete__name}>{title}</span>?
      </Title>

      <p className={style.delete__text}>
        <FormattedMessage
          id='roles.delete.text'
          defaultMessage='Select the new role that users will be granted rights to.'
        />
      </p>

      <Select
        label={intl.formatMessage({
          id: 'roles.new_role',
          defaultMessage: 'New role'
        })}
        required
        value={String(value)}
        onChange={handleChange}
      >
        {roles.map((el) => {
          if (el.id !== roleId) {
            return (
              <MenuItem key={el.id} value={el.id}>
                {el.title}
              </MenuItem>
            )
          }
          return null
        })}
      </Select>
    </ModalDelete>
  )
}

export default DeleteRole
