import { HISTORY_MODEL } from '@/const'
import { TModel } from '@/interfaces/History'
import { IMember } from '@/interfaces/Member'
import { TBadgeStatus, TCaseStatus, TRunStatus } from '@/interfaces/Types'
import { format, isValid, parseISO } from 'date-fns'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import Icon from '../Icon/Icon'
import Link from '../Link'
import { HistoryStatus } from '../Status'
import AllHistoryStatus from '../Status/AllHistoryStatus'
import { UserLink } from '../User'
import style from './styles/history_item.module.scss'

export type TAction = 'created' | 'assigned' | 'tested' | 'deleted' | 'marked'

export interface IHistoryItemProps {
  id: number
  date: string
  action: TAction
  model: TModel
  color?: string
  changeBy: IMember
  assignedTo?: IMember | null
  title: string
  url: string | null
  status: TBadgeStatus | TCaseStatus | TRunStatus | null
  code: string
}

const HistoryItem = ({
  id,
  date,
  action,
  model,
  color = undefined,
  changeBy,
  assignedTo,
  title,
  url,
  code,
  status
}: IHistoryItemProps): React.ReactElement => {
  const changedProfileUrl = changeBy?.profile_url
  const assignedProfileUrl = assignedTo?.profile_url

  const icon = {
    milestone: 'flag',
    run: 'run',
    case: 'test_case',
    case_run: 'run'
  }

  const parseDate = parseISO(date)
  return (
    <div className={style.item}>
      {isValid(parseDate)
        ? (
          <div className={style.info__date}>{format(parseDate, 'HH:mm')}</div>
          )
        : null}

      <HistoryStatus status={action} />

      <div className={style.info}>
        <div className={style.info__top}>
          {model === HISTORY_MODEL.milestone
            ? (
              <div
                className={style.info__color}
                style={{ backgroundColor: color }}
              />
              )
            : null}
          <span className={style.info__code}>{code}</span>
        </div>

        <div className={style.info__bottom}>
          <Icon src={icon[model]} className={style.info__icon} />

          {url !== null
            ? (
              <Link to={url} className={style.info__link}>
                {title}
              </Link>
              )
            : (
              <span className={style.info__title}>{title}</span>
              )}
        </div>
      </div>

      <div>
        <div className={style.changed}>
          <Icon size='xs' src='edit' className={style.changed__icon} />
          <span>
            <FormattedMessage
              id='history.change_by'
              defaultMessage='Change by'
            />
          </span>
        </div>

        <UserLink
          firstName={changeBy.first_name}
          lastName={changeBy.last_name}
          avatar={changeBy.avatar}
          profileUrl={changedProfileUrl}
          email={changeBy.email}
        />
      </div>

      {assignedTo !== undefined && assignedTo !== null
        ? (
          <div>
            <div className={style.assigned}>
              <Icon
                size='xs'
                src='arrow_right'
                className={style.assigned__icon}
              />
              <span>
                <FormattedMessage
                  id='history.assigned'
                  defaultMessage='Assigned to'
                />
              </span>
            </div>

            {assignedProfileUrl !== undefined
              ? (
                <UserLink
                  firstName={assignedTo.first_name}
                  lastName={assignedTo.last_name}
                  avatar={assignedTo.avatar}
                  profileUrl={assignedProfileUrl}
                  email={assignedTo.email}
                />
                )
              : null}
          </div>
          )
        : null}

      {status !== null
        ? (
          <AllHistoryStatus model={model} status={status} />
          )
        : null}
    </div>
  )
}

export default HistoryItem
