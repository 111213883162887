import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import BaseModal from '@/components/Modal/Base'
import Title from '@/components/Title'
import { useStores } from '@/hooks'
import { ModalDelete } from '@/components/Modal'
import style from '../styles/runitem.module.scss'

interface IProps {
  isOpen: boolean
  handleCloseModal: () => void
  idRun: number
  mutateMilestones?: () => void
  title: string
}

const ModalDeleteRun = ({
  isOpen,
  idRun,
  handleCloseModal,
  mutateMilestones,
  title
}: IProps): JSX.Element => {
  const { api } = useStores()
  const [isDeleting, setIsDeleting] = useState(false)

  const handleDelete = async (): Promise<void> => {
    setIsDeleting(true)

    api
      .deleteRun(idRun)
      .then(() => {
        mutateMilestones?.()
        handleCloseModal()
      })
      .catch((err) => console.log('err', err))
      .finally(() => setIsDeleting(false))
  }

  return (
    <BaseModal
      open={isOpen}
      onGx-after-hide={handleCloseModal}
      hideDefaultClose
      className={style.cases__modal}
      size='medium'
    >
      <ModalDelete
        onCancel={handleCloseModal}
        onDelete={handleDelete}
        disableSubmit={isDeleting}
      >
        <Title type='h2' theme='h1' className={style.delete__title}>
          <FormattedMessage
            id='run.delete.title'
            defaultMessage='Do you really want to delete the test run '
          />
          <span className={style.delete__name}>{title}</span>?
        </Title>

        <p className={style.delete__text}>
          <FormattedMessage
            id='run.delete.text'
            defaultMessage='All links will be deleted.'
          />
        </p>
      </ModalDelete>
    </BaseModal>
  )
}

export default ModalDeleteRun
