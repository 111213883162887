import { useEffect, useRef } from 'react'

const useDebounceSwrInfinite = <P extends any[], T extends (...args: P) => void>(
  callback: T,
  delay: number
): T => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    return () => {
      if (timeoutRef.current != null) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  const debouncedCallback = useRef<T>(callback)

  useEffect(() => {
    debouncedCallback.current = callback
  }, [callback])

  const debouncedFunction = (...args: P): void => {
    if (timeoutRef.current != null) {
      clearTimeout(timeoutRef.current)
    }

    timeoutRef.current = setTimeout(() => {
      debouncedCallback.current(...args)
    }, delay)
  }

  return debouncedFunction as T
}

export default useDebounceSwrInfinite
