import style from '../styles/registration.module.scss'

import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Controller, useForm } from 'react-hook-form'
import Input from '@/components/Input/Input'
import Icon from '@/components/Icon/Icon'
import Button from '@/components/Button/Button'
import { useStores } from '@/hooks'
import { reactHookFormErrorFormater } from '@/utils'
import { Form } from '@/components/Form'
import Title from '@/components/Title'
import BackButton from '@/components/Button/BackButton'
import useGlobalConfig from '@/hooks/useGlobalConfig'
import PhoneInput from '@/components/Input/PhoneInput'

interface IProps {
  nextStage: () => void
  setEmail: (value) => void
  email: string
  setPhone: (value) => void
  phone: string
}

export interface IFormInputs {
  email?: string
  phone?: string
  non_field_errors?: string
}

const SendEmail = ({
  nextStage,
  email,
  setEmail,
  setPhone,
  phone
}: IProps): React.ReactElement => {
  const intl = useIntl()
  const { api } = useStores()
  const globalConfig = useGlobalConfig()

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting }
  } = useForm<IFormInputs>({
    defaultValues: {
      email: email,
      phone: phone
    }
  })

  const onSubmit = async (params): Promise<void> => {
    try {
      const res = await api.registrationFirstStep({
        email: params.email,
        phone: params.phone
      })
      nextStage()
      return res
    } catch (error) {
      const errors = reactHookFormErrorFormater(params, error)
      errors.forEach((item) => {
        setError(item.key as keyof IFormInputs, {
          type: '400',
          message: item.value
        })
      })
    }
  }

  const formatPhoneToBackend = (phone: string): string => {
    if (phone.startsWith('+') || phone.length === 0) {
      return phone
    } else return '+' + phone
  }

  const nonFieldErrors = errors?.non_field_errors?.message ?? ''

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className={style.top}>
        <BackButton href={globalConfig.login_url} />

        <Title type='h1' className={style.top__title}>
          <FormattedMessage
            id='registration.title'
            defaultMessage='Registration'
          />
        </Title>
      </div>

      <div className={style.form}>
        <p>
          <FormattedMessage
            id='registration.send_email'
            defaultMessage='Please enter the email you will use to sign in to the system.'
          />
        </p>

        <Controller
          control={control}
          name='email'
          render={({ field: { onChange, value } }) => {
            return (
              <Input
                type='text'
                className={style.form__input}
                label={intl.formatMessage({
                  id: 'registration.email',
                  defaultMessage: 'Email'
                })}
                required
                value={value}
                onChange={(e) => {
                  setEmail(e.target.value)
                  onChange(e.target.value)
                }}
                error={errors?.email?.message ?? ''}
              />
            )
          }}
        />

        <Controller
          control={control}
          name='phone'
          render={({ field: { onChange, value } }) => {
            return (
              <PhoneInput
                label={intl.formatMessage({
                  id: 'profile.change_phone.phone',
                  defaultMessage: 'Phone'
                })}
                required
                country='ru'
                className={style.form__input}
                regions='europe'
                value={value}
                onChange={(e) => {
                  const formattedPhone = formatPhoneToBackend(e.detail.value)
                  setPhone(e.detail.formattedValue)
                  onChange(formattedPhone)
                }}
                error={errors?.phone?.message ?? ''}
              />
            )
          }}
        />

        <div className={style.form__bottom}>
          <Button type='submit' disabled={isSubmitting} size='lg'>
            <Icon src='arrow_right' slot='icon-left' />
            <FormattedMessage id='registration.next' defaultMessage='Next' />
          </Button>
        </div>

        {nonFieldErrors !== ''
          ? (
            <div className={style.form__error}>{nonFieldErrors}</div>
            )
          : null}
      </div>
    </Form>
  )
}

export default SendEmail
