import { TCurrentSuite } from '@/interfaces/Types'
import { makeAutoObservable } from 'mobx'

interface ISuiteData {
  nodeTitle: string
  id: TCurrentSuite
}

class Suite {
  currentSuite: ISuiteData = {
    nodeTitle: '',
    id: null
  }

  constructor () {
    makeAutoObservable(this)
  }

  setDefaultValueSuite (): void {
    this.currentSuite.id = null
    this.currentSuite.nodeTitle = ''
  }

  setCurrentSuite (data: ISuiteData): void {
    this.currentSuite.id = data.id
    this.currentSuite.nodeTitle = data.nodeTitle
  }

  getCurrentSuite (): ISuiteData {
    return this.currentSuite
  }
}

export const suiteService = new Suite()
