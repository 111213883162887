import React from 'react'
import ProjectLayout from '../layout/components/ProjectLayout'
import UserLayout from '../layout/components/UserLayout'
import RunDetail from '../RunDetail'

const Run = (): React.ReactElement => {
  return (
    <UserLayout>
      <ProjectLayout>
        <RunDetail />
      </ProjectLayout>
    </UserLayout>
  )
}

export default Run
