import React from 'react'
import style from './styles/notification_item.module.scss'
import Icon from '../Icon/Icon'
import { FormattedMessage } from 'react-intl'
import { INotifiesMilestoneCreateData } from '@/interfaces/Notifies'
import { ITimeSince } from '@/interfaces/Utils'
import Link from '../Link'
import NotifyTime from './components/NotifyTime'

interface IProps {
  data: INotifiesMilestoneCreateData
  time: ITimeSince
}

const NotifyMilestoneCreate = ({ data, time }: IProps): React.ReactElement => {
  const { milestone, project } = data

  return (
    <>
      <div
        className={style.item__color}
        style={{ backgroundColor: milestone.color }}
      />

      <div className={style.item__content}>
        <p className={style.info}>
          <FormattedMessage
            id='notifications.item.milestone_create'
            values={{
              link: <Link to={project.absolute_url}>{project.title}</Link>
            }}
            defaultMessage='Created new milestone in {link} project'
          />
        </p>

        <div className={style.milestone}>
          <Link to={milestone.absolute_url} className={style.milestone__link}>
            <Icon src='flag' className={style.milestone__icon} />
            <span className={style.milestone__name}>{milestone.title}</span>
          </Link>

          <div className={style.milestone__date}>
            <Icon src='clock' />

            <span>{milestone.end_date}</span>
          </div>
        </div>

        <NotifyTime time={time} />
      </div>
    </>
  )
}

export default NotifyMilestoneCreate
