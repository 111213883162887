import { CHECKBOX_STATE, ICheckboxState } from '@/interfaces/Checkbox'
import { ISuite } from '@/interfaces/Suites'

export const updateItemStates = (
  oldState: ICheckboxState[],
  items: ISuite[],
  clickedId: number
): ICheckboxState[] => {
  const newState = oldState.map((i) => ({ ...i }))
  // getters
  const getItemState = (id: number): CHECKBOX_STATE | -1 => {
    const find = newState.find((i) => i.id === id)
    if (find !== undefined) {
      return find.state
    } else {
      return -1
    }
  }

  // setters
  // ОБНОВЛЕНИЕ СОСТОЯНИЙ РОДИТЕЛЯ ПРИ ИЗМЕНЕНИИ ЕГО ПОТОМКОВ
  // const updateParent = (id: number) => {
  // const item = items.find((i) => i.id === id);
  // const parent = items.find((i) => i.id === item?.parent);
  // было так
  // if (parent !== undefined) return;
  // if (parent === undefined) return;
  // const childIds = items.filter((i) => i.parent === parent.id).map((i) => i.id);
  // const childStates = childIds.map((childId) => getItemState(childId));
  // if (childStates.length === childStates.filter((s) => s === CHECKBOX_STATE.CHECKED).length) {
  //   newState.find((i) => i.id === parent.id).state = CHECKBOX_STATE.CHECKED;
  // } else if (childStates.length === childStates.filter((s) => s === CHECKBOX_STATE.UNCHECKED).length) {
  //   newState.find((i) => i.id === parent.id).state = CHECKBOX_STATE.UNCHECKED;
  // }
  // else {
  // newState.find((i) => i.id === parent.id).state = CHECKBOX_STATE.INDETERMINATE;
  // }
  // updateParent(parent.id);
  // };

  const setUnchecked = (id: number): void => {
    const find = newState.find((i) => i.id === id)
    if (find !== undefined) find.state = CHECKBOX_STATE.UNCHECKED
    // ОБНОВЛЕНИЕ СОСТОЯНИЯ ПОТОМКОВ ПРИ ИЗМЕНЕНИИ СОСТОЯНИЯ РОДИТЕЛЯ (ВСЕ НЕ АКТИВНЫ)
    // items
    //   .filter((i) => i.parent === id)
    //   .map((i) => i.id)
    //   .forEach((childId) => setUnchecked(childId));
    // updateParent(id);
  }

  const setChecked = (id: number): void => {
    const find = newState.find((i) => i.id === id)
    if (find !== undefined) find.state = CHECKBOX_STATE.CHECKED
    // ОБНОВЛЕНИЕ СОСТОЯНИЯ ПОТОМКОВ ПРИ ИЗМЕНЕНИИ СОСТОЯНИЯ РОДИТЕЛЯ (ВСЕ АКТИВНЫ)
    // items
    //   .filter((i) => i.parent === id)
    //   .map((i) => i.id)
    //   .forEach((childId) => setChecked(childId));
    // updateParent(id);
  }
  // actual logic
  const itemState = getItemState(clickedId)
  if (itemState === CHECKBOX_STATE.CHECKED) {
    setUnchecked(clickedId)
  } else {
    setChecked(clickedId)
  }
  return newState
}
