import Button from '@/components/Button/Button'
import TextButton from '@/components/Button/TextButton'
import Checkbox from '@/components/Checkbox/Checkbox'
import ErrorsBlock from '@/components/ErrorsBlock'
import Icon from '@/components/Icon/Icon'
import Title from '@/components/Title'
import useGlobalConfig from '@/hooks/useGlobalConfig'
import { useAuth } from '@garpix/cms'
import { GxForm } from '@garpix/garpix-web-components-react'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import style from './styles/login.module.scss'
import LoginInput from '@/components/Input/LoginInput'
import PasswordInput from '@/components/Input/PasswordInput'

interface IProps {
  isReloading?: boolean
}

const Login = ({ isReloading = false }: IProps): React.ReactElement => {
  const intl = useIntl()
  const obj = useGlobalConfig()
  const hrefLogin =
    obj.registration_url !== undefined && obj.registration_url !== ''
      ? obj.registration_url
      : null
  const hrefForgotPass =
    obj.forgot_url !== undefined && obj.forgot_url !== ''
      ? obj.forgot_url
      : null

  const handleSuccess = (): void => {
    if (isReloading) {
      location.reload()
    } else {
      location.href = '/'
    }
  }

  const handleError = (error): void => {
    console.log('error', error)
    console.log('authData', authData)
  }

  const {
    handleChange,
    onSubmit,
    errors,
    setFieldValue,
    authData,
    handleBlur,
    isSubmitting
  } = useAuth({
    handleSuccess,
    handleError
  })

  const handleChecked = (e): void => {
    setFieldValue(e.target.name, e.target.checked)
  }

  const isFormFilled =
    authData !== undefined &&
    authData.username !== '' &&
    authData.username !== undefined &&
    authData.password !== '' &&
    authData.password !== undefined

  return (
    <>
      <Title type='h1'>
        <FormattedMessage id='titles.auth' defaultMessage='Authorization' />
      </Title>
      <GxForm onGx-submit={onSubmit}>
        <LoginInput
          type='text'
          label={intl.formatMessage({
            id: 'login.email',
            defaultMessage: 'Email'
          })}
          className={style.form__field}
          clearable
          name='username'
          onBlur={handleBlur}
          onInput={handleChange}
          onChange={handleChange}
          error={errors?.username}
        />
        <PasswordInput
          name='password'
          label={intl.formatMessage({
            id: 'login.password',
            defaultMessage: 'Password'
          })}
          autoComplete='off'
          className={style.form__field}
          onBlur={handleBlur}
          onInput={handleChange}
          onChange={handleChange}
          error={errors?.password}
        />

        <div className={style.form__bottom}>
          <Checkbox
            onGx-change={handleChecked}
            name='signed'
            checked={authData?.signed ?? false}
          >
            {intl.formatMessage({
              id: 'login.signed',
              defaultMessage: 'Keep me signed in'
            })}
          </Checkbox>
          {hrefForgotPass !== null
            ? (
              <TextButton href={hrefForgotPass}>
                <FormattedMessage
                  id='login.forgot_password'
                  defaultMessage='Forgot password?'
                />
              </TextButton>
              )
            : null}
        </div>

        <div className={style.form__bottom}>
          {hrefLogin !== null
            ? (
              <TextButton href={hrefLogin} className={style.form__link} size='lg'>
                <FormattedMessage id='login.sign_in' defaultMessage='Sign in' />
              </TextButton>
              )
            : null}

          <Button
            type='submit'
            size='lg'
            disabled={isSubmitting === true || !isFormFilled}
            className={style.form__btn}
          >
            <Icon src='login' slot='icon-left' />
            <FormattedMessage id='login.enter' defaultMessage='Log in' />
          </Button>
        </div>
      </GxForm>
      {Boolean(errors?.non_field_errors) || Boolean(errors?.network_error)
        ? (
          <ErrorsBlock
            className={style.errors}
            networkError={errors?.network_error}
            errorsArray={errors?.non_field_errors}
          />
          )
        : null}
    </>
  )
}

export default Login
