export const runtimeConfig =
    typeof window === 'undefined'
      ? {
          // server
          API_URL: process?.env?.RAZZLE_API_URL ?? '',
          WS_URL: process?.env?.RAZZLE_WS_URL ?? '',
          RAZZLE_SENTRY_ENABLE: process?.env?.RAZZLE_SENTRY_ENABLE ?? '',
          IS_PRODUCTION: process?.env?.IS_PRODUCTION ?? ''
        }
      : {
          // client
          API_URL: window?.env?.API_URL !== undefined ? window?.env?.API_URL : '',
          WS_URL: window?.env?.WS_URL !== undefined ? window?.env?.WS_URL : '',
          RAZZLE_SENTRY_ENABLE: window?.env?.RAZZLE_SENTRY_ENABLE !== undefined ? window?.env?.RAZZLE_SENTRY_ENABLE : '',
          IS_PRODUCTION: window?.env?.IS_PRODUCTION !== undefined ? window?.env?.IS_PRODUCTION : ''
        }
