import ColorPickerDropdown from '@/components/ColorPicker/ColorPickerDropdown'
import Input from '@/components/Input/Input'
import InputDayPicker from '@/components/InputDayPicker/InputDayPicker'
import Textarea from '@/components/Textarea'
import Title from '@/components/Title'
import Wrapper from '@/components/Wrapper'
import { compareAsc, parse } from 'date-fns'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import style from '../styles/add_mile.module.scss'

const MilestoneForm = ({ watch, setValue, register, errors }): React.ReactElement => {
  const intl = useIntl()

  const color = watch('color')
  const startDate = watch('start_date')
  const endDate = watch('end_date')

  const handleChangeColor = (newColor: string): void => {
    setValue('color', newColor)
  }

  const handleChangeStartDate = (date): void => {
    setValue('start_date', date)
    const parseStateDate = parse(date, 'dd/MM/yyyy', new Date())
    const parseEndDate = parse(endDate, 'dd/MM/yyyy', new Date())
    if (compareAsc(parseEndDate, parseStateDate) === -1) {
      setValue('end_date', undefined)
    }
  }

  const handleChangeEndtDate = (date): void => {
    setValue('end_date', date)
  }

  return (
    <Wrapper className={style.form}>
      <div className={style.form__left}>
        <Title type='h2'>
          <FormattedMessage id='titles.milestone' defaultMessage='Milestone' />
        </Title>

        <ColorPickerDropdown
          color={color}
          onChange={handleChangeColor}
          className={style.form__picker}
        />

        <Input
          label={intl.formatMessage({
            id: 'milestones.form.name',
            defaultMessage: 'Name'
          })}
          required
          className={style.form__block}
          {...register('title', { required: true })}
          error={errors?.title?.message ?? ''}
        />

        <div className={style.form__dates}>
          <InputDayPicker
            label={intl.formatMessage({
              id: 'milestones.form.start_date',
              defaultMessage: 'Start date'
            })}
            placeholder={intl.formatMessage({
              id: 'input.dd/mm/yyyy',
              defaultMessage: 'dd/mm/yyyy'
            })}
            value={startDate}
            onChangeHandlerInput={handleChangeStartDate}
            onChangeHandlerPicker={handleChangeStartDate}
            className={style.form__date}
            required
            error={errors?.start_date?.message}
          />

          <div className={style.form__divider} />

          <InputDayPicker
            label={intl.formatMessage({
              id: 'milestones.form.end_date',
              defaultMessage: 'End date'
            })}
            placeholder={intl.formatMessage({
              id: 'input.dd/mm/yyyy',
              defaultMessage: 'dd/mm/yyyy'
            })}
            value={endDate}
            onChangeHandlerInput={handleChangeEndtDate}
            onChangeHandlerPicker={handleChangeEndtDate}
            className={style.form__date}
            required
            disabledDays={[
              { from: new Date(1970, 1, 1), to: parse(startDate, 'dd/MM/yyyy', new Date()) }
            ]}
            error={errors?.end_date?.message}
          />
        </div>

        <Textarea
          label={intl.formatMessage({
            id: 'milestones.form.description',
            defaultMessage: 'Description'
          })}
          {...register('description')}
          error={errors?.description?.message ?? ''}
        />
      </div>

      <div className={style.form__right}>
        {/* <Title type='h3' className={style.form__right_title}>
          <Icon src='clip' />
          <FormattedMessage id='titles.links' defaultMessage='Links' />
        </Title>

        <div className={style.links__item}>
          <Icon src='task' className={style.links__icon} />
          <Input
            label={intl.formatMessage({
              id: 'project.form.tracker',
              defaultMessage: 'Task tracking system'
            })}
            className={style.links__input}
          />
        </div> */}
      </div>
    </Wrapper>
  )
}

export default MilestoneForm
