import TextButton from '@/components/Button/TextButton'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import style from '../styles/notifications.module.scss'

interface IProps {
  handleClear: () => void
  handleMarkReadAll: () => void
}

const NotificationsFooter = ({
  handleClear,
  handleMarkReadAll
}: IProps): React.ReactElement => {
  return (
    <div className={style.footer}>
      <TextButton onClick={handleClear}>
        <FormattedMessage
          id='notifications.clear_all'
          defaultMessage='Clear all'
        />
      </TextButton>
      <TextButton onClick={handleMarkReadAll}>
        <FormattedMessage
          id='notifications.mark_all_read'
          defaultMessage='Mark all as read'
        />
      </TextButton>
    </div>
  )
}

export default NotificationsFooter
