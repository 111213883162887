import React from 'react'
import { FormattedMessage } from 'react-intl'
import style from './styles/failed.module.scss'

const FailedData = (): React.ReactElement => {
  return (
    <div className={style.failed}>
      <FormattedMessage
        id='error.failed_load'
        defaultMessage='Failed to load'
      />
    </div>
  )
}

export default FailedData
