export interface IGitlabReportFormData {
  reportType: string
  intervalMethod: string
  branchName: string
  startTag: string
  endTag: string
  startDate: string
  endDate: string
  reportName: string
}

export enum REPORT_TYPE {
  withDescription = 'headlineWithDescription',
  onlyHeadlines = 'onlyHeadlines',
}

export enum INTERVAL_METHOD {
  tags = 'Tags',
  time = 'Time',
}
