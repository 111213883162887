import { useState } from 'react'

interface IUseModal {
  isOpen: boolean
  handleOpenModal: () => void
  handleCloseModal: () => void
}

const useModal = (): IUseModal => {
  const [isOpen, setIsOpen] = useState(false)

  const handleOpenModal = (): void => {
    setIsOpen(true)
  }

  const handleCloseModal = (): void => {
    setIsOpen(false)
  }

  return {
    isOpen,
    handleOpenModal,
    handleCloseModal
  }
}

export default useModal
