import React, { useEffect, useState } from 'react'
import Icon from '@/components/Icon/Icon'
import classNames from 'classnames'
import { TextTag } from '@/components/Tag'
import { observer } from 'mobx-react'
import style from '../styles/update_cases.module.scss'
import { FormattedMessage } from 'react-intl'
import Checkbox from '@/components/Checkbox/Checkbox'
import { ICountSelected } from '@/interfaces/Suites'

interface IProps {
  caseCount?: number
  setCurrentSuite: (value: number | undefined) => void
  currentSuite?: number
  checkSuite: (suiteId: number, isChecked: boolean) => void
  countSelected: ICountSelected
}
const withoutSuite = -1

const WithoutSuite = observer(
  ({
    caseCount = 0,
    setCurrentSuite,
    currentSuite,
    countSelected,
    checkSuite
  }: IProps) => {
    const [isChecked, setChecked] = useState(false)
    const [isIndeterminate, setIndeterminate] = useState(false)

    const handleClick = (): void => {
      if (currentSuite === withoutSuite) {
        setCurrentSuite(undefined)
      } else {
        setCurrentSuite(withoutSuite)
      }
    }

    const handleCheckbox = ({
      target
    }: React.ChangeEvent<HTMLInputElement>): void => {
      const value = isIndeterminate ? true : target.checked
      setChecked(value)
      checkSuite(withoutSuite, value)
    }

    useEffect(() => {
      if (countSelected !== undefined) {
        if (countSelected[withoutSuite]?.selected > 0) {
          setIndeterminate(true)
        }
        if (
          countSelected[withoutSuite]?.selected ===
          countSelected[withoutSuite]?.total
        ) {
          setIndeterminate(false)
          setChecked(true)
        }
        if (countSelected[withoutSuite]?.selected === 0) {
          setIndeterminate(false)
          setChecked(false)
        }
      }
    }, [countSelected])

    return (
      <div className={style.dnd__root}>
        <Checkbox
          size='medium'
          onGx-change={handleCheckbox}
          checked={isChecked}
          isIndeterminate={isIndeterminate}
        />

        <button
          type='button'
          onClick={handleClick}
          className={classNames({
            [style.dnd__titlebtn]: true,
            [style['dnd__titlebtn--opened']]: currentSuite === -1
          })}
        >
          <Icon src='folders' />

          <FormattedMessage
            id='suites.root_suite'
            defaultMessage='Without suite'
          />
        </button>

        <div className={style.dnd__right}>
          <TextTag className={style.dnd__tag}>
            <Icon src='test_case' />
            {countSelected?.[withoutSuite]?.selected}/{caseCount}
          </TextTag>
        </div>
      </div>
    )
  }
)

export default WithoutSuite
