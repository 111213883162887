import Icon from '@/components/Icon/Icon'
import MenuItem from '@/components/MenuItem/MenuItem'
import { TPriority } from '@/interfaces/Types'
import classNames from 'classnames'
import React, { forwardRef } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Select from '../Select'
import style from './styles/priorityselect.module.scss'

interface IProps {
  className?: string
  handleChange?: (e: any) => void
  value?: TPriority | ''
  required?: boolean
  clearable?: boolean
  labelLeft?: boolean
}

const icons = {
  VERY_LOW: 'priority_very_low',
  LOW: 'priority_low',
  MEDIUM: 'priority_medium',
  HIGH: 'priority_high',
  CRITICAL: 'priority_critical'
}

const PrioritySelect = (
  {
    labelLeft = false,
    className = '',
    handleChange,
    value,
    required = false,
    clearable = true
  }: IProps,
  ref
): React.ReactElement => {
  const intl = useIntl()

  return (
    <Select
      labelLeft={labelLeft}
      ref={ref}
      className={classNames({
        [style.form]: true,
        [className]: Boolean(className)
      })}
      placeholder={intl.formatMessage({
        id: 'input.all',
        defaultMessage: 'All'
      })}
      required={required}
      label={intl.formatMessage({
        id: 'case.form.priority',
        defaultMessage: 'Priority'
      })}
      onChange={handleChange}
      clearable={clearable}
      value={value}
    >
      {icons[value as TPriority] !== undefined && value !== ''
        ? (
          <Icon
            src={icons[value as TPriority]}
            className={style.priority__icon}
            slot='prefix'
          />
          )
        : null}

      {Object.entries(icons).map(([iconValue, iconSrc]) => (
        <MenuItem
          key={iconValue}
          value={iconValue}
          className={style.project_item}
        >
          <Icon src={iconSrc} className={style.priority__icon} />
          <FormattedMessage
            id={`priority.${iconValue.toLowerCase()}`}
            defaultMessage={iconValue}
          />
        </MenuItem>
      ))}
    </Select>
  )
}

export default forwardRef(PrioritySelect)
