import React, { useEffect, useState } from 'react'
import style from './styles/members.module.scss'
import Wrapper from '@/components/Wrapper'
import ListContent from '@/components/ListContent'
import { useObjectPage, useStores } from '@/hooks'
import useDebounce from '@/hooks/useDebounce'
import { FormattedMessage, useIntl } from 'react-intl'
import useSWR from 'swr'
import { scrollTop } from '@/utils'
import Title from '@/components/Title'
import SearchInput from '@/components/Input/SearchInput/SearchInput'
import Button from '@/components/Button/Button'
import Icon from '@/components/Icon/Icon'
import RoleCompanySelect from '@/components/Select/RoleCompanySelect'
import InvitesList from './components/InvitesList'
import { COMPANY_ROLES, MODALS_CONTENTS } from '@/const'
import { observer } from 'mobx-react'

const CompanyInvites = observer((): React.ReactElement => {
  const { modalStore, api } = useStores()
  const { id: idCompany } = useObjectPage()
  const [currentRole, setCurrentRole] = useState<number | ''>('')
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search)
  const [page, setPage] = useState(1)
  const pageSize = 10
  const intl = useIntl()

  const { data, error, isLoading, mutate } = useSWR(
    {
      id: idCompany,
      q: debouncedSearch,
      status: 'created',
      role: currentRole,
      _key: 'getCompanyInvites'
    },
    api.getCompanyInvites,
    {
      onSuccess: () => {
        scrollTop()
      }
    }
  )

  const handleMutate = async (): Promise<void> => {
    await mutate()
  }

  const handleSearch = (value: string): void => {
    setSearch(value)
    setPage(1)
  }

  const handleClickAddUser = (): void => {
    modalStore.open(MODALS_CONTENTS.addUserCompany, {
      idCompany: idCompany
    })
  }

  const handleChangeRole = (e): void => {
    if (e.target.value !== '') {
      setCurrentRole(Number(e.target.value))
    } else {
      setCurrentRole('')
    }
  }

  useEffect(() => {
    handleMutate().catch((err) => console.log('err', err))
  }, [modalStore.currentModal])

  return (
    <Wrapper className={style.members}>
      <div className={style.members__top}>
        <div className={style.members__left}>
          <Title type='h1' className={style.members__title}>
            <FormattedMessage
              id='company.invites.title'
              defaultMessage='Users'
            />
          </Title>
        </div>

        <Button onClick={handleClickAddUser}>
          <Icon src='plus' slot='icon-left' />

          <FormattedMessage
            id='company.invites.button'
            defaultMessage='Add user'
          />
        </Button>
      </div>

      <div className={style.members__filters}>
        <RoleCompanySelect
          bannedRoles={COMPANY_ROLES.owner}
          handleChange={handleChangeRole}
          value={String(currentRole)}
        />

        <SearchInput
          handleChange={handleSearch}
          value={search}
          className={style.members__search}
          placeholder={intl.formatMessage({
            id: 'company.invites.search',
            defaultMessage: 'Search user'
          })}
        />
      </div>

      <ListContent
        isLoading={isLoading}
        error={error}
        hasData={data !== undefined && data?.count > 0}
        emptyListIcon='folder'
        emptyListText={intl.formatMessage({
          id: 'company.invites.no_search',
          defaultMessage: 'No users has been found'
        })}
        emptyListButton={intl.formatMessage({
          id: 'company.invites.button',
          defaultMessage: 'Add users'
        })}
        onClick={handleClickAddUser}
      >
        {data !== undefined
          ? (
            <InvitesList
              data={data}
              currentPage={page}
              pageSize={pageSize}
              setPage={setPage}
              idCompany={idCompany}
              onMutate={handleMutate}
            />
            )
          : null}
      </ListContent>
    </Wrapper>
  )
})

export default CompanyInvites
