import style from './styles/input.module.scss'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import Icon from '@/components/Icon/Icon'
import Button from '@/components/Button/Button'

interface IProps {
  onClose: () => void
  onConfirm: () => void
}

const ConfirmDeleteAvatar = ({ onClose, onConfirm }: IProps): React.ReactElement => {
  return (
    <>
      <div className={style.modal__top}>
        <FormattedMessage
          id='profile.delete_avatar.title'
          defaultMessage='Delete avatar'
        />

        <Button onClick={onClose} theme='light' circle>
          <Icon src='clear' />
        </Button>
      </div>

      <div className={style.modal__middle}>
        <FormattedMessage
          id='profile.delete_avatar.text'
          defaultMessage='Are you sure you want to delete the avatar?'
        />
      </div>

      <div className={style.modal__bottom}>
        <Button onClick={onConfirm}>
          <FormattedMessage id='common.confirm' defaultMessage='Confirm' />
        </Button>
      </div>
    </>
  )
}

export default ConfirmDeleteAvatar
