import { defaultCube } from '@/images'
import classNames from 'classnames'
import React from 'react'
import style from './styles/avatar.module.scss'

interface IProps {
  src?: string | null
  size?: 'small' | 'medium' | 'large' | 'xl' | 'xxl' | 'xxxl'
  className?: string
}

const AvatarProject = ({
  src = null,
  size = 'medium',
  className = ''
}: IProps): React.ReactElement => {
  return (
    <>
      <img
        className={classNames({
          [style.avatar]: true,
          [style[`avatar--${size}`]]: true,
          [style['border--light']]: true,
          [style[`border--${size}`]]: true,
          [className]: Boolean(className)
        })}
        src={src ?? defaultCube}
        alt='avatar'
      />
    </>
  )
}

export default AvatarProject
