import Wrapper from '@/components/Wrapper'
import React, { useEffect, useState } from 'react'
import { UserCompanyRole } from '@/components/User'
import useSWR from 'swr'
import { useStores } from '@/hooks'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import style from '../styles/dashboard.module.scss'
import { AvatarCompany } from '@/components/Avatar'
import { LoaderCompanyInfoDashboard } from '../loaders'
import Button from '@/components/Button/Button'
import Icon from '@/components/Icon/Icon'
import ReleaseCalendar from '@/apps/Dashboard/components/ReleaseCalendar'

const CompanyInfo = observer((): React.ReactElement => {
  const { api, companyStore } = useStores()
  const companyId = companyStore.getCompany()
  const [isCalendarOpen, setCalendarOpen] = useState(false)

  const { data: currentCompany, isLoading } = useSWR(
    companyId !== undefined
      ? {
          id: companyId,
          _key: 'getCompany'
        }
      : null,
    companyId !== undefined ? api.getCompany : null
  )

  const handleToggleCalendar = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation()
    isCalendarOpen ? handleCloseCalendar() : setCalendarOpen(true)
  }

  const handleCloseCalendar = (): void => {
    setCalendarOpen(false)
  }

  useEffect(() => {
    setCalendarOpen(false)
  }, [companyId])

  return (
    <Wrapper className={style.comp__wrapper}>
      {currentCompany !== undefined
        ? (
          <>
            <Link to={currentCompany.absolute_url} className={style.comp__link}>
              <AvatarCompany
                src={currentCompany.image}
                className={style.comp__logo}

              />

              <span>{currentCompany.title}</span>
            </Link>

            <div className={style.comp__side}>
              <UserCompanyRole role={currentCompany.user_role_type} />

              <Button
                theme='light'
                onMouseDown={(e) => {
                  handleToggleCalendar(e)
                }}
              >
                <Icon src='calendar' />
              </Button>

              {isCalendarOpen
                ? (
                  <ReleaseCalendar
                    open={isCalendarOpen}
                    onClose={handleCloseCalendar}
                  />)
                : null}
            </div>
          </>
          )
        : null}

      {isLoading
        ? (
          <LoaderCompanyInfoDashboard />
          )
        : null}
    </Wrapper>
  )
})

export default CompanyInfo
