import React, { Suspense, useEffect, useState } from 'react'
import { usePage, useStores } from '@/hooks'
import style from '../../styles/update_cases.module.scss'
import useSWR from 'swr'
import { useIntl } from 'react-intl'
import ListContent from '@/components/ListContent'
import Loading from '@/components/Loading/Loading'
import SearchInput from '@/components/Input/SearchInput/SearchInput'
import useDebounce from '@/hooks/useDebounce'
import WithoutSuite from '../../components/WithoutSuite'
import { useNavigate } from 'react-router-dom'
import { ISuite } from '@/interfaces/Suites'
import { ICase } from '@/interfaces/Case'
import { filterSuitesByCases } from '../../utils'
import SuitesDndList from './SuitesDndList'

interface IProps {
  selectedCase: ICase[]
}

const Suites = ({ selectedCase }: IProps): React.ReactElement => {
  const page = usePage()
  const { api } = useStores()
  const navigate = useNavigate()

  const [suites, setSuites] = useState<ISuite[]>([])
  const [search, setSearch] = useState('')
  const [withoutSuiteCount, setCount] = useState(0)

  const debouncedSearch = useDebounce(search)

  const intl = useIntl()

  const projectId = page.init_state.project_object.id
  const runId = page.init_state.run_id

  const { data, error, isLoading, isValidating } = useSWR(
    {
      project_id: projectId,
      q: debouncedSearch,
      _key: 'getSuitesCreate'
    },
    api.getSuites,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false
    }
  )

  const { data: casesWithoutSuites } = useSWR(
    {
      page: 1,
      page_size: 1,
      id: runId,
      without_suite: true,
      _key: 'getCases'
    },
    api.getCasesInRun
  )

  const handleNavigateCreateSuite = (): void => {
    const createSuite: string = `${String(
      page.init_state.sidebar.ProjectPageCasesAndSuites.absolute_url
    )}?createSuite=true`
    navigate(createSuite)
  }

  useEffect(() => {
    if (data !== undefined) {
      setSuites(() => filterSuitesByCases(data, selectedCase))
      setCount(selectedCase.filter((item) => item.suite === null).length)
    }
  }, [selectedCase, data])

  return (
    <div className={style.suites}>
      <SearchInput
        handleChange={setSearch}
        value={search}
        placeholder={intl.formatMessage({
          id: 'suites.search',
          defaultMessage: 'Search suites'
        })}
      />

      <Suspense fallback={<Loading />}>
        <div className={style.suites__tree}>
          <ListContent
            isLoading={isLoading || isValidating}
            error={error}
            hasData={
              (data !== undefined && data?.length > 0) ||
              (casesWithoutSuites !== undefined && casesWithoutSuites.count > 0)
            }
            emptyListIcon='folders'
            emptyListText={intl.formatMessage({
              id: 'suites.no_suites',
              defaultMessage: 'No suites has been found'
            })}
            emptyListButton={intl.formatMessage({
              id: 'suites.add_suite',
              defaultMessage: 'Add test suite'
            })}
            onClick={handleNavigateCreateSuite}
            classNameError={style.dnd__error}
          >
            <div className={style.dnd__list}>
              {withoutSuiteCount !== 0
                ? (
                  <WithoutSuite caseCount={withoutSuiteCount} />
                  )
                : null}

              <SuitesDndList data={suites} />
            </div>
          </ListContent>
        </div>
      </Suspense>
    </div>
  )
}
export default Suites
