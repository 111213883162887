import { useStores } from '@/hooks'
import { IHistoryElement } from '@/interfaces/History'
import React from 'react'
import HistoryItem from '../HistoryItem'
import Title from '../Title'
import style from './style/history.module.scss'

interface IProps {
  data: IHistoryElement[]
}

const HistoryGroup = ({ data }: IProps): React.ReactElement => {
  const map = new Map()
  const store = useStores()

  data.forEach((el) => {
    const date = el.date.slice(0, 10)

    if (map.has(date)) {
      map.set(date, [...map.get(date), el])
    } else {
      map.set(date, [el])
    }
  })

  const structuredHistory = Array.from(map.entries())
  return (
    <div className={style.history}>
      {structuredHistory.map((el) => {
        const formatedDate = store.localeDate.formatDate(el[0])
        return (
          <div key={el[0]} className={style.history__block}>
            <Title type='h3' className={style.history__title}>
              {formatedDate}
            </Title>

            <div className={style.history__list}>
              {el[1].map((el) => {
                return (
                  <HistoryItem
                    key={el.id}
                    id={el.id}
                    date={el.date}
                    action={el.action}
                    model={el.model}
                    color={el.color}
                    changeBy={el.change_by}
                    assignedTo={el.assigned_to}
                    title={el.title}
                    url={el.absolute_url}
                    code={el.code}
                    status={el.status}
                  />
                )
              })}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export { HistoryGroup }
