import React from 'react'
import Icon from '../Icon/Icon'
import { BadgeStatus } from '../Status'
import { TBadgeStatus } from '../../interfaces/Types'
import style from './styles/caseitem.module.scss'

interface IProps {
  code: string
  title: string
  date?: string
  status: TBadgeStatus
}

const CaseItemReport = ({
  code,
  title,
  date,
  status
}: IProps): React.ReactElement => {
  return (
    <div className={style.report}>
      <div className={style.report__info}>
        <span className={style.report__code}>{code}</span>
        <span className={style.report__title}>
          <Icon src='test_case' />
          {title}
        </span>
      </div>

      {date !== undefined
        ? (
          <span className={style.report__date}>
            <Icon src='edit' />
            {date}
          </span>
          )
        : null}

      <BadgeStatus status={status} className={style.report__status} />
    </div>
  )
}

export default CaseItemReport
