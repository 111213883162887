import React, { forwardRef, useEffect, useState } from 'react'
import Wrapper from '@/components/Wrapper'
import { observer } from 'mobx-react'
import Title from '@/components/Title'
import Button from '@/components/Button/Button'
import Icon from '@/components/Icon/Icon'
import { FormattedMessage, useIntl } from 'react-intl'
import { Form } from '@/components/Form'
import { Accordion } from '@/components/Accordion'
import { TCasesSort, TPriority, TCaseType } from '@/interfaces/Types'
import CasesList from './CasesList/CasesList'
import ListContent from '@/components/ListContent'
import useSWR from 'swr'
import { useObjectPage, usePage, useStores } from '@/hooks'
import PrioritySelect from '@/components/Select/PrioritySelect/PrioritySelect'
import { LinkButton } from '@/components/Button/LinkButton'
import useSidebar from '@/hooks/useSidebar'
import SearchInput from '@/components/Input/SearchInput/SearchInput'
import BaseModal from '@/components/Modal/Base'
import { ISuite, ISuiteCaseParams } from '@/interfaces/Suites'
import useModal from '@/hooks/useModal'
import ExportCases from './ExportCases'
import useDebounce from '@/hooks/useDebounce'
import ImportCases from './ImportCases'
import { scrollTop } from '@/utils'
import Tags from '@/components/Tags/Tags'
import { ApproveAll } from './ApproveAll'
import { Dropdown, DropdownMenu } from '@/components/Dropdown'
import TypeSelect from '@/components/Select/TypeSelect'
import { useCurrentPermissions } from '@/hooks/useCurrentPermissions'
import {
  CREATE_PERMISSION_LEVEL,
  EDIT_PERMISSION_LEVEL,
  USER_PERMISSIONS
} from '@/const'
import { pageSizeSelectService } from '@/services/PageSizeSelect'
import ImportPanel from './ImportPanel'
import style from './styles/cases.module.scss'

export interface IFormInputs {
  suites: ISuite[]
}

interface IProps {
  params: ISuiteCaseParams
  changeParams: (name: string, value: any) => void
}

const Cases = observer(
  forwardRef(
    (
      { params, changeParams }: IProps,
      ref: React.MutableRefObject<{
        handleReloadSuites: () => void
      } | null>
    ): React.ReactElement => {
      const store = useStores()
      const subpage = useSidebar()
      const obj = useObjectPage()
      const page = usePage()
      const { id } = obj
      const intl = useIntl()

      const canUserApproveCases = useCurrentPermissions(
        USER_PERMISSIONS.case_approve,
        EDIT_PERMISSION_LEVEL
      )
      const canUserAddCase = useCurrentPermissions(
        USER_PERMISSIONS.case,
        CREATE_PERMISSION_LEVEL
      )
      const canUserExportCase = useCurrentPermissions(
        USER_PERMISSIONS.cases_export,
        EDIT_PERMISSION_LEVEL
      )

      const [selectTags, setSelectTags] = useState<number[]>(
        params.tagsCases ?? []
      )
      const [pageSize, setPageSize] = useState<number>(
        pageSizeSelectService.getSize('casesPage') ?? 200
      )
      const [currentPage, setCurrentPage] = useState(1)
      const [showFilters, setShowFilters] = useState(false)

      const [search, setSearch] = useState<string>(params.qCases ?? '')
      const debouncedSearch = useDebounce(search)
      const [priority, setPriority] = useState<TPriority | ''>(
        params.casesPriority ?? ''
      )
      const [type, setType] = useState<TCaseType | ''>('')
      const [sortBy, setSortBy] = useState<TCasesSort>(
        params.casesSort ?? '-sort'
      )

      const suiteId =
        params?.activeNode !== undefined && params?.activeNode !== -1
          ? Number(params?.activeNode)
          : undefined

      const isWithoutSuite = params?.activeNode === -1
      const draftCasesCount = page.init_state.draft_cases_count
      const {
        data,
        error,
        isLoading,
        mutate: mutateCases
      } = useSWR(
        {
          q: debouncedSearch,
          page: currentPage,
          page_size: pageSize,
          priority: priority,
          tags: selectTags,
          ordering: sortBy,
          project: obj.id,
          case_type: type,
          suite: suiteId,
          without_suite: isWithoutSuite,
          _key: 'getCases'
        },
        store.api.getCases,
        {
          onSuccess: () => {
            scrollTop()
          }
        }
      )

      const handleSetSelectTags = (value: number[]): void => {
        setSelectTags(value)
        if (value.length > 0) {
          changeParams('tagsCases', value)
        } else {
          changeParams('tagsCases', undefined)
        }
      }

      const handleSearch = (value: string): void => {
        setSearch(value)
        setCurrentPage(1)
        if (value !== '') {
          changeParams('qCases', value)
        } else {
          changeParams('qCases', undefined)
        }
      }

      const handlePriority = (value: TPriority | ''): void => {
        setPriority(value)
        setCurrentPage(1)
        if (value !== '') {
          changeParams('casesPriority', value)
        } else {
          changeParams('casesPriority', undefined)
        }
      }

      const handleType = (value: TCaseType): void => {
        setType(value)
        setCurrentPage(1)
      }

      const handleSortClick = (): void => {
        const updSortParam = sortBy === 'sort' ? '-sort' : 'sort'
        setSortBy(updSortParam)
        changeParams('casesSort', updSortParam)
        setCurrentPage(1)
      }

      const mutateSuites = async (): Promise<void> => {
        await Promise.all([mutate(), ref.current?.handleReloadSuites?.()])
      }

      const handleOpenFilters = (): void => {
        setShowFilters(!showFilters)
      }

      useEffect(() => {
        setCurrentPage(1)
      }, [suiteId])

      const { data: suites, mutate } = useSWR(
        {
          project_id: id,
          q: search,
          _key: 'getSuites'
        },
        store.api.getSuites
      )

      const { data: suiteInfo } = useSWR(
        suiteId !== null && suiteId !== undefined
          ? {
              id: suiteId
            }
          : null,
        store.api.getSuite
      )

      const {
        isOpen: openImport,
        handleOpenModal: handleOpenImport,
        handleCloseModal: handleCloseImport
      } = useModal()

      const {
        isOpen: openImportQase,
        handleOpenModal: handleOpenImportQase,
        handleCloseModal: handleCloseImportQase
      } = useModal()

      const {
        isOpen: openExport,
        handleOpenModal: handleOpenExport,
        handleCloseModal: handleCloseExport
      } = useModal()

      const {
        isOpen: openApproveAll,
        handleOpenModal: handleOpenApproveAll,
        handleCloseModal: handleCloseApproveAll
      } = useModal()

      const {
        isOpen: openImportTestIt,
        handleOpenModal: handleOpenTestIt,
        handleCloseModal: handleCloseTestIt
      } = useModal()

      const handleChangeTags = (): void => {
        setCurrentPage(1)
      }

      const handleChangePageSize = (e: any): void => {
        const newPageSize = Number(e.target.value)
        setPageSize(newPageSize)
        pageSizeSelectService.setSize('casesPage', newPageSize)
        setCurrentPage(1)
      }

      const handleApproveAll = async (): Promise<void> => {
        await mutateCases()
      }

      useEffect(() => {
        setCurrentPage(1)
      }, [suiteId])

      return (
        <Wrapper className={style.cases}>
          <div className={style.cases__top}>
            <div className={style.cases__head}>
              <Title type='h1' className={style.cases__title}>
                <FormattedMessage
                  id='cases.title'
                  defaultMessage='Test Cases'
                />
              </Title>

              <ImportPanel projectId={obj.id} />
            </div>

            <div className={style.cases__right}>
              {canUserExportCase
                ? (
                  <>
                    <Button
                      theme='light'
                      className={style.cases__addsuite}
                      onClick={handleOpenExport}
                    >
                      <Icon src='export' slot='icon-left' />

                      <FormattedMessage
                        id='cases.export'
                        defaultMessage='Export'
                      />
                    </Button>

                    <BaseModal
                      open={openExport}
                      onGx-after-hide={handleCloseExport}
                      hideDefaultClose
                      className={style.modal__wrapper}
                    >
                      {suites !== undefined
                        ? (
                          <ExportCases
                            listSuites={suites}
                            onClose={handleCloseExport}
                          />
                          )
                        : undefined}
                    </BaseModal>
                  </>
                  )
                : null}

              {canUserAddCase
                ? (
                  <>
                    <Dropdown placement='bottom-end'>
                      <Button
                        slot='trigger'
                        theme='light'
                        className={style.cases__addsuite}
                      >
                        <Icon src='import' slot='icon-left' />
                        <FormattedMessage
                          id='cases.import'
                          defaultMessage='Import'
                        />
                      </Button>

                      <DropdownMenu>
                        <div className={style.dropdown}>
                          <button
                            type='button'
                            onClick={handleOpenImport}
                            className={style.dropdown__btn}
                          >
                            <Icon
                              src='eqatorIcon'
                              className={style.title__icon}
                            />
                            <span>
                              <FormattedMessage
                                id='cases.import.eqator'
                                defaultMessage='Eqator format'
                              />
                            </span>
                          </button>

                          <button
                            type='button'
                            onClick={handleOpenImportQase}
                            className={style.dropdown__btn}
                          >
                            <Icon src='qaseIcon' className={style.title__icon} />
                            <span>
                              <FormattedMessage
                                id='cases.import.qase'
                                defaultMessage='Qase format'
                              />
                            </span>
                          </button>

                          <button
                            type='button'
                            onClick={handleOpenTestIt}
                            className={style.dropdown__btn}
                          >
                            <Icon
                              src='testitIcon'
                              className={style.title__icon}
                            />
                            <span>
                              <FormattedMessage
                                id='cases.import.testit'
                                defaultMessage='TestIt format'
                              />
                            </span>
                          </button>
                        </div>
                      </DropdownMenu>
                    </Dropdown>

                    {openImport
                      ? (
                        <BaseModal
                          open={openImport}
                          size='medium'
                          onGx-after-hide={handleCloseImport}
                          hideDefaultClose
                          className={style.modal__wrapper}
                        >
                          <ImportCases
                            exampleCase={page.init_state.excel_import_example}
                            mutateSuites={mutateSuites}
                            idProject={id}
                            id={suiteId}
                            onClose={handleCloseImport}
                            format='eqator'
                          />
                        </BaseModal>
                        )
                      : null}

                    {openImportQase
                      ? (
                        <BaseModal
                          open={openImportQase}
                          size='medium'
                          onGx-after-hide={handleCloseImportQase}
                          hideDefaultClose
                          className={style.modal__wrapper}
                        >
                          <ImportCases
                            mutateSuites={mutateSuites}
                            idProject={id}
                            id={suiteId}
                            onClose={handleCloseImportQase}
                            format='qase'
                          />
                        </BaseModal>
                        )
                      : null}

                    {openImportTestIt
                      ? (
                        <BaseModal
                          open={openImportTestIt}
                          size='medium'
                          onGx-after-hide={handleCloseTestIt}
                          hideDefaultClose
                          className={style.modal__wrapper}
                        >
                          <ImportCases
                            mutateSuites={mutateSuites}
                            idProject={id}
                            id={suiteId}
                            onClose={handleCloseTestIt}
                            format='testit'
                          />
                        </BaseModal>
                        )
                      : null}

                    {canUserAddCase
                      ? (
                        <LinkButton
                          href={subpage.ProjectPageCasesCreate.absolute_url}
                          className={style.cases__addsuite}
                        >
                          <Icon src='plus' slot='icon-left' />

                          <FormattedMessage
                            id='cases.add_case'
                            defaultMessage='Add test case'
                          />
                        </LinkButton>
                        )
                      : null}
                  </>
                  )
                : null}
            </div>
          </div>

          {suiteInfo !== undefined && suiteInfo.description !== ''
            ? (
              <div className={style.cases__general}>
                <div className={style.cases__general__title}>
                  <Icon src='folders' />
                  <Title type='h3' className={style.cases__h3}>
                    {suiteInfo.title}
                  </Title>
                </div>

                <Accordion
                  head={intl.formatMessage({
                    id: 'sidebar.description',
                    defaultMessage: 'description'
                  })}
                >
                  <div className={style.cases__desk}>{suiteInfo.description}</div>
                </Accordion>
              </div>
              )
            : null}

          <div className={style.cases__form}>
            <Button
              theme='white'
              onClick={handleSortClick}
              className={style.cases__sortid}
            >
              <Icon
                className={style.cases__filter}
                src={sortBy === 'sort' ? 'asc' : 'desc'}
                slot='icon-left'
              />
            </Button>

            <SearchInput
              handleChange={handleSearch}
              value={search}
              className={style.cases__input}
              placeholder={intl.formatMessage({
                id: 'cases.search',
                defaultMessage: 'Search checks'
              })}
            />

            <Button theme='light' onClick={handleOpenFilters}>
              <Icon src='filter' className={style.cases__filter} />
              {selectTags.length > 0 || priority !== '' || type !== ''
                ? (
                  <span className={style.counter}>
                    {selectTags.length +
                    (priority !== '' ? 1 : 0) +
                    (type !== '' ? 1 : 0)}
                  </span>
                  )
                : null}
            </Button>
            {canUserApproveCases
              ? (
                <Button
                  theme='light'
                  className={style.cases__addsuite}
                  onClick={handleOpenApproveAll}
                >
                  <Icon src='checked' slot='icon-left' />
                  <FormattedMessage
                    id='cases.approve.all'
                    defaultMessage='Approve all'
                  />
                </Button>
                )
              : null}

            <BaseModal
              size='medium'
              open={openApproveAll}
              onGx-after-hide={handleCloseApproveAll}
              hideDefaultClose
              className={style.modal__wrapper}
            >
              <ApproveAll
                draftCasesCount={draftCasesCount}
                handleSucces={handleApproveAll}
                handleClose={handleCloseApproveAll}
              />
            </BaseModal>
          </div>

          {showFilters && (
            <>
              <Form onSubmit={undefined}>
                <div className={style.cases__form}>
                  <PrioritySelect
                    handleChange={(e) => handlePriority(e.target.value)}
                    value={priority}
                    className={style.cases__select}
                    labelLeft
                  />

                  <TypeSelect
                    handleChange={(e) => handleType(e.target.value)}
                    value={type}
                    clearable
                    className={style.cases__select}
                    labelLeft
                  />
                </div>
              </Form>

              <Tags
                onChange={handleChangeTags}
                setSelectTags={handleSetSelectTags}
                selectTags={selectTags}
              />
            </>
          )}

          <ListContent
            isLoading={isLoading}
            error={error}
            hasData={data !== undefined && data?.count > 0}
            emptyListIcon='test_case'
            emptyListText={intl.formatMessage({
              id: 'cases.no_cases',
              defaultMessage: 'No test cases added yet'
            })}
            emptyListButton={intl.formatMessage({
              id: 'cases.add_case',
              defaultMessage: 'Add test case'
            })}
            href={subpage.ProjectPageCasesCreate.absolute_url}
          >
            {data !== undefined
              ? (
                <CasesList
                  currentPage={currentPage}
                  pageSize={pageSize}
                  setPage={setCurrentPage}
                  setPageSize={handleChangePageSize}
                  initialCases={data.results}
                  casesTotal={data.count}
                  casesSort={sortBy}
                />
                )
              : null}
          </ListContent>
        </Wrapper>
      )
    }
  )
)

export default Cases
