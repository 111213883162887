import { useIntl } from 'react-intl'

/** Принимает имя и фамилию и возвращает значение поумолчанию если нет ни того ни другого  */
export const getNameUser = (firstName: string, lastName: string): string => {
  const intl = useIntl()

  return firstName !== '' || lastName !== ''
    ? `${firstName} ${lastName}`
    : intl.formatMessage({
      id: 'common.user',
      defaultMessage: 'User'
    })
}

/** Принимает имя, фамилию и почту и возвращает инициалы пользователя или первые символы почты  */
export const getInitials = (
  firstName: string,
  lastName: string,
  email?: string | null
): string => {
  if (email === undefined || email === null) {
    const initials: string = `${firstName[0] ?? ''}${lastName[0] ?? ''}`
    return initials
  }
  if (email !== null && email !== undefined) {
    const initials: string =
      `${firstName[0] ?? ''}${lastName[0] ?? ''}` !== ''
        ? `${firstName[0] ?? ''}${lastName[0] ?? ''}`
        : `${email.substring(0, 2)}`
    return initials
  }
  return ''
}
