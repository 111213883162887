import { ModalDelete } from '@/components/Modal'
import Title from '@/components/Title'
import { useObjectPage } from '@/hooks'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import style from '../styles/add_mile.module.scss'

interface IProps {
  onCancel: () => void
  onDelete: () => void
  disableSubmit: boolean
}

const DeleteMilestone = ({
  onCancel,
  onDelete,
  disableSubmit
}: IProps): React.ReactElement => {
  const { title } = useObjectPage()

  return (
    <ModalDelete
      onCancel={onCancel}
      onDelete={onDelete}
      disableSubmit={disableSubmit}
    >
      <Title type='h2' theme='h1' className={style.delete__title}>
        <FormattedMessage
          id='milestone.delete.title'
          defaultMessage='Do you really want to delete the test run '
        />
        <span className={style.delete__name}>{title}</span>?
      </Title>

      <p className={style.delete__text}>
        <FormattedMessage
          id='milestone.delete.text'
          defaultMessage='All links will be deleted.'
        />
      </p>
    </ModalDelete>
  )
}

export default DeleteMilestone
