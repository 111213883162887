import React from 'react'
import style from './styles/runslist.module.scss'
import Pagination from '@/components/Pagination'
import PaginatedQueryResult from '@/api/interfaces/PaginatedQueryResult'
import { IRunsElement } from '@/interfaces/Runs'
import RunItem from '@/components/RunItem'

interface IProps {
  data: PaginatedQueryResult<IRunsElement>
  currentPage: number
  pageSize: number
  setPage: (string) => void
  handleUpdate: () => void
  handleOpenModal?: (runId: number) => void
}

const RunsList = ({
  data,
  currentPage,
  pageSize,
  setPage,
  handleUpdate,
  handleOpenModal
}: IProps): JSX.Element => {
  return (
    <>
      <div className={style.runslist__list}>
        {data.results.map((el) => {
          return (
            <RunItem
              key={el.id}
              title={el.title}
              milestone={el.milestone}
              deadline={el.deadline}
              status={el.status}
              id={el.id}
              assignedTo={el.assigned_to}
              absoluteUrl={el.absolute_url}
              statistic={el.statistics}
              handleUpdate={handleUpdate}
              isFull
              handleOpenModal={handleOpenModal}
            />
          )
        })}
      </div>

      <div className={style.runslist__pagination}>
        <Pagination
          currentPage={currentPage}
          total={data.count}
          pageSize={pageSize}
          handleChange={setPage}
        />
      </div>
    </>
  )
}

export default RunsList
