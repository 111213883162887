import React from 'react'
import Icon from '@/components/Icon/Icon'
import Title from '@/components/Title'
import { FormattedMessage } from 'react-intl'
import Button from '@/components/Button/Button'
import style from '../styles/notifications.module.scss'

interface IProps {
  handleCloseModal: () => void
}

const NotificationsHead = ({ handleCloseModal }: IProps): React.ReactElement => {
  return (
    <div className={style.head}>
      <Icon src='notification' />

      <Title type='h2' theme='h3' className={style.head__title}>
        <FormattedMessage
          id='titles.notifications'
          defaultMessage='Notifications'
        />
      </Title>

      <Button theme='light' circle onClick={handleCloseModal}>
        <Icon src='clear' />
      </Button>
    </div>
  )
}

export default NotificationsHead
