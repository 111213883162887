/**
 * Import all your stores
 */
//  import { AuthStore } from './authStore';
import { ApiUser } from '@garpix/cms'
import { Api } from '@/api'
import { ModalStore } from './Modal'
import { UserStore } from './User'
import { LocaleDate } from './LocaleDate'
import { CompanyStore } from './Company'
import { RouterStore } from './RouterStore'
import { RolesStore } from './RolesStore'
import { ModalCompanyStore } from '@/services/modalCompanyStore'
import { IntegrationServerErrors } from './IntegrationServerErrors'
import { RunDetailStore } from './RunDetail'

/**
 * Root Store Class with
 */
export class RootStore {
  userStore: UserStore
  modalStore: ModalStore
  localeDate: LocaleDate
  api: Api
  companyStore: CompanyStore
  routerStore: RouterStore
  rolesStore: RolesStore
  modalCompanyStore: ModalCompanyStore
  integrationServerErrorsStore: IntegrationServerErrors
  runDetailStore: RunDetailStore

  constructor (url: string, lng: string) {
    const userApi = new ApiUser(url)
    this.api = new Api(url, lng, {
      userApi
    })
    this.localeDate = new LocaleDate(lng)
    this.userStore = new UserStore(this)
    this.modalStore = new ModalStore(this)
    this.companyStore = new CompanyStore(this)
    this.routerStore = new RouterStore(this)
    this.rolesStore = new RolesStore(this)
    this.modalCompanyStore = new ModalCompanyStore(this)
    this.integrationServerErrorsStore = new IntegrationServerErrors(this)
    this.runDetailStore = new RunDetailStore(this)
  }
}
