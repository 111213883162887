import Button from '@/components/Button/Button'
import { Dropdown, DropdownMenu } from '@/components/Dropdown'
import Icon from '@/components/Icon/Icon'
import { IStep } from '@/interfaces/Case'
import React from 'react'
import style from '../styles/case_detail.module.scss'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import { useCurrentPermissions } from '@/hooks/useCurrentPermissions'
import {
  CAN_VIEW_PERMISSION_LEVEL,
  CASE_IN_RUN_STATUS,
  CREATE_PERMISSION_LEVEL,
  USER_PERMISSIONS
} from '@/const'
import { useObjectPage } from '@/hooks'
import { ICaseInRunPage } from '@/interfaces/ObjectPage'

export interface IStepButtonsProps {
  stepElement: IStep
  jiraHealthCheckStatus?: boolean
  handleJiraBugFormOpen: () => void
  handleOpenAddComment: () => void
  handleOpenBugListModal: () => void
  handleOpenCommentListModal: () => void
  setCurrentItem: React.Dispatch<React.SetStateAction<IStep>>
}

const StepButton = ({
  stepElement,
  jiraHealthCheckStatus,
  setCurrentItem,
  handleOpenAddComment,
  handleOpenBugListModal,
  handleOpenCommentListModal,
  handleJiraBugFormOpen
}: IStepButtonsProps): JSX.Element => {
  const { statistics } = useObjectPage<ICaseInRunPage>()

  const isBugExists: boolean =
    stepElement.issues_count !== undefined && stepElement.issues_count > 0

  const isCommentExists: boolean =
    stepElement.comments_count !== undefined && stepElement.comments_count > 0

  const canUserJiraIssuesView = useCurrentPermissions(
    USER_PERMISSIONS.jira_issues,
    CAN_VIEW_PERMISSION_LEVEL
  )

  const canUserCommentsView = useCurrentPermissions(
    USER_PERMISSIONS.jira_issues,
    CAN_VIEW_PERMISSION_LEVEL
  )

  const canUserJiraIssuesAdd = useCurrentPermissions(
    USER_PERMISSIONS.jira_issues,
    CREATE_PERMISSION_LEVEL
  ) && statistics.status === CASE_IN_RUN_STATUS.inprocess

  const canUserCommentsAdd = useCurrentPermissions(
    USER_PERMISSIONS.case,
    CREATE_PERMISSION_LEVEL
  ) && statistics.status === CASE_IN_RUN_STATUS.inprocess

  const isReturnDropdown =
    !canUserCommentsAdd &&
    !canUserJiraIssuesAdd &&
    (!canUserJiraIssuesView || (canUserJiraIssuesView && !isBugExists)) &&
    (!canUserCommentsView || (canUserCommentsView && !isCommentExists))

  if (isReturnDropdown) return <></>

  return (
    <Dropdown>
      <Button
        slot='trigger'
        circle
        theme={isBugExists || isCommentExists ? 'light' : 'violet'}
        onClick={() => setCurrentItem(stepElement)}
      >
        <Icon src='dotsIcon' />
      </Button>
      <DropdownMenu>
        <div className={style.menu}>
          <>
            {stepElement.comments_count !== undefined &&
            stepElement.comments_count > 0
              ? (
                <button
                  onClick={handleOpenCommentListModal}
                  className={style.menu__btn}
                  type='button'
                >
                  <Icon src='comment' />

                  <FormattedMessage
                    id='titles.comments'
                    defaultMessage='Comments'
                  />
                </button>
                )
              : null}

            {canUserCommentsAdd
              ? (
                <button
                  onClick={handleOpenAddComment}
                  className={classNames({
                    [style.menu__btn]: true,
                    [style.menu__add]: true
                  })}
                  type='button'
                >
                  <Icon src='plus' />

                  <FormattedMessage
                    id='button.add_comment'
                    defaultMessage='Add comment'
                  />
                </button>
                )
              : null}

            {canUserJiraIssuesView &&
            stepElement.issues_count !== undefined &&
            stepElement.issues_count > 0 &&
            jiraHealthCheckStatus === true
              ? (
                <button
                  onClick={handleOpenBugListModal}
                  className={style.menu__btn}
                  type='button'
                >
                  <Icon src='bug' />

                  <FormattedMessage id='titles.bugs' defaultMessage='Bugs' />
                </button>
                )
              : null}

            {canUserJiraIssuesAdd && jiraHealthCheckStatus === true
              ? (
                <button
                  onClick={handleJiraBugFormOpen}
                  className={classNames({
                    [style.menu__btn]: true,
                    [style.menu__add]: true
                  })}
                  type='button'
                >
                  <Icon src='plus' />

                  <FormattedMessage
                    id='button.add_bug'
                    defaultMessage='Add bug'
                  />
                </button>
                )
              : null}
          </>
        </div>
      </DropdownMenu>
    </Dropdown>
  )
}

export default StepButton
