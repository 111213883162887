import { format, parseISO } from 'date-fns'
import { RootStore } from './Root'

export class LocaleDate {
  locale: string
  rootStore: RootStore

  constructor (lng: string) {
    this.locale = lng
  }

  formatDate = (date: string): string => {
    let formatedDate = ''
    if (this.locale === 'ru') {
      formatedDate = format(parseISO(date), 'dd.MM.yyyy')
    } else {
      formatedDate = format(parseISO(date), 'MM-dd-yyyy')
    }
    return formatedDate
  }

  formatDateTime = (date: string): string => {
    let formatedDate = ''
    if (this.locale === 'ru') {
      formatedDate = format(parseISO(date), 'dd.MM.yyyy, HH:mm')
    } else {
      formatedDate = format(parseISO(date), 'MM-dd-yyyy, hh:mm a')
    }
    return formatedDate
  }
}
