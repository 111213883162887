import Button from '@/components/Button/Button'
import ErrorsBlock from '@/components/ErrorsBlock'
import Icon from '@/components/Icon/Icon'
import Wrapper from '@/components/Wrapper'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import style from './styles/forms.module.scss'

interface IProps {
  errors?: string[] | string
  isSubmitting: boolean
  buttonText: string
  onCancel?: () => void
}

const Footer = ({ errors = [], isSubmitting, buttonText, onCancel }: IProps): React.ReactElement => {
  const navigate = useNavigate()

  const handleCancel = (): void => {
    if (onCancel !== undefined) {
      onCancel()
    }
    navigate(-1)
  }

  return (
    <Wrapper className={style.footer}>
      <Button theme='light' className={style.footer__cancel} onClick={handleCancel}>
        <Icon src='clear' slot='icon-left' />
        <FormattedMessage id='common.cancel' defaultMessage='Cancel' />
      </Button>

      {Array.isArray(errors)
        ? (
          <ErrorsBlock errorsArray={errors} className={style.footer__errors} />
          )
        : (
          <ErrorsBlock errorsArray={[errors]} className={style.footer__errors} />
          )}

      <Button disabled={isSubmitting} type='submit'>
        <Icon src='checked' slot='icon-left' />
        {buttonText}

      </Button>
    </Wrapper>
  )
}

export default Footer
