import { IObjectPage } from '@/interfaces/ObjectPage'
import { usePage as usePageCms } from '@garpix/cms'

const useObjectPage = <T extends IObjectPage>(): T => {
  const value = usePageCms()
  const page = value.page
  return page.init_state.object
}

export default useObjectPage
