import AuthRegLayout from '@/apps/layout/components/AuthRegLayout'
import { regImage } from '@/images'
import React from 'react'
import RegistrationConfirm from '../RegistrationConfirm'
import YandexMetrika from '@/components/YandexMetrika'

const RegistrationConfirmPage = (): React.ReactElement => {
  return (
    <>
      <YandexMetrika />
      <AuthRegLayout image={regImage}>
        <RegistrationConfirm />
      </AuthRegLayout>
    </>
  )
}

export default RegistrationConfirmPage
