import React from 'react'
import AddOrEditCase from '../AddOrEditCase'
import ProjectLayout from '../layout/components/ProjectLayout'
import UserLayout from '../layout/components/UserLayout'

const CreateCasePage = (): React.ReactElement => {
  return (
    <UserLayout>
      <ProjectLayout>
        <AddOrEditCase />
      </ProjectLayout>
    </UserLayout>
  )
}

export default CreateCasePage
