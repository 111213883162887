import React from 'react'
import style from './styles/tags.module.scss'
import { TextTag } from '../Tag'
import useTags from '@/hooks/useTags'
import classNames from 'classnames'
import { TagStyle } from '@/interfaces/TagInterfaces'

interface IProps {
  onChange?: () => void
  setSelectTags: (value: number[]) => void
  selectTags: number[]
  className?: string
}

const Tags = ({
  onChange,
  setSelectTags,
  selectTags,
  className = ''
}: IProps): React.ReactElement => {
  const { tags } = useTags()

  const handleTags = (value: number[]): void => {
    setSelectTags(value)
    onChange?.()
  }

  const toggleTags = (id: number): void => {
    const isSelected = selectTags.includes(id)
    if (isSelected) {
      handleTags([...selectTags.filter((t) => t !== id)])
    } else {
      handleTags([...selectTags, id])
    }
  }

  const handleDeleteTag = (id: number): void => {
    handleTags([...selectTags.filter((t) => t !== id)])
  }

  const tagsArray = tags?.results ?? []

  return (
    <>
      {tagsArray.length > 0
        ? (
          <div
            className={classNames({
              [style.tags]: true,
              [className]: Boolean(className)
            })}
          >
            {tagsArray.map((el) => {
              const isSelected = selectTags.includes(el.id as number)
              return (
                <TextTag
                  tagStyle={TagStyle.SelectedOutline}
                  onClick={() => toggleTags(el.id as number)}
                  onDelete={() => handleDeleteTag(el.id as number)}
                  isDeletable={isSelected}
                  key={el.id}
                >
                  {el.title}
                </TextTag>
              )
            })}
          </div>
          )
        : null}
    </>
  )
}

export default Tags
