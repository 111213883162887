import Container from '@/components/Container'
import React from 'react'
import { IUserProfile } from '@/interfaces/User'
import UserInfo from './components/UserInfo'

export interface IFormInputs extends Partial<IUserProfile> {
  non_field_errors?: string
  delete_avatar: boolean
}

const UsersProfile = (): React.ReactElement => {
  return (
    <Container>
      <UserInfo />
    </Container>
  )
}

export default UsersProfile
