import React from 'react'
import UserLayout from '../layout/components/UserLayout'
import UsersProfile from '../UsersProfile'

const AnotherProfile = (): React.ReactElement => {
  return (
    <UserLayout>
      <UsersProfile />
    </UserLayout>
  )
}

export default AnotherProfile
