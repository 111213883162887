import React from 'react'
import classNames from 'classnames'
import style from './styles/loading.module.scss'

interface IProps {
  size?: 'xl' | 'large' | 'medium' | 'small' | 'xs' | 'xxs' | 'custom'
  form?: 'line' | 'circle'
  className?: string
}

const Shimmer = ({
  size = 'medium',
  form = 'line',
  className = ''
}: IProps): React.ReactElement => {
  return (
    <span
      className={classNames({
        [style.shimmer]: true,
        [style[`shimmer--${size}`]]: size !== 'custom',
        [style[`shimmer--${form}`]]: form !== 'line',
        [className]: Boolean(className)
      })}
    />
  )
}

export default Shimmer
