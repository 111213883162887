import React from 'react'
import style from './styles/notification_item.module.scss'
import { INotifiesInviteDeclinedData } from '@/interfaces/Notifies'
import { ITimeSince } from '@/interfaces/Utils'
import NotifyTime from './components/NotifyTime'
import { FormattedMessage } from 'react-intl'
import Tooltip from '../Tooltip'
import { AvatarCompany, AvatarUser } from '../Avatar'
import { getInitials } from '../UserName'

interface IProps {
  data: INotifiesInviteDeclinedData
  time: ITimeSince
}

const NotifyInviteDeclined = ({ time, data }: IProps): React.ReactElement => {
  const { company, author } = data
  const initialsUser = getInitials(
    author.first_name,
    author.last_name,
    author.email
  )

  return (
    <>
      <Tooltip content={`${author.first_name} ${author.last_name}`}>
        <AvatarUser
          src={author.avatar}
          className={style.item__avatar}
          initials={initialsUser}
        />
      </Tooltip>

      <div className={style.item__content}>
        <p className={style.info}>
          <FormattedMessage
            id='notifications.item.invite_declined'
            defaultMessage='The user <span>declined</span> the invitation'
            values={{
              span: (chunks) => (
                <span className={style.invtext__decline}>{chunks}</span>
              )
            }}
          />
        </p>

        <div className={style.run}>
          <AvatarCompany src={company.avatar} />

          <span>{company.title}</span>
        </div>

        <NotifyTime time={time} />
      </div>
    </>
  )
}

export default NotifyInviteDeclined
