import React, { useEffect, useRef, useState } from 'react'
import { DayPicker } from 'react-day-picker'
import { enUS, ru } from 'date-fns/locale'
import { useLocale, useStores } from '@/hooks'
import CalendarDetail from '@/apps/Dashboard/components/CalendarDetail'
import Icon from '@/components/Icon/Icon'
import CustomDayContent from '@/apps/Dashboard/components/CustomDayContent'
import { IMilestonesList } from '@/interfaces/Milestones'
import {
  addDays,
  endOfMonth,
  endOfWeek,
  format,
  startOfMonth,
  startOfWeek,
  subDays
} from 'date-fns'
import { useNavigate } from 'react-router-dom'
import useSWR from 'swr'
import { Shimmer } from '@/components/Loading'
import style from '../styles/dashboard.module.scss'

interface IProps {
  open: boolean
  onClose: () => void
}

interface ReleaseByDateMap {
  [date: string]: IMilestonesList[]
}

interface IStartOfWeekOptions {
  weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6
}

interface ISelectedMonth {
  endDateAfter: string
  endDateBefore: string
}

const calendarLocale = {
  ru: ru,
  en: enUS
}

const startOfWeekOptions: IStartOfWeekOptions = {
  weekStartsOn: 1
}
const currentDate = new Date()
const firstDayOfMonth = startOfMonth(currentDate)
const lastDayOfMonth = endOfMonth(currentDate)

const initialSelectedMonth: ISelectedMonth = {
  endDateAfter: format(
    startOfWeek(firstDayOfMonth, startOfWeekOptions),
    'yyyy-MM-dd'
  ),
  endDateBefore: format(
    endOfWeek(lastDayOfMonth, startOfWeekOptions),
    'yyyy-MM-dd'
  )
}

const ReleaseCalendar = ({
  open,
  onClose
}: IProps): React.ReactElement | null => {
  const [currentMonth, setCurrentMonth] = useState<Date>(new Date())
  const [selectedDate, setSelectedDate] = useState<Date | null>(null)
  const [selectedReleases, setSelectedReleases] = useState<IMilestonesList[]>([])
  const [showDetail, setShowDetail] = useState(false)
  const [selectedMonth, setSelectedMonth] =
    useState<ISelectedMonth>(initialSelectedMonth)
  const [firstVisibleDay, setFirstVisibleDay] = useState<Date | null>(
    new Date(initialSelectedMonth.endDateAfter)
  )
  const [lastVisibleDay, setLastVisibleDay] = useState<Date | null>(
    new Date(initialSelectedMonth.endDateBefore)
  )

  const { api, companyStore } = useStores()
  const navigate = useNavigate()
  const { locale } = useLocale()

  const releaseByDate: { [key: string]: IMilestonesList[] } = {}
  const currentCompany = companyStore.getCompany()

  const { data, error, isLoading } = useSWR(
    {
      end_date_after: selectedMonth?.endDateAfter,
      end_date_before: selectedMonth?.endDateBefore,
      page_size: 10000,
      company: currentCompany,
      _key: 'getMilestones'
    },
    api.getMilestones
  )

  const calendarRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const handleClickOutside = (e): void => {
      if (
        calendarRef.current != null &&
        !(calendarRef.current as Node).contains(e.target)
      ) {
        onClose()
      }
    }

    if (open) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [onClose])

  const groupReleasesByDate = (
    releases: IMilestonesList[]
  ): ReleaseByDateMap => {
    if (Array.isArray(releases) && releases.length > 0) {
      releases.forEach((release) => {
        const date = release.end_date

        if (releaseByDate[date] === undefined) {
          releaseByDate[date] = []
        }
        releaseByDate[date].push(release)
      })
    }

    return releaseByDate
  }

  const groupReleases = groupReleasesByDate(data?.results ?? [])

  const handleDayClick = (date: Date): void => {
    const releasesForDay = groupReleases[format(date, 'yyyy-MM-dd')] ?? []

    if (releasesForDay.length === 1) {
      const release = releasesForDay[0]
      navigate(release.absolute_url)
    } else if (releasesForDay.length > 1) {
      setSelectedDate(date)
      setShowDetail(!showDetail)
      setSelectedReleases(releasesForDay)
    } else {
      setSelectedDate(date)
      setShowDetail(!showDetail)
    }
  }

  const handleGoBack = (): void => {
    setShowDetail(false)
    setSelectedReleases([])
  }

  useEffect(() => {
    setSelectedMonth({
      endDateAfter:
        firstVisibleDay != null ? format(firstVisibleDay, 'yyyy-MM-dd') : '',
      endDateBefore:
        lastVisibleDay != null ? format(lastVisibleDay, 'yyyy-MM-dd') : ''
    })
  }, [firstVisibleDay, lastVisibleDay])

  const handleMonthChange = (newMonthDate: Date): void => {
    setCurrentMonth(newMonthDate)
    setFirstVisibleDay(
      new Date(
        format(startOfWeek(newMonthDate, startOfWeekOptions), 'yyyy-MM-dd')
      )
    )
    setLastVisibleDay(
      new Date(
        format(endOfWeek(lastDayOfMonth, startOfWeekOptions), 'yyyy-MM-dd')
      )
    )
  }

  const handleNextDay = (): void => {
    if (selectedDate != null) {
      const nextDay = addDays(selectedDate, 1)
      const releasesForDay = groupReleases[format(nextDay, 'yyyy-MM-dd')] ?? []
      setSelectedDate(nextDay)
      setSelectedReleases(releasesForDay)
      setCurrentMonth(nextDay)
    }
  }

  const handlePrevDay = (): void => {
    if (selectedDate != null) {
      const prevDay = subDays(selectedDate, 1)
      const releasesForDay = groupReleases[format(prevDay, 'yyyy-MM-dd')] ?? []
      setSelectedDate(prevDay)
      setSelectedReleases(releasesForDay)
      setCurrentMonth(prevDay)
    }
  }

  return (
    <div className={style.calendar} ref={calendarRef}>
      {showDetail
        ? (
          <CalendarDetail
            locale={calendarLocale[locale]}
            error={error}
            isLoading={isLoading}
            onPrevDay={handlePrevDay}
            onNextDay={handleNextDay}
            date={selectedDate}
            milestones={selectedReleases}
            handleGoBack={handleGoBack}
          />
          )
        : (
          <DayPicker
            weekStartsOn={1}
            classNames={{
              day_today: style.rdpday__today,
              months: style.rdpday__months,
              day: style.rdpday,
              cell: style.rdpCell
            }}
            components={{
              IconRight: () => <Icon src='arrow_right' />,
              IconLeft: () => <Icon src='arrow_left' />,
              DayContent: (props) =>
                isLoading
                  ? (
                    <Shimmer size='custom' className={style.shimmer} />
                    )
                  : (
                    <CustomDayContent
                      {...props}
                      releases={
                    groupReleases[format(props.date, 'yyyy-MM-dd')] ?? []
                  }
                    />
                    )
            }}
            locale={calendarLocale[locale]}
            showOutsideDays
            onDayClick={handleDayClick}
            onMonthChange={handleMonthChange}
            month={currentMonth}
          />
          )}
    </div>
  )
}

export default ReleaseCalendar
