import { usePage } from '@/hooks'
import React from 'react'
import AddOrEditMilestone from '../AddOrEditMilestone'
import ProjectLayout from '../layout/components/ProjectLayout'
import UserLayout from '../layout/components/UserLayout'

const MilestonePageUpdate = (): React.ReactElement => {
  const page = usePage()

  const title = page.init_state.object.title
  const projectId = page.init_state.object.project
  const id = page.init_state.object.id

  return (
    <UserLayout>
      <ProjectLayout>
        <AddOrEditMilestone project={projectId} title={title} id={id} />
      </ProjectLayout>
    </UserLayout>
  )
}

export default MilestonePageUpdate
