import { RootStore } from './Root'
import { makeAutoObservable, action } from 'mobx'

export interface IIntegrationServerErrorsStore {
  integrationError: boolean
}

export class IntegrationServerErrors implements IIntegrationServerErrorsStore {
  integrationError = false
  rootStore: RootStore

  constructor (rootStore: RootStore) {
    this.rootStore = rootStore
    this.setIntegrationError = action(this.setIntegrationError.bind(this))
    makeAutoObservable(this)
  }

  setIntegrationError (value: boolean): void {
    this.integrationError = value
  }

  getIntegrationError (): boolean {
    return this.integrationError
  }
}
