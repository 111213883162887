import React from 'react'
import style from './styles/notification_item.module.scss'
import Icon from '../Icon/Icon'
import { CaseStatus } from '../Status'
import { FormattedMessage } from 'react-intl'
import { INotifiesCaseChangeStatusData } from '@/interfaces/Notifies'
import { ITimeSince } from '@/interfaces/Utils'
import Link from '../Link'
import NotifyTime from './components/NotifyTime'
import Tooltip from '../Tooltip'
import { getInitials } from '../UserName'
import AvatarUser from '@/components/Avatar/AvatarUser'

interface IProps {
  data: INotifiesCaseChangeStatusData
  time: ITimeSince
}

const NotifyCaseChangeStatus = ({ data, time }: IProps): React.ReactElement => {
  const { author, project, 'test-case': tCase } = data
  const initialsUser = getInitials(
    author.first_name,
    author.last_name,
    author.email
  )

  return (
    <>
      <Tooltip content={`${author.first_name} ${author.last_name}`}>
        <AvatarUser
          src={author.avatar}
          className={style.item__avatar}
          initials={initialsUser}
        />
      </Tooltip>

      <div className={style.item__content}>
        <p className={style.info}>
          <FormattedMessage
            id='notifications.item.change_status'
            values={{
              link: (
                <Link to={project.absolute_url} className={style.item__text}>
                  {project.title}
                </Link>
              )
            }}
            defaultMessage='Changed status in {link} project'
          />
        </p>

        <div className={style.run}>
          <Link to={tCase.absolute_url} className={style.run__link}>
            <Icon src='test_case' className={style.run__icon} />
            <span className={style.run__name}>{tCase.title}</span>
          </Link>

          <CaseStatus status={tCase.status} />
        </div>

        <NotifyTime time={time} />
      </div>
    </>
  )
}

export default NotifyCaseChangeStatus
