import { CASE_TYPES } from '@/const'
import { TCaseType } from '@/interfaces/Types'
import { useEffect, useState } from 'react'

interface IUseCaseType {
  isCase: boolean
  isTask: boolean
  isChecklist: boolean
}

const useCaseType = (initialCaseType: TCaseType): IUseCaseType => {
  const [isCase, setIsCase] = useState(initialCaseType === CASE_TYPES.case)
  const [isTask, setIsTask] = useState(initialCaseType === CASE_TYPES.task)
  const [isChecklist, setIsChecklist] = useState(
    initialCaseType === CASE_TYPES.checklist
  )

  useEffect(() => {
    setIsCase(initialCaseType === CASE_TYPES.case)
    setIsTask(initialCaseType === CASE_TYPES.task)
    setIsChecklist(initialCaseType === CASE_TYPES.checklist)
  }, [initialCaseType])

  return {
    isCase,
    isTask,
    isChecklist
  }
}

export default useCaseType
