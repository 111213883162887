import { IProject } from '@/interfaces/Project'

export const getStatusFromMappings = (
  data: IProject,
  healthCheck: boolean
): boolean | null => {
  const isIntegrationSuccess = data.gitlab_enable && healthCheck
  const isIntegrationFailure = !healthCheck && data.gitlab_enable
  const noIntegrationYet = !data.gitlab_enable && data.gitlab_token == null

  const statusMappings = [
    { condition: isIntegrationSuccess, value: true },
    { condition: isIntegrationFailure, value: false },
    { condition: noIntegrationYet, value: null }
  ]

  const matchingMapping = statusMappings.find((mapping) => mapping.condition)

  return matchingMapping != null ? matchingMapping.value : null
}
