import Button from '@/components/Button/Button'
import Icon from '@/components/Icon/Icon'
import React from 'react'
import classNames from 'classnames'
import { LinkButton } from '../Button/LinkButton'
import style from './styles/emptylist.module.scss'

interface IProps {
  icon: string
  text: string
  buttonText?: string
  className?: string
  onClick?: () => void
  href?: string
  size?: 'xs' | 'md' | 'lg'
}

const EmptyList = ({
  icon,
  text,
  buttonText,
  className = '',
  onClick,
  href,
  size = 'lg'
}: IProps): React.ReactElement => {
  return (
    <div
      className={classNames({
        [style.emptylist]: true,
        [style[`emptylist--${size}`]]: size,
        [className]: Boolean(className)
      })}
    >
      <Icon src={icon} className={style.emptylist__foldericon} />

      <div className={style.emptylist__text}>{text}</div>
      {buttonText !== undefined
        ? (
            href === undefined
              ? (
                <Button onClick={onClick}>
                  <Icon src='plus' slot='icon-left' />
                  {buttonText}
                </Button>
                )
              : (
                <LinkButton href={href}>
                  <Icon src='plus' slot='icon-left' />
                  {buttonText}
                </LinkButton>
                )
          )
        : null}
    </div>
  )
}

export default EmptyList
