import { IRunsElement } from '@/interfaces/Runs'
import { RootStore } from './Root'
import { makeAutoObservable, action } from 'mobx'

export class RunDetailStore {
  run?: IRunsElement
  rootStore: RootStore

  constructor (rootStore: RootStore) {
    this.rootStore = rootStore
    this.setRun = action(this.setRun.bind(this))
    makeAutoObservable(this)
  }

  setRun (run: IRunsElement | undefined): void {
    this.run = run
  }

  getRun (): IRunsElement | undefined {
    return this.run
  }
}
