import React from 'react'
import ReactDOM from 'react-dom'
import { GxModal } from '@garpix/garpix-web-components-react'
import { NoSSR } from '@garpix/cms'
import classNames from 'classnames'
import style from './styles/modal.module.scss'
import { TSizeModal } from './interface'

interface BaseModalProps {
  handleClose?: () => void
  theme?: string
  children: React.ReactNode
  open: boolean | undefined
  hideDefaultClose?: boolean
  className?: string
  size?: TSizeModal
  side?: 'center' | 'right'
}

const PortalModal: React.FC<BaseModalProps> = ({
  open,
  handleClose,
  children,
  theme = 'default',
  hideDefaultClose = false,
  className = '',
  size = 'large',
  side = 'center',
  ...props
}) => {
  return ReactDOM.createPortal(
    <GxModal
      open={open ?? undefined}
      className={classNames({
        [style.modal]: true,
        [style[`modal--${size}`]]: true,
        [style[`modal--${side}`]]: side !== 'center',
        [style.hide_close]: hideDefaultClose,
        [className]: Boolean(className)
      })}
      {...props}
    >
      {children}
    </GxModal>,
    document.body
  )
}

const BaseModal: React.FC<BaseModalProps> = (props) => {
  return (
    <NoSSR>
      <PortalModal {...props} />
    </NoSSR>
  )
}

export default BaseModal
