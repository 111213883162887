import React from 'react'
import Companies from '../Companies'
import UserLayout from '../layout/components/UserLayout'

const CompaniesPage = (): React.ReactElement => {
  return (
    <UserLayout>
      <Companies />
    </UserLayout>
  )
}

export default CompaniesPage
