import Button from '@/components/Button/Button'
import Icon from '@/components/Icon/Icon'
import BaseAsyncCreatableSelect from '@/components/Select/AsyncSelect/BaseAsyncCreatableSelect'
import { TextTag } from '@/components/Tag'
import Title from '@/components/Title'
import { useStores } from '@/hooks'
import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import style from '../styles/add_case.module.scss'
import { ITag } from '@/interfaces/Case'

interface IProps {
  tags: ITag[]
  project: number
  onChange: (e) => void
}

const Tags = ({
  project,
  onChange,
  tags = []
}: IProps): React.ReactElement => {
  const [showSelect, setShowSelect] = useState(false)
  const { api } = useStores()
  const intl = useIntl()

  const handleClick = (): void => {
    setShowSelect(true)
  }

  const loadOptions = (
    inputValue: string,
    callback: (options: Array<{ value: number | undefined, label: string | undefined }>) => void
  ): any => {
    api
      .getTags({
        page: 1,
        page_size: 100,
        q: inputValue,
        project: project
      })

      .then((paginatedObject) => {
        callback(
          paginatedObject.results.map((el) => {
            return { value: el.id, label: el.title }
          })
        )
      })
      .catch((err) => console.log(err))
  }

  const handleSelect = async (e): Promise<void> => {
    const tagsTitle = tags.map((el) => el.title)
    const title = e.label
    const id = e.value
    if (!tagsTitle.includes(title)) {
      const newTag: { title: string, id?: number } = { title: title, id: id }
      if (e?._isNew === true) {
        const tag = await api.createTag({
          project: project,
          title: e.label
        })
        newTag.id = tag.id
      }
      onChange([...tags, newTag])
    }
  }

  const onDelete = (title): void => {
    onChange([...tags.filter((el) => el.title !== title)])
  }

  return (
    <>
      <Title type='h3' className={style.form__right_title}>
        <Icon src='tag' />
        <FormattedMessage id='titles.tags' defaultMessage='Tags' />
      </Title>

      <div className={style.tags__block}>
        {tags.map((el) => {
          return (
            <TextTag
              key={el.title}
              isDeletable
              onDelete={() => onDelete(el.title)}
            >
              {el.title}
            </TextTag>
          )
        })}

        <Button size='sm' circle onClick={handleClick}>
          <Icon src='plus' />
        </Button>
      </div>

      {showSelect
        ? (
          <BaseAsyncCreatableSelect
            placeholder={
              intl.formatMessage({
                id: 'tags.placeholder',
                defaultMessage: 'Tag name'
              })
            }
            handleChange={handleSelect}
            loadOptions={loadOptions}
            labelClassName={style.tags__select}
          />
          )
        : null}
    </>
  )
}

export default Tags
