import DropdownStatus from '@/components/Dropdown/DropdownStatus'
import React from 'react'
import style from '../styles/case_detail.module.scss'
import { useStores } from '@/hooks'
import { TBadgeStatus } from '@/interfaces/Types'
import { IStep } from '@/interfaces/Case'

export interface IStepStatusProps {
  stepElement: IStep
  updateStepData: () => Promise<void>
}

const StepStatus = ({
  stepElement,
  updateStepData
}: IStepStatusProps): JSX.Element => {
  const { api } = useStores()

  const handleDropdownChange = (value: string): void => {
    void api
      .postStepRunStatus({
        id: stepElement.id,
        status: value.toUpperCase() as TBadgeStatus
      })
      .then(() => {
        void updateStepData()
      })
  }

  return (
    <div className={style.steps__status}>
      <DropdownStatus
        status={stepElement.status}
        handleChange={(value: TBadgeStatus) => {
          handleDropdownChange(value)
        }}
      />
    </div>
  )
}

export default StepStatus
