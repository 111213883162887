import Button from '@/components/Button/Button'
import CaseItemReport from '@/components/CaseItem/CaseItemReport'
import GroupStatuses from '@/components/GroupStatuses/GroupStatuses'
import Icon from '@/components/Icon/Icon'
import Pagination from '@/components/Pagination'
import useSWR from 'swr'
import Title from '@/components/Title'
import Wrapper from '@/components/Wrapper'
import { usePage, useStores } from '@/hooks'
import React, { useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import style from '../style/report_detail.module.scss'
import { downloadFile, scrollToBlock } from '@/utils'
import { LoaderReportDetailSumary } from '../loaders'

const pageSize = 10

const Summary = (): React.ReactElement => {
  const page = usePage()
  const { api, localeDate } = useStores()
  const reports = useRef(null)

  const [pageNumber, setPageNumber] = useState(1)
  const [isDownload, setIsDownload] = useState(false)

  const run = page.init_state.run_object
  const shareHash = page.init_state.share_hash
  const statistics = run.statistics

  const { data, isLoading } = useSWR(
    {
      run_id: run.id,
      page_size: pageSize,
      page: pageNumber,
      share_hash: shareHash,
      _key: 'getShareCasesInRun'
    },
    api.getShareCasesInRun
  )

  const handleChangePageNumber = (page: number): void => {
    scrollToBlock(reports)
    setPageNumber(page)
  }

  const handleDownloadTable = (): void => {
    setIsDownload(true)
    api
      .getShareReportExcel({
        run_id: run.id,
        share_hash: shareHash
      })
      .then((res) => {
        const date = new Date().toJSON().slice(0, 10)
        const fileName = `Run_${String(run.id)}_summary_${date}.xlsx`

        downloadFile(res.data, fileName)
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => setIsDownload(false))
  }

  return (
    <Wrapper ref={reports}>
      <div className={style.summary__head}>
        <Title type='h2' className={style.summary__title}>
          <FormattedMessage
            id='titles.case_summary'
            defaultMessage='Test check Summary'
          />
        </Title>

        <GroupStatuses
          statistic={statistics}
          className={style.summary__statuses}
        />

        <Button
          theme='light'
          className={style.summary__download}
          onClick={handleDownloadTable}
          disabled={isDownload}
        >
          <Icon src='download' slot='icon-left' />
          <FormattedMessage
            id='reports.download_table'
            defaultMessage='Download table'
          />
        </Button>
      </div>

      {!isLoading && data !== undefined
        ? (
          <div className={style.summary__list}>
            {data.results.map((el) => {
              const formatedDate = localeDate.formatDate(
                el.status_updated_at
              )
              return (
                <CaseItemReport
                  key={el.id}
                  code={el.code}
                  title={el.title}
                  date={formatedDate}
                  status={el.status}
                />
              )
            })}
          </div>
          )
        : null}

      {isLoading ? <LoaderReportDetailSumary countShimmers={pageSize} /> : null}

      <Pagination
        currentPage={pageNumber}
        total={data?.count ?? 0}
        pageSize={pageSize}
        handleChange={handleChangePageNumber}
        className={style.summary__pagination}
      />
    </Wrapper>
  )
}

export default Summary
