import classNames from 'classnames'
import React from 'react'
import style from './styles/member.module.scss'
import { AvatarUser } from '../Avatar'

interface IMemberProps {
  name: string
  avatar: string | null
  className?: string
  initials?: string
}

const CompanyMemberShort = ({
  name,
  avatar,
  className = '',
  initials
}: IMemberProps): React.ReactElement => {
  return (
    <div
      className={classNames({
        [style.member]: true,
        [className]: Boolean(className)
      })}
    >
      <div className={style.member__info}>
        <AvatarUser src={avatar} size='large' initials={initials} />

        <div className={style.member__name}>{name}</div>
      </div>
    </div>
  )
}

export default CompanyMemberShort
