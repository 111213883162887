import Button from '@/components/Button/Button'
import CaseItemReport from '@/components/CaseItem/CaseItemReport'
import GroupStatuses from '@/components/GroupStatuses/GroupStatuses'
import Icon from '@/components/Icon/Icon'
import Pagination from '@/components/Pagination'
import useSWR from 'swr'
import Title from '@/components/Title'
import Wrapper from '@/components/Wrapper'
import { useObjectPage, useStores } from '@/hooks'
import { IReportPage } from '@/interfaces/ObjectPage'
import React, { useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import style from '../style/report_detail.module.scss'
import { downloadFile, scrollToBlock } from '@/utils'
import PageSizeSelect from '@/components/Select/PageSizeSelect'
import { LoaderReportSumary } from '../loaders'
import { pageSizeSelectService } from '@/services/PageSizeSelect'

const Summary = (): React.ReactElement => {
  const { statistics, id } = useObjectPage<IReportPage>()
  const { api, localeDate } = useStores()
  const reports = useRef(null)

  const [pageNumber, setPageNumber] = useState(1)
  const [isDownload, setIsDownload] = useState(false)
  const [pageSize, setPageSize] = useState<number>(
    pageSizeSelectService.getSize('reportDetail') ?? 10
  )

  const { data, isLoading } = useSWR(
    {
      id: id,
      page_size: pageSize,
      page: pageNumber,
      _key: 'getCasesInRun'
    },
    api.getCasesInRun
  )

  const handleChangePageNumber = (page: number): void => {
    scrollToBlock(reports)
    setPageNumber(page)
  }

  const handleDownloadTable = (): void => {
    setIsDownload(true)
    api
      .getReportExcel({
        id
      })
      .then((res) => {
        const date = new Date().toJSON().slice(0, 10)
        const fileName = `Run_${id}_summary_${date}.xlsx`

        downloadFile(res.data, fileName)
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => setIsDownload(false))
  }

  const handleChangePageSize = (e: any): void => {
    const newPageSize = Number(e.target.value)
    setPageSize(newPageSize)
    pageSizeSelectService.setSize('reportDetail', newPageSize)
    setPageNumber(1)
  }

  return (
    <Wrapper ref={reports}>
      <div className={style.summary__head}>
        <Title type='h2' className={style.summary__title}>
          <FormattedMessage
            id='titles.case_summary'
            defaultMessage='Test checks Summary'
          />
        </Title>

        <GroupStatuses
          statistic={statistics}
          className={style.summary__statuses}
        />

        <Button
          theme='light'
          className={style.summary__download}
          onClick={handleDownloadTable}
          disabled={isDownload}
        >
          <Icon src='download' slot='icon-left' />
          <FormattedMessage
            id='reports.download_table'
            defaultMessage='Download table'
          />
        </Button>
      </div>

      {!isLoading && data !== undefined
        ? (
          <div className={style.summary__list}>
            {data.results.map((el) => {
              const formatedDate = localeDate.formatDate(el.status_updated_at)
              return (
                <CaseItemReport
                  key={el.id}
                  code={el.code}
                  title={el.title}
                  date={formatedDate}
                  status={el.status}
                />
              )
            })}
          </div>
          )
        : null}

      {isLoading ? <LoaderReportSumary countShimmers={pageSize} /> : null}

      <div className={style.summary__bottom}>
        <PageSizeSelect
          value={String(pageSize)}
          handleChange={handleChangePageSize}
        />

        <Pagination
          currentPage={pageNumber}
          total={data?.count ?? 0}
          pageSize={pageSize}
          handleChange={handleChangePageNumber}
        />
      </div>
    </Wrapper>
  )
}

export default Summary
