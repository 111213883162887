import React from 'react'
import { FormattedMessage } from 'react-intl'
import Icon from '@/components/Icon/Icon'
import Button from '@/components/Button/Button'
import style from '../styles/runitem.module.scss'

const AssignToBtn = ({ handleClick }: { handleClick: () => void }): JSX.Element => {
  return (
    <Button onClick={handleClick} className={style.assing_btn}>
      <Icon src='user' slot='icon-left' />
      <FormattedMessage id='button.assign_to' defaultMessage='Assign to' />
    </Button>
  )
}

export default AssignToBtn
