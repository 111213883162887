
import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import Icon from '@/components/Icon/Icon'
import { LinkButton } from '@/components/Button/LinkButton'
import { usePage } from '@garpix/cms'
import style from '../styles/registration.module.scss'

const Success = (): React.ReactElement => {
  const obj = usePage()
  const hrefLogin = obj.page.init_state.global.login_url

  useEffect(() => {
    // Отправляем в Яндекс Метрику цель что человек зарегался. Должно работать только на проде
    if ('ym' in window) {
      window.ym(94694018, 'reachGoal', 'registration')
    }
  }, [])

  return (
    <div className={style.form__end}>
      <div className={style.form__icon}>
        <Icon src='checked' size='small' className={style.form__bigicon} />
      </div>

      <div className={style.form__endtext}>
        <FormattedMessage
          id='registration.success.text'
          defaultMessage='Your account has been successfully activated!'
        />
      </div>

      <LinkButton href={hrefLogin} size='lg'>
        <Icon src='arrow_right' slot='icon-left' />

        <FormattedMessage
          id='registration.success.button'
          defaultMessage='To login'
        />
      </LinkButton>
    </div>
  )
}

export default Success
