import React from 'react'
import style from '../styles/companies.module.scss'
import Pagination from '@/components/Pagination'
import PaginatedQueryResult from '@/api/interfaces/PaginatedQueryResult'
import { ICompanyList } from '@/interfaces/Company'
import CompanyItem from '@/components/CompanyItem'
import Link from '@/components/Link'
import Icon from '@/components/Icon/Icon'
import { FormattedMessage } from 'react-intl'
import useGlobalConfig from '@/hooks/useGlobalConfig'

interface IProps {
  data: PaginatedQueryResult<ICompanyList>
  currentPage: number
  pageSize: number
  setPage: (string) => void
  onChange: () => void
  userId: number
}

const CompaniesList = ({
  data,
  currentPage,
  pageSize,
  userId,
  setPage,
  onChange
}: IProps): React.ReactElement => {
  const obj = useGlobalConfig()

  return (
    <>
      <div className={style.company__list}>
        {data.results.map((el) => {
          return (
            <CompanyItem
              key={el.id}
              userId={userId}
              id={el.id}
              title={el.title}
              code={el.code}
              owner={el.owner}
              image={el.image}
              projectsCount={el.projects_count}
              membersCount={el.users_count}
              urlCompany={el.absolute_url}
              status={el.status}
              deleteTimeLeft={el.time_deleted_left}
              inviteId={el.invite_id}
              onChange={onChange}
            />
          )
        })}
        <Link to={obj.create_company_url} className={style.company__add}>
          <Icon className={style.company__plus} src='plus' />

          <div className={style.company__addtext}>
            <FormattedMessage
              id='companies.add_company'
              defaultMessage='Add company'
            />
          </div>
        </Link>
      </div>

      <div className={style.company__pagination}>
        <Pagination
          currentPage={currentPage}
          total={data.count}
          pageSize={pageSize}
          handleChange={setPage}
        />
      </div>
    </>
  )
}

export default CompaniesList
