import React from 'react'
import { Layout } from '.'
import style from '../styles/layout.module.scss'
import { Logo } from './Header/Logo'
import Container from '@/components/Container'

interface LayoutProps {
  children: React.ReactNode
}

const ShareLayout = ({ children }: LayoutProps): React.ReactElement => {
  return (
    <Layout>
      <header className={style.share__header}>
        <Container>
          <Logo className={style.share__logo} />
        </Container>
      </header>
      <main className={style.share__main}>{children}</main>
    </Layout>
  )
}

export default ShareLayout
