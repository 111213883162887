import FileItem from '@/components/FileItem'
import { Shimmer } from '@/components/Loading'
import classNames from 'classnames'
import React from 'react'
import { IFilePreview } from '../interfaces'
import style from '../styles/upload_zone.module.scss'

interface IFilesListProps {
  isScrollableList?: boolean
  currentFiles: IFilePreview[]
  isForm?: boolean
  handleOpenModalDelete: (id: number, name: string) => void
  fileDownloadRef: React.RefObject<HTMLAnchorElement>
  canUserDeleteFile?: boolean
  isUpload?: boolean
  readonly?: boolean
}

const FilesList = ({
  isScrollableList,
  currentFiles,
  isForm,
  handleOpenModalDelete,
  fileDownloadRef,
  canUserDeleteFile,
  isUpload,
  readonly = false
}: IFilesListProps): React.ReactElement => {
  return (
    <div
      className={classNames({
        [style.files__list]: true,
        [style['files__list--scrollable']]: isScrollableList,
        [style['files__list--readonly']]: readonly
      })}
    >
      {currentFiles.map((el) => {
        return (
          <div
            key={el.id}
            className={classNames({
              [style.files__item]: isForm === false
            })}
          >
            <FileItem
              id={el.id}
              name={el.name !== undefined ? el.name : ''}
              size={el.size}
              date={el.date}
              previewUrl={el.previewUrl}
              downloadUrl={el.url}
              handleDelete={handleOpenModalDelete}
              isLoaded={el.isLoaded}
              // handleOpenModalPreview={handleOpenModalPreview}
              fileDownloadRef={fileDownloadRef}
              canUserDeleteFile={canUserDeleteFile === true && !readonly}
            />
          </div>
        )
      })}
      {isUpload === true ? <Shimmer className={style.files__loader} /> : null}
    </div>
  )
}

export default FilesList
