import React from 'react'
import UserLayout from '../layout/components/UserLayout'
import AddOrEditCompany from '../AddOrEditCompany'
import { useObjectPage } from '@/hooks'

const CompanyUpdatePage = (): React.ReactElement => {
  const { id } = useObjectPage()

  return (
    <UserLayout>
      <AddOrEditCompany id={id} />
    </UserLayout>
  )
}

export default CompanyUpdatePage
