import React from 'react'
import EditProfile from '../EditProfile'
import UserLayout from '../layout/components/UserLayout'

const Profile = (): React.ReactElement => {
  return (
    <UserLayout>
      <EditProfile />
    </UserLayout>
  )
}

export default Profile
