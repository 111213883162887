import { MODAL_STAGE } from '@/const'
import { usePage } from '@/hooks'
import React, { useEffect, useState } from 'react'
import SetPassword from './components/SetPassword'
import Success from './components/Success'
import { Cookies } from '@garpix/cms'
import ErrorRegistration from './components/ErrorRegistration'

interface IUserSession {
  user_session_token: string
  email: string
}

const RegistrationConfirm = (): React.ReactElement => {
  const page = usePage()
  let sessionToken: string | undefined
  let sessionEmail: string | undefined
  if ((page.init_state.user_session as IUserSession) !== null) {
    sessionToken = page.init_state.user_session.user_session_token
    sessionEmail = page.init_state.user_session.email
  }
  const [stage, setStage] = useState<number>(
    page.init_state.user_session === null ? MODAL_STAGE.third : MODAL_STAGE.first
  )

  const StateMachineNext = {
    1: () => MODAL_STAGE.second
  }

  const handleNext = (): void => {
    setStage(StateMachineNext[String(stage)]())
  }

  useEffect(() => {
    if (sessionToken !== undefined) {
      const sessionCookieName = 'gx_create_user_session'
      Cookies.setCookie(sessionCookieName, sessionToken)
    }
  }, [])

  return (
    <>
      {stage === MODAL_STAGE.first
        ? (
          <SetPassword email={sessionEmail} nextStage={handleNext} />
          )
        : null}

      {stage === MODAL_STAGE.second ? <Success /> : null}

      {stage === MODAL_STAGE.third ? <ErrorRegistration /> : null}
    </>
  )
}

export default RegistrationConfirm
