import React from 'react'
import CommonLayout from '../layout/components/CommonLayout'
import { errorRobot } from '@/images'
import { useIntl } from 'react-intl'
import Error404 from '../Errors/Error404'

const ErrorPage404 = (): React.ReactElement => {
  const intl = useIntl()

  return (
    <CommonLayout>
      <Error404
        numberError={404}
        textError={intl.formatMessage({
          id: 'error.error',
          defaultMessage: 'Error...'
        })}
        descriptionTitle={intl.formatMessage({
          id: 'error.page_not_found',
          defaultMessage: 'Page not found'
        })}
        descriprionText={intl.formatMessage({
          id: 'error.text_404',
          defaultMessage: 'The page you are looking for does not exist, may be out of date or has been removed.'
        })}
        image={errorRobot}
      />
    </CommonLayout>
  )
}

export default ErrorPage404
