import React from 'react'
import style from './styles/loading.module.scss'
import classNames from 'classnames'
import { Shimmer } from '@/components/Loading'

interface IProps {
  countShimmers: number
  className?: string
}

const LoaderMilestoneDiagram = ({
  countShimmers,
  className = ''
}: IProps): React.ReactElement => {
  const shimmers = Array.from({ length: countShimmers }, (_, index) => (
    <Shimmer key={index} size='xs' />
  ))

  return (
    <div
      className={classNames({
        [style.wrap]: true,
        [className]: Boolean(className)
      })}
    >
      <Shimmer size='xl' form='circle' />

      <div className={style.loading}>{shimmers}</div>
    </div>
  )
}

export default LoaderMilestoneDiagram
