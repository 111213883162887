export enum CHECKBOX_STATE {
  UNCHECKED,
  CHECKED,
  INDETERMINATE,
}

export interface ICheckboxState {
  id: any
  state: CHECKBOX_STATE
}
