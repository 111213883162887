import React from 'react'
import Icon from '@/components/Icon/Icon'
import { FormattedMessage } from 'react-intl'
import style from '../styles/company.module.scss'
import Button from '@/components/Button/Button'
import { AvatarCompany } from '@/components/Avatar'

interface IProps {
  onClose: () => void
  success: () => void
  isSubmitting: boolean
  avatar?: string | null
  companyName: string | null
  error: string
}

const ConfirmLeave = ({
  onClose,
  success,
  isSubmitting,
  avatar,
  companyName,
  error
}: IProps): React.ReactElement => {
  return (
    <div className={style.change_owner}>
      <div className={style.change_owner__content}>
        <div className={style.change_owner__company}>
          <AvatarCompany src={avatar} size='xl' />

          <span className={style.change_owner__name}>{companyName}</span>
        </div>

        <p className={style.change_owner__warning}>
          <FormattedMessage
            id='company.leave.notice'
            defaultMessage='Do you really want to leave the company?'
          />
        </p>
      </div>

      {error !== ''
        ? (
          <span className={style.change_owner__error}>{error}</span>
          )
        : null}

      <div className={style.change_owner__bottom}>
        <Button onClick={onClose} theme='light'>
          <Icon src='clear' slot='icon-left' />

          <FormattedMessage
            id='company.change_owner.cancel'
            defaultMessage='Cancel'
          />
        </Button>

        <Button disabled={isSubmitting} onClick={success}>
          <Icon src='checked' slot='icon-left' />

          <FormattedMessage id='company.leave.confirm' defaultMessage='Leave' />
        </Button>
      </div>
    </div>
  )
}

export default ConfirmLeave
