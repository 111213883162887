import React, { useEffect, useState } from 'react'
import style from '../../styles/update_cases.module.scss'
import { observer } from 'mobx-react'
import { suiteService } from '../service'
import { usePage } from '@garpix/cms'
import CasesList from './CasesList'
import { FieldArrayWithId } from 'react-hook-form'
import { IFormInputs } from '@/apps/AddOrEditRun'
import { ICase } from '@/interfaces/Case'
import ListContent from '@/components/ListContent'
import { useIntl } from 'react-intl'
import useSidebar from '@/hooks/useSidebar'
import { useNavigate } from 'react-router-dom'

interface IProps {
  selectedCase: Array<FieldArrayWithId<IFormInputs, 'cases', '_id'>>
}

const Cases = observer(({ selectedCase }: IProps): React.ReactElement => {
  const { page } = usePage()
  const runId = page.init_state.object.id
  const intl = useIntl()
  const suiteId = suiteService.currentSuite.id
  const sidebar = useSidebar()
  const navigate = useNavigate()

  const [cases, setCases] = useState<ICase[]>(selectedCase)

  const handleNavigateCreate = (): void => {
    navigate(sidebar.ProjectPageCasesCreate.absolute_url)
  }

  useEffect(() => {
    if (suiteId === null) {
      setCases(selectedCase)
    } else {
      setCases(selectedCase.filter((item) => item.suite === suiteId))
    }
  }, [suiteId, selectedCase])

  useEffect(() => {
    suiteService.setDefaultValueSuite()
  }, [runId])

  return (
    <div className={style.cases}>
      <div>
        <div className={style.cases__filters} />
      </div>
      <ListContent
        isLoading={false}
        error={undefined}
        hasData={cases.length > 0}
        emptyListIcon='folders'
        emptyListText={intl.formatMessage({
          id: 'cases.no_cases',
          defaultMessage: 'Test cases list is empty'
        })}
        emptyListButton={intl.formatMessage({
          id: 'cases.add_case',
          defaultMessage: 'Add'
        })}
        onClick={handleNavigateCreate}
        classNameError={style.dnd__error}
      >
        <CasesList
          data={cases}
        />
      </ListContent>
    </div>
  )
})
export default Cases
