import { FormattedMessage } from 'react-intl'
import * as yup from 'yup'
import React from 'react'

export const estimationSchema = yup
  .string()
  .matches(/^(\d+w\s?)?(\d+d\s?)?(\d+h\s?)?(\d+m\s?)?$/, {
    message: (
      <FormattedMessage
        id='case.validation.estimated'
        defaultMessage='Error. Use format: (3w 4d 12h 5m)'
      />
    ),
    excludeEmptyString: true
  })
