import Title from '@/components/Title'
import { useStores } from '@/hooks'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import style from './styles/modal.module.scss'
import { SYNC_CASE_TYPES } from '@/const'
import { Form } from '@/components/Form'
import { Controller, useForm } from 'react-hook-form'
import { TSyncCase } from '@/interfaces/Types'
import { reactHookFormErrorFormater } from '@/utils'
import Button from '@/components/Button/Button'
import { Radio } from '@/components/Radio'

export interface IFormInputs {
  non_field_errors?: string
  mode: TSyncCase
}

interface IProps{
  handleClose: () => void
}

const ViewSyncCase = ({ handleClose }: IProps): React.ReactElement | null => {
  const { api, modalStore } = useStores()
  const runId = modalStore.dataModal.runId
  const caseId = modalStore.dataModal.caseId

  const {
    setError,
    control,
    handleSubmit,
    formState: { isSubmitting, errors }
  } = useForm<IFormInputs>({
    defaultValues: { mode: SYNC_CASE_TYPES.save }
  })

  const refresh = (): void => window.location.reload()

  const onSubmit = async (params): Promise<void> => {
    try {
      await api.syncCaseInRun(runId, caseId, params)
      handleClose()
      refresh()
    } catch (error) {
      const errors = reactHookFormErrorFormater(params, error)
      errors.forEach((item) => {
        setError(item.key as keyof IFormInputs, {
          message: item.value
        })
      })
    }
  }

  const nonFieldErrors = errors?.non_field_errors?.message ?? ''

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Title type='h2'>
        <FormattedMessage
          id='runs.cases.sync.title'
          defaultMessage='Sync case'
        />
      </Title>

      <p>
        <FormattedMessage
          id='runs.cases.sync.desc'
          defaultMessage='The parent check has been changed! Choose the update method:'
        />
      </p>

      <div className={style.sync__radios}>
        <Controller
          control={control}
          name='mode'
          render={({ field: { onChange, value } }) => {
            return (
              <Radio
                value={SYNC_CASE_TYPES.save}
                name='sync_type'
                onGx-change={(e) => onChange(e.target.value)}
                checked={value === SYNC_CASE_TYPES.save}
              >
                <FormattedMessage
                  id='runs.cases.sync.save'
                  defaultMessage='Update without changing statuses'
                />

                <span className={style.sync__description}>
                  <FormattedMessage
                    id='runs.cases.sync.save.description'
                    defaultMessage='"The current statuses of the test case will be saved, and if the parent case has new steps, they will be added with the \"Untested\" status."'
                  />
                </span>

              </Radio>
            )
          }}
        />

        <Controller
          control={control}
          name='mode'
          render={({ field: { onChange, value } }) => {
            return (
              <Radio
                value={SYNC_CASE_TYPES.revert}
                name='sync_type'
                onGx-change={(e) => onChange(e.target.value)}
                checked={value === SYNC_CASE_TYPES.revert}
              >
                <FormattedMessage
                  id='runs.cases.sync.revert'
                  defaultMessage='Update with reset statuses'
                />

                <span className={style.sync__description}>
                  <FormattedMessage
                    id='runs.cases.sync.revert.description'
                    defaultMessage='Current test case statuses will be set to "Untested", new steps will also have this status.'
                  />
                </span>
              </Radio>
            )
          }}
        />

        {nonFieldErrors !== ''
          ? (
            <div className={style.sync__error}>{nonFieldErrors}</div>
            )
          : null}
      </div>

      <div className={style.sync__bottom}>
        <Button
          disabled={isSubmitting}
          type='submit'
        >
          <FormattedMessage id='common.confirm' defaultMessage='Confirm' />
        </Button>
      </div>
    </Form>
  )
}

export default ViewSyncCase
