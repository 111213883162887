import React from 'react'
import style from '../../styles/update_cases.module.scss'
import { ICaseInRun } from '@/interfaces/Case'
import CaseItemInRun from '@/components/CaseItem/CaseItemInRun/CaseItemInRun'
import { useObjectPage } from '@/hooks'
import PaginatedQueryResult from '@/api/interfaces/PaginatedQueryResult'
import Pagination from '@/components/Pagination'
import PageSizeSelect from '@/components/Select/PageSizeSelect'

interface IProps {
  data: PaginatedQueryResult<ICaseInRun>
  handleUpdate: () => void
  handlePageSize: (e: any) => void
  setPage: (page: number) => void
  currentPage: number
  pageSize: number
}

const CasesList = ({
  data,
  handleUpdate,
  currentPage,
  pageSize,
  setPage,
  handlePageSize
}: IProps): React.ReactElement => {
  const { id: runId } = useObjectPage()

  return (
    <div className={style.caseslist__list}>
      {data.results.map((el) => {
        return (
          <CaseItemInRun
            key={el.id}
            link={el.absolute_url}
            title={el.title}
            priority={el.priority}
            tags={el.tags}
            id={el.id}
            runId={runId}
            code={el.code}
            commentsCount={el.comments_count}
            status={el.status}
            type={el.case_type}
            needsUpdate={el.needs_update}
            onMutate={handleUpdate}
          />
        )
      })}

      <div className={style.caseslist__pagination}>
        <PageSizeSelect value={String(pageSize)} handleChange={handlePageSize} />

        <Pagination
          currentPage={currentPage}
          total={data.count}
          pageSize={pageSize}
          handleChange={setPage}
        />
      </div>
    </div>
  )
}

export default CasesList
