import { GxTooltip } from '@garpix/garpix-web-components-react'
import { Components } from '@garpix/garpix-web-components'
import React, { forwardRef } from 'react'
import classNames from 'classnames'
import style from './styles/tooltip.module.scss'

const Tooltip = forwardRef<Components.GxTooltip, any>(
  ({ ...props }, ref): React.ReactElement => {
    const { children = '', className = '' } = props
    return (
      <GxTooltip
        {...props}
        ref={ref}
        className={classNames({
          [style.tooltip]: true,
          [className]: Boolean(className)
        })}
        hoist
      >
        {children}
      </GxTooltip>
    )
  }
)

export default Tooltip
