import React from 'react'
import Icon from '@/components/Icon/Icon'
import style from './styles/baseasyncselect.module.scss'

export const DropdownIndicator = (props): React.ReactElement => {
  if (props.isFocused === true) {
    return <Icon src='arrow_down' className={style.async__arrow} />
  } else {
    return <Icon src='arrow_down' className={style.async__active} />
  }
}

export const ClearIndicator = (props): React.ReactElement => {
  const handleClick = (): void => props.clearValue()
  return (
    <button type='button' onClick={handleClick} className={style.async__clear}>
      <Icon size='small' src='clear' />
    </button>
  )
}
