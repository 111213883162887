import React from 'react'
import style from '../styles/case_detail.module.scss'
import { IEpicField } from '@/interfaces/Project'
import { IFormData } from '../interfaces'
import { useFormContext } from 'react-hook-form'
import BaseAsyncSelect from '@/components/Select/AsyncSelect/BaseAsyncSelect'
import { useIntl } from 'react-intl'
import { useStores } from '@/hooks'

interface IProps {
  field: IEpicField
  required: boolean
  projectId: number | string
  error?: string
}

interface IOptions {
  value: string
  label: string
  email: string
}

function CustomFieldSelect ({ field, projectId, required, error }: IProps): JSX.Element {
  const { setValue, getValues, register } = useFormContext<IFormData>()
  const intl = useIntl()
  const { api } = useStores()
  const { id, name } = field

  const handleChange = (selectedOption: IOptions): void => {
    if (selectedOption != null) {
      const existingCustomFields = getValues('custom_fields')
      const updatedCustomFields = {
        ...existingCustomFields,
        [selectedOption.value.toString()]: selectedOption.email
      }
      setValue('custom_fields', updatedCustomFields)
    }
  }

  const loadOptions = (
    inputValue: string,
    callback: (options: IOptions[]) => void
  ): void => {
    api
      .getJiraUsers({
        id: projectId,
        q: inputValue
      })
      .then((data) => {
        callback(
          data.map((el) => {
            return {
              value: id,
              label: el.name,
              email: el.email
            }
          })
        )
      })
      .catch((err) => console.log(err))
  }

  return (
    <div className={style.input__container}>
      <BaseAsyncSelect
        label={name}
        required={required}
        {...register('custom_fields', { required: true })}
        placeholder={intl.formatMessage({
          id: 'input.placeholder',
          defaultMessage: 'Select...'
        })}
        error={error}
        handleChange={handleChange}
        loadOptions={loadOptions}
      />
    </div>
  )
}

export default CustomFieldSelect
