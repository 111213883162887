import React from 'react'
import style from './styles/notification_item.module.scss'
import Icon from '../Icon/Icon'
import { FormattedMessage } from 'react-intl'
import { INotifiesMilestoneDeadlineData } from '@/interfaces/Notifies'
import { ITimeSince } from '@/interfaces/Utils'
import Link from '../Link'
import classNames from 'classnames'
import NotifyTime from './components/NotifyTime'

interface IProps {
  data: INotifiesMilestoneDeadlineData
  time: ITimeSince
}

const NotifyMilestoneDeadline = ({
  data,
  time
}: IProps): React.ReactElement => {
  const { milestone, project } = data

  return (
    <>
      <div
        className={style.item__color}
        style={{ backgroundColor: milestone.color }}
      />

      <div className={style.item__content}>
        <p className={style.info}>
          <FormattedMessage
            id='notifications.item.milestone_deadline'
            values={{
              link: <Link to={project.absolute_url}>{project.title}</Link>
            }}
            defaultMessage='The end date of the milestone is drawing to a close in {link} project'
          />
        </p>
        <div className={style.run}>
          <Link to={milestone.absolute_url} className={style.milestone__link}>
            <Icon src='flag' className={style.milestone__icon} />
            <span className={style.milestone__name}>{milestone.title}</span>
          </Link>

          <div
            className={classNames({
              [style.milestone__date]: true,
              [style['milestone__date--deadline']]: true
            })}
          >
            <Icon src='clock' />

            <span>{milestone.end_date}</span>
          </div>
        </div>

        <NotifyTime time={time} />
      </div>
    </>
  )
}

export default NotifyMilestoneDeadline
