import React, { useState } from 'react'
import style from './styles/caseitem.module.scss'
import { TextTag } from '@/components/Tag'
import { ITag } from '@/interfaces/Case'
import Priority from '../Priority'
import { TCaseStatus, TCaseType, TPriority } from '@/interfaces/Types'
import { CaseStatus } from '../Status'
import Link from '../Link'
import classNames from 'classnames'
import Icon from '@/components/Icon/Icon'
import { Dropdown, DropdownMenu } from '@/components/Dropdown'
import { FormattedMessage } from 'react-intl'
import { useStores } from '@/hooks'
import { useCurrentPermissions } from '@/hooks/useCurrentPermissions'
import {
  CASE_ICON,
  CASE_TYPES,
  CREATE_PERMISSION_LEVEL,
  EDIT_PERMISSION_LEVEL,
  USER_PERMISSIONS
} from '@/const'
import { useNavigate } from 'react-router-dom'

interface IProps {
  id: number
  title: string
  priority: TPriority
  code: string
  tags?: ITag[]
  status: TCaseStatus
  priorityVariant?: 'default' | 'icon'
  link: string
  editUrl?: string
  className?: string
  showDropdown?: boolean
  stepsCount?: number
  caseType?: TCaseType
  isDraggable?: boolean
}

const CaseItem = ({
  id,
  link,
  title,
  priority,
  code,
  tags,
  status = 'draft',
  className = '',
  priorityVariant = 'default',
  editUrl,
  stepsCount,
  caseType,
  showDropdown = false
}: IProps): React.ReactElement => {
  const [localStatus, setLocalStatus] = useState(status)

  const { api } = useStores()
  const navigate = useNavigate()

  const shouldShowApproveButton =
    status !== 'approved' &&
    ((stepsCount !== undefined &&
      stepsCount > 0 &&
      caseType !== CASE_TYPES.task) ||
      caseType === CASE_TYPES.task)

  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const handleApproveCase = async (): Promise<void> => {
    await api.approveCase({
      id: id
    })
    setLocalStatus('approved')
    setIsDropdownOpen(false)
  }

  const handleCloneCase = async (): Promise<void> => {
    const res = await api.cloneCase(id)
    setIsDropdownOpen(false)
    navigate(res.absolute_url)
  }

  const canUserCaseApprove = showDropdown
    ? useCurrentPermissions(
      USER_PERMISSIONS.case_approve,
      EDIT_PERMISSION_LEVEL
    )
    : false

  const canUserCaseEdit = showDropdown
    ? useCurrentPermissions(USER_PERMISSIONS.case, EDIT_PERMISSION_LEVEL)
    : false

  const canUserCaseClone = showDropdown
    ? useCurrentPermissions(USER_PERMISSIONS.case, CREATE_PERMISSION_LEVEL)
    : false

  return (
    <div
      className={classNames({
        [style.caseitem__item]: true,
        [style.caseitem__dropdownOpen]: isDropdownOpen,
        [className]: Boolean(className)
      })}
    >
      <div className={style.caseitem__desc}>
        <div className={style.caseitem__codeContainer}>
          <span className={style.caseitem__code}>{code}</span>
          <div className={style.caseitem__info}>
            <Icon
              src={caseType !== undefined ? CASE_ICON[caseType] : 'test_case'}
              className={style.caseitem__caseicon}
            />

            <Link
              to={link}
              className={classNames(
                style.caseitem__title,
                style.caseitem__link
              )}
            >
              {title}
            </Link>
          </div>
        </div>

        <div className={style.caseitem__tags}>
          {tags?.map((el) => {
            return (
              <TextTag size='small' key={el.id}>
                {el.title}
              </TextTag>
            )
          })}
        </div>
      </div>

      <div className={style.caseitem__right}>
        <Priority priority={priority} variant={priorityVariant} />
        <CaseStatus status={localStatus} variant='icon' />

        {showDropdown
          ? (
            <Dropdown
              open={isDropdownOpen}
              placement='bottom-end'
              onGx-show={() => {
                setIsDropdownOpen(true)
              }}
              onGx-hide={() => setIsDropdownOpen(false)}
            >
              <button slot='trigger' type='button'>
                <Icon src='etc' className={style.caseitem__dropdownbtn} />
              </button>

              <DropdownMenu className={style.menu}>
                {canUserCaseApprove && shouldShowApproveButton
                  ? (
                    <button
                      type='button'
                      className={style.menu__btn}
                      onClick={handleApproveCase}
                    >
                      <Icon src='checked' className={style.menu__delete_icon} />

                      <span>
                        <FormattedMessage
                          id='common.approve'
                          defaultMessage='Approve'
                        />
                      </span>
                    </button>
                    )
                  : null}

                {canUserCaseEdit
                  ? (
                    <div className={style.menu__btn}>
                      <Icon src='edit' />

                      <span>
                        <Link to={editUrl as string} className={style.menu__link}>
                          <FormattedMessage
                            id='common.edit'
                            defaultMessage='Edit'
                          />
                        </Link>
                      </span>
                    </div>
                    )
                  : null}

                {canUserCaseClone
                  ? (
                    <button
                      type='button'
                      className={style.menu__btn}
                      onClick={handleCloneCase}
                    >
                      <Icon src='test_case' />

                      <FormattedMessage id='common.clone' defaultMessage='Clone' />
                    </button>
                    )
                  : null}
              </DropdownMenu>
            </Dropdown>
            )
          : null}
      </div>
    </div>
  )
}

export default CaseItem
