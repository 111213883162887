import Icon from '@/components/Icon/Icon'
import Tooltip from '@/components/Tooltip'
import useGlobalConfig from '@/hooks/useGlobalConfig'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import style from './styles/header.module.scss'
import Link from '@/components/Link'

const TechWorksInfo = (): React.ReactElement => {
  const { faq_url: faqUrl } = useGlobalConfig()

  const tooltipText = (
    <FormattedMessage
      id='header.tech_work'
      defaultMessage='Technical work is underway. Possible interruptions in the system operation. If you have found an error, please report it to <link>Support</link>.'
      values={{
        link: (chunks) => <Link to={faqUrl} className={style.techwork__link}>{chunks}</Link>
      }}
    />
  )

  return (
    <Tooltip trigger='click' placement='bottom-start' clickable>
      <Icon src='attention2' size='small' className={style.techwork__icon} />

      <span slot='content'>{tooltipText}</span>
    </Tooltip>
  )
}

export default TechWorksInfo
