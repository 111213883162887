import React from 'react'
import Dashboard from '../Dashboard'
import UserLayout from '../layout/components/UserLayout'

const DashboardPage = (): React.ReactElement => {
  return (
    <UserLayout>
      <Dashboard />
    </UserLayout>
  )
}

export default DashboardPage
