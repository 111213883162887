import Button from '@/components/Button/Button'
import { LineChart } from '@/components/Charts'
import useSWR from 'swr'
import Icon from '@/components/Icon/Icon'
import PeriodSelect from '@/components/Select/PeriodSelect'
import Title from '@/components/Title'
import Wrapper from '@/components/Wrapper'
import { useObjectPage, useStores } from '@/hooks'
import { downloadBase64File, getLastDayStatistic } from '@/utils'
import React, { useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import style from '../style/report_detail.module.scss'
import GroupFullStatuses from '@/components/GroupStatuses/GroupFullStatuses'
import { CHART_COLORS } from '@/const'
import { TCaseStatusInRun } from '@/interfaces/Types'
import { IPeriodSelectValue } from '@/components/Select/interfaces/SelectProps'
import { EmptyChart } from '@/components/EmptyResults'

const Activity = ({ dates, setDates }): React.ReactElement => {
  const page = useObjectPage()
  const chartRef = useRef()
  const store = useStores()

  const saveChart = (): void => {
    const chart = chartRef.current
    // @ts-expect-error
    const img = chart.toBase64Image()
    downloadBase64File(img, 'chart.png')
  }

  const { data } = useSWR(
    {
      id: page.id,
      date_from: dates.start,
      date_to: dates.end,
      _key: 'getRunReports'
    },
    store.api.getRunReports
  )

  const labels = data?.activity.labels ?? []

  const lastDayDate = labels.length > 0 ? labels[labels.length - 1] : ''

  const statistic = getLastDayStatistic(data?.activity?.datasets ?? [])

  const datasets: Array<{
    label: TCaseStatusInRun
    data: number[]
    borderColor: string
    backgroundColor: string
  }> = []

  data?.activity.datasets.forEach((el) => {
    const item = {
      label: el.label,
      data: el.data,
      borderColor: CHART_COLORS.status[el.label.toLowerCase()].opaque,
      backgroundColor: CHART_COLORS.status[el.label.toLowerCase()].opacity
    }

    datasets.push(item)
  })

  const handlechange = (value: IPeriodSelectValue): void => {
    setDates(value)
  }

  return (
    <Wrapper className={style.block}>
      <Title type='h2'>
        <FormattedMessage
          id='titles.activity_chart'
          defaultMessage='Activity chart'
        />
      </Title>

      <div className={style.activity__flex}>
        <div className={style.activity__chart}>
          {datasets.length === 0
            ? (
              <EmptyChart />
              )
            : (
              <LineChart data={{ labels, datasets }} ref={chartRef} />
              )}
        </div>

        <div className={style.activity__right}>
          <div className={style.activity__filters}>
            <PeriodSelect handleChange={handlechange} />

            <Button theme='light' wide onClick={saveChart}>
              <Icon src='download' slot='icon-left' />
              <FormattedMessage
                id='project.dowmload_chart'
                defaultMessage='Download chart'
              />
            </Button>
          </div>

          <div className={style.result__statuslist}>
            {data?.statistics !== undefined
              ? (
                <>
                  <Title type='h3' className={style.info__title}>
                    <FormattedMessage
                      id='project.statistic.status'
                      defaultMessage='Status on {date}'
                      values={{ date: lastDayDate }}
                    />
                  </Title>
                  <GroupFullStatuses statistic={statistic} />
                </>
                )
              : null}
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default Activity
