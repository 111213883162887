import useSWR from 'swr'
import CommentItem from '@/components/CommentItem'
import Title from '@/components/Title'
import { useStores } from '@/hooks'
import { ICaseInRunComment } from '@/interfaces/Case'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import style from './styles/modal.module.scss'
import Icon from '@/components/Icon/Icon'
import Button from '@/components/Button/Button'

const ViewCommentsCase = (): React.ReactElement | null => {
  const { api, modalStore } = useStores()
  const taskId = modalStore.dataModal.taskId

  const { data } = useSWR(
    {
      id: taskId,
      _key: 'getCommentsStepRun'
    },
    api.getCommentsStepRun
  )

  const comments: ICaseInRunComment[] | undefined = data?.results

  if (comments === undefined) return null

  return (
    <>
      <div className={style.modal__comments_head}>
        <Title type='h2'>
          <FormattedMessage id='titles.comments' defaultMessage='Comments' />
        </Title>

        <Button>
          <Icon src='plus' slot='icon-left' />
          <FormattedMessage id='button.add_comment' defaultMessage='Add comment' />
        </Button>
      </div>

      <div className={style.modal__comments}>
        {comments.map((el) => {
          return (
            <CommentItem
              key={el.id}
              status={el.status}
              comment={el.comment}
              createdTime={el.created_at}
            />
          )
        })}
      </div>
    </>
  )
}

export default ViewCommentsCase
