import React, { Dispatch } from 'react'
import { observer } from 'mobx-react'
import CompanyMemberToAdd from '@/apps/AddOrEditProject/components/CompanyMemberToAdd'
import { ICompanyUser } from '@/interfaces/Company'
import { IProjectMember, IProjectRoleShort } from '@/interfaces/Member'
import { IUpdateMembers } from '@/interfaces/ApiParams'

interface IProps {
  companyUser: ICompanyUser
  projectMembers: IProjectMember[]
  projectRoles: IProjectRoleShort[]
  setMember: Dispatch<React.SetStateAction<IUpdateMembers[]>>
  newMembers: IUpdateMembers[]
  isError?: boolean
  isCheckbox?: boolean
}

const CompanyUser = observer(
  ({
    companyUser,
    projectMembers,
    projectRoles,
    setMember,
    newMembers,
    isError = false,
    isCheckbox = true
  }: IProps) => {
    const user = companyUser.user

    const handleRoleSelect = (roleId): void => {
      setMember([
        { abac_role: roleId, id: user.id },
        ...newMembers.filter((el) => el.id !== user.id)
      ])
    }

    return (
      <CompanyMemberToAdd
        key={companyUser.id} // Перемещаем key на вложенный компонент
        role={newMembers[user.id]?.abac_role}
        handleRoleChange={handleRoleSelect}
        setMember={setMember}
        projectRoles={projectRoles}
        id={user.id}
        firstName={user.first_name}
        email={user.email}
        lastName={user.last_name}
        avatar={user.avatar}
        target='_blank'
        profileUrl={user.profile_url}
        newMembers={newMembers}
        isError={isError}
        isCheckbox={isCheckbox}
      />
    )
  }
)

export default CompanyUser
