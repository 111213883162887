import { RootStore } from './Root'
import { makeAutoObservable, action } from 'mobx'

export interface ICompanyStore {
  companyId?: number
}

export class CompanyStore implements ICompanyStore {
  companyId?: number
  rootStore: RootStore

  constructor (rootStore: RootStore) {
    this.rootStore = rootStore
    this.setCompany = action(this.setCompany.bind(this))
    makeAutoObservable(this)
  }

  setCompany (companyId: number | undefined): void {
    this.companyId = companyId
  }

  getCompany (): number | undefined {
    return this.companyId
  }
}
