import React from 'react'
import { DayPicker as Picker, DayPickerSingleProps } from 'react-day-picker'
import { useLocale } from '@/hooks'
import { enUS, ru } from 'date-fns/locale'
import classNames from 'classnames'
import style from './styles/daypicker.module.scss'
import Bottom from './component/Bottom'
import Icon from '../Icon/Icon'

interface IProps extends DayPickerSingleProps {
  handleReset: () => void
  disabledReset?: boolean
}

const DayPickerSingle = ({
  className = '',
  handleReset,
  disabledReset = false,
  selected = undefined,
  mode = 'single',
  ...props
}: IProps): React.ReactElement => {
  const { locale } = useLocale()
  const pickerLocale = {
    ru: ru,
    en: enUS
  }

  return (
    <div
      className={classNames({
        [style.picker]: true,
        [className]: Boolean(className)
      })}
    >
      <Picker
        showOutsideDays
        locale={pickerLocale[locale]}
        selected={selected}
        mode={mode}
        components={{
          IconRight: () => <Icon src='arrow_right' />,
          IconLeft: () => <Icon src='arrow_left' />
        }}
        {...props}
      />
      <Bottom
        disabledReset={disabledReset}
        selected={selected}
        handleReset={handleReset}
      />
    </div>
  )
}

export default DayPickerSingle
