import Input from '@/components/Input/Input'
import { ModalDelete } from '@/components/Modal'
import Title from '@/components/Title'
import { useObjectPage } from '@/hooks'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import style from '../styles/add_edit.module.scss'

interface IProps {
  onCancel: () => void
  onDelete: () => void
  disableSubmit: boolean
}

const DeleteProject = ({
  onCancel,
  onDelete,
  disableSubmit
}: IProps): React.ReactElement => {
  const { title } = useObjectPage()
  const [value, setValue] = useState('')

  const handleChange = (e): void => {
    setValue(e.target.value)
  }
  return (
    <ModalDelete
      onCancel={onCancel}
      onDelete={onDelete}
      disableSubmit={disableSubmit || title !== value}
    >
      <Title type='h2' theme='h1' className={style.delete__title}>
        <FormattedMessage
          id='project.delete.title'
          defaultMessage='Do you really want to delete the project '
        />
        <span className={style.delete__name}>{title}</span>?
      </Title>

      <p className={`${style.delete__text} ${style['delete__text--red']}`}>
        <FormattedMessage
          id='project.delete.text'
          defaultMessage='The action cannot be undone.'
        />
      </p>

      <p className={style.delete__text}>
        <FormattedMessage
          id='project.delete.confirm'
          defaultMessage='Please type in the name of the project to confirm.'
        />
      </p>

      <Input onChange={handleChange} type='search' value={value} />
    </ModalDelete>
  )
}

export default DeleteProject
