import Title from '@/components/Title'
import Wrapper from '@/components/Wrapper'
import React from 'react'
import useSWR from 'swr'
import { FormattedMessage, useIntl } from 'react-intl'
import style from '../styles/dashboard.module.scss'
import { useStores } from '@/hooks'
import CaseItem from '@/components/CaseItem'
import { observer } from 'mobx-react'
import EmptyList from '@/components/EmptyList/EmptyList'
import { LoaderCasesDashboard } from '../loaders'

const pageSize = 7

const LastCases = observer((): React.ReactElement => {
  const { api, companyStore } = useStores()
  const currentCompany = companyStore.getCompany()
  const intl = useIntl()

  const { data, isLoading } = useSWR(
    {
      page: 1,
      page_size: pageSize,
      company: currentCompany,
      _key: 'getCases'
    },
    api.getMemberCases
  )

  return (
    <Wrapper className={style.cases}>
      <div className={style.top}>
        <Title type='h2' theme='h1' className={style.title}>
          <FormattedMessage
            id='titles.last_cases'
            defaultMessage='Last Test Cases'
          />
        </Title>
      </div>

      {!isLoading && data !== undefined && data.results.length > 0
        ? (
          <>
            {data.results.map((el) => {
              return (
                <CaseItem
                  link={el.absolute_url}
                  key={el.id}
                  title={el.title}
                  priority={el.priority}
                  id={el.id}
                  className={style.item}
                  code={el.code}
                  status={el.status}
                  priorityVariant='icon'
                />
              )
            })}
          </>
          )
        : null}

      {isLoading ? <LoaderCasesDashboard countShimmers={pageSize} /> : null}

      {!isLoading && data !== undefined && data.results.length === 0
        ? (
          <EmptyList
            icon='test_case'
            text={intl.formatMessage({
              id: 'cases.last_cases.empty',
              defaultMessage: 'You don’t have last Test Cases'
            })}
            className={style.steps__empty}
          />
          )
        : null}
    </Wrapper>
  )
})

export default LastCases
