import Title from '@/components/Title'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import style from '../styles/dashboard.module.scss'
import Wrapper from '@/components/Wrapper'
import Icon from '@/components/Icon/Icon'
import Button from '@/components/Button/Button'
import ReleaseCalendar from '@/apps/Dashboard/components/ReleaseCalendar'

const RecentsHead = (): React.ReactElement => {
  const [isCalendarOpen, setCalendarOpen] = useState(false)

  const handleToggleCalendar = (
    e: React.MouseEvent<HTMLButtonElement>
  ): void => {
    e.stopPropagation()
    isCalendarOpen ? handleCloseCalendar() : setCalendarOpen(true)
  }

  const handleCloseCalendar = (): void => {
    setCalendarOpen(false)
  }

  return (
    <Wrapper className={style.comp__wrapper}>
      <Title type='h1' theme='h3' className={style.comp__title}>
        <FormattedMessage
          id='titles.recently_dashboard'
          defaultMessage='Recently dashboard'
        />
      </Title>
      <Button
        theme='light'
        onMouseDown={(e) => {
          handleToggleCalendar(e)
        }}
        circle
      >
        <Icon src='calendar' />
      </Button>

      {isCalendarOpen
        ? (
          <ReleaseCalendar open={isCalendarOpen} onClose={handleCloseCalendar} />
          )
        : null}
    </Wrapper>
  )
}

export default RecentsHead
