import { Components } from '@garpix/garpix-web-components'
import { GxInput } from '@garpix/garpix-web-components-react'
import classNames from 'classnames'
import React, { forwardRef } from 'react'
import Icon from '../Icon/Icon'
import style from './styles/input.module.scss'

const Input = forwardRef<Components.GxInput, any>(
  ({ onBlur, onChange, ...props }, ref): React.ReactElement => {
    const { children = '', className = '', label, error = '' } = props
    const showError = Array.isArray(error) ? error[0] : error
    return (
      <GxInput
        ref={ref}
        {...props}
        onGx-blur={onBlur}
        onGx-input={onChange}
        className={classNames({
          [style.input]: true,
          [style.error]: Boolean(showError),
          [className]: Boolean(className)
        })}
        invalid={Boolean(showError)}
        label={label}
        pill
      >
        <Icon src='show_password' slot='hide-password-icon' />
        <Icon src='hide_password' slot='show-password-icon' />
        <Icon src='clear' slot='clear-icon' />
        {children}
        {showError !== ''
          ? (
            <>
              <Icon src='error' slot='suffix' className={style.error__icon} />
              <div slot='help-text' className={style.error__text}>
                {showError}
              </div>
            </>
            )
          : null}
      </GxInput>
    )
  }
)
export default Input
