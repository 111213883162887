import { TRunStatus } from '@/interfaces/Types'
import classNames from 'classnames'
import React from 'react'
import { useIntl } from 'react-intl'
import style from './styles/status.module.scss'

interface IProps {
  status: TRunStatus
  size?: 'small' | 'default'
  className?: string
}

const TaskStatus = ({
  status,
  size = 'default',
  className = ''
}: IProps): React.ReactElement => {
  const intl = useIntl()
  const stat = status ?? ''

  const defaultMessage = stat.charAt(0).toUpperCase() + stat.slice(1)

  const statusText = intl.formatMessage({
    id: `status.task.${stat.toLowerCase()}`,
    defaultMessage
  })

  return (
    <div
      className={classNames({
        [style.status]: true,
        [style.task]: true,
        [style[`task--${stat.toLowerCase()}`]]: true,
        [style[`task--${size}`]]: size !== 'default',
        [className]: Boolean(className)
      })}
    >
      {statusText}
    </div>
  )
}

export default TaskStatus
