import React, { Dispatch } from 'react'
import Button from '@/components/Button/Button'
import Icon from '@/components/Icon/Icon'
import Title from '@/components/Title'
import { FormattedMessage } from 'react-intl'
import style from './index.module.scss'
import { IProjectRoleShort } from '@/interfaces/Member'
import { observer } from 'mobx-react'
import BaseModal from '@/components/Modal/Base'
import { IUpdateMembers } from '@/interfaces/ApiParams'
import { ICompanyUser } from '@/interfaces/Company'
import CompanyMemberToAdd from '@/apps/AddOrEditProject/components/CompanyMemberToAdd'

interface IProps {
  roles: IProjectRoleShort[]
  onClose: () => void
  handleUpdateProject: () => void
  baseModalProps: {
    open: boolean
    hideDefaultClose?: boolean
    'onGx-after-hide'?: () => void
  }
  setMember: Dispatch<React.SetStateAction<IUpdateMembers[]>>
  companyUsersSelect: ICompanyUser[]
  newMembers: IUpdateMembers[]
}

const SpecifyMembers = ({
  handleUpdateProject,
  onClose,
  roles,
  baseModalProps,
  setMember,
  newMembers,
  companyUsersSelect
}: IProps): React.ReactElement => {
  return (
    <BaseModal {...baseModalProps}>
      <div className={style.modal}>
        <div className={style.modal__head}>
          <div className={style.modal__info}>
            <Button onClick={onClose} theme='light' circle>
              <Icon src='back' />
            </Button>

            <Title type='h2' className={style.modal__title}>
              <FormattedMessage
                id='project.specify.roles_title'
                defaultMessage='Specify roles'
              />
            </Title>
          </div>

          <Button onClick={handleUpdateProject}>
            <Icon src='checked' slot='icon-left' />
            <FormattedMessage id='common.add' defaultMessage='Add' />
          </Button>
        </div>

        <Title type='h3'>
          <FormattedMessage
            id='project.specify.roles'
            defaultMessage='Specify the roles of team members to add to the project.'
          />
        </Title>

        <div className={style.modal__wrap}>
          {companyUsersSelect?.map((companyUser) => {
            const user = companyUser.user

            const handleRoleSelect = (roleId): void => {
              setMember([
                { abac_role: roleId, id: user.id },
                ...newMembers.filter((el) => el.id !== user.id)
              ])
            }
            return (
              <CompanyMemberToAdd
                key={companyUser.id} // Перемещаем key на вложенный компонент
                role={newMembers[companyUser.id]?.abac_role}
                handleRoleChange={handleRoleSelect}
                setMember={setMember}
                projectRoles={roles}
                id={user.id}
                firstName={user.first_name}
                email={user.email}
                lastName={user.last_name}
                avatar={user.avatar}
                target='_blank'
                profileUrl={user.profile_url}
                newMembers={newMembers}
                isError
                isCheckbox={false}
              />
            )
          })}
        </div>
      </div>
    </BaseModal>
  )
}

export default observer(SpecifyMembers)
