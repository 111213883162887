import { useStores } from '@/hooks'
import React, { forwardRef } from 'react'
import { useIntl } from 'react-intl'
import {
  TAdditional,
  IValue,
  TestPlanSelectProps,
  ILoadOptions
} from '../interfaces/AsyncSelect'
import BaseAsyncPaginate from '../AsyncSelect/BaseAsyncPaginate'

const PAGE_SIZE = 20

const defaultAdditional: TAdditional = {
  page: 1
}

const JiraTasksSelect = (
  { handleChange, idProject, ...props }: TestPlanSelectProps,
  ref
): React.ReactElement => {
  const { api } = useStores()
  const intl = useIntl()

  const loadPageOptions = async (
    q: string,
    _: unknown,
    { page }: TAdditional
  ): Promise<ILoadOptions> => {
    const { options, hasMore } = await loadOptions(q, page)

    return {
      options,
      hasMore,
      additional: {
        page: page + 1
      }
    }
  }

  const loadOptions = async (search: string, page: number): Promise<any> => {
    let hasMore = false

    const options: IValue[] = await api
      .getJiraTasksList({
        idProject: idProject,
        page: page,
        page_size: PAGE_SIZE,
        q: search
      })
      .then((paginatedObject) => {
        hasMore = paginatedObject.next !== null

        return paginatedObject.results.map((el) => {
          return { value: el.id, label: el.name }
        })
      })

    return {
      options,
      hasMore,
      additional: {
        page: page + 1
      }
    }
  }

  return (
    <BaseAsyncPaginate
      {...props}
      closeMenuOnSelect
      label={intl.formatMessage({
        id: 'case.placeholder.parent_task',
        defaultMessage: 'Parent Task'
      })}
      placeholder={intl.formatMessage({
        id: 'input.placeholder',
        defaultMessage: 'Select...'
      })}
      handleChange={handleChange}
      loadOptions={loadPageOptions}
      additional={defaultAdditional}
      ref={ref}
    />
  )
}

export default forwardRef<TestPlanSelectProps, any>(JiraTasksSelect)
