import React from 'react'
import style from '../styles/add_case.module.scss'
import Loading from '@/components/Loading/Loading'
import { useIntl } from 'react-intl'

export const AISpiner = (): React.ReactElement => {
  const intl = useIntl()
  return (
    <div className={style.ai__spiner}>
      <div>
        <Loading size='medium' />
        {intl.formatMessage({
          id: 'cases.ai.spiner',
          defaultMessage: 'AI magic happens'
        })}
      </div>
    </div>
  )
}
