import React from 'react'
import UserLayout from '../layout/components/UserLayout'
import Projects from '../Projects/Projects'

const ProjectsPage = (): React.ReactElement => {
  return (
    <UserLayout>
      <Projects />
    </UserLayout>
  )
}

export default ProjectsPage
