import Button from '@/components/Button/Button'
import ErrorsBlock from '@/components/ErrorsBlock'
import Icon from '@/components/Icon/Icon'
import Title from '@/components/Title'
import Wrapper from '@/components/Wrapper'
import React from 'react'
import BackButton from '../Button/BackButton'
import style from './styles/forms.module.scss'
import { AvatarCompany } from '../Avatar'

interface IProps {
  title: string
  errors?: string[] | string
  isSubmitting: boolean
  buttonText: string
  pastHref: string
  isDeletable: boolean
  onDelete?: () => void
  companyName?: string
  companyImage?: string | null
}

const Head = ({
  companyName,
  companyImage,
  title,
  errors = [],
  isSubmitting,
  buttonText,
  pastHref,
  isDeletable,
  onDelete
}: IProps): React.ReactElement => {
  return (
    <Wrapper className={style.head}>
      <BackButton href={pastHref} className={style.head__back} />

      <div className={style.head__avatarAndTitle}>
        {companyImage === null || companyImage === undefined
          ? null
          : (
            <AvatarCompany
              src={companyImage}
              className={style.head__avatar}
            />
            )}

        <div className={style.head__titleWrapper}>
          <span className={style.head__company}> {companyName} </span>
          <Title type='h1' className={style.head__title}>
            {title}
          </Title>
        </div>
      </div>

      {Array.isArray(errors)
        ? (
          <ErrorsBlock errorsArray={errors} className={style.head__error} />
          )
        : (
          <ErrorsBlock errorsArray={[errors]} className={style.head__error} />
          )}

      <div className={style.head__buttons}>
        {isDeletable
          ? (
            <Button
              circle
              theme='light'
              onClick={onDelete}
              className={style.head__delete}
            >
              <Icon src='delete' />
            </Button>
            )
          : null}

        <div className={style.head__button}>
          <Button
            disabled={isSubmitting}
            type='submit'
            className={style.head__save}
          >
            <Icon src='checked' slot='icon-left' />
            {buttonText}
          </Button>
        </div>
      </div>
    </Wrapper>
  )
}

export default Head
