import useSWR from 'swr'
import SearchInput from '@/components/Input/SearchInput/SearchInput'
import Pagination from '@/components/Pagination'
import ReportsItem from '@/components/ReportsItem'
import MilestoneSelect from '@/components/Select/MilestoneSelect/MilestoneSelect'
import Title from '@/components/Title'
import Wrapper from '@/components/Wrapper'
import { useObjectPage, usePage, useStores } from '@/hooks'
import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { EmptySearch } from '@/components/EmptyResults'
import useDebounce from '@/hooks/useDebounce'
import { scrollTop } from '@/utils'
import style from './styles/reports.module.scss'
import Button from '@/components/Button/Button'
import Icon from '@/components/Icon/Icon'
import Tooltip from '@/components/Tooltip'
import GitlabReportButton from './components/GitlabReportButton'
import { LoaderReportItems } from './loaders'
import { useCurrentPermissions } from '@/hooks/useCurrentPermissions'
import { EDIT_PERMISSION_LEVEL, USER_PERMISSIONS } from '@/const'

const pageSize = 10

const RunsReports = (): React.ReactElement => {
  const isCurrentUserUseGitlabReport = useCurrentPermissions(
    USER_PERMISSIONS.gitlab_reports,
    EDIT_PERMISSION_LEVEL
  )

  const store = useStores()
  const intl = useIntl()
  const page = useObjectPage()
  const { init_state: initState } = usePage()
  const [pageNumber, setPageNumber] = useState(1)
  const [milestone, setMilestone] = useState<number | undefined>()
  const [search, setSearch] = useState('')
  const [visible, setVisible] = useState(false)
  const debouncedSearch = useDebounce(search)
  const link = initState.share_link

  const { data, isLoading } = useSWR(
    {
      id: page.id,
      milestone,
      page_size: pageSize,
      page: pageNumber,
      q: debouncedSearch,
      _key: 'getReports'
    },
    store.api.getReports,
    {
      revalidateOnFocus: false,
      onSuccess: () => {
        scrollTop()
      }
    }
  )

  const { data: gitlabHealthStatus } = useSWR(
    {
      id: page.id.toString(),
      _key: 'getGitlabHealthCheckStatus'
    },
    store.api.getGitlabHealthCheckStatus
  )

  const gitlabConnectionStatus = gitlabHealthStatus?.result

  const handleSearch = (value: string): void => {
    setSearch(value)
    setPageNumber(1)
  }

  const handleChangeSelect = (e): void => {
    if (e !== null) {
      setMilestone(e.value)
    } else {
      setMilestone(undefined)
    }
    setPageNumber(1)
  }

  let timer: NodeJS.Timeout | undefined

  const handleCopyLink = (): void => {
    navigator.clipboard
      .writeText(link)
      .then(() => {
        setVisible(true)
        timer = setTimeout(() => {
          setVisible(false)
        }, 2000)
      })
      .catch(() => {})
  }

  useEffect(() => {
    return () => {
      if (timer !== undefined) clearTimeout(timer)
    }
  }, [])

  return (
    <Wrapper className={style.reports}>
      <div className={style.reports__titlewrap}>
        <Title type='h2' theme='h1' className={style.reports__title}>
          <FormattedMessage id='titles.reports' defaultMessage='Reports' />
        </Title>

        <div className={style.reports_gitlab}>
          {isCurrentUserUseGitlabReport
            ? (
              <GitlabReportButton connStatus={gitlabConnectionStatus} />
              )
            : null}

          <Tooltip
            content={intl.formatMessage({
              id: 'common.link_copied',
              defaultMessage: 'Link copied'
            })}
            trigger='manual'
            open={visible}
            placement='bottom'
          >
            <Button theme='light' onClick={handleCopyLink}>
              <Icon src='clip' slot='icon-left' />

              <FormattedMessage
                id='common.copy_link'
                defaultMessage='Copy link'
              />
            </Button>
          </Tooltip>
        </div>
      </div>

      <div className={style.reports__top}>
        <MilestoneSelect
          handleChange={handleChangeSelect}
          labelClassName={style.reports__select_label}
          isClearable
          projectId={page.id}
        />

        <SearchInput
          handleChange={handleSearch}
          value={search}
          className={style.reports__search}
          placeholder={intl.formatMessage({
            id: 'runs.search',
            defaultMessage: 'Search runs'
          })}
        />
      </div>

      <div className={style.reports__results}>
        {!isLoading
          ? data?.results.map((el) => {
            return (
              <ReportsItem
                key={el.id}
                color={el.color}
                code={el.code}
                title={el.title}
                absoluteUrl={el.absolute_url}
                statistics={el.statistics}
              />
            )
          })
          : null}

        {!isLoading && data?.results.length === 0 ? <EmptySearch /> : null}

        {isLoading ? <LoaderReportItems countShimmers={pageSize} /> : null}
      </div>

      <div className={style.reports__pagination}>
        <Pagination
          currentPage={pageNumber}
          total={data?.count ?? 0}
          pageSize={pageSize}
          handleChange={setPageNumber}
        />
      </div>
    </Wrapper>
  )
}

export default RunsReports
