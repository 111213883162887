import { logo } from '@/images'
import React from 'react'
import { NavLink } from 'react-router-dom'
import style from './styles/header.module.scss'
import { useLocale } from '@/hooks'
import { defaultLanguage } from '@/i18n'
import classNames from 'classnames'
import useGlobalConfig from '@/hooks/useGlobalConfig'

const Logo = ({ className = '' }): React.ReactElement => {
  const { locale } = useLocale()
  const homePageLink = locale !== defaultLanguage ? `/${locale}` : '/'

  const { show_maintenance_message: showMaintenanceMessage } =
    useGlobalConfig()

  return (
    <NavLink
      to={homePageLink}
      className={classNames({
        [style.header__logo_link]: true,
        [style['header__logo_link--indents']]: !showMaintenanceMessage,
        [className]: Boolean(className)
      })}
    >
      <img
        src={logo}
        alt='Eqator logo'
        width='130px'
        height='70px'
        className={style.header__logo}
      />
    </NavLink>
  )
}

export { Logo }
