import React from 'react'
import FailedData from '../Failed'
import EmptyList from '../EmptyList/EmptyList'
import { LoaderList } from './loaders'

interface IProps {
  isLoading: boolean
  error: any
  hasData: boolean
  emptyListIcon: string
  emptyListText: string
  emptyListButton?: string
  href?: string
  children: React.ReactNode
  className?: string
  classNameError?: string
  onClick?: () => void
}

const ListContent = ({
  isLoading,
  error,
  hasData,
  emptyListIcon,
  emptyListText,
  emptyListButton,
  href,
  onClick,
  children,
  className = '',
  classNameError = ''
}: IProps): React.ReactElement => {
  if (isLoading) return <LoaderList countShimmers={10} />

  if (error !== undefined) {
    return (
      <div className={classNameError}>
        <FailedData />
      </div>
    )
  }

  return (
    <>
      {!hasData
        ? (
          <EmptyList
            icon={emptyListIcon}
            text={emptyListText}
            buttonText={emptyListButton}
            href={href}
            onClick={onClick}
            className={className}
          />
          )
        : (
          <>{children}</>
          )}
    </>
  )
}

export default ListContent
