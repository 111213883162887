import Container from '@/components/Container'
import { Form } from '@/components/Form'
import { Footer, Head } from '@/components/Forms'
import { usePage, useStores } from '@/hooks'
import { IRunsElement } from '@/interfaces/Runs'
import { TRunStatus } from '@/interfaces/Types'
import { reactHookFormErrorFormater } from '@/utils'
import React, { useState } from 'react'
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm
} from 'react-hook-form'
import { useIntl } from 'react-intl'
import RunForm from './components/RunForm'
import { IValue } from '@/components/Select/interfaces/AsyncSelect'
import { useNavigate } from 'react-router-dom'
import { IAttachment } from '@/interfaces/Attachment'
import BaseModal from '@/components/Modal/Base'
import useModal from '@/hooks/useModal'
import DeleteRun from './components/DeleteRun'
import useSidebar from '@/hooks/useSidebar'
import { milestoneService } from '../MilestoneDetail/service'
import { observer } from 'mobx-react'
import { suiteService } from '../../apps/SuitesCases/service'
import { ICase } from '@/interfaces/Case'
import { SuitesCasesChanges } from '@/components/UpdateCases'
import UploadContainer from '@/components/UploadZone/UploadContainer/UploadContainer'
import style from './styles/add_run.module.scss'

interface IProps {
  id?: number
}

export interface IFormInputs
  extends Partial<Omit<IRunsElement, 'assigned_to' | 'milestone' | 'files'>> {
  non_field_errors?: string
  title?: string
  status?: TRunStatus
  references?: string
  description?: string
  files: IAttachment[]
  cases: ICase[]
  plan?: number
  project: number
  assigned_to?: number | null
  milestone?: IValue
}

const AddOrEditRun = observer(({ id }: IProps): React.ReactElement => {
  const [isDeleting, setIsDeleting] = useState(false)

  const isUpdate = id !== undefined
  const { api } = useStores()
  const page = usePage()
  const navigate = useNavigate()
  const { isOpen, handleOpenModal, handleCloseModal } = useModal()
  const { ProjectPageRuns } = useSidebar()
  const { project_object: projectObject } = page.init_state

  const emptyProject = {
    data: {
      id: 0,
      title: '',
      status: 'CREATED',
      references: '',
      description: '',
      assigned_to: null
    },
    project: projectObject.id,
    milestone:
      milestoneService.currentMilestone !== undefined &&
      milestoneService.currentMilestone.value !== 0
        ? {
            value: milestoneService.currentMilestone.value,
            label: milestoneService.currentMilestone.label
          }
        : undefined,
    files: [],
    cases: []
  }
  const intl = useIntl()

  const getDefaultValues = async (): Promise<IFormInputs> => {
    if (id !== undefined) {
      const run = await api.getRun({ id })
      const cases = await api.getBaseCasesInRun(id)
      const milestoneId = run.milestone?.id
      const assigned = run.assigned_to
      return {
        ...run,
        milestone:
          milestoneId !== undefined
            ? {
                value: milestoneId,
                label: run.milestone.title
              }
            : undefined,
        assigned_to: assigned?.id,
        cases: cases.results,
        files: run.files,
        project: projectObject.id
      }
    }
    return emptyProject
  }

  const {
    register,
    handleSubmit,
    control,
    setError,
    watch,
    formState: { isSubmitting, errors }
  } = useForm<IFormInputs>({
    defaultValues: id !== undefined ? getDefaultValues : emptyProject
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'cases',
    keyName: '_id'
  })

  const onSubmit: SubmitHandler<IFormInputs> = async (
    params
  ): Promise<void> => {
    try {
      if (id === undefined) {
        const res = await api.createRun(params)
        suiteService.resetCurrentSuite()
        navigate(res.absolute_url)
      } else {
        const res = await api.updateRun(id, params)
        suiteService.resetCurrentSuite()
        navigate(res.absolute_url)
      }
    } catch (error) {
      const errors = reactHookFormErrorFormater(params, error)
      errors.forEach((item) => {
        setError(item.key as keyof IFormInputs, {
          message: item.value
        })
      })
    }
  }

  const handleOpenDeleteModal = (): void => {
    handleOpenModal()
  }

  const handleDelete = async (): Promise<void> => {
    if (id === undefined) {
      return
    }

    setIsDeleting(true)

    api
      .deleteRun(id)
      .then(() => {
        handleCloseModal()
        navigate(ProjectPageRuns.absolute_url)
      })
      .catch((err) => console.log('err', err))
      .finally(() => setIsDeleting(false))
  }

  const initFiles = watch('files')
  const buttonText = isUpdate
    ? intl.formatMessage({
      id: 'runs.update_run',
      defaultMessage: 'Update run'
    })
    : intl.formatMessage({
      id: 'runs.add_run',
      defaultMessage: 'Add run'
    })

  const nonFieldErrors = errors?.non_field_errors?.message ?? ''

  return (
    <Container>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Head
          title={buttonText}
          isSubmitting={isSubmitting}
          errors={nonFieldErrors}
          buttonText={buttonText}
          pastHref={ProjectPageRuns.absolute_url}
          isDeletable={isUpdate}
          onDelete={handleOpenDeleteModal}
        />

        <div className={style.container}>
          <RunForm
            register={register}
            errors={errors}
            project={projectObject}
            control={control}
          />

          {initFiles !== undefined
            ? (
              <Controller
                control={control}
                name='files'
                render={({ field: { onChange } }) => (
                  <UploadContainer
                    initFiles={initFiles}
                    onChange={onChange}
                    isScrollableList
                    canUserDeleteFile
                    type='form'
                  />
                )}
              />
              )
            : null}
        </div>
        <SuitesCasesChanges
          selectedCase={fields}
          append={append}
          remove={remove}
        />

        <Footer
          isSubmitting={isSubmitting}
          errors={nonFieldErrors}
          buttonText={buttonText}
        />
      </Form>

      {isUpdate
        ? (
          <BaseModal
            open={isOpen}
            onGx-after-hide={handleCloseModal}
            onGx-overlay-dismiss={handleCloseModal}
            hideDefaultClose
            size='medium'
          >
            <DeleteRun
              onCancel={handleCloseModal}
              onDelete={handleDelete}
              disableSubmit={isDeleting}
            />
          </BaseModal>
          )
        : null}
    </Container>
  )
})

export default AddOrEditRun
