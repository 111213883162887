import React from 'react'
import style from '../styles/notification_item.module.scss'
import Icon from '@/components/Icon/Icon'

const NotifyCircle = (): React.ReactElement => {
  return (
    <div className={style.item__circle}>
      <Icon src='attention' className={style.item__icon} />
    </div>
  )
}

export default NotifyCircle
