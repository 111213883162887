import React from 'react'
import ProjectLayout from '../layout/components/ProjectLayout'
import UserLayout from '../layout/components/UserLayout'
import GitlabReport from '../ReportsGitlab/GitlabReport'

const ProjectPageGitlabReports = (): React.ReactElement => {
  return (
    <UserLayout>
      <ProjectLayout>
        <GitlabReport />
      </ProjectLayout>
    </UserLayout>
  )
}

export default ProjectPageGitlabReports
