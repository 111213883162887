import classNames from 'classnames'
import React from 'react'
import style from './styles/container.module.scss'

interface IProps {
  children: React.ReactNode
  className?: string
}

const Container = ({
  children,
  className = ''
}: IProps): React.ReactElement => {
  return (
    <div
      className={classNames({
        [style.container]: true,
        [className]: Boolean(className)
      })}
    >
      {children}
    </div>
  )
}
export default Container
