import Container from '@/components/Container'
import { Form } from '@/components/Form'
import { Footer, Head } from '@/components/Forms'
import BaseModal from '@/components/Modal/Base'
import { useStores } from '@/hooks'
import useModal from '@/hooks/useModal'
import useSidebar from '@/hooks/useSidebar'
import { IMilestone } from '@/interfaces/Milestones'
import { formatDateForFrontend, getRandomElement, reactHookFormErrorFormater } from '@/utils'
import React, { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import DeleteMilestone from './components/DeleteMilestone'
import MilestoneForm from './components/MilestoneForm'
import { MILESTONE_DEFAULT_COLORS } from './const'

interface IProps {
  title: string
  project: number
  id?: number
}
const emptyMilestone = {
  title: '',
  start_date: '',
  end_date: '',
  description: '',
  color: getRandomElement(MILESTONE_DEFAULT_COLORS)
}

interface IFormInputs extends Partial<IMilestone> {
  non_field_errors?: string
}

const AddOrEditMilestone = ({
  project,
  id,
  title
}: IProps): React.ReactElement => {
  const [isLoading, setIsLoading] = useState(false)

  const { api } = useStores()
  const intl = useIntl()
  const navigate = useNavigate()
  const { ProjectPageMilestones, ProjectPageRuns } = useSidebar()
  const { isOpen, handleOpenModal, handleCloseModal } = useModal()

  const getDefaultValues = async (): Promise<IFormInputs> => {
    if (id !== undefined) {
      const obj = await api.getMilestone({ id: id })
      delete obj.files
      return {
        ...obj,
        start_date: formatDateForFrontend(obj.start_date),
        end_date: formatDateForFrontend(obj.end_date)
      }
    }
    return { ...emptyMilestone, project }
  }

  const {
    register,
    watch,
    handleSubmit,
    setValue,
    setError,
    formState: { isSubmitting, errors }
  } = useForm<IFormInputs>({
    defaultValues: getDefaultValues
  })

  const onSubmit: SubmitHandler<IMilestone> = async (params): Promise<void> => {
    try {
      if (id === undefined) {
        const res = await api.createMilestone(params)
        navigate(res.absolute_url)
      } else {
        const res = await api.updateMilestone(id, params)
        navigate(res.absolute_url)
      }
    } catch (error) {
      const errors = reactHookFormErrorFormater(params, error, 'data')
      errors.forEach((item) => {
        setError(item.key as keyof IFormInputs, {
          message: item.value
        })
      })
    }
  }

  const isUpdate = id !== undefined

  const buttonText = isUpdate
    ? intl.formatMessage({
      id: 'milestones.update_milestone',
      defaultMessage: 'Update milestone'
    })
    : intl.formatMessage({
      id: 'milestones.add_milestone',
      defaultMessage: 'Add milestone'
    })

  const handleOpenDeleteModal = (): void => {
    handleOpenModal()
  }

  const handleDelete = async (): Promise<void> => {
    if (id === undefined) {
      return
    }

    setIsLoading(true)

    api
      .deleteMilestone(id)
      .then(() => {
        handleCloseModal()
        navigate(ProjectPageRuns.absolute_url)
      })
      .catch((err) => console.log('err', err))
      .finally(() => setIsLoading(false))
  }
  const nonFieldErrors = errors?.non_field_errors?.message ?? ''

  return (
    <Container>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Head
          title={title}
          isSubmitting={isSubmitting}
          errors={nonFieldErrors}
          buttonText={buttonText}
          pastHref={ProjectPageMilestones.absolute_url}
          isDeletable={isUpdate}
          onDelete={handleOpenDeleteModal}
        />
        <MilestoneForm
          watch={watch}
          setValue={setValue}
          errors={errors}
          register={register}
        />
        <Footer
          isSubmitting={isSubmitting}
          errors={nonFieldErrors}
          buttonText={buttonText}
        />
      </Form>

      {isUpdate
        ? (
          <BaseModal
            open={isOpen}
            onGx-after-hide={handleCloseModal}
            onGx-overlay-dismiss={handleCloseModal}
            hideDefaultClose
            size='medium'
          >
            <DeleteMilestone
              onCancel={handleCloseModal}
              onDelete={handleDelete}
              disableSubmit={isLoading}
            />
          </BaseModal>
          )
        : null}
    </Container>
  )
}

export default AddOrEditMilestone
