import { Accordion } from '@/components/Accordion'
import Title from '@/components/Title'
import Wrapper from '@/components/Wrapper'
import { IAttachment } from '@/interfaces/Attachment'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import UploadZone from '@/components/UploadZone/UploadZone'
import Button from '@/components/Button/Button'
import Icon from '@/components/Icon/Icon'
import { IFilePreview } from '@/components/UploadZone/interfaces'
import { useCurrentPermissions } from '@/hooks/useCurrentPermissions'
import { CREATE_PERMISSION_LEVEL, USER_PERMISSIONS } from '@/const'
import { useStores } from '@/hooks'
import style from '../styles/case_detail.module.scss'

interface IProps {
  initFiles: IAttachment[]
  mutateRun: () => void
  runId: number
  caseId: number
}

const AccordionHead = ({ count }: { count: number }): React.ReactElement => {
  return (
    <Title type='h2' className={style.files__title}>
      <FormattedMessage id='titles.files' defaultMessage='Files' />
      {` (${count})`}
    </Title>
  )
}

const Files = ({
  mutateRun,
  initFiles,
  runId,
  caseId
}: IProps): React.ReactElement => {
  const [files, setFiles] = useState<File[]>([])
  const [currentFile, setCurrentFile] =
        useState<Partial<IFilePreview> | null>(null)
  const [isDownloading, setIsDownloading] = useState(false)
  const { api } = useStores()

  const handleUpdate = async (): Promise<void> => {
    mutateRun()
  }

  const downloadAllFiles = (): void => {
    setIsDownloading(true)
    api
      .downloadCaseInRunFiles(runId, caseId)
      .then((res) => {
        if (res.file !== '') {
          const link = document.createElement('a')
          link.href = res.file

          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        } else {
          console.error('File URL not found in the response')
        }
      })
      .catch((error): void => {
        console.error(error)
      })
      .finally(() => {
        setIsDownloading(false)
      })
  }

  const onChange = (): void => {}

  const canUserDeleteFile = useCurrentPermissions(
    USER_PERMISSIONS.case,
    CREATE_PERMISSION_LEVEL
  )

  return (
    <Wrapper className={style.caseinrun_file}>
      <Accordion
        head={<AccordionHead count={initFiles.length} />}
        defaultOpened={false}
      >
        <UploadZone
          className={style.caseinrun_file}
          initFiles={initFiles}
          isScrollableList
          enableReinitialize
          isUpload={false}
          canUserDeleteFile={canUserDeleteFile}
          onChange={onChange}
          files={files}
          setFiles={setFiles}
          currentFile={currentFile}
          setCurrentFile={setCurrentFile}
          handleDeleteFile={handleUpdate}
          isForm
          readonly
        />

        {initFiles.length > 0
          ? (
            <div className={style.files__footer}>

              <Button
                theme='light'
                disabled={isDownloading}
                onClick={downloadAllFiles}
              >
                <Icon src='download' slot='icon-left' />
                <FormattedMessage
                  id='upload_zone.download.all'
                  defaultMessage='Download all files'
                />
              </Button>
            </div>
            )
          : null}

      </Accordion>
    </Wrapper>
  )
}

export default Files
