import React from 'react'
import style from './styles/loading.module.scss'
import classNames from 'classnames'
import { Shimmer } from '@/components/Loading'

interface IProps {
  className?: string
}

const LoaderCompanyInfoDashboard = ({
  className = ''
}: IProps): React.ReactElement => {
  return (
    <div
      className={classNames({
        [style.loading]: true,
        [className]: Boolean(className)
      })}
    >
      <Shimmer size='xs' />
    </div>
  )
}

export default LoaderCompanyInfoDashboard
