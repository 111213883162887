import CategoryFaqSelect from '@/components/Select/CategoryFaqSelect/CategoryFaqSelect'
import Title from '@/components/Title'
import Wrapper from '@/components/Wrapper'
import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import style from '../style/report_detail.module.scss'
import { useStores } from '@/hooks'
import { IValue } from '@/components/Select/interfaces/AsyncSelect'
import Textarea from '@/components/Textarea'
import Button from '@/components/Button/Button'
import { feedback } from '@/images'
import Icon from '@/components/Icon/Icon'

interface IProps{
  email: string
}

const SendQuestion = ({ email }: IProps): React.ReactElement => {
  const store = useStores()
  const intl = useIntl()
  const [category, setCategory] = useState<IValue>()
  const [text, setText] = useState<string>('')
  const [error, setError] = useState<string>('')
  const [isSend, setSend] = useState(false)
  const handleSendFeedback = (): void => {
    if (category === undefined) {
      setError(
        intl.formatMessage({
          id: 'faq.form.error.category',
          defaultMessage: 'Choose problem category'
        })
      )
      return
    }
    if (text === '') {
      setError(
        intl.formatMessage({
          id: 'faq.form.error.text',
          defaultMessage: 'Enter problem description'
        })
      )
      return
    }

    store.api
      .createFeedback({
        text: text,
        category: category.value
      })
      .then(() => {
        setError('')
        setCategory(undefined)
        setText('')
        setSend(true)
      })
      .catch((err) => console.log(err))
  }

  const handleCloseModal = (): void => {
    setSend(false)
  }

  return (
    <Wrapper className={style.send}>
      {!isSend
        ? (
          <>
            <Title type='h1'>
              <FormattedMessage
                id='faq.form.title'
                defaultMessage='Do you have any questions?'
              />
            </Title>

            <p>
              <FormattedMessage
                id='faq.form.description'
                defaultMessage='Ask a question online and we will contact you as soon as possible'
              />
            </p>

            <CategoryFaqSelect
              value={category}
              handleChange={setCategory}
              required
            />

            <Textarea
              className={style.send__textarea}
              required
              label={intl.formatMessage({
                id: 'faq.form.problem',
                defaultMessage: 'Description of your problem'
              })}
              onChange={(e) => setText(e.target.value)}
              value={text}
            />

            <Button
              className={style.send__button}
              type='button'
              onClick={handleSendFeedback}
            >
              <Icon src='resend' slot='icon-left' />

              <FormattedMessage id='faq.form.send' defaultMessage='Send' />
            </Button>
            {error !== ' ' ? <p className={style.send__error}>{error}</p> : null}
          </>
          )
        : (
          <div className={style.send__success}>
            <div className={style.send__top}>
              <img className={style.send__img} src={feedback} alt='Feedback' />
              <Button
                className={style.send__close}
                type='button'
                theme='light'
                circle
                onClick={handleCloseModal}
              >
                <Icon src='clear' />
              </Button>
            </div>

            <Title theme='h2' className={style.send__title}>
              <FormattedMessage
                id='faq.form.tnank'
                defaultMessage='Thank you for your request!'
              />
            </Title>

            <div className={style.send__bottom}>
              <p className={style.send__text}>
                <FormattedMessage
                  id='faq.form.success1'
                  defaultMessage='Your request has been successfully sent.'
                />
              </p>

              <p className={style.send__text}>
                <FormattedMessage
                  id='faq.form.success2'
                  defaultMessage='We will contact you by mail {email} as soon as possible.'
                  values={{ email }}

                />
              </p>
            </div>
          </div>
          )}
    </Wrapper>
  )
}

export default SendQuestion
