import React, {
  forwardRef,
  useEffect,
  useRef,
  useState,
  useImperativeHandle
} from 'react'
import style from '../../styles/update_cases.module.scss'
import { observer } from 'mobx-react'
import { TCaseType, TPriority } from '@/interfaces/Types'
import useSWR from 'swr'
import { useObjectPage, useStores } from '@/hooks'
import PrioritySelect from '@/components/Select/PrioritySelect/PrioritySelect'
import SearchInput from '@/components/Input/SearchInput/SearchInput'
import { ISuite } from '@/interfaces/Suites'
import useDebounce from '@/hooks/useDebounce'
import Tags from '@/components/Tags/Tags'
import { suiteService } from '../service'
import ListContent from '@/components/ListContent'
import CasesList from './CasesList'
import useSidebar from '@/hooks/useSidebar'
import RunStatusSelect from '@/components/Select/RunStatusSelect'
import Button from '@/components/Button/Button'
import Icon from '@/components/Icon/Icon'
import { useIntl } from 'react-intl'
import { TCaseOrdering } from '@/interfaces/ObjectPage'
import { scrollToBlock } from '@/utils'
import { pageSizeSelectService } from '@/services/PageSizeSelect'

export interface IFormInputs {
  suites: ISuite[]
}

export interface IProps {
  updatePage?: () => void
}

const Cases = observer(
  forwardRef(
    (
      { updatePage }: IProps,
      ref: React.MutableRefObject<{
        handleMutate: () => void
      } | null>
    ): React.ReactElement => {
      const { api } = useStores()
      const { id: runId, case_ordering: caseOrdering } = useObjectPage()
      const intl = useIntl()
      const subpage = useSidebar()

      const [currentPage, setCurrentPage] = useState(1)
      const [selectTags, setSelectTags] = useState<number[]>([])
      const [pageSize, setPageSize] = useState(
        pageSizeSelectService.getSize('runDetailCases') ?? 10
      )
      const [search, setSearch] = useState<string>('')
      const [priority, setPriority] = useState<TPriority | ''>('')
      const [status, setStatus] = useState<TCaseType | ''>('')
      const [sortBy, setSortBy] = useState<TCaseOrdering>(caseOrdering)
      const [isSortingActive, setIsSortingActive] = useState(false)

      const debouncedSearch = useDebounce(search)
      const casesRef = useRef<HTMLDivElement>(null)

      const suiteId = suiteService.currentSuite.id

      const { data, isLoading, error, mutate } = useSWR(
        {
          q: debouncedSearch,
          page: currentPage,
          id: runId,
          page_size: pageSize,
          priority: priority === '' ? undefined : priority,
          status: status === '' ? undefined : status,
          tags: selectTags,
          suite: suiteId !== null ? suiteId : undefined,
          without_suite: suiteId === undefined ? true : undefined,
          _key: 'getCasesInRun'
        },
        api.getCasesInRun,
        {
          revalidateOnFocus: false
        }
      )

      const handleSortCasesInRun = async (): Promise<void> => {
        setIsSortingActive(true)
        const newValue = sortBy === 'ASC' ? 'DESC' : 'ASC'
        void api
          .postCasesOrdering({
            runId: runId,
            caseOrdering: newValue
          })
          .then(async () => {
            setCurrentPage(1)
            setSortBy(newValue)
            await mutate()
          })
          .finally(() => {
            setIsSortingActive(false)
          })
      }

      const handleSearch = (value: string): void => {
        setSearch(value)
        setIsSortingActive(false)
        setCurrentPage(1)
      }

      const handlePriority = (value: TPriority): void => {
        setPriority(value)
        setCurrentPage(1)
      }

      const handleStatus = (value: TCaseType): void => {
        setStatus(value)
        setIsSortingActive(false)
        setCurrentPage(1)
      }

      const handleMutate = async (): Promise<void> => {
        await mutate()
        await updatePage?.()
      }

      useImperativeHandle(ref, () => ({ handleMutate }))

      const handleChangeTags = (): void => {
        setCurrentPage(1)
      }

      const handleChangePageSize = (e: any): void => {
        const newPageSize = Number(e.target.value)
        scrollToBlock(casesRef)
        setPageSize(newPageSize)
        pageSizeSelectService.setSize('runDetailCases', newPageSize)
        setCurrentPage(1)
      }

      const handleChangePage = (page: number): void => {
        setCurrentPage(page)
        scrollToBlock(casesRef)
      }

      useEffect(() => {
        setCurrentPage(1)
      }, [suiteId])

      useEffect(() => {
        suiteService.setDefaultValueSuite()
      }, [runId])

      return (
        <div className={style.cases}>
          <div>
            <div className={style.cases__filters}>
              <PrioritySelect
                handleChange={(e) => handlePriority(e.target.value)}
                value={priority}
              />

              <RunStatusSelect
                handleChange={(e) => handleStatus(e.target.value)}
                className={style.cases__select}
                value={status}
              />

              <SearchInput
                handleChange={handleSearch}
                value={search}
                className={style.cases__filter}
                placeholder={intl.formatMessage({
                  id: 'cases.search',
                  defaultMessage: 'Search checks'
                })}
              />

              <Button
                disabled={isSortingActive}
                theme='light'
                onClick={handleSortCasesInRun}
              >
                <Icon src={sortBy === 'ASC' ? 'asc' : 'desc'} />
              </Button>
            </div>
          </div>

          <Tags
            onChange={handleChangeTags}
            setSelectTags={setSelectTags}
            selectTags={selectTags}
            className={style.tags}
          />

          <ListContent
            isLoading={isLoading}
            error={error}
            hasData={data !== undefined && data?.count > 0}
            emptyListIcon='test_case'
            emptyListText={intl.formatMessage({
              id: 'cases.no_cases',
              defaultMessage: 'No test cases added yet'
            })}
            emptyListButton={intl.formatMessage({
              id: 'cases.add_case',
              defaultMessage: 'Add test case'
            })}
            href={subpage.ProjectPageCasesCreate.absolute_url}
          >
            {data !== undefined
              ? (
                <CasesList
                  data={data}
                  currentPage={currentPage}
                  pageSize={pageSize}
                  handleUpdate={handleMutate}
                  setPage={handleChangePage}
                  handlePageSize={handleChangePageSize}
                />
                )
              : null}
          </ListContent>
        </div>
      )
    }
  )
)

export default Cases
