import usePage from '@/hooks/usePage'
import { IObjectPage } from '@/interfaces/ObjectPage'
import React from 'react'
import { Helmet } from 'react-helmet-async'

const Layout = ({ children }): React.ReactElement => {
  const page = usePage()
  const { object } = page?.init_state
  const {
    seo_title: seoTitle = '',
    title
  } = object as IObjectPage
  const currentTitle = seoTitle === '' ? title : seoTitle
  return (
    <>
      <Helmet>
        <title>{currentTitle ?? ''}</title>
      </Helmet>
      {children}
    </>

  )
}

export {
  Layout
}
