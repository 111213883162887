import { calculatePercent } from '@/utils'
import React from 'react'
import Icon from '../Icon/Icon'
import { BadgeSimpleStatus } from '@/components/Status'
import { TextTag } from '../Tag'
import style from './styles/report_item.module.scss'
import Link from '../Link'

interface IProps {
  color: string
  code: string
  title: string
  absoluteUrl: string
  statistics: {
    count: number
    passed: number
    blocked: number
    retest: number
    failed: number
    untested: number
  }
}

const ReportsItem = ({
  color,
  code,
  title,
  absoluteUrl,
  statistics
}: IProps): React.ReactElement => {
  return (
    <div className={style.item}>
      <div className={style.item__info}>
        <div className={style.item__color} style={{ backgroundColor: color }} />
        <div>
          <span className={style.item__code}>{code}</span>

          <Link to={absoluteUrl} className={style.item__link}>
            <Icon src='test_case' />
            <span className={style.item__title}>{title}</span>
          </Link>
        </div>
      </div>

      <div className={style.item__statuses}>
        <BadgeSimpleStatus
          status='PASSED'
          count={statistics.passed}
          percent={calculatePercent(statistics.passed, statistics.count)}
          className={style.item__status}
        />

        <BadgeSimpleStatus
          status='BLOCKED'
          count={statistics.blocked}
          percent={calculatePercent(statistics.blocked, statistics.count)}
          className={style.item__status}
        />

        <BadgeSimpleStatus
          status='RETEST'
          count={statistics.retest}
          percent={calculatePercent(statistics.retest, statistics.count)}
          className={style.item__status}
        />

        <BadgeSimpleStatus
          status='FAILED'
          count={statistics.failed}
          percent={calculatePercent(statistics.failed, statistics.count)}
          className={style.item__status}
        />

        <BadgeSimpleStatus
          status='UNTESTED'
          count={statistics.untested}
          percent={calculatePercent(statistics.untested, statistics.count)}
          className={style.item__status}
        />
      </div>

      <TextTag size='large' className={style.item__counter}>
        <Icon src='test_case' />
        {statistics.count}
      </TextTag>
    </div>
  )
}

export default ReportsItem
