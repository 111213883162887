import React from 'react'
import { timeSince } from '@/utils'
import { NOTIFICATION_EVENT_TYPES } from '@/const'
import NotifyRunAssign from './NotifyRunAssign'
import {
  INotifiesApproveAllCasesData,
  INotifiesCaseChangeStatusData,
  INotifiesInviteSendedData,
  INotifiesInviteDeclinedData,
  INotifiesMilestoneCreateData,
  INotifiesMilestoneDeadlineData,
  INotifiesProfileFillData,
  INotifiesRunAssignData,
  INotifiesRunChangeStatusData,
  INotifiesSystemData,
  INotifiesInviteTimeoutData,
  INotifiesInviteProjectData,
  INotifiesExcludeProjectData,
  INotifiesRoleChangeData,
  INotifiesExcludeCompanyData,
  INotifiesImportCasesData
} from '@/interfaces/Notifies'
import NotifyRunChangeStatus from './NotifyRunChangeStatus'
import NotifyMilestoneDeadline from './NotifyMilestoneDeadline'
import NotifyProfileFill from './NotifyProfileFill'
import NotifyMilestoneCreate from './NotifyMilestoneCreate'
import NotifyWrapper from './components/NotifyWrapper'
import NotifyCaseChangeStatus from './NotifyCaseChangeStatus'
import NotifyAllCases from '@/components/NotificationItem/NotifyAllCases'
import NotifyCompanyInvite from './NotifyCompanyInvite'
import NotifyInviteDeclined from './NotifyInviteDeclined'
import NotifyInviteTimeout from './NotifyInviteTimeout'
import NotifyInviteProject from './NotifyInviteProject'
import NotifyRoleChange from './NotifyRoleChange'
import NotifyExcludeProject from './NotifyExcludeProject'
import NotifyExcludeCompany from './NotifyExcludeCompany'
import NotifyImportCases from './NotifyImportCases'

interface IProps {
  id: number
  isRead: boolean
  event: number
  sentAt: string
  handleMarkRead: (id: number) => void
  handleCloseModal: () => void
  data:
  | INotifiesRunAssignData
  | INotifiesSystemData
  | INotifiesRunChangeStatusData
  | INotifiesCaseChangeStatusData
  | INotifiesMilestoneDeadlineData
  | INotifiesProfileFillData
  | INotifiesMilestoneCreateData
  | INotifiesApproveAllCasesData
  | INotifiesInviteSendedData
  | INotifiesInviteDeclinedData
  | INotifiesInviteTimeoutData
  | INotifiesInviteProjectData
  | INotifiesExcludeProjectData
  | INotifiesRoleChangeData
  | INotifiesExcludeCompanyData
  | INotifiesImportCasesData
}

const NotificationItem = ({
  id,
  isRead,
  event,
  sentAt,
  handleMarkRead,
  handleCloseModal,
  data
}: IProps): React.ReactElement => {
  const time = timeSince(sentAt)
  const renderItemByType = (): React.ReactElement | null => {
    switch (event) {
      case NOTIFICATION_EVENT_TYPES.runAssigh:
        return (
          <NotifyRunAssign data={data as INotifiesRunAssignData} time={time} />
        )

        // case NOTIFICATION_EVENT_TYPES.system:
        //   return <NotifySystem data={data as INotifiesSystemData} time={time} />;

      case NOTIFICATION_EVENT_TYPES.runChangeStatus:
        return (
          <NotifyRunChangeStatus
            data={data as INotifiesRunChangeStatusData}
            time={time}
          />
        )

      case NOTIFICATION_EVENT_TYPES.caseChangeStatus:
        return (
          <NotifyCaseChangeStatus
            data={data as INotifiesCaseChangeStatusData}
            time={time}
          />
        )

      case NOTIFICATION_EVENT_TYPES.milestoneDeadline1:
        return (
          <NotifyMilestoneDeadline
            data={data as INotifiesMilestoneDeadlineData}
            time={time}
          />
        )

      case NOTIFICATION_EVENT_TYPES.milestoneDeadline2:
        return (
          <NotifyMilestoneDeadline
            data={data as INotifiesMilestoneDeadlineData}
            time={time}
          />
        )

      case NOTIFICATION_EVENT_TYPES.profileFill:
        return (
          <NotifyProfileFill
            data={data as INotifiesProfileFillData}
            time={time}
          />
        )

      case NOTIFICATION_EVENT_TYPES.milestoneCreate:
        return (
          <NotifyMilestoneCreate
            data={data as INotifiesMilestoneCreateData}
            time={time}
          />
        )

      case NOTIFICATION_EVENT_TYPES.allCases:
        return (
          <NotifyAllCases
            data={data as INotifiesApproveAllCasesData}
            time={time}
          />
        )

      case NOTIFICATION_EVENT_TYPES.inviteSended:
        return (
          <NotifyCompanyInvite
            data={data as INotifiesInviteSendedData}
            time={time}
          />
        )

      case NOTIFICATION_EVENT_TYPES.inviteDeclined:
        return (
          <NotifyInviteDeclined
            data={data as INotifiesInviteDeclinedData}
            time={time}
          />
        )

      case NOTIFICATION_EVENT_TYPES.inviteTimeout:
        return (
          <NotifyInviteTimeout
            data={data as INotifiesInviteTimeoutData}
            time={time}
          />
        )

      case NOTIFICATION_EVENT_TYPES.inviteProject:
        return (
          <NotifyInviteProject
            data={data as INotifiesInviteProjectData}
            time={time}
          />
        )

      case NOTIFICATION_EVENT_TYPES.roleChanged:
        return (
          <NotifyRoleChange
            data={data as INotifiesRoleChangeData}
            time={time}
          />
        )

      case NOTIFICATION_EVENT_TYPES.excludeProject:
        return (
          <NotifyExcludeProject
            data={data as INotifiesExcludeProjectData}
            time={time}
          />
        )

      case NOTIFICATION_EVENT_TYPES.excludeCompany:
        return (
          <NotifyExcludeCompany
            data={data as INotifiesExcludeCompanyData}
            time={time}
          />
        )

      case NOTIFICATION_EVENT_TYPES.importCases:
        return (
          <NotifyImportCases
            handleCloseModal={handleCloseModal}
            data={data as INotifiesImportCasesData}
            time={time}
          />
        )

      default:
        return null
    }
  }

  const Item = (): React.ReactElement | null => renderItemByType()

  if (Item !== null) {
    return (
      <NotifyWrapper
        isRead={isRead}
        handleMarkAsRead={() => handleMarkRead(id)}
      >
        <Item />
      </NotifyWrapper>
    )
  } else return <></>
}

export default NotificationItem
