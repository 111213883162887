import React from 'react'
import Icon from '@/components/Icon/Icon'
import { defaultCompany } from '@/images'
import classNames from 'classnames'
import style from '../styles/dashboard.module.scss'

interface IProps {
  isActive: boolean
  icon?: string
  logo?: string | null
  title: string
  onClick: () => void
  isSidebarOpened: boolean
}

const CompanyButton = ({
  isActive,
  icon,
  logo,
  title,
  onClick,
  isSidebarOpened
}: IProps): React.ReactElement => {
  const classes = classNames({
    [style.comp_btn]: true,
    [style['comp_btn--active']]: isActive,
    [style['comp_btn--big']]: isSidebarOpened
  })

  return (
    <button type='button' className={classes} onClick={onClick}>
      {icon !== undefined
        ? (
          <Icon src={icon} size='large' className={style.comp_btn__icon} />
          )
        : null}

      {logo !== undefined
        ? (
          <img
            src={logo ?? defaultCompany}
            alt={title}
            width={30}
            height={30}
            className={style.comp_btn__logo}
          />
          )
        : null}

      {isSidebarOpened
        ? (
          <span className={style.comp_btn__title}>{title}</span>
          )
        : null}
    </button>
  )
}

export default CompanyButton
