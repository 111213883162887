import React from 'react'
import style from '../styles/members.module.scss'
import Pagination from '@/components/Pagination'
import { ICompanyInvite } from '@/interfaces/Company'
import CompanyInvite from '@/components/Member/CompanyInvite'
import PaginatedQueryResult from '@/api/interfaces/PaginatedQueryResult'

interface IProps {
  data: PaginatedQueryResult<ICompanyInvite>
  currentPage: number
  pageSize: number
  setPage: (string) => void
  idCompany: number
  onMutate: () => Promise<void>
}

const InvitesList = ({
  data,
  currentPage,
  pageSize,
  setPage,
  idCompany,
  onMutate
}: IProps): React.ReactElement => {
  return (
    <>
      <div className={style.members__list}>
        {data.results.map((el) => {
          return (
            <CompanyInvite
              key={el.id}
              id={el.id}
              email={el.email}
              idCompany={idCompany}
              role={el.role.role_type}
              roleId={el.role.id}
              mutate={onMutate}
            />
          )
        })}
      </div>

      <div className={style.members__pagination}>
        <Pagination
          currentPage={currentPage}
          total={data.count}
          pageSize={pageSize}
          handleChange={setPage}
        />
      </div>
    </>
  )
}

export default InvitesList
