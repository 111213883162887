import { usePage } from '@/hooks'
import React from 'react'
import AddOrEditProject from '../AddOrEditProject'
import UserLayout from '../layout/components/UserLayout'

const AddOrEditProjectPage = (): React.ReactElement => {
  const page = usePage()
  const obj = page.init_state.object
  const companyTitle = obj.title
  const companyImage = obj.image

  return (
    <UserLayout>
      <AddOrEditProject
        companyTitle={companyTitle}
        companyImage={companyImage}
      />
    </UserLayout>
  )
}

export default AddOrEditProjectPage
