import AsyncSelect from 'react-select/async'
import React from 'react'
import style from './styles/baseasyncselect.module.scss'
import customSelectStyles from './styles'
import { useIntl } from 'react-intl'
import classNames from 'classnames'
import { BaseAsyncSelectProps } from '../interfaces/AsyncSelect'
import Select from 'react-select/dist/declarations/src/Select'
import { ClearIndicator, DropdownIndicator } from './components'
import { isServer } from '@garpix/cms'

const BaseAsyncSelect = React.forwardRef<Select, BaseAsyncSelectProps>(
  (
    {
      placeholder,
      label,
      isMulti = false,
      labelLeft = false,
      selectClassName = '',
      labelClassName = '',
      handleChange,
      loadOptions,
      value,
      required = false,
      error = '',
      disabled = false,
      closeMenuOnSelect = true,
      isSearchable = true,
      useMenuPortalTarget = true,
      ...props
    },
    ref
  ): React.ReactElement => {
    const intl = useIntl()

    const noOptionsMessage = intl.formatMessage({
      id: 'select.no_options',
      defaultMessage: 'No options'
    })

    const loadingMessage = intl.formatMessage({
      id: 'select.loading',
      defaultMessage: 'Loading'
    })

    return (
      <label
        className={classNames({
          [style.async__label]: true,
          [style.error]: Boolean(error),
          [style['async__label--left']]: labelLeft,
          [labelClassName]: Boolean(labelClassName)
        })}
      >
        <span
          className={classNames({
            [style.required]: required,
            [style.async__label_text]: true
          })}
        >
          {label}
        </span>
        {!isServer
          ? (
            <AsyncSelect
              {...props}
              ref={ref}
              value={value}
              styles={customSelectStyles}
              placeholder={placeholder}
              components={{ DropdownIndicator, ClearIndicator }}
              onChange={handleChange}
              isMulti={isMulti}
              closeMenuOnSelect={isMulti ? false : closeMenuOnSelect}
              noOptionsMessage={() => noOptionsMessage}
              loadingMessage={() => loadingMessage}
              cacheOptions
              defaultOptions
              required={required}
              isDisabled={disabled}
              loadOptions={loadOptions}
              className={selectClassName}
              menuPortalTarget={useMenuPortalTarget ? document?.body : null}
            />
            )
          : null}

        {error !== ''
          ? (
            <span className={style.async__label_error}>{error.toString()}</span>
            )
          : null}
      </label>
    )
  }
)

export default BaseAsyncSelect
