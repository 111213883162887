import MenuItem from '@/components/MenuItem/MenuItem'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { StatusSelectProps } from '../interfaces/SelectProps'
import Select from '../Select'

const RunStatusSelect = ({
  handleChange,
  value,
  ...props
}: StatusSelectProps): React.ReactElement => {
  const intl = useIntl()

  return (
    <Select
      {...props}
      placeholder={intl.formatMessage({
        id: 'input.placeholder',
        defaultMessage: 'Select'
      })}
      label={intl.formatMessage({
        id: 'runs.status',
        defaultMessage: 'Status'
      })}
      value={value}
      onChange={handleChange}
      clearable
    >
      <MenuItem value='PASSED'>
        <FormattedMessage id='status.test.passed' defaultMessage='Passed' />
      </MenuItem>

      <MenuItem value='BLOCKED'>
        <FormattedMessage id='status.test.blocked' defaultMessage='Blocked' />
      </MenuItem>

      <MenuItem value='RETEST'>
        <FormattedMessage
          id='status.test.retest'
          defaultMessage='Retest'
        />
      </MenuItem>

      <MenuItem value='FAILED'>
        <FormattedMessage
          id='status.test.failed'
          defaultMessage='Failed'
        />
      </MenuItem>

      <MenuItem value='UNTESTED'>
        <FormattedMessage
          id='status.test.untested'
          defaultMessage='Untested'
        />
      </MenuItem>
    </Select>
  )
}

export default RunStatusSelect
