import Button from '@/components/Button/Button'
import React, { useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'

const arr: Array<{ key: string }> = []

const SentryTestButton = (): React.ReactElement => {
  const [sentryError, setSentryError] = useState(false)

  const sendSentryError = (): void => {
    setSentryError(true)
  }

  useEffect(() => {
    if (sentryError) {
      Sentry.captureException(new Error('This is my fake error message'))

      throw new Error('Click to fake error button')
    }
  }, [sentryError])

  if (sentryError) {
    return <>{arr[0].key}</>
  }

  return (
    <div style={{ display: 'none' }}>
      <Button onClick={sendSentryError}>Send Sentry Error</Button>
    </div>
  )
}

export default SentryTestButton
