import MenuItem from '@/components/MenuItem/MenuItem'
import classNames from 'classnames'
import React, { forwardRef, useMemo } from 'react'
import { useIntl } from 'react-intl'
import Select from '../Select'
import style from '../styles/select.module.scss'
import { IValue } from '../interfaces/AsyncSelect'
import { useStores } from '@/hooks'
import useSWR from 'swr'
import { TCompanyRole } from '@/interfaces/Types'

interface IProps {
  className?: string
  handleChange: (value: IValue) => void
  value?: string
  required?: boolean
  error?: string
  bannedRoles?: TCompanyRole
  isFromChangeOwner?: boolean
}

const RoleCompanySelect = (
  {
    className = '',
    handleChange,
    value,
    error,
    required = false,
    bannedRoles,
    isFromChangeOwner = false
  }: IProps,
  ref
): React.ReactElement => {
  const intl = useIntl()
  const { api } = useStores()

  const { data, isLoading } = useSWR(
    { _key: 'getCompanyRoles' },
    api.getCompanyRoles
  )
  const filteredRoles = useMemo(
    () =>
      data?.results.filter(({ role_type: roleType }) => roleType !== bannedRoles) ?? [],
    [data, bannedRoles]
  )

  if (isLoading) return <></>

  return (
    <Select
      ref={ref}
      error={error}
      className={classNames({
        [style.role]: true,
        [className]: Boolean(className)
      })}
      placeholder={intl.formatMessage({
        id: 'input.placeholder',
        defaultMessage: 'Choose'
      })}
      required={required}
      label={intl.formatMessage({
        id: isFromChangeOwner ? 'company.change_owner.role' : 'input.role',
        defaultMessage: 'Role'
      })}
      value={value}
      onChange={handleChange}
      clearable
    >
      {filteredRoles.map((el) => {
        return (
          <MenuItem key={el.id} value={el.id}>
            {el.title}
          </MenuItem>
        )
      })}
    </Select>
  )
}

export default forwardRef(RoleCompanySelect)
