import SuiteItem from '@/components/SuiteItem/SuiteItem'
import { CHECKBOX_STATE } from '@/interfaces/Checkbox'
import { ISuite } from '@/interfaces/Suites'
import React from 'react'
import styles from './checkboxlist.module.scss'

interface CheckboxListProps {
  items: ISuite[]
  idsToRender?: number[]
  indentLevel?: number
  onClick?: (id: number) => void
  getStateForId: (id: number) => CHECKBOX_STATE | -1
}

const CheckboxList: React.FC<CheckboxListProps> = ({
  items,
  getStateForId,
  idsToRender = [],
  indentLevel = 0,
  onClick = () => {}
}) => {
  if (idsToRender.length === 0) {
    idsToRender = items.filter((i) => i.parent === null).map((i) => i.id)
  }

  const getChildNodes = (parentId: number): React.ReactElement => {
    const nodeItems = items.filter((i) => i.parent === parentId)
    if (nodeItems.length === 0) return <></>
    return (
      <CheckboxList
        items={items}
        idsToRender={nodeItems.map((i) => i.id)}
        indentLevel={indentLevel + 1}
        onClick={onClick}
        getStateForId={getStateForId}
      />
    )
  }

  return (
    <ul className={styles.list}>
      {idsToRender.map((id) => {
        const find = items.find((i) => i.id === id)
        const item =
          find !== undefined
            ? find
            : { title: '', cases_count: 0, parent: 0, id: 0, sort: 0 }
        const checkboxState = getStateForId(id)

        return (
          <React.Fragment key={item.id}>
            <li className={styles.item}>
              <SuiteItem
                title={item?.title}
                id={item.id}
                casesCount={item?.cases_count}
                parent={item?.parent}
                sort={item?.sort}
                onChange={() => onClick(item.id)}
                checked={checkboxState === CHECKBOX_STATE.CHECKED}
              />
            </li>

            <li>{getChildNodes(item?.id)}</li>
          </React.Fragment>
        )
      })}
    </ul>
  )
}

export default CheckboxList
