import { AuthorizationStorage, ApiUser, isServer } from '@garpix/cms'
import BaseApi, { QueryData } from '@garpix/base-api'
import { ICurrentUser, IUserProfile } from '@/interfaces/User'
import { Page } from './interfaces/Page'
import { IResponsePage } from '@/interfaces/ObjectPage'
import {
  IEpicFieldResponse,
  IGitlabReport,
  IGitlabReportItem,
  IGitlabSettingsParams,
  IHealthCheck,
  IIssue,
  IJiraCaseResult,
  IJiraIssues,
  IJiraIssuesParams,
  IJiraProjectParams,
  IJiraSettingsParams,
  IJiraTask,
  IJiraUserParams,
  IJiraUsers,
  IProject,
  IProjectCategories,
  IProjectReport,
  IStepCommentParams,
  IStepStatusParams,
  ITestPlans,
  ITypeOfIssues,
  JiraProject
} from '@/interfaces/Project'
import { IProjectElement } from '@/interfaces/Projects'
import PaginatedQueryResult from './interfaces/PaginatedQueryResult'
import {
  IApproveCase,
  ICreateFeedbackParams,
  IGetCasesParams,
  IGetCompanyInvites,
  IGetCompanyUsers,
  IGetFaqParams,
  IGetMilestonesParams,
  IGetProjectParams,
  IGetReportExcel,
  IGetReports,
  IGetRunCharts,
  IGetRunPdf,
  IGetRunsParams,
  IGetShareCasesInRun,
  IGetShareCliReports,
  IGetShareMilestonesParams,
  IGetShareReportExcel,
  IGetShareReportsParams,
  IGetShareRunCharts,
  IGetShareRunPdf,
  IGetStepsInfoParams,
  IGetSuitesParams,
  IPaginatedQueryParams,
  IPostChangeOwner,
  IPostChangeRole,
  IPostCompanyInvites,
  IPostTurnNotifications,
  IPostSyncCase,
  IGetSuitesRunParams,
  IPatchProjectMemberRole,
  IGetProjectMembersParams,
  IGetTestPlans,
  IGetCompanies,
  IGetCompanyUsersNotInProject,
  IGetJiraIssues,
  IGetImportNorifiesParams
} from '@/interfaces/ApiParams'
import { IMilestone, IMilestonesList } from '@/interfaces/Milestones'
import {
  ICaseInRunAllFiles,
  IRunChart,
  IRunReport,
  IRunsElement,
  IStatistics
} from '@/interfaces/Runs'
import {
  IProjectMember,
  IProjectRole,
  IProjectRoleShort,
  ITempProjectMember
} from '@/interfaces/Member'
import { ISingleSuite, ISuite, ISuiteExportItem } from '@/interfaces/Suites'
import {
  ICase,
  ICaseApproveAll,
  ICaseInRun,
  ICaseInRunComment,
  ICaseNext,
  IClonedCase,
  IStep,
  ITag
} from '@/interfaces/Case'
import { formatDateForBackend, isString } from '@/utils'
import qs from 'qs'
import {
  ICliReports,
  IGetCliReports,
  IReportsList
} from '@/interfaces/Reports'
import {
  TCaseStatusInRun,
  TPageModel,
  TProjectRolesPermissions
} from '@/interfaces/Types'
import { format, parse } from 'date-fns'
import { IValue } from '@/components/Select/interfaces/AsyncSelect'
import { IHistoryElement } from '@/interfaces/History'
import { IFaq, IFaqCategory } from '@/interfaces/Faq'
import { INotifies } from '@/interfaces/Notifies'
import {
  IShareCaseInRun,
  IShareCliReports,
  IShareMilestonesList,
  IShareReportsList,
  IShareRunReport
} from '@/interfaces/Share'
import {
  ICompanyList,
  ICompany,
  ICompanyUser,
  INewOwner,
  ICompanyInvite,
  IRole
} from '@/interfaces/Company'
import { IFavoriteAdd, IFavoriteDelete } from '@/interfaces/Favorite'

const errorSerializer = (): any => {
  const data = {
    init_state: {
      object: {
        title: '500',
        seo_title: '500'
      }
    }
  }
  return data
}

const defaultSerializer = (data: any): any => data
const notFoundSerializer = (data: any): any => {
  data.init_state.object = {
    title: '404',
    seo_title: '404'
  }
  return data
}
const unauthorizedSerializer = (data: any): any => {
  data.init_state.object = {
    title: '401',
    seo_title: '401'
  }
  return data
}

const forbiddenSerializer = (data: any): any => {
  data.init_state.object = {
    title: '403',
    seo_title: '403'
  }
  return data
}

const PAGES: { [key in TPageModel]: (data: any) => any } = {
  Page: defaultSerializer,
  HomePage: defaultSerializer,
  LoginPage: defaultSerializer,
  RegistrationPage: defaultSerializer,
  RegistrationPageConfirmed: defaultSerializer,
  EmailConfirmationPage: defaultSerializer,
  PasswordRecoveryPage: defaultSerializer,
  DashboardPage: defaultSerializer,
  LandingPage: defaultSerializer,
  ProjectPage: defaultSerializer,
  CompanyPage: defaultSerializer,
  CompanyPageUpdate: defaultSerializer,
  CompanyPageMembers: defaultSerializer,
  CompanyPageChangeOwner: defaultSerializer,
  CompanyPageInvites: defaultSerializer,
  CompanyPageProjects: defaultSerializer,
  CompanyPageMembersDelete: defaultSerializer,
  CompanyPageMembersInvite: defaultSerializer,
  MilestonePage: defaultSerializer,
  CompanyListPage: defaultSerializer,
  CompanyPageCreate: defaultSerializer,
  ProjectListPage: defaultSerializer,
  ProjectPageCreate: defaultSerializer,
  ProjectPageUpdate: defaultSerializer,
  ProjectPageMilestones: defaultSerializer,
  ProjectPageMilestonesCreate: defaultSerializer,
  ProfilePage: defaultSerializer,
  AnotherProfilePage: defaultSerializer,
  RunPage: defaultSerializer,
  RunPageUpdate: defaultSerializer,
  ProjectPageRuns: defaultSerializer,
  ProjectPageRunsCreate: defaultSerializer,
  ProjectPageCasesAndSuites: defaultSerializer,
  ProjectPageSuitesCreate: defaultSerializer,
  ProjectPageCasesCreate: defaultSerializer,
  CaseRunDetailPage: defaultSerializer,
  CasePageUpdate: defaultSerializer,
  MilestonePageUpdate: defaultSerializer,
  CasePage: defaultSerializer,
  ProjectPageCasesDetail: defaultSerializer,
  ProjectPageGitlabReports: defaultSerializer,
  ProjectPageReports: defaultSerializer,
  ProjectPageIntegrations: defaultSerializer,
  ProjectPageRoles: defaultSerializer,
  FaqPage: defaultSerializer,
  RunPageReport: defaultSerializer,
  ProjectPageHistory: defaultSerializer,
  ProjectPageMembers: defaultSerializer,
  SharePage: defaultSerializer,
  SharePageReports: defaultSerializer,
  SharePageRunReports: defaultSerializer,
  401: unauthorizedSerializer,
  500: defaultSerializer,
  Page404: notFoundSerializer,
  Page403: forbiddenSerializer
}

class Api extends BaseApi {
  AUTH_TOKEN_KEY: string
  language: string
  storage: AuthorizationStorage
  userApi: ApiUser

  constructor (MAIN_URL, language, { userApi }) {
    super(MAIN_URL)
    this.storage = new AuthorizationStorage()
    this.AUTH_TOKEN_KEY = 'gx_access_token'
    this.language = language
    this.userApi = userApi
  }

  getLanguage = (): string => this.language

  isAuthToken = (): boolean => {
    return this.storage.accessToken !== null
  }

  axiosOverride = (axios): any => axios

  asyncOverride = async (axios): Promise<any> => {
    const token = this.storage.accessToken
    axios.defaults.headers.common['Accept-Language'] = this.getLanguage()
    if (token != null) {
      axios.defaults.headers.common.Authorization = `Bearer ${token}`
    }
    if (!isServer) {
      const sessionToken = await this.userApi.getUserSessionToken()
      axios.defaults.headers.common['user-session-token'] = sessionToken
    }
    return axios
  }

  getPage = async (
    pathname: string,
    queryParams = {},
    axiosParams = {}
  ): Promise<IResponsePage> => {
    try {
      const path = pathname.charAt(0) === '/' ? pathname : `/${pathname}`
      const res: { data: Page } = await this.get(
        `/page${path}`,
        queryParams,
        axiosParams
      )
      const page = res.data
      const serializer = PAGES[page.page_model]
      return {
        pageType: page.page_model,
        page: serializer(page),
        statusCode: 200
      }
    } catch (error: any) {
      const status = error?.response?.status
      if (status === 404) {
        const serializer = PAGES.Page404
        return {
          pageType: 'Page404',
          statusCode: 404,
          page: serializer(error.response.data)
        }
      }
      if (status === 403) {
        const serializer = PAGES.Page403
        return {
          pageType: 'Page403',
          statusCode: 403,
          page: serializer(error.response.data)
        }
      }
      if (status === 401) {
        const serializer = PAGES['401']
        return {
          pageType: '401',
          statusCode: 401,
          page: serializer(error.response.data)
        }
      }
      return {
        pageType: '500',
        page: errorSerializer(),
        statusCode: 500
      }
    }
  }

  getCurrentUser = async (): Promise<ICurrentUser> => {
    const res = await this.get<ICurrentUser>('/user/current/')
    return res.data
  }

  changePassword = async (params: {
    password: string
    new_password: string
  }): Promise<ICurrentUser> => {
    const res = await this.post<ICurrentUser>(
      '/garpix_user/change_password/',
      params
    )
    return res.data
  }

  sendCodeRestorePassword = async (params: {
    username: string
  }): Promise<void> => {
    await this.post('/garpix_user/restore_password/send_code/', params)
  }

  checkCodeRestorePassword = async (params: {
    restore_password_confirm_code: string
    username: string
  }): Promise<void> => {
    await this.post('/garpix_user/restore_password/check_code/', params)
  }

  setPasswordRestore = async (params: {
    new_password: string
    username: string
    restore_password_confirm_code: string
  }): Promise<void> => {
    await this.post('/garpix_user/restore_password/set_password/', params)
  }

  registerSetPassword = async (params: {
    password: string
    password_2: string
    email: string
    phone: string
  }): Promise<void> => {
    await this.post('/garpix_user/register/', params)
  }

  sendCodePhone = async (params: { phone: string }): Promise<void> => {
    await this.post('/garpix_user/confirm_phone/send_code/', params)
  }

  checkCodePhone = async (params: {
    phone_confirmation_code: string
  }): Promise<void> => {
    await this.post('/garpix_user/confirm_phone/check_code/', params)
  }

  sendCodeEmail = async (params: { email: string }): Promise<void> => {
    await this.post('/garpix_user/confirm_email/send_code/', params)
  }

  registrationFirstStep = async (params: { email: string, phone: string }): Promise<void> => {
    await this.post('/user/registration_first_step/', params)
  }

  getFormdataUser = (params, image): FormData => {
    const dataJson = JSON.stringify(params)
    const fd = new FormData()
    fd.append('data', dataJson)
    if (image !== undefined && !isString(image)) {
      fd.append('avatar', image)
    }
    return fd
  }

  updateProfile = async (params): Promise<IUserProfile> => {
    const copyParams = { ...params }
    const avatar = copyParams.avatar?.[0]
    delete copyParams.avatar
    const data = copyParams

    const fd = this.getFormdataUser(data, avatar)
    const res = await this.patch<IUserProfile>('/user/update_profile/', fd)
    return res.data
  }

  getProjects = async (
    params: IGetProjectParams
  ): Promise<PaginatedQueryResult<IProjectElement>> => {
    const res = await this.get<PaginatedQueryResult<IProjectElement>>(
      '/projects/',
      params
    )
    return res.data
  }

  getFormdataProject = (params, image): FormData => {
    const dataJson = JSON.stringify(params)
    const fd = new FormData()
    fd.append('data', dataJson)
    if (image !== undefined && !isString(image)) {
      fd.append('image', image)
    }
    return fd
  }

  createProject = async (params): Promise<IProject> => {
    const copyParams = { ...params }
    const image = copyParams.image?.[0]
    delete copyParams.image
    const data = copyParams

    data.urls = data.urls.filter((url) => url.value !== '')
    const fd = this.getFormdataProject(data, image)
    const res = await this.post<IProject>('/projects/', fd, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    return res.data
  }

  getProject = async (params: { id: number | string }): Promise<IProject> => {
    const id = params.id
    const res = await this.get<IProject>(`/projects/${id}/`)
    return res.data
  }

  getGitlabBranches = async (params: {
    idProject: number | string
    page: number
  }): Promise<string[]> => {
    const id = params.idProject
    const page = params.page
    const res = await this.get<string[]>(
      `/projects/${id}/gitlab_branches_list/?page=${page}`
    )
    return res.data
  }

  getGitlabTagsList = async (params: {
    id: number | string
    page: number
  }): Promise<string[]> => {
    const id = params.id
    const page = params.page
    const res = await this.get<string[]>(
      `/projects/${id}/gitlab_tags_list/?page=${page}`
    )
    return res.data
  }

  getGitlabReportList = async (params: {
    id: number | string
  }): Promise<IGitlabReportItem[]> => {
    const id = params.id
    const res = await this.get<IGitlabReportItem[]>(
      `/projects/${id}/gitlab_reports_list/`
    )
    return res.data
  }

  postGitlabReport = async (
    id: number | string,
    params: IGitlabReport
  ): Promise<IGitlabReport> => {
    const res = await this.post<IGitlabReport>(
      `/projects/${id}/generate_gitlab_report/`,
      {
        branch: params.branch,
        is_date_interval: params.is_date_interval,
        tag_from: params.tag_from,
        tag_to: params.tag_to,
        tag_new: params.tag_new,
        report_format: params.report_format,
        updated_after: params.updated_after,
        updated_before: params.updated_before
      }
    )
    return res.data
  }

  getGitlabTagList = async (params: {
    id: number | string
  }): Promise<IProject> => {
    const id = params.id
    const res = await this.get<any>(`/projects/${id}/gitlab_tags_list/`)
    return res.data
  }

  getJiraCustomFields = async (params: {
    id: number | string
    issue_type?: string | number
    jira_id?: string | number
  }): Promise<IEpicFieldResponse> => {
    const pk = params.id
    const jiraId = params.jira_id
    const res = await this.get<IEpicFieldResponse>(
      `/projects/${pk}/jira_issues/get_custom_fields/`,
      { issue_type: params.issue_type, jira_id: jiraId }
    )
    return res.data
  }

  getJiraCategories = async (params: {
    id: number | string
    q: string
  }): Promise<IProjectCategories[]> => {
    const pk = params.id
    const res = await this.get<IProjectCategories[]>(
      `/projects/${pk}/jira_projects/get_categories/`
    )
    return res.data
  }

  getJiraIssueTypes = async (params: {
    id: number | string
    jiraId?: number | string
    fromProject?: boolean
  }): Promise<ITypeOfIssues[]> => {
    const res = await this.get<any>(
      `/projects/${params.id}/jira_issues/get_issue_types/`,
      {
        jira_id: params.jiraId,
        from_project: params.fromProject
      }
    )
    return res.data
  }

  getJiraHealthCheck = async (id: number | string): Promise<IHealthCheck> => {
    const res = await this.get<IHealthCheck>(
      `/projects/${id}/jira_health_check/`
    )
    return res.data
  }

  getJiraProjects = async (
    params: IJiraProjectParams
  ): Promise<JiraProject[]> => {
    const id = params.id
    const res = await this.get<IJiraProjectParams, JiraProject[]>(
      `/projects/${id}/jira_projects/`,
      params
    )
    return res.data
  }

  getJiraUsers = async (params: IJiraUserParams): Promise<IJiraUsers[]> => {
    const id = params.id
    const res = await this.get<IJiraUserParams, IJiraUsers[]>(
      `/projects/${id}/jira_users/`,
      params
    )
    return res.data
  }

  getJiraIssues = async (params: IJiraIssuesParams): Promise<IIssue[]> => {
    const res = await this.get<IJiraIssuesParams, IIssue[]>(
      `/projects/${params.id}/jira_issues/`,
      params
    )
    return res.data
  }

  getJiraCaseIssues = async (params: {
    project_pk: number | string
    case_id: number | string
  }): Promise<IJiraCaseResult[]> => {
    const pk = params.project_pk
    const caseId = params.case_id
    const res = await this.get<IJiraCaseResult[]>(
      `/projects/${pk}/jira_issues/case_issues/${caseId}/`
    )
    return res.data
  }

  getJiraTestPlans = async (
    params: IGetTestPlans
  ): Promise<PaginatedQueryResult<ITestPlans>> => {
    const res = await this.get<PaginatedQueryResult<ITestPlans>>(
      '/test_plans/',
      params
    )
    return res.data
  }

  getJiraTasksList = async (
    params: IGetJiraIssues
  ): Promise<PaginatedQueryResult<IJiraTask>> => {
    const res = await this.get<PaginatedQueryResult<IJiraTask>>(
      `/projects/${params.idProject}/jira_issues/full_jira_list/`,
      params
    )
    return res.data
  }

  getGitlabHealthCheckStatus = async (params: {
    id: number | string
  }): Promise<IHealthCheck> => {
    const pk = params.id
    const res = await this.get<IHealthCheck>(
      `/projects/${pk}/gitlab_health_check/`
    )
    return res.data
  }

  getFormdataJiraIssue = (params, files): FormData => {
    const dataJson = JSON.stringify(params)
    const fd = new FormData()
    fd.append('data', dataJson)
    files.forEach((element) => {
      fd.append('files', element)
    })
    return fd
  }

  postJiraIssues = async (
    id: number | string,
    { files, ...params }: IJiraIssues
  ): Promise<IJiraIssues> => {
    const formData = this.getFormdataJiraIssue(params, files)
    const res = await this.post<IJiraIssues>(
      `/projects/${id}/jira_issues/`,
      formData
    )
    return res.data
  }

  updateProject = async (id: number | string, params): Promise<IProject> => {
    const data = { ...params }
    const image = data.image?.[0]
    delete data.image

    const fd = this.getFormdataProject(data, image)
    const res = await this.patch<IProject>(`/projects/${id}/`, fd)
    return res.data
  }

  postJiraSettings = async (
    id: number | string,
    params: IJiraSettingsParams
  ): Promise<IJiraSettingsParams> => {
    const res = await this.post<IJiraSettingsParams>(
      `/projects/${id}/set_jira_settings/`,
      params
    )
    return res.data
  }

  postGitlabSettings = async (
    id: number | string,
    params: IGitlabSettingsParams
  ): Promise<IGitlabSettingsParams> => {
    const res = await this.post<IGitlabSettingsParams>(
      `/projects/${id}/set_gitlab_settings/`,
      params
    )
    return res.data
  }

  deleteProject = async (id: number): Promise<void> => {
    await this.delete<ICase>(`/projects/${id}/`)
  }

  addFavorite = async (params: IFavoriteAdd): Promise<void> => {
    await this.post('/favorites/', params)
  }

  deleteFavorite = async ({
    modelName,
    objectId
  }: IFavoriteDelete): Promise<void> => {
    await this.delete(`/favorites/${modelName}/${objectId}/`)
  }

  getMilestone = async ({ id }: { id: number }): Promise<IMilestone> => {
    const res = await this.get<IMilestone>(`/milestones/${id}/`)
    return res.data
  }

  deleteMilestone = async (id: number): Promise<void> => {
    await this.delete<IMilestone>(`/milestones/${id}/`)
  }

  getMilestones = async (
    params: IGetMilestonesParams
  ): Promise<PaginatedQueryResult<IMilestonesList>> => {
    const res = await this.get<PaginatedQueryResult<IMilestonesList>>(
      '/milestones/',
      params
    )
    return res.data
  }

  milestoneParamsFormat = ({ files = [], ...params }): FormData => {
    const fd = new FormData()
    const newParams = { ...params }
    if (newParams.start_date !== undefined) {
      newParams.start_date = formatDateForBackend(newParams.start_date)
    }
    if (newParams.end_date !== undefined) {
      newParams.end_date = formatDateForBackend(newParams.end_date)
    }
    const dataJson = JSON.stringify(newParams)
    fd.append('data', dataJson)
    files.forEach((element) => {
      fd.append('files', element)
    })
    return fd
  }

  createMilestone = async (params): Promise<any> => {
    const res = await this.post(
      '/milestones/',
      this.milestoneParamsFormat(params)
    )
    return res.data
  }

  updateMilestone = async (id: number, params: Partial<any>): Promise<any> => {
    const res = await this.patch(
      `/milestones/${id}/`,
      this.milestoneParamsFormat(params)
    )
    return res.data
  }

  getRun = async (params: { id: number }): Promise<IRunsElement> => {
    const res = await this.get<IRunsElement>(`/runs/${params.id}/`)
    return res.data
  }

  deleteRun = async (id: number): Promise<void> => {
    await this.delete<IRunsElement>(`/runs/${id}/`)
  }

  getRuns = async (
    params: IGetRunsParams
  ): Promise<PaginatedQueryResult<IRunsElement>> => {
    const res = await this.get<PaginatedQueryResult<IRunsElement>>(
      '/runs/',
      params,
      {
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: 'repeat' })
        }
      }
    )
    return res.data
  }

  getCasesInRun = async ({
    id,
    ...params
  }: {
    id: number
  }): Promise<PaginatedQueryResult<ICaseInRun> | undefined> => {
    if (id !== null) {
      const res = await this.get<PaginatedQueryResult<ICaseInRun>>(
        `/runs/${id}/cases/`,
        params,
        {
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: 'repeat' })
          }
        }
      )
      return res.data
    } else return undefined
  }

  getCaseInRun = async (params: {
    runId: number
    caseId: number
  }): Promise<ICaseInRun> => {
    const runId = params.runId
    const caseId = params.caseId
    const res = await this.get<ICaseInRun>(`/runs/${runId}/cases/${caseId}/`)
    return res.data
  }

  postCasesOrdering = async ({
    runId,
    caseOrdering
  }: {
    runId: number
    caseOrdering: string
  }): Promise<PaginatedQueryResult<ICaseInRun>> => {
    const res = await this.post<PaginatedQueryResult<ICaseInRun>>(
      `/runs/${runId}/set_cases_ordering/`,
      { case_ordering: caseOrdering }
    )
    return res.data
  }

  getBaseCasesInRun = async (
    id: number
  ): Promise<PaginatedQueryResult<ICase>> => {
    const res = await this.get<PaginatedQueryResult<ICase>>(
      `/runs/${id}/base_cases/`
    )
    return res.data
  }

  getCaseInRunSteps = async (params: {
    id: number
    case_id: number
  }): Promise<IStep[]> => {
    const id = params.id
    const caseId = params.case_id
    const res = await this.get<IStep[]>(`/runs/${id}/cases/${caseId}/steps/`)
    return res.data
  }

  postStepRunStatus = async (
    params: IStepStatusParams
  ): Promise<IStepStatusParams> => {
    const res = await this.post<IStepStatusParams>(
      `/step-run/${params.id}/change_status/`,
      params
    )
    return res.data
  }

  postStepComment = async (
    params: IStepCommentParams
  ): Promise<IStepCommentParams> => {
    const res = await this.post<IStepCommentParams>(
      `/step-run/${params.id}/comment/`,
      params
    )
    return res.data
  }

  postRunAssigned = async (params: {
    id: number
    deadline: string
    user_project: IValue
    description?: string
  }): Promise<any> => {
    const id = params.id
    const deadline =
      format(parse(params.deadline, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd') +
      ' 12:00'
    const userProject = params.user_project.value
    const description = params.description
    const res = await this.post<any>(`/runs/${id}/assigned/`, {
      deadline,
      user_project: userProject,
      description
    })
    return res.data
  }

  cloneRun = async (params: { id: number }): Promise<IRunsElement> => {
    const id = params.id
    const res = await this.post<IRunsElement>(`/runs/${id}/clone/`, {})
    return res.data
  }

  postRunInProgress = async (params: { id: number }): Promise<IRunsElement> => {
    const id = params.id
    const res = await this.post<IRunsElement>(`/runs/${id}/in_process/`, {})
    return res.data
  }

  postRunCompleted = async (params: { id: number }): Promise<IRunsElement> => {
    const id = params.id
    const res = await this.post<IRunsElement>(`/runs/${id}/completed/`, {})
    return res.data
  }

  formDataRun = ({ files = [], ...params }): FormData => {
    const fd = new FormData()
    const newParams = { ...params }
    if (newParams.milestone !== undefined) {
      newParams.milestone = newParams.milestone.value
    }
    if (newParams.cases !== undefined) {
      newParams.cases = newParams.cases.map((el) => {
        if (el.case_id !== undefined) return el.case_id
        else return el.id
      })
    }
    const dataJson = JSON.stringify(newParams)
    fd.append('data', dataJson)
    files
      .filter((el: File) => el instanceof File)
      .forEach((element) => {
        fd.append('files', element)
      })
    return fd
  }

  createRun = async (params): Promise<any> => {
    const res = await this.post('/runs/', this.formDataRun({ ...params }))
    return res.data
  }

  updateRun = async (
    id: number,
    { files = [], ...params }: Partial<any>
  ): Promise<any> => {
    const res = await this.patch(
      `/runs/${id}/`,
      this.formDataRun({ files, ...params })
    )
    return res.data
  }

  updateRunMilestone = async (
    idRun: number,
    idMilestone: number
  ): Promise<any> => {
    const res = await this.patch(`/runs/${idRun}/`, {
      data: { milestone: idMilestone }
    })
    return res.data
  }

  syncCaseInRun = async (
    idRun: number,
    idCase: number,
    params: IPostSyncCase
  ): Promise<IPostSyncCase> => {
    const res = await this.post<IPostSyncCase>(
      `/runs/${idRun}/cases/${idCase}/sync_updates/`,
      params
    )
    return res.data
  }

  getStatsInfo = async (params: IGetStepsInfoParams): Promise<IStatistics> => {
    const res = await this.get<IStatistics>('/runs/steps-info/', params)
    return res.data
  }

  getReportPdf = async (params: IGetRunPdf): Promise<any> => {
    const res = await this.get<IGetRunPdf>(
      `/runs/${params.id}/generate_reports_pdf/`,
      params,
      {
        responseType: 'blob'
      }
    )
    return res
  }

  getUsers = async (
    params: IGetMilestonesParams
  ): Promise<PaginatedQueryResult<IProjectMember>> => {
    const res = await this.get<PaginatedQueryResult<IProjectMember>>(
      '/user/',
      params
    )
    return res.data
  }

  getProjectMembers = async (
    projectPk: number,
    params: IGetProjectMembersParams
  ): Promise<PaginatedQueryResult<IProjectMember>> => {
    const res = await this.get<PaginatedQueryResult<IProjectMember>>(
      `/projects/${projectPk}/members/`,
      params,
      {
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: 'repeat' })
        }
      }
    )
    return res.data
  }

  removeMembers = async (projectId: number, id: number): Promise<any> => {
    const res = await this.delete(`/projects/${projectId}/members/${id}/`)
    return res.data
  }

  getFormdataCase = (params, files): FormData => {
    if (params.suite !== undefined && params.suite !== null) {
      params.suite =
        typeof params.suite === 'number' ? params.suite : params.suite.value
    }

    params.project = params.project.id
    const dataJson = JSON.stringify(params)
    const fd = new FormData()
    fd.append('data', dataJson)
    files.forEach((element) => {
      fd.append('files', element)
    })
    return fd
  }

  createCase = async ({ files = [], ...params }): Promise<ICase> => {
    const res = await this.post<ICase>(
      '/cases/',
      this.getFormdataCase(params, files)
    )
    return res.data
  }

  updateCase = async (
    id: number,
    { files = [], ...params }
  ): Promise<ICase> => {
    const res = await this.patch<ICase>(
      `/cases/${id}/`,
      this.getFormdataCase(params, files)
    )
    return res.data
  }

  getSuites = async (params: IGetSuitesParams): Promise<ISuite[]> => {
    const res = await this.get<any>(`/projects/${params.project_id}/suites/`, {
      q: params.q,
      exclude: params.exclude
    })
    return res.data
  }

  getSuite = async ({ id }: { id: number }): Promise<ISingleSuite> => {
    const res = await this.get<ISingleSuite>(`/suites/${id}/`)
    return res.data
  }

  createSuite = async (params): Promise<ISuite> => {
    const res = await this.post<ISuite>('/suites/', params)
    return res.data
  }

  updateSuite = async (id: number, params): Promise<ISuite> => {
    const res = await this.patch<ISuite>(`/suites/${id}/`, params)
    return res.data
  }

  deleteSuite = async (id: number): Promise<void> => {
    await this.delete<ISuite>(`/suites/${id}/`)
  }

  importCase = async (
    idProject: number,
    idSuite: number | undefined,
    file: FileList
  ): Promise<void> => {
    const formData = new FormData()
    formData.append('file', file[0])
    if (idSuite !== undefined) {
      formData.append('suite', String(idSuite))
    }
    await this.post(`/projects/${idProject}/import_cases_delay/`, formData)
  }

  getCases = async (
    params: IGetCasesParams
  ): Promise<PaginatedQueryResult<ICase>> => {
    const res = await this.get<PaginatedQueryResult<ICase>>('/cases/', params, {
      paramsSerializer: (params) => {
        // @ts-expect-error
        return qs.stringify(params, { arrayFormat: 'repeat', skipNull: true })
      }
    })
    return res.data
  }

  approveCases = async (projectId: number): Promise<ICaseApproveAll> => {
    const res = await this.post<ICaseApproveAll>(
      `/projects/${projectId}/approve_cases/`,
      {}
    )
    return res.data
  }

  getMemberCases = async (
    params: IPaginatedQueryParams
  ): Promise<PaginatedQueryResult<ICase>> => {
    const res = await this.get<PaginatedQueryResult<ICase>>(
      '/cases/member_list/',
      params
    )
    return res.data
  }

  getCase = async (params: { id: number }): Promise<ICase> => {
    const id = params.id
    const res = await this.get<ICase>(`/cases/${id}/`)
    return res.data
  }

  patchCase = async (params: {
    id: number
    sort: number | string
  }): Promise<ICase> => {
    const { sort, id } = params
    const res = await this.patch<ICase>(`/cases/${id}/`, {
      data: { sort: sort }
    })
    return res.data
  }

  deleteCase = async (id: number): Promise<void> => {
    await this.delete<ICase>(`/cases/${id}/`)
  }

  approveCase = async (params: {
    id: number
    data?: IApproveCase
  }): Promise<ICase> => {
    const id = params.id

    const postData: QueryData<ICase> =
      params.data !== undefined ? params.data : {}

    const res = await this.post<ICase>(`/cases/${id}/approve/`, postData)
    return res.data
  }

  cloneCase = async (id: number): Promise<IClonedCase> => {
    const res = await this.post<IClonedCase>(`/cases/${id}/clone/`, {})
    return res.data
  }

  getNextCase = async (params: { id: number }): Promise<ICaseNext> => {
    const id = params.id
    const res = await this.get<ICaseNext>(`/cases/${id}/get_next/`)
    return res.data
  }

  exportCaseSuites = async (params: ISuiteExportItem[]): Promise<any> => {
    const res = await this.post<any>(
      '/cases/export_list/',
      { suites: params },
      {
        responseType: 'blob'
      }
    )
    return res
  }

  getTags = async (params): Promise<PaginatedQueryResult<ITag>> => {
    const res = await this.get<PaginatedQueryResult<ITag>>('/tags/', params)
    return res.data
  }

  createTag = async (params): Promise<ITag> => {
    const res = await this.post<ITag>('/tags/', params)
    return res.data
  }

  removeStep = async (id: number): Promise<any> => {
    const res = await this.delete<any>(`/steps/${id}/`)
    return res.data
  }

  removeAttachments = async (id: number): Promise<void> => {
    await this.delete(`/content/attachments/${id}/`)
  }

  getStepsInfo = async (params: {
    id: number
    model: 'case' | 'run' | 'milestone'
  }): Promise<IStatistics> => {
    const res = await this.get<IStatistics>('/runs/steps-info/', params)
    return res.data
  }

  getHistory = async (
    params
  ): Promise<PaginatedQueryResult<IHistoryElement>> => {
    const res = await this.get<PaginatedQueryResult<IHistoryElement>>(
      '/history/',
      params
    )
    return res.data
  }

  getProjectReport = async ({
    id,
    ...params
  }: IGetReports): Promise<IProjectReport> => {
    const res = await this.get<IProjectReport>(
      `/projects/${id}/report/`,
      params
    )
    return res.data
  }

  getReports = async ({
    id,
    ...params
  }: IGetReports): Promise<PaginatedQueryResult<IReportsList>> => {
    const res = await this.get<PaginatedQueryResult<IReportsList>>(
      `/projects/${id}/reports/`,
      params
    )
    return res.data
  }

  getRunCharts = async ({
    id,
    ...params
  }: IGetRunCharts): Promise<IRunChart> => {
    const res = await this.get<IRunChart>(`/runs/${id}/charts/`, params)
    return res.data
  }

  getRunReports = async ({
    id,
    ...params
  }: IGetRunCharts): Promise<IRunReport> => {
    const res = await this.get<IRunReport>(`/runs/${id}/reports/`, params)
    return res.data
  }

  getSuitesInRun = async (params: IGetSuitesRunParams): Promise<ISuite[]> => {
    const res = await this.get<any>(`/runs/${params.runId}/suites/`, {
      q: params.q,
      exclude: params.exclude
    })
    return res.data
  }

  createComment = async (
    id: number,
    params: { comment: string }
  ): Promise<{ comment: string }> => {
    const res = await this.post(`/step-run/${id}/comment/`, params)
    return res.data
  }

  getCommentsStepRun = async (params: {
    isTask?: boolean
    runId: number
    id: number
  }): Promise<PaginatedQueryResult<ICaseInRunComment>> => {
    if (params.isTask === true) {
      const res = await this.get<PaginatedQueryResult<ICaseInRunComment>>(
        `/runs/${params.runId}/tasks/${params.id}/comments/`
      )
      return res.data
    } else {
      const res = await this.get<PaginatedQueryResult<ICaseInRunComment>>(
        `/step-run/${params.id}/comments/`
      )
      return res.data
    }
  }

  getCommentsTask = async (params: {
    runId: number
    taskId: number
  }): Promise<PaginatedQueryResult<ICaseInRunComment>> => {
    const res = await this.get<PaginatedQueryResult<ICaseInRunComment>>(
      `/runs/${params.runId}/tasks/${params.taskId}/comments/`
    )
    return res.data
  }

  changeTaskStatus = async (
    runId: number,
    taskId: number,
    params: {
      status: TCaseStatusInRun
      comment?: string
    }
  ): Promise<void> => {
    await this.post(`/runs/${runId}/tasks/${taskId}/change_status/`, params)
  }

  createCommentTask = async (
    runId: number,
    taskId: number,
    params: { comment: string }
  ): Promise<{ comment: string }> => {
    const res = await this.post(
      `/runs/${runId}/tasks/${taskId}/comment/`,
      params
    )
    return res.data
  }

  getCliReports = async ({
    projectId,
    ...params
  }: IGetCliReports): Promise<ICliReports> => {
    const res = await this.get<ICliReports>(
      `/cli/reports/${projectId}/`,
      params
    )
    return res.data
  }

  getFaq = async (
    params: IGetFaqParams
  ): Promise<PaginatedQueryResult<IFaq>> => {
    const res = await this.get<PaginatedQueryResult<IFaq>>('/faq/', params)
    return res.data
  }

  getFaqCategorues = async (
    params: IPaginatedQueryParams
  ): Promise<PaginatedQueryResult<IFaqCategory>> => {
    const res = await this.get<PaginatedQueryResult<IFaqCategory>>(
      '/content/feedback/categories/',
      params
    )
    return res.data
  }

  createFeedback = async (params: ICreateFeedbackParams): Promise<any> => {
    const res = await this.post('/content/feedback/', params)
    return res.data
  }

  getSystemNotifies = async (
    params: IPaginatedQueryParams
  ): Promise<PaginatedQueryResult<INotifies>> => {
    const res = await this.get<PaginatedQueryResult<INotifies>>(
      '/garpix_notify/system_notifies/',
      params
    )
    return res.data
  }

  getImportNotifies = async (
    params: IGetImportNorifiesParams
  ): Promise<INotifies[]> => {
    const idProject = params.project
    const res = await this.get<INotifies[]>(
      `/projects/${idProject}/import_notifies/`
    )
    return res.data
  }

  cancelImportCases = async (projectId: number): Promise<any> => {
    await this.post(`/projects/${projectId}/import_cases_cancel/`, {})
  }

  readAllNotifies = async (): Promise<any> => {
    const res = await this.post('/garpix_notify/system_notifies/read_all/', {})
    return res.data
  }

  readNotifies = async (ids: number[]): Promise<any> => {
    const res = await this.post('/garpix_notify/system_notifies/read/', {
      ids
    })
    return res.data
  }

  deleteAllNotifies = async (): Promise<any> => {
    const res = await this.post(
      '/garpix_notify/system_notifies/delete_all/',
      {}
    )
    return res.data
  }

  getReportExcel = async (params: IGetReportExcel): Promise<any> => {
    const res = await this.get<any>(
      `/runs/${params.id}/generate_cases_excel/`,
      params,
      { responseType: 'blob' }
    )
    return res
  }

  getShareReports = async ({
    share_hash: shareHash,
    ...params
  }: IGetShareReportsParams): Promise<
  PaginatedQueryResult<IShareReportsList>
  > => {
    const res = await this.get<PaginatedQueryResult<IShareReportsList>>(
      `/share_projects/${shareHash}/reports/`,
      params
    )
    return res.data
  }

  getShareMilestones = async ({
    share_hash: shareHash,
    ...params
  }: IGetShareMilestonesParams): Promise<
  PaginatedQueryResult<IShareMilestonesList>
  > => {
    const res = await this.get<PaginatedQueryResult<IShareMilestonesList>>(
      `/share_projects/${shareHash}/milestones/`,
      params
    )
    return res.data
  }

  getShareReportPdf = async (params: IGetShareRunPdf): Promise<any> => {
    const res = await this.get<IGetShareRunPdf>(
      `/share_projects/${params.share_hash}/runs/${params.run_id}/generate_reports_pdf/`,
      params,
      {
        responseType: 'blob'
      }
    )
    return res
  }

  getShareRunReports = async (
    params: IGetShareRunCharts
  ): Promise<IShareRunReport> => {
    const res = await this.get<IShareRunReport>(
      `/share_projects/${params.share_hash}/runs/${params.run_id}/reports/`,
      params
    )
    return res.data
  }

  getShareCasesInRun = async (
    params: IGetShareCasesInRun
  ): Promise<PaginatedQueryResult<IShareCaseInRun>> => {
    const res = await this.get<PaginatedQueryResult<IShareCaseInRun>>(
      `/share_projects/${params.share_hash}/runs/${params.run_id}/cases/`,
      params
    )
    return res.data
  }

  getShareReportExcel = async (params: IGetShareReportExcel): Promise<any> => {
    const res = await this.get<any>(
      `/share_projects/${params.share_hash}/runs/${params.run_id}/generate_cases_excel/`,
      params,
      { responseType: 'blob' }
    )
    return res
  }

  getShareCliReports = async ({
    hash,
    ...params
  }: IGetShareCliReports): Promise<IShareCliReports> => {
    const res = await this.get<IShareCliReports>(`/cli/share/${hash}/`, params)
    return res.data
  }

  getCompany = async ({ id }: { id: number | string }): Promise<ICompany> => {
    const res = await this.get<ICompany>(`/company/${id}/`)
    return res.data
  }

  getCompanies = async (
    params: IGetCompanies
  ): Promise<PaginatedQueryResult<ICompanyList>> => {
    const res = await this.get<PaginatedQueryResult<ICompanyList>>(
      '/company/',
      params
    )
    return res.data
  }

  restoreCompany = async (params: { idCompany: number }): Promise<void> => {
    await this.post(`/company/${params.idCompany}/restore/`, {})
  }

  sendInviteCompany = async (
    params: IPostCompanyInvites,
    idCompany: number
  ): Promise<void> => {
    await this.post<any>(`/company/${idCompany}/invite/`, params)
  }

  acceptInvite = async (params: { idInvite: number }): Promise<void> => {
    await this.post<any>(`/company_invite/${params.idInvite}/accept/`, {})
  }

  declineInvite = async (params: { idInvite: number }): Promise<void> => {
    await this.post<any>(`/company_invite/${params.idInvite}/decline/`, {})
  }

  getCompanyUsers = async (
    params: IGetCompanyUsers
  ): Promise<PaginatedQueryResult<ICompanyUser>> => {
    const res = await this.get<PaginatedQueryResult<ICompanyUser>>(
      `/company/${params.company_pk}/user/`,
      params
    )
    return res.data
  }

  getCompanyUsersNotInProject = async (
    params: IGetCompanyUsersNotInProject
  ): Promise<PaginatedQueryResult<ICompanyUser>> => {
    const res = await this.get<PaginatedQueryResult<ICompanyUser>>(
      `/company/${params.company_id}/user/${params.project_id}/not_in_project/`,
      params
    )
    return res.data
  }

  getCompanyRoles = async (
    params: IPaginatedQueryParams
  ): Promise<PaginatedQueryResult<IRole>> => {
    const res = await this.get<any>('/company/roles_list/', params)
    return res.data
  }

  getFormdataCompany = (params, image): FormData => {
    const dataJson = JSON.stringify(params)
    const fd = new FormData()
    fd.append('data', dataJson)
    if (image !== undefined && !isString(image)) {
      fd.append('image', image)
    }
    return fd
  }

  createCompany = async (params): Promise<ICompany> => {
    const copyParams = { ...params }
    const image = copyParams.image?.[0]
    delete copyParams.image
    const data = copyParams

    const fd = this.getFormdataCompany(data, image)
    const res = await this.post<ICompany>('/company/', fd, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    return res.data
  }

  updateCompany = async (id: number | string, params): Promise<ICompany> => {
    const copyParams = { ...params }
    const image = copyParams.image?.[0]
    delete copyParams.image
    const data = copyParams

    const fd = this.getFormdataCompany(data, image)
    const res = await this.patch<ICompany>(`/company/${id}/`, fd, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    return res.data
  }

  leaveCompany = async (id: number): Promise<void> => {
    await this.post<any>(`/company/${id}/leave_company/`, {})
  }

  deleteCompany = async (id: number): Promise<void> => {
    await this.delete<any>(`/company/${id}/`)
  }

  deleteCompanyUser = async (params: {
    idCompany: number
    idUser: number
  }): Promise<void> => {
    await this.delete<any>(
      `/company/${params.idCompany}/user/${params.idUser}/`
    )
  }

  changeOwner = async (
    idCompany: number,
    params: IPostChangeOwner
  ): Promise<INewOwner> => {
    const res = await this.post<INewOwner>(
      `/company/${idCompany}/change_owner/`,
      params
    )
    return res.data
  }

  changeRole = async (
    companyId: number,
    userId: number,
    params: IPostChangeRole
  ): Promise<INewOwner> => {
    const res = await this.post<INewOwner>(
      `/company/${companyId}/user/${userId}/change_role/`,
      params
    )
    return res.data
  }

  turnNotifications = async (
    companyId: number,
    params: IPostTurnNotifications
  ): Promise<INewOwner> => {
    const res = await this.post<INewOwner>(
      `/company/${companyId}/turn_notifications/`,
      params
    )
    return res.data
  }

  getCompanyInvites = async (
    params: IGetCompanyInvites
  ): Promise<PaginatedQueryResult<ICompanyInvite>> => {
    const res = await this.get<PaginatedQueryResult<ICompanyInvite>>(
      `/company/${params.id}/invites/`,
      params
    )
    return res.data
  }

  generateOpenaiCase = async (params): Promise<any> => {
    const res = await this.post<any>('/cases/generate_openai_case/', params)
    return res.data
  }

  getFile = async ({ fullUrl }): Promise<string> => {
    const url = new URL(fullUrl.replace('/api', ''))

    const res = await this.get<any>(
      url.pathname,
      {},
      {
        responseType: 'blob'
      }
    )
    return URL.createObjectURL(res.data)
  }

  /**
   * get the complete list of roles containing all permissons for each role
   * query closed for permissons
   * @param projectId
   * @param params
   * @returns Promise<PaginatedQueryResult<IProjectRole>>
   */

  getProjectRoles = async (
    projectId: number,
    params
  ): Promise<PaginatedQueryResult<IProjectRole>> => {
    const res = await this.get<PaginatedQueryResult<IProjectRole>>(
      `/projects/${projectId}/user_project_roles/`,
      params
    )
    return res.data
  }

  /**
   * get the short (only id: number, title: string) list of roles containing all permissons for each role
   * query opened for all permitions
   * @param projectId
   * @param params
   * @returns Promise<PaginatedQueryResult<IProjectRoleShort>>
   */
  getProjectRolesShort = async (
    projectId: number,
    params
  ): Promise<PaginatedQueryResult<IProjectRoleShort>> => {
    const res = await this.get<PaginatedQueryResult<IProjectRoleShort>>(
      `/projects/${projectId}/user_project_roles/short_list/`,
      params
    )
    return res.data
  }

  changeProjectMemberRole = async (
    projectId: number,
    userId: number,
    params: IPatchProjectMemberRole
  ): Promise<ITempProjectMember> => {
    const res = await this.post<ITempProjectMember>(
      `/projects/${projectId}/members/${userId}/change_role/`,
      params
    )
    return res.data
  }

  getProjectRolesPermissions = async (
    projectId: number
  ): Promise<TProjectRolesPermissions> => {
    const res = await this.get<TProjectRolesPermissions>(
      `/projects/${projectId}/user_project_roles/allowed_permissions/`
    )
    return res.data
  }

  changeProjectRolesPermission = async (
    projectId: number,
    roleId: number,
    params
  ): Promise<ITempProjectMember> => {
    const res = await this.patch<ITempProjectMember>(
      `/projects/${projectId}/user_project_roles/${roleId}/`,
      params
    )
    return res.data
  }

  createProjectRole = async (
    projectId: number,
    params
  ): Promise<IProjectRole> => {
    const res = await this.post<IProjectRole>(
      `/projects/${projectId}/user_project_roles/`,
      params
    )
    return res.data
  }

  deleteProjectRole = async (
    projectId: number,
    roleId: number,
    params
  ): Promise<any> => {
    const res = await this.delete(
      `/projects/${projectId}/user_project_roles/${roleId}/`,
      params
    )
    return res.data
  }

  resetProjectRole = async (
    projectId: number
  ): Promise<PaginatedQueryResult<IProjectRole>> => {
    const res = await this.post<PaginatedQueryResult<IProjectRole>>(
      `/projects/${projectId}/user_project_roles/reset_roles/`,
      {}
    )
    return res.data
  }

  downloadCaseInRunFiles = async (
    runId: number,
    caseId: number
  ): Promise<ICaseInRunAllFiles> => {
    const res = await this.get<ICaseInRunAllFiles>(
      `/runs/${runId}/cases/${caseId}/download_files/`,
      {}
    )
    return res.data
  }
}

export { Api }
