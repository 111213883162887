import React from 'react'
import style from './styles/loading.module.scss'
import { Shimmer, ShimmerWrap } from '@/components/Loading'

interface IProps {
  countShimmers: number
  className?: string
}

const LoaderLayout = ({
  countShimmers,
  className = ''
}: IProps): React.ReactElement => {
  const shimmers = Array.from({ length: countShimmers }, (_, index) => (
    <Shimmer key={index} />
  ))

  const shimmersTable = Array.from(
    { length: countShimmers * 2 },
    (_, index) => <Shimmer key={index} size='small' />
  )

  return (
    <div className={style.content}>
      <ShimmerWrap className={className}>
        <div className={style.wrap}>
          <Shimmer size='xl' form='circle' />

          <Shimmer size='xl' />
        </div>

        <div className={style.wrap}>
          <div className={style.loading}>{shimmers}</div>
        </div>

        <div className={style.table}>{shimmersTable}</div>
      </ShimmerWrap>
    </div>
  )
}

export default LoaderLayout
