import React from 'react'
import style from './styles/toggle.module.scss'
import classNames from 'classnames'

interface IToggleBtnProps {
  toggled: boolean
  handleChange: () => void
  label?: string | null
  textColor?: 'red' | 'base'
  disabled?: boolean
  type?: 'submit' | 'checkbox'
}

const SwitchButton = (
  {
    toggled,
    handleChange,
    label,
    textColor,
    disabled,
    type = 'checkbox'
  }: IToggleBtnProps): JSX.Element => {
  return (
    <div className={style.toggle__wrapper}>
      <span className={classNames(style.label, { [style.error]: textColor === 'red' })}>
        {label}
      </span>
      <label className={classNames(style.toggle__btn, { [style.disabled]: disabled })}>
        <input
          className={style.input}
          type={type}
          checked={toggled}
          onChange={handleChange}
          disabled={disabled}
        />
        <span className={style.span} />
      </label>
    </div>
  )
}

export default SwitchButton
