import React from 'react'
import { Layout } from '.'
import Header from './Header/Header'
import style from '../styles/layout.module.scss'
import useCurrentUser from '@/hooks/useCurrentUser'
import { observer } from 'mobx-react'
import LoaderLayout from './loaders/LoaderLayout'

interface LayoutProps {
  children: React.ReactNode
}

const UserLayout = observer(({ children }: LayoutProps): React.ReactElement => {
  const { status } = useCurrentUser()

  return (
    <Layout>
      <Header />
      <main className={style.common__main}>
        {status === 'loading' ? <LoaderLayout countShimmers={6} /> : null}
        {status === 'notLogin' ? 'Please, login' : null}
        {status === 'isLogin' ? children : null}
      </main>
    </Layout>
  )
})

export default UserLayout
