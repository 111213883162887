import React from 'react'
import classNames from 'classnames'
import style from './styles/tooltip.module.scss'

interface ITooltipProps {
  jsxContent: JSX.Element
  open: boolean
  placement: 'top' | 'bottom' | 'left' | 'right'
  children: JSX.Element
}

const TooltipWithJsxContent = ({
  jsxContent,
  open,
  placement,
  children
}: ITooltipProps): JSX.Element => {
  const tooltipStyles = classNames(style.custom_tooltip, {
    [style['custom_tooltip--visible']]: open
  })

  return (
    <div className={style.custom_tooltip__container}>
      {children}
      {open && (
        <div
          className={classNames(
            style.custom_tooltip,
            [style[`custom_tooltip--${placement}`]],
            tooltipStyles
          )}
        >
          {jsxContent}
        </div>
      )}
    </div>
  )
}

export default TooltipWithJsxContent
