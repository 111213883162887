import React from 'react'
import ProjectLayout from '../layout/components/ProjectLayout'
import UserLayout from '../layout/components/UserLayout'
import Runs from '../Runs/Runs'

const ProjectRuns = (): React.ReactElement => {
  return (
    <UserLayout>
      <ProjectLayout>
        <Runs />
      </ProjectLayout>
    </UserLayout>
  )
}

export default ProjectRuns
