import { useStores } from '@/hooks'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import style from './styles/modal.module.scss'
import Icon from '@/components/Icon/Icon'
import Button from '@/components/Button/Button'
import { useForm } from 'react-hook-form'
import { reactHookFormErrorFormater } from '@/utils'
import CompanyMemberShort from '@/components/Member/CompanyMemberShort'
import { Form } from '@/components/Form'

export interface IFormInputs {
  non_field_errors?: string
  idUser: number
  idCompany: number
}

interface IProps{
  handleClose: () => void
}

const ViewRemoveMemberCompany = ({ handleClose }: IProps): React.ReactElement | null => {
  const { api, modalStore } = useStores()
  const idUser = modalStore.dataModal.memberCompanyId
  const idCompany = modalStore.dataModal.idCompany
  const avatar = modalStore.dataModal.avatar
  const name = modalStore.dataModal.name
  const initialsUser = modalStore.dataModal.initialsUser

  const {
    setError,
    handleSubmit,
    formState: { isSubmitting, errors }
  } = useForm<IFormInputs>({
    defaultValues: {
      idUser,
      idCompany
    }
  })

  const onSubmit = async (params): Promise<void> => {
    try {
      await api.deleteCompanyUser(params)
      handleClose()
    } catch (error) {
      const errors = reactHookFormErrorFormater(params, error)
      errors.forEach((item) => {
        setError(item.key as keyof IFormInputs, {
          type: '400',
          message: item.value
        })
      })
    }
  }

  const nonFieldErrors = errors?.non_field_errors?.message ?? ''

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className={style.deleteuser__top}>
        <Icon src='delete' size='xxxl' className={style.deleteuser__grayicon} />

        <p className={style.deleteuser__text}>
          <FormattedMessage
            id='company.members.delete.title'
            defaultMessage='Do you really want to delete the user from company?'
          />
        </p>

        <CompanyMemberShort name={name} avatar={avatar} initials={initialsUser} />

        {nonFieldErrors !== ''
          ? (
            <div className={style.deleteuser__error}>{nonFieldErrors}</div>
            )
          : null}
      </div>

      <div className={style.deleteuser__bottom}>
        <Button onClick={handleClose} theme='light'>
          <Icon src='clear' slot='icon-left' />

          <FormattedMessage id='common.cancel' defaultMessage='Cancel' />
        </Button>

        <Button disabled={isSubmitting} type='submit'>
          <Icon src='checked' slot='icon-left' />

          <FormattedMessage
            id='company.members.delete.confirm'
            defaultMessage='Delete'
          />
        </Button>
      </div>
    </Form>
  )
}

export default ViewRemoveMemberCompany
