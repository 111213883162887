import { useObjectPage, usePage, useStores } from '@/hooks'
import React, { useEffect, useMemo, useState } from 'react'
import Description from './components/Description'
import PieChartBlock from './components/PieChartBlock'
import useSWR from 'swr'
import Wrapper from '@/components/Wrapper'
import History from './components/History'
import { ICaseInRunPage } from '@/interfaces/ObjectPage'
import Container from '@/components/Container'
import {
  STEP_STATUSES_BACKEND,
  EDIT_PERMISSION_LEVEL,
  USER_PERMISSIONS,
  CASE_IN_RUN_STATUS
} from '@/const'
import useModal from '@/hooks/useModal'
import BaseModal from '@/components/Modal/Base'
import ModalFileUploadingError from '@/components/Modal/ModalFileUploadingError'
import { MAX_SIZE } from '../MilestoneDetail/const'
import { SuitesCasesDetail } from '@/components/UpdateCases'
import { useCurrentPermissions } from '@/hooks/useCurrentPermissions'
import {
  LoaderRunDescription,
  LoaderRunDiagram,
  LoaderRunFiles
} from './loaders'
import UploadContainer from '@/components/UploadZone/UploadContainer/UploadContainer'
import style from './styles/run_detail.module.scss'

const RunDetail = (): JSX.Element => {
  const [largeFileNames, setLargeFileNames] = useState<string[]>([])
  const [isUploadFile, setIsUploadFile] = useState(false)

  const { edit_url: editUrl, id: runId } = useObjectPage<ICaseInRunPage>()
  const page = usePage()
  const { api, runDetailStore } = useStores()

  const { isOpen, handleOpenModal, handleCloseModal } = useModal()

  const { data: run, mutate: mutateRun } = useSWR(
    { id: runId, _key: 'getRun' },
    api.getRun,
    {
      onSuccess: (data) => {
        runDetailStore.setRun(data)
      },
      onError: () => {
        runDetailStore.setRun(undefined)
      }
    }
  )

  const canUserEditRun =
    useCurrentPermissions(USER_PERMISSIONS.run, EDIT_PERMISSION_LEVEL) &&
    run?.statistics.status !== CASE_IN_RUN_STATUS.completed
  const canUserDeleteImage = useCurrentPermissions(
    USER_PERMISSIONS.run,
    EDIT_PERMISSION_LEVEL
  )

  const idProject = page.init_state.project_object.id

  const { data: dataCases, mutate: mutateCases } = useSWR(
    {
      id: runId,
      _key: 'getCasesInRun'
    },
    api.getCasesInRun
  )

  const onChange = async (files: File[]): Promise<void> => {
    const largeFiles = files.filter((file) => file.size > MAX_SIZE)
    const largeFilesNames: string[] = largeFiles.map((obj) => obj.name)
    const filesToUpload = files.filter((file) => file.size <= MAX_SIZE)

    if (largeFiles.length > 0) {
      setLargeFileNames(largeFilesNames)
      handleOpenModal()
    }

    if (filesToUpload.length > 0) {
      setIsUploadFile(true)
      await api.updateRun(runId, { files: filesToUpload })
      setIsUploadFile(false)
      await mutateRun()
    }
  }

  const initFiles = run?.files ?? []

  const handleUpdate = async (): Promise<void> => {
    await mutateRun()
    await mutateCases()
  }

  const isCasesCompleted = useMemo(() => {
    return dataCases?.results.every(
      (el) => el.status !== STEP_STATUSES_BACKEND.untested
    )
  }, [dataCases?.results])

  useEffect(() => {
    return runDetailStore.setRun(undefined)
  }, [])

  return (
    <Container>
      {run !== undefined
        ? (
          <Description
            edit={editUrl}
            idProject={idProject}
            idRun={runId}
            run={run ?? {}}
            isCasesCompleted={isCasesCompleted ?? false}
            handleUpdate={handleUpdate}
          />
          )
        : (
          <Wrapper className={style.container}>
            <LoaderRunDescription />
          </Wrapper>
          )}

      <div className={style.container}>
        {run !== undefined
          ? (
            <PieChartBlock run={run} />
            )
          : (
            <Wrapper className={style.chart}>
              <LoaderRunDiagram countShimmers={6} />
            </Wrapper>
            )}

        {run !== undefined
          ? (
            <UploadContainer
              initFiles={initFiles}
              onChange={onChange}
              isScrollableList
              enableReinitialize
              onDelete={handleUpdate}
              isUpload={isUploadFile}
              className={style.files}
              canUserDeleteFile={canUserDeleteImage}
              type='api'
              readonly={!canUserEditRun}
            />
            )
          : (
            <Wrapper className={style.chart}>
              <LoaderRunFiles />
            </Wrapper>
            )}
      </div>

      <SuitesCasesDetail runStatus={run?.statistics.status} handleUpdate={handleUpdate} />

      <History id={runId} />

      <BaseModal
        size='medium'
        open={isOpen}
        onGx-after-hide={handleCloseModal}
        hideDefaultClose
      >
        <ModalFileUploadingError
          largeFileNames={largeFileNames}
          onCancel={handleCloseModal}
        />
      </BaseModal>
    </Container>
  )
}

export default RunDetail
