import Container from '@/components/Container'
import { Form } from '@/components/Form'
import { Footer, Head } from '@/components/Forms'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import Files from './components/Files'
import Steps from './components/Steps'
import CaseForm from './components/CaseForm'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useObjectPage, useStores } from '@/hooks'
import { useNavigate } from 'react-router-dom'
import { reactHookFormErrorFormater } from '@/utils'
import { CASE_TYPES } from '@/const'
import BaseModal from '@/components/Modal/Base'
import useModal from '@/hooks/useModal'
import DeleteCase from './components/DeleteCase'
import useSidebar from '@/hooks/useSidebar'
import { observer } from 'mobx-react'
import { suiteService } from '../SuitesCases/service'
import { IAISuccessProps, IFormInputs } from './interfaces'

interface IProps {
  id?: number
}

const AddOrEditCase = observer(({ id }: IProps): React.ReactElement => {
  const [isLoading, setIsLoading] = useState(false)

  const intl = useIntl()
  const { api } = useStores()
  const navigate = useNavigate()
  const objectPage = useObjectPage()
  const { isOpen, handleOpenModal, handleCloseModal } = useModal()

  const { ProjectPageCasesAndSuites } = useSidebar()

  const projectId = objectPage.id

  const getDefaultValues = async (): Promise<IFormInputs> => {
    if (id === undefined) {
      const currentSuite =
        suiteService.currentSuite.id !== undefined &&
        suiteService.currentSuite.id !== null
          ? {
              label: suiteService.currentSuite.nodeTitle,
              value: suiteService.currentSuite.id
            }
          : null
      return {
        title: '',
        priority: 'MEDIUM',
        code: '',
        tags: [],
        project: {
          id: projectId,
          title: objectPage.title
        },
        preconditions: '',
        steps: [],
        files: [],
        suite: currentSuite,
        initFiles: [],
        case_type: objectPage.priority_case_type
      }
    } else {
      const obj = await api.getCase({ id: id })
      const suite =
        obj.suite_name !== null
          ? { value: obj.suite_name.id, label: obj.suite_name.title }
          : null
      return {
        ...obj,
        files: [],
        suite: suite,
        initFiles: obj.files ?? []
      }
    }
  }

  const {
    setValue,
    register,
    setError,
    control,
    watch,
    handleSubmit,
    formState: { isSubmitting, errors }
  } = useForm<IFormInputs>({
    defaultValues: getDefaultValues
  })

  const onSubmit = async (params): Promise<void> => {
    try {
      if (id === undefined) {
        await api.createCase(params)
        navigate(ProjectPageCasesAndSuites.absolute_url)
      } else {
        const res = await api.updateCase(id, params)
        navigate(res.absolute_url)
      }
    } catch (error) {
      const errors = reactHookFormErrorFormater(params, error, 'data')
      errors.forEach((item) => {
        setError(item.key as keyof IFormInputs, {
          message: item.value
        })
      })
    }
  }

  const isUpdate = id !== undefined

  const createButtonText = intl.formatMessage({
    id: 'case.add_case',
    defaultMessage: 'Add Test Case'
  })

  const updateButtonText = intl.formatMessage({
    id: 'case.update_case',
    defaultMessage: 'Update Test Case'
  })

  const buttonText = isUpdate ? updateButtonText : createButtonText

  const { append, remove, move, update } = useFieldArray({
    control,
    name: 'steps',
    keyName: '_id'
  })

  const initFiles = watch('initFiles')
  const files = watch('files')
  const project = watch('project')
  const caseType = watch('case_type')
  const steps = watch('steps')

  const isCase: boolean = caseType === CASE_TYPES.case
  const isChecklist: boolean = caseType === CASE_TYPES.checklist

  const nonFieldErrors = errors?.non_field_errors?.message ?? ''

  const handleOpenModalDelete = (): void => {
    handleOpenModal()
  }

  const handleDelete = (): void => {
    if (id === undefined) {
      return
    }

    setIsLoading(true)

    api
      .deleteCase(id)
      .then(() => {
        handleCloseModal()
        navigate(ProjectPageCasesAndSuites.absolute_url)
      })
      .catch((err) => console.log('err', err))
      .finally(() => setIsLoading(false))
  }

  const handleAISuccess = async ({
    title,
    preconditions,
    tags,
    steps
  }: IAISuccessProps): Promise<void> => {
    setValue('title', title)
    setValue('preconditions', preconditions)
    setValue('tags', tags)
    setValue('steps', steps)
  }

  return (
    <Container>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Head
          title={createButtonText}
          isSubmitting={isSubmitting}
          errors={nonFieldErrors}
          buttonText={buttonText}
          pastHref={ProjectPageCasesAndSuites.absolute_url}
          isDeletable={isUpdate}
          onDelete={handleOpenModalDelete}
        />
        {project != null
          ? (
            <CaseForm
              errors={errors}
              register={register}
              control={control}
              project={project}
              isUpdate={isUpdate}
              handleAISuccess={handleAISuccess}
              caseType={caseType}
            />
            )
          : null}
        {initFiles !== undefined
          ? (
            <Controller
              control={control}
              name='files'
              render={({ field: { onChange } }) => (
                <Files
                  initFiles={initFiles}
                  files={files}
                  onChange={onChange}
                  defaultOpened={false}
                />
              )}
            />
            )
          : null}

        {isCase || isChecklist
          ? (
            <Steps
              append={append}
              remove={remove}
              register={register}
              fields={steps}
              type={caseType}
              move={move}
              update={update}
            />
            )
          : null}
        <Footer
          isSubmitting={isSubmitting}
          errors={nonFieldErrors}
          buttonText={buttonText}
        />
      </Form>

      {isUpdate
        ? (
          <BaseModal
            open={isOpen}
            onGx-after-hide={handleCloseModal}
            onGx-overlay-dismiss={handleCloseModal}
            hideDefaultClose
            size='medium'
          >
            <DeleteCase
              onCancel={handleCloseModal}
              onDelete={handleDelete}
              disableSubmit={isLoading}
            />
          </BaseModal>
          )
        : null}
    </Container>
  )
})

export default AddOrEditCase
