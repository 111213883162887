import React from 'react'
import { FormattedMessage } from 'react-intl'
import Button from '../Button/Button'
import Icon from '../Icon/Icon'
import style from './styles/modal.module.scss'

interface IProps {
  onCancel: () => void
  onDelete: () => void
  children: React.ReactNode
  disableSubmit?: boolean
  icon?: 'delete' | 'broom'
  confirmText?: string
}

const ModalDelete = ({
  onCancel,
  onDelete,
  children,
  disableSubmit = false,
  icon = 'delete',
  confirmText
}: IProps): React.ReactElement => {
  const confirmButtonText = confirmText ?? (
    <FormattedMessage id='common.delete' defaultMessage='Delete' />
  )

  return (
    <div className={style.delete}>
      <Icon src={icon} size='xxxl' className={style.delete__icon} />

      {children}

      <div className={style.delete__footer} slot='footer'>
        <Button theme='light' onClick={onCancel}>
          <Icon src='clear' slot='icon-left' />
          <FormattedMessage id='common.cancel' defaultMessage='Cancel' />
        </Button>

        <Button onClick={onDelete} disabled={disableSubmit}>
          <Icon src='checked' slot='icon-left' />
          {confirmButtonText}
        </Button>
      </div>
    </div>
  )
}

export default ModalDelete
