import AuthRegLayout from '@/apps/layout/components/AuthRegLayout'
import Login from '@/apps/Login/Login'
import YandexMetrika from '@/components/YandexMetrika'
import { loginImage } from '@/images'
import React from 'react'

const LoginPage = (): React.ReactElement => {
  return (
    <>
      <YandexMetrika />
      <AuthRegLayout image={loginImage}>
        <Login />
      </AuthRegLayout>
    </>
  )
}

export default LoginPage
