import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Controller, useForm } from 'react-hook-form'
import Input from '@/components/Input/Input'
import Icon from '@/components/Icon/Icon'
import Button from '@/components/Button/Button'
import { useStores } from '@/hooks'
import { reactHookFormErrorFormater } from '@/utils'
import { Form } from '@/components/Form'
import Title from '@/components/Title'
import TextButton from '@/components/Button/TextButton'
import Timer from '@/components/Timer'
import { VERIFY_TYPES } from '@/const'
import ErrorsBlock from '@/components/ErrorsBlock'
import style from '../styles/registration.module.scss'

interface IProps {
  nextStage: () => void
  prevStage: () => void
  setCode: (value) => void
  code: string
  email: string
}

export interface IFormInputs {
  username?: string
  restore_password_confirm_code?: string
  non_field_errors?: string
}

const SendCode = ({
  nextStage,
  prevStage,
  setCode,
  email,
  code
}: IProps): React.ReactElement => {
  const intl = useIntl()
  const { api } = useStores()

  const [seconds, setSeconds] = React.useState(60)

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting }
  } = useForm<IFormInputs>({
    defaultValues: {
      username: email,
      restore_password_confirm_code: code
    }
  })

  const handleResendCode = async (): Promise<void> => {
    setSeconds(60)
    try {
      await api.sendCodeRestorePassword({ username: email })
    } catch (error) {
      if (error.response.data === undefined) {
        setError('non_field_errors', {
          type: '400',
          message: intl.formatMessage({
            id: 'error.server',
            defaultMessage: 'Server error'
          })
        })
      }
    }
  }

  const onSubmit = async (params): Promise<void> => {
    try {
      const res = await api.checkCodeRestorePassword({
        restore_password_confirm_code: params.restore_password_confirm_code,
        username: email
      })
      nextStage()
      return res
    } catch (error) {
      const errors = reactHookFormErrorFormater(params, error)
      errors.forEach((item) => {
        setError(item.key as keyof IFormInputs, {
          type: '400',
          message: item.value
        })
      })
    }
  }
  const nonFieldErrors = errors?.non_field_errors?.message ?? ''

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Button
        theme='light'
        className={style.form__back}
        circle
        onClick={prevStage}
      >
        <Icon src='back' />
      </Button>
      <Title type='h1'>
        <FormattedMessage
          id='profile.forgot_pass.verify'
          defaultMessage='Verify your identity'
        />
      </Title>
      <div className={style.form}>
        <p>
          <FormattedMessage
            id='profile.forgot_pass.text'
            defaultMessage='We just sent you a confirmation code over to {email}.'
            values={{ email }}
          />
        </p>

        <Controller
          control={control}
          name='restore_password_confirm_code'
          render={({ field: { onChange, value } }) => {
            return (
              <Input
                type='text'
                className={style.form__input}
                label={intl.formatMessage({
                  id: 'profile.forgot_pass.code',
                  defaultMessage: 'EnterCode'
                })}
                required
                value={value}
                onChange={(e) => {
                  setCode(e.target.value)
                  onChange(e.target.value)
                }}
                error={errors?.restore_password_confirm_code?.message ?? ''}
              />
            )
          }}
        />

        <div className={style.form__bottom}>
          {seconds === 0
            ? (
              <TextButton onClick={handleResendCode}>
                <FormattedMessage
                  id='profile.resend.code'
                  defaultMessage='Resend code'
                />
              </TextButton>
              )
            : null}

          <Timer
            type={VERIFY_TYPES.Code}
            setSeconds={setSeconds}
            seconds={seconds}
          />

          <Button type='submit' disabled={isSubmitting} size='lg'>
            <Icon src='arrow_right' slot='icon-left' />
            <FormattedMessage id='common.save' defaultMessage='Save' />
          </Button>
        </div>

        {nonFieldErrors !== ''
          ? (
            <ErrorsBlock
              errorsArray={[nonFieldErrors]}
            />
            )
          : null}
      </div>
    </Form>
  )
}

export default SendCode
