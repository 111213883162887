import Container from '@/components/Container'
import { useStores } from '@/hooks'
import React, { useEffect, useState } from 'react'
import EmptyDashboard from './components/EmptyDashboard'
import LastCases from './components/LastCases'
import Projects from './components/Projects'
import Runs from './components/Runs'
import style from './styles/dashboard.module.scss'
import SentryTestButton from './components/SentryTestButton'
import classNames from 'classnames'
import { sidebarService } from './service'
import Sidebar from './components/Sidebar'
import { observer } from 'mobx-react'
import CompanyInfo from './components/CompanyInfo'
import { FormattedMessage } from 'react-intl'
import Title from '@/components/Title'
import RecentsHead from './components/RecentsHead'
import EmptyProjects from './components/EmptyProjects'
import useGlobalConfig from '@/hooks/useGlobalConfig'

const Dashboard = observer((): React.ReactElement => {
  const globalConfig = useGlobalConfig()
  const isUserInCompany = globalConfig.current_user_companies_count > 0
  const { api, companyStore } = useStores()
  const currentCompany = companyStore.getCompany()

  const [hasProjects, setHasProjects] = useState(false)

  useEffect(() => {
    void api.getProjects({ page: 1, page_size: 1 }).then((res) => {
      if (res.count > 0) setHasProjects(true)
    })
  }, [])

  return (
    <>
      <Sidebar />

      <div
        className={classNames({
          [style.content]: true,
          [style['content--opened']]: sidebarService.open
        })}
      >
        <Container>
          <Title type='h1' hidden>
            <FormattedMessage
              id='titles.dashboard'
              defaultMessage='Dashboard'
            />
          </Title>

          <SentryTestButton />

          {currentCompany !== undefined ? <CompanyInfo /> : <RecentsHead />}

          {!isUserInCompany ? <EmptyDashboard /> : null}

          {hasProjects && isUserInCompany
            ? (
              <>
                <div className={style.row}>
                  <Runs />
                  <LastCases />
                </div>

                <Projects />
              </>
              )
            : null}

          {!hasProjects && isUserInCompany ? <EmptyProjects /> : null}
        </Container>
      </div>
    </>
  )
})

export default Dashboard
