import Dropdown from '@/components/Dropdown/Dropdown'
import DropdownMenu from '@/components/Dropdown/DropdownMenu'
import Icon from '@/components/Icon/Icon'
import Link from '@/components/Link'
import { usePage } from '@/hooks'
import { ICurrentUser } from '@/interfaces/User'
import { useUser } from '@garpix/cms'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import style from './styles/user_dropdown.module.scss'
import { getInitials, getNameUser } from '@/components/UserName'
import { AvatarUser } from '@/components/Avatar'
import { LanguageSwitch } from '@/components/Switch'

interface IProps {
  user: ICurrentUser
}

const UserDropdown = ({ user }: IProps): React.ReactElement => {
  const { handleLogoutRedirect } = useUser()

  const handleLogout = async (): Promise<void> => {
    await handleLogoutRedirect()
  }
  const page = usePage()
  const initialsUser = getInitials(
    user.first_name ?? '',
    user.last_name ?? '',
    user.email
  )
  const name = getNameUser(user.first_name, user.last_name)

  return (
    <Dropdown placement='top-end' skidding={5} className={style.dropdown}>
      <button type='button' slot='trigger' className={style.dropdown__button}>
        <AvatarUser
          src={user.avatar ?? null}
          size='medium'
          initials={initialsUser}
        />
        <Icon src='arrow_down' className={style.dropdown__arrow} />
      </button>

      <DropdownMenu className={style.menu}>
        <span className={style.menu__name}>{name}</span>
        {/* <Link to='/' className={style.menu__link}>
          <Icon src='user' />
          <span>Profile</span>
        </Link> */}
        <LanguageSwitch theme='light' className={style.menu__switch} />

        <Link
          to={page.init_state.global.profile_url}
          className={style.menu__link}
        >
          <Icon src='user' />

          <span>
            <FormattedMessage id='header.profile' defaultMessage='Profile' />
          </span>
        </Link>

        <Link to={page.init_state.global.faq_url} className={style.menu__link}>
          <Icon src='support' />

          <span>
            <FormattedMessage id='header.support' defaultMessage='Support' />
          </span>
        </Link>

        <button
          type='button'
          className={style.menu__logout}
          onClick={handleLogout}
        >
          <Icon src='logout' />

          <span>
            <FormattedMessage id='header.logout' defaultMessage='Log out' />
          </span>
        </button>
      </DropdownMenu>
    </Dropdown>
  )
}

export default UserDropdown
