import React, { forwardRef } from 'react'
import BaseAsyncPaginate from '@/components/Select/AsyncSelect/BaseAsyncPaginate'
import {
  BaseAsyncSelectProps,
  ILoadOptions,
  IValue,
  TAdditional
} from '@/components/Select/interfaces/AsyncSelect'
import { useObjectPage, useStores } from '@/hooks'

interface IProps extends BaseAsyncSelectProps {
  handleChange: (e: IValue) => void
  label: string
  placeholder: string
  className?: string
}

const defaultAdditional: TAdditional = {
  page: 1
}

const NameOfBranchField = ({ ...props }: IProps, ref): JSX.Element => {
  const { id: idProject } = useObjectPage()
  const { api } = useStores()

  const loadPageOptions = async (
    q: string,
    _: unknown,
    { page }: TAdditional
  ): Promise<ILoadOptions> => {
    const { options, hasMore } = await loadOptions(q, page)

    return {
      options,
      hasMore,
      additional: {
        page: page + 1
      }
    }
  }

  const loadOptions = async (search: string, page: number): Promise<any> => {
    let hasMore = false

    const options: IValue[] = await api
      .getGitlabBranches({
        page: page,
        idProject: idProject
      })
      .then((paginatedObject) => {
        hasMore = paginatedObject.length > 0

        return paginatedObject.map((el, index) => {
          return { value: index, label: el }
        })
      })

    return {
      options,
      hasMore,
      additional: {
        page: page + 1
      }
    }
  }

  return (
    <BaseAsyncPaginate
      {...props}
      loadOptions={loadPageOptions}
      additional={defaultAdditional}
      isSearchable={false}
      ref={ref}
    />
  )
}

export default forwardRef(NameOfBranchField)
