import Icon from '@/components/Icon/Icon'
import React, { useState } from 'react'
import style from './styles/projectsitem.module.scss'
import { TextTag } from '@/components/Tag'
import { TUserShort } from '@/interfaces/Types'
import Link from '../Link'
import classNames from 'classnames'
import { UserLink } from '../User'
import { AvatarProject } from '../Avatar'
import { CheckboxFavorite } from '../Checkbox'
import { useStores } from '@/hooks'

interface IProps {
  id: number
  code: string
  title: string
  image: string
  absoluteUrl: string
  users: TUserShort[]
  milestonesCount: number
  casesCount: number
  runsCount: number
  isFavorite: boolean
  className?: string
}

const countUsers = 6

const ProjectsItemTable = ({
  id,
  code,
  title,
  image,
  users,
  milestonesCount,
  absoluteUrl,
  casesCount,
  runsCount,
  isFavorite,
  className = ''
}: IProps): React.ReactElement => {
  const [checked, setChecked] = useState(isFavorite)
  const [isLoading, setLoading] = useState(false)
  const { api } = useStores()

  let lastUser: TUserShort | null = null
  if (users.length === countUsers) {
    lastUser = users[countUsers - 1]
  }
  const handleChangeFavorite = async (isChecked: boolean): Promise<void> => {
    setLoading(true)
    if (isChecked) {
      await api.addFavorite({
        model_name: 'ProjectPage',
        object_id: id
      })
      setChecked(true)
    } else {
      await api.deleteFavorite({
        modelName: 'ProjectPage',
        objectId: id
      })
      setChecked(false)
    }
    setLoading(false)
  }

  return (
    <div
      className={classNames({
        [style.projectsitem__table]: true,
        [className]: Boolean(className)
      })}
    >
      <div className={style.projectsitem__start}>
        <AvatarProject
          className={style.projectsitem__avatar}
          src={image !== '' ? image : null}
        />

        <div className={style.projectsitem__itemdescription}>
          <Link to={absoluteUrl} className={style.projectsitem__name}>
            {title}
          </Link>

          <div className={style.projectsitem__id}>id: {code}</div>
        </div>

        <CheckboxFavorite
          checked={checked}
          setChecked={handleChangeFavorite}
          disabled={isLoading}
        />
      </div>

      <div className={style.projectsitem__end}>
        <div className={style.projectsitem__pics}>
          {users !== undefined && users.length > 0
            ? (
              <div className={style.projectsitem__avatarstable}>
                {lastUser !== null
                  ? (
                    <UserLink
                      profileUrl={lastUser.profile_url}
                      avatar={lastUser.avatar !== '' ? lastUser.avatar : null}
                      size='medium'
                      className={style.projectsitem__avataritem}
                      tooltip
                      firstName={lastUser.first_name}
                      lastName={lastUser.last_name}
                      email={lastUser.email}
                    />
                    )
                  : null}

                {users.length > countUsers
                  ? (
                    <div className={style.projectsitem__countusers}>
                      +{users.length - countUsers + 1}
                    </div>
                    )
                  : null}

                {users.slice(0, countUsers - 1).map((el) => {
                  return (
                    <UserLink
                      key={el.id}
                      profileUrl={el.profile_url}
                      avatar={el.avatar !== '' ? el.avatar : null}
                      size='medium'
                      className={style.projectsitem__avataritem}
                      tooltip
                      firstName={el.first_name}
                      lastName={el.last_name}
                      email={el.email}
                    />
                  )
                })}
              </div>
              )
            : null}
        </div>

        <div className={style.projectsitem__tags}>
          <TextTag size='medium'>
            <Icon src='flag' slot='icon-left' />
            {milestonesCount}
          </TextTag>

          <TextTag size='medium'>
            <Icon src='run' slot='icon-left' />
            {runsCount}
          </TextTag>

          <TextTag size='medium'>
            <Icon src='test_case' slot='icon-left' />
            {casesCount}
          </TextTag>
        </div>
      </div>
    </div>
  )
}

export default ProjectsItemTable
