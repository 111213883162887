import Button from '@/components/Button/Button'
import Icon from '@/components/Icon/Icon'
import { HeaderNavLink } from '@/components/NavLinkButton'
import React from 'react'
import style from '../styles/company.module.scss'

interface IProps {
  subpageProjects: { absolute_url: string, title: string }
  subpageMembers: { absolute_url: string, title: string }
  isCurrentUserAdmin: boolean
  subpageInvites: { absolute_url: string, title: string }
  handleOpenLeaveModal: () => void
}

const CompanyNavigation = ({
  subpageProjects,
  subpageMembers,
  isCurrentUserAdmin,
  subpageInvites,
  handleOpenLeaveModal
}: IProps): JSX.Element => {
  return (
    <div className={style.company__navpannel}>
      <div className={style.company__navs}>
        <HeaderNavLink
          className={style.company__nav}
          link={subpageProjects.absolute_url}
        >
          {subpageProjects.title}
        </HeaderNavLink>

        <HeaderNavLink
          className={style.company__nav}
          link={subpageMembers.absolute_url}
        >
          {subpageMembers.title}
        </HeaderNavLink>

        {isCurrentUserAdmin
          ? (
            <HeaderNavLink
              className={style.company__nav}
              link={subpageInvites.absolute_url}
            >
              {subpageInvites.title}
            </HeaderNavLink>
            )
          : null}
      </div>

      <Button theme='light' circle onClick={handleOpenLeaveModal}>
        <Icon src='exitIcon' className={style.exit} />
      </Button>
    </div>
  )
}

export default CompanyNavigation
