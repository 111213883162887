import React from 'react'
import style from '../styles/members.module.scss'
import Pagination from '@/components/Pagination'
import PaginatedQueryResult from '@/api/interfaces/PaginatedQueryResult'
import { ICompanyUser } from '@/interfaces/Company'
import CompanyMember from '@/components/Member/CompanyMember'

interface IProps {
  data: PaginatedQueryResult<ICompanyUser>
  currentPage: number
  pageSize: number
  idCompany: number
  setPage: (string) => void
  handleMutate: () => Promise<void>
  isAdmin: boolean
  isOwner: boolean
}

const UserList = ({
  data,
  currentPage,
  pageSize,
  idCompany,
  setPage,
  isAdmin,
  isOwner,
  handleMutate
}: IProps): React.ReactElement => {
  return (
    <>
      <div className={style.members__list}>
        {data.results.map((el) => {
          return (
            <CompanyMember
              key={el.id}
              id={el.id}
              avatar={el.user.avatar}
              url={el.user.profile_url}
              firstName={el.user.first_name}
              lastName={el.user.last_name}
              idCompany={idCompany}
              onChange={handleMutate}
              isAdmin={isAdmin}
              role={el.role}
              email={el.user.email}
              isOwner={isOwner}
            />
          )
        })}
      </div>

      <div className={style.members__pagination}>
        <Pagination
          currentPage={currentPage}
          total={data.count}
          pageSize={pageSize}
          handleChange={setPage}
        />
      </div>
    </>
  )
}

export default UserList
