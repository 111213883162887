import React from 'react'
import style from './styles/sentry_error.module.scss'
import { errorRobot } from '@/images'
import Title from '@/components/Title'

const SentryError = (): React.ReactElement => {
  return (
    <div className={style.error}>
      <img
        src={errorRobot}
        width={300}
        height={300}
        alt=''
        className={style.error__image}
      />

      <Title type='h1' className={style.error__title}>
        An unexpected error has occurred.
      </Title>

      <p className={style.error__text}>
        We are already trying to fix the problem. Please try reloading the page
        or returning to it later.
      </p>
    </div>
  )
}

export default SentryError
