import React from 'react'
import Icon from '@/components/Icon/Icon'
import classNames from 'classnames'
import { TextTag } from '@/components/Tag'
import { observer } from 'mobx-react'
import { suiteService } from '../../service'
import style from './styles/suitesdndlist.module.scss'
import { FormattedMessage } from 'react-intl'
import { ISuiteCaseParams } from '@/interfaces/Suites'

interface IProps {
  caseCount?: number
  queryParams: ISuiteCaseParams
  changeParams: (name: string, value: number | undefined) => void
}

const WithoutSuite = observer(({ caseCount = 0, changeParams, queryParams }: IProps) => {
  const suiteId = queryParams?.activeNode !== -1 ? undefined : null

  const handleClick = (): void => {
    if (suiteId === undefined) {
      suiteService.setCurrentSuite({ id: null, nodeTitle: 'node.text' })
      changeParams('activeNode', -1)
    } else {
      suiteService.setCurrentSuite({ id: undefined, nodeTitle: 'node.text' })
      changeParams('activeNode', undefined)
    }
  }
  return (
    <div className={style.dnd__root}>
      <button
        type='button'
        onClick={handleClick}
        className={classNames({
          [style.dnd__titlebtn]: true,
          [style['dnd__titlebtn--opened']]: suiteId === null
        })}
      >
        <Icon src='folders' />

        <FormattedMessage
          id='suites.root_suite'
          defaultMessage='Without suite'
        />
      </button>

      <div className={style.dnd__right}>
        <TextTag>
          <Icon src='test_case' />
          {caseCount}
        </TextTag>
      </div>
    </div>
  )
})

export default WithoutSuite
