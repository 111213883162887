import { calculatePercent } from '@/utils'
import React from 'react'
import { BadgeFullStatus } from '@/components/Status'
import { IStatistics } from '@/interfaces/Runs'

interface IProps {
  statistic: IStatistics
}

const GroupFullStatuses = ({
  statistic
}: IProps): React.ReactElement => {
  return (
    <>
      <BadgeFullStatus
        status='UNTESTED'
        count={statistic.untested}
        percent={calculatePercent(statistic.untested, statistic.count)}
      />
      <BadgeFullStatus
        status='PASSED'
        count={statistic.passed}
        percent={calculatePercent(statistic.passed, statistic.count)}
      />

      <BadgeFullStatus
        status='BLOCKED'
        count={statistic.blocked}
        percent={calculatePercent(statistic.blocked, statistic.count)}
      />

      <BadgeFullStatus
        status='RETEST'
        count={statistic.retest}
        percent={calculatePercent(statistic.retest, statistic.count)}
      />

      <BadgeFullStatus
        status='FAILED'
        count={statistic.failed}
        percent={calculatePercent(statistic.failed, statistic.count)}
      />
    </>
  )
}

export default GroupFullStatuses
