import React, { ReactElement } from 'react'
import { NodeModel } from '@minoru/react-dnd-treeview'
import Icon from '@/components/Icon/Icon'
import classNames from 'classnames'
import { TextTag } from '@/components/Tag'
import { observer } from 'mobx-react'
import { ISuite, ISuiteCaseParams } from '@/interfaces/Suites'
import { suiteService } from '../../service'
import { Dropdown, DropdownMenu } from '@/components/Dropdown'
import { FormattedMessage } from 'react-intl'
import useSidebar from '@/hooks/useSidebar'
import { useNavigate } from 'react-router-dom'
import WithoutSuite from './WithoutSuite'
import style from './styles/suitesdndlist.module.scss'
import { USER_PERMISSIONS, CREATE_PERMISSION_LEVEL } from '@/const'
import { useCurrentPermissions } from '@/hooks/useCurrentPermissions'

const LayDndTree = React.lazy(
  async () =>
    await import('@/components/DndTree').then(({ DndTree }) => ({
      default: DndTree
    }))
)

interface IProps {
  data?: ISuite[]
  onChange?: (id: number, target: number, sort: number) => void
  onDelete: (id: number) => void
  onEdit: (id: number) => void
  canEditSuite: boolean
  nodeArray?: number[] | boolean
  setNodeArray: (nodes: number[]) => void
  queryParams: ISuiteCaseParams
  setQueryParams: (name: string, value: any) => void
  caseCountWithoutCases: number | undefined
  changeParamsWithoutCases: (name: string, value: number | undefined) => void
}

interface ClickZoneProps {
  node: any
  onToggle: () => void
  isOpen: boolean
  isDragging: boolean
  hasChild: boolean
  onEdit: (id: number) => void
  onDelete: (id: number) => void
  canEditSuite: boolean
  queryParams: ISuiteCaseParams
  setNode: (name: string, value: any) => void
}

const ClickZone = observer(
  ({
    node,
    onToggle,
    isOpen,
    isDragging,
    hasChild,
    onEdit,
    onDelete,
    canEditSuite,
    setNode,
    queryParams
  }: ClickZoneProps) => {
    const canUserCreateRun = useCurrentPermissions(
      USER_PERMISSIONS.run,
      CREATE_PERMISSION_LEVEL
    )

    const suiteId = Number(queryParams?.activeNode ?? null)
    const nodeId = Number(node.id)
    const { ProjectPageRunsCreate } = useSidebar()
    const navigate = useNavigate()
    const urlRunCreate = ProjectPageRunsCreate?.absolute_url ?? ''

    const handleClick = (): void => {
      if (suiteId === nodeId) {
        setNode('activeNode', undefined)
        suiteService.setCurrentSuite({ id: undefined, nodeTitle: node.text })
      } else {
        setNode('activeNode', nodeId)
        suiteService.setCurrentSuite({ id: nodeId, nodeTitle: node.text })
      }
    }

    const handleNavigateToRun = (): void => {
      suiteService.setCurrentSuite({ id: nodeId, nodeTitle: node.text })
      navigate(`${urlRunCreate}?nodeId=${nodeId}`)
    }

    const handleDelete = (): void => {
      onDelete(nodeId)
    }

    const handleEdit = (): void => {
      onEdit(nodeId)
    }

    return (
      <div className={style.dnd__item}>
        {canEditSuite
          ? (
            <Icon
              className={classNames({
                [style.dnd__action]: true,
                [style.dnd__action_dragging]: isDragging
              })}
              src='action'
            />
            )
          : null}

        <button
          type='button'
          onClick={handleClick}
          className={classNames({
            [style.dnd__titlebtn]: true,
            [style['dnd__titlebtn--opened']]: nodeId === suiteId
          })}
        >
          <Icon src='folders' />

          <div>{node.text}</div>
        </button>

        <div className={style.dnd__right}>
          <TextTag>
            <Icon src='test_case' />

            {node.data.casesCount}
          </TextTag>

          <div className={style.dnd__btngroup}>
            {canEditSuite
              ? (
                <Dropdown placement='bottom-end'>
                  <button
                    slot='trigger'
                    type='button'
                    className={style.dnd__dropdownbtn}
                  >
                    <Icon src='etc' />
                  </button>

                  <DropdownMenu className={style.menu}>
                    {canUserCreateRun
                      ? (
                        <button
                          type='button'
                          className={style.menu__btn}
                          onClick={handleNavigateToRun}
                        >
                          <Icon src='run' />
                          <span>
                            <FormattedMessage
                              id='suites.create.run'
                              defaultMessage='Create run'
                            />
                          </span>
                        </button>
                        )
                      : null}

                    <button
                      type='button'
                      className={style.menu__btn}
                      onClick={handleEdit}
                    >
                      <Icon src='edit' />
                      <span>
                        <FormattedMessage
                          id='suites.edit'
                          defaultMessage='Edit suite'
                        />
                      </span>
                    </button>

                    <button
                      type='button'
                      className={style.menu__btn}
                      onClick={handleDelete}
                    >
                      <Icon src='delete' className={style.menu__delete_icon} />
                      <span>
                        <FormattedMessage
                          id='suites.delete'
                          defaultMessage='Delete suite'
                        />
                      </span>
                    </button>
                  </DropdownMenu>
                </Dropdown>
                )
              : null}

            {hasChild
              ? (
                <button
                  type='button'
                  onClick={onToggle}
                  className={style.dnd__openbtn}
                >
                  <Icon
                    src='arrow_down'
                    className={classNames({
                      [style.dnd__arrow]: true,
                      [style.dnd__arrow_open]: isOpen
                    })}
                  />
                </button>
                )
              : null}
          </div>
        </div>
      </div>
    )
  }
)

const render = (
  node: NodeModel<any>,
  {
    depth,
    isOpen,
    isDragging,
    hasChild,
    onToggle,
    onEdit,
    onDelete,
    queryParams,
    setQueryParams,
    canEditSuite
  }: {
    depth: number
    isOpen: boolean
    isDragging: boolean
    onToggle: () => void
    hasChild: boolean
    onEdit: (id: number) => void
    onDelete: (id: number) => void
    queryParams: ISuiteCaseParams
    setQueryParams: (name: string, value: any) => void
    canEditSuite: boolean
  }
): ReactElement => {
  return (
    <div className={style.dnd}>
      {node.droppable !== undefined
        ? (
          <ClickZone
            isOpen={isOpen}
            node={node}
            onToggle={onToggle}
            isDragging={isDragging}
            hasChild={hasChild}
            onEdit={onEdit}
            onDelete={onDelete}
            setNode={setQueryParams}
            queryParams={queryParams}
            canEditSuite={canEditSuite}
          />
          )
        : null}
    </div>
  )
}
const SuitesDndList = ({
  data = [],
  onChange,
  onDelete,
  onEdit,
  nodeArray,
  setNodeArray,
  queryParams,
  setQueryParams,
  canEditSuite,
  caseCountWithoutCases,
  changeParamsWithoutCases
}: IProps): React.ReactElement => {
  interface IData {
    casesCount: number
    sort: number
  }

  interface IFormatData {
    id: number
    parent: number
    droppable: boolean
    text: string
    data: IData
  }

  const formatData: IFormatData[] = data.map((element) => {
    const isHasParent = data.findIndex((el) => el.id === element.parent) !== -1

    return {
      id: element.id,
      parent: element.parent === null || !isHasParent ? 0 : element.parent,
      droppable: true,
      text: element.title,
      data: { casesCount: element.cases_count, sort: element.sort }
    }
  })

  return (
    <div className={style.dnd__list}>
      <WithoutSuite
        queryParams={queryParams}
        caseCount={caseCountWithoutCases}
        changeParams={changeParamsWithoutCases}
      />

      <LayDndTree
        nodeArray={nodeArray}
        setThreeState={setNodeArray}
        render={(node, params) => {
          const newParams = {
            ...params,
            onEdit,
            onDelete,
            canEditSuite,
            queryParams,
            setQueryParams
          }
          return render(node, newParams)
        }}
        data={formatData}
        onChange={onChange}
      />
    </div>
  )
}

export default SuitesDndList
