import style from '../style/profile.module.scss'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useForm } from 'react-hook-form'
import Input from '@/components/Input/Input'
import Icon from '@/components/Icon/Icon'
import Button from '@/components/Button/Button'
import { useStores } from '@/hooks'
import { reactHookFormErrorFormater } from '@/utils'
import { Form } from '@/components/Form'

interface IProps {
  email: string
  onClose: () => void
  code: string
  success: () => void
}

export interface IFormInputs {
  username?: string
  restore_password_confirm_code?: string
  non_field_errors?: string
  new_password?: string
  confirm?: string
}

const NewPassword = ({
  email,
  code,
  onClose,
  success
}: IProps): React.ReactElement => {
  const intl = useIntl()
  const { api } = useStores()

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting }
  } = useForm<IFormInputs>({
    defaultValues: {
      username: email,
      new_password: '',
      confirm: '',
      restore_password_confirm_code: code
    }
  })

  const onSubmit = async (params): Promise<void> => {
    try {
      await api.setPasswordRestore({
        new_password: params.new_password,
        username: params.username,
        restore_password_confirm_code: params.restore_password_confirm_code
      })
      success()
    } catch (error) {
      const errors = reactHookFormErrorFormater(params, error)
      errors.forEach((item) => {
        setError(item.key as keyof IFormInputs, {
          message: item.value
        })
      })
    }
  }
  const nonFieldErrors = errors?.non_field_errors?.message ?? ''

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className={style.modal__top}>
        <FormattedMessage
          id='profile.change_pass.title'
          defaultMessage='Change password'
        />

        <Button onClick={onClose} theme='light' circle>
          <Icon src='clear' />
        </Button>
      </div>

      <div className={style.modal__middle}>
        <Input
          type='password'
          label={intl.formatMessage({
            id: 'profile.change_pass.new',
            defaultMessage: 'New password'
          })}
          className={style.modal__input}
          required
          {...register('new_password', { required: true })}
          error={errors?.new_password?.message ?? ''}
        />

        <Input
          type='password'
          label={intl.formatMessage({
            id: 'profile.change_pass.repeat',
            defaultMessage: 'Repeat new password'
          })}
          className={style.modal__input}
          required
          {...register('confirm', {
            required: true,
            validate: (value, formValues) => {
              if (formValues.new_password !== value) {
                return intl.formatMessage({
                  id: 'profile.change_pass.no_repeat',
                  defaultMessage: 'Your passwords do no match'
                })
              }
            }
          })}
          error={errors?.confirm?.message ?? ''}
        />
        {nonFieldErrors !== ''
          ? (
            <div className={style.modal__error}>{nonFieldErrors}</div>
            )
          : null}
      </div>

      <div className={style.modal__bottom}>
        <Button disabled={isSubmitting} type='submit'>
          <Icon src='checked' slot='icon-left' />

          <FormattedMessage id='common.save' defaultMessage='Save' />
        </Button>
      </div>
    </Form>
  )
}

export default NewPassword
