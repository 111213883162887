import { FILE_EXTENSIONS } from '@/const'
import { useLocale } from '@/hooks'
import { checkImageInURL, humanFileSize } from '@/utils'
import classNames from 'classnames'
import React from 'react'
import DeleteButton from '../Button/DeleteButton'
import Icon from '../Icon/Icon'
import style from './styles/file_item.module.scss'
import { useFile } from '@/hooks/useFile'

interface IProps {
  id: number
  name: string
  size: number
  date: string
  previewUrl?: string
  downloadUrl?: string
  className?: string
  isLoaded: boolean
  handleDelete: (id: number, name: string) => void
  handleOpenModalPreview?: (name: string, url: string) => void
  fileDownloadRef: React.RefObject<HTMLAnchorElement>
  canUserDeleteFile?: boolean
}

const FileItem = ({
  id,
  name,
  size,
  date,
  previewUrl,
  downloadUrl,
  className = '',
  isLoaded,
  handleDelete,
  fileDownloadRef,
  canUserDeleteFile
}: IProps): JSX.Element => {
  const { href } = useFile({ url: downloadUrl })
  const { locale } = useLocale()
  const fileSize = humanFileSize(size, locale === 'ru')

  const fileIcon = (): string => {
    const ext = name.split('.').reverse()[0].toLowerCase()

    if (FILE_EXTENSIONS.video.includes(ext)) {
      return 'video'
    } else if (checkImageInURL(name)) {
      return ''
    }

    return 'text_file'
  }

  const handleDeleteClick = (): void => {
    handleDelete(id, name)
  }

  const showFiles = isLoaded && previewUrl !== undefined ? href : previewUrl

  return (
    <div
      className={classNames({
        [style.item]: true,
        [className]: Boolean(className)
      })}
    >
      {canUserDeleteFile === true
        ? (
          <DeleteButton
            className={style.delete}
            onClick={handleDeleteClick}
            title='Delete file'
            aria-describedby='delete-file'
            type='button'
            tabIndex={0}
            role='button'
          />
          )
        : null}

      <a
        target='blank'
        rel='noreferrer'
        href={href}
        className={style.icon__wrap}
      >
        {showFiles !== undefined
          ? (
            <img
              src={showFiles}
              width={70}
              height={70}
              alt={name}
              className={style.preview}
            />
            )
          : (
            <Icon src={fileIcon()} size='xl' className={style.icon} />
            )}
      </a>

      {href !== undefined
        ? (
          <a
            ref={fileDownloadRef}
            href={href}
            download={name}
            className={style.download}
            aria-label='Download file'
            aria-describedby='download-file'
            target='_blank'
            rel='noopener noreferrer'
          >
            <span className={style.name}>{name}</span>
          </a>
          )
        : (
          <p className={style.name}>{name}</p>
          )}

      <div className={style.item__bottom}>
        <span>{date}</span>
        <span>{fileSize}</span>
      </div>
    </div>
  )
}

export default FileItem
