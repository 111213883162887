import classNames from 'classnames'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Button from '../Button/Button'
import Icon from '../Icon/Icon'
import { UserCompanyRole } from '../User'
import style from './styles/member.module.scss'
import Select from '../Select/Select'
import MenuItem from '../MenuItem/MenuItem'
import { useStores } from '@/hooks'
import { COMPANY_ROLES, MODALS_CONTENTS } from '@/const'
import Link from '../Link'
import {} from '@/utils'
import { getInitials, getNameUser } from '../UserName'
import { IRole } from '@/interfaces/Company'
import { AvatarUser } from '../Avatar'

interface IMemberProps {
  id: number
  idCompany: number
  isAdmin?: boolean
  isOwner?: boolean
  role: IRole
  className?: string
  avatar: string | null
  firstName: string
  lastName: string
  email: string
  url: string
  onChange: () => Promise<void>
}

const CompanyMember = ({
  id,
  role,
  idCompany,
  isAdmin = false,
  isOwner = false,
  className = '',
  onChange,
  url,
  avatar,
  firstName,
  lastName,
  email
}: IMemberProps): React.ReactElement => {
  const intl = useIntl()
  const { api, rolesStore } = useStores()
  const name = getNameUser(firstName, lastName)
  const initialsUser = getInitials(firstName ?? '', lastName ?? '', email)
  const rolesWithoutOwner = rolesStore.roles.filter(
    (el) => el.role_type !== COMPANY_ROLES.owner
  )
  const handleChange = async (e): Promise<void> => {
    await api.changeRole(idCompany, id, {
      role: e.target.value
    })
    await onChange()
  }

  const isEditable: boolean =
    (isOwner && role.role_type !== COMPANY_ROLES.owner) ||
    (isAdmin && role.role_type === COMPANY_ROLES.employee)

  const { modalStore } = useStores()
  const handleClickRemove = (): void => {
    modalStore.open(MODALS_CONTENTS.removeMemberCompany, {
      memberCompanyId: id,
      idCompany: idCompany,
      avatar: avatar,
      name: name,
      initialsUser
    })
  }

  return (
    <div
      className={classNames({
        [style.member]: true,
        [className]: Boolean(className)
      })}
    >
      <div className={style.member__info}>
        <AvatarUser size='large' src={avatar} initials={initialsUser} />

        <Link
          target='_blank'
          className={classNames(style.member__name, style.member__link)}
          to={url}
        >
          {name}
        </Link>

        {isEditable
          ? (
            <Select
              className={style.member__select}
              placeholder={intl.formatMessage({
                id: 'input.placeholder',
                defaultMessage: 'Choose'
              })}
              required
              onChange={handleChange}
              value={String(role.id) ?? ''}
            >
              {rolesWithoutOwner.map((el) => {
                return (
                  <MenuItem value={el.id} key={el.id}>
                    <FormattedMessage
                      id={`company.roles.${el.role_type}`}
                      defaultMessage='Employee'
                    />
                  </MenuItem>
                )
              })}
            </Select>
            )
          : (
            <UserCompanyRole
              role={role.role_type}
              className={style.member__role}
            />
            )}
      </div>

      {isEditable
        ? (
          <Button onClick={handleClickRemove} theme='light' size='lg' circle>
            <Icon src='clear' size='large' className={style.member__clear} />
          </Button>
          )
        : null}
    </div>
  )
}

export default CompanyMember
