import Icon from '@/components/Icon/Icon'
import React from 'react'
import { TextTag } from '@/components/Tag'
import { ColorStatus } from '../Status'
import LinearProgress from '../LinearProgress'
import Link from '../Link'
import Deadline from '../Deadline'
import AvatarProject from '@/components/Avatar/AvatarProject'
import style from './styles/milestoneitem.module.scss'

interface IProps {
  absoluteUrl: string
  id: number
  title: string
  endDate?: string
  color: string
  progress: number
  casesCount?: number
  runsCount?: number
  code: string
  projectImage?: string
}

const MilestoneItem = ({
  title,
  endDate,
  color,
  progress,
  casesCount,
  runsCount,
  code,
  projectImage,
  absoluteUrl
}: IProps): React.ReactElement => {
  const isCompleted = progress === 100

  return (
    <div className={style.milestoneitem__item}>
      <ColorStatus color={color} size='small' />
      <div className={style.milestoneitem__itemdescription}>
        <div className={style.milestoneitem__code}>{code}</div>
        <Link to={absoluteUrl} className={style.milestoneitem__title}>
          <Icon src='flag' />
          {title}
        </Link>
      </div>
      <div className={style.milestoneitem__right}>
        {runsCount !== undefined
          ? (
            <TextTag size='medium'>
              <Icon src='run' slot='icon-left' />
              {runsCount}
            </TextTag>
            )
          : null}
        {casesCount !== undefined
          ? (
            <TextTag size='medium'>
              <Icon src='test_case' slot='icon-left' />
              {casesCount}
            </TextTag>
            )
          : null}

        {endDate !== undefined
          ? <Deadline
              date={endDate}
              isCompleted={isCompleted}
              className={style.milestoneitem__deadline}
            />
          : <AvatarProject size='small' src={projectImage} />}

        <LinearProgress
          value={progress}
          className={style.milestoneitem__progress}
        />
      </div>
    </div>
  )
}

export default MilestoneItem
