import React from 'react'
import style from './styles/projectslist.module.scss'
import Pagination from '@/components/Pagination'
import PaginatedQueryResult from '@/api/interfaces/PaginatedQueryResult'
import { IProjectElement } from '@/interfaces/Projects'
import { ProjectsItem, ProjectsItemTable } from '@/components/ProjectsItem'
import { SWITCH_VALUE, TSwitchStorage } from '@/services/ProjectSwitcher'

interface IProps {
  data: PaginatedQueryResult<IProjectElement>
  currentPage: number
  pageSize: number
  displayMode: TSwitchStorage
  setPage: (string) => void
}

const ProjectsList = ({
  data,
  displayMode,
  currentPage,
  pageSize,
  setPage
}: IProps): React.ReactElement => {
  return (
    <>
      {displayMode === SWITCH_VALUE.list
        ? (
          <div className={style.project__list}>
            {data.results.map((el) => {
              return (
                <ProjectsItem
                  key={el.id}
                  id={el.id}
                  code={el.code}
                  title={el.title}
                  absoluteUrl={el.absolute_url}
                  image={el.image}
                  users={el.users}
                  milestonesCount={el.milestones_count}
                  casesCount={el.cases_count}
                  isFavorite={el.is_favourite}
                  runsCount={el.runs_count}
                />
              )
            })}
          </div>
          )
        : (
          <div className={style.project__table}>
            {data.results.map((el) => {
              return (
                <ProjectsItemTable
                  key={el.id}
                  id={el.id}
                  code={el.code}
                  title={el.title}
                  absoluteUrl={el.absolute_url}
                  image={el.image}
                  users={el.users}
                  milestonesCount={el.milestones_count}
                  casesCount={el.cases_count}
                  runsCount={el.runs_count}
                  isFavorite={el.is_favourite}
                />
              )
            })}
          </div>
          )}

      <div className={style.project__pagination}>
        <Pagination
          currentPage={currentPage}
          total={data.count}
          pageSize={pageSize}
          handleChange={setPage}
        />
      </div>
    </>
  )
}

export default ProjectsList
