import Icon from '@/components/Icon/Icon'
import React from 'react'
import { ColorStatus, TaskStatus } from '../Status'
import GroupStatuses from '../GroupStatuses/GroupStatuses'
import { IMilestone } from '@/interfaces/Milestones'
import { IProjectMember } from '@/interfaces/Member'
import { IStatistics } from '@/interfaces/Runs'
import UserLink from '../User/UserLink'
import Link from '../Link'
import { TRunStatus } from '@/interfaces/Types'
import classNames from 'classnames'
import { useStores } from '@/hooks'
import { useIntl } from 'react-intl'
import AssignToBtn from './components/AssignToBtn'
import { determineDisplayName } from './utils'
import Deadline from '../Deadline'
import { useCurrentPermissions } from '@/hooks/useCurrentPermissions'
import { PERMISSION_LEVEL, RUN_STATUS, USER_PERMISSIONS } from '@/const'
import RunDropdown from './components/RunDropdown'
import style from './styles/runitem.module.scss'

interface IRunItemProps {
  id: number
  title: string
  milestone: IMilestone | null
  assignedTo: IProjectMember | null
  absoluteUrl: string
  deadline: string | null
  statistic: IStatistics
  isFull?: boolean
  status?: TRunStatus
  className?: string
  handleUpdate?: () => void
  handleOpenModal?: (runId: number) => void
  setRunId?: any
  isFromDashboard?: boolean
}

const RunItem = ({
  id,
  title,
  milestone,
  assignedTo,
  deadline,
  absoluteUrl,
  statistic,
  isFull = false,
  status,
  handleUpdate,
  handleOpenModal,
  isFromDashboard = false,
  className = ''
}: IRunItemProps): JSX.Element => {
  const stores = useStores()
  const profileUrl = assignedTo?.profile_url
  const isCompleted = status === RUN_STATUS.completed
  const currentUser = stores.userStore.currentUser
  const intl = useIntl()

  const showDropdown = true

  let canUserAssignRun: boolean = false

  if (!isFromDashboard) {
    canUserAssignRun = useCurrentPermissions(USER_PERMISSIONS.assign_run, [
      PERMISSION_LEVEL.full
    ])
  }

  const currentUserName = intl.formatMessage({
    id: 'runs.username',
    defaultMessage: 'You'
  })

  const createdStatus = (statistic.status as TRunStatus) === 'CREATED'

  return (
    <div
      className={classNames({
        [style.runitem__item]: true,
        [className]: Boolean(className)
      })}
    >
      {milestone != null
        ? (
          <ColorStatus
            color={milestone.color}
            size='xs'
            className={style.runitem__colorstatus}
          />
          )
        : null}

      <div className={style.runitem__itemdescription}>
        {milestone !== null
          ? (
            <span className={style.runitem__code}>{milestone?.title}</span>
            )
          : null}

        <Link to={absoluteUrl} className={style.runitem__title}>
          <Icon src='run' />

          <span className={style.runitem__text}>{title}</span>
        </Link>
      </div>

      {!createdStatus ? <GroupStatuses statistic={statistic} /> : null}

      {isFull
        ? (
          <div className={style.runitem__username_wrapper}>
            <span className={style.runitem__username}>
              <span className={style.runitem__username_text}>
                {determineDisplayName(assignedTo, currentUser, currentUserName)}
              </span>
            </span>
            {assignedTo !== null &&
          assignedTo !== undefined &&
          profileUrl !== undefined
              ? (
                <UserLink
                  firstName={assignedTo.first_name}
                  lastName={assignedTo.last_name}
                  avatar={assignedTo.avatar}
                  profileUrl={profileUrl}
                  tooltip
                  email={assignedTo.email}
                />
                )
              : null}
          </div>
          )
        : null}

      {isFull && deadline != null
        ? (
          <Deadline date={deadline} isCompleted={isCompleted} />
          )
        : null}

      {(statistic.status as TRunStatus) === 'CREATED'
        ? (
            handleOpenModal !== undefined && canUserAssignRun
              ? (
                <AssignToBtn handleClick={() => handleOpenModal(id)} />
                )
              : (
                <TaskStatus status='ASSIGNED' />
                )
          )
        : (
          <TaskStatus status={statistic.status as TRunStatus} />
          )}

      {showDropdown && !isFromDashboard
        ? (
          <RunDropdown
            milestone={milestone}
            titleRun={title}
            idRun={id}
            runUrl={absoluteUrl}
            status={statistic.status as TRunStatus}
            mutateMilestones={handleUpdate}
          />
          )
        : null}
    </div>
  )
}

export default RunItem
