import { Components } from '@garpix/garpix-web-components'
import { GxRadio } from '@garpix/garpix-web-components-react'
import classNames from 'classnames'
import React, { forwardRef } from 'react'

import style from './styles/radio.module.scss'
import Icon from '../Icon/Icon'

interface IProps extends Components.GxRadio {
  icon: string
}

const RadioPlate = forwardRef<IProps, any>(
  (
    { icon, onBlur, onChange, children, checked, className = '', ...props },
    ref
  ): React.ReactElement => {
    return (
      <GxRadio
        ref={ref}
        {...props}
        checked={checked}
        className={classNames({
          [style.radio]: true,
          [style.plate]: true,
          [style.plate__checked]: checked,
          [className]: Boolean(className)
        })}
      >
        <div className={style.plate__block}>
          <Icon
            className={classNames({
              [style.plate__icon]: true,
              [style.plate__checked]: checked
            })}
            src={icon}
            size='large'
          />
          <span className={style.plate__text}>{children}</span>
        </div>
      </GxRadio>
    )
  }
)

export default RadioPlate
