import React, { useEffect, useRef, useState } from 'react'
import useSWRInfinite from 'swr/infinite'
import NotificationsHead from './NotificationsHead'
import { useStores } from '@/hooks'
import NotificationItem from '@/components/NotificationItem'
import EmptyNotifications from './EmptyNotifications'
import NotificationsFooter from './NotificationsFooter'
import useNotifyCounter from '@/hooks/useNotifyCounter'
import { LoaderNotifications } from '../loaders'
import useDebounceSwrInfinite from '@/hooks/useDebounceSwrInfinite'
import { INotifies } from '@/interfaces/Notifies'
import style from '../styles/notifications.module.scss'

interface IProps {
  handleCloseModal: () => void
}

const PAGE_SIZE = 20

const NotificationsPanel = ({
  handleCloseModal
}: IProps): React.ReactElement => {
  const { api } = useStores()
  const { counterUpdate } = useNotifyCounter()
  const refListNotify = useRef<HTMLDivElement | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const { data, size, setSize, mutate } = useSWRInfinite(
    (index) => {
      return {
        page: index + 1,
        count: PAGE_SIZE,
        _key: 'getSystemNotifies'
      }
    },
    async (params) => {
      setIsLoading(true)
      const data = await api.getSystemNotifies({
        ...params,
        page_size: params.count
      })
      setIsLoading(false)
      return data.results
    },
    {
      revalidateFirstPage: false,
      revalidateAll: false
    }
  )
  const isEmpty = data?.length === 0

  const isReachingEnd =
    isEmpty || (data != null && data[data.length - 1]?.length < PAGE_SIZE)

  const handleEnd = (): void => {
    if (isLoading) return

    const currentRef = refListNotify.current
    if (currentRef != null) {
      const currentScrollTop = currentRef.scrollTop
      const scrollThreshold = 200

      const isScrollEnd =
        currentScrollTop + currentRef.clientHeight + scrollThreshold >=
        currentRef.scrollHeight

      if (!isReachingEnd && isScrollEnd) {
        void setSize((prev) => {
          return prev + 1
        })
      }
    }
  }

  const handleEndDebounced = useDebounceSwrInfinite(handleEnd, 100)

  useEffect(() => {
    refListNotify.current?.addEventListener('scroll', handleEndDebounced)
    return () => {
      refListNotify.current?.removeEventListener('scroll', handleEndDebounced)
    }
  }, [isReachingEnd, size])

  const list: INotifies[] =
    data !== undefined && data !== null ? [].concat.apply([], data) : []

  const handleUpdate = async (): Promise<void> => {
    await mutate()
    counterUpdate()
  }

  const handleClear = async (): Promise<void> => {
    await setSize(1)
    await api.deleteAllNotifies()
    await handleUpdate()
    await mutate()
  }

  const handleMarkReadAll = async (): Promise<void> => {
    await api.readAllNotifies()
    await handleUpdate()
  }

  const handleMarkRead = async (id: number): Promise<void> => {
    await api.readNotifies([id])
    await handleUpdate()
  }

  return (
    <div className={style.notif}>
      <NotificationsHead handleCloseModal={handleCloseModal} />

      <div className={style.content} ref={refListNotify}>
        {list.length > 0
          ? list.map((el) => {
            return (
              <NotificationItem
                key={el.id}
                id={el.id}
                isRead={el.is_read}
                event={el.event}
                sentAt={el.sent_at}
                handleMarkRead={handleMarkRead}
                data={el.data_json}
                handleCloseModal={handleCloseModal}
              />
            )
          })
          : null}

        {!isLoading && list.length === 0 ? <EmptyNotifications /> : null}

        {!isReachingEnd
          ? (
            <LoaderNotifications countShimmers={2} />
            )
          : null}
      </div>

      {list.length > 0
        ? (
          <NotificationsFooter
            handleClear={handleClear}
            handleMarkReadAll={handleMarkReadAll}
          />
          )
        : null}
    </div>
  )
}

export default NotificationsPanel
