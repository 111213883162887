import React, { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import { IntlProvider } from 'react-intl'
import Combine from './apps/pages/Combine'
import { PATHS } from './const'
import { defaultLanguage, htmlLang, messages } from './i18n'
import useLocale from './hooks/useLocale'
import { GarpixCMSProvider } from '@garpix/cms'
import { runtimeConfig } from './config'
import { useStores } from './hooks'
import './styles/index.scss'

const App = ({ context }: any): React.ReactElement => {
  const { locale } = useLocale()
  const { userStore, rolesStore } = useStores()

  useEffect(() => {
    userStore.getCurrentUser()
    void rolesStore.initRoles()
  }, [])

  return (
    <GarpixCMSProvider
      baseUrl={String(runtimeConfig.API_URL)}
      language={htmlLang[locale]}
    >
      <IntlProvider
        messages={messages[locale]}
        locale={locale}
        defaultLocale={defaultLanguage}
      >
        <Routes>
          <Route
            path={PATHS.ALL.path}
            element={<Combine staticContext={context} {...PATHS.ALL} />}
          />
        </Routes>
      </IntlProvider>
    </GarpixCMSProvider>
  )
}

export default App
