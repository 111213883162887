import Button from '@/components/Button/Button'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import style from './styles/cases.module.scss'
import { useObjectPage, useStores } from '@/hooks'
import Icon from '@/components/Icon/Icon'

export const ApproveAll = ({
  handleSucces,
  handleClose,
  draftCasesCount
}): React.ReactElement => {
  const { api } = useStores()
  const obj = useObjectPage()
  const [isSubmit, setSubmit] = useState(false)
  const [countApproved, setCountApproved] = useState<number>()
  const [countSkipped, setCountSkipped] = useState<number>()
  const [error, setError] = useState('')

  const handleApproveAll = async (): Promise<void> => {
    setSubmit(true)
    await api
      .approveCases(obj.id)
      .then((res) => {
        setCountSkipped(res.skipped)
        setCountApproved(res.approved)
        handleSucces()
        setSubmit(false)
      })
      .catch((err) => setError(err.response))
  }

  return (
    <div className={style.modal}>
      <div className={style.modal__draft}>
        <Icon src='draft' size='xl' className={style.modal__drafticon} />
        {draftCasesCount}
      </div>

      <div className={style['cases__approve-desc']}>
        <FormattedMessage
          id='cases.approve.all.desc'
          defaultMessage='Do you really want to approve all drafts test cases'
        />
      </div>

      {error !== ' '
        ? (
          <div className={style.cases__approve_text}>{error}</div>
          )
        : null}

      {countApproved !== undefined && countSkipped !== undefined
        ? (
          <div className={style.cases__approve_text}>
            <span>
              <FormattedMessage
                id='cases.approve.all.approved'
                defaultMessage='Approved: {approved}'
                values={{ approved: countApproved }}
              />
            </span>

            <span>
              <FormattedMessage
                id='cases.approve.all.skipped'
                defaultMessage='Left in the draft: {skipped}'
                values={{ skipped: countSkipped }}
              />
            </span>
          </div>
          )
        : null}

      <div className={style.cases__approve}>
        <Button theme='light' onClick={handleClose} disabled={isSubmit}>
          <Icon src='clear' slot='icon-left' />

          <FormattedMessage id='common.close' defaultMessage='Close' />
        </Button>

        <Button onClick={handleApproveAll} disabled={isSubmit}>
          <Icon src='checked' slot='icon-left' />

          <FormattedMessage id='common.approve' defaultMessage='Approve' />
        </Button>
      </div>
    </div>
  )
}
