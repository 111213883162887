import { runtimeConfig } from '@/config'
import React from 'react'
import { Helmet } from 'react-helmet-async'

/** Подключаем Яндекс Метрику только на проде
 * и только для страниц не закрытых авторизацией */
const YandexMetrika = (): React.ReactElement | null => {
  if (runtimeConfig.IS_PRODUCTION === '1') {
    return (
      <Helmet>
        <script type='text/javascript'>
          {`(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
            m[i].l=1*new Date();
            for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
            k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
            (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
            
            ym(94694018, "init", {
              clickmap:true,
              trackLinks:true,
              accurateTrackBounce:true,
              webvisor:true
            })`}
        </script>
      </Helmet>
    )
  } else return null
}

export default YandexMetrika
