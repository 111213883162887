import React, { useState } from 'react'
import { MODAL_STAGE } from '@/const'
import ChooseUser from './ChooseUser'
import { useForm } from 'react-hook-form'
import { TCompanyRole } from '@/interfaces/Types'
import { Form } from '@/components/Form'
import { reactHookFormErrorFormater } from '@/utils'
import ConfirmLeave from './ConfirmLeave'
import SuccessChange from './SuccessChange'
import { IValue } from '@/components/Select/interfaces/AsyncSelect'
import { useStores } from '@/hooks'
import BaseModal from '@/components/Modal/Base'
import style from '../styles/company.module.scss'

interface IProps {
  onClose: () => void
  isOpen: boolean
  idCompany: number
  avatar?: string | null
  companyName: string
  handleMutate: () => Promise<void>
}

export interface IFormInputs {
  non_field_errors?: string
  idCompany: number
  user: IValue | null
  role: TCompanyRole | ''
}

const ChangeOwner = ({
  onClose,
  isOpen,
  idCompany,
  companyName,
  avatar,
  handleMutate
}: IProps): React.ReactElement => {
  const [stage, setStage] = useState<number>(MODAL_STAGE.first)
  const [isStayCompany, setStayCompany] = useState(true)
  const { api } = useStores()

  const StateMachineNext = {
    1: () => MODAL_STAGE.second,
    2: () => MODAL_STAGE.third
  }

  const handleNext = (): void => {
    setStage(StateMachineNext[String(stage)]())
  }

  const handleSuccess = (): void => {
    setStage(MODAL_STAGE.third)
  }

  const handleError = (): void => {
    setStage(MODAL_STAGE.first)
  }

  const {
    setError,
    handleSubmit,
    control,
    watch,
    clearErrors,
    formState: { isSubmitting, errors }
  } = useForm<IFormInputs>({
    defaultValues: {
      user: null,
      role: '',
      idCompany: idCompany
    }
  })

  const onSubmit = async (params): Promise<void> => {
    try {
      await api.changeOwner(params.idCompany, {
        new_owner: params.user.value,
        role: params.role,
        stay_in_company: isStayCompany
      })
      handleSuccess()
      await handleMutate()
    } catch (error) {
      handleError()
      const errors = reactHookFormErrorFormater(params, error)
      errors.forEach((item) => {
        setError(item.key as keyof IFormInputs, {
          type: '400',
          message: item.value
        })
      })
    }
  }
  const onError = (): void => {
    handleError()
  }

  const onCloseEnd = (): void => {
    onClose()
    setStage(MODAL_STAGE.first)
  }

  const user = watch('user')
  const role = watch('role')
  const nonFieldErrors = errors?.non_field_errors?.message ?? ''

  return (
    <BaseModal
      open={isOpen}
      onGx-after-hide={onCloseEnd}
      onGx-overlay-dismiss={onCloseEnd}
      hideDefaultClose
      size='medium'
      className={style.change_owner}
    >
      <Form onSubmit={handleSubmit(onSubmit, onError)}>
        {stage === MODAL_STAGE.first
          ? (
            <ChooseUser
              idCompany={idCompany}
              onClose={onClose}
              success={handleNext}
              clearErrors={clearErrors}
              isStayCompany={isStayCompany}
              setStayCompany={setStayCompany}
              control={control}
              errors={errors}
              isSubmitting={isSubmitting}
              userValue={user}
              nonFieldErrors={nonFieldErrors}
              roleValue={role}
            />
            )
          : null}
        {stage === MODAL_STAGE.second
          ? (
            <ConfirmLeave
              companyName={companyName}
              avatar={avatar}
              success={handleNext}
              onClose={onClose}
              isSubmitting={isSubmitting}
              error={nonFieldErrors}
            />
            )
          : null}

        {stage === MODAL_STAGE.third
          ? (
            <SuccessChange onClose={onCloseEnd} />
            )
          : null}
      </Form>
    </BaseModal>
  )
}

export default ChangeOwner
