import Button from '@/components/Button/Button'
import { Form } from '@/components/Form'
import Icon from '@/components/Icon/Icon'
import { BadgeTextStatus } from '@/components/Status'
import Textarea from '@/components/Textarea'
import Title from '@/components/Title'
import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import useCaseType from '../hooks'
import { TBadgeStatus } from '@/interfaces/Types'
import { useStores } from '@/hooks'
import { ICaseInRun } from '@/interfaces/Case'
import style from '../styles/case_detail.module.scss'

interface IChangeStepProps {
  status: TBadgeStatus
  handleCloseModal: () => void
  modalTitle: string
  caseInRun: ICaseInRun
  taskStatus: TBadgeStatus
  idStepOrTask: number
  updateStepData: () => Promise<void>
  runId: number
}

const CreateCommentForm = ({
  status,
  handleCloseModal,
  modalTitle,
  idStepOrTask,
  updateStepData,
  caseInRun,
  runId,
  taskStatus
}: IChangeStepProps): JSX.Element => {
  const intl = useIntl()
  const { api } = useStores()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { isCase, isTask, isChecklist } = useCaseType(caseInRun.case_type)
  const [text, setText] = useState('')

  const handleClose = (): void => {
    handleCloseModal()
    setText('')
  }

  const updateCase = async (): Promise<void> => {
    if (idStepOrTask >= 0) {
      setIsSubmitting(true)
      await api.createComment(idStepOrTask, {
        comment: text
      })
      await updateStepData?.()
      setIsSubmitting(false)
    } else {
      setIsSubmitting(false)
      handleCloseModal()
    }
  }

  const updateTask = async (): Promise<void> => {
    const params = {
      status: taskStatus,
      comment: text
    }
    await api.createCommentTask(runId, caseInRun.id, params)
    await updateStepData()
  }

  const handleSave = async (): Promise<void> => {
    setIsSubmitting(true)
    if (isCase || isChecklist) {
      await updateCase()
    }

    if (isTask) {
      await updateTask()
    }

    handleCloseModal()
    setIsSubmitting(false)
  }

  return (
    <Form onSubmit={handleSave}>
      <div className={style.modal__top}>
        <Title type='h2' className={style.modal__title}>
          {modalTitle}
        </Title>

        <Button onClick={handleClose} theme='light' circle>
          <Icon src='clear' />
        </Button>
      </div>

      <div className={style.modal__content}>
        <div className={style.modal__item}>
          <FormattedMessage id='status.label' defaultMessage='Status' />
          <BadgeTextStatus status={status} />
        </div>

        <div className={style.modal__item}>
          <Textarea
            label={intl.formatMessage({
              id: 'status.comment',
              defaultMessage: 'Comment'
            })}
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
        </div>
      </div>

      <div className={style.modal__footer}>
        <Button disabled={isSubmitting} type='submit'>
          <Icon src='checked' slot='icon-left' />
          <FormattedMessage id='common.save' defaultMessage='Save' />
        </Button>
      </div>
    </Form>
  )
}

export default CreateCommentForm
