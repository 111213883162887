import { Components } from '@garpix/garpix-web-components'
import { GxTextarea } from '@garpix/garpix-web-components-react'
import classNames from 'classnames'
import React, { forwardRef } from 'react'
import Icon from '../Icon/Icon'
import style from './styles/textarea.module.scss'

const Textarea = forwardRef<Components.GxTextarea, any>(
  ({ onBlur, onChange, ...props }, ref): React.ReactElement => {
    const { children = '', className = '', label, error = '' } = props
    const showError = Array.isArray(error) ? error[0] : error
    return (
      <>
        <GxTextarea
          ref={ref}
          {...props}
          onGx-blur={onBlur}
          onGx-input={onChange}
          className={classNames({
            [style.textarea]: true,
            [style.error]: Boolean(error),
            [className]: Boolean(className)
          })}
          label={label}
          resize='auto'
        >
          {children}
          {showError !== ''
            ? (
              <Icon src='error' slot='icon' className={style.error__icon} />
              )
            : null}
        </GxTextarea>

        {showError !== ''
          ? (
            <span className={style.error__text}>{error}</span>
            )
          : null}
      </>
    )
  }
)
export default Textarea
