import React from 'react'
import style from './styles/headermenu.module.scss'
import { RecentDropbown, HeaderNavLink } from '@/components/NavLinkButton'
import useGlobalConfig from '@/hooks/useGlobalConfig'

const HeaderMenu = (): React.ReactElement => {
  const { menus } = useGlobalConfig()
  const headerMenu = menus.header_menu

  return (
    <nav className={style.headermenu}>
      {headerMenu.map((el) => {
        if (el.recent_projects.length > 0) {
          return (
            <RecentDropbown
              key={el.id}
              title={el.title}
              recentProjects={el.recent_projects}
            />
          )
        } else {
          return (
            <HeaderNavLink key={el.id} link={el.link}>
              {el.title}
            </HeaderNavLink>
          )
        }
      })}
    </nav>
  )
}

export default HeaderMenu
