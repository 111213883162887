import React, { Suspense } from 'react'
import { FormattedMessage } from 'react-intl'
import Icon from '@/components/Icon/Icon'
import Button from '@/components/Button/Button'
import BaseModal from '@/components/Modal/Base'
import Wrapper from '@/components/Wrapper'
import { Form } from '@/components/Form'
import Title from '@/components/Title'
import AddCase from '@/components/UpdateCases/components/AddCase'
import { useForm, useFieldArray, SubmitHandler } from 'react-hook-form'
import { IRunsElement } from '@/interfaces/Runs'
import { TRunStatus } from '@/interfaces/Types'
import { IAttachment } from '@/interfaces/Attachment'
import { ICase } from '@/interfaces/Case'
import { IValue } from '@/components/Select/interfaces/AsyncSelect'
import { usePage, useStores } from '@/hooks'
import { reactHookFormErrorFormater } from '@/utils'
import { IMilestone } from '@/interfaces/Milestones'
import { Loading } from '@/components/Loading'
import style from '../styles/runitem.module.scss'

const LayDndProvider = React.lazy(
  async () =>
    await import('@minoru/react-dnd-treeview').then(
      ({ getBackendOptions, MultiBackend, DndProvider }) => {
        const DndComponent: React.FC<any> = (props) => {
          const backendOptions = getBackendOptions(props)
          const backend = MultiBackend

          return (
            <DndProvider backend={backend} options={backendOptions}>
              {props.children}
            </DndProvider>
          )
        }

        return {
          default: DndComponent
        }
      }
    )
)

export interface IFormInputs
  extends Partial<Omit<IRunsElement, 'assigned_to' | 'milestone' | 'files'>> {
  non_field_errors?: string
  title?: string
  status?: TRunStatus
  references?: string
  description?: string
  files: IAttachment[]
  plan?: number
  cases: ICase[]
  project: number
  assigned_to?: number | null
  milestone?: IValue
}

interface IProps {
  isOpen: boolean
  handleCloseModal: () => void
  idRun: number
  milestone: IMilestone | null
}

const ModalAddCases = ({
  isOpen,
  idRun,
  milestone,
  handleCloseModal
}: IProps): JSX.Element => {
  const { api } = useStores()
  const page = usePage()
  const { project_object: projectObject } = page.init_state

  const emptyProject = {
    data: {
      id: 0,
      title: '',
      status: 'CREATED',
      references: '',
      description: '',
      assigned_to: null
    },
    project: projectObject.id,
    milestone:
       milestone?.id !== undefined
         ? { value: milestone.id, label: milestone.title }
         : undefined,
    files: [],
    cases: []
  }

  const getDefaultValues = async (): Promise<IFormInputs> => {
    if (idRun !== undefined) {
      const run = await api.getRun({ id: idRun })
      const baseCases = await api.getBaseCasesInRun(idRun)
      const milestoneId = run.milestone?.id
      const assigned = run.assigned_to
      return {
        ...run,
        milestone:
          milestoneId !== undefined
            ? {
                value: milestoneId,
                label: run.milestone.title
              }
            : undefined,
        assigned_to: assigned?.id,
        files: run.files,
        cases: baseCases.results,
        project: projectObject.id
      }
    }
    return emptyProject
  }

  const {
    control,
    setError,
    handleSubmit
  } = useForm<IFormInputs>({
    defaultValues: idRun !== undefined ? getDefaultValues : emptyProject
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'cases',
    keyName: '_id'
  })

  const onSubmit: SubmitHandler<IFormInputs> = async (
    params
  ): Promise<void> => {
    try {
      await api.updateRun(idRun, params)
      handleCloseModal()
    } catch (error) {
      const errors = reactHookFormErrorFormater(params, error)
      errors.forEach((item) => {
        setError(item.key as keyof IFormInputs, {
          message: item.value
        })
      })
    }
  }

  return (
    <Suspense fallback={<Loading />}>
      <LayDndProvider>
        <BaseModal
          open={isOpen}
          onGx-after-hide={handleCloseModal}
          hideDefaultClose
          className={style.cases__modal}
          size='xl'
        >
          <Wrapper className={style.modal}>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <div className={style.modal__head}>
                <div className={style.modal__title}>
                  <Button onClick={handleCloseModal} theme='light' circle>
                    <Icon src='back' />
                  </Button>

                  <Title type='h2' className={style.modal__item}>
                    <FormattedMessage
                      id='case.add_case'
                      defaultMessage='Add Test Cases'
                    />
                  </Title>
                </div>

                <Button type='submit'>
                  <Icon src='checked' slot='icon-left' />

                  <FormattedMessage id='common.save' defaultMessage='Save' />
                </Button>
              </div>

              <AddCase selectedCase={fields} append={append} remove={remove} />
            </Form>
          </Wrapper>
        </BaseModal>
      </LayDndProvider>
    </Suspense>
  )
}

export default ModalAddCases
