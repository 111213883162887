import React from 'react'
import classNames from 'classnames'
import { Shimmer } from '@/components/Loading'
import style from './styles/loading.module.scss'

interface IProps {
  label?: string
  required?: boolean
  className?: string
}

const SelectShimmer = ({
  label,
  required = false,
  className = ''
}: IProps): React.ReactElement => {
  return (
    <div
      className={classNames({
        [style.loading]: true,
        [className]: Boolean(className)
      })}
    >
      {label !== undefined
        ? (
          <span
            className={classNames({
              [style.label]: true,
              [style.label__required]: required
            })}
          >
            {label}
          </span>
          )
        : null}
      <Shimmer className={style.shimm} size='small' />
    </div>
  )
}

export default SelectShimmer
