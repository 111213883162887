import Container from '@/components/Container'
import React from 'react'
import RunsReports from './RunsReports'
import Statistics from './Statistics'

const Reports = (): React.ReactElement => {
  return (
    <Container>
      <RunsReports />
      <Statistics />
    </Container>
  )
}

export default Reports
