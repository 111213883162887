import { IStatistics } from '@/interfaces/Runs'
import classNames from 'classnames'
import React from 'react'
import { BadgeStatus } from '../Status'
import style from './styles/groupstatuses.module.scss'

interface IProps {
  statistic: IStatistics
  className?: string
}

const GroupStatuses = ({
  statistic,
  className = ''
}: IProps): React.ReactElement => {
  return (
    <div
      className={classNames({
        [style.groupstatuses]: true,
        [className]: Boolean(className)
      })}
    >
      <BadgeStatus status='PASSED' count={statistic.passed} countOnly />
      <BadgeStatus status='BLOCKED' count={statistic.blocked} countOnly />
      <BadgeStatus status='RETEST' count={statistic.retest} countOnly />
      <BadgeStatus status='FAILED' count={statistic.failed} countOnly />
      <BadgeStatus status='UNTESTED' count={statistic.untested} countOnly />
    </div>
  )
}

export default GroupStatuses
