import React from 'react'
import style from '../styles/project.module.scss'
import Title from '@/components/Title'
import classNames from 'classnames'
import Tooltip from '@/components/Tooltip'
import { AvatarCompany } from '@/components/Avatar'
import { Radio } from '@/components/Radio'

interface IProps {
  image: string | null
  title: string
  selected: boolean
  onSelect: () => void
  maxCompanyItemTitleLength: number
}

export const CompanySelectItem = ({
  image,
  title,
  selected,
  onSelect,
  maxCompanyItemTitleLength
}: IProps): React.ReactElement => {
  const companyItemClasses = classNames(style.modal__company, {
    [style.modal__selectedcompany]: selected
  })

  const isTitleTooLong = title.length > 25
  const shortenedTitle = isTitleTooLong
    ? title.slice(0, maxCompanyItemTitleLength) + '...'
    : title

  return (
    <div className={companyItemClasses} onClick={() => onSelect()}>
      <div className={style.modal__company__info}>

        <AvatarCompany className={style.modal__company__avatar} src={image} />

        {isTitleTooLong
          ? (
            <Tooltip content={title}>
              <Title type='h3' className={style.modal__company__info__title}>{shortenedTitle}</Title>
            </Tooltip>
            )
          : (
            <Title type='h3' className={style.modal__company__info__title}>
              {title}
            </Title>
            )}
      </div>

      <Radio checked={selected} readOnly className={style.modal__radio} />
    </div>
  )
}
