import BackButton from '@/components/Button/BackButton'
import Button from '@/components/Button/Button'
import { LinkButton } from '@/components/Button/LinkButton'
import HtmlContent from '@/components/HtmlContent/HtmlContent'
import Icon from '@/components/Icon/Icon'
import LinearProgress from '@/components/LinearProgress'
import BaseModal from '@/components/Modal/Base'
import { IValue } from '@/components/Select/interfaces/AsyncSelect'
import { TaskStatus } from '@/components/Status'
import Title from '@/components/Title'
import { UserLink } from '@/components/User'
import Wrapper from '@/components/Wrapper'
import {
  CREATE_PERMISSION_LEVEL,
  EDIT_PERMISSION_LEVEL,
  RUN_STATUS,
  USER_PERMISSIONS
} from '@/const'
import { useStores } from '@/hooks'
import useModal from '@/hooks/useModal'
import { IRunsElement } from '@/interfaces/Runs'
import { TRunStatus } from '@/interfaces/Types'
import { calculatePercent, reactHookFormErrorFormater } from '@/utils'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import style from '../styles/run_detail.module.scss'
import Deadline from '@/components/Deadline'
import { useNavigate } from 'react-router-dom'
import { useCurrentPermissions } from '@/hooks/useCurrentPermissions'
import AssignToModal from './AssignToModal'
import { format, isValid, parseISO } from 'date-fns'

interface IProps {
  run: IRunsElement
  edit: string
  idProject: number
  idRun: number
  isCasesCompleted: boolean
  handleUpdate: () => void
}

export interface IFormInputs {
  id?: number
  description?: string
  project: number
  user_project?: IValue
  deadline?: string
}

const Description = ({
  run,
  edit,
  idProject,
  idRun,
  isCasesCompleted,
  handleUpdate
}: IProps): React.ReactElement => {
  const store = useStores()
  const navigate = useNavigate()
  const { isOpen, handleOpenModal, handleCloseModal } = useModal()
  const [isCloned, setCloned] = useState(false)
  const [isChangingStatus, setIsChangingStatus] = useState(false)
  const profileUrl = run.assigned_to?.profile_url
  const { setError } = useForm<IFormInputs>()

  const cloneRun = (): void => {
    setCloned(true)
    store.api
      .cloneRun({ id: idRun })
      .then((res) => navigate(res.absolute_url))
      .catch((err) => {
        const errors = reactHookFormErrorFormater({}, err)
        errors.forEach((item) => {
          setError(item.key as keyof IFormInputs, {
            message: item.value
          })
        })
      })
  }

  const handleBack = (): void => {
    navigate(-1)
  }

  const canUserAssignRun = useCurrentPermissions(
    USER_PERMISSIONS.assign_run,
    EDIT_PERMISSION_LEVEL
  )
  const canUserEditRun = useCurrentPermissions(
    USER_PERMISSIONS.run,
    CREATE_PERMISSION_LEVEL
  )

  const setRunStatusInProgress = async (): Promise<void> => {
    setIsChangingStatus(true)
    try {
      await store.api.postRunInProgress({ id: idRun })
      handleUpdate()
    } catch (error) {
      console.log('error', error)
    } finally {
      setIsChangingStatus(false)
    }
  }

  const setRunStatusCompleted = async (): Promise<void> => {
    setIsChangingStatus(true)
    try {
      await store.api.postRunCompleted({ id: idRun })
      handleUpdate()
    } catch (error) {
      console.log('error', error)
    } finally {
      setIsChangingStatus(false)
    }
  }

  const getDeadlineData = (): string => {
    if (run?.deadline !== undefined) {
      const newDate = parseISO(run.deadline)
      return isValid(newDate) ? format(newDate ?? 0, 'dd/MM/yyyy') : ''
    }
    return ''
  }

  const progress = calculatePercent(
    run.statistics.passed,
    run.statistics.count
  )

  return (
    <Wrapper className={style.description}>
      <div className={style.description__titlebox}>
        <BackButton onClick={handleBack} className={style.head__back} />

        <Title type='h1' className={style.description__title}>
          {run.title}
        </Title>

        <div className={style.description__titleright}>
          {run?.statistics?.status !== RUN_STATUS.completed &&
          canUserEditRun
            ? (
              <LinkButton href={edit} theme='light' circle>
                <Icon src='edit' />
              </LinkButton>
              )
            : null}

          {canUserEditRun
            ? (
              <Button
                theme='light'
                size='lg'
                disabled={isCloned}
                onClick={cloneRun}
              >
                <Icon slot='icon-left' src='run' />

                <FormattedMessage id='common.clone' defaultMessage='Clone' />
              </Button>
              )
            : null}

          {run?.statistics?.status === RUN_STATUS.assigned && canUserEditRun
            ? (
              <Button
                disabled={isChangingStatus}
                size='lg'
                onClick={setRunStatusInProgress}
              >
                <Icon slot='arrow_right' src='checked' />

                <FormattedMessage
                  id='button.in_progress'
                  defaultMessage='In progress'
                />
              </Button>
              )
            : null}

          {run?.statistics?.status === RUN_STATUS.in_progress &&
          canUserEditRun
            ? (
              <Button
                disabled={!isCasesCompleted || isChangingStatus}
                size='lg'
                onClick={setRunStatusCompleted}
              >
                <Icon slot='icon-left' src='checked' />

                <FormattedMessage
                  id='common.complete'
                  defaultMessage='Complete'
                />
              </Button>
              )
            : null}

          {run?.statistics?.status === RUN_STATUS.completed &&
          canUserEditRun
            ? (
              <Button theme='light' size='lg' onClick={setRunStatusInProgress}>
                <Icon slot='icon-left' src='arrow_right' />

                <FormattedMessage
                  id='runs.return'
                  defaultMessage='Return to progress'
                />
              </Button>
              )
            : null}
        </div>
      </div>

      <div className={style.description__content}>
        <div className={style.description__text}>
          <div className={style.description__grey}>
            <FormattedMessage
              id='milestones.form.description'
              defaultMessage='Description'
            />
          </div>

          <HtmlContent content={run.description} />
        </div>

        <div className={style.description__buttons}>
          {canUserAssignRun &&
          run?.statistics?.status !== RUN_STATUS.completed
            ? (
              <Button onClick={handleOpenModal}>
                <Icon src='user' slot='icon-left' />
                <FormattedMessage
                  id='button.assign_to'
                  defaultMessage='Assign to'
                />
              </Button>
              )
            : null}

          {run?.statistics?.status !== null
            ? (
              <div className={style.description__grey}>
                <FormattedMessage id='status.label' defaultMessage='Status' />

                <TaskStatus status={run?.statistics?.status as TRunStatus} />
              </div>
              )
            : null}
          {run.assigned_to != null
            ? (
              <div className={style.description__grey}>
                <FormattedMessage
                  id='select.assigned_to'
                  defaultMessage='Assigned to'
                />
                {profileUrl !== undefined
                  ? (
                    <div className={style.description__assigned}>
                      <UserLink
                        profileUrl={profileUrl}
                        avatar={
                      run.assigned_to?.avatar !== ''
                        ? run.assigned_to?.avatar
                        : null
                    }
                        size='medium'
                        className={style.projectsitem__avataritem}
                        tooltip
                        firstName={run.assigned_to?.first_name}
                        lastName={run.assigned_to?.last_name}
                        email={run.assigned_to.email}
                      />
                    </div>
                    )
                  : null}
              </div>
              )
            : null}

          {run.deadline !== undefined && run.deadline !== null
            ? (
              <Deadline date={run.deadline} />
              )
            : null}

          <BaseModal
            open={isOpen}
            onGx-after-hide={handleCloseModal}
            hideDefaultClose
            size='small'
            className={style.description__modal}
          >
            <AssignToModal
              handleCloseModal={handleCloseModal}
              handleUpdate={handleUpdate}
              runId={idRun}
              idProject={idProject}
              useFormInstance={useForm<IFormInputs>({
                defaultValues: {
                  user_project: undefined,
                  project: idProject,
                  description: '',
                  deadline: getDeadlineData()
                }
              })}
            />
          </BaseModal>
        </div>
      </div>

      <div className={style.description__complete}>
        <FormattedMessage
          id='common.successfully'
          defaultMessage='Successfully'
        />
      </div>

      <div className={style.description__progress}>
        <LinearProgress value={progress} />
      </div>
    </Wrapper>
  )
}

export default Description
