import classNames from 'classnames'
import React, { useState } from 'react'
import style from './styles/navlinkbutton.module.scss'
import { Dropdown, DropdownMenu } from '../Dropdown'
import { IRecentProject } from '@/interfaces/ObjectPage'
import { NavLink, useLocation } from 'react-router-dom'
import Icon from '../Icon/Icon'
import { FormattedMessage } from 'react-intl'
import useGlobalConfig from '@/hooks/useGlobalConfig'
import { AvatarProject } from '../Avatar'

interface IProps {
  className?: string
  title: string
  recentProjects: IRecentProject[]
}

const RecentDropbown = ({
  title,
  recentProjects,
  className = ''
}: React.PropsWithChildren<IProps>): React.ReactElement => {
  const { project_list_url: projectListUrl } = useGlobalConfig()
  const { pathname } = useLocation()
  const isActiveButton = pathname.startsWith(projectListUrl)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const handleHide = (): void => {
    setIsDropdownOpen(false)
  }

  return (
    <Dropdown
      className={style.dropdown}
      distance='10'
      open={isDropdownOpen}
      onGx-show={() => {
        setIsDropdownOpen(true)
      }}
      onGx-hide={handleHide}
    >
      <button
        slot='trigger'
        type='button'
        className={classNames({
          [style.headernavlink]: true,
          [style.dropdown__button]: true,
          [style.dropdown__active]: isActiveButton,
          [className]: Boolean(className)
        })}
      >
        <span className={style.dropdown__color}>{title}</span>

        <Icon
          src='arrow_down'
          className={classNames({
            [style.dropdown__color]: true,
            [style.dropdown__arrow]: true,
            [className]: Boolean(className)
          })}
        />
      </button>

      <DropdownMenu>
        <div className={style.drop__title}>
          <FormattedMessage
            id='dashboard.recent_projects'
            defaultMessage='Recent projects'
          />
        </div>
        {recentProjects.map((el) => (
          <NavLink
            key={el.id}
            className={({ isActive }) =>
              classNames({
                [style.headernavlink]: true,
                [style.drop__item]: true,
                [style.drop__active]: isActive,
                [className]: Boolean(className)
              })}
            onClick={handleHide}
            to={el.absolute_url}
          >
            <AvatarProject
              src={el.image}
              className={style.drop__logo}
              size='small'
            />

            {el.title}
          </NavLink>
        ))}

        <NavLink
          className={({ isActive }) =>
            classNames({
              [style.headernavlink]: true,
              [style.drop__bottom]: true,
              [style.drop__active]: isActive,
              [className]: Boolean(className)
            })}
          onClick={handleHide}
          to={projectListUrl}
          end
        >
          <Icon src='folder' className={style.drop__logo} slot='prefix' />

          <FormattedMessage
            id='dashboard.all_projects'
            defaultMessage='All projects'
          />
        </NavLink>
      </DropdownMenu>
    </Dropdown>
  )
}

export default RecentDropbown
