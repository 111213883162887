import Icon from '@/components/Icon/Icon'
import React from 'react'
import { TextTag } from '@/components/Tag'
import { ColorStatus } from '../Status'
import { Radio } from '../Radio'
import classNames from 'classnames'
import style from './styles/milestoneitem.module.scss'
interface IProps {
  id: number
  title: string
  color: string
  casesCount?: number
  runsCount?: number
  code: string
  radioName: string
  milestoneId: number
  checked: boolean
  className?: string
  onChange: (value: number) => void
}

const MilestoneItemRadio = ({
  title,
  color,
  casesCount,
  runsCount,
  code,
  radioName,
  onChange,
  milestoneId,
  checked,
  className = ''
}: IProps): React.ReactElement => {
  return (
    <>
      <Radio
        name={radioName}
        className={classNames({
          [style.milestoneradio]: true,
          [className]: Boolean(className)
        })}
        value={milestoneId}
        checked={checked}
        onGx-change={(e) => onChange(Number(e.target.value))}
      >
        <ColorStatus color={color} size='small' />

        <div className={style.milestoneradio__itemdescription}>
          <div className={style.milestoneradio__code}>{code}</div>

          <div className={style.milestoneradio__title}>
            <Icon src='flag' />
            {title}
          </div>
        </div>

        <div className={style.milestoneradio__right}>
          {runsCount !== undefined
            ? (
              <TextTag size='medium'>
                <Icon src='run' slot='icon-left' />
                {runsCount}
              </TextTag>
              )
            : null}

          {casesCount !== undefined
            ? (
              <TextTag size='medium'>
                <Icon src='test_case' slot='icon-left' />
                {casesCount}
              </TextTag>
              )
            : null}
        </div>
      </Radio>
    </>
  )
}

export default MilestoneItemRadio
