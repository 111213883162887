import { useMediaQuery } from 'react-responsive'

interface IUseResponseMedia {
  isMobile: boolean
  isTabletOrSmallLaptop: boolean
  isDesktop: boolean
  isLaptop: boolean
}

const mediaQueries = {
  isMobile: '(max-width: 599px)',
  isTabletOrSmallLaptop: '(min-width: 600px) and (max-width: 1199px)',
  isLaptop: '(min-width: 1200px) and (max-width: 1439px)',
  isDesktop: '(min-width: 1440px)'
}

const useResponsiveMedia = (): IUseResponseMedia => {
  const isDesktop = useMediaQuery({ query: mediaQueries.isDesktop })
  const isLaptop = useMediaQuery({
    query: mediaQueries.isLaptop
  })
  const isTabletOrSmallLaptop = useMediaQuery({
    query: mediaQueries.isTabletOrSmallLaptop
  })
  const isMobile = useMediaQuery({ query: mediaQueries.isMobile })

  return { isDesktop, isLaptop, isTabletOrSmallLaptop, isMobile }
}

export default useResponsiveMedia
