import React, { forwardRef } from 'react'
import classNames from 'classnames'
import style from './styles/loading.module.scss'

interface IProps {
  className?: string
}

const SpinnerWait = forwardRef<IProps, any>(
  ({ ...props }, ref): React.ReactElement => {
    const { className = '' } = props
    return (
      <div
        {...props}
        ref={ref}
        className={classNames({
          [style.spinner__wait]: true,
          [className]: Boolean(className)
        })}
      />
    )
  }
)

export default SpinnerWait
