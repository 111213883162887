import { useLocale } from '@/hooks'
import classNames from 'classnames'
import React from 'react'
import { useLocation } from 'react-router-dom'
import style from './styles/lang-switch.module.scss'

interface IProps {
  theme?: 'white' | 'light'
  className?: string
}

const LanguageSwitch = ({ theme = 'light', className = '' }: IProps): React.ReactElement => {
  const { pathname } = useLocation()
  const { locale } = useLocale()

  const changeLocale = (): void => {
    const splittedPath = pathname.split('/')
    const queryParams = location.search

    if (splittedPath[1] === 'en') {
      splittedPath.splice(1, 1)
    } else {
      splittedPath.splice(1, 0, 'en')
    }

    const newPath = splittedPath.join('/') + queryParams
    location.replace(newPath)
  }

  return (
    <div
      className={classNames({
        [style.switch]: true,
        [style[`switch--${theme}`]]: Boolean(theme),
        [className]: Boolean(className)
      })}
    >
      <input
        className={style.switch__checkbox}
        id='lang_switch'
        type='checkbox'
        defaultChecked={locale === 'en'}
        onClick={changeLocale}
      />
      <label
        htmlFor='lang_switch'
        className={style.switch__label}
        data-left='ru'
        data-right='eng'
      >
        <span className={style.switch__thumb} />
      </label>
    </div>
  )
}

export default LanguageSwitch
