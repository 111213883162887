import AuthRegLayout from '@/apps/layout/components/AuthRegLayout'
import { regImage } from '@/images'
import React from 'react'
import Registration from '../Registration'
import YandexMetrika from '@/components/YandexMetrika'

const RegistrationPage = (): React.ReactElement => {
  return (
    <>
      <YandexMetrika />
      <AuthRegLayout image={regImage}>
        <Registration />
      </AuthRegLayout>
    </>
  )
}

export default RegistrationPage
