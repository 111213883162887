import React, { useEffect, useState } from 'react'

import qs from 'qs'
import { FormattedMessage, useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import useSWR from 'swr'

import Container from '@/components/Container'
import { Form } from '@/components/Form'
import Icon from '@/components/Icon/Icon'
import { LinkButton } from '@/components/Button/LinkButton'
import ListContent from '@/components/ListContent'
import MemberSelect from '@/components/Select/MemberSelect'
import MenuItem from '@/components/MenuItem/MenuItem'
import MilestoneSelect from '@/components/Select/MilestoneSelect/MilestoneSelect'
import RunsList from '@/components/RunsList/RunsList'
import SearchInput from '@/components/Input/SearchInput/SearchInput'
import Select from '@/components/Select/Select'
import Title from '@/components/Title'
import Wrapper from '@/components/Wrapper'
import useDebounce from '@/hooks/useDebounce'
import { useObjectPage, useStores } from '@/hooks'
import useSidebar from '@/hooks/useSidebar'
import { TRunStatus } from '@/interfaces/Types'
import { scrollTop } from '@/utils'
import { suiteService } from '../../apps/SuitesCases/service'
import BaseModal from '@/components/Modal/Base'
import AssignToModal from '@/apps/RunDetail/components/AssignToModal'
import useModal from '@/hooks/useModal'
import { useForm } from 'react-hook-form'
import { IValue } from '@/components/Select/interfaces/AsyncSelect'
import { useCurrentPermissions } from '@/hooks/useCurrentPermissions'
import { CREATE_PERMISSION_LEVEL, USER_PERMISSIONS } from '@/const'
import style from './styles/runs.module.scss'

const pageSize = 10

export interface IFormInputs {
  id?: number
  description?: string
  project: number
  user_project?: IValue
  deadline?: string
}

const Runs = (): React.ReactElement => {
  const { search: searchQuery } = useLocation()
  const [runId, setRunId] = useState<number>(0)
  const defaultParams = qs.parse(searchQuery.substring(1))

  const [queryParams, setQueryParams] = useState(defaultParams)

  const [milestone, setMilestone] = useState<IValue | undefined>(
    (defaultParams?.milestone as IValue | undefined) ?? undefined
  )

  const [user, setUser] = useState<IValue | undefined>(
    (defaultParams?.user as IValue | undefined) ?? undefined
  )

  const [currentPage, setCurrentPage] = useState(
    defaultParams?.page !== undefined ? Number(defaultParams.page) : 1
  )
  const [status, setStatus] = useState<TRunStatus[]>(
    typeof defaultParams?.status === 'string'
      ? (defaultParams.status.split(',') as TRunStatus[])
      : []
  )
  const [search, setSearch] = useState(
    defaultParams?.q !== undefined && typeof defaultParams.q === 'string'
      ? defaultParams.q
      : ''
  )

  const [emptySearch, setEmptySearch] = useState<string>('')

  const { isOpen, handleOpenModal, handleCloseModal } = useModal()

  const handleOpenModalClick = (runId: number): void => {
    setRunId(runId)
    handleOpenModal()
  }

  const canCurrentUserAddRun = useCurrentPermissions(
    USER_PERMISSIONS.run,
    CREATE_PERMISSION_LEVEL
  )

  const { api } = useStores()
  const sidebar = useSidebar()
  const debouncedSearch = useDebounce(search)
  const page = useObjectPage()

  const { data, error, isLoading, mutate } = useSWR(
    {
      page: currentPage,
      page_size: pageSize,
      q: debouncedSearch,
      project: page.id,
      milestone: milestone?.value,
      status: status,
      assigned_to: user?.value,
      _key: 'getRuns'
    },
    api.getRuns,
    {
      onSuccess: () => {
        scrollTop()
      }
    }
  )

  const changeQueryParams = (name: string, value: any): void => {
    setQueryParams((queryParams) => ({ ...queryParams, [name]: value }))
  }

  const changePage = (page: number): void => {
    changeQueryParams('page', page)
    setCurrentPage(page)
  }

  const handleSearch = (value: string): void => {
    changePage(1)
    setSearch(value)
    if (value !== '') {
      changeQueryParams('q', value)
    } else {
      changeQueryParams('q', undefined)
    }
  }

  const handleStatus = (e: any): void => {
    changePage(1)
    const value = e.target.value as TRunStatus[]
    setStatus(value)
    changeQueryParams('status', value)
  }

  const handleUser = (value: IValue | null): void => {
    changePage(1)
    if (value !== null) {
      setUser(value)
      changeQueryParams('user', value)
    } else {
      setUser(undefined)
      changeQueryParams('user', undefined)
    }
  }

  const intl = useIntl()

  const urlRunCreate = sidebar?.ProjectPageRunsCreate?.absolute_url ?? ''

  const handleChangeMilestone = (value: IValue | null): void => {
    changePage(1)
    if (value !== null) {
      setMilestone(value)
      changeQueryParams('milestone', value)
    } else {
      setMilestone(undefined)
      changeQueryParams('milestone', undefined)
    }
  }

  useEffect(() => {
    suiteService.resetCurrentSuite()
  }, [])

  useEffect(() => {
    if (user === undefined) {
      setEmptySearch(
        intl.formatMessage({
          id: 'runs.no_runs',
          defaultMessage: 'No test runs has been found'
        })
      )
    } else {
      setEmptySearch(
        intl.formatMessage({
          id: 'runs.no_runs.user',
          defaultMessage: 'There are no runs assigned to this user'
        })
      )
    }
  }, [user])

  useEffect(() => {
    const newParamsString = qs.stringify(queryParams, { arrayFormat: 'comma' })
    history.replaceState(
      {},
      '',
      newParamsString.length > 0 ? `?${newParamsString}` : ''
    )
  }, [queryParams])

  return (
    <Container>
      <Wrapper className={style.runs}>
        <div className={style.runs__top}>
          <Title type='h1' className={style.runs__title}>
            <FormattedMessage id='titles.runs' defaultMessage='Runs' />
          </Title>

          {canCurrentUserAddRun
            ? (
              <LinkButton href={urlRunCreate} className={style.runs__addproject}>
                <Icon src='plus' slot='icon-left' />

                <FormattedMessage
                  id='runs.add_run'
                  defaultMessage='Add milestone'
                />
              </LinkButton>
              )
            : null}
        </div>

        <Form onSubmit={undefined}>
          <div className={style.runs__form}>
            <div className={style.runs__filter}>
              <MilestoneSelect
                handleChange={handleChangeMilestone}
                projectId={page.id}
                isClearable
                defaultMilestone={milestone}
              />
            </div>

            <div className={style.runs__filter}>
              <MemberSelect
                handleChange={(e) => handleUser(e)}
                isClearable
                projectId={page.id}
                defaultUser={user}
                runPermissionsFilter
              />
            </div>

            <Select
              className={style.runs__filter}
              placeholder={intl.formatMessage({
                id: 'input.placeholder',
                defaultMessage: 'Select'
              })}
              label={intl.formatMessage({
                id: 'runs.status',
                defaultMessage: 'Status'
              })}
              onChange={handleStatus}
              multiple
              value={status}
            >
              <MenuItem value='ASSIGNED'>
                <FormattedMessage
                  id='status.task.assigned'
                  defaultMessage='Assigned'
                />
              </MenuItem>

              <MenuItem value='CREATED'>
                <FormattedMessage
                  id='status.task.created'
                  defaultMessage='Created'
                />
              </MenuItem>

              <MenuItem value='INPROCESS'>
                <FormattedMessage
                  id='status.task.inprocess'
                  defaultMessage='In process'
                />
              </MenuItem>

              <MenuItem value='COMPLETED'>
                <FormattedMessage
                  id='status.task.completed'
                  defaultMessage='Completed'
                />
              </MenuItem>
            </Select>

            <SearchInput
              handleChange={handleSearch}
              value={search}
              className={style.runs__bigfilter}
              placeholder={intl.formatMessage({
                id: 'runs.search',
                defaultMessage: 'Search runs'
              })}
            />
          </div>
        </Form>

        <ListContent
          isLoading={isLoading}
          error={error}
          hasData={data !== undefined && data?.count > 0}
          emptyListIcon='file'
          emptyListText={emptySearch}
          emptyListButton={intl.formatMessage({
            id: 'runs.add_run',
            defaultMessage: 'Add testrun'
          })}
          href={urlRunCreate}
        >
          {data !== undefined
            ? (
              <RunsList
                data={data}
                currentPage={currentPage}
                pageSize={pageSize}
                setPage={changePage}
                handleUpdate={mutate}
                handleOpenModal={handleOpenModalClick}
              />
              )
            : null}
        </ListContent>

        <BaseModal
          open={isOpen}
          onGx-after-hide={handleCloseModal}
          hideDefaultClose
          size='small'
          className={style.runs__modal}
        >
          <AssignToModal
            handleCloseModal={handleCloseModal}
            handleUpdate={mutate}
            runId={runId}
            idProject={page.id}
            useFormInstance={useForm<IFormInputs>({
              defaultValues: {
                user_project: undefined,
                project: page.id,
                description: '',
                deadline: ''
              }
            })}
          />
        </BaseModal>
      </Wrapper>
    </Container>
  )
}

export default Runs
