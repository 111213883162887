import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { useObjectPage, useResponsiveMedia, useStores } from '@/hooks'
import PasswordInput from '@/components/Input/PasswordInput'
import Input from '@/components/Input/Input'
import classNames from 'classnames'
import TaskField from './TaskField'
import JiraProject from './JiraProject'
import { IValue } from '@/components/Select/interfaces/AsyncSelect'
import useSWR, { KeyedMutator } from 'swr'
import IConnectionFormData, { IOptions } from '../interfaces'
import Button from '@/components/Button/Button'
import Icon from '@/components/Icon/Icon'
import { IHealthCheck, IProject } from '@/interfaces/Project'
import { canShowAdditionalFields } from '../utils'
import { Form } from '@/components/Form'
import style from '../styles/jira.module.scss'

interface IJiraFormFields {
  setJiraIntegrationByParam: (enable: boolean) => Promise<void>
  healthCheck?: IHealthCheck
  project?: IProject
  projectMutation: KeyedMutator<IProject>
}

const JiraFormFields = ({
  setJiraIntegrationByParam,
  healthCheck,
  project,
  projectMutation
}: IJiraFormFields): JSX.Element => {
  const intl = useIntl()
  const {
    reset,
    register,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useFormContext<IConnectionFormData>()
  const { isMobile } = useResponsiveMedia()
  const { id } = useObjectPage()
  const { api } = useStores()

  const [isProjectSelected, setIsProjectSelected] = useState(false)
  const [jiraProjectId, setJiraProjectId] = useState('')
  const [selectedProject, setSelectedProject] =
    useState<IValue | undefined>(undefined)

  const show = canShowAdditionalFields(healthCheck, project)
  const { data: currentProjectData } = useSWR({ id }, api.getProject)

  const handleClearClick = async (): Promise<void> => {
    const dataToClear = {
      jira_enable: false,
      jira_project: '',
      jira_api_url: '',
      jira_server_url: '',
      jira_token: '',
      jira_user: '',
      jira_type_tasks: []
    }
    reset({ ...dataToClear })
    await api.postJiraSettings(id, dataToClear)
    await projectMutation()
    setIsProjectSelected(false)
  }

  const onSubmit = async (): Promise<void> => {
    await setJiraIntegrationByParam(true)
    await projectMutation()
  }

  const handleProjectChange = (selectedOption: {
    value: number
    label: string
    key: string
  }): void => {
    setIsProjectSelected(true)
    setJiraProjectId(selectedOption.value.toString())
    setValue('jira_id', selectedOption.value)
    setValue('jira_key', selectedOption.key)
    setValue('jira_project', selectedOption.value)
    setSelectedProject(selectedOption)
  }

  const handleTaskTypeChange = (selected: IOptions[]): void => {
    const selectedValues = selected.map((option) => option.id)
    setValue('jira_type_tasks', selectedValues)
  }

  return (
    <>
      <hr className={style.line__horizontal} />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className={style.form}>
          <div className={style.form__block}>
            <Input
              required
              className={style.formsection__field}
              label={intl.formatMessage({
                id: 'integrations.jira.weburl',
                defaultMessage: 'Web URL'
              })}
              {...register('jira_server_url', { required: true })}
              error={errors.jira_server_url?.message}
            />

            <Input
              required
              className={style.formsection__field}
              label={intl.formatMessage({
                id: 'integrations.jira.jira_api_url',
                defaultMessage: 'JIRA API URL'
              })}
              {...register('jira_api_url', { required: true })}
              error={errors.jira_api_url?.message}
            />

            <Input
              required
              className={style.formsection__field}
              label={intl.formatMessage({
                id: 'integrations.jira.username_or_email',
                defaultMessage: 'Username or Email'
              })}
              {...register('jira_user', { required: true })}
              error={errors.jira_user?.message}
            />

            <PasswordInput
              required
              label={intl.formatMessage({
                id: 'integrations.jira.pwd_or_token',
                defaultMessage: 'Password or API token'
              })}
              {...register('jira_token', { required: true })}
              error={errors.jira_token?.message}
            />
          </div>

          <div className={style.line__box}>
            <div className={style.line__vertical} />
          </div>

          <div className={style.form__block}>
            <div
              className={classNames({
                [style.disabled]: !show
              })}
            >
              <div className={style.form__field}>
                {show
                  ? (
                    <JiraProject
                      setIsProjectSelected={setIsProjectSelected}
                      value={selectedProject}
                      handleChange={handleProjectChange}
                    />
                    )
                  : (
                    <Input
                      required
                      disabled
                      className={style.formsection__field}
                      label={intl.formatMessage({
                        id: 'case.form.project',
                        defaultMessage: 'Project'
                      })}
                    />
                    )}
              </div>

              {isProjectSelected
                ? (
                  <div className={style.form__field}>
                    <TaskField
                      currentProjectData={currentProjectData}
                      jiraProjectId={jiraProjectId}
                      handleChange={handleTaskTypeChange}
                    />
                  </div>
                  )
                : null}
            </div>

            <div className={style.form__btns}>
              <div className={style.btns}>
                <Button
                  size={isMobile ? 'sm' : 'med'}
                  theme='light'
                  onClick={handleClearClick}
                >
                  <Icon src='broom' slot='icon-left' />
                  <FormattedMessage id='common.clear' defaultMessage='Clear' />
                </Button>

                <Button size={isMobile ? 'sm' : 'med'} type='submit'>
                  <Icon src='checked' slot='icon-left' />
                  <FormattedMessage id='common.save' defaultMessage='Save' />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </>
  )
}

export default JiraFormFields
