import React, { useEffect, useState } from 'react'
import { useObjectPage, useStores } from '@/hooks'
import Wrapper from '@/components/Wrapper'
import Title from '@/components/Title'
import Icon from '@/components/Icon/Icon'
import { FormattedMessage, useIntl } from 'react-intl'
import MemberSelect from '@/components/Select/MemberSelect'
import useSWR from 'swr'
import ListContent from '@/components/ListContent'
import { LinkButton } from '@/components/Button/LinkButton'
import useSidebar from '@/hooks/useSidebar'
import RunsList from '@/components/RunsList/RunsList'
import SearchInput from '@/components/Input/SearchInput/SearchInput'
import StatusSelect from '@/components/Select/StatusSelect'
import { IMilestonePage } from '@/interfaces/ObjectPage'
import useDebounce from '@/hooks/useDebounce'
import { useCurrentPermissions } from '@/hooks/useCurrentPermissions'
import { EDIT_PERMISSION_LEVEL, USER_PERMISSIONS } from '@/const'
import AssignToModal from '@/apps/RunDetail/components/AssignToModal'
import { useForm } from 'react-hook-form'
import { IFormInputs } from '@/apps/ProjectDetail/components/Runs'
import BaseModal from '@/components/Modal/Base'
import useModal from '@/hooks/useModal'
import style from '../styles/milestone_detail.module.scss'

interface IProps {
  id: number
}

const Runs = ({ id }: IProps): React.ReactElement => {
  const store = useStores()
  const subpages = useSidebar()
  const obj = useObjectPage<IMilestonePage>()

  const [status, setStatus] = useState<string>('')
  const [emptySearch, setEmptySearch] = useState<string>('')
  const [user, setUser] = useState<number>()
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search)
  const [page, setPage] = useState(1)
  const [runId, setRunId] = useState<number>(0)
  const { isOpen, handleOpenModal, handleCloseModal } = useModal()

  const pageSize = 10

  const canUserAddRun = useCurrentPermissions(
    USER_PERMISSIONS.run,
    EDIT_PERMISSION_LEVEL
  )

  const { data, error, isLoading, mutate } = useSWR(
    {
      page: page,
      page_size: pageSize,
      q: debouncedSearch,
      status: status !== '' ? status : undefined,
      assigned_to: user,
      milestone: id,
      _key: 'runs'
    },
    store.api.getRuns
  )

  const handleSearch = (value: string): void => {
    setSearch(value)
    setPage(1)
  }

  const intl = useIntl()

  const handleChangeStatus = (e): void => {
    setStatus(e.target.value)
    setPage(1)
  }

  const handleChangeUser = (e): void => {
    if (e !== null) {
      setUser(e.value)
    } else {
      setUser(undefined)
    }
    setPage(1)
  }

  const handleOpenModalClick = (runId: number): void => {
    setRunId(runId)
    handleOpenModal()
  }

  useEffect(() => {
    if (user === undefined) {
      setEmptySearch(
        intl.formatMessage({
          id: 'runs.no_runs',
          defaultMessage: 'No test runs has been found'
        })
      )
    } else {
      setEmptySearch(
        intl.formatMessage({
          id: 'runs.no_runs.user',
          defaultMessage: 'There are no runs assigned to this user'
        })
      )
    }
  }, [user])

  const urlRunCreate = subpages?.ProjectPageRunsCreate?.absolute_url ?? ''

  return (
    <Wrapper className={style.runs}>
      <div className={style.runs__top}>
        <Title type='h1' className={style.runs__title}>
          <FormattedMessage id='titles.runs' defaultMessage='Test Runs' />
        </Title>

        {canUserAddRun
          ? (
            <LinkButton
              href={urlRunCreate}
              circle
              className={style.runs__addproject}
            >
              <Icon src='plus' />
            </LinkButton>
            )
          : null}
      </div>

      <div className={style.runs__form}>
        <MemberSelect
          handleChange={handleChangeUser}
          projectId={obj.project}
          isClearable
          runPermissionsFilter
        />

        <StatusSelect handleChange={handleChangeStatus} value={status} />

        <SearchInput
          handleChange={handleSearch}
          value={search}
          placeholder={intl.formatMessage({
            id: 'runs.search',
            defaultMessage: 'Search runs'
          })}
        />
      </div>

      <ListContent
        isLoading={isLoading}
        error={error}
        hasData={data !== undefined && data?.count > 0}
        emptyListIcon='file'
        className={style.runs__empty}
        emptyListText={emptySearch}
        emptyListButton={
          canUserAddRun
            ? intl.formatMessage({
              id: 'runs.add_run',
              defaultMessage: 'Add testrun'
            })
            : undefined
        }
        href={urlRunCreate}
      >
        {data !== undefined
          ? (
            <RunsList
              data={data}
              currentPage={page}
              pageSize={pageSize}
              setPage={setPage}
              handleUpdate={mutate}
              handleOpenModal={handleOpenModalClick}
            />
            )
          : null}
      </ListContent>
      <BaseModal
        open={isOpen}
        onGx-after-hide={handleCloseModal}
        hideDefaultClose
        size='small'
        className={style.runs__modal}
      >
        <AssignToModal
          handleCloseModal={handleCloseModal}
          handleUpdate={mutate}
          idProject={obj.project}
          runId={runId}
          useFormInstance={useForm<IFormInputs>({
            defaultValues: {
              user_project: undefined,
              project: obj.project,
              description: '',
              deadline: ''
            }
          })}
        />
      </BaseModal>
    </Wrapper>
  )
}

export default Runs
