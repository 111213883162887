import Input from '@/components/Input/Input'
import MenuItem from '@/components/MenuItem/MenuItem'
import PrioritySelect from '@/components/Select/PrioritySelect/PrioritySelect'
import Select from '@/components/Select/Select'
import SuitesSelect from '@/components/Select/SuitesSelect'
import Textarea from '@/components/Textarea'
import Title from '@/components/Title'
import Wrapper from '@/components/Wrapper'
import { CASE_TYPES, EDIT_PERMISSION_LEVEL, USER_PERMISSIONS } from '@/const'
import { errorFolder } from '@/images'
import React from 'react'
import {
  Control,
  Controller,
  FieldErrorsImpl,
  UseFormRegister
} from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import style from '../styles/add_case.module.scss'
import Tags from './Tags'
import { AICases } from './AICases'
import { TCaseType } from '@/interfaces/Types'
import { IAISuccessProps } from '../interfaces'
import RadioPlate from '@/components/Radio/RadioPlate'
import { useCurrentPermissions } from '@/hooks/useCurrentPermissions'

interface IProps {
  project: {
    id: number
    title: string
  }
  control: Control<any, any>
  register: UseFormRegister<any>
  errors: Partial<FieldErrorsImpl>
  isUpdate: boolean
  handleAISuccess: (data: IAISuccessProps) => void
  caseType: TCaseType
}

const CaseForm = ({
  project,
  control,
  register,
  errors,
  isUpdate,
  handleAISuccess,
  caseType
}: IProps): React.ReactElement => {
  const intl = useIntl()

  const canGenAiCase = useCurrentPermissions(
    USER_PERMISSIONS.ai_generation,
    EDIT_PERMISSION_LEVEL
  )

  const validateMaxLength = (value: string): boolean | string => {
    if (value.length > 128) {
      return intl.formatMessage({
        id: 'milestones.form.name.error',
        defaultMessage: 'Maximum character length - 128'
      })
    }
    return true
  }

  return (
    <Wrapper className={style.form}>
      <div className={style.form__left}>
        <Title type='h2'>
          <FormattedMessage id='titles.test_case' defaultMessage='Test case' />
        </Title>
        <div className={style.form__type}>
          <Controller
            control={control}
            name='case_type'
            render={({ field: { onChange, value } }) => {
              return (
                <RadioPlate
                  value={CASE_TYPES.case}
                  name='case_type'
                  onGx-change={(e) => onChange(e.target.value)}
                  checked={value === CASE_TYPES.case}
                  disabled={isUpdate}
                  icon='test_case'
                >
                  <FormattedMessage id='input.case' defaultMessage='Case' />
                </RadioPlate>
              )
            }}
          />

          <Controller
            control={control}
            name='case_type'
            render={({ field: { onChange, value } }) => {
              return (
                <RadioPlate
                  value={CASE_TYPES.checklist}
                  name='case_type'
                  onGx-change={(e) => onChange(e.target.value)}
                  checked={value === CASE_TYPES.checklist}
                  disabled={isUpdate}
                  icon='test_check'
                >
                  <FormattedMessage
                    id='input.checklist'
                    defaultMessage='Checklist'
                  />
                </RadioPlate>
              )
            }}
          />

          <Controller
            control={control}
            name='case_type'
            render={({ field: { onChange, value } }) => {
              return (
                <RadioPlate
                  value={CASE_TYPES.task}
                  name='case_type'
                  onGx-change={(e) => onChange(e.target.value)}
                  checked={value === CASE_TYPES.task}
                  disabled={isUpdate}
                  icon='test_task'
                >
                  <FormattedMessage id='input.task' defaultMessage='Task' />
                </RadioPlate>
              )
            }}
          />
        </div>

        {canGenAiCase && caseType !== CASE_TYPES.task
          ? (
            <AICases caseType={caseType} handleSuccess={handleAISuccess} />
            )
          : null}

        <Select
          disabled
          className={style.form__block}
          placeholder={intl.formatMessage({
            id: 'input.placeholder',
            defaultMessage: 'Choose'
          })}
          label={intl.formatMessage({
            id: 'case.form.project',
            defaultMessage: 'Project'
          })}
          required
          value={project.title}
        >
          <MenuItem value={project.title} className={style.project_item}>
            <img
              src={errorFolder}
              width={30}
              height={30}
              alt=''
              className={style.project_item__icon}
            />
            {project.title}
          </MenuItem>
        </Select>

        <Controller
          control={control}
          name='suite'
          render={({ field: { onChange, value } }) => (
            <SuitesSelect
              value={value}
              projectId={project.id}
              label={intl.formatMessage({
                id: 'common.suite',
                defaultMessage: 'Suite'
              })}
              handleChange={onChange}
              isClearable
              labelClassName={style.form__block}
            />
          )}
        />

        <Controller
          name='title'
          control={control}
          defaultValue=''
          rules={{
            required: true,
            validate: validateMaxLength
          }}
          render={({ field }) => (
            <Input
              label={intl.formatMessage({
                id: 'milestones.form.name',
                defaultMessage: 'Name'
              })}
              required
              className={style.form__block}
              {...field}
              error={errors.title?.message ?? ''}
            />
          )}
        />

        <Controller
          control={control}
          name='priority'
          render={({ field: { onChange, value } }) => (
            <PrioritySelect
              value={value}
              required
              clearable={false}
              handleChange={onChange}
              className={style.form__block}
            />
          )}
        />
        <Textarea
          label={intl.formatMessage({
            id: 'case.form.preconditions',
            defaultMessage: 'Preconditions'
          })}
          {...register('preconditions')}
          error={errors?.preconditions?.message ?? ''}
        />
      </div>

      <div className={style.form__right}>
        <Controller
          control={control}
          name='tags'
          render={({ field: { onChange, value } }) => {
            return (
              <Tags tags={value} onChange={onChange} project={project.id} />
            )
          }}
        />
      </div>
    </Wrapper>
  )
}

export default CaseForm
