import Wrapper from '@/components/Wrapper'
import useSWR from 'swr'
import React, { useState } from 'react'
import Title from '@/components/Title'
import SearchInput from '@/components/Input/SearchInput/SearchInput'
import { FormattedMessage, useIntl } from 'react-intl'
import Pagination from '@/components/Pagination'
import { useStores } from '@/hooks'
import { Accordion } from '@/components/Accordion'
import HtmlContent from '@/components/HtmlContent/HtmlContent'
import ListContent from '@/components/ListContent'
import { scrollTop } from '@/utils'
import style from '../style/report_detail.module.scss'

const FaqList = (): React.ReactElement => {
  const { api } = useStores()
  const intl = useIntl()
  const [search, setSearch] = useState<string>('')
  const [page, setPage] = useState<number>(1)
  const pageSize = 7

  const { data } = useSWR(
    { page: page, page_size: pageSize, search: search, _key: 'getFaq' },
    api.getFaq,
    {
      onSuccess: () => {
        scrollTop()
      }
    }
  )

  const handleSearch = (value: string): void => {
    setSearch(value)
    setPage(1)
  }
  const hasData = data !== undefined && data.results.length > 0

  return (
    <Wrapper className={style.list}>
      <Title type='h1'>
        <FormattedMessage id='faq.title' defaultMessage='FAQ' />
      </Title>

      <SearchInput
        value={search}
        handleChange={handleSearch}
        placeholder={intl.formatMessage({
          id: 'faq.search',
          defaultMessage: 'Search question'
        })}
        className={style.modal__search}
      />
      <ListContent
        isLoading={false}
        error={undefined}
        className={style.cases__empty}
        hasData={hasData}
        emptyListIcon='support'
        emptyListText={intl.formatMessage({
          id: 'faq.search.empty',
          defaultMessage: 'No questions found'
        })}
      >
        {data !== undefined
          ? (
            <>
              {data.results.map((el) => {
                return (
                  <Accordion
                    key={el.id}
                    className={style.list__accordion}
                    head={
                      <span className={style.list__head}>
                        {el.title}
                      </span>
                  }
                  >
                    <HtmlContent
                      className={style.list__body}
                      content={el.answer}
                    />
                  </Accordion>
                )
              })}
            </>
            )
          : null}
      </ListContent>

      <Pagination
        currentPage={page}
        total={data?.count ?? 0}
        pageSize={pageSize}
        handleChange={setPage}
      />
    </Wrapper>
  )
}

export default FaqList
