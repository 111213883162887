import React, { useState } from 'react'
import useSWR from 'swr'
import { useObjectPage, useStores } from '@/hooks'
import Container from '@/components/Container'
import Wrapper from '@/components/Wrapper'
import Title from '@/components/Title'
import Icon from '@/components/Icon/Icon'
import style from './styles/companies.module.scss'
import { FormattedMessage } from 'react-intl'
import { LinkButton } from '@/components/Button/LinkButton'
import EmptyCompanies from './components/EmptyCompanies'
import CompaniesList from './components/CompaniesList'
import useCurrentUser from '@/hooks/useCurrentUser'
import useGlobalConfig from '@/hooks/useGlobalConfig'
import { LoaderCompany } from './loaders'

const pageSize = 10

const Companies = (): React.ReactElement => {
  const obj = useGlobalConfig()
  const store = useStores()
  const objectPage = useObjectPage()
  const { currentUser } = useCurrentUser()
  const userId = currentUser?.id ?? 0
  const [page, setPage] = useState(1)
  const { data, isLoading, mutate } = useSWR(
    { page: page, page_size: pageSize, _key: 'getCompanies' },
    store.api.getCompanies
  )

  const handleChange = (): void => {
    mutate().catch((err) => console.log('err', err))
  }

  return (
    <Container>
      <Wrapper className={style.company}>
        <div className={style.company__top}>
          <Title type='h1' className={style.company__title}>
            {objectPage.title}
          </Title>

          <LinkButton href={obj.create_company_url} className={style.company__addcompany}>
            <Icon src='plus' slot='icon-left' />
            <FormattedMessage
              id='companies.add_company'
              defaultMessage='Add company'
            />
          </LinkButton>
        </div>

        <div className={style.company__content}>
          {isLoading
            ? (
              <LoaderCompany countShimmers={pageSize} />
              )
            : (
              <>
                {data !== undefined && data.count > 0
                  ? (
                    <CompaniesList
                      data={data}
                      currentPage={page}
                      pageSize={pageSize}
                      setPage={setPage}
                      onChange={handleChange}
                      userId={userId}
                    />
                    )
                  : (
                    <EmptyCompanies />
                    )}
              </>
              )}
        </div>
      </Wrapper>
    </Container>
  )
}

export default Companies
