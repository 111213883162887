import style from '../styles/registration.module.scss'

import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useForm } from 'react-hook-form'
import Input from '@/components/Input/Input'
import Button from '@/components/Button/Button'
import { usePage, useStores } from '@/hooks'
import { reactHookFormErrorFormater } from '@/utils'
import { Form } from '@/components/Form'
import Title from '@/components/Title'

interface IProps {
  nextStage: () => void
  email?: string
}

export interface IFormInputs {
  email?: string
  password?: string
  password_2?: string
  non_field_errors?: string
}

const SetPassword = ({ nextStage, email }: IProps): React.ReactElement => {
  const intl = useIntl()
  const { api } = useStores()
  const { init_state: initState } = usePage()

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting }
  } = useForm<IFormInputs>({
    defaultValues: {
      password: '',
      password_2: '',
      email: email
    }
  })

  const onSubmit = async (params): Promise<void> => {
    try {
      const res = await api.registerSetPassword({
        email: params.email,
        password: params.password,
        password_2: params.password_2,
        phone: initState.user_session.phone
      })
      nextStage()
      return res
    } catch (error) {
      if (error.response.data.email !== undefined) {
        setError('non_field_errors', {
          type: '400',
          message: `Email: ${String(error.response.data.email)}`
        })
      }
      const errors = reactHookFormErrorFormater(params, error)
      errors.forEach((item) => {
        setError(item.key as keyof IFormInputs, {
          type: '400',
          message: item.value
        })
      })
    }
  }
  const nonFieldErrors = errors?.non_field_errors?.message ?? ''
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Title type='h1' className={style.form__title}>
        <FormattedMessage
          id='registration.password.title'
          defaultMessage='Account password'
        />
      </Title>

      <div className={style.form}>
        <p className={style.form__text}>
          <FormattedMessage
            id='registration.password.text'
            defaultMessage='Please create a password. Strong password includes numbers, letters, and punctuation marks.'
          />
        </p>

        <Input
          type='password'
          className={style.form__input}
          label={intl.formatMessage({
            id: 'registration.password.password',
            defaultMessage: 'Password'
          })}
          required
          {...register('password', { required: true })}
          error={errors?.password?.message ?? ''}
        />

        <Input
          type='password'
          className={style.form__input}
          label={intl.formatMessage({
            id: 'registration.password.repeat',
            defaultMessage: 'Repeat password'
          })}
          required
          {...register('password_2', { required: true })}
          error={errors?.password_2?.message ?? ''}
        />

        {nonFieldErrors !== ''
          ? (
            <div className={style.form__error}>{nonFieldErrors}</div>
            )
          : null}
      </div>

      <div className={style.form__bottom}>
        <Button disabled={isSubmitting} type='submit' size='lg'>
          <FormattedMessage
            id='registration.password.save'
            defaultMessage='Save'
          />
        </Button>
      </div>
    </Form>
  )
}

export default SetPassword
