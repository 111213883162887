import React, { useEffect, useState } from 'react'
import JiraIntegrationHead from './components/JiraIntegrationHead'
import { FormProvider, useForm } from 'react-hook-form'
import { useObjectPage, useStores } from '@/hooks'
import JiraFormFields from './components/JiraFormFields'
import useSWR from 'swr'
import SwitchButton from '@/components/Button/SwitchButton'
import { useIntl } from 'react-intl'
import { useIntegrationStatus } from '../Integrations/context'
import { yupResolver } from '@hookform/resolvers/yup'
import { getStatusFromMappings } from './utils'
import { jiraValidationSchema } from './validations'
import { ControlledAccordion } from '@/components/Accordion'
import styles from '../Integrations/styles/card.module.scss'

const JiraIntegration = (): JSX.Element => {
  const intl = useIntl()

  const { jiraIntegrationStatus, setJiraIntegrationStatus } =
    useIntegrationStatus()

  const formMethods = useForm({
    resolver: yupResolver(jiraValidationSchema)
  })

  const { id } = useObjectPage()
  const { api } = useStores()
  const [toggle, setToggle] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const errorMessage = intl.formatMessage({
    id: 'integrations.jira.failed',
    defaultMessage: 'Synchronization failed'
  })

  const {
    data: healthCheck,
    isLoading: isLoadingHealthCheck,
    mutate: mutateHealthStatus
  } = useSWR([id], api.getJiraHealthCheck)

  const {
    data: project,
    isLoading: isLoadingGetProject,
    mutate: projectMutation
  } = useSWR(
    {
      id: id,
      _key: 'getProject'
    },
    api.getProject
  )

  const setJiraIntegrationByParam = async (
    enable: boolean,
    fromToggle: boolean = false
  ): Promise<void> => {
    if (fromToggle) {
      await api.postJiraSettings(id, { jira_enable: enable })
    } else {
      const requiredData = {
        ...formMethods.getValues(),
        jira_enable: enable
      }
      await api.postJiraSettings(id, requiredData)
    }
    await mutateHealthStatus()
  }

  const handleToggle = async (): Promise<void> => {
    await setJiraIntegrationByParam(!toggle, true)
    setToggle(!toggle)
    await mutateHealthStatus()
    await projectMutation()
  }

  const handleOpenAccordion = async (): Promise<void> => {
    setIsOpen(true)
  }

  useEffect(() => {
    void api.getProject({ id: id }).then((project) => {
      formMethods.reset({
        jira_api_url: project.jira_api_url,
        jira_server_url: project.jira_server_url,
        jira_user: project.jira_user,
        jira_token: project.jira_token,
        jira_type_tasks: project.jira_type_tasks
      })
      setToggle(project?.jira_enable)
    })
  }, [])

  useEffect(() => {
    if (healthCheck?.result !== undefined && project !== undefined) {
      const { result } = healthCheck
      const status = getStatusFromMappings(project, result)
      setJiraIntegrationStatus(status)
      if (status === true) setToggle(status)
      if (status === null) setToggle(false)
    }
  }, [healthCheck, project, setJiraIntegrationStatus])

  return (
    <ControlledAccordion
      className={styles.card}
      setIsOpen={setIsOpen}
      isOpen={isOpen}
      head={<JiraIntegrationHead />}
      headRight={
        <SwitchButton
          disabled={isLoadingHealthCheck || isLoadingGetProject}
          handleChange={formMethods.handleSubmit(
            handleToggle,
            handleOpenAccordion
          )}
          toggled={toggle}
          textColor='red'
          label={jiraIntegrationStatus === false ? errorMessage : null}
        />
      }
    >
      <FormProvider {...formMethods}>
        <JiraFormFields
          project={project}
          healthCheck={healthCheck}
          setJiraIntegrationByParam={setJiraIntegrationByParam}
          projectMutation={projectMutation}
        />
      </FormProvider>
    </ControlledAccordion>
  )
}

export default JiraIntegration
