import React from 'react'
import style from '../../styles/update_cases.module.scss'
import { ICase, ICaseInRun } from '@/interfaces/Case'
import CaseItem from '@/components/CaseItem'

interface IProps {
  data: ICase[] | ICaseInRun[]
}

const CasesList = ({
  data
}: IProps): React.ReactElement => {
  return (
    <div className={style.caseslist__list}>
      {data.map((el) => {
        return (
          <CaseItem
            key={el.id}
            link={el.absolute_url}
            title={el.title}
            priority={el.priority}
            tags={el.tags}
            id={el.id}
            code={el.code}
            status='approved'
          />
        )
      })}
    </div>
  )
}

export default CasesList
