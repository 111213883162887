import classNames from 'classnames'
import React from 'react'
import style from './styles/progress.module.scss'

interface IProps {
  value: number
  variant?: 'default' | 'transparent'
  className?: string
}

const LinearProgress = ({
  value,
  variant = 'default',
  className = ''
}: IProps): React.ReactElement => {
  return (
    <div
      className={classNames({
        [style.progress]: true,
        [style[`progress--${variant}`]]: true,
        [className]: Boolean(className)
      })}
    >
      <div className={style.progress__thumb} style={{ width: `${value}%` }} />
      <span className={style.progress__value}>{value}%</span>
    </div>
  )
}

export default LinearProgress
