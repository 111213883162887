import React from 'react'
import style from './styles/notification_item.module.scss'
import { INotifiesApproveAllCasesData } from '@/interfaces/Notifies'
import { ITimeSince } from '@/interfaces/Utils'
import NotifyTime from './components/NotifyTime'
import { FormattedMessage } from 'react-intl'
import Link from '@/components/Link'
import Tooltip from '../Tooltip'
import { AvatarUser } from '../Avatar'

interface IProps {
  data: INotifiesApproveAllCasesData
  time: ITimeSince
}

const NotifyAllCases = ({ time, data }: IProps): React.ReactElement => {
  const { cases_count: casesCount, project, author } = data

  return (
    <>
      <Tooltip content={`${author.first_name} ${author.last_name}`}>
        <AvatarUser src={author.avatar} className={style.item__avatar} />
      </Tooltip>

      <div className={style.item__content}>
        <p className={style.info}>
          <FormattedMessage
            id='notifications.item.approved_all'
            values={{
              link: (
                <Link to={project.absolute_url} className={style.item__text}>
                  {project.title}
                </Link>
              )
            }}
            defaultMessage='Approved test case in {link} project'
          />
        </p>

        <div className={style.run}>
          <FormattedMessage
            id='notifications.item.approve_all_cases'
            defaultMessage='{cases_count, plural, one {# case} few {# cases} many {# cases} other {# cases}} approved'
            values={{ cases_count: casesCount }}
          />
        </div>

        <NotifyTime time={time} />
      </div>
    </>
  )
}

export default NotifyAllCases
