import React from 'react'
import classNames from 'classnames'
import style from './tag.module.scss'
import DeleteButton from '../Button/DeleteButton'
import { TagStyle } from '@/interfaces/TagInterfaces'

export type TTagSize = 'xs' | 'small' | 'medium' | 'large'
export type TTagTheme = 'common' | 'hollow'

interface IProps {
  className?: string
  size?: TTagSize
  theme?: TTagTheme
  children: React.ReactNode
  isDeletable?: boolean
  onDelete?: () => void
  onClick?: () => void
  tagStyle?: TagStyle
}

const TextTag = ({
  children,
  size = 'medium',
  className = '',
  isDeletable = false,
  onDelete,
  onClick,
  theme = 'common',
  tagStyle = TagStyle.Default
}: React.PropsWithChildren<IProps>): React.ReactElement => {
  const CustomTag = onClick !== undefined ? 'button' : 'div'

  return (
    <div className={style.texttag__wrap}>
      <CustomTag
        className={classNames({
          [style.texttag]: true,
          [style[`texttag--${size}`]]: true,
          [style[`texttag--${theme}`]]: true,
          [style['texttag--deletable']]: isDeletable && tagStyle === TagStyle.Default,
          [style['texttag--selected']]: isDeletable && tagStyle === TagStyle.SelectedOutline,
          [className]: Boolean(className)
        })}
        onClick={onClick}
        type={onClick !== undefined ? 'button' : undefined}
      >
        {children}
      </CustomTag>

      {isDeletable && tagStyle === TagStyle.Default
        ? (
          <DeleteButton className={style.delete} onClick={onDelete} size='xs' />
          )
        : null}
    </div>
  )
}

export default TextTag
