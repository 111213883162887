import useSWR from 'swr'
import usePage from './usePage'
import { useStores } from './useStores'
import PaginatedQueryResult from '@/api/interfaces/PaginatedQueryResult'
import { ITag } from '@/interfaces/Case'

interface IUseTags {
  tags?: PaginatedQueryResult<ITag>
}
const useTags = (): IUseTags => {
  const page = usePage()
  const { api } = useStores()
  const projectId = page.init_state.project_object.id
  const { data: tags } = useSWR(
    {
      page_size: 100,
      project: projectId,
      _key: 'getTags'
    },
    api.getTags
  )

  return {
    tags
  }
}

export default useTags
