import style from '../style/profile.module.scss'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Input from '@/components/Input/Input'
import Icon from '@/components/Icon/Icon'
import TextButton from '@/components/Button/TextButton'
import Button from '@/components/Button/Button'
import { useStores } from '@/hooks'
import { useForm } from 'react-hook-form'
import { reactHookFormErrorFormater } from '@/utils'
import { Form } from '@/components/Form'
import Timer from '@/components/Timer'
import { VERIFY_TYPES } from '@/const'

interface IProps {
  phone: string
  onClose: () => void
  success: () => void
}

export interface IFormInputs {
  non_field_errors?: string
  restore_password_confirm_code?: string
  phone_confirmation_code?: string
  phone?: string
}

const VerifyPhone = ({
  phone,
  success,
  onClose
}: IProps): React.ReactElement => {
  const [seconds, setSeconds] = React.useState(60)
  const intl = useIntl()
  const { api } = useStores()

  const {
    handleSubmit,
    register,
    setError,
    formState: { errors, isSubmitting }
  } = useForm<IFormInputs>({
    defaultValues: {
      non_field_errors: '',
      phone_confirmation_code: '',
      phone: phone
    }
  })

  const onSubmit = async (params): Promise<void> => {
    try {
      await api.checkCodePhone({
        phone_confirmation_code: params.phone_confirmation_code
      })
      success()
    } catch (error) {
      const errors = reactHookFormErrorFormater(params, error)
      errors.forEach((item) => {
        setError(item.key as keyof IFormInputs, {
          message: item.value
        })
      })
    }
  }

  const handleResendCode = async (): Promise<void> => {
    setSeconds(60)
    try {
      await api.sendCodePhone({
        phone: phone
      })
    } catch (error) {
      if (error.response.data === undefined) {
        setError('non_field_errors', {
          type: '404',
          message: intl.formatMessage({
            id: 'error.server',
            defaultMessage: 'Server error'
          })
        })
      }
    }
  }

  const nonFieldErrors = errors?.non_field_errors?.message ?? ''

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className={style.modal__top}>
        <FormattedMessage
          id='profile.change_phone.verify'
          defaultMessage='Verify your identity'
        />

        <Button onClick={onClose} theme='light' circle>
          <Icon src='clear' />
        </Button>
      </div>

      <div className={style.modal__middle}>
        <FormattedMessage
          id='profile.change_phone.send'
          defaultMessage='We just sent you a confirmation code over to {phone}.'
          values={{ phone }}
        />

        <Input
          type='text'
          label={intl.formatMessage({
            id: 'profile.forgot_pass.code',
            defaultMessage: 'Enter code'
          })}
          className={style.modal__input}
          required
          {...register('phone_confirmation_code', {
            required: true
          })}
          error={errors?.phone_confirmation_code?.message ?? ''}
        />
        {seconds === 0
          ? (
            <TextButton onClick={handleResendCode}>
              <FormattedMessage
                id='profile.resend.code'
                defaultMessage='Resend code'
              />
            </TextButton>
            )
          : null}

        <Timer
          type={VERIFY_TYPES.Code}
          setSeconds={setSeconds}
          seconds={seconds}
        />

        {nonFieldErrors !== ''
          ? (
            <div className={style.modal__error}>{nonFieldErrors}</div>
            )
          : null}
      </div>

      <div className={style.modal__bottom}>
        <Button disabled={isSubmitting} type='submit'>
          <Icon src='checked' slot='icon-left' />

          <FormattedMessage
            id='profile.forgot_pass.confirm'
            defaultMessage='Confirm'
          />
        </Button>
      </div>
    </Form>
  )
}

export default VerifyPhone
