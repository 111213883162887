import React from 'react'
import UserLayout from '../layout/components/UserLayout'
import AddOrEditCompany from '../AddOrEditCompany'

const CompanyCreatePage = (): React.ReactElement => {
  return (
    <UserLayout>
      <AddOrEditCompany />
    </UserLayout>
  )
}

export default CompanyCreatePage
