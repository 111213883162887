import React from 'react'
import { ColorStatus } from '@/components/Status'
import { IMilestonesList } from '@/interfaces/Milestones'
import style from '../styles/dashboard.module.scss'

interface IProps {
  date: Date
  releases: IMilestonesList[]
}

const CustomDayContent = ({ date, releases }: IProps): React.ReactElement => {
  return (
    <div className={style.day}>
      <span>{date.getDate()}</span>
      {releases.length > 0
        ? (
          <div className={style.day__releases}>
            <div className={style.statusblock}>
              <ColorStatus color={releases[0].color} size='xs' />
              {releases.length > 1
                ? (
                  <span className={style.countmilestones}>
                    {`+${releases.length - 1}`}
                  </span>
                  )
                : null}
            </div>
            <span className={style.milestonetitle}>{releases[0].title}</span>
          </div>
          )
        : null}
    </div>
  )
}

export default CustomDayContent
