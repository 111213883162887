import Icon from '@/components/Icon/Icon'
import useGlobalConfig from '@/hooks/useGlobalConfig'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import style from '../styles/dashboard.module.scss'
import SelectCompanyModal from '@/apps/Projects/SelectCompanyModal/SelectCompanyModal'
import useModal from '@/hooks/useModal'
import Button from '@/components/Button/Button'

const EmptyProjects = (): React.ReactElement => {
  const globalConfig = useGlobalConfig()
  const { isOpen, handleOpenModal, handleCloseModal } = useModal()
  return (
    <div className={style.empty}>
      <div className={style.empty__block}>
        <Icon
          src='folders'
          size='xxxl'
          className={style.empty__icon}
        />

        <p className={style.empty__text}>
          <FormattedMessage
            id='project.empty'
            defaultMessage='No projects added yet'
          />
        </p>
        <Button
          href={globalConfig.create_project_url}
          className={style.project__addproject}
          onClick={handleOpenModal}
        >
          <Icon src='plus' slot='icon-left' />

          <FormattedMessage
            id='project.add_project'
            defaultMessage='Add project'
          />
        </Button>
      </div>

      <SelectCompanyModal open={isOpen} close={handleCloseModal} />
    </div>
  )
}

export default EmptyProjects
