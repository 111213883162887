import React from 'react'
import ProjectLayout from '../layout/components/ProjectLayout'
import UserLayout from '../layout/components/UserLayout'
import { History } from '@/apps/History'

const ProjectHistory = (): React.ReactElement => {
  return (
    <UserLayout>
      <ProjectLayout>
        <History />
      </ProjectLayout>
    </UserLayout>
  )
}

export default ProjectHistory
