import React from 'react'
import ShareLayout from '../layout/components/ShareLayout'
import ShareReports from '../ShareReports'

const ShareReportPage = (): React.ReactElement => {
  return (
    <ShareLayout>
      <ShareReports />
    </ShareLayout>
  )
}

export default ShareReportPage
