import { useStores } from '@/hooks'
import React from 'react'
import { useIntl } from 'react-intl'
import BaseAsyncSelect from '../AsyncSelect/BaseAsyncSelect'
import { IValue, SuitesSelectProps } from '../interfaces/AsyncSelect'

const SuitesSelect = ({
  handleChange,
  projectId,
  value,
  excludeId,
  label,
  ...props
}: SuitesSelectProps): React.ReactElement => {
  const { api } = useStores()
  const intl = useIntl()

  const loadOptions = (
    inputValue: string,
    callback: (options: IValue[]) => void
  ): void => {
    api
      .getSuites({
        project_id: projectId,
        exclude: excludeId,
        q: inputValue
      })

      .then((paginatedObject) => {
        callback(
          paginatedObject.map((el) => {
            return { value: el.id, label: el.title }
          })
        )
      })
      .catch((err) => console.log(err))
  }

  return (
    <BaseAsyncSelect
      {...props}
      value={value}
      label={label}
      placeholder={intl.formatMessage({
        id: 'input.placeholder',
        defaultMessage: 'Select...'
      })}
      handleChange={handleChange}
      loadOptions={loadOptions}
      useMenuPortalTarget={false}
    />
  )
}

export default SuitesSelect
