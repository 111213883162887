import { defaultCompany, defaultCube, defaultUser } from '@/images'
import classNames from 'classnames'
import React, { useState } from 'react'
import Icon from '../Icon/Icon'
import style from './styles/input.module.scss'
import BaseModal from '../Modal/Base'
import useModal from '@/hooks/useModal'
import ConfirmDeleteAvatar from './ConfirmDeleteAvatar'

interface IProps {
  error: string | string[]
  name: string
  className?: string
  image?: string | null
  deletable?: boolean
  onDelete?: () => void
  handleAddAvatar?: () => void
  variant: 'project' | 'avatar' | 'company'
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void

}

const defaultImages = {
  project: defaultCube,
  avatar: defaultUser,
  company: defaultCompany
}

const squareTypes = ['company']

const PhotoInput = React.forwardRef<HTMLInputElement, IProps>(
  (
    {
      name,
      className = '',
      image,
      variant,
      onChange,
      deletable = false,
      onDelete = () => {
      },
      handleAddAvatar = () => {
      },
      error = '',
      ...props
    },
    ref
  ): React.ReactElement => {
    const [localImage, setImage] = useState('')
    const { isOpen, handleOpenModal, handleCloseModal } = useModal()
    const showError = Array.isArray(error) ? error[0] : error

    const handleChange = (e): void => {
      handleAddAvatar()
      onChange(e)
      const file = e.target.files?.[0]
      if (file !== undefined) {
        setImage(URL.createObjectURL(file))
      }
    }

    const handleDelete = (): void => {
      onDelete()
      setImage(defaultImages[variant])
      handleCloseModal()
    }

    // Если пользователь выбрал новое фото показываем его. Если нет, то основное или заглушку
    const isShowDefault = image === undefined || image === null

    let preview
    if (localImage === '') {
      if (isShowDefault) {
        preview = defaultImages[variant]
      } else {
        preview = image
      }
    } else {
      preview = localImage
    }

    const isSquare = squareTypes.includes(variant)

    return (
      <>
        <div
          className={classNames({
            [style.photo]: true,
            [className]: Boolean(className)
          })}
        >

          <img
            width={200}
            height={200}
            alt=''
            className={classNames({
              [style.photo__image]: true,
              [style['photo__image--square']]: isSquare
            })}
            src={preview}
          />

          <label className={style.photo__upload}>
            <input
              ref={ref}
              className='visually_hidden'
              type='file'
              onClick={(e: any) => {
                e.target.value = null
              }}
              name={name}
              accept='image/png, image/jpeg'
              onChange={handleChange}
            />
            <Icon src='edit' />
          </label>
          {showError !== ''
            ? (
              <>
                <Icon src='error' className={style.error__icon} />
                <div className={style.error__text}>{showError}</div>
              </>
              )
            : null}
          <BaseModal
            open={isOpen}
            onGx-after-hide={handleCloseModal}
            onGx-overlay-dismiss={handleCloseModal}
            hideDefaultClose
            size='medium'
            className={style.modal}
          >
            <ConfirmDeleteAvatar
              onClose={handleCloseModal}
              onConfirm={handleDelete}
            />
          </BaseModal>
        </div>
        {deletable
          ? (
            <button
              type='button'
              className={style.photo__delete}
              onClick={handleOpenModal}
            >
              <Icon src='delete' className={style.photo__icon} />
            </button>
            )
          : null}
      </>
    )
  }
)

export default PhotoInput
