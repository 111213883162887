import Container from '@/components/Container'
import { Form } from '@/components/Form'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { reactHookFormErrorFormater } from '@/utils'
import { usePage, useStores } from '@/hooks'
import { Footer, Head } from '@/components/Forms'
import { useIntl } from 'react-intl'
import CompanyForm from './components/CompanyForm'
import { useNavigate } from 'react-router-dom'
import { COMPANY_ROLES, COMPANY_TYPES } from '@/const'
import BaseModal from '@/components/Modal/Base'
import useModal from '@/hooks/useModal'
import DeleteCompany from './components/DeleteCompany'
import { ICompany } from '@/interfaces/Company'
import useGlobalConfig from '@/hooks/useGlobalConfig'

interface IProps {
  id?: number
}

interface IFormInputs extends Partial<ICompany> {
  non_field_errors?: string
}

const emptyCompany: IFormInputs = {
  image: null,
  title: '',
  full_title: '',
  code: '',
  description: '',
  company_type: COMPANY_TYPES.entity,
  email: '',
  site_url: ''
}

const AddOrEditCompany = ({ id }: IProps): React.ReactElement => {
  const [isDeleteLoading, setIsDeleteLoading] = useState(false)

  const { api } = useStores()
  const intl = useIntl()
  const navigate = useNavigate()
  const { isOpen, handleOpenModal, handleCloseModal } = useModal()
  const config = useGlobalConfig()
  const page = usePage()

  const getDefaultValues = async (): Promise<IFormInputs> => {
    if (id === undefined) {
      return emptyCompany
    } else {
      const company = await api.getCompany({ id })

      // TODO: Пока что расписал поля, т.к. апишка отдает слишком много данных, потом поправить
      return {
        image: company.image,
        title: company.title,
        full_title: company.full_title,
        code: company.code,
        description: company.description,
        company_type: company.company_type,
        email: company.email,
        site_url: company.site_url
      }
    }
  }

  const {
    register,
    setError,
    control,
    watch,
    handleSubmit,
    formState: { isSubmitting, errors }
  } = useForm<IFormInputs>({
    defaultValues: getDefaultValues
  })

  const onSubmit = async (params): Promise<void> => {
    try {
      if (id === undefined) {
        const res = await api.createCompany(params)
        navigate(res.absolute_url)
      } else {
        const res = await api.updateCompany(id, params)
        navigate(res.absolute_url)
      }
    } catch (error) {
      const errros = reactHookFormErrorFormater(params, error, 'data')
      errros.forEach((item) => {
        setError(item.key as keyof IFormInputs, {
          message: item.value
        })
      })
    }
  }

  const handleDelete = async (): Promise<void> => {
    if (id === undefined) {
      return
    }
    setIsDeleteLoading(true)

    api
      .deleteCompany(id)
      .then(() => {
        handleCloseModal()
        navigate(config.company_list_url)
      })
      .catch((err) => console.log('err', err))
      .finally(() => setIsDeleteLoading(false))
  }

  const handleOpenDeleteModal = (): void => {
    handleOpenModal()
  }

  const image = watch('image')

  const isUpdate = id !== undefined

  const userRole = page.init_state.current_user_role

  const isOwner = userRole === COMPANY_ROLES.owner

  const createButtonText = intl.formatMessage({
    id: 'company.add_company',
    defaultMessage: 'Add Company'
  })

  const updateButtonText = intl.formatMessage({
    id: 'company.update_company',
    defaultMessage: 'Update Company'
  })

  const buttonText = isUpdate ? updateButtonText : createButtonText

  const nonFieldErrors = errors?.non_field_errors?.message ?? ''

  return (
    <Container>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Head
          title={buttonText}
          isSubmitting={isSubmitting}
          errors={nonFieldErrors}
          buttonText={buttonText}
          pastHref={config.company_list_url}
          isDeletable={isUpdate && isOwner}
          onDelete={handleOpenDeleteModal}
        />

        <CompanyForm
          image={image}
          errors={errors}
          register={register}
          control={control}
        />

        <Footer
          isSubmitting={isSubmitting}
          errors={nonFieldErrors}
          buttonText={buttonText}
        />
      </Form>

      {isUpdate && isOwner
        ? (
          <BaseModal
            open={isOpen}
            onGx-after-hide={handleCloseModal}
            onGx-overlay-dismiss={handleCloseModal}
            hideDefaultClose
            size='medium'
          >
            <DeleteCompany
              onCancel={handleCloseModal}
              onDelete={handleDelete}
              disableSubmit={isDeleteLoading}
            />
          </BaseModal>
          )
        : null}
    </Container>
  )
}

export default AddOrEditCompany
