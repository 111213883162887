import React from 'react'
import { FormattedMessage } from 'react-intl'
import Button from '../Button/Button'
import Icon from '../Icon/Icon'
import style from './styles/modal.module.scss'
import Title from '../Title'

interface IProps {
  onCancel: () => void
  largeFileNames: string[]
}

const ModalFileUploadingError = ({
  onCancel,
  largeFileNames
}: IProps): React.ReactElement => {
  return (
    <div className={style.delete}>
      <Icon src='error' size='xxxl' className={style.delete__icon} />

      <Title type='h2' theme='h1' className={style.delete__title}>
        <FormattedMessage
          id='modal.upload.title'
          defaultMessage='File upload error'
        />
      </Title>

      {largeFileNames.map((name, idx) => (
        <p key={idx} className={style.uploaderror__cancelled}>
          {name}
        </p>
      ))}

      <Title type='h3'>
        <FormattedMessage
          id='modal.upload.text'
          defaultMessage='You may have uploaded the wrong file format or the file is too large. Please, try again.'
        />
      </Title>

      <div className={style.delete__footer} slot='footer'>
        <Button onClick={onCancel}>
          <Icon src='checked' slot='icon-left' />
          <FormattedMessage id='common.ok' defaultMessage='Ok' />
        </Button>
      </div>
    </div>
  )
}

export default ModalFileUploadingError
