import useSWR from 'swr'
import CommentItem from '@/components/CommentItem'
import Title from '@/components/Title'
import { useStores } from '@/hooks'
import { ICaseInRunComment } from '@/interfaces/Case'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import style from './styles/modal.module.scss'
import { CASE_TYPES } from '@/const'

const ViewCommentsTask = (): JSX.Element | null => {
  const { api, modalStore } = useStores()
  const runId = modalStore.dataModal.runId
  const taskId = modalStore.dataModal.taskId
  const { data } = useSWR(
    {
      runId: runId,
      taskId: taskId,
      _key: 'getCommentsTask'
    },
    api.getCommentsTask
  )
  const comments: ICaseInRunComment[] | undefined = data?.results

  if (comments === undefined) return null

  return (
    <>
      <Title type='h2'>
        <FormattedMessage id='titles.comments' defaultMessage='Comments' />
      </Title>

      <div className={style.modal__comments}>
        {comments.map((el) => {
          return (
            <CommentItem
              key={el.id}
              type={CASE_TYPES.task}
              status={el.status}
              comment={el.comment}
              createdTime={el.created_at}
            />
          )
        })}
      </div>
    </>
  )
}

export default ViewCommentsTask
