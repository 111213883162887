import React, { Children, ReactElement } from 'react'
import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
  Modifier,
  DragEndEvent
} from '@dnd-kit/core'
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy
} from '@dnd-kit/sortable'
import { SortableItemWrapper } from '../SortableItemWrapper'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'

interface IPropType {
  id: string | number
}

interface IDraggableWrapperProps<T> {
  items: T[]
  handleDragEnd: (sortedResults: T[], id: number | string) => void
  children: React.ReactNode[]
  modifiers?: Modifier[]
  disabled: boolean
}

const DraggableWrapper = <T extends IPropType>({
  items,
  handleDragEnd,
  children,
  modifiers,
  disabled
}: IDraggableWrapperProps<T>): JSX.Element => {
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor))

  const onDragEnd = (event: DragEndEvent): void => {
    const { active, over } = event

    if (active === null || over === null || active.id === over.id) {
      return
    }

    const oldIndex = items.findIndex((item) => item.id === active.id)
    const newIndex = items.findIndex((item) => item.id === over.id)

    const newItems = arrayMove([...items], oldIndex, newIndex).map(
      (item, index) => ({
        ...item,
        sort: index
      })
    )

    handleDragEnd(newItems, active.id)
  }

  const wrappedChildren = Children.map(children, (child) => {
    return (
      <SortableItemWrapper
        disabled={disabled}
        key={(child as ReactElement).props.id}
        id={(child as ReactElement).props.id}
      >
        {child}
      </SortableItemWrapper>
    )
  })

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={onDragEnd}
      modifiers={(modifiers != null) ? modifiers : [restrictToVerticalAxis]}
    >
      <SortableContext
        disabled={disabled}
        items={items}
        strategy={verticalListSortingStrategy}
      >
        {wrappedChildren}
      </SortableContext>
    </DndContext>
  )
}

export default DraggableWrapper
