import classNames from 'classnames'
import React from 'react'
import { useIntl } from 'react-intl'
import style from '../styles/status.module.scss'
import { TBadgeStatus } from '../../../interfaces/Types'

interface IProps {
  status: TBadgeStatus
  className?: string
}

const BadgeTextStatus = ({
  status,
  className = ''
}: IProps): React.ReactElement => {
  const intl = useIntl()

  const statusLowerCase = status.toLowerCase()

  const defaultMessage = status.charAt(0) + statusLowerCase.slice(1)

  const statusText = intl.formatMessage({
    id: `status.test.${statusLowerCase}`,
    defaultMessage
  })
  return (
    <div
      className={classNames({
        [style.badge]: true,
        [style[`test--${statusLowerCase}`]]: true,
        [className]: Boolean(className)
      })}
    >
      <span className={style.badge__text}>{statusText}</span>
    </div>
  )
}

export default BadgeTextStatus
