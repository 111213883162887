import { GxSpinner } from '@garpix/garpix-web-components-react'
import { Components } from '@garpix/garpix-web-components'
import React, { forwardRef } from 'react'
import classNames from 'classnames'
import style from './styles/loading.module.scss'

const Spinner = forwardRef<Components.GxTooltip, any>(
  ({ ...props }, ref): React.ReactElement => {
    const { children = '', className = '' } = props
    return (
      <GxSpinner
        {...props}
        ref={ref}
        className={classNames({
          [style.spinner]: true,
          [className]: Boolean(className)
        })}
      >
        {children}
      </GxSpinner>
    )
  }
)

export default Spinner
