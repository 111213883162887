import React, { forwardRef, useEffect, useState } from 'react'
import classNames from 'classnames'
import style from './styles/inputdaypicker.module.scss'
import Dropdown from '../Dropdown/Dropdown'
import Input from '../Input/Input'
import DropdownMenu from '../Dropdown/DropdownMenu'
import DayPickerSingle from '../DayPicker/DayPickerSingle'
import Icon from '../Icon/Icon'
import { format, isValid, parse } from 'date-fns'
import { Matcher } from 'react-day-picker'
import { Components } from '@garpix/garpix-web-components'

interface IProps {
  label?: string
  placeholder?: string
  onChangeHandlerInput: (value) => void
  onChangeHandlerPicker: (value) => void
  value?: string | undefined
  className?: string
  required?: boolean
  disabledDays?: Matcher | Matcher[] | undefined
  error?: string | string[]
}

const InputDayPicker = (
  {
    label = '',
    placeholder = '',
    className = '',
    value,
    onChangeHandlerInput,
    onChangeHandlerPicker,
    required = false,
    disabledDays,
    error = ''
  }: IProps,
  ref
): React.ReactElement => {
  const [selectedDay, setSelectedDay] = useState<Date | undefined>(undefined)
  const [isOpen, setOpen] = useState(false)

  const handleResetDay = (): void => {
    setSelectedDay(undefined)
    onChangeHandlerPicker(undefined)
  }

  useEffect(() => {
    if (value !== undefined) {
      const newDate = parse(value, 'dd/MM/yyyy', new Date())
      setSelectedDay(isValid(newDate) ? newDate : undefined)
    }
  }, [value])

  return (
    <div
      className={classNames({
        [style.inputdaypicker]: true,
        [className]: Boolean(className)
      })}
    >
      <Input
        ref={ref}
        value={value}
        label={label}
        placeholder={placeholder}
        onChange={(e) => onChangeHandlerInput(e.target.value)}
        className={style.inputdaypicker__input}
        required={required}
        error={error}
      />
      <Dropdown
        open={isOpen}
        onGx-hide={() => setOpen(false)}
        className={style.inputdaypicker__drop}
      >
        <Icon
          slot='trigger'
          src='calendar'
          className={style.inputdaypicker__icon}
        />

        <DropdownMenu>
          <DayPickerSingle
            selected={selectedDay}
            disabledReset={selectedDay === undefined}
            onSelect={(day) => {
              setOpen(false)
              day !== undefined
                ? onChangeHandlerPicker(format(day ?? 0, 'dd/MM/yyyy'))
                : onChangeHandlerPicker(undefined)
            }}
            handleReset={handleResetDay}
            mode='single'
            disabled={disabledDays}
          />
        </DropdownMenu>
      </Dropdown>
    </div>
  )
}

export default forwardRef<Components.GxInput, any>(InputDayPicker)
