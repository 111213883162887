import { makeAutoObservable } from 'mobx'

interface ISelectValues {
  default: number
  current: number
}

type TPageSizeSelect =
  | 'historyPage'
  | 'reportDetail'
  | 'addCasesModal'
  | 'runDetailCases'
  | 'casesPage'

interface IParsedStorage {
  parsedStorage?: { [key in TPageSizeSelect]?: ISelectValues }
}

class PageSizeSelect {
  #localStorageKey: string
  pageSize: number
  parsedStorage: IParsedStorage

  constructor () {
    this.#localStorageKey = 'page_size_selects'

    if (typeof window !== 'undefined') {
      const parsedStorage = this.#getParsedStorage()
      this.parsedStorage = parsedStorage
    } else {
      this.parsedStorage = {}
    }
    makeAutoObservable(this)
  }

  #getParsedStorage = (): IParsedStorage => {
    const sidebarStorage = localStorage?.getItem(this.#localStorageKey)

    if (sidebarStorage === null) {
      return {}
    }

    return JSON.parse(sidebarStorage)
  }

  #changeStorageValue = (key: string, value: any): void => {
    this.parsedStorage[key] = value

    const stringifiedStorage = JSON.stringify(this.parsedStorage)
    localStorage.setItem(this.#localStorageKey, stringifiedStorage)
  }

  getSize = (select: TPageSizeSelect): number | undefined => {
    return this.parsedStorage?.[select]
  }

  setSize = (select: TPageSizeSelect, size: number): void => {
    this.#changeStorageValue(select, size)
  }
}

export const pageSizeSelectService = new PageSizeSelect()
