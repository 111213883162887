import React, { forwardRef, useState } from 'react'
import { useIntl } from 'react-intl'
import Select from '@/components/Select/Select'
import MenuItem from '@/components/MenuItem/MenuItem'
import useSWR from 'swr'
import { useStores } from '@/hooks/useStores'
import { IOptions } from '../interfaces'
import style from '../styles/case_detail.module.scss'

interface ITypeOfTaskProps {
  projectId: string | number
  handleChange: (taskId: string | number, subtask: boolean) => void
  error?: string
}

const TypeOfTaskField = (
  { projectId, handleChange, error }: ITypeOfTaskProps,
  ref
): JSX.Element => {
  const intl = useIntl()
  const { api } = useStores()
  const [options, setOptions] = useState<IOptions[]>([])
  const [currentItem, setCurrentItem] = useState<IOptions>()

  useSWR(
    {
      id: projectId,
      fromProject: true,
      _key: 'getJiraIssueTypes'
    },
    api.getJiraIssueTypes,
    {
      onSuccess: (issueTypes) => {
        const uniqueIssueTypes = Array.from(
          new Set(issueTypes.map((issue) => issue))
        )
        const mappedData = uniqueIssueTypes.map((issue, idx) => {
          const issueIds = issueTypes.map((issue) => issue.id)
          return {
            value: issue.name,
            label: issue.name,
            subtask: issue.subtask,
            icon: issue?.iconUrl,
            id: issueIds[idx]
          }
        })
        setOptions(mappedData)
      }
    }
  )

  const handleOptions = (item: IOptions): void => {
    setCurrentItem(item)
    handleChange(item.id, item.subtask)
  }

  return (
    <Select
      required
      label={intl.formatMessage({
        id: 'case.placeholder.task_type',
        defaultMessage: 'Task Type'
      })}
      error={error}
      ref={ref}
      value={currentItem?.label ?? ''}
    >
      {currentItem?.icon !== undefined
        ? (
          <img src={currentItem.icon} slot='prefix' alt='img' />
          )
        : null}

      {options.map((item, index) => (
        <MenuItem
          key={index}
          value={item.label}
          onClick={() => handleOptions(item)}
        >
          <div className={style.task__img}>
            <img src={item.icon} alt={item.label} />
          </div>
          {item.value}
        </MenuItem>
      ))}
    </Select>
  )
}

export default forwardRef<ITypeOfTaskProps, any>(TypeOfTaskField)
