import { TPriority } from '@/interfaces/Types'
import classNames from 'classnames'
import React from 'react'
import { useIntl } from 'react-intl'
import Icon from '../Icon/Icon'
import style from './styles/priority.module.scss'
import Tooltip from '../Tooltip'

interface IProps {
  variant?: 'default' | 'icon'
  priority: TPriority
  className?: string
}

const Priority = ({
  variant = 'default',
  priority,
  className = ''
}: IProps): React.ReactElement => {
  const intl = useIntl()
  const _priority = priority.toLowerCase().replace(' ', '_')

  const defaultMessage = _priority.charAt(0).toUpperCase() + _priority.slice(1)

  const priorityText = intl.formatMessage({
    id: `priority.${_priority}`,
    defaultMessage
  })

  if (variant === 'default') {
    return (
      <div
        className={classNames({
          [style.priority]: true,
          [className]: Boolean(className)
        })}
      >
        <Icon src={`priority_${_priority}`} className={style.priority__icon} />

        <span className={style.priority__text}>{priorityText}</span>
      </div>
    )
  }

  if (variant === 'icon') {
    return (
      <Tooltip content={priorityText}>
        <div
          className={classNames({
            [style.priority]: true,
            [style['priority--icon']]: true,
            [className]: Boolean(className)
          })}
        >
          <Icon
            src={`priority_${_priority}`}
            className={style.priority__icon}
          />
        </div>
      </Tooltip>
    )
  }

  return <></>
}

export default Priority
