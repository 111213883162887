import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useForm } from 'react-hook-form'
import Icon from '@/components/Icon/Icon'
import Button from '@/components/Button/Button'
import { useStores } from '@/hooks'
import { reactHookFormErrorFormater } from '@/utils'
import { Form } from '@/components/Form'
import Title from '@/components/Title'
import Input from '@/components/Input/Input'
import ErrorsBlock from '@/components/ErrorsBlock'
import style from '../styles/registration.module.scss'

interface IProps {
  prevStage: () => void
  nextStage: () => void
  email: string
  code: string
}

export interface IFormInputs {
  new_password?: string
  confirm: string
  username?: string
  restore_password_confirm_code: string
  non_field_errors?: string
}

const SetPassword = ({
  prevStage,
  nextStage,
  code,
  email
}: IProps): React.ReactElement => {
  const { api } = useStores()
  const intl = useIntl()

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting }
  } = useForm<IFormInputs>({
    defaultValues: {
      username: email,
      new_password: '',
      confirm: '',
      restore_password_confirm_code: code
    }
  })

  const onSubmit = async (params): Promise<void> => {
    try {
      await api.setPasswordRestore({
        new_password: params.new_password,
        username: params.username,
        restore_password_confirm_code: params.restore_password_confirm_code
      })
      nextStage()
    } catch (error) {
      const errors = reactHookFormErrorFormater(params, error)
      errors.forEach((item) => {
        setError(item.key as keyof IFormInputs, {
          message: item.value
        })
      })
    }
  }
  const nonFieldErrors = errors?.non_field_errors?.message ?? ''

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Button
        theme='light'
        className={style.form__back}
        circle
        onClick={prevStage}
      >
        <Icon src='back' />
      </Button>

      <Title type='h1'>
        <FormattedMessage
          id='profile.forgot_pass.new_pass'
          defaultMessage='New password'
        />
      </Title>

      <div className={style.form}>
        <p className={style.form__text}>
          <FormattedMessage
            id='profile.forgot_pass.new_pass_text'
            defaultMessage='Please create a new password. Strong password includes numbers, letters, and punctuation marks.'
          />
        </p>

        <Input
          type='password'
          label={intl.formatMessage({
            id: 'profile.change_pass.new',
            defaultMessage: 'New password'
          })}
          className={style.modal__input}
          required
          {...register('new_password', { required: true })}
          error={errors?.new_password?.message ?? ''}
        />

        <Input
          type='password'
          label={intl.formatMessage({
            id: 'profile.change_pass.repeat',
            defaultMessage: 'Repeat new password'
          })}
          className={style.modal__input}
          required
          {...register('confirm', {
            required: true,
            validate: (value, formValues) => {
              if (formValues.new_password !== value) {
                return intl.formatMessage({
                  id: 'profile.change_pass.no_repeat',
                  defaultMessage: 'Your passwords do no match'
                })
              }
            }
          })}
          error={errors?.confirm?.message ?? ''}
        />

        <div className={style.form__bottom}>
          <Button type='submit' disabled={isSubmitting} size='lg'>
            <Icon src='checked' slot='icon-left' />

            <FormattedMessage id='common.save' defaultMessage='Save' />
          </Button>
        </div>

        {nonFieldErrors !== ''
          ? (
            <ErrorsBlock
              errorsArray={[nonFieldErrors]}
            />
            )
          : null}
      </div>
    </Form>
  )
}

export default SetPassword
