import React from 'react'
import style from './styles/comment.module.scss'
import HtmlContent from '../HtmlContent/HtmlContent'
import { TBadgeStatus, TCaseType } from '@/interfaces/Types'
import { BadgeStatus } from '../Status'
import { FormattedMessage } from 'react-intl'
import { CASE_TYPES } from '@/const'
import { useStores } from '@/hooks'

interface IProps {
  status: TBadgeStatus
  comment: string
  createdTime: string
  type?: TCaseType
  currentStepNumber?: number
}

const CommentItem = ({
  status,
  comment,
  createdTime,
  type = CASE_TYPES.case,
  currentStepNumber
}: IProps): JSX.Element => {
  const store = useStores()
  const dateTime = store.localeDate.formatDateTime(createdTime)

  return (
    <div className={style.comment}>
      <div className={style.comment__top}>
        <span className={style.comment__title}>
          {currentStepNumber !== undefined &&
          currentStepNumber > 0
            ? (
              <div className={style.comment__title_step}>
                <FormattedMessage id='case.steps.step' defaultMessage='Step' />
                <span className={style.comment__title_step_count}>
                  {currentStepNumber}
                </span>
              </div>
              )
            : null}

          <span className={style.comment__title_time}>{dateTime}</span>
        </span>
      </div>

      <div className={style.comment__content}>
        <div className={style.comment__left}>
          <span className={style.comment__subtitle}>
            <FormattedMessage
              id='case.comments.status'
              defaultMessage='Status'
            />
          </span>

          <BadgeStatus status={status} className={style.comment__badge} />
        </div>

        <div className={style.comment__right}>
          <span className={style.comment__subtitle}>
            <FormattedMessage
              id='case.comments.comment'
              defaultMessage='Comment'
            />
          </span>

          <HtmlContent className={style.comment__text} content={comment} />
        </div>
      </div>
    </div>
  )
}

export default CommentItem
