import classNames from 'classnames'
import React, { PropsWithChildren, forwardRef } from 'react'
import style from './styles/wrapper.module.scss'

interface IWrapperProps extends Omit<HTMLDivElement, 'className'> {
  variant?: 'component'
  className?: string
}

const Wrapper = (
  {
    children,
    variant = 'component',
    className = ''
  }: PropsWithChildren<IWrapperProps>,
  ref
): React.ReactElement => {
  const classes = classNames({
    [style[variant]]: true,
    [className]: Boolean(className)
  })

  return (
    <div className={classes} ref={ref}>
      {children}
    </div>
  )
}

export default forwardRef<IWrapperProps, any>(Wrapper)
