import Button from '@/components/Button/Button'
import { Form } from '@/components/Form'
import Input from '@/components/Input/Input'
import { IValue } from '@/components/Select/interfaces/AsyncSelect'
import SuitesSelect from '@/components/Select/SuitesSelect'
import Textarea from '@/components/Textarea'
import Title from '@/components/Title'
import { useStores } from '@/hooks'
import { reactHookFormErrorFormater } from '@/utils'
import React, { useState } from 'react'
import style from './styles/modal.module.scss'
import { Controller, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import Icon from '@/components/Icon/Icon'

interface IProps {
  project: number
  handleClose: () => void
  initSuiteId?: number
  handleReloadSuites: () => void
}

interface IFormInputs {
  title: string
  description?: string
  parent: IValue | undefined
}

const initValues = {
  title: '',
  description: '',
  parent: undefined
}

const CreateOrEditSuite = ({
  project,
  handleClose,
  initSuiteId,
  handleReloadSuites
}: IProps): React.ReactElement => {
  const intl = useIntl()
  const { api } = useStores()
  const [values, setValues] = useState<IFormInputs>(initValues)

  const getDefaultValues = async (): Promise<IFormInputs> => {
    if (initSuiteId !== undefined) {
      let newValues
      await api
        .getSuite({
          id: initSuiteId
        })
        .then((res) => {
          const parent =
            res.parent !== null && res.parents.length > 0
              ? {
                  label: res.parents[res.parents.length - 1],
                  value: res.parent
                }
              : undefined

          newValues = {
            title: res.title,
            description: res.description,
            parent
          }

          setValues(newValues)
        })
      return newValues
    }
    return values
  }

  const handleCloseAndReload = (): void => {
    handleClose()
    handleReloadSuites()
  }

  const {
    register,
    setError,
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors }
  } = useForm<IFormInputs>({
    defaultValues: getDefaultValues
  })

  const onSubmit = async ({
    parent,
    ...params
  }: IFormInputs): Promise<void> => {
    if (initSuiteId === undefined) {
      try {
        await api.createSuite({
          parent: parent?.value ?? null,
          project: project,
          ...params
        })
        reset()
        handleCloseAndReload()
      } catch (error) {
        const errors = reactHookFormErrorFormater(params, error)
        errors.forEach((item) => {
          setError(item.key as keyof IFormInputs, {
            message: item.value
          })
        })
      }
    } else {
      try {
        await api.updateSuite(initSuiteId, {
          parent: parent?.value ?? null,
          ...params
        })
        reset()
        handleCloseAndReload()
      } catch (error) {
        const errors = reactHookFormErrorFormater(params, error)
        errors.forEach((item) => {
          setError(item.key as keyof IFormInputs, {
            message: item.value
          })
        })
      }
    }
  }

  const title =
    initSuiteId !== undefined
      ? intl.formatMessage({
        id: 'suites.edit',
        defaultMessage: 'Edit suite'
      })
      : intl.formatMessage({
        id: 'suites.add_suite',
        defaultMessage: 'Add Test Suite'
      })

  const buttonText =
    initSuiteId !== undefined
      ? intl.formatMessage({
        id: 'common.edit',
        defaultMessage: 'Edit'
      })
      : intl.formatMessage({
        id: 'common.create',
        defaultMessage: 'Create'
      })

  return (
    <div>
      <Title type='h2' className={style.modal__title}>
        {title}

        <Button theme='light' circle onClick={handleClose}>
          <Icon src='clear' />
        </Button>
      </Title>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className={style.modal__fields}>
          <div className={style.modal__field}>
            <Controller
              control={control}
              name='parent'
              render={({ field: { onChange, value } }) => {
                return (
                  <SuitesSelect
                    value={value}
                    projectId={project}
                    handleChange={onChange}
                    isClearable
                    defaultValue={values.parent ?? undefined}
                    excludeId={initSuiteId}
                    label={intl.formatMessage({
                      id: 'case.form.test_suite',
                      defaultMessage: 'Parent suite'
                    })}
                  />
                )
              }}
            />
          </div>

          <Input
            className={style.modal__field}
            label={intl.formatMessage({
              id: 'suites.form.name',
              defaultMessage: 'Name'
            })}
            value={values.title}
            required
            {...register('title', { required: true })}
            error={errors?.title?.message ?? ''}
          />

          <Textarea
            className={style.modal__field}
            label={intl.formatMessage({
              id: 'milestones.form.description',
              defaultMessage: 'Description'
            })}
            value={values.description}
            {...register('description', { required: false })}
            error={errors?.description?.message ?? ''}
          />
        </div>

        <div className={style.modal__button}>
          <Button type='submit' disabled={isSubmitting}>
            {buttonText}
          </Button>
        </div>
      </Form>
    </div>
  )
}

export { CreateOrEditSuite }
