import MenuItem from '@/components/MenuItem/MenuItem'
import classNames from 'classnames'
import React, { forwardRef } from 'react'
import Select from '../Select'
import style from './styles/pagesize.module.scss'
import { scrollTop } from '@/utils'

interface IProps {
  className?: string
  handleChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
  value: string
  required?: boolean
  clearable?: boolean
  pageSizeOptions?: number[]
  isScrollTop?: boolean
}

const PageSizeSelect = (
  {
    className = '',
    handleChange,
    value,
    required = false,
    pageSizeOptions = [10, 50, 100],
    isScrollTop = false
  }: IProps,
  ref
): React.ReactElement => {
  const onChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    if (isScrollTop) scrollTop()
    handleChange(e)
  }
  return (
    <Select
      ref={ref}
      className={classNames({
        [style.form]: true,
        [className]: Boolean(className)
      })}
      required={required}
      onChange={onChange}
      value={value}
    >
      {pageSizeOptions.map((pageSize) => (
        <MenuItem key={pageSize} value={pageSize}>
          {pageSize}
        </MenuItem>
      ))}
    </Select>
  )
}

export default forwardRef(PageSizeSelect)
