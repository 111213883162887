import React, { forwardRef, ReactElement } from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  ChartData
} from 'chart.js'
import { Line } from 'react-chartjs-2'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip
)

interface IProps {
  title?: string
  data: ChartData<'line'>
  className?: string
}

const LineChartBase = (
  { title, data, className = '' }: IProps,
  ref
): ReactElement => {
  const options = {
    responsive: true,
    plugins: {
      title: {
        display: title !== undefined,
        text: title !== undefined ? title : ''
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1
        }
      }
    }
  }

  return <Line ref={ref} options={options} data={data} className={className} />
}

export const LineChart = forwardRef(LineChartBase)
