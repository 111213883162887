import AuthRegLayout from '@/apps/layout/components/AuthRegLayout'
import { regImage } from '@/images'
import React from 'react'
import EmailConfirm from '../EmailConfirm'
import YandexMetrika from '@/components/YandexMetrika'

const EmailConfirmPage = (): React.ReactElement => {
  return (
    <>
      <YandexMetrika />
      <AuthRegLayout image={regImage}>
        <EmailConfirm />
      </AuthRegLayout>
    </>
  )
}

export default EmailConfirmPage
