import React from 'react'
import style from './styles/notification_item.module.scss'
import { INotifiesExcludeProjectData } from '@/interfaces/Notifies'
import { ITimeSince } from '@/interfaces/Utils'
import NotifyTime from './components/NotifyTime'
import { FormattedMessage } from 'react-intl'
import Tooltip from '../Tooltip'
import { getInitials } from '../UserName'
import { AvatarProject, AvatarUser } from '../Avatar'

interface IProps {
  data: INotifiesExcludeProjectData
  time: ITimeSince
}

const NotifyExcludeProject = ({ time, data }: IProps): React.ReactElement => {
  const { project, author } = data
  const initialsUser = getInitials(
    author.first_name,
    author.last_name,
    author.email
  )

  return (
    <>
      <Tooltip content={`${author.first_name} ${author.last_name}`}>
        <AvatarUser
          src={author.avatar}
          className={style.item__avatar}
          initials={initialsUser}
        />
      </Tooltip>

      <div className={style.item__content}>
        <p className={style.info}>
          <FormattedMessage
            id='notifications.item.project_exclude'
            defaultMessage='You have been <span>excluded</span> from project'
            values={{
              span: (chunks) => (
                <span className={style.invtext__decline}>{chunks}</span>
              )
            }}
          />
        </p>

        <div className={style.run}>
          <AvatarProject src={project.image} />

          <span className={style.run__name}>{project.title}</span>
        </div>

        <NotifyTime time={time} />
      </div>
    </>
  )
}

export default NotifyExcludeProject
