import React, { useState } from 'react'
import { IFilePreview, IUploadZoneProps } from './interfaces'
import UploadZone from './UploadZone'

const UploadZoneForm = ({
  className = '',
  onChange,
  isScrollableList = false,
  initFiles = [],
  enableReinitialize = false,
  isUpload,
  canUserDeleteFile,
  readonly,
  setFilesCount
}: IUploadZoneProps): React.ReactElement => {
  const [files, setFiles] = useState<File[]>([])
  const [currentFile, setCurrentFile] =
    useState<Partial<IFilePreview> | null>(null)

  const handleDeleteFile = async (): Promise<void> => {
    if (currentFile?.id !== undefined) {
      setFiles((prev) => {
        const newValue = prev.filter((el) => currentFile.name !== el.name)
        onChange(newValue)
        return newValue
      })
    }
  }

  return (
    <UploadZone
      className={className}
      initFiles={initFiles}
      isScrollableList={isScrollableList}
      enableReinitialize={enableReinitialize}
      isUpload={isUpload}
      canUserDeleteFile={canUserDeleteFile}
      onChange={onChange}
      files={files}
      setFiles={setFiles}
      currentFile={currentFile}
      setCurrentFile={setCurrentFile}
      handleDeleteFile={handleDeleteFile}
      isForm
      readonly={readonly}
      setFilesCount={setFilesCount}
    />
  )
}

export default UploadZoneForm
