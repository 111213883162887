import useSWR from 'swr'
import { useStores } from './useStores'

interface IUseNotifyCounter {
  counter: number
  counterUpdate: () => void
}
const useNotifyCounter = (): IUseNotifyCounter => {
  const { api } = useStores()

  const { data, mutate } = useSWR(
    { page: 1, page_size: 1, is_read: false, _key: 'getCounter' },
    api.getSystemNotifies
  )

  const counter = data?.count ?? 0

  const counterUpdate = async (): Promise<void> => {
    await mutate()
  }

  return {
    counter,
    counterUpdate
  }
}

export default useNotifyCounter
