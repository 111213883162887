import React, { useEffect, useState } from 'react'
import useModal from '@/hooks/useModal'
import DownloadAnimated from '@/components/Icon/AnimatedIcons/DownloadAnimated'
import { FormattedMessage } from 'react-intl'
import Icon from '@/components/Icon/Icon'
import { Spinner, SpinnerWait } from '@/components/Loading'
import Tooltip from '@/components/Tooltip'
import { AvatarUser } from '@/components/Avatar'
import useSWR from 'swr'
import { useStores, useWebSocket } from '@/hooks'
import {
  INotifiesImportData,
  TNotificationImportStatus
} from '@/interfaces/Notifies'
import { IMPORT_STATUSES } from '@/const'
import { getInitials } from '@/components/UserName'
import style from './styles/cases.module.scss'

interface IProps {
  projectId: number
}

const ImportPanel = ({ projectId }: IProps): React.ReactElement => {
  const { api } = useStores()
  const { isOpen, handleOpenModal, handleCloseModal } = useModal()
  const [status, setStatus] = useState<TNotificationImportStatus>()
  const [error, setError] = useState<string[]>()

  const handleClickDownload = (): void => {
    isOpen ? handleCloseModal() : handleOpenModal()
  }

  const { data, mutate } = useSWR(
    { project: projectId, _key: 'getImportNotifies' },
    api.getImportNotifies
  )

  const handleGetNotificationImport = (): void => {
    void mutate()
  }

  const handleCancelImport = (): void => {
    api
      .cancelImportCases(projectId)
      .catch((e) => setError(e.response.data.non_field_errors))
  }

  useEffect(() => {
    if (data !== undefined && data?.length > 0) {
      const status = data[0].data_json.event_title
      if (data[0].data_json.result === false) setStatus('cases_import_error')
      else setStatus(status)
    }
  }, [data])

  useWebSocket(handleGetNotificationImport, 'ImportPanel')

  if (data === undefined || data?.length === 0) return <></>

  return (
    <div className={style.download}>
      <button type='button' onClick={handleClickDownload}>
        {status === IMPORT_STATUSES.queue
          ? (
            <Icon src='download' className={style.download__queue} />
            )
          : null}

        {status === IMPORT_STATUSES.start ? <DownloadAnimated /> : null}

        {status === IMPORT_STATUSES.finish
          ? (
            <Icon src='checked' className={style.download__success} />
            )
          : null}

        {status === IMPORT_STATUSES.error
          ? (
            <Icon src='attention' className={style.download__error} />
            )
          : null}
      </button>

      {isOpen
        ? (
          <div className={style.download__panel}>
            <div className={style.download__title}>
              <FormattedMessage
                id='cases.importing.title'
                defaultMessage='Importing Test Cases'
              />

              <button type='button' onClick={handleCloseModal}>
                <Icon src='clear' className={style.download__close} />
              </button>
            </div>

            {error !== undefined
              ? (
                <>
                  {error.map((err, index) => (
                    <span key={index} className={style.download__error}>
                      {err}
                    </span>
                  ))}
                </>
                )
              : null}

            <div className={style.download__block}>
              {data?.map((item) => {
                const {
                  event_title: event,
                  author,
                  file_name: fileName,
                  result
                }: INotifiesImportData = item.data_json
                const initialsUser = getInitials(
                  author.first_name,
                  author.last_name,
                  author.email
                )

                return (
                  <div key={item.id} className={style.download__item}>
                    <div className={style.download__name}>
                      {IMPORT_STATUSES.queue === event ? <SpinnerWait /> : null}

                      {IMPORT_STATUSES.start === event ? <Spinner /> : null}

                      {IMPORT_STATUSES.finish === event && result === true
                        ? (
                          <Icon src='checked' className={style.download__success} />
                          )
                        : null}

                      {result === false
                        ? (
                          <Icon src='attention' className={style.download__error} />
                          )
                        : null}

                      <div className={style.download__file}>
                        <Icon src='draft' />

                        <span>{fileName}</span>
                      </div>
                    </div>

                    <div className={style.download__user}>
                      <Tooltip
                        content={`${author.first_name} ${author.last_name}`}
                      >
                        <AvatarUser initials={initialsUser} src={author.avatar} />
                      </Tooltip>

                      {IMPORT_STATUSES.queue === event
                        ? (
                          <button
                            type='button'
                            onClick={handleCancelImport}
                            className={style.download__cancel}
                          >
                            <FormattedMessage
                              id='common.cancel'
                              defaultMessage='Cancel'
                            />
                          </button>
                          )
                        : null}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
          )
        : null}
    </div>
  )
}

export default ImportPanel
