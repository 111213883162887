import { GxTextField } from '@garpix/garpix-web-components-react'
import classNames from 'classnames'
import React, { forwardRef } from 'react'
import Icon from '../Icon/Icon'
import style from './styles/input.module.scss'

const PasswordInput = forwardRef<HTMLInputElement, any>(
  (
    {
      size = 'medium',
      label,
      className = '',
      children,
      error = '',
      clearable,
      ...props
    },
    ref
  ): React.ReactElement => {
    const showError = Array.isArray(error) ? error[0] : error

    return (
      <GxTextField
        {...props}
        size={size}
        label={label}
        className={classNames({
          [style.input]: true,
          [style.error]: Boolean(showError),
          [className]: Boolean(className)
        })}
        clearable={clearable}
        invalid={Boolean(showError)}
        pill
      >
        <input ref={ref} slot='input' type='password' {...props} />
        <Icon src='show_password' slot='hide-password-icon' />
        <Icon src='hide_password' slot='show-password-icon' />
        <Icon src='clear' slot='clear-icon' />
        {children}
        {showError !== ''
          ? (
            <>
              <Icon src='error' slot='suffix' className={style.error__icon} />
              <div slot='help-text' className={style.error__text}>
                {showError}
              </div>
            </>
            )
          : null}
      </GxTextField>
    )
  }
)

export default PasswordInput
